<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid open-house-outer" id="accoutnSettings">
  <ng-container *ngIf="isLoading == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <div class="container">
  <ng-container *ngIf="dashboardType == 'Buyer' || dashboardType == 'both'">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">My Appointments</h4>
        <ul class="nav nav-tabs">
          <li class="nav_li active"><a data-toggle="tab" class="active" href="#resale">Resale ({{propertyList.length || 0}})</a></li>
          <li class="nav_li active"><a data-toggle="tab" href="#new">New ({{projectList.length || 0}})</a></li>
        </ul>

        <div class="open-house">
          <div class="tab-content">
            <div id="resale" class="tab-pane fade in active show mt-3">
              <ng-container *ngIf="isLoading == false">
                <div class="empty-message" *ngIf="propertyList.length == 0">
                  <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                  <div class="empty-text">
                    <h5>You have no appointments</h5>
                    <p>Add homes to your appointment to help you plan out your home search.</p>
                  </div>
                  <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                </div>
              </ng-container>
              <ng-container *ngFor="let appointmentItem of propertyList">
                <div *ngIf="isLoading == false" [ngClass]="appointmentItem?.property_detail?.prop_status?.title==='Sold' ? 'account-list-property remove_hand_cursor':'account-list-property'">
                  <div class="p_image_view" (click)="openInNewTab(appointmentItem,appointmentItem.property_detail.id,'property')" [style.background-image]="'url(\'' + appointmentItem.property_detail.image_preview + '\')'"></div>
                
                  <div class="property_badge_ top_left_" *ngIf="appointmentItem?.property_detail?.prop_status && appointmentItem?.property_detail?.prop_status?.title">
                    <span [ngClass]="appointmentItem?.property_detail?.prop_status?.title==='Available' ? 'property_badge_sell_ available_tag' : appointmentItem?.property_detail?.prop_status?.title==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{appointmentItem?.property_detail?.prop_status?.title}}</span>
                  </div>

                  <div class="p_details">
                    <div class="p_details_top">
                      <div class="price_div">
                        <span class="set_width">
                          {{ appointmentItem.property_detail.property_name }} {{appointmentItem.property_detail.building}} {{appointmentItem.property_detail.street}} {{appointmentItem.property_detail.address_line_1}} {{appointmentItem.property_detail.address_line_2}} {{appointmentItem.property_detail.locality_title}} {{(appointmentItem.property_detail.City ? appointmentItem.property_detail.city.city : '')}}
                        </span>
                          <div class="mobile-hide">
                          <div class="appointmentDetails">
                            {{ appointmentItem.appo_date }} {{ appointmentItem.appo_time }}
                            <span class="active_appo" *ngIf="appointmentItem.status == 1">Active</span>
                            <span class="done_appo" *ngIf="appointmentItem.status == 3">Done</span>
                            <span class="missed_appo" *ngIf="appointmentItem.status == 4">Missed</span>
                            <span class="cancelled_appo" *ngIf="appointmentItem.status == 5">Cancelled</span>
                          </div>
                          <span *ngIf="appointmentItem.status == 1 && appointmentItem?.property_detail?.prop_status?.title!='Sold' && appointmentItem?.property_detail?.prop_status?.title!='Under offer'" (click)="onCancelAppointmentClick(appointmentItem.id)" class="cancelAppointment">Cancel Appointment</span>
                        </div>
                        <div class="recently-mob mobile-show">
                          <div class="appointmentDetails">
                            {{ appointmentItem.appo_date }} {{ appointmentItem.appo_time }}
                            <span class="active_appo" *ngIf="appointmentItem.status == 1">Active</span>
                            <span class="done_appo" *ngIf="appointmentItem.status == 3">Done</span>
                            <span class="missed_appo" *ngIf="appointmentItem.status == 4">Missed</span>
                            <span class="cancelled_appo" *ngIf="appointmentItem.status == 5">Cancelled</span>
                          </div>
                          <span *ngIf="appointmentItem.status == 1 && appointmentItem?.property_detail?.prop_status?.title!='Sold' && appointmentItem?.property_detail?.prop_status?.title!='Under offer'" (click)="onCancelAppointmentClick(appointmentItem.id)" class="cancelAppointment">Cancel Appointment</span>
                        </div>
                      </div>
                      <p class="address">
                        <i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.asking_price_in_word }}

                      </p>
                      <p class="card-text">
                        <span class="pro_spec" *ngIf="appointmentItem.property_detail.total_showers"> <i class="fa fa-bath"></i> {{(appointmentItem.property_detail.total_showers ? appointmentItem.property_detail.total_showers : 0)}} Bath </span>
                        <span class="pro_spec" *ngIf="appointmentItem.property_detail.total_bedrooms"> <i class="fa fa-bed"></i> {{(appointmentItem.property_detail.total_bedrooms ? appointmentItem.property_detail.total_bedrooms : 0)}} Bed </span>
                        <span class="pro_spec" *ngIf="appointmentItem.property_detail.super_builtup"> <i class="fa fa-check-square"></i> {{ appointmentItem.property_detail.super_builtup }} Sq.Ft. </span>
                      </p>
                    </div>
                    <div class="p_details_bottom" (click)="openInNewTab(appointmentItem,appointmentItem.property_detail.id,'property')">
                      <ul class="prop_more_details">
                        <li *ngIf="appointmentItem.property_detail.transaction_name">
                          <h5>Type</h5>
                          <p>{{ appointmentItem.property_detail.transaction_name }}</p>
                        </li>
                        <li *ngIf="appointmentItem.property_detail.rate_sqft">
                          <h5>Price per SQ.FT</h5>
                          <p><i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.rate_sqft }}</p>
                        </li>
                        <li *ngIf="appointmentItem.property_detail.created">
                          <h5>Year Built</h5>
                          <p>{{ appointmentItem.property_detail.created | date : "y" }}</p>
                        </li>
                        <li *ngIf="appointmentItem.property_detail.property_ownership">
                          <h5>Ownership</h5>
                          <p>{{ appointmentItem.property_detail.property_ownership }}</p>
                        </li>
                        <li *ngIf="appointmentItem.property_detail.transfer_fees">
                          <h5>HOA Dues</h5>
                          <p><i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.transfer_fees }}</p>
                        </li>
                        <li *ngIf="appointmentItem.property_detail.current_rent">
                          <h5>Lot Size</h5>
                          <p><i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.current_rent }}</p>
                        </li>
                        <li *ngIf="appointmentItem.property_detail.furnished_type">
                          <h5>Furnished</h5>
                          <p>{{ appointmentItem.property_detail.furnished_type }}</p>
                        </li>
                        <li *ngIf="appointmentItem.property_detail.locality_title">
                          <h5>Locality</h5>
                          <p>{{ appointmentItem.property_detail.locality_title }}</p>
                        </li>
                        <li *ngIf="appointmentItem.type_of_appointment === 0">
                          <h5>Appointment Type</h5>
                          <p>Tour in Person</p>
                        </li>
                        <li *ngIf="appointmentItem.type_of_appointment === 1">
                          <h5>Appointment Type</h5>
                          <p>Tour via Video Chat</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div id="new" class="tab-pane fade mt-3">
              <ng-container *ngIf="isLoading == false">
                <div class="empty-message" *ngIf="projectList.length == 0">
                  <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                  <div class="empty-text">
                    <h5>You have no appointments</h5>
                    <p>Add homes to your appointment to help you plan out your home search.</p>
                  </div>
                  <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                </div>
              </ng-container>
              <ng-container *ngFor="let appointProjectItem of projectList">
                <div *ngIf="isLoading == false"  [ngClass]="appointProjectItem?.project_detail?.project_status=='Sold' ? 'account-list-property remove_hand_cursor':'account-list-property'">
                  <div class="p_image_view" (click)="openInNewTab(appointProjectItem,appointProjectItem.project_detail.id,'project')" [style.background-image]="'url(\'' + appointProjectItem.project_detail.image_preview + '\')'"></div>
                  <div class="property_badge_ top_left_" *ngIf="appointProjectItem?.project_detail?.project_status">
                    <span [ngClass]="appointProjectItem?.project_detail?.project_status==='Available' ? 'property_badge_sell_ available_tag' : appointProjectItem?.project_detail?.project_status==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{appointProjectItem?.project_detail?.project_status}}</span>
                    <!-- <span class="property_badge_sell_ neg">Under Negotiation</span> -->
                  </div>
                  <div class="p_details">
                    <div class="p_details_top">
                      <div class="price_div">
                        <span class="set_width"  >
                        {{ appointProjectItem.project_detail.project_name }} {{appointProjectItem.project_detail.building}} {{appointProjectItem.project_detail.street}} {{appointProjectItem.project_detail.address_line_1}} {{appointProjectItem.project_detail.address_line_2}} {{appointProjectItem.project_detail.locality_title}} {{(appointProjectItem.project_detail.City ? appointProjectItem.project_detail.city.city : '')}}
                      &nbsp;
                        <span *ngIf="appointProjectItem.project_unit_type"> ({{ appointProjectItem.project_unit_type }})</span>
                      </span>

                        <div class="mobile-hide">
                          <div class="appointmentDetails">
                            {{ appointProjectItem.appo_date }} {{ appointProjectItem.appo_time }}
                            <span class="active_appo" *ngIf="appointProjectItem.status == 1">Active</span>
                            <span class="done_appo" *ngIf="appointProjectItem.status == 3">Done</span>
                            <span class="missed_appo" *ngIf="appointProjectItem.status == 4">Missed</span>
                            <span class="cancelled_appo" *ngIf="appointProjectItem.status == 5">Canceled</span>
                          </div>
                          <span *ngIf="appointProjectItem.status == 1 && appointProjectItem?.project_detail?.project_status!='Sold' && appointProjectItem?.project_detail?.project_status!='Under offer'" (click)="onCancelProjectAppointment(appointProjectItem.id)" class="cancelAppointment">Cancel Appointment</span>
                        </div>
                        <div class="recently-mob mobile-show">
                          <div class="appointmentDetails">
                            {{ appointProjectItem.appo_date }} {{ appointProjectItem.appo_time }}
                            <span class="active_appo" *ngIf="appointProjectItem.status == 1">Active</span>
                            <span class="done_appo" *ngIf="appointProjectItem.status == 3">Done</span>
                            <span class="missed_appo" *ngIf="appointProjectItem.status == 4">Missed</span>
                            <span class="cancelled_appo" *ngIf="appointProjectItem.status == 5">Canceled</span>
                          </div>
                          <span *ngIf="appointProjectItem.status == 1 && appointProjectItem?.project_detail?.project_status!='Sold' && appointProjectItem?.project_detail?.project_status!='Under offer'" (click)="onCancelProjectAppointment(appointProjectItem.id)" class="cancelAppointment">Cancel Appointment</span>
                        </div>
                      </div>
                      <p class="address" *ngIf="appointProjectItem?.project_detail.show_price_on_website === 1">
                        <i class="fa fa-rupee"></i>{{ appointProjectItem.project_detail.minMaxData.min_rate_title }} - {{ appointProjectItem.project_detail.minMaxData.max_rate_title }}
                      </p>
                      <p class="card-text">
                        <span class="pro_spec" *ngIf="appointProjectItem.project_detail.total_showers"> <i class="fa fa-bath"></i> {{(appointProjectItem.project_detail.total_showers ? appointProjectItem.project_detail.total_showers : 0)}} Bath </span>
                        <span class="pro_spec" *ngIf="appointProjectItem.project_detail.total_bedrooms"> <i class="fa fa-bed"></i> {{(appointProjectItem.project_detail.total_bedrooms ? appointProjectItem.project_detail.total_bedrooms : 0)}} Bed </span>
                        <span class="pro_spec" *ngIf="appointProjectItem.project_detail.super_builtup"> <i class="fa fa-check-square"></i> {{ appointProjectItem.project_detail.super_builtup }} Sq.Ft. </span>
                      </p>
                    </div>
                    <div class="p_details_bottom" (click)="openInNewTab(appointProjectItem,appointProjectItem.project_detail.id,'project')">
                      <ul class="prop_more_details">
                        <li *ngIf="appointProjectItem.project_detail.transaction_name">
                          <h5>Type</h5>
                          <p>{{ appointProjectItem.project_detail.transaction_name }}</p>
                        </li>
                        <li *ngIf="appointProjectItem.project_detail.rate_sqft">
                          <h5>Price per SQ.FT</h5>
                          <p><i class="fa fa-rupee"></i>{{ appointProjectItem.project_detail.rate_sqft }}</p>
                        </li>
                        <li *ngIf="appointProjectItem.project_detail.created">
                          <h5>Year Built</h5>
                          <p>{{ appointProjectItem.project_detail.created | date : "y" }}</p>
                        </li>

                        <li *ngIf="appointProjectItem.project_detail.furnished_type">
                          <h5>Furnished</h5>
                          <p>{{ appointProjectItem.project_detail.furnished_type }}</p>
                        </li>
                        <li *ngIf="appointProjectItem.project_detail.locality_title">
                          <h5>Locality</h5>
                          <p>{{ appointProjectItem.project_detail.locality_title }}</p>
                        </li>
                        <li *ngIf="appointProjectItem.type_of_appointment === 0">
                          <h5>Appointment Type</h5>
                          <p>Tour in Person</p>
                        </li>
                        <li *ngIf="appointProjectItem.type_of_appointment === 1">
                          <h5>Appointment Type</h5>
                          <p>Tour via Video Chat</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="dashboardType == 'Seller' ">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">My Appointments</h4>
        <div class="open-house">
        <ng-container *ngFor="let sellerAppointmentItem of sellerAppointmentList">
          <div *ngIf="isLoading == false" class="account-list-property">
            <div class="p_image_view" (click)="openInNewTab(sellerAppointmentItem,sellerAppointmentItem.property_detail.id,'property')" [style.background-image]="'url(\'' + sellerAppointmentItem.property_detail.image_preview + '\')'"></div>
            <div class="p_details">
              <div class="p_details_top">
                <div class="price_div">
                  <span><i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.asking_price_in_word }}</span>
                  <div class="mobile-hide">
                    <div class="appointmentDetails">
                      {{ sellerAppointmentItem.appo_date }} {{ sellerAppointmentItem.appo_time }}
                      <span class="active_appo" *ngIf="sellerAppointmentItem.property_detail.status == 1">Active</span>
                      <span class="done_appo" *ngIf="sellerAppointmentItem.property_detail.status == 3">Done</span>
                      <span class="missed_appo" *ngIf="sellerAppointmentItem.property_detail.status == 4">Missed</span>
                      <span class="cancelled_appo" *ngIf="sellerAppointmentItem.property_detail.status == 5">Canceled</span>
                    </div>
                    <span *ngIf="sellerAppointmentItem.appo_from_type == 'seller'" (click)="onCancelAppointmentClick(sellerAppointmentItem.property_detail.id)" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel</span>
                    <button *ngIf="sellerAppointmentItem.appo_from_type == 'buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0" class="btn accept_btn green-btn" (click)="onAcceptAppointmentClick(sellerAppointmentItem.id,sellerAppointmentItem.appo_from_type)" >Accept</button>
                    <button *ngIf="sellerAppointmentItem.appo_from_type == 'buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0" class="btn reschedule_btn red-btn"  (click)="onRescheduleAppointmentClick(sellerAppointmentItem.property_detail.id,sellerAppointmentItem.assign_to_user_id,sellerAppointmentItem.franchisee_id)" >Reschedule</button>
                  </div>
                  <div class="recently-mob mobile-show">
                    <div class="appointmentDetails">
                      {{ sellerAppointmentItem.appo_date }} {{ sellerAppointmentItem.appo_time }}
                      <span class="active_appo" *ngIf="sellerAppointmentItem.property_detail.status == 1">Active</span>
                      <span class="done_appo" *ngIf="sellerAppointmentItem.property_detail.status == 3">Done</span>
                      <span class="missed_appo" *ngIf="sellerAppointmentItem.property_detail.status == 4">Missed</span>
                      <span class="cancelled_appo" *ngIf="sellerAppointmentItem.property_detail.status == 5">Canceled</span>
                    </div>
                    <span *ngIf="sellerAppointmentItem.appo_from_type == 'seller'" (click)="onCancelAppointmentClick(sellerAppointmentItem.property_detail.id)" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel</span>
                    <button *ngIf="sellerAppointmentItem.appo_from_type == 'buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0" class="btn accept_btn green-btn" (click)="onAcceptAppointmentClick(sellerAppointmentItem.id,sellerAppointmentItem.appo_from_type)" >Accept</button>
                    <button *ngIf="sellerAppointmentItem.appo_from_type == 'buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0" class="btn reschedule_btn red-btn"  (click)="onRescheduleAppointmentClick(sellerAppointmentItem.property_detail.id,sellerAppointmentItem.assign_to_user_id,sellerAppointmentItem.franchisee_id)" >Reschedule</button>
                  </div>
                  </div>
                <p class="address">
                  {{ sellerAppointmentItem.property_detail.property_name }} {{sellerAppointmentItem.property_detail.building}} {{sellerAppointmentItem.property_detail.street}} {{sellerAppointmentItem.property_detail.address_line_1}} {{sellerAppointmentItem.property_detail.address_line_2}} {{sellerAppointmentItem.property_detail.locality_title}} {{(sellerAppointmentItem.property_detail.City ? sellerAppointmentItem.property_detail.city.city : '')}}
                </p>
                <p class="card-text">
                  <span class="pro_spec" *ngIf="sellerAppointmentItem.property_detail.total_showers"> <i class="fa fa-bath"></i> {{(sellerAppointmentItem.property_detail.total_showers ? sellerAppointmentItem.property_detail.total_showers : 0)}} Bath </span>
                  <span class="pro_spec" *ngIf="sellerAppointmentItem.property_detail.total_bedrooms"> <i class="fa fa-bed"></i> {{(sellerAppointmentItem.property_detail.total_bedrooms ? sellerAppointmentItem.property_detail.total_bedrooms : 0)}} Bed </span>
                  <span class="pro_spec" *ngIf="sellerAppointmentItem.property_detail.super_builtup"> <i class="fa fa-check-square"></i> {{ sellerAppointmentItem.property_detail.super_builtup }} Sq.Ft. </span>
                </p>
              </div>
              <div class="p_details_bottom"  (click)="openInNewTab(sellerAppointmentItem,sellerAppointmentItem.property_detail.id,'property')">
                <ul class="prop_more_details">
                  <li *ngIf="sellerAppointmentItem.property_detail.transaction_name">
                    <h5>Type</h5>
                    <p>{{ sellerAppointmentItem.property_detail.transaction_name }}</p>
                  </li>
                  <li *ngIf="sellerAppointmentItem.property_detail.rate_sqft">
                    <h5>Price per SQ.FT</h5>
                    <p><i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.rate_sqft }}</p>
                  </li>
                  <li *ngIf="sellerAppointmentItem.property_detail.created">
                    <h5>Year Built</h5>
                    <p>{{ sellerAppointmentItem.property_detail.created | date : "y" }}</p>
                  </li>
                  <li *ngIf="sellerAppointmentItem.property_detail.property_ownership">
                    <h5>Ownership</h5>
                    <p>{{ sellerAppointmentItem.property_detail.property_ownership }}</p>
                  </li>
                  <li *ngIf="sellerAppointmentItem.property_detail.transfer_fees">
                    <h5>HOA Dues</h5>
                    <p><i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.transfer_fees }}</p>
                  </li>
                  <li *ngIf="sellerAppointmentItem.property_detail.current_rent">
                    <h5>Lot Size</h5>
                    <p><i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.current_rent }}</p>
                  </li>
                  <li *ngIf="sellerAppointmentItem.property_detail.furnished_type">
                    <h5>Furnished</h5>
                    <p>{{ sellerAppointmentItem.property_detail.furnished_type }}</p>
                  </li>
                  <li *ngIf="sellerAppointmentItem.property_detail.locality_title">
                    <h5>Locality</h5>
                    <p>{{ sellerAppointmentItem.property_detail.locality_title }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      </div>
    </div>
  </ng-container>
  </div>
</div>
