import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { API_BASE_URL, API_ENDPOINTS } from '../global';
import { FlashService } from './FlashService.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private httpClient: HttpClient, private FlashService: FlashService, private router: Router) {}

  // ** Start Login API **/
  public login(credentials) {
    return this.httpClient.post<any>(API_ENDPOINTS.API_LOGIN_URL, credentials).pipe(
      map(result => {
        var apiResult = result;
        if(result.success) {
          var usr = result.data.username;
          var name = result.data.name;
          result = result.message;
          this.FlashService.success(result);
          localStorage.setItem('currentUser', JSON.stringify(apiResult.data));

          const firstName=apiResult.data.first_name !=="undefined" && apiResult.data.first_name !=="null" ? apiResult.data.first_name : ''
          const lastName=apiResult.data.last_name !=="undefined" && apiResult.data.last_name !=="null" ? apiResult.data.last_name : ''

          localStorage.setItem('username', firstName+' '+lastName);
        } else {
          if(apiResult.error && apiResult.error_code !== "err-000040") {
            result = result.error_code;
            this.FlashService.error(result);
          }
        }
        return apiResult;
      })
    );
  }
  // ** End Login API **/

  // ** Start Forgot Password API **/
  public forgotPassword(credentials) {
    return this.httpClient.post<any>(API_ENDPOINTS.API_FORGOT_PASSWORD, credentials).pipe(
      map(result => {
        var apiResult = result;
        if(result.success) {
          result = result.message;
          this.FlashService.success(result);
        } else {
          if(apiResult.error && apiResult.error_code !== "err-000040") {
            result = result.error_code;
            this.FlashService.error(result);
          }
        }
        return apiResult;
      })
    );
  }
  // ** End Forgot Password API **/

  // ** Start Register API **/
  public register(credentials) {
    return this.httpClient.post<any>(API_ENDPOINTS.API_REGISTER_URL, credentials).pipe(
      map(result => {
        var apiResult = result;
        if(result.success) {
          result = result.message;
          this.FlashService.success(result);
        } else {
          result= result.error_code;
          this.FlashService.error(result);
        }
        return apiResult;
      })
    );
  }
  // ** End Register API **/

  // ** Start Submit Job Application API **/
  public jobAppplication(credentials) {
    return this.httpClient.post<any>(API_ENDPOINTS.API_JOB_APPLICATION, credentials).pipe(
      map(result => {
        var apiResult = result;
        if(result.success) {
          result = result.message;
          this.FlashService.success(result);
        } else {
          result= result.error_code;
          this.FlashService.error(result);
        }
        return apiResult;
      })
    );
  }
  // ** End Submit Job Application API **/

  // ** Start Find Property API **/
  public findProperties(credentials) {
    return this.httpClient.post<any>(API_ENDPOINTS.API_FIND_PROPERTIES, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Find Property API **/

  // ** Start Find Projects API **/
  public findProjects(credentials) {
    return this.httpClient.post<any>(API_ENDPOINTS.API_FIND_PROJECTS, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Find Projects API **/

  // ** Start Get All Amenities API **/
  public getAmenities(credentials, propertytypeid = null) {
    let url = '';
    if (propertytypeid) {
      url = API_BASE_URL + '/master/getAmenities/' + propertytypeid + '?is_web_api=1';
    } else {
      url = API_ENDPOINTS.API_GET_AMENITIES;
    }
    return this.httpClient.get<any>(url, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** Start Get All Amenities API **/

  // ** Start Get All Property Facing API **/
  public getFacing(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROPERTY_FACING, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** Ends Get All Property Facing API **/

  // ** Start Get All Property Facing API **/
  public getPropertyAge(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROPERTY_AGE, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** Ends Get All Property Facing API **/

  // ** Start Get All Property Types API **/
  public getPropertyTypes(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROPERTY_TYPE, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Property Type API **/

  // ** Start Get All Property Status API **/
  public getPropertyStatus(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROPERTY_STATUS, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Property Status API **/

  // ** Start Get All Property Facts API **/
  public getallPropertyfacts(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROPERTY_FACTS, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Property Facts API **/

  // ** Start Check is user logged in **/
  public loginCheck() {
    if(localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }
  // ** End Check is user logged in **/

  // ** Start Check is user logged in **/
  public mobileVerificationCheck() {
    var userData = JSON.parse(localStorage.getItem('currentUser'));
    if(userData.contact_no == "") {
      return false;
    } else {
      if(userData.contact_no_verified) {
        return true;
      } else {
        return false;
      }
    }
  }
  // ** End Check is user logged in **/

  // ** Start Do POST API Request **/
  public postRequest(url, data) {
    var currentAppUser = <any>localStorage.getItem('currentUser');
    if (currentAppUser) {
      currentAppUser = JSON.parse(currentAppUser);
      data.append('userid', currentAppUser.id);
      data.append('role_id', currentAppUser.role_id);
      // data.append('franchisee_id', currentAppUser.franchisee_id);
    }
    data.append('is_web_api', 1);
    return this.httpClient.post(url, data, {}).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
  // ** END Do POST API Request **/

  // ** Start Do GET API Request **/
  public getRequest(url, credentials) {
    return this.httpClient.get<any>(url, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** END Do GET API Request **/

  // ** Start Get All Property Category API **/
  public getPropertyCategory(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROPERTY_CATEGORY + '&property_type_id=' + credentials.property_type_id + '&is_for_project=' + credentials.is_for_project, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Property Category API **/

  // ** Start Get All Property Dimensions API **/
  public getPropertyDimensions(credentials) {
    let url = '';
    if (credentials && credentials.property_type_id) {
      url = API_BASE_URL + '/master/getPropertyDimension/' + credentials.property_type_id + '?is_web_api=1';
    } else {
      url = API_ENDPOINTS.API_GET_PROPERTY_DIMENSIONS;
    }
    return this.httpClient.get<any>(url, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Property Dimensions API **/

  // ** Start Get All Project Dimensions API **/
  public getProjectDimensions(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROJECT_DIMENSIONS + '&property_type_id=' + credentials.property_type_id, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Property Dimensions API **/

  // ** Start Get All Project Types API **/
  public getProjectTypes(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_PROJECT_TYPE, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Project Type API **/

  // ** Start Get All Land Category API **/
  public getLandCategories(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_LAND_CATEGORY, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Land Category API **/

  // ** Start Get All Land Features API **/
  public getLandFeatures(credentials) {
    return this.httpClient.get<any>(API_ENDPOINTS.API_GET_LAND_FEATURES, credentials).pipe(
      map(result => {
        return result;
      })
    );
  }
  // ** End Get All Land Features API **/

  // ** Start ShareLink API **/
  public shareLink(credentials) {
    var currentAppUser = <any>localStorage.getItem('currentUser');
    if (currentAppUser) {
      currentAppUser = JSON.parse(currentAppUser);
      credentials.append('userid', currentAppUser.id);
    }
    return this.httpClient.post<any>(API_ENDPOINTS.API_SHARE_URL, credentials).pipe(
      map(result => {
        var apiResult = result;
        if(result.success) {
          result = result.message;
          this.FlashService.success(result);
        } else {
          result = result.message;
          this.FlashService.error(result);
        }
        return apiResult;
      })
    );
  }
  // ** End ShareLink API **/

}
