import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuyerParamsGuard implements CanActivate {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (route.queryParams.type !== 'Buyer') {
      let urlHit=state.url.split('?')[0]
      if(urlHit){
        this.router.navigate([urlHit],{queryParams: { type: 'Buyer' }});
      }
    }
  }
}
