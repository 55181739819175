import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS } from '../../global';
import { Meta, Title } from '@angular/platform-browser';
import { FlashService } from '../../_services/FlashService.service';

declare var $: any;
declare const require: any;
declare var google:any;

@Component({
  selector: 'app-reset-password',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  submitted = false;
  email: string = '';
  resetDone: string = '';
  forgot_pass_key: string = '';
  isAddingLead: boolean = false;
  isLeadAdded: boolean = false;
  resetPasswordForm: FormGroup;

  // tslint:disable-next-line:no-shadowed-variable max-line-length
  constructor(private FlashService: FlashService, private route: ActivatedRoute, private ApiService: ApiService, private title: Title,  private formBuilder: FormBuilder, private router :Router) { }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    var componentObj = this;
    this.route.params.subscribe(routeParams => {
      if(this.route.snapshot.queryParams.email !== undefined) {
        this.email = this.route.snapshot.queryParams.email;
      }
      if(this.route.snapshot.queryParams.key !== undefined) {
        this.forgot_pass_key = this.route.snapshot.queryParams.key;
      }
      this.validateUser();

      this.resetPasswordForm = this.formBuilder.group({
        passwordReset: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        confirmPasswordReset: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
      }, {validator: this.ConfirmedValidator('passwordReset', 'confirmPasswordReset')});

    });
    setTimeout (() => {
      this.changeHeaderClass();
    }, 200);
  }

  get g() {
    return this.resetPasswordForm.controls;
  }

  ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  validateUser(){
    var form_data = new FormData();
    form_data.append('email', this.email);
    form_data.append('forgot_pass_key', this.forgot_pass_key);
    this.ApiService.postRequest(API_ENDPOINTS.API_FORGOT_VALIDATE_USER, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        // this.FlashService.success(apiResult.message);
      } else {
        this.resetDone = apiResult.error_code;
        // this.FlashService.error(apiResult.error_code);
        $('#reset_title').hide();
        $('.lead_generator_main').hide();
      }
    }, error => {
      // this.isAddingLead = false;
      // this.FlashService.error("Something wrong!");
    });
  }

  submitUserResetPassword(){
    var componentObj = this;
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let email = this.email;
    let forgot_pass_key = this.forgot_pass_key;
    let password = this.resetPasswordForm.value.passwordReset;
    let form_data = new FormData();
    form_data.append('email', email);
    form_data.append('forgot_pass_key', forgot_pass_key);
    form_data.append('password', password);
    form_data.append('web_api', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_WEB_USER_RESET_PASSWORD, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.router.navigate(['/'])
        this.FlashService.success(apiResult.message);
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      // this.isAddingLead = false;
      // this.FlashService.error("Something wrong!");
    });
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $('#imgHeadLogo').attr('src', 'assets/img/empire_logo.png');
    this.title.setTitle('Empire Realtors » Reset Password');
  }

}
