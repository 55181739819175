<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid account-settings-outer" id="accoutnSettings">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h4 class="page-title">Account Settings</h4>
        <!-- <app-flash></app-flash>  -->
        <div class="account-settings">
          <div class="saveLoadingOverlay" *ngIf="formSubmiting" [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div>
          <form [formGroup]="profileForm" (ngSubmit)="onProfileSubmit()">
            <div class="main-profile-settings">
              <div class="image-div">
                <div class="image-left" [style.background-image]="'url(\'' + profile_image + '\')'"></div>
                <div class="image-right">
                  <label class="image-upload-container">
                    <span>Change Photo</span>
                    <input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)" style="display: none;" />
                  </label>
                </div>
              </div>
              <div class="profile-info-div">
                <h4>Contact Info</h4>
                <div class="row d-flex">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input [(ngModel)]="first_name" formControlName="firstName" class="form-control" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && g.firstName.errors }" />
                      <div *ngIf="submitted && g.firstName.errors" class="invalid-feedback">
                        <div *ngIf="g.firstName.errors.required">First Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input [(ngModel)]="last_name" formControlName="lastName" class="form-control" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && g.lastName.errors }" />
                      <div *ngIf="submitted && g.lastName.errors" class="invalid-feedback">
                        <div *ngIf="g.lastName.errors.required">Last Name is required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-flex">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="email" [ngModel]="email" readonly formControlName="profileEmail" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && g.profileEmail.errors }" />
                      <div *ngIf="submitted && g.profileEmail.errors" class="invalid-feedback">
                        <div *ngIf="g.profileEmail.errors.required">Email is required</div>
                        <div *ngIf="g.profileEmail.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input [(ngModel)]="phone" formControlName="mobileNumber" class="form-control" [pattern]="mobNumberPattern" placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && g.mobileNumber.errors }" />
                      <div *ngIf="submitted && g.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="g.mobileNumber.errors.required">Mobile Number is required</div>
                        <div *ngIf="g.mobileNumber.errors.pattern || g.mobileNumber.errors.minlength || g.mobileNumber.errors.maxlength">Please enter valid Mobile Number</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-profile-settings">
              <div class="image-div">&nbsp;</div>
              <div class="profile-info-div">
                <h4>Address Info</h4>
                <div class="row d-flex">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="address_line1" formControlName="addressline1" class="form-control" placeholder="Address Line 1" [ngClass]="{ 'is-invalid': submitted && g.addressline1.errors }" />
                      <div *ngIf="submitted && g.addressline1.errors" class="invalid-feedback">
                        <div *ngIf="g.addressline1.errors.required">Address Line 1 is required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-flex">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input [(ngModel)]="address_line2" formControlName="addressline2" class="form-control" placeholder="Address Line 2" [ngClass]="{ 'is-invalid': submitted && g.addressline2.errors }" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <select [(ngModel)]="state_eloc" formControlName="profilestate" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.profilestate.errors }" (change)="getStateCities($event.target.value)">
                        <option *ngFor="let stateOption of state_options" value="{{ stateOption.id }}">
                            {{ stateOption.title }}
                        </option>
                      </select>
                      <div *ngIf="submitted && g.profilestate.errors" class="invalid-feedback">
                        <div *ngIf="g.profilestate.errors.required">State is required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-flex">
                  <div class="col-md-6">
                    <div class="form-group">
                      <select [(ngModel)]="city_eloc" formControlName="profilecity" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.profilecity.errors }">
                        <option *ngFor="let cityOption of city_options" value="{{ cityOption.id }}">
                            {{ cityOption.title }}
                        </option>
                      </select>
                      <div *ngIf="submitted && g.profilecity.errors" class="invalid-feedback">
                        <div *ngIf="g.profilecity.errors.required">City is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input id="locality_search" formControlName="prolocality" class="form-control" placeholder="Locality" [ngClass]="{ 'is-invalid': submitted && g.prolocality.errors }" />
                    </div>
                  </div>
                </div>
                <div class="row d-flex">
                  <div class="col-md-3">&nbsp;</div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button type="submit" class="btn btn-block login-btn">Save Updates</button>
                    </div>
                  </div>
                  <div class="col-md-3">&nbsp;</div>
                </div>
              </div>
            </div>
          </form>
          <form class="passwordChangeForm" [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()">
            <div class="main-profile-settings">
              <div class="image-div">&nbsp;</div>
              <div class="profile-info-div">
                <h4>Change Password</h4>
                <div class="row d-flex">
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="password" [(ngModel)]="oldPass" formControlName="oldpassword" class="form-control" placeholder="Old Password" [ngClass]="{ 'is-invalid': submittedp && p.oldpassword.errors }" />
                      <div *ngIf="submittedp && p.oldpassword.errors" class="invalid-feedback">
                        <div *ngIf="p.oldpassword.errors.required">Old Password is required</div>
                        <div *ngIf="p.oldpassword.errors.minlength">Password must be at least 6 characters</div>
                        <div *ngIf="p.oldpassword.errors.maxlength">Max Length  20 characters</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="password" [(ngModel)]="newPass" formControlName="newpassword" class="form-control" placeholder="New Password" [ngClass]="{ 'is-invalid': submittedp && p.newpassword.errors }" />
                      <div *ngIf="submittedp && p.newpassword.errors" class="invalid-feedback">
                        <div *ngIf="p.newpassword.errors.required">New Password is required</div>
                        <div *ngIf="p.newpassword.errors.minlength">Password must be at least 6 characters</div>
                        <div *ngIf="p.newpassword.errors.maxlength">Max Length  20 characters</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="password" [(ngModel)]="confPass" formControlName="confirmpassword" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submittedp && p.confirmpassword.errors }" />
                      <div *ngIf="submittedp && p.confirmpassword.errors" class="invalid-feedback">
                        <div *ngIf="p.confirmpassword.errors.required">Confirm Password is required</div>
                        <div *ngIf="p.confirmpassword.errors.minlength">Password must be at least 6 characters</div>
                        <div *ngIf="p.confirmpassword.errors.maxlength">Max Length  20 characters</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-flex">
                  <div class="col-md-3">&nbsp;</div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button type="submit" class="btn btn-block login-btn">Change Password</button>
                    </div>
                  </div>
                  <div class="col-md-3">&nbsp;</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
