/* tslint:disable:quotemark */
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_BASE_URL, API_ENDPOINTS, WEB_URL } from '../../global';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;
declare const require: any;
declare var google: any;

@Component({
  selector: 'app-rentcalculator',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './rentcalculator.component.html',
  styleUrls: ['./rentcalculator.component.scss']
})

export class RentcalCulatorComponent implements OnInit {

  affordText = "You can afford <i class='fa fa-rupee'></i>[AMOUNT]/mo";
  overbudgetText = "You're overbudget at <i class='fa fa-rupee'></i>[AMOUNT]/mo";
  spendLeftText = "You will have <i class='fa fa-rupee'></i>[AMOUNT]/mo left to spend.";
  fitComfortablyText = "Based on your income, a rental at this price should fit comfortably within your budget.";
  expensesExceedText = "Careful! If you spend this much on rent, your debts and expenses will exceed your income.";
  landlordsMoreText = "Generally, landlords require your rent to be no more than one-third of your gross income.";

  rentSliderTitle: string;
  rentSliderText: string;
  rentSliderSpend: string;

  rentSliderValue: number = 10;
  rentSliderOptions: any;

  rentForm: FormGroup;
  locationKeyword = 'placeName';
  locationData: any;
  locationErrorMsg: string;
  locationIsLoadingResult: boolean;
  locationSearch: string = '';
  locationSearchValue: string = '';

  matchingProperties: any = [];
  matchingPropertySlider: any;
  monthlyRent: number = 0;
  isLoggedIn;
  isFavoriting: boolean = false;
  net_income_tax: number;
  gross_income: number;
  net_income: number;
  m_debts: number;
  m_savings: number;
  m_expenses: number;
  isFindingProperty: boolean = false;
  isSliderInitialize: boolean = false;

  slider_color = 'green';
  slider_orange = 'orange';

  constructor(private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private ApiService: ApiService, private title: Title, private meta: Meta, private formBuilder: FormBuilder) {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    var componentObj = this;
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.route.params.subscribe(routeParams => {
      this.rentSliderOptions = {
        floor: 10,
        ceil: 40,
        translate: (value: number): string => {
          return value + "%";
        },
        showSelectionBar: true,
        getSelectionBarColor: (value: number): string => {
          if (value >= 33) {
            return componentObj.slider_orange;
          }
          return componentObj.slider_color;
        }
      };
      this.rentForm = this.formBuilder.group({
        net_income: ['', [Validators.minLength(0), Validators.min(0)]],
        m_debts: ['', [Validators.minLength(0), Validators.min(0)]],
        m_savings: ['', [Validators.minLength(0), Validators.min(0)]],
        m_expenses: ['', [Validators.minLength(0), Validators.min(0)]]
      });
      this.isLoggedIn = this.ApiService.loginCheck();
      setTimeout(() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  locationOnSelectEvent(item) {
    if (item.eLoc) {
      this.locationSearch = "in " + item.eLoc;
      this.locationSearchValue = item.eLoc;
      this.onRentCalPriceChange();
    }
  }

  getLocationServerResponse(event) {
    this.locationIsLoadingResult = true;
    var form_data = new FormData();
    form_data.append('search', event);
    form_data.append('is_web_api', '1');
    this.ApiService.getRequest(API_BASE_URL + "/master/getLocality?search=" + event + "&is_web_api=1", form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        if (apiResult.data.length) {
          this.locationData = apiResult.data;
        } else {
          this.locationData = [];
          this.locationErrorMsg = "Please enter a valid rental location.";
        }
      }
      this.locationIsLoadingResult = false;
    });
  }

  locationSearchCleared() {
    this.locationData = [];
  }

  get g() {
    return this.rentForm.controls;
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Rent Calculator');
  }

  initMatchingPropertyViewSlider() {
    this.matchingPropertySlider = $('#matching_property_view').lightSlider({
      gallery: false,
      item: 4.6,
      loop: false,
      slideMargin: 15,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            item: 1.5
          }
        }
      ]
    });
  }

  getMatchingProperties() {
    this.isFindingProperty = true;
    var form_data = new FormData();
    form_data.append('property_type_id', '1');
    form_data.append('transaction_type_id', '3');
    form_data.append('locality_elocs', this.locationSearchValue + "");
    form_data.append('max_budget_amount', this.monthlyRent + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.matchingProperties = apiResult.data.list;
        this.isFindingProperty = false;
        if ((apiResult.data.list || []).length > 0) {
          setTimeout(() => {
            if (this.isSliderInitialize) {
              this.matchingPropertySlider.refresh();
            } else {
              this.isSliderInitialize = true;
              this.initMatchingPropertyViewSlider();
            }
          }, 200);
        } else {
          if (this.isSliderInitialize) {
            this.matchingPropertySlider.refresh();
          }
        }
      }
    });
  }

  makeFavoriteOthers(pid) {
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('property_id', pid);
      form_data.append('status', "1");
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          if ($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
          }
        }
      });
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  onRentCalPriceChange() {
    if (this.net_income < 0) {
      this.net_income = 0;
    }
    if (this.m_debts < 0) {
      this.m_debts = 0;
    }
    if (this.m_savings < 0) {
      this.m_savings = 0;
    }
    if (this.m_expenses < 0) {
      this.m_expenses = 0;
    }
    if (this.net_income > 0) {
      this.net_income_tax = Math.round((this.net_income * 25) / 75);
      this.gross_income = this.net_income_tax + this.net_income;
      this.monthlyRent = Math.round((this.net_income * this.rentSliderValue) / 100);
      var spendLeft = this.net_income - this.monthlyRent;
      if (this.m_debts > 0) {
        spendLeft = spendLeft - this.m_debts;
      }
      if (this.m_savings > 0) {
        spendLeft = spendLeft - this.m_savings;
      }
      if (this.m_expenses > 0) {
        spendLeft = spendLeft - this.m_expenses;
      }
      if (spendLeft > 0) {
        if (this.rentSliderValue >= 35) {
          this.rentSliderTitle = this.affordText.replace("[AMOUNT]", this.monthlyRent + "");
          this.rentSliderText = this.landlordsMoreText;
          this.rentSliderSpend = this.spendLeftText.replace("[AMOUNT]", spendLeft + "");
        } else {
          this.rentSliderTitle = this.affordText.replace("[AMOUNT]", this.monthlyRent + "");
          this.rentSliderText = this.fitComfortablyText;
          this.rentSliderSpend = this.spendLeftText.replace("[AMOUNT]", spendLeft + "");
        }
        this.slider_color = 'green';
        this.slider_orange = 'orange';
      } else {
        this.rentSliderTitle = this.overbudgetText.replace("[AMOUNT]", this.monthlyRent + "");
        this.rentSliderText = this.expensesExceedText;
        this.rentSliderSpend = this.spendLeftText.replace("[AMOUNT]", spendLeft + "");
        this.slider_color = 'red';
        this.slider_orange = 'red';
      }
    }
    if (this.net_income > 0 && this.locationSearch != "") {
      this.matchingProperties = [];
      this.getMatchingProperties();
    }
  }

  viewallMatching() {
    var data = {};
    data['transaction_type_id'] = '3';
    data['property_type_id'] = '1';
    data['locality_elocs'] = this.locationSearchValue + "";
    data['max_budget_amount'] = this.monthlyRent + "";

    const url = this.router.createUrlTree(['property'], { queryParams: data })
    window.open(url.toString(), '_blank')
    // this.router.navigate(['property'], {queryParams: data});
  }

  openPropertyDetails(id) {
    window.open(WEB_URL + '/property-details/' + id, '_blank')
  }
}
