<div class="container-fluid" id="singleProperty">
  <div class="container">
    <!-- Start Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li>
            <a href="/"> Home </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="propertyInArea(propertyinfo.locality_title)"> Property for sale in {{propertyinfo.locality_title}}</a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> {{propertyinfo.display_name}} </span>
          </li>
          
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <!-- Start Property header -->
    <div class="row property_head">
      <div class="col-md-6">
        <h4>{{propertyinfo.display_name}}</h4>
        <h6>
          {{propertyinfo.locality_title}} {{(propertyinfo.City ? propertyinfo.city.city : '')}}
          <span id="header_emi_tag" *ngIf="propertyinfo.transaction_type_id != 3"></span>
        </h6>

        <h6><b>Property ID</b>&nbsp;:&nbsp;{{propertyinfo.id || ''}}</h6>
      </div>
      <div class="{{propertyinfo.saleable_area ? 'col-md-3' : 'col-md-2'}}">
        <ul class="prop_other_info">
          <li>
            <h4 *ngIf="propertyinfo.asking_price_in_word"><i
              class="fa fa-rupee"></i> {{propertyinfo.asking_price_in_word}}</h4>
            <button class="btn btn-sm close-price-btn" (click)="scrollTo('tax_info')" *ngIf="propertyinfo.closing_price_title">
              Closing Price
            </button>
          </li>
          <li *ngIf="propertyinfo.saleable_area">
            <h4>{{propertyinfo.saleable_area}}</h4>
            <p>Sq.Ft</p>
          </li>
          <li>
            <h4 *ngIf="propertyinfo.total_bedrooms">{{(propertyinfo.total_bedrooms ? propertyinfo.total_bedrooms : 0)}}</h4>
            <p *ngIf="propertyinfo.total_bedrooms">Bed</p>
          </li>
          <li>
            <h4 *ngIf="propertyinfo.total_showers">{{(propertyinfo.total_showers ? propertyinfo.total_showers : 0)}}</h4>
            <p *ngIf="propertyinfo.total_showers">Baths</p>
          </li>
        </ul>
      </div>
      <div class="{{propertyinfo.saleable_area ? 'col-md-3' : 'col-md-4'}}">
        <ul class="prop_icon_info">
          <li class="like_share_btn">
            <button class="btn btn-common btn-lg heart_btn"
                    [ngClass]="{ 'is_favorite_prop': propertyinfo.is_saved == 1 }"
                    (click)="makeFavorite(propertyinfo.id, propertyinfo.franchisee_id)">
              <i  class="fa fa-heart"></i>
              <!-- <div class="favoriteLoadingOverlay" *ngIf="isFavoriting"
                   [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div> -->
            </button>
            <button type="button" id="shrehide" class="btn btn-common btn-lg share_btn" data-toggle="modal" data-target="#sharePropModal">
              <i class="fa fa-share-alt"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Property header -->
    <!-- Start Property images -->
    <div class="row">
      <div class="col-md-9">
        <div class="project_main_img" (click)="open(0)">
          <div class="property_badge top_left" *ngIf="propertyinfo.prop_status">
            <span class="property_badge_sell badge-color">{{propertyinfo.prop_status.title}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="project_other_img">
          <div class="project_other_img_1" (click)="open(0)"></div>
          <div class="project_other_img_2" (click)="open(0)"><span class="more_images_3 hidden"></span></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">

        <!--<div class="row property_gallery" *ngif="showPropImages">
          <div class="col-md-12">
            <ul id="lightSlider"></ul>
          </div>
        </div>-->
        <!-- End Property images -->
        <!-- Start Property images -->
        <div class="row property_more_info">
          <div class="col-md-12">
            <ul class="nav nav-tabs prop_info_tabs" role="tablist">
              <li class="nav-item" *ngIf="_video && _video.length > 0">
                <span class="nav-link active" id="property_video_tab" (click)="scrollTo('property_video')"
                      role="tab" aria-controls="property_video"
                      aria-selected="true">Property Video</span>
              </li>
              <li class="nav-item">
                <span class="nav-link active" id="property_description_tab" (click)="scrollTo('property_description')"
                      role="tab" aria-controls="property_description"
                      aria-selected="true">Description of property</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_dimentions_tab" (click)="scrollTo('property_dimentions')" role="tab"
                      aria-controls="property_dimentions" aria-selected="false">Property Dimensions</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_detail_tab" (click)="scrollTo('property_detail')" role="tab"
                      aria-controls="property_detail" aria-selected="false">Property Detail</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_amenities_tab" (click)="scrollTo('property_amenities')" role="tab"
                      aria-controls="property_amenities" aria-selected="false">Amenities</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_nearby_tab" (click)="scrollTo('property_nearby')" role="tab"
                      aria-controls="property_nearby" aria-selected="false">Near By Landmarks</span>
              </li>
            </ul>
            <div class="tab-content prop_info_tab_content">
              <div class="tab-content prop_info_tab_content" *ngIf="_video && _video.length > 0">
                <div class="tab-pane fade show active" id="property_video" role="tabpanel"
                     aria-labelledby="property_video_tab">
                  <h5>Property Videos</h5>
                  <div class="d-flex row">
                    <ul>
                      <li class="li-display" *ngFor="let video of _video;">
                        <iframe [src]="video" width="280" height="200" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_description" role="tabpanel"
                   aria-labelledby="property_description_tab">
                <h5>Description of property</h5>
                <div
                  class="property_description_contant">{{(propertyinfo.description ? propertyinfo.description : 'Description not found.')}}</div>
              </div>

              <div class="tab-pane fade show active" id="property_dimentions" role="tabpanel"
                   aria-labelledby="property_dimentions_tab">
                <h5>Property Dimensions</h5>
                <div class="p_details_accordians">
                  <div class="accordion_outer no-border">
                    <div class="accordion_in_content">
                      <div class="row d-flex">
                        <div class="col-md-6"
                             *ngIf="propertyinfo.living_room_width || propertyinfo.living_room_flooring">
                          <h4>Living Room Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.living_room_width">
                              Dimension : {{(propertyinfo.living_room_width ? propertyinfo.living_room_width : '-')}}
                              x {{(propertyinfo.living_room_length ? propertyinfo.living_room_length : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.living_room_flooring">
                              Flooring : {{(propertyinfo.living_room_flooring ? propertyinfo.living_room_flooring.title : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.living_room_furniture">
                              Furniture : <span *ngFor="let itemF of propertyinfo.living_room_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                            <li *ngIf="!propertyinfo.living_room_width && !propertyinfo.living_room_flooring && !propertyinfo.living_room_furniture">
                              No Data
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6"
                             *ngIf="propertyinfo.kitchen_width || propertyinfo.kitchen_flooring">
                          <h4>Kitchen Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.kitchen_width">
                              Dimension : {{(propertyinfo.kitchen_width ? propertyinfo.kitchen_width : '-')}}
                              x {{(propertyinfo.kitchen_length ? propertyinfo.kitchen_length : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.kitchen_flooring">
                              Flooring : {{(propertyinfo.kitchen_flooring ? propertyinfo.kitchen_flooring.title : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.kitchen_furniture">
                              Furniture : <span *ngFor="let itemF of propertyinfo.kitchen_furniture; index as i"><span
                              *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6" *ngFor="let itemB of propertyinfo.prop_bedroom; index as bi">
                          <h4>Bedroom {{bi + 1}}</h4>
                          <ul>
                            <li *ngIf="itemB.width">
                              Dimension : {{(itemB.width ? itemB.width : '-')}}
                              x {{(itemB.length ? itemB.length : '-')}}
                            </li>
                            <li *ngIf="itemB.bedroom_flooring">
                              Flooring : {{(itemB.bedroom_flooring ? itemB.bedroom_flooring.title : '-')}}
                            </li>
                            <li *ngIf="itemB.bedroom_furniture">
                              Furniture : <span *ngFor="let itemF of itemB.bedroom_furniture; index as i"><span
                              *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                            <li>Attached Bath : {{(itemB.attached_bathroom == 'Yes' ? 'Yes' : 'No')}}</li>
                            <li *ngIf="itemB.attached_bathroom == 'Yes'">Bath Dimension
                              : {{(itemB.bathroom_width ? itemB.bathroom_width : '-')}}
                              x {{(itemB.bathroom_length ? itemB.bathroom_length : '-')}}</li>
                          </ul>
                        </div>
                        <div class="col-md-6" *ngFor="let itemD of propertyinfo.prop_dimension; index as di">
                          <h4>{{itemD.dimension.title}}</h4>
                          <ul>
                            <li>Dimension : {{(itemD.width ? itemD.width : '-')}}
                              x {{(itemD.length ? itemD.length : '-')}}</li>
                            <li>Flooring : {{(itemD.bedroom_flooring ? itemD.bedroom_flooring.title : '-')}}</li>
                            <li>Furniture : <span *ngFor="let itemF of itemD.bedroom_furniture; index as i"><span
                              *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span></li>
                            <li>Attached Bath : {{(itemD.attached_bathroom == 'Yes' ? 'Yes' : 'No')}}</li>
                            <li *ngIf="itemD.attached_bathroom == 'Yes'">Bath Dimension
                              : {{(itemD.bathroom_width ? itemD.bathroom_width : '-')}}
                              x {{(itemD.bathroom_length ? itemD.bathroom_length : '-')}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_detail" role="tabpanel" aria-labelledby="property_detail_tab">
                <h5>Property details</h5>
                <div class="p_details_accordians">
                  <div class="accordion_outer">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#property_lot" aria-expanded="true"
                        aria-controls="property_lot">Property / Lot Details <i class="fa fa-minus"></i><i
                      class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="property_lot">
                      <div class="row d-flex">
                        <div class="col-md-6" *ngIf="propertyinfo.total_bedrooms || propertyinfo.prop_transaction_type">
                          <h4>Property Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.total_bedrooms">
                              No. of Beds: {{propertyinfo.total_bedrooms ? propertyinfo.total_bedrooms : '-'}}
                            </li>
                            <li *ngIf="propertyinfo.prop_transaction_type">
                              For {{propertyinfo.prop_transaction_type.title}}</li>
                          </ul>
                        </div>
                        <div class="col-md-6" *ngIf="propertyinfo.super_builtup || propertyinfo.builtup || propertyinfo.carpet_area_unit">
                          <h4>Land Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.super_builtup">
                              Super Built Up: {{propertyinfo.super_builtup ? propertyinfo.super_builtup + ' ' + propertyinfo.super_builtup_area_unit.title : '-'}}</li>
                            <li *ngIf="propertyinfo.builtup">
                              Built Up: {{propertyinfo.builtup ? propertyinfo.builtup + ' ' + propertyinfo.builtup_area_unit.title : '-'}}</li>
                            <li *ngIf="propertyinfo.carpet">
                              Carpet: {{propertyinfo.carpet ? propertyinfo.carpet + ' ' + propertyinfo.carpet_area_unit.title : '-'}}</li>
                            <li *ngIf="!propertyinfo.builtup && !propertyinfo.super_builtup && !propertyinfo.carpet">
                              No Data
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion_outer" *ngIf="propertyinfo.prop_school && propertyinfo.prop_school.length > 0">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#virtual_tour" aria-expanded="true"
                        aria-controls="virtual_tour">School Information<i class="fa fa-minus"></i><i
                      class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="virtual_tour">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>School Information</h4>
                          <ul *ngFor="let school of propertyinfo.prop_school;">
                            <li>{{school.placeName}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion_outer" *ngIf="propertyinfo.prop_feature && propertyinfo.prop_feature.length > 0">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#interior_features"
                        aria-expanded="true" aria-controls="interior_features">Unique Features <i
                      class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="interior_features">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>Utility Information</h4>
                          <ul *ngFor="let feature of propertyinfo.prop_feature;">
                            <li>{{feature.feature.title}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion_outer" id="tax_info" *ngIf="propertyinfo.prop_tax">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#tax_asses" aria-expanded="true"
                        aria-controls="tax_asses">Taxes / Financial Information <i class="fa fa-minus"></i><i
                      class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="tax_asses">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>Financial Information</h4>
                          <ng-container *ngIf="propertyinfo.prop_tax; else noTaxInfo">
                            <!-- Taxes Informartion for Rent / Lease Properties -->
                            <ng-container *ngIf="propertyinfo.transaction_type_id === 3; else sellPropTaxes">
                              <table class="tax_table" style="width: 100%;">
                                <tbody>
                                <tr *ngIf="propertyinfo.security_deposite">
                                  <td class="tax_info" style="width: 70%;">Deposite</td>
                                  <td class="tax_info text-right" style="width: 30%;">
                                    {{propertyinfo.security_deposite_title}}
                                  </td>
                                </tr>
                                <tr *ngIf="propertyinfo.advance_rent">
                                  <td class="tax_info" style="width: 70%;">Advance Rent</td>
                                  <td class="tax_info text-right" style="width: 30%;">
                                    {{propertyinfo.advance_rent_title}}
                                  </td>
                                </tr>
                                <tr *ngIf="propertyinfo.notary_charge">
                                  <td class="tax_info" style="width: 70%;">Notary Charge</td>
                                  <td class="tax_info text-right" style="width: 30%;">
                                    {{propertyinfo.notary_charge_title}}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="tax_total" style="width: 70%;">Closing Price</td>
                                  <td class="tax_total text-right" style="width: 30%;">
                                    {{propertyinfo.closing_price_title}}
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </ng-container>
                            <!-- Ends -->
                            <!-- Taxes Informartion for Sell and other Properties -->
                            <ng-template #sellPropTaxes>
                              <table class="tax_table" style="width: 100%;">
                                <tbody>
                                <tr>
                                  <td class="tax_info" style="width: 70%;">Basic Price</td>
                                  <td class="tax_info text-right" style="width: 30%;">
                                    {{propertyinfo.asking_price_title}}
                                  </td>
                                </tr>
                                <tr *ngFor="let tax of propertyinfo.prop_tax.tax_list;">
                                  <td class="tax_info" style="width: 70%;">
                                    {{(tax.title | titlecase) + ' ' + tax.tax_percentage + '%'}}
                                  </td>
                                  <td class="tax_info text-right" style="width: 30%;">{{tax.tax_amount_title}}</td>
                                </tr>
                                <tr>
                                  <td class="tax_total" style="width: 70%;">Closing Price</td>
                                  <td class="tax_total text-right" style="width: 30%;">
                                    {{propertyinfo.closing_price_title}}
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </ng-template>
                            <!-- Ends -->
                          </ng-container>
                          <ng-template #noTaxInfo>
                            <p>No data</p>
                          </ng-template>
                        </div>

                        <div class="col-md-6" *ngIf="propertyinfo.property_tax || propertyinfo.maintenance_charge || propertyinfo.lease_register">
                          <h4>Extra Cost</h4>
                          <ul>
                            <li *ngIf="propertyinfo.property_tax">Property Tax: {{ propertyinfo.property_tax }}</li>
                            <li *ngIf="propertyinfo.maintenance_charge">Maintenance Charge: {{ propertyinfo.maintenance_charge }}</li>
                            <li *ngIf="propertyinfo.lease_register">Lease Register: {{ propertyinfo.lease_register }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_amenities" role="tabpanel"
                   aria-labelledby="property_amenities_tab" *ngIf="propertyinfo.prop_amenities && propertyinfo.prop_amenities.length > 0">
                <h5>Amenities</h5>
                <ul class="p_amenities">
                  <li class="s_amenities" *ngFor="let itemAmenities of propertyinfo.prop_amenities">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {{itemAmenities.amenity.title}}
                  </li>
                </ul>
              </div>

              <div class="tab-pane fade show active" id="ask_que_agent" role="tabpanel"
                   aria-labelledby="ask_que_agent_tab" *ngIf="propertyinfo.assign_to">
                <h5>Ask Realtors
                  Agent {{(propertyinfo.assign_to.name ? propertyinfo.assign_to.name : propertyinfo.assign_to.first_name + ' ' + propertyinfo.assign_to.last_name)}}
                  a Question</h5>
                <div class="row d-flex agent_que_box">
                  <div class="col-md-4">
                    <img src="{{propertyinfo.assign_to.image_preview}}" class="rounded-circle" alt="Cinque Terre"
                         width="95" height="95"/>
                    <div class="ag_info">
                      <h6>{{(propertyinfo.assign_to.name ? propertyinfo.assign_to.name : propertyinfo.assign_to.first_name + ' ' + propertyinfo.assign_to.last_name)}}</h6>
                      <!--<p>{{(propertyinfo.assign_to.city.city ? propertyinfo.assign_to.city.city : '')}} Realtors Agent</p>-->
                      <p>{{propertyinfo.assign_to.email}}</p>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <textarea class="form-control" id="agent_text_que" rows="4"
                              placeholder="I'd like to know more about Vadodara Real Estate."></textarea>
                    <span id="agent_text_que_alert" class="fs-12 hidden">Your query is submitted. Our team will get back to you soon</span>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-common btn-lg agent_btn" [disabled]="clicked" (click)="askQuestion(propertyinfo.id)">Ask a Question</button>
                    <p *ngIf="propertyinfo.assign_to.contact_no">Text/Call <b>{{propertyinfo.assign_to.contact_no}}</b>
                    </p>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_nearby" role="tabpanel"
                   aria-labelledby="property_nearby_tab">
                <h5>Near by your Landmarks</h5>
                <ul class="nav nav-tabs prop_nearby_tabs" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="schools-tab" (click)="changeNearBy('school', gm)" data-toggle="pill"
                       href="#schools" role="tab" aria-controls="schools" aria-selected="true">Schools</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="restaurants-tab" (click)="changeNearBy('restaurant', gm)" data-toggle="pill"
                       href="#restaurants" role="tab" aria-controls="restaurants" aria-selected="false">Restaurants</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="hospitals-tab" (click)="changeNearBy('hospital', gm)" data-toggle="pill"
                       href="#hospitals" role="tab" aria-controls="hospitals" aria-selected="false">Hospitals</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="atms-tab" (click)="changeNearBy('atm', gm)" data-toggle="pill" href="#atms"
                       role="tab" aria-controls="atms" aria-selected="false">Atm's</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="shopping_mall-tab" (click)="changeNearBy('shopping_mall', gm)"
                       data-toggle="pill" href="#shopping_mall" role="tab" aria-controls="shopping_mall"
                       aria-selected="false">Shopping Mall</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="schools-tab">
                    <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                             [zoomControl]="false" [mapTypeControl]="true" [scrollwheel]="false" [usePanning]="true"
                             (mapReady)="mapReady($event)">
                      <agm-marker *ngFor="let m of markers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="icon"
                                 
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <div class="imgDiv" [style.background-image]="'url(\'' + m.image_preview + '\')'">&nbsp;</div>
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p><i class="fa fa-rupee"></i> {{ m.asking_price }}</p>
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                      <agm-marker *ngFor="let m of schoolMarkers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="iconSchool"
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <!-- <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p>{{ m.asking_price }}</p>
                            </div> -->
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <div >{{m.asking_price}}</div>
                              <div class="dir_top">
                                <span class="link" (click)="getDirection(m.lat,m.lng)">Get Directions</span>
                                <!-- &nbsp;|&nbsp;<span class="link" (click)="openPropertyDetails(m.pid)">Visit Property Detail</span> -->
                              </div>
          
                              <!-- <div class="property_badge_map" >
                                <span 
                                  class="property_badge_sell_map">{{m?.status}}</span>
                              </div> -->
                              <!-- <p *ngIf='m.asking_price'><i class="fa fa-rupee"></i> {{ m.asking_price }}</p> -->
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>

              <div *ngIf="(similerProperties || []).length > 0" class="tab-pane fade show active" id="similar_property"
                   role="tabpanel" aria-labelledby="similar_property_tab">
                <h5>Similar Property</h5>
                <div class="row ss_property_view" id="similar_property_view">
                  <ng-container *ngFor="let item of similerProperties">
                    <div class="d-flex single_property_view"
                         *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                      <div class="card">
                        <div class="grid_image_view" [style.background-image]="'url(\'' + item.image_preview + '\')'">
                          <div class="property_badge top_left" *ngIf="item.prop_transaction_type">
                            <span class="property_badge_sell">{{item.prop_transaction_type.title}}</span>
                          </div>
                          <div class="property_badge top_right favoriteIcon_{{item.id}}"
                               [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }"
                               *ngIf="item.prop_transaction_type" (click)="makeFavoriteOthers(item.id, item.franchisee_id)">
                            <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: #FFFFFF;"></i>
                            <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                          </div>
                        </div>

                        <div class="card-body" (click)="viewSimilarPropDetails(item.id)">
                          <h5 class="card-title"><i class="fa fa-rupee"></i>{{item.asking_price}}</h5>
                          <p class="card-text">
                            {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                          </p>
                          <p class="card-text">
                            <span class="pro_spec" *ngIf="item.total_washrooms"> <i
                              class="fa fa-bath"></i> {{item.total_washrooms}} Bath </span>
                            <span class="pro_spec" *ngIf="item.total_bedrooms"> <i
                              class="fa fa-bed"></i> {{item.total_bedrooms}} Bed </span>
                            <span class="pro_spec" *ngIf="item.saleable_area"> <i
                              class="fa fa-check-square"></i> {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_emi_calculator" role="tabpanel"
                   aria-labelledby="property_emi_calculator_tab" *ngIf="propertyinfo.transaction_type_id != 3">
                <h5>Monthly Cost/EMI</h5>
                <div class="row d-flex">
                  <div class="col-md-12">
                    <p id="cost_month"></p>
                  </div>
                </div>
                <div class="row d-flex">
                  <div class="col-md-2 tabs_titles_floors">
                    <ul class="nav nav-tabs tabs_titles_floors_tabs" role="tablist">
                      <li class="nav-item">
                        <span (click)="onEMITabClick(1)" class="nav-link active" id="principal_int_tab"
                              href="#principal_int" data-toggle="tab" role="tab" aria-controls="principal_int"
                              aria-selected="true">Principal & Interest</span>
                      </li>
                      <!--<li class="nav-item">
                        <span (click)="onEMITabClick(2)" class="nav-link" id="mortgage_inc_tab" href="#mortgage_inc" data-toggle="tab" role="tab" aria-controls="mortgage_inc" aria-selected="false">Mortgage insurance</span>
                      </li>-->
                      <li class="nav-item">
                        <span (click)="onEMITabClick(3)" class="nav-link" id="property_taxes_tab" href="#property_taxes"
                              data-toggle="tab" role="tab" aria-controls="property_taxes" aria-selected="false">Property Taxes</span>
                      </li>
                      <li class="nav-item">
                        <span (click)="onEMITabClick(4)" class="nav-link" id="home_ins_tab" href="#home_ins"
                              data-toggle="tab" role="tab" aria-controls="home_ins"
                              aria-selected="false">Home Insurance</span>
                      </li>
                      <!--<li class="nav-item">
                        <span (click)="onEMITabClick(5)" class="nav-link" id="hoa_fee_tab" href="#hoa_fee"
                              data-toggle="tab" role="tab" aria-controls="hoa_fee" aria-selected="false">HOA fees</span>
                      </li>-->
                      <!--<li class="nav-item">
                        <span (click)="onEMITabClick(6)" class="nav-link" id="utilities_emi_tab" href="#utilities_emi"
                              data-toggle="tab" role="tab" aria-controls="utilities_emi"
                              aria-selected="false">Utilities</span>
                      </li>-->
                    </ul>
                  </div>
                  <div class="col-md-10 floor_pans" [formGroup]="emiForm">
                    <div class="tab-content tabs_titles_floors_tab_content">
                      <div class="tab-pane fade show active" id="principal_int" data-toggle="tab" role="tabpanel"
                           aria-labelledby="principal_int_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="input-label" for="home_price">Home Price</label>
                              <div class="custom_input_prepend">
                                <i class="fa fa-rupee"></i>
                                <!-- [ngClass]="{ 'is-invalid': g.home_price.errors }" -->
                                <input type="number" id="home_price" [(ngModel)]="home_price"
                                       formControlName="home_price" 
                                       class="form-control" placeholder="Home Price" (keyup)="onEMICalPriceChange()"/>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="inter-flex">
                                <label class="input-label down_payment" for="down_payment">Down Payment</label>
                                <label class="input-label down_payment_per" for="down_payment_per">Down Payment
                                  %</label>
                              </div>
                              <div class="inter-flex">
                                <div class="custom_input_prepend">
                                  <i class="fa fa-rupee"></i>
                                  <!-- [ngClass]="{ 'is-invalid': g.down_payment.errors }" -->
                                  <input type="number" id="down_payment" [(ngModel)]="down_payment"
                                         formControlName="down_payment"
                                          class="form-control"
                                         placeholder="Down Payment" (keyup)="onEMICalDownPaymentChange()"/>
                                </div>
                                <div class="custom_input_append">
                                  <!-- [ngClass]="{ 'is-invalid': g.down_payment_per.errors }" -->
                                  <input type="number" id="down_payment_per" [(ngModel)]="down_payment_per"
                                         formControlName="down_payment_per"
                                          class="form-control"
                                         placeholder="Down Payment per" (keyup)="onEMICalDownPaymentPerChange()"/>
                                  <span class="append_text">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="form-group">
                              <label class="input-label" for="loan_program">Loan Program</label>
                              <select [(ngModel)]="loan_program" class="form-control" (change)="onEMICalTermChange()"
                                      [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let loan_program_option of loan_program_options"
                                        value="{{ loan_program_option.id }}">
                                  {{ loan_program_option.title }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="input-label" for="int_rate">Interest Rate</label>
                              <div class="custom_input_append">
                                <!-- [ngClass]="{ 'is-invalid': g.int_rate.errors }" -->
                                <input type="number" id="int_rate" [(ngModel)]="int_rate" formControlName="int_rate"
                                        class="form-control"
                                       placeholder="Interest rate" (keyup)="onEMICalIntRateChange()"/>
                                <span class="append_text">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="mortgage_inc" role="tabpanel" data-toggle="tab"
                           aria-labelledby="mortgage_inc_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="inc_morg_ins"
                                       ng-model="inc_morg_ins" (change)="changeMortgage()"/>
                                <label class="custom-control-label" for="inc_morg_ins">Include mortgage
                                  insurance</label>
                                <p>Mortgage insurance is usually required for down payments below 20%.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="property_taxes" role="tabpanel" data-toggle="tab"
                           aria-labelledby="property_taxes_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <p>This estimate is based on the home value and an estimated local tax rate. Actual rate
                                may vary.</p>
                              <div class="inter-flex-pt">
                                <label class="input-label pt_first">Home Price</label>
                                <label class="input-label pt_second">Tax Rate</label>
                                <label class="input-label pt_third">&nbsp;</label>
                              </div>
                              <div class="inter-flex-pt">
                                <div class="custom_text pt_first">
                                  <i class="fa fa-rupee"></i>
                                  {{ new_home_price }} X
                                </div>
                                <div class="custom_input_append pt_second">
                                  <input type="number" id="property_tex_per" [(ngModel)]="property_tex_per"
                                         formControlName="property_tex_per"
                                         [ngClass]="{ 'is-invalid': g.property_tex_per.errors }" class="form-control"
                                         placeholder="Property Tex per" (keyup)="onPropertyTaxPerChange()"/>
                                  <span class="append_text">%</span>
                                </div>
                                <div class="custom_text pt_third">
                                  = <i class="fa fa-rupee"></i> {{ property_tex_yearly }} /year
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="home_ins" role="tabpanel" data-toggle="tab"
                           aria-labelledby="home_ins_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="input-label" for="home_ins_year">Insurance Per Year</label>
                              <div class="custom_input_prepend custom_input_append">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="home_ins_year" [(ngModel)]="home_ins_year"
                                       formControlName="home_ins_year"
                                       [ngClass]="{ 'is-invalid': g.home_ins_year.errors }" class="form-control"
                                       placeholder="Insurance Per Year" (keyup)="onYearInsChange()"/>
                                <span class="append_text">/year</span>
                              </div>
                              <p>Most lenders require homeowners insurance, which protects your home and property.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="hoa_fee" role="tabpanel" data-toggle="tab"
                           aria-labelledby="hoa_fee_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="custom_input_prepend custom_input_append">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="hoa_per_month" [(ngModel)]="hoa_per_month"
                                       formControlName="hoa_per_month"
                                       [ngClass]="{ 'is-invalid': g.hoa_per_month.errors }" class="form-control"
                                       placeholder="Insurance Per Year" (keyup)="onHOAPerMonthChange()"/>
                                <span class="append_text">/month</span>
                              </div>
                              <p>Some properties require monthly HOA dues to cover common amenities or services.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="utilities_emi" role="tabpanel" data-toggle="tab"
                           aria-labelledby="utilities_emi_tab">
                        <div class="row d-flex">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="input-label" for="water_sewer_month">Water/Sewer</label>
                              <div class="custom_input_prepend custom_input_append">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="water_sewer_month" [(ngModel)]="water_sewer_month"
                                       formControlName="water_sewer_month"
                                       [ngClass]="{ 'is-invalid': g.water_sewer_month.errors }" class="form-control"
                                       placeholder="Water/Sewer" (keyup)="onUtilitiesChange()"/>
                                <span class="append_text">/month</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="input-label" for="gas_month">Gas</label>
                              <div class="custom_input_prepend custom_input_append">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="gas_month" [(ngModel)]="gas_month" formControlName="gas_month"
                                       class="form-control" [ngClass]="{ 'is-invalid': g.gas_month.errors }"
                                       placeholder="Gas" (keyup)="onUtilitiesChange()"/>
                                <span class="append_text">/month</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="input-label" for="internet_month">Internet</label>
                              <div class="custom_input_prepend custom_input_append">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="internet_month" [(ngModel)]="internet_month"
                                       formControlName="internet_month"
                                       [ngClass]="{ 'is-invalid': g.internet_month.errors }" class="form-control"
                                       placeholder="Internet" (keyup)="onUtilitiesChange()"/>
                                <span class="append_text">/month</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="input-label" for="electric_month">Electric</label>
                              <div class="custom_input_prepend custom_input_append">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="electric_month" [(ngModel)]="electric_month"
                                       formControlName="electric_month"
                                       [ngClass]="{ 'is-invalid': g.electric_month.errors }" class="form-control"
                                       placeholder="Electric" (keyup)="onUtilitiesChange()"/>
                                <span class="append_text">/month</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="suggestedProperties && suggestedProperties.length > 0" class="tab-pane fade show active" id="suggested_property"
                   role="tabpanel" aria-labelledby="suggested_property_tab">
                <h5>Suggested New Homes</h5>
                <div class="row ss_property_view" id="suggested_property_view">
                  <ng-container *ngFor="let item of suggestedProperties">
                    <div class="d-flex single_property_view"
                         *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                      <div class="card">
                        <div class="grid_image_view" [style.background-image]="'url(\'' + item.image_preview + '\')'">
                          <div class="property_badge top_left" *ngIf="item.prop_transaction_type">
                            <span class="property_badge_sell">{{item.prop_transaction_type.title}}</span>
                          </div>
                          <div class="property_badge top_right favoriteIcon_{{item.id}}"
                               [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }"
                               *ngIf="item.prop_transaction_type" (click)="makeFavoriteOthers(item.id, item.franchisee_id)">
                            <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: #FFFFFF;"></i>
                            <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                          </div>
                        </div>
<!--                        <div class="card-body" [routerLink]="['/property-details', item.id ]">-->
                        <div class="card-body" (click)="viewSimilarPropDetails(item.id)">
                          <h5 class="card-title"><i class="fa fa-rupee"></i>{{item.asking_price}}</h5>
                          <p class="card-text">
                            {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                          </p>
                          <p class="card-text">
                            <span class="pro_spec" *ngIf="item.total_washrooms"> <i
                              class="fa fa-bath"></i> {{item.total_washrooms}} Bath </span>
                            <span class="pro_spec" *ngIf="item.total_bedrooms"> <i
                              class="fa fa-bed"></i> {{item.total_bedrooms}} Bed </span>
                            <span class="pro_spec" *ngIf="item.saleable_area"> <i
                              class="fa fa-check-square"></i> {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Property images -->
      </div>
      <div class="col-md-3" [ngClass]="{'sticky-div':enableSticky}">
        <div class="side_block" id="visit">
          <h5>Book Your Appointment</h5>
          <div class="owl-carousel owl-theme" id="visit_date_slider"></div>
          <p>Feel free to Schedule Visit, its free ,with no obligation and cancel any time.</p>
          <div class="row schedule-select mt-2">
            <div (click)="selectAppointmentType(1)" class="col-md-6 text-center">
              <div class="row schedule-option {{ (this.tourInPerson ? 'option-active' : '') }}">
                <div class="col-md-12">
                  <i aria-hidden="true" class="fa fa-home icon-cls"></i>
                </div>
                <div class="col-md-12 schedule-text">
                  Tour in person
                </div>
              </div>
            </div>
            <div (click)="selectAppointmentType(2)" class="col-md-6 text-center">
              <div class="row schedule-option {{ (this.tourVideoChat ? 'option-active' : '') }}">
                <div class="col-md-12">
                  <i aria-hidden="true" class="fa fa-mobile icon-cls"></i>
                </div>
                <div class="col-md-12 schedule-text">
                  Tour via video chat
                </div>
              </div>
            </div>
          </div>
          <a (click)="searchschedulevisit(propertyinfo.franchisee_id, propertyinfo.assign_to_user_id)" class="schdule btn">Book Now</a>
        </div>
        <!--<div class="side_block agent_details">
          <img src="../../assets/img/web-image/projectdetialspage/person.png" alt="Nimpesh Parikh" />
          <h4>Nimpesh Parikh</h4>
          <div class="ag_in_details_flex">
            <span>Pwc Property Hub</span>
            <span>Operating Since <b>2011</b></span>
          </div>
          <div class="ag_in_details">
            <p class="ag_title">Rera Id</p>
            <p>https://gujrera.gujarat.gov.in/viewProjectDetailPage?projectID=8199</p>
          </div>
          <div class="ag_in_details">
            <p class="ag_title">Location</p>
            <p>Bhatar Road, Surat, Gujarat, 395001 India</p>
          </div>
          <div class="ag_in_details_flex">
            <div class="ag_in_details_inner">
              <p class="ag_title">Properties For Sale</p>
              <p>30</p>
            </div>
            <div class="ag_in_details_inner">
              <p class="ag_title">Properties For Rent</p>
              <p>15</p>
            </div>
          </div>
          <a class="schdule btn">Contact Certified Agent</a>
        </div>-->
      </div>
    </div>
  </div>
</div>

<!-- Shareable Link Modal -->
<div class="modal fade share_model" id="sharePropModal" tabindex="-1" role="dialog" aria-labelledby="sharePropModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <app-flash></app-flash> -->

        <div class="loginform">
          <form [formGroup]="shareform" (ngSubmit)="onShareSubmit(propertyinfo.id)">
            <div class="form-group">
              <input type="text" formControlName="emailShare" class="form-control" placeholder="Email Address"
                     [ngClass]="{ 'is-invalid': submitted && k.emailShare.errors }" />
              <div *ngIf="submitted && k.emailShare.errors" class="invalid-feedback">
                <div *ngIf="k.emailShare.errors.required">Email is required</div>
                <div *ngIf="k.emailShare.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <!--<div class="form-group">
              <input type="text" formControlName="subjectShare" class="form-control" placeholder="Subject"
                     [ngClass]="{ 'is-invalid': submitted && k.subjectShare.errors }" />
              <div *ngIf="submitted && k.subjectShare.errors" class="invalid-feedback">
                <div *ngIf="k.subjectShare.errors.required">Subject is required</div>
              </div>
            </div>-->
            <div class="form-group">
              <button class="bttn btn-block login-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
