<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid seller-property-outer" id="accoutnSettings">
    <ng-container *ngIf="isLoading == true">
      <div class="loading_div">
        <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
      </div>
    </ng-container>
    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- <app-flash></app-flash> -->
            <h4 class="page-title">My Property</h4>
            <div class="open-house">
              <div class="tab-content">
                <div id="resale" class="tab-pane fade in active show mt-3">
                  <ng-container *ngIf="isLoading == false">
                    <div class="empty-message" *ngIf="sellerPropertyList.length == 0">
                      <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                      <div class="empty-text">
                        <h5>You have no appointments</h5>
                        <p>Add homes to your appointment to help you plan out your home search.</p>
                      </div>
                      <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                    </div>
                  </ng-container>
                  <div *ngIf="sellerPropertyList.length > 0">
                    <ng-container *ngFor="let propertyItem of sellerPropertyList">
                      <div *ngIf="isLoading == false" class="account-list-property">
                        <div class="p_image_view" [routerLink]="['/property-details', propertyItem.source_id ]" [style.background-image]="'url(\'' + propertyItem.image_preview + '\')'"></div>
                        <div class="p_details">
                          <div class="p_details_top">
                            <div class="price_div">
                              <span><i class="fa fa-rupee"></i>{{ propertyItem.asking_price_title }}</span>
                              <button class="btn view-offer-btn mobile-hide" *ngIf="propertyItem.property_status_id != 2" (click)="onMarkSold(propertyItem.source_id)">Mark As Sold</button>
                              <button class="btn sold_btn red-btn mobile-hide" *ngIf="propertyItem.property_status_id == 2" >Sold</button>
                              <button class="btn make-offer-btn mobile-hide" *ngIf="propertyItem.property_status_id != 2">Start Offer</button>

                              <button class="btn mark-sold-btn mobile-show" *ngIf="propertyItem.property_status_id != 2" (click)="onMarkSold(propertyItem.id)">Mark As Sold</button>
                              <button class="btn red-btn sold-btnM mobile-show" *ngIf="propertyItem.property_status_id == 2" >Sold</button>
                              <button class="btn offer-btn mobile-show" *ngIf="propertyItem.property_status_id != 2">Start Offer</button>
                            </div>
                            <p class="address">
                              {{ propertyItem.property_name }} {{propertyItem.locality_title}} {{(propertyItem.City ? propertyItem.city.city : '')}}
                            </p>
                            <p class="card-text">
                              <span class="pro_spec" *ngIf="propertyItem.total_showers"> <i class="fa fa-bath"></i> {{(propertyItem.total_showers ? propertyItem.total_showers : 0)}} Bath </span>
                              <span class="pro_spec" *ngIf="propertyItem.total_bedrooms"> <i class="fa fa-bed"></i> {{(propertyItem.total_bedrooms ? propertyItem.total_bedrooms : 0)}} Bed </span>
                              <span class="pro_spec" *ngIf="propertyItem.super_builtup"> <i class="fa fa-check-square"></i> {{ propertyItem.super_builtup }} Sq.Ft. </span>
                            </p>
                          </div>
                          <div class="p_details_bottom" [routerLink]="['/property-details', propertyItem.related_source_id ]">
                            <ul class="prop_more_details">
                              <li *ngIf="propertyItem.transaction_name">
                                <h5>Type</h5>
                                <p>{{ propertyItem.transaction_name }}</p>
                              </li>
                              <li *ngIf="propertyItem.rate_sqft">
                                <h5>Price</h5>
                                <p><i class="fa fa-rupee"></i>{{ propertyItem.rate_sqft }}</p>
                              </li>
                              <li class="totalViews">
                                <h5>Online Visit</h5>
                                <p>{{ propertyItem.online_visit }}</p>
                              </li>
                              <li class="totalViews">
                                <h5>Site Visit</h5>
                                <p>{{ propertyItem.site_visit }}</p>
                              </li>
                              <li class="totalViews">
                                <h5>Total Offers</h5>
                                <p>{{ propertyItem.total_offers }}</p>
                              </li>
                              <li class="totalViews">
                                <h5>Total Selected</h5>
                                <p>{{ propertyItem.total_selected }}</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
