/* tslint:disable:prefer-const no-var-keyword whitespace variable-name quotemark no-inferrable-types max-line-length no-shadowed-variable */
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { FlashService } from '../../_services/FlashService.service';
import { API_ENDPOINTS } from '../../global';
import {Meta, Title} from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-seller-property',
  templateUrl: './seller-property.component.html',
  styleUrls: ['./seller-property.component.scss']
})
export class SellerPropertyComponent implements OnInit {
  search_icon: string = '';
  isLoading: boolean = true;
  dashboardType = 'buyer';
  sellerPropertyList: any = [];
  newPropertyList: any = [];

  constructor(private ApiService: ApiService, private FlashService: FlashService, private router: Router, private title: Title, private route: ActivatedRoute, ) {
    this.search_icon = API_ENDPOINTS.HOME_ICON;
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if(!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if(this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
      this.getSellerPropertyList();

      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  getSellerPropertyList() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SELLER_PROPERTIES, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if(apiResult.success) {
        this.isLoading = false;
        this.sellerPropertyList = apiResult.data.list;
      } else {
        this.isLoading = false;
      }
    });
  }

  onMarkSold(id) {
    var form_data = new FormData();
    form_data.append('property_id', id + '');
    this.ApiService.postRequest(API_ENDPOINTS.API_MARKED_PROPERTY, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.FlashService.success('Appointment has been successfully sold.');
        this.getSellerPropertyList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » My Properties');
  }
}
