<div class="property_search_outer">

  <div class="padding-div" id="properties_map_view">
    <div class="row mx-0">
      <div class="col-md-6 map-view-main-outer px-0">
        <div class="map-view">
          <span (click)="openSaveSearchPopup()" class="save-search-btn" *ngIf="isLoggedIn">Save Search</span>
          <div class="saveSearchPopup">
            <form (ngSubmit)="onSaveSearch()" [formGroup]="saveSearchForm">
              <div class="form-group">
                <input [(ngModel)]="searchnameval" [ngClass]="{ 'is-invalid': submitted && g.searchname.errors }" class="form-control"
                       formControlName="searchname" placeholder="Search Name" />
                <div *ngIf="submitted && g.searchname.errors" class="invalid-feedback">
                  <div *ngIf="g.searchname.errors.required">Search Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <button class="btn btn-block save-form-btn" type="submit">Save Search</button>
                <span (click)="viewSaveSearched()" class="view_saved_searches">View Saved Searches</span>
              </div>
            </form>
          </div>
          <agm-map #gm (mapReady)="mapReady($event)" (zoomChange)="zoomChange($event)" [disableDefaultUI]="false" [latitude]="lat" [longitude]="lng"
                   [mapTypeControl]="true" [usePanning]="true" [zoomControl]="false" [zoom]="zoom">
            <agm-marker (mouseOver)="onMarkerMouseOver(infoWindow, gm)" *ngFor="let m of markers; let i = index"
                        [iconUrl]="m.symbol" [label]="m.label" [latitude]="m.lat"
                        [longitude]="m.lng" [markerDraggable]="m.draggable">
              <agm-info-window #infoWindow [disableAutoPan]="false">
                <div class="infoWindowOuter">
                  <div *ngIf="m.image_preview" [style.background-image]="'url(\'' + m.image_preview + '\')'" class="imgDiv">
                    &nbsp;</div>
                  <!-- <div class="otherInfo">
                    <h4>{{ m.locality_title }}</h4>
                  </div> -->
                  <div class="otherInfo">
                    <h4>{{ m.locality_title }}</h4>
                    <div >{{m.address}}</div>
                    <div class="dir_top">
                      <span class="link" (click)="getDiraction(m.lat,m.lng)">Get Directions</span>&nbsp;|&nbsp;<span class="link" (click)="openProjectInNewTab(m.pid)">Visit Property Detail</span>
                    </div>

                    <div class="property_badge_map" >
                      <span 
                        class="property_badge_sell_map">{{(m.status)}}</span>
                    </div>
                    <!-- <p *ngIf='m.asking_price'><i class="fa fa-rupee"></i> {{ m.asking_price }}</p> -->
                  </div>
                </div>
              </agm-info-window>
            </agm-marker>
            <agm-polyline [strokeColor]="'#07b57a'" [strokeWeight]="3" [visible]="true">
              <agm-polyline-point *ngFor="let coordinate of latlngarea; let i=index" [latitude]="coordinate[0]"
                                  [longitude]="coordinate[1]">
              </agm-polyline-point>
            </agm-polyline>
          </agm-map>
        </div>
      </div>

      <div (click)="hideSearchBox()" class="col-md-6 px-0 content-view-main-outer">
        <ng-container *ngIf="isLoading == true">
          <div class="loading_div">
            <img class="loading_img" src="../../assets/img/web-image/loader.gif" />
          </div>
        </ng-container>
        <div class="content-view px-3">
          <div class="col-md-12">
            <div class="input-group search_text_input" id="search_text">
              <input class="form-control form-control-lg" id="searchinput" placeholder="Type Location or Project/Society or Keyword"
                     type="text">
              <span class="input-group-append" (click)="searchProperty()"><i class="fa fa-search"></i></span>
            </div>
          </div>
          <div class="col-md-12 new-filter">
            <label> Budget &nbsp;&nbsp;</label>
            <div class="form-group row mx-0">
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_budget_amount"
                       name="min_budget_amount" placeholder="Min" />
              </div>
              <label> to </label>
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_budget_amount"
                       name="max_budget_amount" placeholder="Max" />
              </div>
              <span id="clearBudgetFilter"><i (click)="clearBudgetFilter()" aria-hidden="true" class="fa fa-times"></i></span>
              <div class="col-md-5 filterBtnDiv">
                <button (click)="showHideFilter()" class="btn btn-block login-btn mx-2 plus-minus collapsed" id="filterBtn"
                        type="button">Filters</button>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-9 prop_type_title">
              <h4>{{ name }}</h4>
            </div>
            <div class="col-3 iconview" *ngIf="projectsList.length>0 && moreFilter=='close'">
              <span class="sort_btn"><i aria-hidden="true" class="fa fa-sort-amount-asc"></i></span>
              <span (click)="showgridviewfunction()" [ngClass]="activeView ? 'grid_btn active_view' : 'grid_btn'">
                <i aria-hidden="true" class="fa fa-th-large"></i>
              </span>
              <span (click)="showlistviewfunction()" [ngClass]="!activeView ? 'list_btn active_view' : 'list_btn'">
                <i aria-hidden="true" class="fa fa-list"></i>
              </span>
            </div>
            <div class="col-12 prop_cat_view">
              <span *ngIf="total_props_s" class="total_props">{{ total_props_s }}</span>
            </div>
          </div>

          <!-- More Filters Options Starts -->
          <div class="row collapse mt-3" id="moreFilter">
            <div class="col-md-12 filter-property-form" style="border-bottom: 1px solid #cbcbcb !important;">
              <div class="row mx-0">
                <div class="col-md-12">
                  <!-- Category -->
                  <!--<label><i class="fa fa-briefcase fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Category</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer" id="f_property_type_category">
                      <li class="pl-2" *ngFor="let singlett of propertyTypeCategory">
                        {{ singlett.title }}
                        <input type="radio" id="tt{{ singlett.id }}" (change)="searchboxapply(0)" value="{{ singlett.id }}" [(ngModel)]="propertytypeid"><span class="checkmark"></span>
                      </li>
                    </ul>
                  </div>-->
                  <div class="col-md-12 col-sm-6 multi-select">
                    <label><i aria-hidden="true" class="fa fa-cubes fa_icon"></i> &nbsp;<b> Property Category</b></label>
                    <div class="form-group pl-3 pt-2">
                      <ul class="filter_drop_outer" style="height: auto;display: flex;">
                        <li *ngFor="let singlett of projectTypeCategory" class="pl-2">
                          <label class="custom_checkbox">
                            {{ singlett.title }}
                            <input (change)="searchboxapply(0)" [(ngModel)]="propertytypeid" id="tt{{ singlett.id }}" type="radio" value="{{ singlett.id }}"><span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Ends -->
                  <div class="col-md-12" id="">
                    <!-- Project Status -->
                    <label><i aria-hidden="true" class="fa fa-cog fa_icon"></i> &nbsp;<b> Project Status</b></label>
                    <div class="form-group pl-2 mb-2 pb-3">
                      <ng-container *ngFor="let status of projectStatusList">
                        <div class="checkbox-div">
                          <input [(ngModel)]="status.isChecked" class="f_box_check" id="projstatus{{ status.id }}" name="projstatus[]" type="checkbox" value="{{ status.id }}">
                          <label class="f_box_label" for="projstatus{{ status.id }}">{{ status.text }}</label>
                        </div>
                      </ng-container>
                    </div>
                    <!-- Ends -->
                  </div>
                </div>


                <div class="col-md-6" id="bedroom_filter_div">
                  <!-- Bedrooms -->
                  <label><i aria-hidden="true" class="fa fa-bed fa_icon"></i> &nbsp;<b> Bedrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebedroom of bedroomList">
                      <div class="checkbox-div">
                        <input [(ngModel)]="singlebedroom.isChecked" class="f_box_check" id="b{{ singlebedroom.id }}" name="beds[]" type="checkbox" value="{{ singlebedroom.id }}">
                        <label class="f_box_label" for="b{{ singlebedroom.id }}">{{ singlebedroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>
                <div class="col-md-6" id="bathroom_filter_div">
                  <!-- Bathrooms -->
                  <label><i aria-hidden="true" class="fa fa-bath fa_icon"></i> &nbsp;<b> Bathrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebathroom of bathroomList">
                      <div class="checkbox-div">
                        <input [(ngModel)]="singlebathroom.isChecked" class="f_box_check" id="bath{{ singlebathroom.id }}" name="baths[]" type="checkbox" value="{{ singlebathroom.id }}">
                        <label class="f_box_label" for="bath{{ singlebathroom.id }}">{{ singlebathroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>

                <!-- Property Facts -->
                <div class="col-md-12" id="prop_fact_div">
                  <label><i aria-hidden="true" class="fa fa-area-chart fa_icon"></i> &nbsp;<b> Property Facts</b></label>
                  <div class="form-group">
                    <div class="row mx-0">
                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Min Carpet Area</label>
                        </div>
                        <div class="col-12" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_saleable_area"
                                   name="min_saleable_area" placeholder="Min" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control" id="min_saleable_area_unit"
                                    name="min_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Max Carpet Area</label>
                        </div>
                        <div class="col-12 px-2" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_saleable_area"
                                   name="max_saleable_area" placeholder="Max" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control" id="max_saleable_area_unit"
                                    name="max_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ends -->

                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i aria-hidden="true" class="fa fa-tree fa_icon"></i> &nbsp;<b> Property Type</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_category"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Amenities -->
                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i aria-hidden="true" class="fa fa-tree fa_icon"></i> &nbsp;<b> Amenities</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_amenities"></ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Ends -->
              </div>
            </div>

            <div class="col-md-12 filter-foot">
              <div class="col-md-6">
                <button (click)="clearFilter()" class="btn login-btn mx-2" type="button">Reset</button>
              </div>
              <div class="col-md-6 text-right">
                <button (click)="searchboxapply(1)" class="btn login-btn mx-2" type="button">Apply Filters</button>
              </div>
            </div>

          </div>
          <!-- More Filters Options Ends -->

          <!-- Start Project Grid View -->
          <div class="row" id="property_grid_view">
            <ng-container *ngFor="let item of projectsList  | paginate : pageConfig">
              <div *ngIf="item.name" class="col-md-6 d-flex single_property_grid">
                <div class="card" (click)="openProjectInNewTab(item.id)">
                  <div [style.background-image]="'url(\'' + item.image_preview + '\')'" class="grid_image_view">
                    <!-- <div *ngIf="item.transaction_type" class="property_badge top_left">
                      <span *ngIf="item.is_coming_soon == 1" class="property_badge_sell">Coming Soon</span>
                    </div> -->


                    <div class="property_badge top_left" *ngIf="item.project_status">
                      <!-- <span class="property_badge_sell" *ngIf="item.is_coming_soon == 1">Coming Soon</span> -->
                      <span 
                        class="property_badge_sell">{{item.project_status}}</span>
                    </div>
                    <div (click)="makeFavorite(item.id, item.is_favorited, item.franchisee_id);$event.stopPropagation()"
                         [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }"
                         class="property_badge top_right favoriteIcon_{{item.id}}">
                      <i class="fa fa-heart fav_white_color" id="i_favIcon_{{item.id}}"></i>
                    </div>
                  </div>
                  <div class="card-body ">
                    <h6 class="card-title card_text_title">{{item.name}}</h6>
                    <p class="card-text">
                      <i class="fa fa-map-marker"></i>
                      {{item.locality_title}} {{(item.city ? item.city.city : '')}}
                    </p>
                    <p class="card-text">
                      <span><b>Units: </b></span> {{item.project_unit_group ? item.project_unit_group : 'No Data'}}
                    </p>
                    <span *ngIf="item.project_status" class="project_status">
                      <label *ngIf="item.project_status == 'Under Construction'" class="label" style="background-color: #FF9000;">{{item.project_status}}</label>
                      <label *ngIf="item.project_status == 'Pre Launch'" class="label" style="background-color: #B6E517;">{{item.project_status}}</label>
                      <label *ngIf="item.project_status == 'Ready Possession'" class="label" style="background-color: #3E6BE8;">{{item.project_status}}</label>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div *ngIf="isProject == true" class="col-md-12 text-right">
              <pagination-controls (pageChange)="pageChange($event, 10)" class="my-pagination" id="property_grid"
                                   maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- End Project Grid View -->

          <!-- Start Project List View -->
          <div class="row" id="property_list_view">
            <ng-container *ngFor="let item of projectsList">
              <div *ngIf="item.name" (click)="openProjectInNewTab(item.id)"
                   class="col-md-12 single_property_list">
                <div class="row no-gutters">
                  <div class="col-md-12">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5">
                          <span class="card-title card_text_title">{{item.name}}</span>
                          <span class="card-text">
                            <i class="fa fa-map-marker"></i>
                            {{item.locality_title}} {{(item.city ? item.city.city : '')}}
                          </span>
                        </div>
                        <div class="col-md-7">
                          <p class="card-text">
                            <span><b>Units: </b></span> {{item.project_unit_group ? item.project_unit_group : 'No Data'}}
                            <span *ngIf="item.project_status" class="project_status">
                              <label *ngIf="item.project_status == 'Under Construction'" class="label" style="background-color: #FF9000;">{{item.project_status}}</label>
                              <label *ngIf="item.project_status == 'Pre Launch'" class="label" style="background-color: #B6E517;">{{item.project_status}}</label>
                              <label *ngIf="item.project_status == 'Ready Possession'" class="label" style="background-color: #3E6BE8;">{{item.project_status}}</label>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div *ngIf="isProject == true" class="col-md-12 text-right">
              <pagination-controls (pageChange)="pageChange($event, 10)" class="my-pagination" id="property_grid"
                                   maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- End Project List View -->

          <ng-container *ngIf="projectsList.length == 0 && moreFilter === 'close' && isLoading == false">
            <div [formGroup]="leadForm" class="lead_generator_main" id="lead_generator_div">
              <div class="contact-info-outer">
                <div class="row d-flex">
                  <div class="col-md-12">
                    <!-- <app-flash></app-flash> -->
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="first_name" [ngClass]="{ 'is-invalid': submitted && l.firstName.errors }" class="form-control"
                             formControlName="firstName" placeholder="First Name" />
                      <div *ngIf="submitted && l.firstName.errors" class="invalid-feedback">
                        <div *ngIf="l.firstName.errors.required">First Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="last_name" [ngClass]="{ 'is-invalid': submitted && l.lastName.errors }" class="form-control"
                             formControlName="lastName" placeholder="Last Name" />
                      <div *ngIf="submitted && l.lastName.errors" class="invalid-feedback">
                        <div *ngIf="l.lastName.errors.required">Last Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="email" [ngClass]="{ 'is-invalid': submitted && l.profileEmail.errors }" class="form-control" formControlName="profileEmail"
                             placeholder="Email Address" type="email" />
                      <div *ngIf="submitted && l.profileEmail.errors" class="invalid-feedback">
                        <div *ngIf="l.profileEmail.errors.required">Email is required</div>
                        <div *ngIf="l.profileEmail.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="phone" [ngClass]="{ 'is-invalid': submitted && l.mobileNumber.errors }" [pattern]="mobNumberPattern"
                             class="form-control" formControlName="mobileNumber"
                             placeholder="Mobile Number" />
                      <div *ngIf="submitted && l.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="l.mobileNumber.errors.required">Mobile Number is required</div>
                        <div
                          *ngIf="l.mobileNumber.errors.pattern || l.mobileNumber.errors.minlength || l.mobileNumber.errors.maxlength">
                          Please enter valid Mobile Number</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="save-button">
                <div class="row d-flex">
                  <div class="col-md-3">&nbsp;</div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button (click)="onLeadFormSubmit()" class="btn btn-block login-btn" id="submitProjLead" type="submit">Send Your Details</button>
                      <button class="btn btn-block login-btn loaderBtn" disabled style="display: none;" type="button">
                        <span class="spinSpan spinner-border" role="status"></span> Saving
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3">&nbsp;</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <app-property-list-footer></app-property-list-footer>

      </div>
    </div>
  </div>
</div>

<div class="change-view">
  <a (click)="toggleMapView()" class="show-map" href="javascript:void(0);"><i class="fa fa-map"></i> &nbsp; Map</a>
  <a (click)="toggleListView()" class="show-list" href="javascript:void(0);"><i class="fa fa-list"></i> &nbsp;
    List</a>
</div>
