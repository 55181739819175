<div id="developerDetail">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <ng-container *ngIf="isDeveloper === true">
          <div class="loading_div">
            <img class="loading_img" src="../../assets/img/web-image/loader.gif" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isDeveloper === false && !developerData.group_name">
    <div class="container dev_no_data">
      <div class="row">
        <div class="col-md-12 text-center">
          <img class="dev_no_data_img" src="../../assets/img/dev_no_data.png" />
        </div>
      </div>
    </div>
  </ng-container>

  <section *ngIf="developerData.group_name" class="developer-section">
    <div class="container developer-info">
      <div class="row">
        <div class="col-lg-3 col-md-12 dev-logo-row">
          <img alt="Developer-Logo" class="developer-img" src="{{ developerData.group_image_preview }}"/>
        </div>
        <div class="col-lg-9 col-md-12">
          <p class="developer-name">{{ developerData.group_name }}</p>
          <p class="developer-desc">
            {{ developerData.company_history }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="developerData && developerData.discount_present == 1" class="discount-section">
    <div class="container-fluid">
      <div class="row discount-row">
        <div class="col-md-12 text-center">
          <p class="discount-text">"SPECIAL OFFER" - BOOK YOUR PROPERTY AND GET {{ developerData.discount_offered }}% DISCOUNT. OFFER VALID FOR {{ (developerData.discount_days > 1 ? developerData.discount_days+' DAYS' : developerData.discount_days+' DAY') }}</p>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="onGoingProjects && onGoingProjects.length > 0" class="ongoing-project projects">
    <div class="container">
      <div class="row project-title-row">
        <div class="col-md-12">
          <div class="title">On Going / New Projects</div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let og_prj of onGoingProjects">
          <div class="col-md-4 ss_property_view">
            <div class="d-flex single_property_view">
              <a [routerLink]="['/project-details', 665 ]" class="card" href="#" target="_blank">
                <div [style.background-image]="'url(\'' + og_prj.image_preview + '\')'" class="grid_image_view" >
                </div>
                <div class="card-body">
                  <img alt="Sell" class="project-logo mb-2" src="{{ og_prj.project_logo_preview }}"/>
                  <h3 class="card-title">{{ og_prj.name }}</h3>
                  <p class="card-text">
                    {{ og_prj.locality_title }} {{ og_prj.city.city }}
                  </p>
                  <p class="card-text">
                    <b>Units: </b> {{ (og_prj.project_unit_group ? og_prj.project_unit_group : '--') }}
                  </p>
                  <!--<p class="card-text">
                    Possession : Dec 2024
                  </p>-->
                  <div class="project_status mt-2">
                    <label *ngIf="og_prj.project_type_id == 1 || og_prj.project_type_id == 7" class="label">
                      Residential
                    </label>
                    <label *ngIf="og_prj.project_type_id == 3 || og_prj.project_type_id == 7" class="label {{ (og_prj.project_type_id == 1 || og_prj.project_type_id == 7 ? 'ml-2' : '') }}">
                      Commercial
                    </label>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <section *ngIf="upComingProjects && upComingProjects.length > 0" class="upcoming-project projects">
    <div class="container">
      <div class="row project-title-row">
        <div class="col-md-12">
          <div class="title">Up-Coming Projects</div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let uc_prj of upComingProjects">
          <div class="col-md-4 ss_property_view">
            <div class="d-flex single_property_view">
              <a [routerLink]="['/project-details', 665 ]" class="card" href="#" target="_blank">
                <div [style.background-image]="'url(\'' + uc_prj.image_preview + '\')'" class="grid_image_view" >
                </div>
                <div class="card-body">
                  <img alt="Sell" class="project-logo mb-2" src="{{ uc_prj.project_logo_preview }}"/>
                  <h3 class="card-title">{{ uc_prj.name }}</h3>
                  <p class="card-text">
                    {{ uc_prj.locality_title }} {{ uc_prj.city.city }}
                  </p>
                  <p class="card-text">
                    <b>Units: </b> {{ (uc_prj.project_unit_group ? uc_prj.project_unit_group : '--') }}
                  </p>
                  <!--<p class="card-text">
                    Possession : Dec 2024
                  </p>-->
                  <div class="project_status mt-2">
                    <label *ngIf="uc_prj.project_type_id == 1 || uc_prj.project_type_id == 7" class="label">
                      Residential
                    </label>
                    <label *ngIf="uc_prj.project_type_id == 3 || uc_prj.project_type_id == 7" class="label {{ (uc_prj.project_type_id == 1 || uc_prj.project_type_id == 7 ? 'ml-2' : '') }}">
                      Commercial
                    </label>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <section *ngIf="pastProjects && pastProjects.length > 0" class="past-project projects">
    <div class="container">
      <div class="row project-title-row">
        <div class="col-md-12">
          <div class="title">Past Projects</div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let past_prj of pastProjects">
          <div class="col-md-4 ss_property_view">
            <div class="d-flex single_property_view">
              <a [routerLink]="['/project-details', 665 ]" class="card" href="#" target="_blank">
                <div [style.background-image]="'url(\'' + past_prj.image_preview + '\')'" class="grid_image_view" >
                </div>
                <div class="card-body">
                  <img alt="Sell" class="project-logo mb-2" src="{{ past_prj.project_logo_preview }}"/>
                  <h3 class="card-title">{{ past_prj.name }}</h3>
                  <p class="card-text">
                    {{ past_prj.locality_title }} {{ past_prj.city.city }}
                  </p>
                  <p class="card-text">
                    <b>Units: </b> {{ (past_prj.project_unit_group ? past_prj.project_unit_group : '--') }}
                  </p>
                  <!--<p class="card-text">
                    Possession : Dec 2024
                  </p>-->
                  <div class="project_status mt-2">
                    <label *ngIf="past_prj.project_type_id == 1 || past_prj.project_type_id == 7" class="label">
                      Residential
                    </label>
                    <label *ngIf="past_prj.project_type_id == 3 || past_prj.project_type_id == 7" class="label {{ (past_prj.project_type_id == 1 || past_prj.project_type_id == 7 ? 'ml-2' : '') }}">
                      Commercial
                    </label>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

</div>
