/* tslint:disable:no-string-literal variable-name no-inferrable-types quotemark max-line-length no-shadowed-variable prefer-const no-var-keyword object-literal-shorthand object-literal-key-quotes triple-equals whitespace prefer-for-of */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API_BASE_URL, API_ENDPOINTS } from 'src/app/global';
import { environment } from 'src/environments/environment';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
declare var $: any;

@Component({
  selector: 'app-home',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  emiForm: FormGroup;
  cityForm: FormGroup;
  myControl = new FormControl();
  inquiryform: FormGroup;
  submitted = false;
  options: string[] = ['One', 'Two', 'Three'];
  values = '';
  transaction_type_id: string = '1';
  propertytypeid: string = '1';
  suitable_for: string = '3';
  boardType: string = '1';
  selectedCity: string = '';
  selectedCityAreaList: string = '';
  selectedSuitableFor: string = '';
  cityName: string = '';
  isLoggedIn;

  dropdownList = [];
  selectedItems = [];
  singleSelectdropdownSettings = {};
  multiSelectdropdownSettings = {};
  multiSelectCitydropdownSettings = {};

  bedrooms = [];
  bathroom = [];
  amenities = [];
  city_options = [];
  city_property_options = [];
  schoolList = [];
  min_budget;
  max_budget;
  construction_status;
  constructionStatusList;
  pTypes;
  ptypes_list;
  fpType;
  fpType_list;
  budgetList;
  bedroomList;
  bathroomList;
  amenitiesList;
  city_eloc;
  city_eloc1;
  school;
  isSchool = true;
  isAffordableCal = true;
  isAmenities = true;
  isNoOfStaff = false;
  isBedrooms = true;

  personaImgSrc: string = '';
  personaTitle: string = '';
  personaText: string = '';
  mobileupdateuser: string = '';

  home_price: number = 3000000;
  down_payment: number = 450000;
  new_home_price: number = this.home_price - this.down_payment;
  down_payment_per: number = 15;
  loan_program_options: any = [];
  featuresProjectList: any = [];
  featuresPropertyList: any = [];
  allFeaturesProjectList: any = [];
  AllFeaturesPropertyList: any = [];
  loan_program: number = 4;
  int_rate: number = 7.25;

  areaSearchPropTypeId: string = '1';
  areaSearchTypeText: string = 'Residential';
  schollfullname: string = '';

  sellingSearchInput;
  sellingSearchInputForSchool;
  carnivalPropertyInput;
  searchPersonalInput;
  personal_area_title: string = '';
  area_title: string = '';
  area_lat: string = '';
  area_lng: string = '';
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  phone: string = '';
  mobilenumber: string = '';
  inquiryName: string = '';
  inquiryEmail: string = '';
  inquiryMobileNo: string = '';
  locality_elocs: string = '';
  userInfo;
  userEmail: string = '';
  activationKey: string = '';
  user_curr_city: string = '';
  prop_type_id: string = '3';
  lat: number = 0;
  lng: number = 0;
  minNoOfStaff: number = 10;
  perPersonSqFt: number = 50;
  perPersonRangeSqFt: number = 20;
  filterPropertyType: number = 1;
  projectStatus = '';
  isProjectSearch = 0;
  propertyList: any = [];


  customOptions: any = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<span aria-label="Previous">‹</span>', '<span aria-label="Next">›</span>'],
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },

    },
  }

  constructor(private ApiService: ApiService, private router: Router, private title: Title, private meta: Meta, private formBuilder: FormBuilder, private route: ActivatedRoute, private FlashService: FlashService) {

  }
  ngOnInit(): void {

    this.getLocation();
    this.getPropertyList()
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.isLoggedIn = this.ApiService.loginCheck();
    this.userInfo = JSON.parse(localStorage.getItem('currentUser'));
    $('.residentialDiv').show();
    $('.commercialDiv').hide();
    $('.investDiv').hide();
    this.getCities();
    this.getCitiesProperty();
    this.getLoanMonthly();
    this.getTopProjectList();
    // this.getUserFeaturesPropertyList();
    this.getSuitableFor(3);
    this.emiForm = this.formBuilder.group({
      home_price: ['', [Validators.minLength(1), Validators.min(1)]],
      down_payment: ['', [Validators.minLength(1), Validators.min(1)]],
      down_payment_per: ['', [Validators.minLength(1), Validators.min(1)]],
      int_rate: ['', [Validators.minLength(1), Validators.min(1)]],
      home_ins_year: ['', [Validators.minLength(0), Validators.min(0)]]
    });
    this.cityForm = this.formBuilder.group({
      profilecity: ['', [Validators.required]]
    });
    setTimeout(() => {
      const userCurrCity = localStorage.getItem('user_curr_city');
      this.changeHeaderClass();
      $('#searchinput').tagsinput({
        itemValue: 'eLoc',
        itemText: 'placeName',
        typeaheadjs: {
          displayKey: 'placeNameFull',
          source: (search, sync, async) => {
            if (search.length >= 2) {
              return $.ajax({
                type: 'GET',
                dataType: 'json',
                url: API_BASE_URL + '/master/getLocality',
                data: { is_web_api: 1, search: search, search_type: 'property' },
                success: (response) => {
                  response = response.data;
                  return async(response);
                }
              });
            }
          }
        },
      });

      $('#searchinput').on('itemAdded', (event) => {
        if (event.item && Number(event.item.is_project) === 1) {
          this.isProjectSearch = Number(event.item.is_project);
        }
      });

      $('.twitter-typeahead').click(() => {
        $('#filterserch').show();
        $('.banner-search-box').addClass('active');
      });

      $('.twitter-typeahead').focusout(() => {
        $('.banner-search-box').removeClass('active');
      });

      this.sellingSearchInput = $('#sellingSearchInput');

      if (userCurrCity) {
        this.sellingSearchInput.val(userCurrCity);
        this.area_title = userCurrCity
        this.area_lat = this.lat as any
        this.area_lng = this.lng as any
      }


      this.sellingSearchInput.typeahead(null, {
        displayKey: 'placeNameFull',
        source: (search, sync, async) => {          
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL + '/master/getLocality',
              //search_type: 'other' // defore
              data: { is_web_api: 1, search: search, search_type: 'property' },
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      this.sellingSearchInput.on('typeahead:selected', (e, datalocitem) => {
        setTimeout(() => {
          this.sellingSearchInput.val(datalocitem.placeName);
        }, 20);
        this.area_title = datalocitem.placeName;
        this.area_lat = datalocitem.latitude;
        this.area_lng = datalocitem.longitude;
      });

      this.carnivalPropertyInput = $('#carnivalPropertyInput');
      this.carnivalPropertyInput.typeahead(null, {
        displayKey: 'placeNameFull',
        source: (search, sync, async) => {
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL + '/master/getLocality',
              data: { is_web_api: 1, search: search, search_type: 'other' },
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      this.carnivalPropertyInput.on('typeahead:selected', (e, datalocitem) => {
        this.area_title = datalocitem.placeName;
        this.area_lat = datalocitem.latitude;
        this.area_lng = datalocitem.longitude;
      });

      this.searchPersonalInput = $('.searchPersonalInput');
      this.searchPersonalInput.typeahead(null, {
        itemValue: 'eLoc',
        itemText: 'placeName',
        displayKey: 'placeNameFull',
        source: (search, sync, async) => {
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL + '/master/getLocality',
              data: { is_web_api: 1, search: search, search_type: 'other' },
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      // const userCurrCity = localStorage.getItem('user_curr_city');
      if (userCurrCity) {
        $.each(userCurrCity.split(','), (index, value) => {
          $('#searchinput').tagsinput('add', { "placeNameFull": value, "placeName": value, "eLoc": value });
        });
        this.searchPersonalInput.typeahead('val', userCurrCity);
        this.personal_area_title = userCurrCity;
      }
      this.searchPersonalInput.on('typeahead:selected', (e, datalocitem) => {
        this.personal_area_title = datalocitem.placeName;
      });

    }, 500);

    setTimeout(() => {
      this.sellingSearchInputForSchool = $('#sellingSearchInputforSchool');
      this.sellingSearchInputForSchool.typeahead(null, {
        displayKey: 'placeNameFull',
        source: (search, sync, async) => {
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL + '/master/getLocality',
              data: { is_web_api: 1, search: search, search_type: 'other' },
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      this.sellingSearchInputForSchool.on('typeahead:selected', (e, datalocitem) => {
        this.area_title = datalocitem.placeName;
        this.area_lat = datalocitem.latitude;
        this.area_lng = datalocitem.longitude;
      });

      this.changeHeaderClass();
    }, 200);

    this.constructionStatusList = [
      { id: 1, text: 'New' },
      { id: 1, text: 'Resale' }
    ];
    this.pTypes = [
      { id: 1, text: 'All Residential' },
      { id: 5, text: 'Home' },
      { id: 4, text: 'Apartment' },
      { id: 3, text: 'Plot' },
      { id: 2, text: 'All Projects' }
    ];
    this.fpType = [
      { id: 1, text: 'Residential' },
      { id: 2, text: 'Commercial' },
      { id: 3, text: 'Investment' }
    ];
    this.fpType_list = [{ id: 1, text: 'Residential' }];
    this.budgetList = [
      { id: 5 * 100000, text: '5 Lac' },
      { id: 10 * 100000, text: '10 Lac' },
      { id: 15 * 100000, text: '15 Lac' },
      { id: 20 * 100000, text: '20 Lac' },
      { id: 22 * 100000, text: '22 Lac' },
      { id: 25 * 100000, text: '25 Lac' },
      { id: 30 * 100000, text: '30 Lac' },
      { id: 35 * 100000, text: '35 Lac' },
      { id: 40 * 100000, text: '40 Lac' },
      { id: 45 * 100000, text: '45 Lac' },
      { id: 50 * 100000, text: '50 Lac' },
      { id: 55 * 100000, text: '55 Lac' },
      { id: 60 * 100000, text: '60 Lac' },
      { id: 65 * 100000, text: '65 Lac' },
      { id: 70 * 100000, text: '70 Lac' },
      { id: 75 * 100000, text: '75 Lac' },
      { id: 80 * 100000, text: '80 Lac' },
      { id: 85 * 100000, text: '85 Lac' },
      { id: 90 * 100000, text: '90 Lac' },
      { id: 95 * 100000, text: '95 Lac' },
      { id: 100 * 100000, text: '1 Cr' },
      { id: 1.2 * 10000000, text: '1.2 Cr' },
      { id: 1.4 * 10000000, text: '1.4 Cr' },
      { id: 1.6 * 10000000, text: '1.6 Cr' },
    ];
    this.bedroomList = [
      { id: 1, text: '1BHK' },
      { id: 2, text: '2BHK' },
      { id: 3, text: '3BHK' },
      { id: 4, text: '4BHK' },
      { id: 5, text: '5BHK' }
    ];
    this.bathroomList = [
      { id: 1, text: '1' },
      { id: 2, text: '2' },
      { id: 3, text: '3' },
      { id: 4, text: '4' },
      { id: 5, text: '5' }
    ];
    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
    this.schoolList = [
      { id: 1, text: 'GSEB' },
      { id: 2, text: 'CBSE' },
      { id: 3, text: 'IGCSE' },
      { id: 4, text: 'IB' }
    ];
    this.amenitiesList = [
      { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
      { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
      { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
      { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
      { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
      { id: 35, text: 'Power Back up', icon: 'fa fa-lightbulb-o' },
      { id: 41, text: 'Library', icon: 'fa fa-book' },
      { id: 42, text: '24 Hours Water Supply', icon: 'fa fa-tint' },
      { id: 43, text: 'Mini Theater', icon: 'fa fa-video-camera' },
      { id: 44, text: 'Cafeteria', icon: 'fa fa-coffee' },
      { id: 45, text: 'Vastu Compliant', icon: 'fa fa-street-view' }
    ];
    this.amenities = [
      { id: 40, text: 'Gated Community' },
      { id: 16, text: 'Gym' },
      { id: 7, text: 'Swimming Pool' },
      { id: 1, text: 'Garden' },
      { id: 14, text: 'Jogging Track' },
      { id: 35, text: 'Power Back up' },
      { id: 41, text: 'Library' },
      { id: 42, text: '24 Hours Water Supply' },
      { id: 43, text: 'Mini Theater' },
      { id: 44, text: 'Cafeteria' },
      { id: 45, text: 'Vastu Compliant' }
    ];
    this.loan_program_options = [
      { id: 1, title: '5 Years' },
      { id: 2, title: '10 Years' },
      { id: 3, title: '15 Years' },
      { id: 4, title: '20 Years' }
    ];
    this.singleSelectdropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
    };
    this.multiSelectdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 'All'
    };
    this.multiSelectCitydropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1
    };

    this.inquiryform = this.formBuilder.group({
      inquiryname: ['', [Validators.required]],
      inquiryemail: ['', [Validators.required, Validators.email]],
      inquirymobileno: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
    });

    if ((this.route.snapshot.queryParams.email != undefined && this.route.snapshot.queryParams.email != "") && (this.route.snapshot.queryParams.activation_key != undefined && this.route.snapshot.queryParams.activation_key != "")) {
      this.userEmail = this.route.snapshot.queryParams.email;
      this.activationKey = this.route.snapshot.queryParams.activation_key;
      this.showUserVerified(this.userEmail, this.activationKey);
    }

    $('.carousel').carousel({
      interval: 5000,
      pause: false,
    });
  }

  handleSellingInPut(){
    this.sellingSearchInput.val(this.area_title);
  }

  showUserVerified(email, activationkey) {
    this.isLoggedIn = this.ApiService.loginCheck();
    var form_data = new FormData();
    form_data.append('email', email);
    form_data.append('activation_key', activationkey);
    this.ApiService.postRequest(API_ENDPOINTS.API_VERIFY_USER_URL, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        if (this.isLoggedIn) {
          this.router.navigate(['']);
        } else {
          this.FlashService.success('User is verified. Login to continue further.');
          this.openLoginModel();
        }
      } else {
        if (apiResult.error && apiResult.error_code === 'err-000040') {
          this.mobileupdateuser = apiResult.data.id;
          this.openOtpModel();
        }
        // this.FlashService.error(apiResult.error_code);
      }
    });
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  openOtpModel(): void {
    $('#otpModal #userId').val(this.mobileupdateuser);
    $('#otpModal').modal('toggle');
  }

  get g() {
    return this.emiForm.controls;
  }

  get i() {
    return this.inquiryform.controls;
  }
  get h() {
    return this.cityForm.controls;
  }

  getCities() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_CITIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        if (apiResult.data.length > 0) {
          this.city_options = apiResult.data;
        }
      }
    });
  }

  getCitiesProperty() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_PROPERTY_CITIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        const userCurrCity = localStorage.getItem('user_curr_city');
        if (userCurrCity && apiResult.data.length > 0) { // If User Current Location is available then select that City
          for (let i = 0; i < apiResult.data.length; i++) {
            if (apiResult.data[i]['title'] === userCurrCity) {
              this.city_eloc1 = apiResult.data[i]['id'];
            }
          }
        } else { // Or if No User Current Location found and select first city from cities dropdown.
          if (apiResult.data.length > 0) {
            this.city_eloc1 = apiResult.data[0]['id'];
          }
        }
        this.selectedCity = this.city_eloc1;
        this.city_property_options = apiResult.data;
        form_data.append('city_eloc', this.city_eloc1);
        form_data.append('property_type_id', '1');
        this.getSelectedCityArea(form_data);
      }
    });
  }

  getCitiesArea(city_eloc) {
    this.selectedCity = city_eloc;
    var form_data = new FormData();
    form_data.append('city_eloc', this.selectedCity);
    form_data.append('property_type_id', '1');
    this.getSelectedCityArea(form_data);
  }

  getSuitableFor(propType) {
    this.prop_type_id = propType;
    var form_data = new FormData();
    if (propType == 3) {
      this.ApiService.postRequest(API_ENDPOINTS.API_GET_SUITABLE_FOR_COMM, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          if (apiResult.data.length > 0) {
            this.selectedSuitableFor = apiResult.data;
          }
        }
      });
    } else {
      this.ApiService.postRequest(API_ENDPOINTS.API_GET_SUITABLE_FOR_RESD, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          if (apiResult.data.length > 0) {
            this.selectedSuitableFor = apiResult.data;
          }
        }
      });
    }
  }

  getSelectedCityArea(form_data) {
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_PROPERTY_AREAWISE, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.selectedCityAreaList = apiResult.data;
      }
    });
  }

  changeHeaderClass() {
    $('#header').addClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » India Real Estate Property Site - Buy Sell Rent Properties');
    this.meta.updateTag({
      name: 'description',
      content: 'Search properties on empirerealtors.com. Buy, rent or sale of residential & commercial property. Check real estate prices, property trends.'
    });
  }

  hideshow(action) {
    if (action) {
      $('#filterserch').show();
    } else {
      $('#filterserch').hide();
    }
  }

  searchTypeChange(id) {
    this.propertytypeid = id;
    if (id == "1") {
      this.pTypes = [
        { id: 1, text: 'All Residential' },
        { id: 5, text: 'Home' },
        { id: 4, text: 'Apartment' },
        { id: 3, text: 'Plot' },
        { id: 2, text: 'All Projects' }
      ];
      this.constructionStatusList = [
        { id: 1, text: 'New' },
        { id: 1, text: 'Resale' }
      ];
      this.budgetList = [
        { id: 5 * 100000, text: '5 Lac' },
        { id: 10 * 100000, text: '10 Lac' },
        { id: 15 * 100000, text: '15 Lac' },
        { id: 20 * 100000, text: '20 Lac' },
        { id: 25 * 100000, text: '25 Lac' },
        { id: 30 * 100000, text: '30 Lac' },
        { id: 35 * 100000, text: '35 Lac' },
        { id: 40 * 100000, text: '40 Lac' },
        { id: 45 * 100000, text: '45 Lac' },
        { id: 50 * 100000, text: '50 Lac' },
        { id: 55 * 100000, text: '55 Lac' },
        { id: 60 * 100000, text: '60 Lac' },
        { id: 65 * 100000, text: '65 Lac' },
        { id: 70 * 100000, text: '70 Lac' },
        { id: 75 * 100000, text: '75 Lac' },
        { id: 80 * 100000, text: '80 Lac' },
        { id: 85 * 100000, text: '85 Lac' },
        { id: 90 * 100000, text: '90 Lac' },
        { id: 95 * 100000, text: '95 Lac' },
        { id: 100 * 100000, text: '1 Cr' },
        { id: 1.2 * 10000000, text: '1.2 Cr' },
        { id: 1.4 * 10000000, text: '1.4 Cr' },
        { id: 1.6 * 10000000, text: '1.6 Cr' },
      ];
    }
    if (id == "3") {
      this.constructionStatusList = [];
      this.pTypes = [
        { id: 34, text: 'Office' },
        { id: 38, text: 'Showroom' },
        { id: 45, text: 'Plot' },
        { id: 28, text: 'Hotel' },
        { id: 53, text: 'Warehouse' },
        { id: 52, text: 'Godown' },
        { id: 66, text: 'Industrial Land' },
        { id: 48, text: 'Industrial Shed' },
        { id: 2, text: 'All Projects' }
      ];
      this.budgetList = [
        { id: 5 * 100000, text: '5 Lac' },
        { id: 10 * 100000, text: '10 Lac' },
        { id: 15 * 100000, text: '15 Lac' },
        { id: 20 * 100000, text: '20 Lac' },
        { id: 25 * 100000, text: '25 Lac' },
        { id: 30 * 100000, text: '30 Lac' },
        { id: 35 * 100000, text: '35 Lac' },
        { id: 40 * 100000, text: '40 Lac' },
        { id: 45 * 100000, text: '45 Lac' },
        { id: 50 * 100000, text: '50 Lac' },
        { id: 55 * 100000, text: '55 Lac' },
        { id: 60 * 100000, text: '60 Lac' },
        { id: 65 * 100000, text: '65 Lac' },
        { id: 70 * 100000, text: '70 Lac' },
        { id: 75 * 100000, text: '75 Lac' },
        { id: 80 * 100000, text: '80 Lac' },
        { id: 85 * 100000, text: '85 Lac' },
        { id: 90 * 100000, text: '90 Lac' },
        { id: 95 * 100000, text: '95 Lac' },
        { id: 100 * 100000, text: '1 Cr' },
        { id: 1.2 * 10000000, text: '1.2 Cr' },
        { id: 1.4 * 10000000, text: '1.4 Cr' },
        { id: 1.6 * 10000000, text: '1.6 Cr' },
      ];
    }
    if (id == "4") {
      this.constructionStatusList = [];
      this.pTypes = [
        { id: 3, text: 'Residential Plot' },
        { id: 59, text: 'Commercial Plot' },
        { id: 57, text: 'Agriculture Plot' },
        { id: 62, text: 'Non-Agriculture Plot' },
        { id: 2, text: 'All Projects' }
      ];
      this.budgetList = [
        { id: 5 * 100000, text: '5 Lac' },
        { id: 10 * 100000, text: '10 Lac' },
        { id: 15 * 100000, text: '15 Lac' },
        { id: 20 * 100000, text: '20 Lac' },
        { id: 25 * 100000, text: '25 Lac' },
        { id: 30 * 100000, text: '30 Lac' },
        { id: 35 * 100000, text: '35 Lac' },
        { id: 40 * 100000, text: '40 Lac' },
        { id: 45 * 100000, text: '45 Lac' },
        { id: 50 * 100000, text: '50 Lac' },
        { id: 55 * 100000, text: '55 Lac' },
        { id: 60 * 100000, text: '60 Lac' },
        { id: 65 * 100000, text: '65 Lac' },
        { id: 70 * 100000, text: '70 Lac' },
        { id: 75 * 100000, text: '75 Lac' },
        { id: 80 * 100000, text: '80 Lac' },
        { id: 85 * 100000, text: '85 Lac' },
        { id: 90 * 100000, text: '90 Lac' },
        { id: 95 * 100000, text: '95 Lac' },
        { id: 100 * 100000, text: '1 Cr' },
        { id: 1.2 * 10000000, text: '1.2 Cr' },
        { id: 1.4 * 10000000, text: '1.4 Cr' },
        { id: 1.6 * 10000000, text: '1.6 Cr' },
      ];
    }
    if (id == "5") {
      this.pTypes = [
        { id: 1, text: 'Residential' },
        { id: 3, text: 'Commercial' },
        { id: 2, text: 'All Projects' }
      ];
      this.constructionStatusList = [
        { id: 1, text: '3% ROI' },
        { id: 1, text: '4% ROI' },
        { id: 1, text: '5% ROI' },
        { id: 1, text: '6% ROI' }
      ];
      this.budgetList = [
        { id: 5 * 100000, text: '5 Lac' },
        { id: 10 * 100000, text: '10 Lac' },
        { id: 15 * 100000, text: '15 Lac' },
        { id: 20 * 100000, text: '20 Lac' },
        { id: 25 * 100000, text: '25 Lac' },
        { id: 30 * 100000, text: '30 Lac' },
        { id: 35 * 100000, text: '35 Lac' },
        { id: 40 * 100000, text: '40 Lac' },
        { id: 45 * 100000, text: '45 Lac' },
        { id: 50 * 100000, text: '50 Lac' },
        { id: 55 * 100000, text: '55 Lac' },
        { id: 60 * 100000, text: '60 Lac' },
        { id: 65 * 100000, text: '65 Lac' },
        { id: 70 * 100000, text: '70 Lac' },
        { id: 75 * 100000, text: '75 Lac' },
        { id: 80 * 100000, text: '80 Lac' },
        { id: 85 * 100000, text: '85 Lac' },
        { id: 90 * 100000, text: '90 Lac' },
        { id: 95 * 100000, text: '95 Lac' },
        { id: 100 * 100000, text: '1 Cr' },
        { id: 1.2 * 10000000, text: '1.2 Cr' },
        { id: 1.4 * 10000000, text: '1.4 Cr' },
        { id: 1.6 * 10000000, text: '1.6 Cr' },
      ];
    }
    if (id == "6") {
      this.constructionStatusList = [];
      this.pTypes = [
        { id: 1, text: 'Residential' },
        { id: 3, text: 'Commercial' },
        { id: 2, text: 'All Projects' }
      ];
      this.budgetList = [
        { id: 3 * 10000000, text: 'Above 3 Cr' },
        { id: 5 * 10000000, text: 'Above 5 Cr' },
        { id: 6 * 10000000, text: 'Above 6 Cr' },
        { id: 10 * 10000000, text: 'Above 10 Cr' },
        { id: 15 * 10000000, text: 'Above 15 Cr' }
      ];
    }
  }

  searchProperty() {
    var data = {};
    var form_data = new FormData();
    this.isLoggedIn = this.ApiService.loginCheck();
    data['property_type_id'] = this.propertytypeid;
    if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      data['transaction_type_id'] = $(".seconddescription").find('.active').data('transactiontypeid');
    }

    if (this.min_budget && this.min_budget.length) {
      data['min_budget_amount'] = this.min_budget[0].id;
    }
    if (this.max_budget && this.max_budget.length) {
      data['max_budget_amount'] = this.max_budget[0].id;
    }
    if (this.construction_status && this.construction_status.length) {
      data['property_status_id'] = this.construction_status[0].id;
    }
    if (this.propertytypeid == "1") {
      if (this.bedrooms && this.bedrooms.length) {
        data['bedrooms'] = (this.bedrooms.map(value => value.id)).join(',');
      }
    }
    if ($('#searchinput').tagsinput('items') && $('#searchinput').tagsinput('items').length) {
      data['locality_elocs'] = ($('#searchinput').tagsinput('items').map(value => value.eLoc)).join(',');
      this.locality_elocs = data['locality_elocs'];
    }

    if (!data['locality_elocs']) {
      this.FlashService.error('Please enter a Location');
      return
    }

    if (this.userInfo && this.isProjectSearch === 0) {
      if (localStorage.getItem('property_type_id' + this.userInfo.id) && localStorage.getItem('transaction_type_id' + this.userInfo.id)) {
        var localData = {};

        localData['property_type_id'] = localStorage.getItem('property_type_id' + this.userInfo.id);
        localData['transaction_type_id'] = localStorage.getItem('transaction_type_id' + this.userInfo.id);
        localData['locality_elocs'] = data['locality_elocs'];
        if (localStorage.getItem('page_name' + this.userInfo.id) && localStorage.getItem('page_name' + this.userInfo.id) == 'property') {
          this.router.navigate(['/property'], { queryParams: localData });
        } else if (localStorage.getItem('page_name' + this.userInfo.id) && localStorage.getItem('page_name' + this.userInfo.id) == 'projects') {
          this.router.navigate(['/projects'], { queryParams: localData });
        } else {
          data['page'] = 'search';
          this.router.navigate(['/transaction-type'], { queryParams: data });
        }
      } else {
        data['page'] = 'search';
        this.router.navigate(['/transaction-type'], { queryParams: data });
      }
    } else if (this.isProjectSearch === 1) {
      this.router.navigate(['/projects'], { queryParams: data });
    } else {
      data['page'] = 'search';
      this.router.navigate(['/transaction-type'], { queryParams: data });
    }
  }

  clearFilter() {
    this.ptypes_list = [];
    this.construction_status = [];
    this.min_budget = [];
    this.max_budget = [];
    this.bedrooms = [];
    $('#searchinput').tagsinput('removeAll');
  }

  changeAreaType(id) {
    this.areaSearchPropTypeId = id;
    if (this.areaSearchPropTypeId == "1") {
      this.areaSearchTypeText = 'Residential';
      $("#areacommercial").removeClass('active');
      $('#arearesidential').addClass('active');
    } else if (this.areaSearchPropTypeId == "3") {
      this.areaSearchTypeText = 'Commercial';
      $("#arearesidential").removeClass('active');
      $('#areacommercial').addClass('active');
    }
    var form_data = new FormData();
    form_data.append('city_eloc', this.selectedCity);
    form_data.append('property_type_id', this.areaSearchPropTypeId);
    this.getSelectedCityArea(form_data);
  }

  applyFilterByArea(name) {
    var data = {};
    this.cityName = name;
    data['locality_elocs'] = this.cityName;
    this.router.navigate(['/buy'], { queryParams: data });
  }

  applyFilterSuitableFor(name) {
    var data = {};
    data['prop_type_id'] = this.prop_type_id;
    data['suitable_for_id'] = name;
    this.router.navigate(['/suitable-property'], { queryParams: data });
  }

  applyFilterBySchool(board_type) {
    var data = {};
    this.boardType = board_type;
    const userCurrCity = localStorage.getItem('user_curr_city');
    if (userCurrCity) {
      data['locality_elocs'] = userCurrCity;
    }
    data['board_type'] = board_type;
    this.router.navigate(['/buy'], { queryParams: data });
  }

  searchCityProperty(page) {
    var data = {};
    data['property_type_id'] = this.areaSearchPropTypeId;
    data['transaction_type_id'] = $(".seconddescription").find('.active').data('transactiontypeid');
    data['locality_elocs'] = this.cityName;
    if (page === 'project') {
      this.router.navigate(['/projects'], { queryParams: data });
    } else {
      this.router.navigate(['/property'], { queryParams: data });
    }
    $("#cityFilterModel").modal('hide');
  }

  searchSchoolProperty(page) {
    var data = {};
    if (this.area_title != "" && this.area_lat != "" && this.area_lng != "") {
      data['transaction_type_id'] = 4;
      data['board_type'] = this.boardType;
      data['property_type_id'] = this.transaction_type_id;
      data['locality_elocs'] = this.area_title;
      if (page === 'project') {
        this.router.navigate(['/projects'], { queryParams: data });
      } else {
        this.router.navigate(['/property'], { queryParams: data });
      }
      $("#schoolFilterModel").modal('hide');
    } else {
      $('#sellingSearchInputforSchool').focus();
    }
  }

  openPersonaModel(id) {
    this.budgetList = this.budgetList.filter(x => x.text !== '1.8 Cr' && x.text !== '2 Cr')

    /******* Home *******/
    if (id == 2) {
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isNoOfStaff = false;
      this.isBedrooms = true;
      this.filterPropertyType = 1;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
        { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
        { id: 35, text: 'Power Back up', icon: 'fa fa-lightbulb-o' },
        { id: 41, text: 'Library', icon: 'fa fa-book' },
        { id: 42, text: '24 Hours Water Supply', icon: 'fa fa-tint' },
        { id: 43, text: 'Mini Theater', icon: 'fa fa-video-camera' },
        { id: 44, text: 'Cafeteria', icon: 'fa fa-coffee' },
        { id: 45, text: 'Vastu Compliant', icon: 'fa fa-street-view' }
      ];
      this.amenities = [
        { id: 16, text: 'Gym' },
        { id: 1, text: 'Garden' },
        { id: 40, text: 'Gated Community' },
        { id: 7, text: 'Swimming Pool' },
        { id: 14, text: 'Jogging Track' },
        { id: 35, text: 'Power Back up' },
        { id: 41, text: 'Library' },
        { id: 42, text: '24 Hours Water Supply' },
        { id: 43, text: 'Mini Theater' },
        { id: 44, text: 'Cafeteria' },
        { id: 45, text: 'Vastu Compliant' }
      ];
      this.min_budget = [{ id: 22 * 100000, text: '22 Lac' }];
      this.max_budget = [{ id: 30 * 100000, text: '30 Lac' }];
      this.bedrooms = [{ id: 2, text: '2BHK' }, { id: 3, text: '3BHK' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/single-property-families.jpg';
      this.personaTitle = 'Young Buyer';
      this.personaText = 'Amit a young engineer with work experience of 4 years with age of 28, just got married 2 years back drawing salary for 35K his wife also looking for Job - as School Teacher - Both combine  will  draw Salary of 50K. They currently live with their Parents - Parents do not need financial support for next 5 years - they know the benefits of investing early in their carriers and now are looking for home which their salary can afford.';
    }
    if (id == 4) {
      this.filterPropertyType = 1;
      this.isSchool = true;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isBedrooms = true;
      this.isNoOfStaff = false;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
        { id: 46, text: 'Splash Pool', icon: 'fa fa-s15' },
        { id: 2, text: 'Children Play Area', icon: 'fa fa-child' },
        { id: 19, text: 'C. C. TV', icon: 'fa fa-camera' },
        { id: 6, text: 'Indoor Game Room', icon: 'fa fa-gamepad' },
        { id: 5, text: 'Basket Ball Court', icon: 'fa fa-futbol-o' }
      ];
      this.amenities = [
        { id: 40, text: 'Gated Community' },
        { id: 16, text: 'Gym' },
        { id: 7, text: 'Swimming Pool' },
        { id: 1, text: 'Garden' },
        { id: 14, text: 'Jogging Track' },
        { id: 46, text: 'Splash Pool' },
        { id: 2, text: 'Children Play Area' },
        { id: 19, text: 'C. C. TV' },
        { id: 6, text: 'Indoor Game Room' },
        { id: 5, text: 'Basket Ball Court' }
      ];
      this.min_budget = [{ id: 30 * 100000, text: '30 Lac' }];
      this.max_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.bedrooms = [{ id: 2, text: '2BHK' }, { id: 3, text: '3BHK' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/kids-lovable-properties.jpg';
      this.personaTitle = 'Kids Safe Properties';
      this.personaText = 'Meeta & Harish are married happily, having one kid Kabir. Both parents are working and their choice is to find a property which will make Kabir comfortable going to play center close by or a School which is close to their residence. Their choice is where Kabir can find company after hours and play area for him with a gated community. Currently 2 -3 BHK is ideal for them, as , even if their parents join them - their parents feel comfortable in their new house.';
    }
    if (id == 1) {
      this.filterPropertyType = 1;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isBedrooms = true;
      this.isNoOfStaff = false;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 3, text: 'Senior Citizens Park', icon: 'fa fa-blind' },
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
        { id: 19, text: 'C. C. TV', icon: 'fa fa-camera' },
        { id: 6, text: 'Indoor Game Room', icon: 'fa fa-gamepad' }
      ];
      this.amenities = [
        { id: 3, text: 'Senior Citizens Park' },
        { id: 1, text: 'Garden' },
        { id: 14, text: 'Jogging Track' },
        { id: 40, text: 'Gated Community' },
        { id: 16, text: 'Gym' },
        { id: 7, text: 'Swimming Pool' },
        { id: 19, text: 'C. C. TV' },
        { id: 6, text: 'Indoor Game Room' }
      ];
      this.min_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.max_budget = [{ id: 75 * 100000, text: '75 Lac' }];
      this.bedrooms = [{ id: 2, text: '2BHK' }, { id: 3, text: '3BHK' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/senior-citizen-properties.jpg';
      this.personaTitle = 'Senior Buyers';
      this.personaText = 'Saritaben (60) & Surendrabhai(62) have a big house and their kids are settled abroad with no planning to return back to their home country. It has been stressful for Seniors to maintain their current home as mostly they only use ground floor - They want to have peaceful and enjoyable life having all things close by to them like easy of access to transportation, temples, commercial activity like banks and routine needs of course Hospitals would be their criteria as well . Property if this choice if 2-3 BHK on single floor would be great for them.';
    }
    if (id == 3) {
      this.filterPropertyType = 1;
      this.isSchool = true;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isBedrooms = true;
      this.isNoOfStaff = false;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 2, text: 'Children Play Area', icon: 'fa fa-child' },
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
      ];
      this.amenities = [
        { id: 1, text: 'Garden' },
        { id: 2, text: 'Children Play Area' },
        { id: 40, text: 'Gated Community' },
        { id: 16, text: 'Gym' },
        { id: 7, text: 'Swimming Pool' },
      ];
      this.min_budget = [{ id: 15 * 100000, text: '15 Lac' }];
      this.max_budget = [{ id: 25 * 100000, text: '25 Lac' }];
      this.onEMICalPriceChange();
      this.bedrooms = [{ id: 2, text: '2BHK' }];
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/working-parents.jpg';
      this.personaTitle = 'Affordable Housing';
      this.personaText = 'When you sell and buy with Empire Realtors, you get full service and save thousands in fees.We get more eyes on your listing as India’s #1 brokerage website. When you sell and buy with Empire Realtors, you get full service and save thousands in fees. We get more eyes on your listing as India’s #1 brokerage website.';
    }

    /******* Home *******/
    /******* Commercial *******/
    if (id == 7) {
      this.filterPropertyType = 3;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isNoOfStaff = true;
      this.isBedrooms = false;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
        { id: 46, text: 'Splash Pool', icon: 'fa fa-s15' },
        { id: 2, text: 'Children Play Area', icon: 'fa fa-child' },
        { id: 19, text: 'C. C. TV', icon: 'fa fa-camera' },
        { id: 6, text: 'Indoor Game Room', icon: 'fa fa-gamepad' },
        { id: 5, text: 'Basket Ball Court', icon: 'fa fa-futbol-o' }
      ];
      this.amenities = [
        { id: 40, text: 'Gated Community' },
        { id: 16, text: 'Gym' },
        { id: 7, text: 'Swimming Pool' },
        { id: 1, text: 'Garden' },
        { id: 14, text: 'Jogging Track' },
        { id: 46, text: 'Splash Pool' },
        { id: 2, text: 'Children Play Area' },
        { id: 19, text: 'C. C. TV' },
        { id: 6, text: 'Indoor Game Room' },
        { id: 5, text: 'Basket Ball Court' }
      ];

      this.amenities = []
      this.amenitiesList = []
      this.min_budget = [{ id: 15 * 100000, text: '15 Lac' }];
      this.max_budget = [{ id: 35 * 100000, text: '35 Lac' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/Start_Up.jpg';
      this.personaTitle = 'Start Up\'s';
      this.personaText = 'Samir is a 35-year-old entrepreneur who owns a small business that specializes in digital marketing services. He is looking for a new office space to accommodate his growing team and allow for more collaboration and creativity. Sam is a busy professional who values efficiency and productivity, but also wants his office to reflect his company\'s culture and brand.';
    }
    if (id == 10) {
      this.filterPropertyType = 3;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isBedrooms = false;
      this.isNoOfStaff = true;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
        { id: 46, text: 'Splash Pool', icon: 'fa fa-s15' },
        { id: 2, text: 'Children Play Area', icon: 'fa fa-child' },
        { id: 19, text: 'C. C. TV', icon: 'fa fa-camera' },
        { id: 6, text: 'Indoor Game Room', icon: 'fa fa-gamepad' },
        { id: 5, text: 'Basket Ball Court', icon: 'fa fa-futbol-o' }
      ];
      this.amenities = [
        { id: 40, text: 'Gated Community' },
        { id: 16, text: 'Gym' },
        { id: 7, text: 'Swimming Pool' },
        { id: 1, text: 'Garden' },
        { id: 14, text: 'Jogging Track' },
        { id: 46, text: 'Splash Pool' },
        { id: 2, text: 'Children Play Area' },
        { id: 19, text: 'C. C. TV' },
        { id: 6, text: 'Indoor Game Room' },
        { id: 5, text: 'Basket Ball Court' }
      ];

      this.amenities = []
      this.amenitiesList = []
      this.min_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.max_budget = [{ id: 80 * 100000, text: '80 Lac' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/corporate_office.jpg';
      this.personaTitle = 'Professionals';
      this.personaText = 'Bharat is a 45-year-old professional who is looking for a new commercial property to accommodate his growing company. He values his time and is looking for a streamlined and efficient process for finding and leasing a new space. Bharat is a busy professional who values practicality and functionality, his motive is clear, space that is convenient and accessible for both employees and clients.';
    }
    if (id == 6) {
      this.filterPropertyType = 3;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isNoOfStaff = true;
      this.isBedrooms = false;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
        { id: 46, text: 'Splash Pool', icon: 'fa fa-s15' },
        { id: 2, text: 'Children Play Area', icon: 'fa fa-child' },
        { id: 19, text: 'C. C. TV', icon: 'fa fa-camera' },
        { id: 6, text: 'Indoor Game Room', icon: 'fa fa-gamepad' },
        { id: 5, text: 'Basket Ball Court', icon: 'fa fa-futbol-o' }
      ];
      this.amenities = [
        { id: 40, text: 'Gated Community' },
        { id: 16, text: 'Gym' },
        { id: 7, text: 'Swimming Pool' },
        { id: 1, text: 'Garden' },
        { id: 14, text: 'Jogging Track' },
        { id: 46, text: 'Splash Pool' },
        { id: 2, text: 'Children Play Area' },
        { id: 19, text: 'C. C. TV' },
        { id: 6, text: 'Indoor Game Room' },
        { id: 5, text: 'Basket Ball Court' }
      ];
      this.budgetList = [...this.budgetList, ...[{ id: 1.8 * 10000000, text: '1.8 Cr' }, { id: 200 * 100000, text: '2 Cr' },]];
      this.amenities = []
      this.amenitiesList = []
      this.min_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.max_budget = [{ id: 200 * 100000, text: '2 Cr' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/chartered_accountant.jpg';
      this.personaTitle = 'Entrepreneur';
      this.personaText = 'Raj is a 30-year-old entrepreneur who is starting his own showroom for clothing . He is passionate about his business and is looking for a retail space that will allow him to create a unique and welcoming shopping experience for his customers. Raj values creativity and aesthetics, but also wants to ensure that the space is practical and functional for her business needs.';
    }
    if (id == 8) {
      this.filterPropertyType = 3;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = true;
      this.isNoOfStaff = true;
      this.isBedrooms = false;
      this.projectStatus = '';
      this.amenities = [];
      this.amenitiesList = [
        { id: 40, text: 'Gated Community', icon: 'fa fa-snowflake-o' },
        { id: 16, text: 'Gym', icon: 'fa fa-h-square' },
        { id: 7, text: 'Swimming Pool', icon: 'fa fa-shower' },
        { id: 1, text: 'Garden', icon: 'fa fa-certificate' },
        { id: 14, text: 'Jogging Track', icon: 'fa fa-blind' },
        { id: 46, text: 'Splash Pool', icon: 'fa fa-s15' },
        { id: 2, text: 'Children Play Area', icon: 'fa fa-child' },
        { id: 19, text: 'C. C. TV', icon: 'fa fa-camera' },
        { id: 6, text: 'Indoor Game Room', icon: 'fa fa-gamepad' },
        { id: 5, text: 'Basket Ball Court', icon: 'fa fa-futbol-o' }
      ];
      this.amenities = [
        { id: 40, text: 'Gated Community' },
        { id: 16, text: 'Gym' },
        { id: 7, text: 'Swimming Pool' },
        { id: 1, text: 'Garden' },
        { id: 14, text: 'Jogging Track' },
        { id: 46, text: 'Splash Pool' },
        { id: 2, text: 'Children Play Area' },
        { id: 19, text: 'C. C. TV' },
        { id: 6, text: 'Indoor Game Room' },
        { id: 5, text: 'Basket Ball Court' }
      ];
      this.amenities = []
      this.amenitiesList = []
      this.min_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.max_budget = [{ id: 160 * 100000, text: '1.6 Cr' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/visa_consultant.jpg';
      this.personaTitle = 'IT Firms';
      this.personaText = 'When you sell and buy with Empire Realtors, you get full service and save thousands in fees.We get more eyes on your listing as India’s #1 brokerage website. When you sell and buy with Empire Realtors, you get full service and save thousands in fees. We get more eyes on your listing as India’s #1 brokerage website.';
    }
    /******* Commercial *******/
    /******* Investment *******/
    if (id == 20) {
      this.filterPropertyType = 1;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = false;
      this.isNoOfStaff = false;
      this.isBedrooms = false;
      this.projectStatus = 'Under Construction';
      this.amenities = [];
      this.min_budget = [{ id: 30 * 100000, text: '30 Lac' }];
      this.max_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/new_project.jpg';
      this.personaTitle = 'New Project';
      this.personaText = 'When you sell and buy with Empire Realtors, you get full service and save thousands in fees.We get more eyes on your listing as India’s #1 brokerage website. When you sell and buy with Empire Realtors, you get full service and save thousands in fees. We get more eyes on your listing as India’s #1 brokerage website.';
    }
    if (id == 21) {
      this.filterPropertyType = 3;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = false;
      this.isNoOfStaff = false;
      this.isBedrooms = false;
      this.projectStatus = 'Under Construction';
      this.amenities = [];
      this.min_budget = [{ id: 30 * 100000, text: '30 Lac' }];
      this.max_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/commercial_scheme.jpg';
      this.personaTitle = 'Commercial Scheme';
      this.personaText = 'When you sell and buy with Empire Realtors, you get full service and save thousands in fees.We get more eyes on your listing as India’s #1 brokerage website. When you sell and buy with Empire Realtors, you get full service and save thousands in fees. We get more eyes on your listing as India’s #1 brokerage website.';
    }
    if (id == 22) {
      this.filterPropertyType = 1;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = false;
      this.isNoOfStaff = false;
      this.isBedrooms = false;
      this.projectStatus = 'Under Construction';
      this.amenities = [];
      this.min_budget = [{ id: 30 * 100000, text: '30 Lac' }];
      this.max_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/residential_scheme.jpg';
      this.personaTitle = 'Residential Scheme';
      this.personaText = 'When you sell and buy with Empire Realtors, you get full service and save thousands in fees.We get more eyes on your listing as India’s #1 brokerage website. When you sell and buy with Empire Realtors, you get full service and save thousands in fees. We get more eyes on your listing as India’s #1 brokerage website.';
    }
    if (id == 19) {
      this.filterPropertyType = 3;
      this.isSchool = false;
      this.isAffordableCal = true;
      this.isAmenities = false;
      this.isNoOfStaff = false;
      this.isBedrooms = false;
      this.projectStatus = 'Under Construction';
      this.amenities = [];
      this.min_budget = [{ id: 30 * 100000, text: '30 Lac' }];
      this.max_budget = [{ id: 45 * 100000, text: '45 Lac' }];
      this.onEMICalPriceChange();
      this.suitable_for = id;
      this.personaImgSrc = '../assets/img/persona/warehouse_space.jpg';
      this.personaTitle = 'Warehouse Space';
      this.personaText = 'When you sell and buy with Empire Realtors, you get full service and save thousands in fees.We get more eyes on your listing as India’s #1 brokerage website. When you sell and buy with Empire Realtors, you get full service and save thousands in fees. We get more eyes on your listing as India’s #1 brokerage website.';
    }
    /******* Investment *******/
    if ($('#personaSlideOut').hasClass('activePersona')) {
      $('#personaSlideOut').animate({ 'max-width': '40%', margin: '0px', padding: '0' }).removeClass('activePersona');
      $('.single-persona-block').animate({ 'max-width': '25%', margin: '0px', padding: '0px 15px', opacity: '1' });
    } else {
      $('.single-persona-block:not(.single-persona-block-' + id + ')').animate({ 'max-width': '0px', opacity: '0', margin: '0px', padding: '0px' }).addClass('disable-pointer-events');
      $('.single-persona-block:not(.single-persona-block-' + id + ') .contenthover a').animate({ padding: '0px' });
      $('#personaSlideOut').animate({ 'max-width': '75%', margin: '0px', padding: '0px 15px' }).addClass('activePersona');
    }
  }

  getLoanMonthly() {
    var term = 5;
    if (this.loan_program == 1) {
      term = 5;
    } else if (this.loan_program == 2) {
      term = 10;
    } else if (this.loan_program == 3) {
      term = 15;
    } else if (this.loan_program == 4) {
      term = 20;
    }
    var totalAmount = (this.home_price - this.down_payment) * (this.int_rate / 100) * term;
    var monthlyAmount = ((this.home_price - this.down_payment) + totalAmount) / (term * 12);
    $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
  }

  onEMICalPriceChange() {
    setTimeout(() => {
      if (this.home_price <= 0) {
        this.home_price = 500000;
      }
      this.down_payment = (this.home_price * this.down_payment_per) / 100;
      this.new_home_price = this.home_price - this.down_payment;
      this.getLoanMonthly();
    }, 200);
  }

  onEMICalDownPaymentChange() {
    if (this.down_payment <= 0) {
      this.down_payment_per = 1;
      this.down_payment = (this.home_price * this.down_payment_per) / 100;
    } else {
      this.down_payment_per = (this.down_payment * 100) / this.home_price;
    }
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }

  onEMICalDownPaymentPerChange() {
    if (this.down_payment_per <= 1) {
      this.down_payment_per = 1;
    }
    this.down_payment = (this.home_price * this.down_payment_per) / 100;
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }

  onChangeNoOfStaff() {
    if (this.minNoOfStaff <= 1) {
      this.minNoOfStaff = 10;
    } else if (this.minNoOfStaff >= 9999) {
      this.minNoOfStaff = 9999;
    }
  }

  onEMICalTermChange() {
    this.getLoanMonthly();
  }

  onEMICalIntRateChange() {
    if (this.int_rate <= 0) {
      this.int_rate = 1;
    }
    this.getLoanMonthly();
  }

  closePersonaModel() {
    $('#personaSlideOut').animate({ 'max-width': '40%', margin: '0px', padding: '0' }).removeClass('activePersona');
    $('.single-persona-block').animate({ 'max-width': '25%', margin: '0px', padding: '0px 15px', opacity: '1' }).removeClass('disable-pointer-events');
    $('.single-persona-block .contenthover a').animate({ padding: '10px 15px' });
  }

  makeProjFavorite(pid, status) {
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      const userInfo = JSON.parse(localStorage.getItem('currentUser'));
      const form_data = new FormData();
      form_data.append('id', pid);
      form_data.append('status', '1');
      // form_data.append('locality_elocs', this.locality_elocs);
      form_data.append('transaction_type_id', JSON.parse(localStorage.getItem('transaction_type_id' + userInfo.id)));
      this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_FAVORITE, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          if ($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css('color', '#FFFFFF');
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css('color', '#F02174');
          }
        }
      });
    }
  }

  applyPersonaFilter() {
    $("#personafiltermodel").modal('hide');
    let data = {};
    data['property_type_id'] = this.filterPropertyType;
    // data['suitable_for_ids'] = this.suitable_for;
    let suitableType = Number(this.suitable_for);
    /*if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      data['transaction_type_id'] = $(".seconddescription").find('.active').data('transactiontypeid');
    }*/
    if (this.min_budget && this.min_budget.length) {
      data['min_budget_amount'] = this.min_budget[0].id;
    }
    if (this.max_budget && this.max_budget.length) {
      data['max_budget_amount'] = this.max_budget[0].id;
    }
    if (this.projectStatus !== '') {
      data['project_status'] = this.projectStatus;
    }
    if (this.filterPropertyType == 1) {
      if (this.bedrooms && this.bedrooms.length) {
        data['bedrooms'] = (this.bedrooms.map(value => value.id)).join(',');
      }
      if (this.bathroom && this.bathroom.length) {
        data['bath'] = (this.bathroom.map(value => value.id)).join(',');
      }
    }
    if (this.amenities && this.amenities.length) {
      data['amenitie_ids'] = (this.amenities.map(value => value.id)).join(',');
    }
    if (this.personal_area_title) {
      data['locality_elocs'] = this.personal_area_title;
    } else {
      const userCurrCity = localStorage.getItem('user_curr_city');
      if (userCurrCity) {
        data['locality_elocs'] = userCurrCity;
      }
    }
    /*if ($('#searchPersonalInput').tagsinput('items') && $('#searchPersonalInput').tagsinput('items').length) {
      data['location_elocs'] = ($('#searchPersonalInput').tagsinput('items').map(value => value)).join(',');
    }*/
    if (suitableType === 7 || suitableType === 10 || suitableType === 6 || suitableType === 8) {
      let rangePerPerson = (this.minNoOfStaff * this.perPersonRangeSqFt) / 100;
      let totalAreaReq = this.minNoOfStaff * this.perPersonSqFt;
      let totalMinAreaReq = (this.minNoOfStaff - rangePerPerson) * this.perPersonSqFt;
      let totalMaxAreaReq = (this.minNoOfStaff + rangePerPerson) * this.perPersonSqFt;
      data['min_saleable_area'] = totalMinAreaReq;
      data['max_saleable_area'] = totalMaxAreaReq;
    }
    this.router.navigate(['/suitable-property'], { queryParams: data });
  }

  onMaxBudgetChange() {
    if (this.max_budget && this.max_budget.length) {
      this.home_price = this.max_budget[0].id;
      this.onEMICalPriceChange();
    }
  }

  frontLeadGenerator() {
    let inputValue = $('#sellingSearchInput')
    if (!inputValue.val()) {
      this.FlashService.error('Please enter valid location');
      return
    }

    var data = {};
    if (this.area_title != "" && this.area_lat != "" && this.area_lng != "") {
      data['locality'] = this.area_title;
      data['lat'] = this.area_lat;
      data['lng'] = this.area_lng;
      this.router.navigate(['/lead-generator'], { queryParams: data });
    } else {
      $('#sellingSearchInput').focus();
    }
  }

  searchCarnivalProperty() {
    let data = {};
    const userCurrCity = localStorage.getItem('user_curr_city');
    if (userCurrCity) {
      data['locality_elocs'] = userCurrCity;
    } else {
      data['locality_elocs'] = 'Vadodara';
      data['lat'] = 22.295492;
      data['lng'] = 73.173463;
    }
    data['property_type_id'] = 1;
    data['page'] = 'carnival';
    this.router.navigate(['/transaction-type'], { queryParams: data });
  }

  onFilterSelect(id) {
    if ($('#personaSlideOut').hasClass('activePersona')) {
      this.closePersonaModel();
    }
    $('#residential_btn').removeClass('fp_type_active');
    $('#commerical_btn').removeClass('fp_type_active');
    $('#investment_btn').removeClass('fp_type_active');
    if (id === 1) {
      $('#residential_btn').addClass('fp_type_active');
      $('.residentialDiv').show();
      $('.commercialDiv').hide();
      $('.investDiv').hide();
    } else if (id === 2) {
      $('#commerical_btn').addClass('fp_type_active');
      $('.residentialDiv').hide();
      $('.commercialDiv').show();
      $('.investDiv').hide();
    } else {
      $('#investment_btn').addClass('fp_type_active');
      $('.residentialDiv').hide();
      $('.commercialDiv').hide();
      $('.investDiv').show();
    }
  }

  onFilterDeSelect(item: any) {
    this.fpType_list = [item];
  }

  initTopProjectSlider() {


    return
    $("#suggested_project_view").lightSlider({
      gallery: false,
      item: 3,
      autoWidth: false,
      slideMove: 1, // slidemove will be 1 if loop is true
      slideMargin: 15,

      addClass: '',
      mode: "slide",
      useCSS: true,
      cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
      easing: 'linear', //'for jquery animation',////

      speed: 600, //ms'
      auto: false,
      loop: false,
      slideEndAnimation: true,
      pause: 2000,

      keyPress: false,
      controls: true,
      prevHtml: '<span aria-label="Previous">‹</span>',
      nextHtml: '<span aria-label="Next">›</span>',

      responsive: [
        {
          breakpoint: 767,
          settings: {
            item: 1.5
          }
        }
      ]
    });

  }

  initFeaturesPropertySlider() {

    $('#suggested_property_view').lightSlider({
      gallery: false,
      item: 4.5,
      auto: true,
      loop: true,
      speed: 1500,
      pauseOnHover: true,
      slideMargin: 25,
      pager: false,
      // controls: true,
      // prevHtml: '<img src="../assets/img/prev.png" style="height: 30px;margin-left: 6px;" />',
      // nextHtml: '<img src="../assets/img/next.png" style="height: 30px;margin-left: 11px;" />',
      responsive: [
        {
          slideMargin: 10,
          breakpoint: 767,
          settings: {
            item: 1
          }
        }
      ],
    });
  }

  getTopProjectList() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_TOP_PROJECTS, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.featuresProjectList = apiResult.data.list;
        setTimeout(() => {
          this.initTopProjectSlider();
        }, 200);
      }
    });
  }

  getUserFeaturesPropertyList() {
    var form_data = new FormData();
    form_data.append('is_featured', '1');
    form_data.append('limit', '10');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.featuresPropertyList = apiResult.data.list;
        setTimeout(() => {
          this.initFeaturesPropertySlider();
        }, 200);
      }
    });
  }

  searchFeatureProperty() {
    let data = {};
    const userCurrCity = localStorage.getItem('user_curr_city');
    if (userCurrCity) {
      data['locality_elocs'] = userCurrCity;
    }
    data['is_featured'] = 1;
    if (this.isLoggedIn) {
      const userInfo = JSON.parse(localStorage.getItem('currentUser'));
      if (JSON.parse(localStorage.getItem('property_type_id' + userInfo.id)) != null && JSON.parse(localStorage.getItem('transaction_type_id' + userInfo.id)) != null) {
        data['property_type_id'] = JSON.parse(localStorage.getItem('property_type_id' + userInfo.id));
        data['transaction_type_id'] = JSON.parse(localStorage.getItem('transaction_type_id' + userInfo.id));
      }
    }
    this.router.navigate(['/buy'], { queryParams: data });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          const oldUserCurrCity = localStorage.getItem('user_curr_city');
          // tslint:disable-next-line:max-line-length
          const GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + '%2C' + position.coords.longitude + '&language=en&key=' + environment.G_CLIENT_KEY;
          let city = '';
          $.getJSON(GEOCODING).done((location) => {
            const results = location.results;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < results[0].address_components.length; i++) {
              // tslint:disable-next-line:prefer-for-of
              for (let b = 0; b < results[0].address_components[i].types.length; b++) {
                if (results[0].address_components[i].types[b] === 'administrative_area_level_2' || results[0].address_components[i].types[b] === "administrative_area_level_3") {
                  city = results[0].address_components[i];
                  break;
                }
              }
            }
            // @ts-ignore
            localStorage.setItem('user_curr_city', (city.long_name ? city.long_name : 'Vadodara'));
            if (!oldUserCurrCity) {
              window.location.reload();
            }
          });
        }
      },
        (error: any) => {
          localStorage.setItem('user_curr_city', ('Vadodara'));
          console.log(error)
        });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  getPropertyList() {
    var form_data = new FormData();
    form_data.append('page', '1');
    form_data.append('list_for', 'property');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.propertyList = apiResult.data.list;
      }
    })
  }
}

