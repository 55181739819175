<div class="container-fluid" id="frontLeadGenerator">
  <ng-container *ngIf="isAddingLead == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <div class="row lead_generator_main" *ngIf="isLeadAdded == true">
    <div class="col-md-12 addlead-thankyou">
      <div class="page-head mt-5 text-center">
        <p class="page-title">Thank You!</p>
        <p class="page-details">Your details are submitted Successfully.</p>
        <p class="page-details">"Our team will get in touch with you", check your email for verification.</p>
      </div>
      <div class="save-button">
        <div class="row d-flex">
          <div class="col-md-2 offset-5">
            <div class="form-group">
              <button (click)="onBackToHome()" class="btn btn-block login-btn" type="submit">Back to Site</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isLeadAdded == false">
    <div class="container">
      <div class="col-md-12">
        <div class="page-head">
        </div>
        <div class="lead_generator_main">
          <!-- ====================================== STEP 1 Starts ====================================== -->
          <div class="sell_step_1 mb-3" *ngIf="step1">
            <div class="row d-flex">
              <div class="col-md-3 offset-md-5 sell_step_title_div">
                <h5 class="sell_step_title">Transaction Type</h5>
              </div>
              <div class="row mb-5">
                <div class="col-md-4 offset-md-2 text-center">
                  <div class="card mb-4 {{ this.tranTypeSell == true ? 'active-card' : '' }}">
                    <div class="card-body text-center">
                      <img src="../../assets/img/sell/eco_house.jpg" class="card-img-top tran_sell_img" alt="Sell"/>
                    </div>
                  </div>
                  <div class="col-md-5 offset-md-3">
                    <button (click)="onTranTypeSelect(4)" class="btn btn-block login-btn sell-btn" type="submit">Sell</button>
                  </div>
                </div>
                <div class="col-md-4 text-center next-card-div">
                  <div class="card mb-4 {{ this.tranTypeRent == true ? 'active-card' : '' }}">
                    <div class="card-body text-center">
                      <img alt="Wild Landscape" class="card-img-top rent-img" src="../../assets/img/sell/rent.jpg"/>
                    </div>
                  </div>
                  <div class="col-md-5 offset-md-3">
                    <button (click)="onTranTypeSelect(3)" class="btn btn-block login-btn sell-btn" type="submit">Rent / Lease</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ====================================== STEP 1 Ends ====================================== -->

          <!-- ====================================== STEP 2 Starts ====================================== -->
          <div class="sell_step_2 mb-3" *ngIf="step2">
            <div class="row d-flex">
              <div class="col-md-3 offset-md-5 sell_step_title_div">
                <h5 class="sell_step_title">Property Type</h5>
              </div>
              <div class="row mb-5">
                <div class="col-lg-3 col-md-6 text-center">
                  <div class="card mb-4 {{ this.propTypeResidential == true ? 'active-card' : '' }}">
                    <div class="card-body text-center">
                      <img src="../../assets/img/sell/residential.jpg" class="card-img-top" alt="Residential"/>
                    </div>
                  </div>
                  <div class="col-lg-6 offset-lg-2 step-btn-div">
                    <button (click)="onSelectPropType(1)" class="btn btn-block login-btn sell-btn" type="submit">Residential</button>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 next-card-div text-center">
                  <div class="card mb-4 {{ this.propTypeCommercial == true ? 'active-card' : '' }}">
                    <div class="card-body text-center">
                      <img src="../../assets/img/sell/commercial.jpg" class="card-img-top commerical_sell_img" alt="Commercial"/>
                    </div>
                  </div>
                  <div class="col-lg-6 offset-lg-2 step-btn-div">
                    <button (click)="onSelectPropType(3)" class="btn btn-block login-btn sell-btn" type="submit">Commercial</button>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 next-card-div text-center">
                  <div class="card mb-4 {{ this.propTypeLand == true ? 'active-card' : '' }}">
                    <div class="card-body text-center">
                      <img src="../../assets/img/sell/land.jpg" class="card-img-top" alt="Land"/>
                    </div>
                  </div>
                  <div class="col-lg-6 offset-lg-2 step-btn-div">
                    <button (click)="onSelectPropType(4)" class="btn btn-block login-btn sell-btn" type="submit">Land</button>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 next-card-div text-center">
                  <div class="card mb-4 {{ this.propTypeIndustrial == true ? 'active-card' : '' }}">
                    <div class="card-body text-center">
                      <img src="../../assets/img/sell/industrial.jpg" class="card-img-top" alt="Industrial"/>
                    </div>
                  </div>
                  <div class="col-lg-6 offset-lg-2 step-btn-div">
                    <button (click)="onSelectPropType(6)" class="btn btn-block login-btn sell-btn" type="submit">Industrial</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ====================================== STEP 2 Ends ====================================== -->

          <div class="save-button" *ngIf="step1 || step2">
            <div class="row d-flex">
              <div class="col-md-2 offset-md-4 step_back_btn" *ngIf="step2">
                <div class="form-group">
                  <button type="submit" class="btn btn-block login-btn" (click)="onStepTwoBack()">Back</button>
                </div>
              </div>
              <div class="col-md-2 {{ step1 == true ? 'offset-md-5' : '' }} step_nxt_btn" *ngIf="step2">
                <div class="form-group">
                  <button type="submit" class="btn btn-block login-btn" (click)="onStepTwoNext()">Next</button>
                </div>
              </div>
              <div class="col-md-2 {{ step1 == true ? 'offset-md-5' : '' }} step_nxt_btn" *ngIf="step1">
                <div class="form-group">
                  <button type="submit" class="btn btn-block login-btn" (click)="onStepOneNext()">Next</button>
                </div>
              </div>
            </div>
          </div>

          <!-- ====================================== STEP 3 Starts ====================================== -->
          <div class="sell_step_3 mb-3" *ngIf="step3" >
            <div class="row d-flex">
              <div class="col-lg-3 offset-lg-5 col-md-12 sell_step_title_div">
                <h5 class="sell_step_title">Property Address</h5>
              </div>
            </div>
            <div class="row mb-5 sell_step_3_map">
              <div class="col-lg-6 col-md-12 map-view-main-outer">
                <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false" [mapTypeControl]="true" [usePanning]="true" (mapReady)="mapReady($event)">
                  <agm-marker *ngFor="let m of markers; let i = index"
                              [latitude]="m.lat"
                              [longitude]="m.lng"
                              [label]="m.label"
                              [iconUrl]="icon"
                              (markerClick)="clickedMarker(m.pid)"
                              [markerDraggable]="m.draggable"
                              (dragEnd)="markerDragEnd(m, $event)">
                  </agm-marker>
                </agm-map>
              </div>
              <div [formGroup]="propAddressForm" class="col-lg-6 col-md-12 prop-address-div">
                <div class="row d-flex mt-5">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="address_line1" formControlName="addressline1" class="form-control" placeholder="Address Line 1" [ngClass]="{ 'is-invalid': submitted && k.addressline1.errors }" />
                      <div *ngIf="submitted && k.addressline1.errors" class="invalid-feedback">
                        <div *ngIf="k.addressline1.errors.required">Address Line 1 is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="address_line2" formControlName="addressline2" class="form-control" placeholder="Address Line 2" [ngClass]="{ 'is-invalid': submitted && k.addressline2.errors }" />
                      <div *ngIf="submitted && k.addressline2.errors" class="invalid-feedback">
                        <div *ngIf="k.addressline2.errors.required">Address Line 2 is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <select [(ngModel)]="state_eloc" formControlName="profilestate" class="form-control" [ngClass]="{ 'is-invalid': submitted && k.profilestate.errors }" (change)="getStateCities($event.target.value,$event.target.data-lat,$event.target.data-lng)">
                        <option *ngFor="let stateOption of state_options" value="{{ stateOption.id }}">
                          {{ stateOption.title }}
                        </option>
                      </select>
                      <div *ngIf="submitted && k.profilestate.errors" class="invalid-feedback">
                        <div *ngIf="k.profilestate.errors.required">State is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <select [(ngModel)]="city_eloc" formControlName="profilecity" class="form-control" [ngClass]="{ 'is-invalid': submitted && k.profilecity.errors }" (change)="setCityLocation($event.target.value)">
                        <option *ngFor="let cityOption of city_options" value="{{ cityOption.id }}">
                          {{ cityOption.title }}
                        </option>
                      </select>
                      <div *ngIf="submitted && k.profilecity.errors" class="invalid-feedback">
                        <div *ngIf="k.profilecity.errors.required">City is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input id="locality_search" formControlName="prolocality" class="form-control" placeholder="Locality" [ngClass]="{ 'is-invalid': submitted && k.prolocality.errors }" />
                      <div *ngIf="submitted && k.prolocality.errors" class="invalid-feedback">
                        <div *ngIf="k.prolocality.errors.required">Locality is required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="save-button">
                  <div class="row d-flex">
                    <div class="col-md-4 offset-md-2 step_back_btn">
                      <div class="form-group">
                        <button type="button" class="btn btn-block login-btn" (click)="onStepThreeBack()">Back</button>
                      </div>
                    </div>
                    <div class="col-md-4 step_nxt_btn">
                      <div class="form-group">
                        <button type="submit" class="btn btn-block login-btn" (click)="onStepThreeNext()">Next</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ====================================== STEP 3 Ends ====================================== -->

          <!-- ====================================== STEP 4 Starts ====================================== -->
          <div class="sell_step_4 mb-3" *ngIf="step4" [formGroup]="leadForm">
            <div class="row d-flex">
              <div class="col-lg-3 offset-lg-5 col-md-12 sell_step_title_div">
                <h5 class="sell_step_title">Give Your Details</h5>
              </div>
            </div>
            <div class="row mb-5 sell_step_4_map">
              <div class="col-lg-6 col-md-12 map-view-main-outer">
                <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false" [mapTypeControl]="true" [usePanning]="true" (mapReady)="mapReady($event)">
                  <agm-marker *ngFor="let m of markers; let i = index"
                              [latitude]="m.lat"
                              [longitude]="m.lng"
                              [label]="m.label"
                              [iconUrl]="icon"
                              (markerClick)="clickedMarker(m.pid)"
                              [markerDraggable]="m.draggable">
                  </agm-marker>
                </agm-map>
              </div>
              <div class="col-lg-6 col-md-12 prop-address-div">
                <div class="row d-flex mt-5">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="first_name" formControlName="firstName" class="form-control" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && g.firstName.errors }" />
                      <div *ngIf="submitted && g.firstName.errors" class="invalid-feedback">
                        <div *ngIf="g.firstName.errors.required">First Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="last_name" formControlName="lastName" class="form-control" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && g.lastName.errors }" />
                      <div *ngIf="submitted && g.lastName.errors" class="invalid-feedback">
                        <div *ngIf="g.lastName.errors.required">Last Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="email" [(ngModel)]="email" formControlName="profileEmail" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && g.profileEmail.errors }" />
                      <div *ngIf="submitted && g.profileEmail.errors" class="invalid-feedback">
                        <div *ngIf="g.profileEmail.errors.required">Email is required</div>
                        <div *ngIf="g.profileEmail.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="phone" formControlName="mobileNumber" class="form-control" [pattern]="mobNumberPattern" placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && g.mobileNumber.errors }" />
                      <div *ngIf="submitted && g.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="g.mobileNumber.errors.required">Mobile Number is required</div>
                        <div *ngIf="g.mobileNumber.errors.pattern || g.mobileNumber.errors.minlength || g.mobileNumber.errors.maxlength">Please enter valid Mobile Number</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="save-button">
                  <div class="row d-flex">
                    <div class="col-md-4 offset-md-2 step_back_btn">
                      <div class="form-group">
                        <button type="button" class="btn btn-block login-btn" (click)="onStepFourBack()">Back</button>
                      </div>
                    </div>
                    <div class="col-md-4 step_nxt_btn">
                      <div class="form-group">
                        <button type="submit" class="btn btn-block login-btn" (click)="onLeadFormSubmit()">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ====================================== STEP 4 Ends ====================================== -->

        </div>
      </div>
    </div>
  </div>
</div>
