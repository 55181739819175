/* tslint:disable:no-shadowed-variable max-line-length */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS } from 'src/app/global';

declare var $: any;
declare const require: any;

@Component({
  selector: 'app-developer-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './developer-details.component.html',
  styleUrls: ['./developer-details.component.scss']
})
export class DeveloperDetailsComponent implements OnInit {
  developerData: any = [];
  onGoingProjects: any = [];
  upComingProjects: any = [];
  pastProjects: any = [];
  isDeveloper = false;

  constructor(private route: ActivatedRoute, private ApiService: ApiService, private title: Title, ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.isDeveloper = true;
    this.getDeveloperDetails();
    setTimeout (() => {
      this.changeHeaderClass();
    }, 500);
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $('#imgHeadLogo').attr('src', 'assets/img/empire_logo.png');
    this.title.setTitle('Empire Realtors » Developer Details');
  }

  getDeveloperDetails() {
    const formData = new FormData();
    formData.append('group_slug', this.route.snapshot.params.group_slug);
    this.ApiService.postRequest(API_ENDPOINTS.API_DEVELOPER_DETAIL, formData).subscribe((apiResult: any) => {
      if (apiResult.success === 1) {
        this.developerData = apiResult.data;
        this.upComingProjects = apiResult.data.upcoming_projects;
        this.onGoingProjects = apiResult.data.ongoing_projects;
        this.pastProjects = apiResult.data.past_projects;
      }
      this.isDeveloper = false;
    });
  }

}
