/* tslint:disable:quotemark no-inferrable-types variable-name no-shadowed-variable max-line-length */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/global';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
declare var $: any;

@Component({
  selector: 'app-buyer-viewed',
  templateUrl: './buyer-viewed.component.html',
  styleUrls: ['./buyer-viewed.component.scss']
})
export class BuyerViewedComponent implements OnInit {

  home_icon: string = '';
  buyerRecentlyViewed: any = [];
  buyerNewRecentlyViewed: any = [];
  isLoading: boolean = true;
  dashboardType = 'buyer';

  constructor(private ApiService: ApiService, private ref: ChangeDetectorRef,  private router: Router, private route: ActivatedRoute, private FlashService: FlashService, private title: Title,) {
    this.home_icon = API_ENDPOINTS.HOME_ICON;
    window.scroll(0,0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
      this.getRecentlyViewedProperties();
      this.getRecentlyViewedProjects();
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  getRecentlyViewedProperties(){
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_RECENTLY_VIEWED_PROPERTIES, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerRecentlyViewed = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }
  getRecentlyViewedProjects(){
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_RECENTLY_VIEWED_PROJECT, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerNewRecentlyViewed = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }
  onRejectedClick(id) {
    const form_data = new FormData();
    form_data.append('oppprtunity_wishlist_id', id + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_VIEWED_REJECTED, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Property has been successfully rejected.");
        this.getRecentlyViewedProperties();
        this.getRecentlyViewedProjects();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }
  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Recently Viewed');
  }

}
