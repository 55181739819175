<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid account-reviews-outer" id="accoutnSettings">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">Reviews</h4>
        <div class="account-reviews">
          <div class="empty-message">
            <img src="{{ review_icon }}" class="home-icon" alt="Home" />
            <div class="empty-text">
              <h5>You Have No Pending Reviews</h5>
              <p>When you work with Empire Realtors to buy or sell a home, you can provide feedback on your Empire Realtors Agent, partner agent, and other service providers here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
