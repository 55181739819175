<div class="container-fluid" id="singleProperty">
  <div class="container">
    <!-- Start Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li>
            <a href="/"> Home </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> Project Unit detail </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <!-- Start Property header -->
    <div class="row property_head">
      <div class="col-md-6">
        <h4>{{propertyinfo.display_name}}</h4>
        <p *ngIf="propertyinfo.project_account">By <a href="projects">{{propertyinfo.project_account.name}}</a></p>
        <h6>{{propertyinfo.locality_title}} {{(propertyinfo.City ? propertyinfo.city.city : '')}}</h6>
      </div>
      <div class="col-md-3">
        <ul class="prop_other_info">
          <li *ngIf="propertyinfo.unit_data && propertyinfo.show_unit_price_on_website == 1">
            <h4>
              <i class="fa fa-rupee"></i> {{propertyinfo.unit_data.rpcf_price_title}}</h4>
            <button class="btn btn-info btn-sm" (click)="scrollTo('tax_info')">Closing Price</button>
          </li>
          <li *ngIf="propertyinfo.unit_data">
            <h4 *ngIf="propertyinfo.unit_data.total_bedrooms">{{(propertyinfo.unit_data.total_bedrooms ? propertyinfo.unit_data.total_bedrooms : 0)}}</h4>
            <p *ngIf="propertyinfo.unit_data.total_bedrooms">Bed</p>
          </li>
          <li *ngIf="propertyinfo.unit_data">
            <h4 *ngIf="propertyinfo.unit_data.total_showers">{{(propertyinfo.unit_data.total_showers ? propertyinfo.unit_data.total_showers : 0)}}</h4>
            <p *ngIf="propertyinfo.unit_data.total_showers">Baths</p>
          </li>
          <li class="like_share_btn">
            <button class="btn btn-common btn-lg heart_btn" [ngClass]="{ 'is_favorite_prop': propertyinfo.is_saved == 1 }" (click)="makeFavorite(propertyinfo.id, propertyinfo.unit_data.id)">
              <i *ngIf="!isFavoriting" class="fa fa-heart"></i>
              <div class="favoriteLoadingOverlay" *ngIf="isFavoriting" [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div>
            </button>
            <button id="shrehide" class="btn btn-common btn-lg share_btn"><i class="fa fa-share-alt"></i></button>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Property header -->

    <!-- Start Property images -->
    <div class="row">
      <div class="col-md-9">
        <div class="project_main_img" (click)="open(0)"></div>
      </div>
      <div class="col-md-3">
        <div class="project_other_img">
          <div class="project_other_img_1" (click)="open(0)"></div>
          <div class="project_other_img_2" (click)="open(0)"><span class="more_images_3 hidden"></span></div>
        </div>
      </div>
    </div>
    <!-- End Property images -->

    <div class="row">
      <div class="col-md-9">
        <!-- Start Property info -->
        <div class="row property_more_info">
          <div class="col-md-12">
            <ul class="nav nav-tabs prop_info_tabs" role="tablist">
              <li class="nav-item">
                <span class="nav-link active" id="property_description_tab" (click)="scrollTo('property_description')" role="tab" aria-controls="property_description" aria-selected="true">Description of property</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_dimentions_tab" (click)="scrollTo('property_dimentions')" role="tab" aria-controls="property_dimentions" aria-selected="false">Property Dimentions</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_detail_tab" (click)="scrollTo('property_detail')" role="tab" aria-controls="property_detail" aria-selected="false">Property Detail</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_amenities_tab" (click)="scrollTo('property_amenities')" role="tab" aria-controls="property_amenities" aria-selected="false">Amenities</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_nearby_tab" (click)="scrollTo('property_nearby')" role="tab" aria-controls="property_nearby" aria-selected="false">Near By Landmarks</span>
              </li>
            </ul>

            <div class="tab-content prop_info_tab_content">
              <div class="tab-pane fade show active" id="property_description" role="tabpanel" aria-labelledby="property_description_tab">
                <h5>Description of property</h5>
                <div class="property_description_contant">{{(propertyinfo.description ? propertyinfo.description : 'Description not found.')}}</div>
              </div>

              <div class="tab-pane fade show active" id="property_dimentions" role="tabpanel" aria-labelledby="property_dimentions_tab">
                <h5>Property Dimentions</h5>
                <div class="p_details_accordians">
                  <div class="accordion_outer no-border">
                    <div class="accordion_in_content collapse show">
                      <div class="row d-flex" *ngIf="propertyinfo.proj_unit_room">
                        <div class="col-md-6" *ngIf="propertyinfo.proj_unit_room.living_room">
                          <h4>Living Room Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.proj_unit_room.living_room.width">
                              Dimension : {{(propertyinfo.proj_unit_room.living_room.width ? propertyinfo.proj_unit_room.living_room.width : '-')}} x {{(propertyinfo.proj_unit_room.living_room.length ? propertyinfo.proj_unit_room.living_room.length : '-')}}
                            </li>
<!--                            <li>Flooring : -</li>-->
                            <li *ngIf="propertyinfo.living_room_furniture">
                              Furniture : <span *ngFor="let itemF of propertyinfo.living_room_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                            <li *ngIf="!propertyinfo.proj_unit_room.living_room.width && !propertyinfo.living_room_furniture">
                              No Data
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6" *ngIf="propertyinfo.proj_unit_room.kitchen">
                          <h4>Kitchen Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.proj_unit_room.kitchen.width">
                              Dimension : {{(propertyinfo.proj_unit_room.kitchen.width ? propertyinfo.proj_unit_room.kitchen.width : '-')}} x {{(propertyinfo.proj_unit_room.kitchen.length ? propertyinfo.proj_unit_room.kitchen.length : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.kitchen_flooring">
                              Flooring : {{(propertyinfo.kitchen_flooring ? propertyinfo.kitchen_flooring.title : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.kitchen_furniture">
                              Furniture : <span *ngFor="let itemF of propertyinfo.kitchen_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                            <li *ngIf="!propertyinfo.proj_unit_room.kitchen.width && !propertyinfo.kitchen_flooring && !propertyinfo.kitchen_furniture">
                              No Data
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6" *ngFor="let itemB of propertyinfo.proj_unit_room.bedroom; index as bi">
                          <h4>Bedroom {{bi+1}}</h4>
                          <ul>
                            <li *ngIf="itemB.width">
                              Dimension : {{(itemB.width ? itemB.width : '-')}} x {{(itemB.length ? itemB.length : '-')}}
                            </li>
                            <li *ngIf="itemB.bedroom_flooring">
                              Flooring : {{(itemB.bedroom_flooring ? itemB.bedroom_flooring.title : '-')}}
                            </li>
                            <li *ngIf="itemB.bedroom_furniture">
                              Furniture : <span *ngFor="let itemF of itemB.bedroom_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                            <li>Attached Bath : {{(itemB.attached_bathroom == 'Yes' ? 'Yes' : 'No')}}</li>
                            <li *ngIf="itemB.attached_bathroom == 'Yes'">Bath Dimension : {{(itemB.bathroom_width ? itemB.bathroom_width : '-')}} x {{(itemB.bathroom_length ? itemB.bathroom_length : '-')}}</li>
                          </ul>
                        </div>
                        <div class="col-md-6" *ngFor="let itemC of propertyinfo.unit_data.proj_unit_dimension; index as bi">
                          <h4>{{itemC.dimension.title}}</h4>
                          <ul>
                            <li>Dimension : {{(itemC.width ? itemC.width : '-')}} x {{(itemC.length ? itemC.length : '-')}}</li>
                            <li>Flooring : {{(itemC.dimension_flooring ? itemC.dimension_flooring.title : '-')}}</li>
                            <li>Furniture : <span *ngFor="let itemF of itemC.dimension_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--<ul class="p_dimension">
                  <ng-container *ngFor="let itemDimension of propertyinfo.prop_dimension">
                    <li class="s_dimension" *ngIf="itemDimension.dimension !== undefined">
                      <i class="fa fa-check" aria-hidden="true"></i>
                      {{itemDimension.dimension.title}}
                    </li>
                  </ng-container>
                </ul>-->
              </div>

              <div class="tab-pane fade show active" id="property_detail" role="tabpanel" aria-labelledby="property_detail_tab">
                <h5>Property details</h5>
                <div class="p_details_accordians">
                  <div class="accordion_outer" *ngIf="propertyinfo.proj_school.length > 0">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#virtual_tour" aria-expanded="true" aria-controls="virtual_tour">School Information<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="virtual_tour">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>School Information</h4>
                          <ul *ngFor="let school of propertyinfo.proj_school;">
                            <li>{{school.placeName}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion_outer" *ngIf="propertyinfo.proj_feature.length > 0">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#interior_features" aria-expanded="true" aria-controls="interior_features">Unique Features <i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="interior_features">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>Utility Information</h4>
                          <ul *ngFor="let feature of propertyinfo.proj_feature;">
                            <li>{{feature.feature.title}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion_outer" id="tax_info" *ngIf="propertyinfo.show_unit_price_on_website == 1">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#tax_asses" aria-expanded="true" aria-controls="tax_asses">Taxes / Financial Information<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="tax_asses">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>Financial Information</h4>
                          <ng-container *ngIf="propertyinfo.proj_tax.tax_list.length > 0; else noTaxInfo">
                            <table class="tax_table" style="width: 100%;">
                              <tbody>
                              <tr *ngFor="let tax of propertyinfo.proj_tax.tax_list;">
                                <td class="tax_info" style="width: 70%;">
                                  {{tax.title + ' ' + tax.tax_percentage + '%'}}
                                </td>
                                <td class="tax_info text-right" style="width: 30%;">{{tax.tax_amount_title}}</td>
                              </tr>
                              <tr>
                                <td class="tax_info" style="width: 70%;">Basic Price</td>
                                <td class="tax_info text-right" style="width: 30%;">
                                  {{propertyinfo.unit_data.rpcf_price_title}}
                                </td>
                              </tr>
                              <tr>
                                <td class="tax_total" style="width: 70%;">Closing Price</td>
                                <td class="tax_total text-right" style="width: 30%;">
                                  {{propertyinfo.unit_data.closing_price_title}}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </ng-container>
                          <ng-template #noTaxInfo>
                            <p>No data</p>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion_outer">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#property_lot" aria-expanded="true" aria-controls="property_lot">Property / Lot Details <i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="property_lot">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>Property Information</h4>
                          <ul *ngIf="propertyinfo.unit_data">
                            <li *ngIf="propertyinfo.unit_data.total_bedrooms">No. of Beds: {{propertyinfo.unit_data.total_bedrooms ? propertyinfo.unit_data.total_bedrooms : '-'}}</li>
                            <li>For sale</li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <h4>Land Information</h4>
                          <ul *ngIf="propertyinfo.unit_data">
                            <li *ngIf="propertyinfo.unit_data.super_builtup">
                              Super Built Up: {{propertyinfo.unit_data.super_builtup ? propertyinfo.unit_data.super_builtup : ''}} - {{propertyinfo.unit_data.unit_super_builtup_area_unit.title ? propertyinfo.unit_data.unit_super_builtup_area_unit.title : ''}}
                            </li>
                            <li *ngIf="propertyinfo.unit_data.carpet">
                              Carpet: {{propertyinfo.unit_data.carpet ? propertyinfo.unit_data.carpet + ' ' + propertyinfo.unit_data.unit_carpet_area_unit.title : '-'}}
                            </li>
                            <li *ngIf="!propertyinfo.unit_data.super_builtup && !propertyinfo.unit_data.carpet">
                              No Data
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion_outer" *ngIf="propertyinfo.proj_specification">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#specification" aria-expanded="true" aria-controls="specification">Specification <i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="specification">
                      <div class="row d-flex">
                        <div class="col-md-12">
                          <table class="tbl_spec">
                            <tbody>
                            <tr *ngFor="let specf of propertyinfo.proj_specification; index as i">
                              <td class="tbl_spec_info" valign="top" style="width: 20%;font-size: 13px;">
                                {{specf.specification.title}}
                              </td>
                              <td class="tbl_spec_info" style="width: 80%;font-size: 13px;">{{specf.description}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_amenities" role="tabpanel" aria-labelledby="property_amenities_tab">
                <h5>Amenities</h5>
                <ul class="p_amenities">
                  <li class="s_amenities" *ngFor="let itemAmenities of propertyinfo.proj_amenity">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {{itemAmenities.amenity.title}}
                  </li>
                </ul>
              </div>

              <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="property_amenities_tab" *ngIf="propertyinfo.proj_bank_approve">
                <h5>Bank Approval</h5>
                <div class="logo-container">
                  <ul>
                    <li *ngFor="let bank_app of propertyinfo.proj_bank_approve; index as i">
                      <div class="logo-holder logo-1">
                        <img src="{{bank_app.bank.bank_logo_url}}" alt="{{bank_app.bank.title}}" title="{{bank_app.bank.title}}" width="100%" height="100%" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-pane fade show active" id="ask_que_agent" role="tabpanel" aria-labelledby="ask_que_agent_tab" *ngIf="propertyinfo.assign_to">
                <h5>Ask Realtors Agent {{(propertyinfo.assign_to.name ? propertyinfo.assign_to.name : propertyinfo.assign_to.first_name + ' ' + propertyinfo.assign_to.last_name)}} a Question</h5>
                <div class="row d-flex agent_que_box">
                  <div class="col-md-4">
                    <img src="{{propertyinfo.assign_to.image_preview}}" class="rounded-circle" alt="Cinque Terre" width="95" height="95" />
                    <div class="ag_info">
                      <h6>{{(propertyinfo.assign_to.name ? propertyinfo.assign_to.name : propertyinfo.assign_to.first_name + ' ' + propertyinfo.assign_to.last_name)}}</h6>
                      <!--<p>{{(propertyinfo.assign_to.city.city ? propertyinfo.assign_to.city.city : '')}} Realtors Agent</p>-->
                      <p>{{propertyinfo.assign_to.email}}</p>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <textarea class="form-control" id="agent_text_que" rows="4" placeholder="I'd like to know more about Vadodara Real Estate."></textarea>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-common btn-lg agent_btn">Ask a Question</button>
                    <p *ngIf="propertyinfo.assign_to.contact_no">Text/Call <b>{{propertyinfo.assign_to.contact_no}}</b></p>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_nearby" role="tabpanel" aria-labelledby="property_nearby_tab">
                <h5>Near by your Landmarks</h5>
                <ul class="nav nav-tabs prop_nearby_tabs" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="schools-tab" (click)="changeNearBy('school', gm)" data-toggle="pill" href="#schools" role="tab" aria-controls="schools" aria-selected="true">Schools</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="restaurants-tab" (click)="changeNearBy('restaurant', gm)" data-toggle="pill" href="#restaurants" role="tab" aria-controls="restaurants" aria-selected="false">Restaurants</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="hospitals-tab" (click)="changeNearBy('hospital', gm)" data-toggle="pill" href="#hospitals" role="tab" aria-controls="hospitals" aria-selected="false">Hospitals</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="atms-tab" (click)="changeNearBy('atm', gm)" data-toggle="pill" href="#atms" role="tab" aria-controls="atms" aria-selected="false">Atm's</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="shopping_mall-tab" (click)="changeNearBy('shopping_mall', gm)" data-toggle="pill" href="#shopping_mall" role="tab" aria-controls="shopping_mall" aria-selected="false">Shopping Mall</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="schools-tab">
                    <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false" [mapTypeControl]="true" [usePanning]="true" (mapReady)="mapReady($event)">
                      <agm-marker *ngFor="let m of markers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="icon"
                                  (markerClick)="clickedMarker(m.pid)"
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <div class="imgDiv" [style.background-image]="'url(' + m.image_preview + ')'">&nbsp;</div>
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p><i class="fa fa-rupee"></i> {{ m.asking_price }}</p>
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                      <agm-marker *ngFor="let m of schoolMarkers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="iconSchool"
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p>{{ m.asking_price }}</p>
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- End Property info -->
      </div>
      <!-- ============ Start Appointment Schedule ============ -->
      <div class="col-md-3" [ngClass]="{'sticky-div':enableSticky}">
        <div class="side_block" id="visit">
          <h5>Book Your Appointment</h5>
          <div class="owl-carousel owl-theme" id="visit_date_slider"></div>
          <p>Feel free to Schedule Visit, its free ,with no obligation and cancel any time.</p>
          <a (click)="searchschedulevisit(propertyinfo.franchisee_id, propertyinfo.assign_to_user_id)" class="schdule btn">Book Now</a>
        </div>
        <!--<div class="side_block agent_details">
          <img src="../../assets/img/web-image/projectdetialspage/person.png" alt="Nimpesh Parikh" />
          <h4>Nimpesh Parikh</h4>
          <div class="ag_in_details_flex">
            <span>Pwc Property Hub</span>
            <span>Operating Since <b>2011</b></span>
          </div>
          <div class="ag_in_details">
            <p class="ag_title">Rera Id</p>
            <p>https://gujrera.gujarat.gov.in/viewProjectDetailPage?projectID=8199</p>
          </div>
          <div class="ag_in_details">
            <p class="ag_title">Location</p>
            <p>Bhatar Road, Surat, Gujarat, 395001 India</p>
          </div>
          <div class="ag_in_details_flex">
            <div class="ag_in_details_inner">
              <p class="ag_title">Properties For Sale</p>
              <p>30</p>
            </div>
            <div class="ag_in_details_inner">
              <p class="ag_title">Properties For Rent</p>
              <p>15</p>
            </div>
          </div>
          <a class="schdule btn">Contact Certified Agent</a>
        </div>-->
      </div>
      <!-- ============ Ends Appointment Schedule ============ -->
    </div>

  </div>
</div>
