<div class="property_search_outer">

    <div class="padding-div" id="properties_map_view">
    <div class="row mx-0">
      <div class="col-md-6 map-view-main-outer px-0">
        <div class="map-view">
          <span class="save-search-btn" (click)="openSaveSearchPopup()" *ngIf="isLoggedIn">Save Search</span>
          <div class="saveSearchPopup">
            <form [formGroup]="saveSearchForm" (ngSubmit)="onSaveSearch()">
              <div class="form-group">
                <input [(ngModel)]="searchnameval" formControlName="searchname" class="form-control"
                       placeholder="Search Name" [ngClass]="{ 'is-invalid': submitted && g.searchname.errors }" />
                <div *ngIf="submitted && g.searchname.errors" class="invalid-feedback">
                  <div *ngIf="g.searchname.errors.required">Search Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-block save-form-btn">Save Search</button>
                <span  (click)="viewSaveSearched()"  class="view_saved_searches">View Saved Searches</span>
              </div>
            </form>
          </div>
          <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
            [mapTypeControl]="true" [usePanning]="true" (mapReady)="mapReady($event)" (zoomChange)="zoomChange($event)">
            <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
              [label]="m.label" [iconUrl]="m.symbol" 
              (mouseOver)="onMarkerMouseOver(infoWindow, gm)" [markerDraggable]="m.draggable">
              <agm-info-window [disableAutoPan]="false" #infoWindow>
                <div class="infoWindowOuter">
                  <div *ngIf="m.image_preview" class="imgDiv" [style.background-image]="'url(\'' + m.image_preview + '\')'">
                    &nbsp;</div>
                  <!-- <div class="otherInfo">
                    <h4>{{ m.locality_title }}</h4>
                  </div> -->

                  <div class="otherInfo">
                    <h4>{{ m.locality_title }}</h4>
                    <div >{{m.address}}</div>
                    <div class="dir_top">
                      <span class="link" (click)="getDiraction(m.lat,m.lng)">Get Directions</span>&nbsp;|&nbsp;<span class="link" (click)="openProjectDetails(m.pid)">Visit Project Detail</span>
                    </div>

                    <div class="property_badge_map" >
                      <span 
                        class="property_badge_sell_map">{{m.status}}</span>
                    </div>
                    <!-- <p *ngIf='m.asking_price'><i class="fa fa-rupee"></i> {{ m.asking_price }}</p> -->
                  </div>


                </div>
              </agm-info-window>
            </agm-marker>
            <agm-polyline [visible]="true" [strokeWeight]="3" [strokeColor]="'#07b57a'">
              <agm-polyline-point *ngFor="let coordinate of latlngarea; let i=index" [latitude]="coordinate[0]"
                [longitude]="coordinate[1]">
              </agm-polyline-point>
            </agm-polyline>
          </agm-map>
        </div>
      </div>

      <div class="col-md-6 px-0 content-view-main-outer" (click)="hideSearchBox()">
        <ng-container *ngIf="isLoading == true">
          <div class="loading_div">
            <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
          </div>
        </ng-container>
        <div class="content-view px-3">
          <div class="row">
          <div class="col-md-1 mt-1">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                New
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="searchProject(4)">Re-Sale</a>
                <a class="dropdown-item" (click)="searchProject(3)">Rent</a>
                <!-- <a class="dropdown-item" href="#">Sell</a> -->
              </div>
            </div>
          </div>
          <div class="col-md-10" style="margin-left: 34px;">
            <div class="input-group search_text_input" id="search_text">
              <input type="text" id="searchinput" class="form-control form-control-lg"
                     placeholder="Type Location or Project/Society or Keyword">
              <span class="input-group-append" (click)="searchProjectInput()"><i class="fa fa-search"></i></span>
            </div>
          </div>
        </div>
          <div class="col-md-12 new-filter">
            <label> Budget &nbsp;&nbsp;</label>
            <div class="form-group row mx-0">
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_budget_amount"
                       placeholder="Min" name="min_budget_amount" />
              </div>
              <label> to </label>
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_budget_amount"
                       placeholder="Max" name="max_budget_amount" />
              </div>
              <span id="clearBudgetFilter"><i class="fa fa-times" aria-hidden="true" (click)="clearBudgetFilter()"></i></span>
              <div class="col-md-5 filterBtnDiv">
                <button type="button" id="filterBtn" class="btn btn-block login-btn mx-2 plus-minus collapsed"
                        (click)="showHideFilter()">Filters</button>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-9 prop_type_title">
              <h4>{{ name }}</h4>
            </div>
            <div class="col-3 iconview" *ngIf="projectsList.length>0 && moreFilter=='close'">
              <span class="sort_btn"><i class="fa fa-sort-amount-asc" aria-hidden="true"></i></span>
              <span  [ngClass]="activeView ? 'grid_btn active_view' : 'grid_btn'" (click)="showgridviewfunction()"><i class="fa fa-th-large"
                  aria-hidden="true"></i></span>
              <span  [ngClass]="!activeView ? 'list_btn active_view' : 'list_btn'" (click)="showlistviewfunction()"><i class="fa fa-list"
                  aria-hidden="true"></i></span>
            </div>
            <div class="col-12 prop_cat_view">
              <span class="total_props" *ngIf="total_props_s">{{ total_props_s }}</span>
            </div>
          </div>

          <!-- More Filters Options Starts -->
          <div class="row collapse mt-3" id="moreFilter">
            <div class="col-md-12 filter-property-form" style="border-bottom: 1px solid #cbcbcb !important;">
              <div class="row mx-0">
                <div class="col-md-12">
                  <!-- Category -->
                  <!--<label><i class="fa fa-briefcase fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Category</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer" id="f_property_type_category">
                      <li class="pl-2" *ngFor="let singlett of propertyTypeCategory">
                        {{ singlett.title }}
                        <input type="radio" id="tt{{ singlett.id }}" (change)="searchboxapply(0)" value="{{ singlett.id }}" [(ngModel)]="propertytypeid"><span class="checkmark"></span>
                      </li>
                    </ul>
                  </div>-->
                  <div class="col-md-12 col-sm-6 multi-select">
                    <label><i class="fa fa-cubes fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Category</b></label>
                    <div class="form-group pl-3 pt-2">
                      <ul class="filter_drop_outer" style="height: auto;display: flex;">
                        <li class="pl-2" *ngFor="let singlett of projectTypeCategory">
                          <label class="custom_checkbox">
                            {{ singlett.title }}
                            <input type="radio" id="tt{{ singlett.id }}" (change)="searchboxapply(0)" value="{{ singlett.id }}" [(ngModel)]="propertytypeid"><span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Ends -->
                  <div class="col-md-12" id="">
                    <!-- Project Status -->
                    <label><i class="fa fa-cog fa_icon" aria-hidden="true"></i> &nbsp;<b> Project Status</b></label>
                    <div class="form-group pl-2 mb-2 pb-3">
                      <ng-container *ngFor="let status of projectStatusList">
                        <div class="checkbox-div">
                          <input type="checkbox" name="projstatus[]" id="projstatus{{ status.id }}" class="f_box_check" value="{{ status.id }}" [(ngModel)]="status.isChecked">
                          <label class="f_box_label" for="projstatus{{ status.id }}">{{ status.text }}</label>
                        </div>
                      </ng-container>
                    </div>
                    <!-- Ends -->
                  </div>
                </div>


                <div class="col-md-6" id="bedroom_filter_div">
                  <!-- Bedrooms -->
                  <label><i class="fa fa-bed fa_icon" aria-hidden="true"></i> &nbsp;<b> Bedrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebedroom of bedroomList">
                      <div class="checkbox-div">
                        <input type="checkbox" name="beds[]" id="b{{ singlebedroom.id }}" class="f_box_check" value="{{ singlebedroom.id }}" [(ngModel)]="singlebedroom.isChecked">
                        <label class="f_box_label" for="b{{ singlebedroom.id }}">{{ singlebedroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>
                <div class="col-md-6" id="bathroom_filter_div">
                  <!-- Bathrooms -->
                  <label><i class="fa fa-bath fa_icon" aria-hidden="true"></i> &nbsp;<b> Bathrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebathroom of bathroomList">
                      <div class="checkbox-div">
                        <input type="checkbox" name="baths[]" id="bath{{ singlebathroom.id }}" class="f_box_check" value="{{ singlebathroom.id }}" [(ngModel)]="singlebathroom.isChecked">
                        <label class="f_box_label" for="bath{{ singlebathroom.id }}">{{ singlebathroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>

                <!-- Property Facts -->
                <div class="col-md-12" id="prop_fact_div">
                  <label><i class="fa fa-area-chart fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Facts</b></label>
                  <div class="form-group">
                    <div class="row mx-0">
                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Min Carpet Area</label>
                        </div>
                        <div class="col-12" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_saleable_area"
                                   placeholder="Min" name="min_saleable_area" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control" name="min_saleable_area_unit"
                                    id="min_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Max Carpet Area</label>
                        </div>
                        <div class="col-12 px-2" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_saleable_area"
                                   placeholder="Max" name="max_saleable_area" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control" name="max_saleable_area_unit"
                                    id="max_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ends -->

                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i class="fa fa-tree fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Type</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_category"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Amenities -->
                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i class="fa fa-tree fa_icon" aria-hidden="true"></i> &nbsp;<b> Amenities</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_amenities"></ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Ends -->
              </div>
            </div>

            <div class="col-md-12 filter-foot">
              <div class="col-md-6">
                <button type="button" class="btn login-btn mx-2" (click)="clearFilter()">Reset</button>
              </div>
              <div class="col-md-6 text-right">
                <button type="button" class="btn login-btn mx-2" (click)="searchboxapply(1)">Apply Filters</button>
              </div>
            </div>

          </div>
          <!-- More Filters Options Ends -->

          <!-- Start Project Grid View -->
          <div class="row" id="property_grid_view">
            <ng-container *ngFor="let item of projectsList  | paginate : pageConfig">
              <!-- <img src="{{item.image_preview }}" alt=""> -->
              
              <div class="col-md-6 d-flex single_property_grid" *ngIf="item.name">
                <div  (click)="openProjectDetails(item.id)" class="card">
                  <div class="grid_image_view" [style.background-image]="'url(\'' + item.image_preview + '\')'">
                    
                    <!-- <div class="property_badge top_left" *ngIf="item.transaction_type">
                      <span *ngIf="item.is_coming_soon == 1" class="property_badge_sell">Coming Soon</span>
                    </div> -->
                    <div class="property_badge top_left" *ngIf="item.project_status">
                      <!-- <span class="property_badge_sell" *ngIf="item.is_coming_soon == 1">Coming Soon</span> -->
                      <span class="property_badge_sell">{{item.project_status}}</span>
                    </div>


                    <div class="property_badge top_right favoriteIcon_{{item.id}}"
                      [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }"
                      (click)="makeFavorite(item.id, item.is_favorited, item.franchisee_id);$event.stopPropagation()">
                      <!--<i class="fa fa-heart fav_white_color" *ngIf="item.is_favorited == 0"></i>
                      <i class="fa fa-heart fav_red_color" *ngIf="item.is_favorited == 1"></i>-->
                      <i id="i_favIcon_{{item.id}}" class="fa fa-heart fav_white_color"></i>
                    </div>
                  </div>
                  <div class="card-body">
                    <h6 class="card-title card_text_title">{{item.name}}</h6>
                    <p class="card-text">
                      {{item.locality_title}} {{(item.city ? item.city.city : '')}}
                    </p>
                    <p class="card-text">
                      <span><b>Units: </b></span > {{item.project_unit_group ? item.project_unit_group : 'No Data'}}
                    </p>
                    <span class="project_status" *ngIf="item.project_status">
                      <label class="label" *ngIf="item.project_status == 'Under Construction'" style="background-color: #FF9000;">{{item.project_status}}</label>
                      <label class="label" *ngIf="item.project_status == 'Pre Launch'" style="background-color: #B6E517;">{{item.project_status}}</label>
                      <label class="label" *ngIf="item.project_status == 'Ready Possession'" style="background-color: #3E6BE8;">{{item.project_status}}</label>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 text-right" *ngIf="isProject == true">
              <pagination-controls id="property_grid" (pageChange)="pageChange($event, 10)" class="my-pagination"
                maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- End Project Grid View -->

          <!-- Start Project List View -->
          <div class="row" id="property_list_view">
            <ng-container *ngFor="let item of projectsList">
              <div class="col-md-12 single_property_list" *ngIf="item.name" (click)="openProjectDetails(item.id)">
                <div class="row no-gutters">
                  <!--<div class="col-md-6">
                    <div class="list_image_view" [style.background-image]="'url(' + item.image_preview + ')'">
                      <div class="property_badge top_left" *ngIf="item.transaction_type">
                        <span class="property_badge_sell">{{item.transaction_type}}</span>
                      </div>
                      <div class="property_badge top_right" *ngIf="item.transaction_type">
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: white"></i>
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: red"></i>
                      </div>
                    </div>
                  </div>-->
                  <div class="col-md-12">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="row">
                            <span class="card-title card_text_title">{{item.name}}</span>

                            <span class="card-text">
                              <i class="fa fa-map-marker"></i>
                              {{item.locality_title}} {{(item.city ? item.city.city : '')}}
                            </span>
                          </div>
                          <!--<p class="card-text">
                            {{ item.project_type }}
                          </p>-->
                        </div>
                        <div class="col-md-7">
                          <p class="card-text font_Size_tile">
                            <span><b>Units: </b></span> {{item.project_unit_group ? item.project_unit_group : 'No Data'}}
                            <span class="project_status" *ngIf="item.project_status">
                              <label class="label" *ngIf="item.project_status == 'Under Construction'" style="background-color: #FF9000;">{{item.project_status}}</label>
                              <label class="label" *ngIf="item.project_status == 'Pre Launch'" style="background-color: #B6E517;">{{item.project_status}}</label>
                              <label class="label" *ngIf="item.project_status == 'Ready Possession'" style="background-color: #3E6BE8;">{{item.project_status}}</label>
                            </span>
                          </p>
                          <!--<button [routerLink]="['/project-details', item.id ]" class="btn project-owner-btn">Contact Owner</button>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 text-right" *ngIf="isProject == true">
              <pagination-controls id="property_grid" (pageChange)="pageChange($event, 10)" class="my-pagination"
                maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- End Project List View -->

          <ng-container *ngIf="projectsList.length == 0 && moreFilter === 'close' && isLoading == false">
            <div id="lead_generator_div" class="lead_generator_main" [formGroup]="leadForm">
              <div class="contact-info-outer">
                <div class="row d-flex">
                  <div class="col-md-12">
                    <!-- <app-flash></app-flash> -->
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="first_name" formControlName="firstName" class="form-control"
                        placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && l.firstName.errors }" />
                      <div *ngIf="submitted && l.firstName.errors" class="invalid-feedback">
                        <div *ngIf="l.firstName.errors.required">First Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="last_name" formControlName="lastName" class="form-control"
                        placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && l.lastName.errors }" />
                      <div *ngIf="submitted && l.lastName.errors" class="invalid-feedback">
                        <div *ngIf="l.lastName.errors.required">Last Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="email" [(ngModel)]="email" formControlName="profileEmail" class="form-control"
                        placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && l.profileEmail.errors }" />
                      <div *ngIf="submitted && l.profileEmail.errors" class="invalid-feedback">
                        <div *ngIf="l.profileEmail.errors.required">Email is required</div>
                        <div *ngIf="l.profileEmail.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="phone" formControlName="mobileNumber" class="form-control"
                        [pattern]="mobNumberPattern" placeholder="Mobile Number"
                        [ngClass]="{ 'is-invalid': submitted && l.mobileNumber.errors }" />
                      <div *ngIf="submitted && l.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="l.mobileNumber.errors.required">Mobile Number is required</div>
                        <div
                          *ngIf="l.mobileNumber.errors.pattern || l.mobileNumber.errors.minlength || l.mobileNumber.errors.maxlength">
                          Please enter valid Mobile Number</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="save-button">
                <div class="row d-flex">
                  <div class="col-md-3">&nbsp;</div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button type="submit" id="submitProjLead" class="btn btn-block login-btn" (click)="onLeadFormSubmit()">Send Your Details</button>
                      <button type="button" class="btn btn-block login-btn loaderBtn" disabled style="display: none;">
                        <span class="spinSpan spinner-border" role="status"></span> Saving
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3">&nbsp;</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <app-property-list-footer></app-property-list-footer>

      </div>
    </div>
  </div>
</div>

<div class="change-view">
  <a href="javascript:void(0);" class="show-map" (click)="toggleMapView()"><i class="fa fa-map"></i> &nbsp; Map</a>
  <a href="javascript:void(0);" class="show-list" (click)="toggleListView()"><i class="fa fa-list"></i> &nbsp;
    List</a>
</div>
