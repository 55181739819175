<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid favourite-property-outer" id="accoutnSettings">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <!--<h4 class="page-title">Shortlisted Property</h4>
        <div class="shortlisted-property">
          <div class="empty-message">
            <img src="{{ home_icon }}" class="home-icon" alt="Home" />
            <div class="empty-text">
              <h5>Your Shortlist is Empty</h5>
              <p>Add your top 3 favourite homes into your Shortlist, so that you can track them more easily</p>
            </div>
          </div>
        </div>--->
        <!--<h4 class="page-title">Your Favourites ({{ favouritesPropertiesList.length }})</h4>-->
        <h4 class="page-title">Your Favourites</h4>

        <ul class="nav nav-tabs">
          <li class="nav_li active"><a data-toggle="tab" class="active" href="#resale">Resale ({{ favouritesPropertiesList.length }})</a></li>
          <li class="nav_li active"><a data-toggle="tab" href="#new">New ({{ favouritesProjectList.length }})</a></li>
        </ul>

        <div class="favourite-property">

          <div class="tab-content">

            <div id="resale" class="tab-pane fade in active show mt-3">
              <ng-container *ngIf="isLoading == false">
                <div class="empty-message" *ngIf="favouritesPropertiesList.length == 0">
                  <img src="{{ home_icon }}" class="home-icon" alt="Home" />
                  <div class="empty-text">
                    <h5>You don't have any Favourite Property.</h5>
                    <p>Get notifications when new homes that match your criteria hit the market.</p>
                  </div>
                  <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                </div>
              </ng-container>
              <ng-container *ngIf="isLoading == true">
                <div class="loading_div">
                  <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
                </div>
              </ng-container>
              <ng-container *ngFor="let favouritesItem of favouritesPropertiesList">
                <div *ngIf="isLoading == false" [ngClass]="favouritesItem?.prop_status?.title==='Sold' ? 'account-list-property remove_hand_cursor':'account-list-property'">
                  <div class="p_image_view" (click)="navigateTo(favouritesItem)"  [style.background-image]="'url(\'' + favouritesItem.image + '\')'"></div>
                  <div class="property_badge_ top_left_" *ngIf="favouritesItem?.prop_status && favouritesItem?.prop_status?.title" >
                    <span [ngClass]="favouritesItem?.prop_status?.title==='Available' ? 'property_badge_sell_ available_tag' : favouritesItem?.prop_status?.title==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{favouritesItem?.prop_status?.title}}</span>
                    <!-- <span class="property_badge_sell_ neg">Under Negotiation</span> -->
                  </div>
                  <div class="p_details">
                    <div class="p_details_top">
                      <div class="price_div">
                        <span>
                          {{ favouritesItem.display_name }}</span>
                        <!--<i class="fa fa-trash" (click)="makeFavorite(favouritesItem.id)"></i>-->
                        <ng-container *ngIf="favouritesItem?.prop_status?.title!='Sold' && favouritesItem?.prop_status?.title!='Under offer'">
                          <button (click)="onRescheduleAppointmentClick(0, favouritesItem.property_id,  favouritesItem.assign_to_user_id, favouritesItem.franchisee_id, 'property')" class="btn make-offer-btn mobile-hide color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                          <button (click)="onRescheduleAppointmentClick(0, favouritesItem.property_id, favouritesItem.assign_to_user_id, favouritesItem.franchisee_id, 'property')" class="btn make-offer-btn appointment_btn mobile-show color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                        </ng-container>
                      </div>
                      <p class="address"><i class="fa fa-rupee"></i>{{ favouritesItem.asking_price_in_word }}</p>
                      <p class="card-text">
                        <span class="pro_spec" *ngIf="favouritesItem.total_showers"> <i class="fa fa-bath"></i> {{(favouritesItem.total_showers ? favouritesItem.total_showers : 0)}} Bath </span>
                        <span class="pro_spec" *ngIf="favouritesItem.total_bedrooms"> <i class="fa fa-bed"></i> {{(favouritesItem.total_bedrooms ? favouritesItem.total_bedrooms : 0)}} Bed </span>
                        <span class="pro_spec" *ngIf="favouritesItem.super_builtup"> <i class="fa fa-check-square"></i> {{ favouritesItem.super_builtup }} Sq.Ft. </span>
                      </p>
                    </div>
                    <div class="p_details_bottom" (click)="navigateTo(favouritesItem)" >
                      <ul class="prop_more_details">
                        <li *ngIf="favouritesItem.transaction_name">
                          <h5>Type</h5>
                          <p>{{ favouritesItem.transaction_name }}</p>
                        </li>
                        <li *ngIf="favouritesItem.rate_sqft">
                          <h5>Price per SQ.FT</h5>
                          <p><i class="fa fa-rupee"></i>{{ favouritesItem.rate_sqft }}</p>
                        </li>
                        <li *ngIf="favouritesItem.created">
                          <h5>Year Built</h5>
                          <p>{{ favouritesItem.created | date : "y" }}</p>
                        </li>
                        <li *ngIf="favouritesItem.property_ownership">
                          <h5>Ownership</h5>
                          <p>{{ favouritesItem.property_ownership }}</p>
                        </li>
                        <li *ngIf="favouritesItem.transfer_fees">
                          <h5>HOA Dues</h5>
                          <p><i class="fa fa-rupee"></i>{{ favouritesItem.transfer_fees }}</p>
                        </li>
                        <li *ngIf="favouritesItem.current_rent">
                          <h5>Lot Size</h5>
                          <p><i class="fa fa-rupee"></i>{{ favouritesItem.current_rent }}</p>
                        </li>
                        <li *ngIf="favouritesItem.furnished_type">
                          <h5>Furnished</h5>
                          <p>{{ favouritesItem.furnished_type }}</p>
                        </li>
                        <li *ngIf="favouritesItem.locality_title">
                          <h5>Locality</h5>
                          <p>{{ favouritesItem.locality_title }}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div id="new" class="tab-pane fade mt-3">
              <ng-container *ngIf="isLoading == false">
                <div class="empty-message" *ngIf="favouritesProjectList.length == 0">
                  <img src="{{ home_icon }}" class="home-icon" alt="Home" />
                  <div class="empty-text">
                    <h5>You don't have any Favourite Project.</h5>
                    <p>Get notifications when new homes that match your criteria hit the market.</p>
                  </div>
                  <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                </div>
              </ng-container>
              <ng-container *ngIf="isLoading == true">
                <div class="loading_div">
                  <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
                </div>
              </ng-container>
              <ng-container *ngFor="let favProjectItem of favouritesProjectList">
                <div *ngIf="isLoading == false" class="account-list-property">
                  <div class="p_image_view" (click)="navigateTo(favProjectItem)"  [style.background-image]="'url(\'' + favProjectItem.image + '\')'"></div>
                  <div class="p_details">
                    <div class="p_details_top">
                      <div class="price_div">
                        <span>{{ favProjectItem.display_name }}</span>
                        <!--<i class="fa fa-trash"></i>-->
                        <button (click)="onRescheduleAppointmentClick(favProjectItem.project_id, favProjectItem.project_unit_id, favProjectItem.assign_to_user_id, favProjectItem.franchisee_id, 'project')" class="btn make-offer-btn mobile-hide color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                        <button (click)="onRescheduleAppointmentClick(favProjectItem.project_id, favProjectItem.project_unit_id, favProjectItem.assign_to_user_id, favProjectItem.franchisee_id, 'project')" class="btn make-offer-btn appointment_btn mobile-show color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                      </div>
                      <p class="address" *ngIf="favProjectItem.show_price_on_website==1"><i class="fa fa-rupee"></i>{{ favProjectItem.asking_price_in_word }}</p>
                      <p class="card-text">
                        <span class="pro_spec" *ngIf="favProjectItem.total_showers"> <i class="fa fa-bath"></i> {{(favProjectItem.total_showers ? favProjectItem.total_showers : 0)}} Bath </span>
                        <span class="pro_spec" *ngIf="favProjectItem.total_bedrooms"> <i class="fa fa-bed"></i> {{(favProjectItem.total_bedrooms ? favProjectItem.total_bedrooms : 0)}} Bed </span>
                        <span class="pro_spec" *ngIf="favProjectItem.area_size"> <i class="fa fa-check-square"></i> {{ favProjectItem.area_size }} Sq.Ft. </span>
                      </p>
                    </div>
                    <div class="p_details_bottom" (click)="navigateTo(favProjectItem)" >
                      <ul class="prop_more_details">
                        <li *ngIf="favProjectItem.transaction_name">
                          <h5>Type</h5>
                          <p>{{ favProjectItem.transaction_name }}</p>
                        </li>
                        <li *ngIf="favProjectItem.rate_sqft">
                          <h5>Price per SQ.FT</h5>
                          <p><i class="fa fa-rupee"></i>{{ favProjectItem.rate_sqft }}</p>
                        </li>
                        <li *ngIf="favProjectItem.created">
                          <h5>Year Built</h5>
                          <p>{{ favProjectItem.created | date : "y" }}</p>
                        </li>
                        <li *ngIf="favProjectItem.property_ownership">
                          <h5>Ownership</h5>
                          <p>{{ favProjectItem.property_ownership }}</p>
                        </li>
                        <li *ngIf="favProjectItem.transfer_fees">
                          <h5>HOA Dues</h5>
                          <p><i class="fa fa-rupee"></i>{{ favProjectItem.transfer_fees }}</p>
                        </li>
                        <li *ngIf="favProjectItem.current_rent">
                          <h5>Lot Size</h5>
                          <p><i class="fa fa-rupee"></i>{{ favProjectItem.current_rent }}</p>
                        </li>
                        <li *ngIf="favProjectItem.furnished_type">
                          <h5>Furnished</h5>
                          <p>{{ favProjectItem.furnished_type }}</p>
                        </li>
                        <li *ngIf="favProjectItem.locality_title">
                          <h5>Locality</h5>
                          <p>{{ favProjectItem.locality_title }}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</div>
