<div class="container px-1 fixed_position_header">
  <nav class="nav-menu my-account-menu" *ngIf="isLoggedIn">
    <ul>
      <li class="nav-item dropdown" *ngIf="contactType === 'both'; else singleContact">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{dashboardType}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" id="buyerLink" [routerLink]="['/buyerdashboard']" [queryParams]="{type: 'Buyer'}"
            [routerLinkActive]="['is-active']">Buyer</a>
          <a class="dropdown-item" id="sellerLink" [routerLink]="['/sellerdashboard']" [queryParams]="{type: 'Seller'}" [routerLinkActive]="['is-active']">Seller</a>
        </div>
      </li>
      <ng-template #singleContact>
        <li *ngIf="contactType == 'Buyer'"><a [routerLink]="['/buyerdashboard']" [queryParams]="{type: 'Buyer'}" [routerLinkActive]="['is-active']">Buyer Dashboard</a></li>
        <li *ngIf="contactType == 'Seller'"><a [routerLink]="['/sellerdashboard']" [queryParams]="{type: 'Seller'}" [routerLinkActive]="['is-active']">Seller</a></li>
      </ng-template>
      <li *ngIf="dashboardType == 'Buyer'"><a [routerLink]="['/favourites']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">Favourites</a></li>
      <li *ngIf="dashboardType == 'Buyer'"><a [routerLink]="['/savedsearch']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">Saved Search</a></li>
      <li *ngIf="dashboardType == 'Buyer'"><a [routerLink]="['/openhouse']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">My Appointments</a></li>
      <li *ngIf="dashboardType == 'Buyer'"><a [routerLink]="['/buyer-viewed']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">Recently Viewed</a></li>
      <li *ngIf="dashboardType == 'Seller'"><a [routerLink]="['/seller-property']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">My Property</a></li>
      <li *ngIf="dashboardType == 'Seller'"><a [routerLink]="['/openhouse']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">My Appointments</a></li>
      <li><a [routerLink]="['/offers']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">Offers</a></li>
      <!-- <li><a [routerLink]="['/reviews']" [queryParams]="{type: dashboardType}" [routerLinkActive]="['is-active']">My
          Reviews</a></li>
      <li><a [routerLink]="['/email-settings']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">My Email Preferences</a></li> -->
      <li><a [routerLink]="['/myaccount']" [queryParams]="{type: dashboardType}"
          [routerLinkActive]="['is-active']">Account Settings</a></li>
    </ul>
  </nav>
</div>
