import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/global';
import { ApiService } from './../../_services/api.service';
declare var $: any;

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  home_icon: string = '';
  applyForm: FormGroup;
  submitted = false;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  phone: string = '';
  mobilenumber: string = '';
  fileName = '';
  position = '';
  resumeFile = {};

  constructor(private ApiService: ApiService, private route: ActivatedRoute, private title: Title, private formBuilder: FormBuilder) {
    this.home_icon = API_ENDPOINTS.HOME_ICON;
   }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    /*if(!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }*/
    this.route.params.subscribe(routeParams => {
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });

    this.applyForm = this.formBuilder.group({
      position: [''],
      fullname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
      resume: ['', [Validators.required]],
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $('#imgHeadLogo').attr('src', 'assets/img/empire_logo.png');
    this.title.setTitle('Empire Realtors » Jobs');
  }

  get f() {
    return this.applyForm.controls;
  }

  openApplyModel(position) {
    $('#applyFormModel').modal('toggle');
    this.position = position;
    $('#position').val(position);
  }

  onFileSelected(event) {
    const file:File = event.target.files[0];
    this.resumeFile = file;
  }

  onApplySubmtit() {
    this.submitted = true;
    if (this.applyForm.invalid) {
      this.submitted = false;
      return;
    }
    var form_data = new FormData();
    const resumeFile: {} = this.resumeFile;
    form_data.append('position', this.position);
    form_data.append('name', this.applyForm.value.fullname);
    form_data.append('contact_no', this.applyForm.value.mobileNumber);
    form_data.append('email', this.applyForm.value.email);
    // @ts-ignore
    form_data.append('file', resumeFile);
    form_data.append('is_web_api', '1');
    this.ApiService.jobAppplication(form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        setTimeout (() => {
          $('#applyFormModel').modal('toggle');
        }, 1000);
      }
    }, error => {
      // console.log('error');
    });
  }
}
