/* tslint:disable */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { API_ENDPOINTS } from '../../global';
import { ApiService } from './../../_services/api.service';
import { FlashService } from '../../_services/FlashService.service';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { environment } from 'src/environments/environment';

declare var FB: any;
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  registerForm: FormGroup;
  loginform: FormGroup;
  forgotPasswordform: FormGroup;
  mobileUpdateForm: FormGroup;
  loginSubmitted: boolean = false;
  registerSubmitted: boolean = false
  forgotSubmit: boolean = false
  onMobileUpdateSubmitted: boolean = false;
  verifyOtpSubmitted: boolean = false;
  resendOtpsubmitted: boolean = false;
  username: any
  isLoggedIn;
  isMobileVerified;
  auth2: any;
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  _document: any;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  phone: string = '';
  mobilenumber: string = '';
  mobileupdateuser: string = '';
  verifyUserEmail: string = '';

  otp: string;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  otpConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: true,
    placeholder: '*',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

  contactType = '';
  dashboardType = 'Buyer';
  normalLoggingIn = false;
  mobileUpadating = false;
  otpUpadating = false;



  constructor(private ApiService: ApiService,
    private authService: SocialAuthService, private route: ActivatedRoute, private FlashService: FlashService, private formBuilder: FormBuilder) {
    this.username = localStorage.getItem('username');
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.verifyUserEmail = params.email;
    }
    );
    this.isLoggedIn = this.ApiService.loginCheck();
    /** Start Google Signin **/
    // this.googleSDK();
    /** End Google Signin **/

    // this.fbSDK()



    this.registerForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
    });

    this.mobileUpdateForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
    });

    this.loginform = this.formBuilder.group({
      emaillogin: ['', [Validators.required, Validators.email]],
      passwordlogin: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
    });

    this.forgotPasswordform = this.formBuilder.group({
      emailreset: ['', [Validators.required, Validators.email]]
    });

    $(function () {
      var navMain = $(".navbar-toggleable-md");
      navMain.on("click", "a:not([data-toggle])", null, function () {
        navMain.collapse('hide');
      });
    });

    $('.forgotPasswordform').hide();
    $('#forgotPasswordLi').hide();

    if (this.isLoggedIn) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser) {
        var form_data = new FormData();
        form_data.append('is_web_api', '1');
        this.ApiService.postRequest(API_ENDPOINTS.API_CHECK_USER_ACC_TYPE, form_data).subscribe((apiResult: any) => {
          if (apiResult.success) {
            this.dashboardType = apiResult.data.contact_type;
          }
        });
      }
    }
  }

  fbSDK() {
    /** Start FB Signin **/
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: environment.F_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    /** End FB Signin **/
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  openLoginModel(): void {
    $("#login").addClass('zsg-tab_active');
    $("#newac").removeClass('zsg-tab_active');
    $('#loginModal').modal('toggle');
    $('#login').show();
    $('#newac').show();
    $('.loginform').show();
    $('.newform').hide();
    $('.social_login_section').show();
    $('.social-pop-btn').show();
    $('.forgotPasswordform').hide();
    $('#forgotPasswordLi').hide();
    this.FlashService.hide("");
    this.resetForms()
  }

  openOtpModel(): void {
    $('#otpModal').modal('toggle');
  }

  openMobileUpdateModel(): void {
    $('#mobileUpdateModal').modal('toggle');
  }

  showLogin(): void {
    $(".newform").hide();
    $(".loginform").show();
    $("#login").addClass('zsg-tab_active');
    $("#newac").removeClass('zsg-tab_active');
    this.resetForms()
  }

  showRegister(): void {
    $(".newform").show();
    $(".loginform").hide();
    $("#newac").addClass('zsg-tab_active');
    $("#login").removeClass('zsg-tab_active');
    this.resetForms()
  }

  get f() {
    return this.registerForm.controls;
  }

  get g() {
    return this.loginform.controls;
  }

  get k() {
    return this.forgotPasswordform.controls;
  }

  get m() {
    return this.mobileUpdateForm.controls;
  }

  get r() {
    return this.forgotPasswordform.controls;
  }

  prepareLoginButton() {
    this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleUser) => {
        this.normalLoggingIn = true;
        let profile = googleUser.getBasicProfile();
        var sco_api = '1';
        var form_data = new FormData();
        form_data.append('username', profile.getEmail());
        form_data.append('google', profile.getId());
        form_data.append('name', profile.getName());
        form_data.append('is_sso', sco_api);
        form_data.append('is_web_api', sco_api);
        this.ApiService.login(form_data).subscribe((apiResult: any) => {
          this.normalLoggingIn = false;
          if (apiResult.success) {
            this.openLoginModel();
            window.location.reload();
          } else {
            if (apiResult.error && apiResult.error_code === "err-000040") {
              this.openLoginModel();
              if (apiResult.data.contact_no) {
                this.mobileupdateuser = apiResult.data.id;
                this.openOtpModel();
              } else {
                this.mobileupdateuser = apiResult.data.id;
                this.openMobileUpdateModel();
              }
            }
          }
        });
      });
  }

  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: environment.G_CLIENT_ID,
          cookiepolicy: 'single_host_origin',
          scope: environment.G_SCOPE
        });
        this.prepareLoginButton();
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
  }

  onRegisterSubmit() {
    this.registerSubmitted = true;
    this.normalLoggingIn = true;
    if (this.registerForm.invalid) {
      this.normalLoggingIn = false;
      return;
    }
    var emailid = this.registerForm.value.email;
    var passwd = this.registerForm.value.password;
    var f_name = this.registerForm.value.firstname;
    var l_name = this.registerForm.value.lastname;
    var mobile = this.registerForm.value.mobileNumber;
    var web_api = '1';
    var form_data = new FormData();
    form_data.append('email', emailid);
    form_data.append('password', passwd);
    form_data.append('first_name', f_name);
    form_data.append('last_name', l_name);
    form_data.append('contact_no', mobile);
    form_data.append('is_web_api', web_api);
    form_data.append('from', 'web');
    this.ApiService.register(form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.registerForm.reset()
        this.registerSubmitted = false
        //this.openLoginModel();
        //window.location.reload();
        if (apiResult.message.includes('Registration done successfully. Check inbox to verify email.')) {
          $('#loginModal').hide();
          this.openOtpModel();
        }
      } else {
        if (apiResult.error && apiResult.error_code === "err-000040") {
          this.openLoginModel();
          if (apiResult.data.contact_no) {
            this.mobileupdateuser = apiResult.data.id;
            this.openOtpModel();
          } else {
            this.mobileupdateuser = apiResult.data.id;
            this.openMobileUpdateModel();
          }
        }
      }
      this.normalLoggingIn = false;
    }, error => {
      this.normalLoggingIn = false;
      console.log('error');
    });
  }

  onLoginSubmit() {

    this.loginSubmitted = true;
    this.normalLoggingIn = true;
    var emailid = this.loginform.value.emaillogin;
    var passwd = this.loginform.value.passwordlogin;
    if (!emailid && !passwd) {
      if (this.loginform.invalid) {
        this.normalLoggingIn = false;
        return;
      }
    }
    var web_api = '1';
    var form_data = new FormData();
    form_data.append('username', emailid);
    form_data.append('password', passwd);
    form_data.append('is_web_api', web_api);
    this.ApiService.login(form_data).subscribe((apiResult: any) => {
      this.loginSubmitted = true;
      if (apiResult.success) {
        this.openLoginModel();
        window.location.reload();
      } else if (apiResult.error && apiResult.error_code === "err-000040") {
        this.openLoginModel();
        this.FlashService.error("");
        this.FlashService.success("");
        if (apiResult.data.contact_no) {
          this.mobileupdateuser = apiResult.data.id;
          this.openOtpModel();
        } else {
          this.mobileupdateuser = apiResult.data.id;
          this.openMobileUpdateModel();
        }
      }
      else if (apiResult.error && apiResult.error_code.includes("Please verify your email")) {
        $('#loginModal').hide();
        this.openOtpModel();
      }

      this.normalLoggingIn = false;
    }, error => {
      this.normalLoggingIn = false;
      console.log('error');
    });
  }

  onForgotPassSubmit() {

    this.forgotSubmit = true;
    this.normalLoggingIn = true;
    if (this.forgotPasswordform.invalid) {
      this.normalLoggingIn = false;
      return;
    }
    var emailid = this.forgotPasswordform.value.emailreset;
    var web_api = '1';
    var form_data = new FormData();
    form_data.append('email', emailid);
    form_data.append('is_web_api', web_api);
    this.ApiService.forgotPassword(form_data).subscribe((apiResult: any) => {
      this.normalLoggingIn = false;
      this.forgotSubmit = true;
      console.log(apiResult.success);
      return;
    }, error => {
      this.normalLoggingIn = false;
      console.log('error');
    });
  }

  facebookLogin() {

    FB.login((response) => {
      if (response.authResponse) {
        var id = response.authResponse.userID;
        FB.api("/" + id + "/", { locale: 'en_US', fields: 'name, email' },
          (response) => {
            if (response && !response.error) {
              this.normalLoggingIn = true;
              var sco_api = '1';
              var form_data = new FormData();
              form_data.append('username', response.email);
              form_data.append('facebook', response.id);
              form_data.append('name', response.name);
              form_data.append('is_sso', sco_api);
              form_data.append('is_web_api', sco_api);
              this.ApiService.login(form_data).subscribe((apiResult: any) => {
                this.normalLoggingIn = false;
                if (apiResult.success) {
                  this.openLoginModel();
                  window.location.reload();
                } else {
                  if (apiResult.error && apiResult.error_code === "err-000040") {
                    this.openLoginModel();
                    if (apiResult.data.contact_no) {
                      this.mobileupdateuser = apiResult.data.id;
                      this.openOtpModel();
                    } else {
                      this.mobileupdateuser = apiResult.data.id;
                      this.openMobileUpdateModel();
                    }
                  }
                }
              });
            }
          }
        );
      } else {
        console.log('User login failed');
      }
    }, { scope: 'user_birthday' });
  }


  async loginWithGoogle() {
    let res = await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
    this.normalLoggingIn = true;
    var sco_api = '1';
    var form_data = new FormData();
    // profile.getEmail()
    form_data.append('username', res.email);
    form_data.append('google', res.id);
    form_data.append('name', (res.firstName || '') + ' ' + (res.lastName || ''));
    form_data.append('is_sso', sco_api);
    form_data.append('is_web_api', sco_api);
    this.ApiService.login(form_data).subscribe((apiResult: any) => {
      this.normalLoggingIn = false;
      if (apiResult.success) {
        this.openLoginModel();
        window.location.reload();
      } else {
        if (apiResult.error && apiResult.error_code === "err-000040") {
          this.openLoginModel();
          if (apiResult.data.contact_no) {
            this.mobileupdateuser = apiResult.data.id;
            this.openOtpModel();
          } else {
            this.mobileupdateuser = apiResult.data.id;
            this.openMobileUpdateModel();
          }
        }
      }
    });
  }

  onMobileUpdateSubmit(): void {

    this.onMobileUpdateSubmitted = true;
    this.mobileUpadating = true;
    if (this.mobileUpdateForm.invalid) {
      this.mobileUpadating = false;
      return;
    }
    var mobile = this.mobileUpdateForm.value.mobileNumber;
    var form_data = new FormData();
    form_data.append('userid', this.mobileupdateuser);
    form_data.append('contact_no', mobile);
    this.ApiService.postRequest(API_ENDPOINTS.API_UPDATE_PROFILE, form_data).subscribe((apiResult: any) => {
      this.mobileUpadating = false;
      this.onMobileUpdateSubmitted = false
      if (apiResult.success) {
        this.openMobileUpdateModel();
        this.openOtpModel();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.mobileUpadating = false;
      this.FlashService.error("Something wrong!");
    });
  }

  onResendOTP(): void {

    this.resendOtpsubmitted = true;
    this.otpUpadating = true;
    var form_data = new FormData();
    form_data.append('userid', this.mobileupdateuser);
    if (this.verifyUserEmail) {
      form_data.append('email', this.verifyUserEmail);
    }
    this.ApiService.postRequest(API_ENDPOINTS.API_RESEND_OTP, form_data).subscribe((apiResult: any) => {
      this.otpUpadating = false;
      this.resendOtpsubmitted = false;
      if (apiResult.success) {
        this.FlashService.success("OTP resent successfully.");
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.otpUpadating = false;
      this.FlashService.error("Something wrong!");
    });
  }

  onVerifyOTP(): void {
    if (this.otp && this.otp.length === 4) {
      this.verifyOtpSubmitted = true;
      this.otpUpadating = true;
      var form_data = new FormData();
      if (!this.mobileupdateuser) {
        this.mobileupdateuser = $('#otpModal #userId').val();
      }
      form_data.append('userid', this.mobileupdateuser);
      form_data.append('otp', this.otp);
      this.ApiService.postRequest(API_ENDPOINTS.API_VERIFY_MOBILE, form_data).subscribe((apiResult: any) => {
        this.otpUpadating = false;
        if (apiResult.success) {
          this.openOtpModel();
          this.openLoginModel();
          this.FlashService.success("Account verified successfully. Please login to check your account.");
          this.verifyOtpSubmitted = false;
        } else {
          this.FlashService.error(apiResult.error_code);
        }
      }, error => {
        this.otpUpadating = false;
        this.FlashService.error("Something wrong!");
      });
    } else {
      this.FlashService.error("Please enter valid OTP.");
    }
  }

  logout(): void {
    localStorage.removeItem('username');
    localStorage.removeItem('currentUser');
    window.location.reload();
  }
  changeType(type) {
    this.dashboardType = type;
  }

  showForgotPassDiv() {
    $('#login').hide();
    $('#newac').hide();
    $('.loginform').hide();
    $('.newform').hide();
    $('.social_login_section').hide();
    $('.social-pop-btn').hide();
    $('.forgotPasswordform').show();
    $('#forgotPasswordLi').show();

    this.resetForms()
  }

  showLoginDiv() {
    $('.forgotPasswordform').hide();
    $('#forgotPasswordLi').hide();
    $('#login').show();
    $('#newac').show();
    $('.loginform').show();
    // $('.newform').show();
    $('.social_login_section').show();
    $('.social-pop-btn').show();
    this.resetForms()
  }

  resetForms() {
    this.FlashService.hide("");
    this.registerForm.reset()
    this.loginform.reset()
    this.forgotPasswordform.reset()
    this.loginSubmitted = this.registerSubmitted = this.forgotSubmit = false
  }
}
