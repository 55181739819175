import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit(): void {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » India Real Estate Property Site - Buy Sell Rent Properties');

  }

}
