<div class="container-fluid" id="schedule_visit">
	<div class="container">
		<!-- Start Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li>
            <a href="/"> Home </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <a *ngIf="temp_source == 'property'" [routerLink]="['property']"> Property </a>
						<a *ngIf="temp_source == 'project' || temp_source ==  'project_unit'" [routerLink]="['projects']"> Project </a>
						<a *ngIf="temp_source == 'carnival_project'" [routerLink]="['carnival-project']"> Carnival Project </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
					<li>
            <a *ngIf="temp_source == 'property'" [routerLink]="['/property-details', related_source_id]"> Property Detail </a>
						<a *ngIf="temp_source == 'project'" [routerLink]="['/project-details', related_source_id]"> Project Detail </a>
						<a *ngIf="temp_source == 'carnival_project'" [routerLink]="['/carnival-project-details', project_unit_id]"> Project Unit Detail </a>
						<a *ngIf="temp_source == 'project_unit'" [routerLink]="['/project-unit-details', project_unit_id]"> Project Unit Detail </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> Book Your Appointment </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->
		<div class="row">
			<!-- <app-flash></app-flash> -->
			<div class="col-md-9">
				<div id="schedule_left_box_1" class="schedule_left_box">
					<h4>Pick a date</h4>
					<div class="owl-carousel owl-theme" id="visit_date_slider"></div>
					<h4>Pick a time</h4>
					<div class="timeLoadingOverlay" *ngIf="isTimeLoading" [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div>
					<div class="owl-carousel owl-theme" id="visit_time_slider">
						<div *ngFor="let itemTime of timeList" class="time_item" [attr.data-stime]="itemTime.stime" [attr.data-etime]="itemTime.etime">{{ itemTime.stime }}</div>
					</div>
				</div>
				<div id="schedule_left_box_2" class="schedule_left_box" style="display: none;">
					<h4>Tell us about yourself</h4>
					<form [formGroup]="scheduleForm" (ngSubmit)="onScheduleSubmit()" #scheduleFormSubmit="ngForm">
						<div class="form-group">
							<input [(ngModel)]="subject" [ngClass]="{ 'is-invalid': submitted && g.mobileNumber.errors }" [pattern]="mobNumberPattern" class="form-control phone" formControlName="mobileNumber" id="phone" name="phone" pattern="[+]{1}-[0-9]{2}-[0-9]{10}" placeholder="Phone Number" />
							<div *ngIf="submitted && g.mobileNumber.errors" class="invalid-feedback">
								<div *ngIf="g.mobileNumber.errors.required">Mobile Number is required</div>
								<div *ngIf="g.mobileNumber.errors.pattern || g.mobileNumber.errors.minlength || g.mobileNumber.errors.maxlength">Please enter valid Mobile Number</div>
							</div>
						</div>
						<div class="form-group">
							<textarea class="form-control" id="message" placeholder="Message" rows="10">{{ description }}</textarea>
						</div>
					</form>
				</div>
        <div class="schedule_left_box" id="schedule_left_box_3" style="display: none;">
          <h4>Which video chat app would you like to use for the tour?</h4>
          <form #scheduleVideoChatFormSubmit="ngForm" (ngSubmit)="onScheduleSubmit()" [formGroup]="scheduleVideoChatForm">
            <div (click)="showVideoChatOption(1)" class="form-group">
              <label class="btn video-chat-label whatsapp-label active-label"><i aria-hidden="true" class="fa fa-whatsapp"></i> WhatsApp </label>
              <input [(ngModel)]="whatsAppNo" [ngClass]="{ 'is-invalid': submitted && k.whatsAppNumber.errors }" [pattern]="mobNumberPattern" class="form-control phone" formControlName="whatsAppNumber" id="whatsapp_phone" name="whatsapp_phone" pattern="[+]{1}-[0-9]{2}-[0-9]{10}" placeholder="WhatsApp Number" />
              <div *ngIf="submitted && k.whatsAppNumber.errors" class="invalid-feedback">
                <div *ngIf="k.whatsAppNumber.errors.required">Mobile Number is required</div>
                <div *ngIf="k.whatsAppNumber.errors.pattern || k.whatsAppNumber.errors.minlength || k.whatsAppNumber.errors.maxlength">Please enter valid Mobile Number</div>
              </div>
            </div>
            <div (click)="showVideoChatOption(2)" class="form-group mt-4">
              <label class="btn video-chat-label skype-label"><i aria-hidden="true" class="fa fa-skype"></i> Skype ID </label>
              <input [(ngModel)]="googleDuo" [ngClass]="{ 'is-invalid': submitted && k.skypeId.errors }" class="form-control video-chat-ids" formControlName="skypeId" id="skype_id" name="skype_id" placeholder="live:4e3def81b7c78bb" />
              <div *ngIf="submitted && k.skypeId.errors" class="invalid-feedback">
                <div *ngIf="k.skypeId.errors.required">Skype Id is required</div>
              </div>
            </div>
            <div (click)="showVideoChatOption(3)" class="form-group mt-4">
              <label class="btn video-chat-label google-label"><i aria-hidden="true" class="fa fa-google"></i> Google Duo </label>
              <input [(ngModel)]="skypeId" [ngClass]="{ 'is-invalid': submitted && k.googleDuo.errors }" [pattern]="mobNumberPattern" class="form-control phone" formControlName="googleDuo" id="google_duo" name="google_duo" pattern="[+]{1}-[0-9]{2}-[0-9]{10}" placeholder="Google Duo Number" />
              <div *ngIf="submitted && k.googleDuo.errors" class="invalid-feedback">
                <div *ngIf="k.googleDuo.errors.required">Mobile Number is required</div>
                <div *ngIf="k.googleDuo.errors.pattern || k.googleDuo.errors.minlength || k.googleDuo.errors.maxlength">Please enter valid Mobile Number</div>
              </div>
            </div>
          </form>
        </div>
			</div>
			<div class="col-md-3">
				<div class="schedule_right_box">
					<div class="row d-flex">
						<div class="col-md-12">
							<span class="title">Date</span>
							<span class="r_data">{{ appo_date_d }}</span>
						</div>
					</div>
					<div class="row d-flex">
						<div class="col-md-6">
							<span class="title">Start time</span>
							<span class="r_data">{{ appo_time }}</span>
						</div>
						<div class="col-md-6">
							<span class="title">End time</span>
							<span class="r_data">{{ appo_e_time }}</span>
						</div>
					</div>
					<div class="row d-flex">
						<div class="col-md-12">
							<button class="btn" id="back" *ngIf="appointmentStep == 2" (click)="appointmentBackStep()">Back</button>
							<button class="btn" id="next" (click)="appointmentNextStep()">Next</button>
              <button class="btn btn-block login-btn loaderBtn" disabled style="display: none;" type="button">
                <span class="spinSpan spinner-border" role="status"></span> Saving
              </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
