<div class="container-fluid" id="singleProperty">
  <div class="container">
    <!-- Start Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li>
            <a href="/"> Home </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="propertyInArea(propertyinfo.locality_title)">Project for sale in {{propertyinfo.locality_title}}
            </a>
            
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> {{propertyinfo.display_name}} </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <!-- Start Property header -->
    <div class="row property_head">
      <div class="col-md-6">
        <h4>{{propertyinfo.display_name}}</h4>
        <h6>{{propertyinfo.locality_title}} {{(propertyinfo.city ? propertyinfo.city.city : '')}}</h6>
      </div>
      <div class="col-md-4">
        <ul class="prop_other_info">
          <li *ngIf="propertyinfo.unit_data && propertyinfo.show_unit_price_on_website == 1; else contactInfo">
              <h4><i class="fa fa-rupee"></i> {{propertyinfo.unit_data.new_basic_price_title}}</h4>
              <button (click)="scrollTo('tax_info')" class="btn btn-info btn-sm close-price-btn">Closing Price</button>
          </li>
          <ng-template #contactInfo>
            <li>
              <ng-template #popTemplate>
                Name: {{ (propertyinfo.assign_to.name ? propertyinfo.assign_to.name : propertyinfo.assign_to.first_name + ' ' + propertyinfo.assign_to.last_name) }} <br>
                Contact No: {{propertyinfo.assign_to.contact_no}} <br>
                Email: {{propertyinfo.assign_to.email}}
              </ng-template>
              <button [tooltip]="popTemplate" class="btn btn-common btn-lg contact-tooltip-btn"  type="button">
                <i aria-hidden="true" class="fa fa-phone"></i> Call For Price <br> {{propertyinfo.assign_to.contact_no}}
              </button>
            </li>
          </ng-template>
          <li>
            <h4 *ngIf="propertyinfo && propertyinfo.unit_data && propertyinfo.unit_data.total_bedrooms">{{(propertyinfo.unit_data.total_bedrooms ? propertyinfo.unit_data.total_bedrooms : 0)}}</h4>
            <p *ngIf="propertyinfo && propertyinfo.unit_data && propertyinfo.unit_data.total_bedrooms">Bed</p>
          </li>
          <li>
            <h4 *ngIf="propertyinfo && propertyinfo.unit_data && propertyinfo.unit_data.total_showers">{{(propertyinfo.unit_data.total_showers ? propertyinfo.unit_data.total_showers : 0)}}</h4>
            <p *ngIf="propertyinfo && propertyinfo.unit_data && propertyinfo.unit_data.total_showers">Baths</p>
          </li>
          <li class="like_share_btn">
            <button class="btn btn-common btn-lg heart_btn" [ngClass]="{ 'is_favorite_prop': propertyinfo.is_saved == 1 }" (click)="makeFavorite(propertyinfo.id, propertyinfo.unit_data.id)">
              <i *ngIf="!isFavoriting" class="fa fa-heart"></i>
              <div class="favoriteLoadingOverlay" *ngIf="isFavoriting" [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div>
            </button>
            <button type="button" id="shrehide" class="btn btn-common btn-lg share_btn" data-toggle="modal" data-target="#sharePropModal">
              <i class="fa fa-share-alt"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Property header -->

    <!-- Start Property images -->
    <div class="row">
      <div class="col-md-9">
        <div class="project_main_img" (click)="open(0)"></div>
      </div>
      <div class="col-md-3">
        <div class="project_other_img">
          <div class="project_other_img_1" (click)="open(0)"></div>
          <div class="project_other_img_2" (click)="open(0)"><span class="more_images_3 hidden"></span></div>
        </div>
      </div>
    </div>
    <!-- End Property images -->

    <div class="row">
      <div class="col-md-9">
        <!-- Start Property info -->
        <div class="row property_more_info">
          <div class="col-md-12">
            <ul class="nav nav-tabs prop_info_tabs" role="tablist">
              <li class="nav-item">
                <span class="nav-link active" id="property_description_tab" (click)="scrollTo('property_description')" role="tab" aria-controls="property_description" aria-selected="true">Description of property</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_dimentions_tab" (click)="scrollTo('property_dimentions')" role="tab" aria-controls="property_dimentions" aria-selected="false">Property Dimentions</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_detail_tab" (click)="scrollTo('property_detail')" role="tab" aria-controls="property_detail" aria-selected="false">Property Detail</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_amenities_tab" (click)="scrollTo('property_amenities')" role="tab" aria-controls="property_amenities" aria-selected="false">Amenities</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="property_nearby_tab" (click)="scrollTo('property_nearby')" role="tab" aria-controls="property_nearby" aria-selected="false">Near By Landmarks</span>
              </li>
            </ul>

            <div class="tab-content prop_info_tab_content">
              <div class="tab-pane fade show active" id="property_description" role="tabpanel" aria-labelledby="property_description_tab">
                <h5>About property</h5>
                <div class="property_description_contant">{{(propertyinfo.description ? propertyinfo.description : 'Description not found.')}}</div>
              </div>

              <div class="tab-pane fade show active" id="property_dimentions" role="tabpanel" aria-labelledby="property_dimentions_tab">
                <h5>Property Dimentions</h5>
                <div class="p_details_accordians">
                  <div class="accordion_outer no-border">
                    <div class="accordion_in_content collapse show" id="interior_features">
                      <div class="row d-flex" *ngIf="propertyinfo.proj_unit_room">
                        <div class="col-md-6" *ngIf="propertyinfo.proj_unit_room.living_room">
                          <h4>Living Room Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.proj_unit_room.living_room.width">Dimension : {{(propertyinfo.proj_unit_room.living_room.width ? propertyinfo.proj_unit_room.living_room.width : '-')}} x {{(propertyinfo.proj_unit_room.living_room.length ? propertyinfo.proj_unit_room.living_room.length : '-')}}</li>
<!--                            <li>Flooring : -</li>-->
                            <li *ngIf="propertyinfo.living_room_furniture">Furniture : <span *ngFor="let itemF of propertyinfo.living_room_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span></li>
                            <li *ngIf="!propertyinfo.living_room_furniture && !propertyinfo.proj_unit_room.living_room.width">No Data</li>
                            <li *ngIf="propertyinfo.proj_unit_room.living_room.on_floor">On Floor : {{propertyinfo.proj_unit_room.living_room.on_floor}}</li>
                          </ul>
                        </div>

                        <div class="col-md-6" *ngIf="propertyinfo.proj_unit_room.kitchen">
                          <h4>Kitchen Information</h4>
                          <ul>
                            <li *ngIf="propertyinfo.proj_unit_room.kitchen.width">
                              Dimension : {{(propertyinfo.proj_unit_room.kitchen.width ? propertyinfo.proj_unit_room.kitchen.width : '-')}} x {{(propertyinfo.proj_unit_room.kitchen.length ? propertyinfo.proj_unit_room.kitchen.length : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.kitchen_flooring">
                              Flooring : {{(propertyinfo.kitchen_flooring ? propertyinfo.kitchen_flooring.title : '-')}}
                            </li>
                            <li *ngIf="propertyinfo.kitchen_furniture">
                              Furniture : <span *ngFor="let itemF of propertyinfo.kitchen_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                            <li *ngIf="!propertyinfo.kitchen_furniture && !propertyinfo.kitchen_flooring && !propertyinfo.proj_unit_room.kitchen.width">
                              No Data
                            </li>
                            <li *ngIf="propertyinfo.proj_unit_room.kitchen.on_floor">On Floor : {{propertyinfo.proj_unit_room.kitchen.on_floor}}</li>
                          </ul>
                        </div>

                        <div class="col-md-6" *ngFor="let itemB of propertyinfo.proj_unit_room.bedroom; index as bi">
                          <h4>Bedroom {{bi+1}}</h4>
                          <ul>
                            <li *ngIf="itemB.width">
                              Dimension : {{(itemB.width ? itemB.width : '-')}} x {{(itemB.length ? itemB.length : '-')}}
                            </li>
                            <li *ngIf="itemB.bedroom_flooring">
                              Flooring : {{(itemB.bedroom_flooring ? itemB.bedroom_flooring.title : '-')}}
                            </li>
                            <li *ngIf="itemB.bedroom_furniture">
                              Furniture : <span *ngFor="let itemF of itemB.bedroom_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span>
                            </li>
                            <li>Attached Bath : {{(itemB.attached_bathroom == 'Yes' ? 'Yes' : 'No')}}</li>
                            <li *ngIf="itemB.attached_bathroom == 'Yes'">
                              Bath Dimension : {{(itemB.bathroom_width ? itemB.bathroom_width : '-')}} x {{(itemB.bathroom_length ? itemB.bathroom_length : '-')}}
                            </li>
                            <li *ngIf="itemB.on_floor">On Floor : {{itemB.on_floor}}</li>
                          </ul>
                        </div>

                        <div class="col-md-6" *ngFor="let itemC of propertyinfo.unit_data.proj_unit_dimension; index as bi">
                          <h4>{{itemC.dimension.title}}</h4>
                          <ul>
                            <li>Dimension : {{(itemC.width ? itemC.width : '-')}} x {{(itemC.length ? itemC.length : '-')}}</li>
                            <li *ngIf="itemC.dimension_flooring">Flooring : {{(itemC.dimension_flooring ? itemC.dimension_flooring.title : '-')}}</li>
                            <li *ngIf="itemC.on_floor">On Floor : {{itemC.on_floor}}</li>
<!--                            <li *ngIf="itemC.dimension_furniture">Furniture : <span *ngFor="let itemF of itemC.dimension_furniture; index as i"><span *ngIf="i != 0">, </span>{{itemF.furniture.title}}</span></li>-->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_detail" role="tabpanel" aria-labelledby="property_detail_tab">
                <h5>Property details</h5>
                <div class="p_details_accordians">
                  <div class="accordion_outer">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#property_lot" aria-expanded="true" aria-controls="property_lot">Property / Lot Details <i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="property_lot">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>Property Information</h4>
                          <ul *ngIf="propertyinfo.unit_data">
                            <li *ngIf="propertyinfo.unit_data.total_bedrooms">No. of Beds: {{propertyinfo.unit_data.total_bedrooms ? propertyinfo.unit_data.total_bedrooms : '-'}}</li>
                            <li>For sale</li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <h4>Land Information</h4>
                          <ul *ngIf="propertyinfo.unit_data">
                            <li *ngIf="propertyinfo.unit_data.super_builtup">
                              Super Built Up: {{propertyinfo.unit_data.super_builtup ? propertyinfo.unit_data.super_builtup : ''}} - {{propertyinfo.unit_data.unit_super_builtup_area_unit.title ? propertyinfo.unit_data.unit_super_builtup_area_unit.title : ''}}
                            </li>
                            <li *ngIf="propertyinfo.unit_data.carpet">
                              Carpet: {{propertyinfo.unit_data.carpet ? propertyinfo.unit_data.carpet + ' ' + propertyinfo.unit_data.unit_carpet_area_unit.title : '-'}}
                            </li>
                            <li *ngIf="!propertyinfo.unit_data.super_builtup && !propertyinfo.unit_data.carpet">
                              No Data
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion_outer" *ngIf="propertyinfo.proj_school && propertyinfo.proj_school.length > 0">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#virtual_tour" aria-expanded="true" aria-controls="virtual_tour">School Information<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="virtual_tour">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>School Information</h4>
                          <ul *ngFor="let school of propertyinfo.proj_school;">
                            <li>{{school.placeName}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion_outer" *ngIf="propertyinfo.proj_feature && propertyinfo.proj_feature.length > 0">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#unique_features" aria-expanded="true" aria-controls="interior_features">Unique Features <i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="unique_features">
                      <div class="row d-flex" *ngIf="propertyinfo.proj_feature">
                        <div class="col-md-6">
                          <h4>Utility Information</h4>
                          <ul *ngFor="let feature of propertyinfo.proj_feature;">
                            <li>{{feature.feature.title}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion_outer" id="tax_info" *ngIf="propertyinfo.show_unit_price_on_website == 1">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#tax_asses" aria-expanded="true" aria-controls="tax_asses">Taxes / Financial Information<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="tax_asses">
                      <div class="row d-flex">
                        <div class="col-md-6">
                          <h4>Financial Information</h4>
                          <ng-container *ngIf="propertyinfo.extra_cost_data; else noTaxInfo">
                            <table class="tax_table" style="width: 100%;">
                              <tbody>
                              <tr>
                                <td class="tax_info" style="width: 70%;">Basic Price</td>
                                <td class="tax_info text-right" style="width: 30%;">
                                  {{propertyinfo.unit_data.new_basic_price_title}}
                                </td>
                              </tr>
                              <tr *ngFor="let tax of propertyinfo.extra_cost_data;">
                                <td class="tax_info" style="width: 70%;">
                                  {{(tax.tax_percentage ? tax.display_text + ' ' + tax.tax_percentage + '%' : tax.display_text)}}
                                </td>
                                <td class="tax_info text-right" style="width: 30%;">{{tax.cost_value_title}}</td>
                              </tr>
                              <tr>
                                <td class="tax_total" style="width: 70%;">Closing Price</td>
                                <td class="tax_total text-right" style="width: 30%;">
                                  {{propertyinfo.unit_data.closing_price_title}}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </ng-container>
                          <ng-template #noTaxInfo>
                            <p>No data</p>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion_outer" *ngIf="propertyinfo.proj_specification && propertyinfo.proj_specification.length > 0">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#specification" aria-expanded="true" aria-controls="specification">Specification <i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="specification">
                      <div class="row d-flex">
                        <div class="col-md-12">
                          <table class="tbl_spec">
                            <tbody>
                            <tr *ngFor="let specf of propertyinfo.proj_specification; index as i">
                              <td class="tbl_spec_info" valign="top" style="width: 20%;font-size: 13px;">
                                {{specf.specification.title}}
                              </td>
                              <td class="tbl_spec_info" style="width: 80%;font-size: 13px;">{{specf.description}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_amenities" role="tabpanel" aria-labelledby="property_amenities_tab" *ngIf="propertyinfo.proj_amenity && propertyinfo.proj_amenity.length > 0">
                <h5>Amenities</h5>
                <ul class="p_amenities">
                  <li class="s_amenities" *ngFor="let itemAmenities of propertyinfo.proj_amenity">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {{itemAmenities.amenity.title}}
                  </li>
                </ul>
              </div>

              <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="property_amenities_tab" *ngIf="propertyinfo.proj_bank_approve && propertyinfo.proj_bank_approve.length > 0">
                <h5>Bank Approval</h5>
                <div class="logo-container">
                  <ul>
                    <li *ngFor="let bank_app of propertyinfo.proj_bank_approve; index as i">
                      <div class="logo-holder logo-1">
                        <img src="{{bank_app.bank.bank_logo_url}}" alt="{{bank_app.bank.title}}" title="{{bank_app.bank.title}}" width="100%" height="100%" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-pane fade show active" id="ask_que_agent" role="tabpanel" aria-labelledby="ask_que_agent_tab" *ngIf="propertyinfo.assign_to">
                <h5>Ask Realtors Agent {{(propertyinfo.assign_to.name ? propertyinfo.assign_to.name : propertyinfo.assign_to.first_name + ' ' + propertyinfo.assign_to.last_name)}} a Question</h5>
                <div class="row d-flex agent_que_box">
                  <div class="col-md-4">
                    <img src="{{propertyinfo.assign_to.image_preview}}" class="rounded-circle" [alt]="propertyinfo.assign_to.image_preview ?  'Cinque Terre' : 'No image'" width="95" height="95" />
                    <div class="ag_info">
                      <h6>{{(propertyinfo.assign_to.name ? propertyinfo.assign_to.name : propertyinfo.assign_to.first_name + ' ' + propertyinfo.assign_to.last_name)}}</h6>
                      <!--<p>{{(propertyinfo.assign_to.city.city ? propertyinfo.assign_to.city.city : '')}} Realtors Agent</p>-->
                      <p>{{propertyinfo.assign_to.email}}</p>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <textarea class="form-control" id="agent_text_que" rows="4" placeholder="I'd like to know more about Vadodara Real Estate."></textarea>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-common btn-lg agent_btn">Ask a Question</button>
                    <p *ngIf="propertyinfo.assign_to.contact_no">Text/Call <b>{{propertyinfo.assign_to.contact_no}}</b></p>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_nearby" role="tabpanel" aria-labelledby="property_nearby_tab">
                <h5>Near by your Landmarks</h5>
                <ul class="nav nav-tabs prop_nearby_tabs" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="schools-tab" (click)="changeNearBy('school', gm)" data-toggle="pill" href="#schools" role="tab" aria-controls="schools" aria-selected="true">Schools</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="restaurants-tab" (click)="changeNearBy('restaurant', gm)" data-toggle="pill" href="#restaurants" role="tab" aria-controls="restaurants" aria-selected="false">Restaurants</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="hospitals-tab" (click)="changeNearBy('hospital', gm)" data-toggle="pill" href="#hospitals" role="tab" aria-controls="hospitals" aria-selected="false">Hospitals</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="atms-tab" (click)="changeNearBy('atm', gm)" data-toggle="pill" href="#atms" role="tab" aria-controls="atms" aria-selected="false">Atm's</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="shopping_mall-tab" (click)="changeNearBy('shopping_mall', gm)" data-toggle="pill" href="#shopping_mall" role="tab" aria-controls="shopping_mall" aria-selected="false">Shopping Mall</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="schools-tab">
                    <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false" [mapTypeControl]="true" [usePanning]="true" [scrollwheel]="false" (mapReady)="mapReady($event)">
                      <agm-marker *ngFor="let m of markers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="icon"
                                  (markerClick)="clickedMarker(m.pid)"
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <div class="imgDiv" [style.background-image]="'url(\'' + m.image_preview + '\')'">&nbsp;</div>
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p><i class="fa fa-rupee"></i> {{ m.asking_price }}</p>
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                      <agm-marker *ngFor="let m of schoolMarkers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="iconSchool"
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p>{{ m.asking_price }}</p>
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="property_emi_calculator" role="tabpanel"
                   *ngIf="propertyinfo && propertyinfo.unit_data && propertyinfo.unit_data.property_type_id === 1 || propertyinfo.unit_data.property_type_id === 3" aria-labelledby="property_emi_calculator_tab">
                <h5>Monthly cost/EMI</h5>
                <div class="row d-flex">
                  <div class="col-md-12">
                    <p id="cost_month"></p>
                  </div>
                </div>
                <div class="row d-flex">
                  <div class="col-md-2 tabs_titles_floors">
                    <ul class="nav nav-tabs tabs_titles_floors_tabs" role="tablist">
                      <li class="nav-item">
                        <span (click)="onEMITabClick(1)" class="nav-link active" id="principal_int_tab"
                              href="#principal_int" data-toggle="tab" role="tab" aria-controls="principal_int"
                              aria-selected="true">Principal & interest</span>
                      </li>
                      <li class="nav-item">
                        <span (click)="onEMITabClick(3)" class="nav-link" id="property_taxes_tab" href="#property_taxes"
                              data-toggle="tab" role="tab" aria-controls="property_taxes" aria-selected="false">Property taxes</span>
                      </li>
                      <li class="nav-item">
                        <span (click)="onEMITabClick(4)" class="nav-link" id="home_ins_tab" href="#home_ins"
                              data-toggle="tab" role="tab" aria-controls="home_ins"
                              aria-selected="false">Home insurance</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-10 floor_pans" [formGroup]="emiForm">
                    <div class="tab-content tabs_titles_floors_tab_content">
                      <div class="tab-pane fade show active" id="principal_int" data-toggle="tab" role="tabpanel"
                           aria-labelledby="principal_int_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="input-label" for="home_price">Home Price</label>
                              <div class="custom_input_prepend">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="home_price" [(ngModel)]="home_price"
                                       formControlName="home_price" [ngClass]="{ 'is-invalid': i.home_price.errors }"
                                       class="form-control" placeholder="Home Price" (keyup)="onEMICalPriceChange()"/>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="inter-flex">
                                <label class="input-label down_payment" for="down_payment">Down payment</label>
                                <label class="input-label down_payment_per" for="down_payment_per">Down payment
                                  %</label>
                              </div>
                              <div class="inter-flex">
                                <div class="custom_input_prepend">
                                  <i class="fa fa-rupee"></i>
                                  <input type="number" id="down_payment" [(ngModel)]="down_payment"
                                         formControlName="down_payment"
                                         [ngClass]="{ 'is-invalid': i.down_payment.errors }" class="form-control"
                                         placeholder="Down payment" (keyup)="onEMICalDownPaymentChange()"/>
                                </div>
                                <div class="custom_input_append">
                                  <input type="number" id="down_payment_per" [(ngModel)]="down_payment_per"
                                         formControlName="down_payment_per"
                                         [ngClass]="{ 'is-invalid': i.down_payment_per.errors }" class="form-control"
                                         placeholder="Down payment per" (keyup)="onEMICalDownPaymentPerChange()"/>
                                  <span class="append_text">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="form-group">
                              <label class="input-label">Loan program</label>
                              <select [(ngModel)]="loan_program" class="form-control" (change)="onEMICalTermChange()"
                                      [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let loan_program_option of loan_program_options"
                                        value="{{ loan_program_option.id }}">
                                  {{ loan_program_option.title }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="input-label" for="int_rate">Interest rate</label>
                              <div class="custom_input_append">
                                <input type="number" id="int_rate" [(ngModel)]="int_rate" formControlName="int_rate"
                                       [ngClass]="{ 'is-invalid': i.int_rate.errors }" class="form-control"
                                       placeholder="Interest rate" (keyup)="onEMICalIntRateChange()"/>
                                <span class="append_text">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="mortgage_inc" role="tabpanel" data-toggle="tab"
                           aria-labelledby="mortgage_inc_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="inc_morg_ins"
                                       ng-model="inc_morg_ins" (change)="changeMortgage()"/>
                                <label class="custom-control-label" for="inc_morg_ins">Include mortgage
                                  insurance</label>
                                <p>Mortgage insurance is usually required for down payments below 20%.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="property_taxes" role="tabpanel" data-toggle="tab"
                           aria-labelledby="property_taxes_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <p>This estimate is based on the home value and an estimated local tax rate. Actual rate
                                may vary.</p>
                              <div class="inter-flex-pt">
                                <label class="input-label pt_first">Home price</label>
                                <label class="input-label pt_second">Tax rate</label>
                                <label class="input-label pt_third">&nbsp;</label>
                              </div>
                              <div class="inter-flex-pt">
                                <div class="custom_text pt_first">
                                  <i class="fa fa-rupee"></i>
                                  {{ new_home_price }} X
                                </div>
                                <div class="custom_input_append pt_second">
                                  <input type="number" id="property_tex_per" [(ngModel)]="property_tex_per"
                                         formControlName="property_tex_per"
                                         [ngClass]="{ 'is-invalid': i.property_tex_per.errors }" class="form-control"
                                         placeholder="Property Tex per" (keyup)="onPropertyTaxPerChange()"/>
                                  <span class="append_text">%</span>
                                </div>
                                <div class="custom_text pt_third">
                                  = <i class="fa fa-rupee"></i> {{ property_tex_yearly }} /year
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="home_ins" role="tabpanel" data-toggle="tab"
                           aria-labelledby="home_ins_tab">
                        <div class="row d-flex">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="input-label" for="home_ins_year">Insurance Per Year</label>
                              <div class="custom_input_prepend custom_input_append">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="home_ins_year" [(ngModel)]="home_ins_year"
                                       formControlName="home_ins_year"
                                       [ngClass]="{ 'is-invalid': i.home_ins_year.errors }" class="form-control"
                                       placeholder="Insurance Per Year" (keyup)="onYearInsChange()"/>
                                <span class="append_text">/year</span>
                              </div>
                              <p>Most lenders require homeowners insurance, which protects your home and property.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        <!-- End Property info -->
      </div>
      <!-- ============ Start Appointment Schedule ============ -->
      <div class="col-md-3" [ngClass]="{'sticky-div':enableSticky}">
        <div class="side_block" id="visit">
          <h5>Book Your Appointment</h5>
          <div class="owl-carousel owl-theme" id="visit_date_slider"></div>
          <p>Feel free to Schedule Visit, its free ,with no obligation and cancel any time.</p>
          <div class="row schedule-select mt-2">
            <div (click)="selectAppointmentType(1)" class="col-md-6 text-center">
              <div class="row schedule-option {{ (this.tourInPerson ? 'option-active' : '') }}">
                <div class="col-md-12">
                  <i aria-hidden="true" class="fa fa-home icon-cls"></i>
                </div>
                <div class="col-md-12 schedule-text">
                  Tour in person
                </div>
              </div>
            </div>
            <div (click)="selectAppointmentType(2)" class="col-md-6 text-center">
              <div class="row schedule-option {{ (this.tourVideoChat ? 'option-active' : '') }}">
                <div class="col-md-12">
                  <i aria-hidden="true" class="fa fa-mobile icon-cls"></i>
                </div>
                <div class="col-md-12 schedule-text">
                  Tour via video chat
                </div>
              </div>
            </div>
          </div>
          <a (click)="searchschedulevisit(propertyinfo.franchisee_id, propertyinfo.assign_to_user_id)" class="schdule btn">Book Now</a>
        </div>
      </div>
      <!-- ============ Ends Appointment Schedule ============ -->
    </div>

  </div>
</div>

<!-- Shareable Link Modal -->
<div class="modal fade share_model" id="sharePropModal" tabindex="-1" role="dialog" aria-labelledby="sharePropModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <app-flash></app-flash> -->

        <div class="loginform">
          <form [formGroup]="shareform" (ngSubmit)="onShareSubmit()">
            <div class="form-group">
              <input type="text" formControlName="emailShare" class="form-control" placeholder="Email Address"
                     [ngClass]="{ 'is-invalid': submitted && g.emailShare.errors }" />
              <div *ngIf="submitted && g.emailShare.errors" class="invalid-feedback">
                <div *ngIf="g.emailShare.errors.required">Email is required</div>
                <div *ngIf="g.emailShare.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <!--<div class="form-group">
              <input type="text" formControlName="subjectShare" class="form-control" placeholder="Subject"
                     [ngClass]="{ 'is-invalid': submitted && g.subjectShare.errors }" />
              <div *ngIf="submitted && g.subjectShare.errors" class="invalid-feedback">
                <div *ngIf="g.subjectShare.errors.required">Subject is required</div>
              </div>
            </div>-->
            <div class="form-group">
              <button class="bttn btn-block login-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
