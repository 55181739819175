import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS } from '../../global';

declare var $: any;

@Component({
  selector: 'app-sez-investment',
  templateUrl: './sez-investment.component.html',
  styleUrls: ['./sez-investment.component.scss']
})
export class SezInvestmentComponent implements OnInit {

  home_icon: string = '';

  constructor(private ApiService: ApiService,  private router: Router, private route: ActivatedRoute) {
    this.home_icon = API_ENDPOINTS.HOME_ICON;
   }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if(!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
  }
}
