<!-- <div class="container-fluid" style="background: #f5f5f5;"> -->
  <!-- <section id="job_top">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div id="div_job_img">
            <img class="job_img" src="../../assets/img/close_tmp.png"/>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <app-contact-us></app-contact-us>
<!-- </div> -->

