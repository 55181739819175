<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid saved-search-outer" id="accoutnSettings">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">Saved Searches</h4>
        <div class="saved-search">
          <ng-container *ngFor="let searchItem of savedSearchList">
            <div class="search-item">
              <h4 (click)="onSaveSearchClicked(searchItem.json_string, searchItem.source)">{{ searchItem.name }}</h4>
              <div class="actions">
                <!--<span class="btn save-delete" (click)="onEditSearchDelete(searchItem.id)">Edit</span>-->
                <span class="btn save-delete" (click)="onSaveSearchDelete(searchItem.id)">Delete</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isLoading == false">
            <div class="empty-message" *ngIf="savedSearchList.length == 0">
              <img src="{{ search_icon }}" class="home-icon" alt="Home" />
              <div class="empty-text">
                <h5>You have not saved any searches.</h5>
                <p>Get notifications when new homes that match your criteria hit the market.</p>
              </div>
              <button class="btn ac_btn" [routerLink]="['/']">Search</button>
            </div>
          </ng-container>
          <ng-container *ngIf="isLoading == true">
            <div class="loading_div">
              <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
