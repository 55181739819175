/* tslint:disable:no-string-literal prefer-const no-var-keyword object-literal-shorthand max-line-length no-shadowed-variable variable-name no-inferrable-types */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_BASE_URL } from '../../global';
import { Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-suitable-property',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './suitable-property.component.html',
  styleUrls: ['./suitable-property.component.scss']
})
export class SuitablePropertyComponent implements OnInit {

  transaction_type_id: string = '0';
  property_type_id: string = '1';
  sellingSearchInput;
  area_title: string = '';
  area_lat: string = '';
  area_lng: string = '';
  suitable_for_id: string = '';
  localityEloc = '';
  amenitieIds = '';
  minBudgetAmount = 0;
  maxBudgetAmount = 0;
  bath = '';
  bedrooms = '';
  projectStatus = '';
  minSaleableArea = 0;
  maxSaleableArea = 0;
  searchReSaleProperty = true;

  constructor(private router: Router, private route: ActivatedRoute, private title: Title) { }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    window.scroll(0, 0);
    if (this.route.snapshot.queryParams.suitable_for_id !== undefined) {
      this.suitable_for_id = this.route.snapshot.queryParams.suitable_for_id;
    }
    if (this.route.snapshot.queryParams.suitable_for_ids !== undefined) {
      this.suitable_for_id = this.route.snapshot.queryParams.suitable_for_ids;
    }
    if (this.route.snapshot.queryParams.prop_type_id !== undefined) {
      this.property_type_id = this.route.snapshot.queryParams.prop_type_id;
    }
    if (this.route.snapshot.queryParams.property_type_id !== undefined) {
      this.property_type_id = this.route.snapshot.queryParams.property_type_id;
    }
    if ( this.route.snapshot.queryParams.locality_elocs !== '') {
      this.localityEloc = this.route.snapshot.queryParams.locality_elocs;
    }
    if (this.route.snapshot.queryParams.transaction_type_id !== undefined && this.route.snapshot.queryParams.transaction_type_id !== '') {
      this.transaction_type_id = this.route.snapshot.queryParams.transaction_type_id;
    }
    if (this.route.snapshot.queryParams.min_budget_amount !== undefined && this.route.snapshot.queryParams.min_budget_amount !== '') {
      this.minBudgetAmount = Number(this.route.snapshot.queryParams.min_budget_amount);
    }
    if (this.route.snapshot.queryParams.max_budget_amount !== undefined && this.route.snapshot.queryParams.max_budget_amount !== '') {
      this.maxBudgetAmount = Number(this.route.snapshot.queryParams.max_budget_amount);
    }
    if (this.route.snapshot.queryParams.bath !== undefined && this.route.snapshot.queryParams.bath !== '') {
      this.bath = this.route.snapshot.queryParams.bath;
    }
    if (this.route.snapshot.queryParams.bedrooms !== undefined && this.route.snapshot.queryParams.bedrooms !== '') {
      this.bedrooms = this.route.snapshot.queryParams.bedrooms;
    }
    if (this.route.snapshot.queryParams.amenitie_ids !== undefined && this.route.snapshot.queryParams.amenitie_ids !== '') {
      this.amenitieIds = this.route.snapshot.queryParams.amenitie_ids;
    }
    if (this.route.snapshot.queryParams.min_saleable_area !== undefined && this.route.snapshot.queryParams.min_saleable_area !== '') {
      this.minSaleableArea = Number(this.route.snapshot.queryParams.min_saleable_area);
    }
    if (this.route.snapshot.queryParams.max_saleable_area !== undefined && this.route.snapshot.queryParams.max_saleable_area !== '') {
      this.maxSaleableArea = Number(this.route.snapshot.queryParams.max_saleable_area);
    }
    if (this.route.snapshot.queryParams.project_status !== undefined && this.route.snapshot.queryParams.project_status !== '') {
      this.searchReSaleProperty = false;
      this.projectStatus = this.route.snapshot.queryParams.project_status;
    }
    var componentObj = this;
    setTimeout (() => {
      this.sellingSearchInput = $('#sellingSearchInput');
      this.sellingSearchInput.typeahead(null, {
        displayKey: 'placeNameFull',
        source: (search, sync, async) => {
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL + '/master/getLocality',
              data: {is_web_api: 1, search: search},
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      this.sellingSearchInput.on('typeahead:selected', (e, datalocitem) => {
        componentObj.area_title = datalocitem.placeName;
        componentObj.area_lat = datalocitem.latitude;
        componentObj.area_lng = datalocitem.longitude;
      });

      this.changeHeaderClass();
    }, 200);
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $('#imgHeadLogo').attr('src', 'assets/img/empire_logo.png');
    this.title.setTitle('Empire Realtors » Rent/Lease');
  }

  onBackToHome() {
    this.router.navigate(['/']);
  }

  searchProperty(page) {
    let data = {};
    this.searchReSaleProperty = true;
    data['property_type_id'] = this.property_type_id;
    if (this.suitable_for_id !== '') {
      data['suitable_for_id'] = this.suitable_for_id;
    }
    if (this.minBudgetAmount > 0) {
      data['min_budget_amount'] = this.minBudgetAmount;
    }
    if (this.maxBudgetAmount > 0) {
      data['max_budget_amount'] = this.maxBudgetAmount;
    }
    if (this.bath !== '') {
      data['bath'] = this.bath;
    }
    if (this.bedrooms !== '') {
      data['bedrooms'] = this.bedrooms;
    }
    if (this.amenitieIds !== '') {
      data['amenitie_ids'] = this.amenitieIds;
    }
    if (this.projectStatus !== '') {
      this.searchReSaleProperty = false;
      data['projstatus'] = this.projectStatus;
    }
    if (this.minSaleableArea > 0) {
      data['min_saleable_area'] = this.minSaleableArea;
      data['min_saleable_area_unit'] = 1;
    }
    if (this.maxSaleableArea > 0) {
      data['max_saleable_area'] = this.maxSaleableArea;
      data['max_saleable_area_unit'] = 1;
    }
    if (this.localityEloc) {
      data['locality_elocs'] = this.localityEloc;
    } else {
      const userCurrCity = localStorage.getItem('user_curr_city');
      data['locality_elocs'] = userCurrCity;
    }
    if (this.transaction_type_id === '0') {
      this.router.navigate(['/projects'], {queryParams: data});
    } else {
      data['transaction_type_id'] = 4;
      this.router.navigate(['/property'], {queryParams: data});
    }
  }

  selPropTypeDiv(divType) {
    $('#new_prop_div').removeClass('bg_white');
    $('#residential_div').removeClass('bg_white');
    $('#commercial_div').removeClass('bg_white');
    if (divType === 'residential') {
      $('#residential_div').addClass('bg_white');
    } else if (divType === 'new') {
      $('#new_prop_div').addClass('bg_white');
    } else {
      $('#commercial_div').addClass('bg_white');
    }
  }

}
