import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from './../../_services/api.service';
import { API_BASE_URL, API_ENDPOINTS } from '../../global';
import { FlashService } from 'src/app/_services/FlashService.service';

declare var $: any;
@Component({
  selector: 'app-single-property-card',
  templateUrl: './single-property-card.component.html',
  styleUrls: ['./single-property-card.component.scss']
})
export class SinglePropertyCardComponent implements OnInit {

@Input() propertyData;
isLoggedIn;
locality_elocs;
transaction_type_id: string = '';

  constructor(private ApiService: ApiService,private FlashService:FlashService) { }

  ngOnInit(): void {
  }

  makeFavorite(pid, status) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('property_id', pid);
      form_data.append('status', "1");
      form_data.append('locality_elocs', this.locality_elocs);
      form_data.append('transaction_type_id', this.transaction_type_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
        if(apiResult.success) {
          if($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
            this.FlashService.success('Property is removed from your Wishlist.')
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
            this.FlashService.success('Property is added to your Wishlist.')
          }
        }
      });
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

}
