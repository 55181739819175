/* tslint:disable:quotemark */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ApiService } from './../../_services/api.service';
import { FlashService } from '../../_services/FlashService.service';
import { API_ENDPOINTS } from '../../global';
import {Meta, Title} from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-partners-program',
  templateUrl: './partners-program.component.html',
  styleUrls: ['./partners-program.component.scss']
})
export class PartnersProgramComponent implements OnInit {

  home_icon: string = '';

  constructor(private ApiService: ApiService,  private router: Router, private route: ActivatedRoute, private FlashService: FlashService, private title: Title, private formBuilder: FormBuilder) {
    this.home_icon = API_ENDPOINTS.HOME_ICON;
   }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    /*if(!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }*/
    this.route.params.subscribe(routeParams => {
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Partner Program');
  }
}
