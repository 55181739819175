<div class="property_search_outer">

  <div class="padding-div" id="properties_map_view">
    <div class="row mx-0">
      <div class="col-md-6 map-view-main-outer px-0">
        <div class="map-view">
          <span class="save-search-btn" (click)="openSaveSearchPopup()" *ngIf="isLoggedIn">Save Search</span>
          <div class="saveSearchPopup">
            <form [formGroup]="saveSearchForm" (ngSubmit)="onSaveSearch()">
              <div class="form-group">
                <input [(ngModel)]="searchnameval" formControlName="searchname" class="form-control"
                  placeholder="Search Name" [ngClass]="{ 'is-invalid': submitted && g.searchname.errors }" />
                <div *ngIf="submitted && g.searchname.errors" class="invalid-feedback">
                  <div *ngIf="g.searchname.errors.required">Search Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-block save-form-btn">Save Search</button>
                <span (click)="viewSaveSearched()" class="view_saved_searches">View Saved Searches</span>
              </div>
            </form>
          </div>
          <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
            [mapTypeControl]="true" [usePanning]="true" (mapReady)="mapReady($event)" (zoomChange)="zoomChange($event)">
            <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
              [label]="m.label" [iconUrl]="m.symbol" (markerClick)="clickedMarker(m.pid)"
              (mouseOver)="onMarkerMouseOver(infoWindow, gm)" [markerDraggable]="m.draggable">
              <agm-info-window [disableAutoPan]="false" #infoWindow>
                <div class="infoWindowOuter">
                  <div *ngIf='m.image_preview' class="imgDiv" [style.background-image]="'url(\'' + m.image_preview + '\')'">
                    &nbsp;</div>
                  <div class="otherInfo">
                    <h4>{{ m.locality_title }}</h4>
                    <p *ngIf='m.base_price'><i class="fa fa-rupee"></i> {{ m.base_price }}</p>
                  </div>
                </div>
              </agm-info-window>
            </agm-marker>
          </agm-map>
        </div>
      </div>

      <div class="col-md-6 px-0 content-view-main-outer" (click)="hideSearchBox()">
        <ng-container *ngIf="isLoading == true">
          <div class="loading_div">
            <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
          </div>
        </ng-container>
        <div class="content-view px-3">
          <div class="col-md-12">
            <div class="input-group search_text_input" id="search_text">
              <input type="text" id="searchinput" class="form-control form-control-lg"
                     placeholder="Type Location or Project/Society or Keyword">
              <span class="input-group-append"><i class="fa fa-search"></i></span>
            </div>
          </div>
          <div class="col-md-12 new-filter">
            <label> Budget &nbsp;&nbsp;</label>
            <div class="form-group row mx-0">
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_budget_amount"
                       placeholder="Min" name="min_budget_amount" />
              </div>
              <label> to </label>
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_budget_amount"
                       placeholder="Max" name="max_budget_amount" />
              </div>
              <span id="clearBudgetFilter"><i class="fa fa-times" aria-hidden="true" (click)="clearBudgetFilter()"></i></span>
              <div class="col-md-5 filterBtnDiv">
                <button type="button" id="filterBtn" class="btn btn-block login-btn mx-2 plus-minus collapsed"
                        (click)="showHideFilter()">Filters</button>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-9 prop_type_title">
              <h4 style="padding-top: 0px;">{{ name }}</h4>
            </div>
            <div class="col-3 iconview" style="padding-top: 0px;" *ngIf="projectsList.length>0">
              <span class="sort_btn"><i class="fa fa-sort-amount-asc" aria-hidden="true"></i></span>
              <span class="grid_btn active_view" (click)="showgridviewfunction()"><i class="fa fa-th-large"
                  aria-hidden="true"></i></span>
              <span class="list_btn" (click)="showlistviewfunction()"><i class="fa fa-list"
                  aria-hidden="true"></i></span>
            </div>
            <div class="col-12 prop_cat_view">
              <span class="total_props" *ngIf="total_props_s">{{ total_props_s }}</span>
            </div>
          </div>

          <!-- More Filters Options Starts -->
          <div class="row collapse mt-3" id="moreFilter">
            <div class="col-md-12 filter-property-form" style="border-bottom: 1px solid #cbcbcb !important;">
              <div class="row mx-0">
                <div class="col-md-12">
                  <!-- Category -->
                  <div class="col-md-12 col-sm-6 multi-select">
                    <label><i class="fa fa-cubes fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Category</b></label>
                    <div class="form-group pl-3 pt-2">
                      <ul class="filter_drop_outer" style="height: auto;display: flex;">
                        <li class="pl-2" *ngFor="let singlett of projectTypeCategory">
                          <label class="custom_checkbox">
                            {{ singlett.title }}
                            <input type="radio" id="tt{{ singlett.id }}" (change)="searchboxapply(0)" value="{{ singlett.id }}" [(ngModel)]="propertytypeid"><span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-12" id="">
                    <!-- Project Status -->
                    <label><i class="fa fa-cog fa_icon" aria-hidden="true"></i> &nbsp;<b> Project Status</b></label>
                    <div class="form-group pl-2 mb-2 pb-3">
                      <ng-container *ngFor="let status of projectUnitStatusList">
                        <div class="checkbox-div">
                          <input type="checkbox" name="projstatus[]" id="projstatus{{ status.id }}" class="f_box_check" value="{{ status.id }}" [(ngModel)]="status.isChecked">
                          <label class="f_box_label" for="projstatus{{ status.id }}">{{ status.text }}</label>
                        </div>
                      </ng-container>
                    </div>
                    <!-- Ends -->
                  </div>
                  <!-- Ends -->
                </div>

                <div class="col-md-6" id="bedroom_filter_div">
                  <!-- Bedrooms -->
                  <label><i class="fa fa-bed fa_icon" aria-hidden="true"></i> &nbsp;<b> Bedrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebedroom of bedroomList">
                      <div class="checkbox-div">
                        <input type="checkbox" name="beds[]" id="b{{ singlebedroom.id }}" class="f_box_check" value="{{ singlebedroom.id }}" [(ngModel)]="singlebedroom.isChecked">
                        <label class="f_box_label" for="b{{ singlebedroom.id }}">{{ singlebedroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>
                <div class="col-md-6" id="bathroom_filter_div">
                  <!-- Bathrooms -->
                  <label><i class="fa fa-bath fa_icon" aria-hidden="true"></i> &nbsp;<b> Bathrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebathroom of bathroomList">
                      <div class="checkbox-div">
                        <input type="checkbox" name="baths[]" id="bath{{ singlebathroom.id }}" class="f_box_check" value="{{ singlebathroom.id }}" [(ngModel)]="singlebathroom.isChecked">
                        <label class="f_box_label" for="bath{{ singlebathroom.id }}">{{ singlebathroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>

                <!-- Property Facts -->
                <div class="col-md-12" id="prop_fact_div">
                  <label><i class="fa fa-area-chart fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Facts</b></label>
                  <div class="form-group">
                    <div class="row mx-0">
                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Min Carpet Area</label>
                        </div>
                        <div class="col-12" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_saleable_area"
                                   placeholder="Min" name="min_saleable_area" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control" name="min_saleable_area_unit"
                                    id="min_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Max Carpet Area</label>
                        </div>
                        <div class="col-12 px-2" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_saleable_area"
                                   placeholder="Max" name="max_saleable_area" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control" name="max_saleable_area_unit"
                                    id="max_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ends -->

                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i class="fa fa-tree fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Type</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_category"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Facing -->
                <div class="col-md-6 col-sm-6 multi-select" id="facing_div">
                  <label><i class="fa fa-compass fa_icon" aria-hidden="true"></i> &nbsp;<b> Facing</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_facing"></ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Ends -->

                <!--<div class="col-md-6 col-sm-6 multi-select" id="furnish_div">
                  <label><i class="fa fa-cubes fa_icon" aria-hidden="true"></i> &nbsp;<b> Furnishing</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2" *ngFor="let furnish of furnishingList">
                        <label class="custom_checkbox" for="furnish{{ furnish.id }}">
                          {{ furnish.text }}
                          <input type="checkbox" name="furnishing" id="furnish{{ furnish.id }}" value="{{ furnish.id }}"
                                 [(ngModel)]="furnish.isChecked"><span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>-->

                <div class="col-md-6 col-sm-6 multi-select" id="extra_rooms_div">
                  <label><i class="fa fa-clock-o fa_icon" aria-hidden="true"></i> &nbsp;<b> Extra Rooms</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li>
                        <ul id="f_property_rooms"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Amenities -->
                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i class="fa fa-tree fa_icon" aria-hidden="true"></i> &nbsp;<b> Amenities</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_amenities"></ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Ends -->

              </div>
            </div>

            <div class="col-md-12 filter-foot">
              <div class="col-md-6">
                <button type="button" class="btn login-btn mx-2" (click)="clearFilter()">Reset</button>
              </div>
              <div class="col-md-6 text-right">
                <button type="button" class="btn login-btn mx-2" (click)="searchboxapply(1)">Apply Filters</button>
              </div>
            </div>

          </div>
          <!-- More Filters Options Ends -->

          <!-- Start Project Grid View -->
          <div class="row" id="project_grid_view" *ngIf="viewProject">
            <ng-container *ngFor="let item of projectsList | paginate : pageConfig">
              <div class="col-md-6 d-flex single_property_grid" *ngIf="item.id">
                <div [routerLink]="['/project-unit-details', item.project_unit_id ]" class="card">
                  <div class="grid_image_view" [style.background-image]="'url(\'' + item.image_preview + '\')'">
                    <div class="property_badge top_left">
                      <div class="property_badge top_left favoriteIcon_{{item.project_unit_id}}"
                        [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }"
                        (click)="makeProjectFav(item.id, item.project_unit_id, item.is_favorited, item.franchisee_id);$event.stopPropagation()">
                        <!--<i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: white"></i>
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: red"></i>-->
                        <i id="i_favIcon_{{item.project_unit_id}}" class="fa fa-heart fav_white_color"></i>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title" *ngIf="item.base_price && item.show_unit_price_on_website == 1">
                      <i class="fa fa-rupee"></i> <span style="padding-right: 10px;">{{item.base_price}}</span>
                    </h5>
                    <ng-container *ngIf="item.show_unit_price_on_website != 1">
                      <span class="card-title"><span style="padding-right: 10px;">{{item.project_unit_id_txt}}</span></span>
                    </ng-container>
                    <p class="card-text">
                      {{item.locality_title}} {{(item.city ? item.city.city : '')}}
                    </p>
<!--                    <p class="card-text" style="padding-top: 10px;border-top: 1px solid rgb(0 0 0 / 51%);">-->
                    <p class="card-text">
                      <span class="pro_spec" *ngIf="item.total_bedrooms"> {{item.total_bedrooms}} <i
                          class="fa fa-bed"></i> </span>
                      <span class="pro_spec" *ngIf="item.total_showers"> {{item.total_showers}} <i
                          class="fa fa-bath"></i></span>
                      <span class="pro_spec" *ngIf="item.project_unit_type_txt">
                        <label class="project_type">{{item.project_unit_type_txt}}</label>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 text-right" *ngIf="isLoading == false">
              <pagination-controls id="property_grid" (pageChange)="pageChange($event, 10)" class="my-pagination"
                maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- Ends Project Grid View -->

          <!-- Start Project List View -->
          <div class="row" id="project_list_view">
            <ng-container *ngFor="let item of projectsList | paginate : pageConfig">
              <div class="col-md-12 single_property_list" *ngIf="item.id">
                <div class="row no-gutters">
                  <div class="col-md-12">
                    <div class="card-body" [routerLink]="['/project-unit-details', item.id ]">
                      <div class="row">
                        <div class="col-md-3">
                          <h5 class="card-title" *ngIf="item.base_price && item.show_unit_price_on_website == 1">
                            <i class="fa fa-rupee"></i> <span style="padding-right: 10px;">{{item.base_price}}</span>
                          </h5>
                          <ng-container *ngIf="item.show_unit_price_on_website != 1">
                            <span class="card-title"><button class="btn project-owner-btn">{{item.project_unit_id_txt}}</button></span>
                          </ng-container>
                        </div>
                        <div class="col-md-4">
                          <p class="card-text">
                            <span class="pro_spec" *ngIf="item.total_bedrooms"> {{item.total_bedrooms}} <i
                              class="fa fa-bed"></i> </span>
                            <span class="pro_spec" *ngIf="item.total_showers"> {{item.total_showers}} <i
                              class="fa fa-bath"></i> </span>
                            <span class="pro_spec" *ngIf="item.project_unit_type_txt">
                              <label class="project_type">{{item.project_unit_type_txt}}</label>
                            </span>
                          </p>
                        </div>
                        <div class="col-md-5">
                          <p class="card-text">
                            {{item.locality_title}} {{(item.city ? item.city.city : '')}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 text-right" *ngIf="isLoading == false">
              <pagination-controls id="property_grid" (pageChange)="pageChange($event, 10)" class="my-pagination"
                maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- Ends Project List View -->

          <ng-container *ngIf="isLoading == false">
            <div class="lead_generator_div" *ngIf="projectsList.length == 0 && moreFilter === 'close' && page == 'new'">
              <p>No property found</p>
            </div>
          </ng-container>
        </div>

        <app-property-list-footer></app-property-list-footer>

      </div>
    </div>
  </div>

</div>

<div class="change-view">
  <a href="javascript:void(0);" class="show-map" (click)="toggleMapView()"><i class="fa fa-map"></i> &nbsp; Map</a>
  <a href="javascript:void(0);" class="show-list" (click)="toggleListView()"><i class="fa fa-list"></i> &nbsp; List</a>
</div>
