/* tslint:disable:no-inferrable-types max-line-length no-shadowed-variable typedef-whitespace whitespace prefer-const no-var-keyword quotemark triple-equals only-arrow-functions no-string-literal object-literal-shorthand object-literal-key-quotes variable-name forin */
import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from './../../_services/api.service';
import { Meta, Title } from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {fromEvent} from 'rxjs';
import { API_BASE_URL, API_ENDPOINTS } from 'src/app/global';

declare var $: any;
declare const require: any;
declare var google:any;

@Component({
  selector: 'app-luxury-project-units',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './luxury-project-units.component.html',
  styleUrls: ['./luxury-project-units.component.scss']
})
export class LuxuryProjectUnitsComponent implements OnInit {

  submitted = false;
  saveSearchForm: FormGroup;
  propertyList: any = [];
  projectsList: any = [];
  error;
  searchnameval: string = 'My Search';
  json_string: string = '';
  name: string = '';
  total_props_s: string = '';
  locality_elocs;
  currentPage: number = 1;
  loadmore: boolean = false;
  isLoading: boolean = true;
  zoom: number = 13;
  lat: number = 22.295492;
  lng: number = 73.173463;
  markers: marker[] = [];
  budgetList;
  icon = {
    url: 'assets/img/er_marker.png',
    scaledSize: {
      width: 30,
      height: 49
    }
  };
  isLoggedIn;
  propMap: any;

  propertytypeid: string = '';
  transaction_type_id: string = '';
  boardType: string = '';
  discount: string = '';
  transaction_type_text: string = '';
  transactionTypeList;
  bedroomList;
  bathroomList;
  projectUnitStatusList;
  discountList;
  property_category_id: string = '';
  page: string = 'new';
  propertyCategoryList;
  projectTypeCategory;
  propertyDimensionList;

  minBudgetAmount;
  minBudgetAmountValue;
  maxBudgetAmount;
  maxBudgetAmountValue;
  viewProject = true;
  viewProperty = false;
  moreFilter: string = 'close';
  furnishingList;
  ribbionClass: string = '';
  enableSticky: boolean = false;
  enableabsoluteMap: boolean = false;
  property_type_text: string = '';
  pageConfig: any;
  isZoomFlag: boolean = false;
  showHideResetBtn: boolean = false;
  isProjUnit: boolean = false;
  suitable_for_id = '';
  prevLocality_elocs: any;

  constructor(private ApiService: ApiService, private ref: ChangeDetectorRef, private router: Router,  private route: ActivatedRoute, private formBuilder: FormBuilder, private title: Title, private meta: Meta, @Inject(DOCUMENT) private document:Document) {
    this.pageConfig = {
      currentPage : 1,
      itemsPerPage : 10,
      totalItems : 0,
      id : 'property_grid'
    };
    if(this.route.snapshot.queryParams.page_no !== undefined) {
      this.pageConfig.currentPage = this.route.snapshot.queryParams.page_no;
    }
    if(this.route.snapshot.queryParams.limit !== undefined) {
      this.pageConfig.itemsPerPage = this.route.snapshot.queryParams.limit;
    }
  }

  get g() {
    return this.saveSearchForm.controls;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').addClass('header-fixed');
    $('#header .container-fluid').addClass('header-container-fluid');
    $('.footer-bottom').hide();
    const componentObj = this;
    if (this.route.snapshot.queryParams.property_type_id !== undefined) {
      this.propertytypeid = this.route.snapshot.queryParams.property_type_id;
      if (this.propertytypeid === '1') {
        this.property_type_text = 'Residential';
      } else if (this.propertytypeid === '3') {
        this.property_type_text = 'Commercial';
      } else if (this.propertytypeid === '4') {
        this.property_type_text = 'Land';
      } else if (this.propertytypeid === '7') {
        this.property_type_text = 'Mix';
      }
    }
    this.transactionTypeList = [
      {id: 4, text: 'Buy'},
      {id: 3, text: 'Rent'}
    ];
    if(this.route.snapshot.queryParams.transaction_type_id !== undefined) {
      this.transaction_type_id = this.route.snapshot.queryParams.transaction_type_id;
      if(this.transaction_type_id == "4") {
        this.transaction_type_text = "Buy";
      }
      if(this.transaction_type_id == "3") {
        this.transaction_type_text = "Rent";
      }
    }
    if (this.route.snapshot.queryParams.suitable_for_id !== undefined) {
      this.suitable_for_id = this.route.snapshot.queryParams.suitable_for_id;
    }
    $('#clearBudgetFilter').hide();
    $('#facing_div').show();
    $('#prop_fact_div').show();
    if (this.propertytypeid == "1") {
      $('#bedroom_filter_div').show();
      $('#bathroom_filter_div').show();
      $('#furnish_div').show();
      $('#extra_rooms_div').show();
      $('#furnish_div').show();
    } else {
      $('#bedroom_filter_div').hide();
      $('#bathroom_filter_div').hide();
      $('#extra_rooms_div').hide();
      $('#furnish_div').hide();
      if (this.propertytypeid == "4") {
        $('#facing_div').hide();
        $('#prop_fact_div').hide();
      }
    }
    if(this.route.snapshot.queryParams.board_type !== undefined) {
      this.boardType = this.route.snapshot.queryParams.board_type;
      this.icon.url = 'assets/img/school_pin.png';
    }
    this.bedroomList = [
      // {id: "", text: 'Any', isChecked: true},
      {id: 1, text: '1+', isChecked: false},
      {id: 2, text: '2+', isChecked: false},
      {id: 3, text: '3+', isChecked: false},
      {id: 4, text: '4+', isChecked: false},
      {id: 5, text: '5+', isChecked: false}
    ];
    if(this.route.snapshot.queryParams.bedrooms !== undefined) {
      $.each(this.route.snapshot.queryParams.bedrooms.split(','), function(index, value) {
        componentObj.bedroomList[value - 1].isChecked = true;
      });
    }
    this.discountList = [
      {id: '', text: 'All', isChecked: false},
      {id: 10, text: 'Above 10%', isChecked: false},
      {id: 20, text: 'Above 20%', isChecked: false},
      {id: 30, text: 'Above 30%', isChecked: false},
      {id: 40, text: 'Above 40%', isChecked: false}
    ];
    if(this.route.snapshot.queryParams.discount !== undefined) {
      this.discount = this.route.snapshot.queryParams.discount;
    }
    this.bathroomList = [
      // {id: "", text: 'Any', isChecked: true},
      {id: 1, text: '1+', isChecked: false},
      {id: 2, text: '2+', isChecked: false},
      {id: 3, text: '3+', isChecked: false},
      {id: 4, text: '4+', isChecked: false},
      {id: 5, text: '5+', isChecked: false}
    ];
    if(this.route.snapshot.queryParams.bath !== undefined) {
      $.each(this.route.snapshot.queryParams.bath.split(','), function(index, value) {
        componentObj.bathroomList[value - 1].isChecked = true;
      });
    }
    this.projectUnitStatusList = [
      { id: 'Under Construction', text: 'Under Construction', isChecked: false },
      { id: 'Pre Launch', text: 'Pre Launch', isChecked: false },
      { id: 'Ready Possession', text: 'Ready Possession', isChecked: false }
    ];
    if (this.route.snapshot.queryParams.projstatus !== undefined) {
      $.each(this.route.snapshot.queryParams.projstatus.split(','), (index, value) => {
        if (value == 'Under Construction') {
          componentObj.projectUnitStatusList[0].isChecked = true;
        } else if (value == 'Pre Launch') {
          componentObj.projectUnitStatusList[1].isChecked = true;
        } else if (value == 'Ready Possession') {
          componentObj.projectUnitStatusList[2].isChecked = true;
        }
      });
    }
    this.furnishingList = [
      {id: 'Fully Furnished', text: 'Furnished', isChecked: false},
      {id: 'UnFurnished', text: 'Unfurnished', isChecked: false},
      {id: 'Semi Furnished', text: 'Semi Furnished', isChecked: false},
      {id: 'Ready To Furnished', text: 'Ready To Furnished', isChecked: false},
      {id: 'Not Specified', text: 'Not Specified', isChecked: false}
    ];
    if(this.route.snapshot.queryParams.furnishing_id !== undefined) {
      $.each(this.route.snapshot.queryParams.furnishing_id.split(','), function(index, value) {
        $.each(componentObj.furnishingList, function(pci, pcv) {
          if(pcv.id == value) {
            componentObj.furnishingList[pci].isChecked = true;
          }
        });
      });
    }
    this.getProjectsList('', '', 0);
    this.viewProject = true;
    this.viewProperty = false;
    $('#project_list_view').hide();

    this.saveSearchForm = this.formBuilder.group({
      searchname: ['', [Validators.required]]
    });
    this.isLoggedIn = this.ApiService.loginCheck();
    var data = {is_web_api:1};
    this.searchCritareaNameUpdate();

    // *** Start Get Property Amenities ***//
    this.ApiService.getAmenities(data).subscribe((apiResult: any) => {
      if(apiResult){
        var store = [];
        $.each(apiResult.data, function( index, value ) {
          store.push('<li><label class="custom_checkbox" for="hame'+value.id+'">'+value.title+'<input type="checkbox" name="homeamenties[]" id="hame'+value.id+'" value="'+value.id+'"><span class="checkmark"></span></label></li>');
        });
        $("#f_property_amenities").html(store);
        /*$("input[name='homeamenties[]'").change(function(){
          componentObj.searchboxapply();
        });*/
        if(this.route.snapshot.queryParams.amenitie_ids !== undefined) {
          $.each(this.route.snapshot.queryParams.amenitie_ids.split(','), function(index, value) {
            $("#hame" + value).prop("checked", true);
          });
        }
      }
    });
    // *** End Get Property Amenities ***//

    // *** Start Get Project Dimensions ***//
    const propDimensions = {is_mobile_api: 1, property_type_id: this.propertytypeid};
    this.ApiService.getProjectDimensions(propDimensions).subscribe((apiResult: any) => {
      if (apiResult){
        this.propertyDimensionList = apiResult.data;
        let store = [];
        $.each(apiResult.data, function( index, value ) {
          store.push('<li><label class="custom_checkbox text-left" for="pdimension' + value.id + '">' + value.title + '<input type="checkbox" name="property_dimension[]" id="pdimension' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_property_rooms').html(store);
        /*$("input[name='property_dimension[]'").change(function(){
          componentObj.searchboxapply(0);
        });*/
        if (this.route.snapshot.queryParams.property_dimesion_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_dimesion_id.split(','), function(index, value) {
            $('#pdimension' + value).prop('checked', true);
          });
        }
      }
    });
    // *** End Get Project Dimensions ***//

    // *** Start Get Property Status ***//
    this.ApiService.getPropertyStatus(data).subscribe((apiResult: any) => {
      if (apiResult) {
        var storepropetystatus = [];
        $.each(apiResult.data, function( index, value ) {
          storepropetystatus.push('<li><label class="custom_checkbox" for="pstatus'+value.id+'">'+value.title+'<input type="checkbox" name="property_status" id="pstatus'+value.id+'" value="'+value.id+'"><span class="checkmark"></span></label></li>');
        });
        $("#property_status").html(storepropetystatus);
        /*$("input[name='property_status'").change(function(){
          componentObj.searchboxapply();
        });*/
        if(this.route.snapshot.queryParams.property_status_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_status_id.split(','), function(index, value) {
            $("#pstatus" + value).prop("checked", true);
          });
        }
      }
    });
    // *** End Get Property Status ***//

    // *** Start Get Property Facts ***//
    this.ApiService.getallPropertyfacts(data).subscribe((apiResult: any) => {
      if (apiResult){
        var storepropetyfacts = [];
        storepropetyfacts.push('<option value="0">Unit</option>');
        $.each(apiResult.data, function( index, value ) {
          storepropetyfacts.push('<option value="'+value.id+'">'+value.title+'</option>');
        });
        $(".f_property_facts").html(storepropetyfacts);
        if(this.route.snapshot.queryParams.min_builtup_area !== undefined) {
          $("#min_builtup_area").val(this.route.snapshot.queryParams.min_builtup_area);
        }
        if(this.route.snapshot.queryParams.min_builtup_area_unit !== undefined) {
          $("#min_builtup_area_unit").val(this.route.snapshot.queryParams.min_builtup_area_unit);
        }
        if(this.route.snapshot.queryParams.max_builtup_area !== undefined) {
          $("#max_builtup_area").val(this.route.snapshot.queryParams.max_builtup_area);
        }
        if(this.route.snapshot.queryParams.max_builtup_area_unit !== undefined) {
          $("#max_builtup_area_unit").val(this.route.snapshot.queryParams.max_builtup_area_unit);
        }
        if(this.route.snapshot.queryParams.min_saleable_area !== undefined) {
          $("#min_saleable_area").val(this.route.snapshot.queryParams.min_saleable_area);
        }
        if(this.route.snapshot.queryParams.min_saleable_area_unit !== undefined) {
          $("#min_saleable_area_unit").val(this.route.snapshot.queryParams.min_saleable_area_unit);
        }
        if(this.route.snapshot.queryParams.max_saleable_area !== undefined) {
          $("#max_saleable_area").val(this.route.snapshot.queryParams.max_saleable_area);
        }
        if(this.route.snapshot.queryParams.max_saleable_area_unit !== undefined) {
          $("#max_saleable_area_unit").val(this.route.snapshot.queryParams.max_saleable_area_unit);
        }
      }
    });
    // *** End Get Property Facts ***//

    // *** Start Get Property Facing ***//
    this.ApiService.getFacing(data).subscribe((apiResult: any) => {
      if (apiResult){
        var store = [];
        $.each(apiResult.data, function( index, value ) {
          store.push('<li><label class="custom_checkbox" for="hfac'+value.id+'">'+value.title+'<input type="checkbox" name="homefacing[]" id="hfac'+value.id+'" value="'+value.id+'"><span class="checkmark"></span></label></li>');
        });
        $("#f_property_facing").html(store);
        /*$("input[name='homefacing[]'").change(function(){
          componentObj.searchboxapply();
        });*/
        if(this.route.snapshot.queryParams.facing_ids !== undefined) {
          $.each(this.route.snapshot.queryParams.facing_ids.split(','), function(index, value) {
            $("#hfac" + value).prop("checked", true);
          });
        }
      }
    });
    // *** Ends Get Property Facing ***//

    // *** Start Get Property Age ***//
    this.ApiService.getPropertyAge(data).subscribe((apiResult: any) => {
      if (apiResult){
        var store = [];
        $.each(apiResult.data, function( index, value ) {
          store.push('<li><label class="custom_checkbox" for="age'+value.id+'">'+value.title+'<input type="checkbox" name="propertyAge[]" id="age'+value.id+'" value="'+value.id+'"><span class="checkmark"></span></label></li>');
        });
        $("#f_property_age").html(store);
        /*$("input[name='propertyAge[]'").change(function(){
          componentObj.searchboxapply();
        });*/
        if(this.route.snapshot.queryParams.facing_ids !== undefined) {
          $.each(this.route.snapshot.queryParams.facing_ids.split(','), function(index, value) {
            $("#age" + value).prop("checked", true);
          });
        }
      }
    });
    // *** Ends Get Property Age ***//

    // *** Start Get Property Type Category ***//
    const propTypeCategory = {is_mobile_api: 1};
    this.ApiService.getProjectTypes(propTypeCategory).subscribe((apiResult: any) => {
      if (apiResult){
        this.projectTypeCategory = apiResult.data;
      }
    });
    // *** End Get Property Type Category ***//

    // *** Start Get Property Category ***//
    this.setPropertyCategoryList();
    // *** End Get Property Category ***//

    setTimeout (() => {
      var elocInput = $('#searchinput');
      elocInput.tagsinput({
        itemValue: 'eLoc',
        itemText: 'placeName',
        freeInput: true,
        confirmKeys: [13, 44],
        typeaheadjs: {
          displayKey: 'placeNameFull',
          source: (search, sync, async) => {
            if (search.length >= 2) {
              return $.ajax({
                type: 'GET',
                dataType: 'json',
                url: API_BASE_URL+'/master/getLocality',
                data: {is_web_api:1, search: search},
                success: (response) => {
                  response = response.data;
                  return async(response);
                }
              });
            }
          }
        },
      });
      $('#searchinput').on('itemAdded', function(event) {
        componentObj.searchboxapply(0);
      });
      $('#searchinput').on('itemRemoved', function(event) {
        componentObj.searchboxapply(0);
      });
      if(this.locality_elocs !== undefined) {
        $.each(this.locality_elocs.split(','), function(index, value) {
          elocInput.tagsinput('add', {"placeNameFull": value, "placeName": value, "eLoc": value});
        });
      }
      $("#property_list_view").hide();
      this.changeHeaderClass();

      var amountStringMatcher = function() {
        return function findMatches(q, cb) {
          var matches = [];
          q = q.replace(/[^0-9.]+/g, "");
          q = q.replace(/\.+$/, "");
          matches.push({'label': q + ' Hund.', 'value': q * 100});
          matches.push({'label': q+' Th.', 'value': q*1000});
          matches.push({'label': q+' Lc.', 'value': q*100000});
          matches.push({'label': q+' Cr.', 'value': q*10000000});
          cb(matches);
        };
      };

      this.minBudgetAmount = $('#min_budget_amount');
      this.minBudgetAmount.typeahead(null, {
        name: 'minBudgetAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.minBudgetAmount.on('typeahead:selected', (event, data) => {
        componentObj.minBudgetAmountValue = data.value;
        componentObj.searchboxapply(0);
      });
      if(this.route.snapshot.queryParams.min_budget_amount !== undefined) {
        componentObj.minBudgetAmountValue = this.route.snapshot.queryParams.min_budget_amount;
        var amountText = "";
        if((componentObj.minBudgetAmountValue / 10000000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 10000000) + " Cr.";
        } else if((componentObj.minBudgetAmountValue / 100000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 100000) + " Lc.";
        } else if((componentObj.minBudgetAmountValue / 1000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 1000) + " Th.";
        }
        $("#min_budget_amount").val(amountText);
        $('#clearBudgetFilter').show();
      }

      this.maxBudgetAmount = $('#max_budget_amount');
      this.maxBudgetAmount.typeahead(null, {
        name: 'maxBudgetAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.maxBudgetAmount.on('typeahead:selected', (event, data) => {
        componentObj.maxBudgetAmountValue = data.value;
        componentObj.searchboxapply(0);
      });
      if(this.route.snapshot.queryParams.max_budget_amount !== undefined) {
        componentObj.maxBudgetAmountValue = this.route.snapshot.queryParams.max_budget_amount;
        var amountText = "";
        if((componentObj.maxBudgetAmountValue / 10000000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 10000000) + " Cr.";
        } else if((componentObj.maxBudgetAmountValue / 100000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 100000) + " Lc.";
        } else if((componentObj.maxBudgetAmountValue / 1000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 1000) + " Th.";
        }
        $("#max_budget_amount").val(amountText);
        $('#clearBudgetFilter').show();
      }
    }, 200);

    fromEvent(window, 'scroll').subscribe((event) => {
      var footerDiv = document.getElementById("footer");
      var scrollPos = footerDiv.offsetTop;
      if (this.document.defaultView.scrollY < scrollPos) {
        this.enableabsoluteMap = false;
        this.enableSticky=true;
      } else if(this.document.defaultView.scrollY > scrollPos) {
        this.enableSticky=false;
        this.enableabsoluteMap = true;
      } else {
        this.enableSticky=false;
        this.enableabsoluteMap = false;
      }
    });
  }

  mapReady(map) {
    this.propMap = map;
  }

  onScroll(): void  {
    /*if(this.loadmore && !this.isLoading) {
      this.isLoading = true;
      this.currentPage = this.currentPage + 1;
      this.getProjectsOnScroll();
    }*/
  }

  ngOnDestroy(): void {

  }

  searchCritareaNameUpdate() {
    this.name = "";
    if(this.route.snapshot.queryParams.locality_elocs !== undefined) {
      this.locality_elocs = this.route.snapshot.queryParams.locality_elocs;
      this.name = this.locality_elocs;
    }
    if(this.propertytypeid !== undefined) {
      if(this.propertytypeid == "1" || this.propertytypeid == "3") {
        if(this.transaction_type_id !== undefined) {
          this.name = this.property_type_text + " projects for Sale";
          /*if(this.transaction_type_id == "4") {
            if(this.name == "") {
              this.name = this.property_type_text + " projects for Sale";
            } else {
              this.name = this.property_type_text + " projects for sale in " + this.name;
            }
          } else {
            if(this.name == "") {
              this.name = this.property_type_text + " projects for Rent";
            } else {
              this.name = this.property_type_text + " projects for rent in " + this.name;
            }
          }*/
        }
      } else if(this.propertytypeid == "4") {
        this.name = "Land for Sale";
        /*if(this.name == "") {
          this.name = "Land for Sale";
        } else {
          this.name = "Land for sale in " + this.name;
        }*/
      } else if(this.propertytypeid == "5") {
        this.name = "Property for Investment";
        /*if(this.name == "") {
          this.name = "Property for Investment";
        } else {
          this.name = "Property for investment in " + this.name;
        }*/
      } else if(this.propertytypeid == "6") {
        this.name = "Hi End Properties for Sale";
        /*if(this.name == "") {
          this.name = "Hi End Properties for Sale";
        } else {
          this.name = "Hi End Properties for sale in " + this.name;
        }*/
      }
    }
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Project Units');
    /*if(this.route.snapshot.queryParams.locality_elocs !== undefined) {
      this.title.setTitle(this.name + ' - Real Estate / Property in ' + this.route.snapshot.queryParams.locality_elocs);
    } else {
      this.title.setTitle(this.name + ' - Real Estate / Properties');
    }*/
    this.meta.updateTag({ name: 'description', content: 'Find ' + this.name + ' on empirerealtors.com. Verified Listings, Map View, Price Trends, Offers, Photos & Amenities.' });
  }

  viewSaveSearched(){
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
    // const querType = [];
    // querType['type'] = 'Buyer';
    this.router.navigate(['/savedsearch'])
    }
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    var position = new google.maps.LatLng(this.lat, this.lng);
    this.propMap.panTo(position);
  }

  addMarker(markerN) {
    this.markers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  clickedMarker(pid: string) {
    this.router.navigate(['property-details', pid]);
  }

  activeView:boolean=true
  showgridviewfunction() {
    this.activeView=true
    $(() => {
      // $('.active_view').removeClass('active_view');
      // $('.grid_btn').addClass('active_view');
      $('#project_grid_view').show();
      $('#project_list_view').hide();
    });
    var data = {};
    data = this.getQueryData();
    data['page_no'] = this.pageConfig.currentPage;
    data['limit'] = 10;
    this.router.navigate(['/luxury-projects'], {queryParams: data});
    setTimeout(() => {
      this.getProjectsList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  showlistviewfunction() {
    this.activeView=false
    $(() => {
      // $('.active_view').removeClass('active_view');
      // $('.list_btn').addClass('active_view');
      $('#project_grid_view').hide();
      $('#project_list_view').show();
    });
    var data = {};
    data = this.getQueryData();
    data['page_no'] = this.pageConfig.currentPage;
    data['limit'] = 10;
    this.router.navigate(['/luxury-projects'], {queryParams: data});
    setTimeout(() => {
      this.getProjectsList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  // tslint:disable-next-line:variable-name
  searchboxapply(is_modal) {
    if (!this.isLoading) {
      var data = {};
      data = this.getQueryData();
      this.setPropertyCategoryList();
      this.router.navigate(['/luxury-projects'], {queryParams: data});
      setTimeout(() => {
        this.getProjectsList('', '', is_modal);
        this.ref.detectChanges();
        if (is_modal === 1) {
          $('#filterBtn').addClass('collapsed');
          $('#moreFilter').removeClass('show');
          $('#footer').show();
          this.moreFilter = 'close';
        } else {
          // $('#lead_generator_div').hide();
          // $('#project_grid_view').hide();
          // $('#project_list_view').hide();
        }
        // this.showHideResetBtn = true;
      }, 1000);
    }
  }

  getQueryData() {
    $('#clearBudgetFilter').hide();
    this.propertyList = [];
    this.isLoading = true;
    this.total_props_s = '';
    this.lat = 22.295492;
    this.lng = 73.173463;
    this.markers = [];
    var data = {};

    if (this.transaction_type_id !== undefined) {
      data['transaction_type_id'] = this.transaction_type_id;
    }
    if (this.suitable_for_id !== undefined) {
      data['suitable_for_id'] = this.suitable_for_id;
    }
    if (this.discount !== undefined && this.discount != "") {
      data['discount'] = this.discount;
    }
    if (this.page !== undefined) {
      data['page'] = this.page;
    }
    if (this.propertytypeid !== undefined) {
      data['property_type_id'] = this.propertytypeid;
    }
    if (this.transaction_type_id == "4") {
      this.transaction_type_text = "Buy";
    }
    if (this.transaction_type_id == "3") {
      this.transaction_type_text = "Rent";
    }

    var projstatus = [];
    $.each(this.projectUnitStatusList, (index, val) => {
      if (val.isChecked && val.id !== "") {
        projstatus.push(val.id);
      }
    });
    if (projstatus.length != 0) {
      data['projstatus'] = projstatus.join(",");
    }

    var beds = [];
    if (this.propertytypeid === '1') {
      $('#bedroom_filter_div').show();
      $.each(this.bedroomList, function (index, val) {
        if (val.isChecked && val.id !== "") {
          beds.push(val.id);
        }
      });
      if (beds.length != 0) {
        data['bedrooms'] = beds.join(",");
      }
    } else {
      $('#bedroom_filter_div').hide();
    }

    var baths = [];
    if (this.propertytypeid === '1') {
      $('#bathroom_filter_div').show();
      $.each(this.bathroomList, function (index, val) {
        if (val.isChecked && val.id !== "") {
          baths.push(val.id);
        }
      });
      if (baths.length != 0) {
        data['bath'] = baths.join(",");
      }
    } else {
      $('#bathroom_filter_div').hide();
    }

    var property_category = [];
    $("input[name='property_type[]']:checked").each(function(i){
      property_category.push($(this).val());
    });
    if (property_category.length != 0) {
      data['property_category_id'] = property_category.join(",");
    }

    var furnishing = [];
    if (this.propertytypeid == "1") {
      $('#furnish_div').show();
      $.each(this.furnishingList, function (index, val) {
        if (val.isChecked && val.id !== "") {
          furnishing.push(val.id);
        }
      });
      if (furnishing.length != 0) {
        data['furnishing'] = furnishing.join(",");
      }
    } else {
      $('#furnish_div').hide();
    }

    var facing= new Array();
    if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      $('#facing_div').show();
      $("input[name='homefacing[]']:checked").each(function (i) {
        facing.push($(this).val());
      });
      if (facing.length != 0) {
        data['facing_ids'] = facing.join(",");
      }
    } else {
      $('#facing_div').hide();
    }

    var propertyAge= new Array();
    if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      $('#prop_age_div').show();
      $("input[name='propertyAge[]']:checked").each(function (i) {
        propertyAge.push($(this).val());
      });
      if (propertyAge.length != 0) {
        data['property_age_id'] = propertyAge.join(",");
      }
    } else {
      $('#prop_age_div').hide();
    }

    var property_status = [];
    $("input[name='property_status']:checked").each(function(i){
      property_status.push($(this).val());
    });
    if (property_status.length != 0) {
      data['property_status_id'] = property_status.join(",");
    }

    var amenities= new Array();
    $("input[name='homeamenties[]']:checked").each(function(index, val){
      amenities.push($(val).val());
    });
    if (amenities.length!=0) {
      data['amenitie_ids'] = amenities.join(',');
    }

    var property_dimesion = new Array();
    if (this.propertytypeid == "1") {
      $('#extra_rooms_div').show();
      $("input[name='property_dimension[]']:checked").each(function (i) {
        property_dimesion.push($(this).val());
      });
      if (property_dimesion.length != 0) {
        data['property_dimesion_id'] = property_dimesion.join(',');
      }
    } else {
      $('#extra_rooms_div').hide();
    }

    if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      if ($("#min_builtup_area").val() != "0" && $("#min_builtup_area").val() != "") {
        data['min_builtup_area'] = $("#min_builtup_area").val();
      }
      if ($("#min_builtup_area_unit").val() != "0" && $("#min_builtup_area_unit").val() != "") {
        data['min_builtup_area_unit'] = $("#min_builtup_area_unit").val();
      }
      if ($("#max_builtup_area").val() != "0" && $("#max_builtup_area").val() != "") {
        data['max_builtup_area'] = $("#max_builtup_area").val();
      }
      if ($("#max_builtup_area_unit").val() != "0" && $("#max_builtup_area_unit").val() != "") {
        data['max_builtup_area_unit'] = $("#max_builtup_area_unit").val();
      }
      if ($("#min_saleable_area").val() != "0" && $("#min_saleable_area").val() != "") {
        data['min_saleable_area'] = $("#min_saleable_area").val();
      }
      if ($("#min_saleable_area_unit").val() != "0" && $("#min_saleable_area_unit").val() != "") {
        data['min_saleable_area_unit'] = $("#min_saleable_area_unit").val();
      }
      if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
        data['max_saleable_area'] = $("#max_saleable_area").val();
      }
      if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
        data['max_saleable_area_unit'] = $("#max_saleable_area_unit").val();
      }
      if ($('#searchinput').tagsinput('items') && $('#searchinput').tagsinput('items').length) {
        data['locality_elocs'] = ($('#searchinput').tagsinput('items').map(value => value.eLoc)).join(',');
      }
      if (this.minBudgetAmountValue !== undefined) {
        data['min_budget_amount'] = this.minBudgetAmountValue;
        $('#clearBudgetFilter').show();
      }
      if (this.maxBudgetAmountValue !== undefined) {
        data['max_budget_amount'] = this.maxBudgetAmountValue;
        $('#clearBudgetFilter').show();
      }
    }
    data['page'] = 'new';
    return data;
  }

  setPropertyCategoryList() {
    var componentObj = this;
    var propCatData = {};
    if (this.page === 'new') {
      propCatData = {is_mobile_api: 1, property_type_id: this.propertytypeid, is_for_project: 1};
    } else {
      propCatData = {is_mobile_api: 1, property_type_id: this.propertytypeid, is_for_project: 0};
    }
    this.ApiService.getPropertyCategory(propCatData).subscribe((apiResult: any) => {
      if (apiResult){
        this.propertyCategoryList = apiResult.data;
        var store = [];
        $.each(apiResult.data, ( index, value ) => {
          store.push('<li><label class="custom_checkbox" for="ptype' + value.id + '">' + value.title + '<input type="checkbox" name="property_type[]" id="ptype' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_property_category').html(store);
        /*$("input[name='property_type[]'").change(() => {
          componentObj.searchboxapply(0);
        });*/
        if (this.route.snapshot.queryParams.property_category_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_category_id.split(','), (index, value) => {
            $('#ptype' + value).prop('checked', true);
          });
        }
      }
    });
  }

  clearFilter() {
    if (!this.isLoading) {
      this.isLoading = true;
      var data = {};
      if (this.route.snapshot.queryParams.locality_elocs !== undefined) {
        data['locality_elocs'] = this.route.snapshot.queryParams.locality_elocs;
      }

      if (this.transaction_type_id !== undefined) {
        data['transaction_type_id'] = this.transaction_type_id;
      }
      if (this.propertytypeid !== undefined) {
        data['property_type_id'] = this.propertytypeid;
      }
      if (this.suitable_for_id !== undefined) {
        data['suitable_for_id'] = this.suitable_for_id;
      }
      var property_category = [];
      $("input[name='property_type[]']:checked").each(function(index, val){
        property_category.push($(val).val());
      });
      if (property_category.length != 0) {
        data['property_category_id'] = property_category.join(",");
      }
      this.router.navigate(['/luxury-projects'], {queryParams: data});
      setTimeout(() => {
        // this.getPropertyList('', '');
        // this.ref.detectChanges();
        window.location.reload();
      }, 200);
    }
  }

  clearBudgetFilter() {
    this.minBudgetAmountValue = null;
    $("#min_budget_amount").val('');
    this.maxBudgetAmountValue = null;
    $("#max_budget_amount").val('');
    this.searchboxapply(0);
    $('#clearBudgetFilter').hide();
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  makeProjectFav(pid, puid, status, franchisee_id) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('id', pid);
      form_data.append('project_unit_id', puid);
      form_data.append('status', "1");
      form_data.append('locality_elocs', this.locality_elocs);
      form_data.append('transaction_type_id', this.transaction_type_id);
      form_data.append('franchisee_id', franchisee_id);
      // form_data.append('from_rpc', "1");
      this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_FAVORITE, form_data).subscribe((apiResult: any) => {
        if(apiResult.success) {
          if($('.favoriteIcon_' + puid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + puid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + puid).find('.fa').css("color", "#FFFFFF");
          } else {
            $('.favoriteIcon_' + puid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + puid).find('.fa').css("color", "#F02174");
          }
        }
      });
    }
  }

  openSaveSearchPopup(): void {
    this.getSavedSearches();
    if($('.saveSearchPopup').hasClass('savedsearchactive')) {
      $('.saveSearchPopup').removeClass('savedsearchactive');
    } else {
      $('.saveSearchPopup').addClass('savedsearchactive');
    }
  }

  hideSearchBox() {
    $('.saveSearchPopup').removeClass('savedsearchactive');
  }

  onSaveSearch() {
    this.submitted = true;
    if (this.saveSearchForm.invalid) {
      return;
    }
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var data = {};
      data = this.getQueryData();
      var form_data = new FormData();
      this.json_string = JSON.stringify(data);
      form_data.append('json_string', this.json_string);
      form_data.append('name', this.searchnameval);
      form_data.append('source', 'project-units');
      this.ApiService.postRequest(API_ENDPOINTS.API_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
        if(apiResult.success) {
          this.router.navigate(['/savedsearch']);
        }
      });
    }
  }

  prepareSearchJson() {
    var data = {};
    if(this.transaction_type_id !== undefined) {
      data['transaction_type_id'] = this.transaction_type_id;
    }
    if(this.discount !== undefined) {
      data['discount'] = this.discount;
    }
    if(this.page !== undefined) {
      data['page'] = this.page;
    }
    if(this.propertytypeid !== undefined) {
      data['property_type_id'] = this.propertytypeid;
    }

    var beds = [];
    $.each(this.bedroomList, function(index, val) {
      if(val.isChecked && val.id !== "") {
        beds.push(val.id);
      }
    });
    if(beds.length != 0) {
      data['bedrooms'] = beds.join(",");
    }

    var baths = [];
    $.each(this.bathroomList, function(index, val) {
      if(val.isChecked && val.id !== "") {
        baths.push(val.id);
      }
    });
    if(baths.length != 0) {
      data['bath'] = baths.join(",");
    }

    var property_category = [];
    $("input[name='property_type[]']:checked").each(function(index, val){
      property_category.push($(val).val());
    });
    if(property_category.length != 0) {
      data['property_category_id'] = property_category.join(",");
    }

    var furnishing = [];
    $.each(this.furnishingList, function(index, val) {
      if(val.isChecked && val.id !== "") {
        furnishing.push(val.id);
      }
    });
    if(furnishing.length != 0) {
      data['furnishing'] = furnishing.join(",");
    }

    var facing= new Array();
    $("input[name='homefacing[]']:checked").each(function(i){
      facing.push($(this).val());
    });
    if(facing.length!=0) {
      data['facing_ids'] = facing.join(",");
    }

    var propertyAge= new Array();
    $("input[name='propertyAge[]']:checked").each(function(i){
      propertyAge.push($(this).val());
    });
    if(propertyAge.length!=0) {
      data['property_age_id'] = propertyAge.join(",");
    }

    var property_status = [];
    $("input[name='property_status']:checked").each(function(i){
      property_status.push($(this).val());
    });
    if(property_status.length != 0) {
      data['property_status_id'] = property_status.join(",");
    }

    var amenities= new Array();
    $("input[name='homeamenties[]']:checked").each(function(i){
      amenities.push($(this).val());
    });
    if(amenities.length!=0) {
      data['amenitie_ids'] = amenities.join(",");
    }
    if($("#min_builtup_area").val() != "0" && $("#min_builtup_area").val() != "") {
      data['min_builtup_area'] = $("#min_builtup_area").val();
    }
    if($("#min_builtup_area_unit").val() != "0" && $("#min_builtup_area_unit").val() != "") {
      data['min_builtup_area_unit'] = $("#min_builtup_area_unit").val();
    }
    if($("#max_builtup_area").val() != "0" && $("#max_builtup_area").val() != "") {
      data['max_builtup_area'] = $("#max_builtup_area").val();
    }
    if($("#max_builtup_area_unit").val() != "0" && $("#max_builtup_area_unit").val() != "") {
      data['max_builtup_area_unit'] = $("#max_builtup_area_unit").val();
    }
    if($("#min_saleable_area").val() != "0" && $("#min_saleable_area").val() != "") {
      data['min_saleable_area'] = $("#min_saleable_area").val();
    }
    if($("#min_saleable_area_unit").val() != "0" && $("#min_saleable_area_unit").val() != "") {
      data['min_saleable_area_unit'] = $("#min_saleable_area_unit").val();
    }
    if($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area'] = $("#max_saleable_area").val();
    }
    if($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area_unit'] = $("#max_saleable_area_unit").val();
    }
    if($('#searchinput').tagsinput('items') && $('#searchinput').tagsinput('items').length){
      data['locality_elocs'] = ($('#searchinput').tagsinput('items').map(value => value.eLoc)).join(',');
      if (this.searchnameval === "") {
        this.searchnameval = data['locality_elocs'];
      }
    }
    if(this.minBudgetAmountValue !== undefined) {
      data['min_budget_amount'] = this.minBudgetAmountValue;
    }
    if(this.maxBudgetAmountValue !== undefined) {
      data['max_budget_amount'] = this.maxBudgetAmountValue;
    }

    this.router.navigate(['/luxury-projects'], {queryParams: data});
    this.json_string = JSON.stringify(data);
  }

  getSavedSearches() {
    var componentObj = this;
    this.prepareSearchJson();
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if(apiResult.success) {
        $.each(apiResult.data, function(index, value) {
          if(value.json_string === componentObj.json_string) {
            componentObj.searchnameval = value.name;
          }
        });
      }
    });
  }

  getProjectsList(NorthWest, SouthEst, is_modal){
    if(this.route.snapshot.queryParams?.locality_elocs !== this.prevLocality_elocs){
      this.pageConfig.currentPage=1
    }
    this.prevLocality_elocs=this.route.snapshot.queryParams.locality_elocs
    this.searchCritareaNameUpdate();
    var form_data = new FormData();
    var page_no = this.pageConfig.currentPage;
    for (var i in this.route.snapshot.queryParams){
      var param = this.route.snapshot.queryParams[i];
      if(this.route.snapshot.queryParams[i]){
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page_no', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'project_unit');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECT_UNITS, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success){
        var propCattxt = "";
        if (this.route.snapshot.queryParams.property_category_id) {
          var propCat = this.route.snapshot.queryParams.property_category_id;
          propCat = propCat.split(',');
          $(this.propertyCategoryList).each(function(k, v) {
            for (var i = 0;i <= propCat.length; i++) {
              if (v.id == propCat[i]) {
                propCattxt = propCattxt != "" ? propCattxt + ', ' + v.title : v.title;
              }
            }
          });
        }
        propCattxt = propCattxt != "" ? propCattxt : "Project";
        this.loadmore = apiResult.data.paginate.Project.nextPage;
        if (apiResult.data.paginate.Project.count > 0) {
          this.isProjUnit = true;
          if (is_modal == 1) {
            $('#project_grid_view').show();
          }
        } else {
          this.isProjUnit = false;
          if (is_modal == 1) {
            $('#lead_generator_div').show();
          }
        }
        this.currentPage = 1;
        this.pageConfig.totalItems = apiResult.data.paginate.Project.count;
        this.projectsList = apiResult.data.list;
        if (apiResult.data.paginate.Project.count === 0) {
          if (apiResult.data.lat_lng) {
            this.lat = apiResult.data.lat_lng.lat;
            this.lng = apiResult.data.lat_lng.lng;
          }
          this.name =(this.projectsList || []).length > 0 ?  'We are working on your search criteria, please fill the below form, our team will get back to you soon' : 'No projects found';
        }
        this.ref.detectChanges();
        this.total_props_s = apiResult.data.paginate.Project.count + ' ' + propCattxt;
        /*$.each(apiResult.data.list, function( index, value ) {
          if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
            componentObj.lat = value.locality_lat;
            componentObj.lng = value.locality_lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            var markerN = {
              pid: value.id,
              symbol: {
                url: 'assets/img/er_marker.png',
                scaledSize: {
                  width: 30,
                  height: 49
                }
              },
              lat: value.locality_lat,
              lng: value.locality_lng,
              draggable: false,
              image_preview: value.image_preview,
              locality_title: value.name,
              asking_price: value.rpcf_price_title
            };
            componentObj.addMarker(markerN);
        }
        });*/
        this.isZoomFlag = false;
        this.isLoading = false;
        this.getProjectsFav('', '', 0);
        this.getProjectsUnitMapIcons('', '', 0);
      } else {
        this.loadmore = false;
        this.currentPage = 1;
        this.isZoomFlag = false;
        this.isLoading = false;
        this.total_props_s = "0 Project";
        this.ref.detectChanges();
      }
    });
  }

  getProjectsFav(NorthWest, SouthEst, is_modal) {
    var form_data = new FormData();
    var page_no = this.pageConfig.currentPage;
    for (var i in this.route.snapshot.queryParams){
      var param = this.route.snapshot.queryParams[i];
      if(this.route.snapshot.queryParams[i]){
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page_no', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'fav_icon');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECT_UNITS, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        $.each(apiResult.data.list, (index, value) => {
          if (value.is_favorited == 1) {
            $('#i_favIcon_' + value.project_unit_id).removeClass('fav_white_color');
            $('#i_favIcon_' + value.project_unit_id).addClass('fav_red_color');
          }
        });
      }
    });
  }

  getProjectsUnitMapIcons(NorthWest, SouthEst, is_modal) {
    var form_data = new FormData();
    var page_no = this.pageConfig.currentPage;
    for (var i in this.route.snapshot.queryParams){
      var param = this.route.snapshot.queryParams[i];
      if(this.route.snapshot.queryParams[i]){
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page_no', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'map_icon');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECT_UNITS, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        $.each(apiResult.data.list, function( index, value ) {
          if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
            componentObj.lat = value.locality_lat;
            componentObj.lng = value.locality_lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            var markerN = {
              pid: value.id,
              symbol: {
                url: 'assets/img/er_marker.png',
                scaledSize: {
                  width: 30,
                  height: 49
                }
              },
              lat: value.locality_lat,
              lng: value.locality_lng,
              draggable: false,
              image_preview: value.image_preview,
              locality_title: value.name,
              asking_price: value.rpcf_price_title
            };
            componentObj.addMarker(markerN);
          }
        });
      }
    });
  }

  showHideFilter() {
  this.activeView=true
    if (this.moreFilter === 'close' || this.moreFilter === undefined) {
      $('#filterBtn').removeClass('collapsed');
      $('#moreFilter').addClass('show');
      $('#project_grid_view').hide();
      $('#project_list_view').hide();
      $('#lead_generator_div').hide();
      this.moreFilter = 'open';
    } else {
      $('#filterBtn').addClass('collapsed');
      $('#moreFilter').removeClass('show');
      if (this.isProjUnit === false) {
        $('#lead_generator_div').show();
      } else {
        $('#project_grid_view').show();
      }
      this.moreFilter = 'close';
    }
  }

  pageChange(newPage: number, itemsPerPage) {
    this.pageConfig.currentPage = newPage;
    this.pageConfig.itemsPerPage = itemsPerPage;
    var data = {};
    data = this.getQueryData();
    data['page_no'] = newPage;
    data['limit'] = itemsPerPage;
    this.router.navigate(['/luxury-projects'], {queryParams: data});
    setTimeout(() => {
      this.getProjectsList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  zoomChange(e: any) {
    if (this.isZoomFlag === false) {
      this.isLoading = true;
      const tmpMap = this.propMap;
      const bounds = tmpMap.getBounds();
      const NorthWest: any = [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()];
      const SouthEst: any = [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()];
      this.getProjectsList(NorthWest, SouthEst, 0);
    }
  }

  openFilterPropertyModal(): void {
    $('#filterPropertyModal').modal('toggle');
  }

  toggleMapView() {
    $('.show-map').hide();
    $('.show-list').show();
    $('.map-view-main-outer').show();
    $('.content-view-main-outer').hide();
  }

  toggleListView() {
    $('.show-map').show();
    $('.show-list').hide();
    $('.map-view-main-outer').hide();
    $('.content-view-main-outer').show();
  }

}

interface marker {
  pid?: string;
  lat: number;
  lng: number;
  label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
  symbol?: object;
  draggable: boolean;
}
