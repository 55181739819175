/* tslint:disable:prefer-const no-var-keyword variable-name no-string-literal no-inferrable-types object-literal-key-quotes quotemark prefer-for-of triple-equals */
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from 'select2';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS, WEB_URL } from 'src/app/global';
import { OrderByArray } from '../shared/sortarray';

declare var $: any;

// @ts-ignore
@Component({
  selector: 'app-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AccountDashboardComponent implements OnInit {

  search_icon: string = '';
  isLoading: boolean = true;
  sellerPropertyList: any = [];
  sellerAppointmentList: any = [];
  sellerOfferList: any = [];
  buyerOfferList: any = [];
  buyerOfferProjectList: any = [];
  buyerNewWishlist: any = [];
  buyerResaleWishlist: any = [];
  newAppointmntList: any = [];
  resaleAppointmntList: any = [];
  buyerRecentlyViewed: any = [];
  buyerNewRecentlyViewed: any = [];
  dashboardType = 'Buyer';
  schedulevisitdata = {};
  propertyName: string = '';
  currentItem: any;
  propertyPrice: string = '';
  submitted = false;
  offerPrice: number = 0;
  offerForm: FormGroup;
  offerformvalid: boolean = true;
  minOfferPriceText: string = '';
  offerpriceminerror: boolean = true;
  offerAmount;
  // tslint:disable-next-line:variable-name
  project_unit_no_id: string = '';
  projectUnitNoId: string = '';
  // projectUnitNoData: Array<Select2OptionData>;
  projectUnitNoData: any = [];
  options: Options;

  // tslint:disable-next-line:max-line-length no-shadowed-variable
  constructor(private ApiService: ApiService, private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private FlashService: FlashService, private title: Title, private formBuilder: FormBuilder) {
    this.search_icon = API_ENDPOINTS.HOME_ICON;
    window.scroll(0,0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    $('#filterPropBtn').show();
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.offerForm = this.formBuilder.group({
      projectUnitNoId: ['', [Validators.required]],
      offerprice: ['', [Validators.required]]
    });
    setTimeout(() => {

      this.changeHeaderClass();
      var amountStringMatcher = () => {
        return function findMatches(q, cb) {
          var matches = [];
          q = q.replace(/[^0-9.]+/g, '');
          matches.push({ 'label': q + ' Hund.', 'value': q * 100 });
          matches.push({ 'label': q + ' Th.', 'value': q * 1000 });
          matches.push({ 'label': q + ' Lc.', 'value': q * 100000 });
          matches.push({ 'label': q + ' Cr.', 'value': q * 10000000 });
          cb(matches);
        };
      };
      this.offerAmount = $('#counterofferAmount');
      this.offerAmount.typeahead(null, {
        name: 'offerAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.offerAmount.on('typeahead:selected', function (event, data) {
        this.offerPrice = data.value;
      });
    }, 200);

    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.queryParams.type !== undefined) {
        if (this.route.snapshot.queryParams.type === 'both') {
          this.dashboardType = 'Buyer';
        } else {
          this.dashboardType = this.route.snapshot.queryParams.type;
        }
      }
      this.getSellerPropertyList();
      this.getSellerAppointmentList();
      this.getAppointmentList();
      this.getProjectAppointmentList();
      this.getOfferList();
      this.getBuyerResaleWishlist();
      this.getBuyerNewWishlist();
      this.getBuyerOfferList();
      this.getBuyerRecentlyViewedList();
      this.getBuyerNewRecentlyViewedList();
      setTimeout(() => {
        this.changeHeaderClass();
      }, 200);

      setTimeout(() => {
        let routerdFrom = localStorage.getItem('isFromSchedule')
        if (routerdFrom == 'propertyAddAppointment') {
          let target = document.getElementById('buyerAppointments_sec');
          if (target) this.activeBuyerSidebarRoute('buyerAppointments', { target })
        }
        localStorage.removeItem('isFromSchedule')
      }, 1000);

    });
  }

  activeSellerSidebarRoute(tabName, event) {
    let div1 = document.getElementById('sellerPropertyDiv');
    let div2 = document.getElementById('sellerAppointmentDiv');
    let div3 = document.getElementById('sellerOffersDiv');

    var sidebarli = document.getElementsByClassName('sidebarli');
    for (var i = 0; i < sidebarli.length; i++) {
      sidebarli[i].classList.remove('active');
    }
    event.target.classList.add('active');
    if (tabName === 'sellerProperty') {
      div1.classList.remove('section-hide');
      div1.classList.add('section-show');
      div2.classList.add('section-hide');
      div2.classList.remove('section-show');
      div3.classList.remove('section-show');
      div3.classList.add('section-hide');
    }
    if (tabName === 'sellerAppointment') {
      div1.classList.add('section-hide');
      div1.classList.remove('section-show');
      div2.classList.remove('section-hide');
      div2.classList.add('section-show');
      div3.classList.remove('section-show');
      div3.classList.add('section-hide');
    }
    if (tabName === 'sellerOffer') {
      div1.classList.add('section-hide');
      div1.classList.remove('section-show');
      div2.classList.add('section-hide');
      div2.classList.remove('section-show');
      div3.classList.add('section-show');
      div3.classList.remove('section-hide');
    }
  }

  activeBuyerSidebarRoute(tabName, event) {
    let div1 = document.getElementById('buyerWishlistDiv');
    let div2 = document.getElementById('buyerAppointmentsDiv');
    let div3 = document.getElementById('buyerViewedDiv');
    let div4 = document.getElementById('buyerOfferDiv');

    var sidebarli = document.getElementsByClassName('sidebarli');
    for (var i = 0; i < sidebarli.length; i++) {
      sidebarli[i].classList.remove('active');
    }
    event.target.classList.add('active');
    if (tabName === 'buyerWishlist') {
      this.getBuyerResaleWishlist();
      this.getBuyerNewWishlist();
      div1.classList.remove('section-hide');
      div1.classList.add('section-show');
      div2.classList.add('section-hide');
      div2.classList.remove('section-show');
      div3.classList.remove('section-show');
      div3.classList.add('section-hide');
      div4.classList.remove('section-show');
      div4.classList.add('section-hide');
    }
    if (tabName === 'buyerAppointments') {
      this.getAppointmentList();
      this.getProjectAppointmentList();
      div1.classList.add('section-hide');
      div1.classList.remove('section-show');
      div2.classList.remove('section-hide');
      div2.classList.add('section-show');
      div3.classList.remove('section-show');
      div3.classList.add('section-hide');
      div4.classList.remove('section-show');
      div4.classList.add('section-hide');
    }
    if (tabName === 'buyerViewed') {
      this.getBuyerRecentlyViewedList();
      this.getBuyerNewRecentlyViewedList();
      div1.classList.add('section-hide');
      div1.classList.remove('section-show');
      div2.classList.add('section-hide');
      div2.classList.remove('section-show');
      div3.classList.add('section-show');
      div3.classList.remove('section-hide');
      div4.classList.remove('section-show');
      div4.classList.add('section-hide');
    }
    if (tabName === 'buyerOffer') {
      this.getOfferList()
      div1.classList.add('section-hide');
      div1.classList.remove('section-show');
      div2.classList.add('section-hide');
      div2.classList.remove('section-show');
      div3.classList.remove('section-show');
      div3.classList.add('section-hide');
      div4.classList.add('section-show');
      div4.classList.remove('section-hide');
    }
  }

  onChnageTabMode(type) {

    switch (type) {
      case 'resaleWishList':
        this.getBuyerResaleWishlist();
        break;

      case 'newWishList':
        this.getBuyerNewWishlist();
        break;

      case 'resaleAppointmntList':
        this.getAppointmentList();
        break;

      case 'newAppointmntList':
        this.getProjectAppointmentList();
        break;

      case 'resaleViewed':
        this.getBuyerRecentlyViewedList();
        break;

      case 'newViewed':
        this.getBuyerNewRecentlyViewedList();
        break;
    }
  }

  getSellerPropertyList() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SELLER_PROPERTIES, form_data).subscribe((apiResult: any) => {

      if (apiResult.success) {
        this.isLoading = false;
        this.sellerPropertyList = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getSellerAppointmentList() {
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_APPOINTMENT_PROPERTIES, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.sellerAppointmentList = apiResult.data;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getOfferList() {
    this.isLoading = true
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_OFFERS_PROPERTIES, form_data).subscribe((apiResult: any) => {

      if (apiResult.success) {
        this.isLoading = false;
        this.sellerOfferList = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getBuyerOfferList() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_SET_BUYER_PROPERTY_OFFER, form_data).subscribe((apiResult: any) => {

      if (apiResult.success) {
        this.isLoading = false;
        this.buyerOfferList = apiResult.data.list.property;
        this.buyerOfferProjectList = apiResult.data.list.project;
      } else {
        this.isLoading = false;
      }
    });
  }
  getProjectAppointmentList() {
    this.isLoading = true;
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_APPOINTMENT_PROJECTS, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.newAppointmntList = OrderByArray((apiResult.data || []), 'appo_date', 'desc', true, true);
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getBuyerResaleWishlist() {
    this.isLoading = true
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_WISHLIST_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerResaleWishlist = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  openInNewTab(id, type, aStatus='') {

// || aStatus == 'Under offer'
    if (aStatus == 'Sold' ) {
      return
    }

    if (type === 'property') {
      window.open(WEB_URL + '/property-details/' + id, '_blank')
    }

    if (type === 'project') {
      window.open(WEB_URL + '/project-details/' + id, '_blank')
    }

    if (type === 'PU') {
      window.open(WEB_URL + '/project-unit-details/' + id, '_blank')
    }
  }

  getBuyerNewWishlist() {
    this.isLoading = true
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_WISHLIST_PROJECT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerNewWishlist = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getAppointmentList() {
    this.isLoading = true
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_APPOINTMENT_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.resaleAppointmntList = OrderByArray((apiResult.data || []), 'appo_date', 'desc', true, true);
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getBuyerRecentlyViewedList() {
    this.isLoading = true
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_RECENTLY_VIEWED_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerRecentlyViewed = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getBuyerNewRecentlyViewedList() {
    this.isLoading = true
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_RECENTLY_VIEWED_PROJECT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerNewRecentlyViewed = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  onMarkSold(id) {
    const form_data = new FormData();
    form_data.append('property_id', id + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_MARKED_PROPERTY, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Property has been successfully sold.");
        this.getSellerPropertyList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  onCancelAppointmentClick(id) {
    const form_data = new FormData();
    form_data.append('appointment_id', id + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_CANCEL_APPOINTMENT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Appointment has been successfully cancelled.");
        // this.getSellerAppointmentList();
        // this.getAppointmentList()
        this.resaleAppointmntList.filter(x => x.id == id)[0].status = 5
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  onCancelProjectAppointmentClick(id) {
    const form_data = new FormData();
    form_data.append('appointment_id', id + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_CANCEL_APPOINTMENT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Appointment has been successfully cancelled.");
        this.getProjectAppointmentList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  onAcceptAppointmentClick(id, from, oppoId) {
    const form_data = new FormData();
    form_data.append('appointment_id', id + "");
    form_data.append('status', '1');
    form_data.append('from', from);
    form_data.append('appo_attendee_id', oppoId);
    this.ApiService.postRequest(API_ENDPOINTS.API_ACCEPT_APPOINTMENT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Appointment has been successfully accepted.");
        this.getSellerAppointmentList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  onRescheduleAppointmentClick(project_id, id, assign_to_user_id, franchisee_id, source) {
    if (id != '0') {
      this.schedulevisitdata['related_source_id'] = id;
    } else {
      this.schedulevisitdata['related_source_id'] = project_id;
    }
    this.schedulevisitdata['assign_to_user_id'] = assign_to_user_id;
    this.schedulevisitdata['franchisee_id'] = franchisee_id;
    this.schedulevisitdata['temp_source'] = source;
    this.schedulevisitdata['source'] = source;
    this.router.navigate(['/schedulevisit'], { queryParams: this.schedulevisitdata });
  }

  navigateToProject(projectId, projectUnitId) {
    if (projectId > 0) {
      window.open(WEB_URL + '/project-details/' + projectId, '_blank')
      // this.router.navigate(['/project-details/' + projectId]);
    } else if (projectUnitId > 0) {
      // this.router.navigate(['/project-unit-details/' + projectUnitId]);
      window.open(WEB_URL + '/project-unit-details/' + projectUnitId, '_blank')
    }
  }

  changeHeaderClass(): void {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard');
  }

  onRejectedClick(id) {
    const form_data = new FormData();
    form_data.append('oppprtunity_wishlist_id', id + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_VIEWED_REJECTED, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Property has been successfully rejected.");
        this.getBuyerRecentlyViewedList();
        this.getBuyerNewRecentlyViewedList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  get g() {
    return this.offerForm.controls;
  }

  onMakeCounterOfferClick(vieweditem) {
    this.propertyName = '';
    this.currentItem = vieweditem;
    $('#filterPropBtn').show();
    $('.loaderBtn').hide();
    $('.projectUnitNoIdDiv').hide();
    $('#projectUnitNoIdSel').removeAttr('disabled');
    if (vieweditem.project_id && vieweditem.project_unit_id && vieweditem.project_unit_no_id) {
      $('.projectUnitNoIdDiv').show();
      const form_data = new FormData();
      form_data.append('project_id', vieweditem.project_id);
      form_data.append('project_unit_id', vieweditem.project_unit_id);
      form_data.append('project_unit_no_id', vieweditem.project_unit_no_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_GET_PROJECT_UNIT_NO, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          this.projectUnitNoData = apiResult.data.list;
          this.project_unit_no_id = vieweditem.project_unit_no_id;
          if (vieweditem.project_unit_no_id > 0) {
            $('#projectUnitNoIdSel').attr('disabled', 'disabled');
          }
        } else {
        }
        $('#makeCounterOfferModel').modal('toggle');
      }, error => {
        this.submitted = false;
        this.FlashService.error('Something wrong!');
        $('#makeCounterOfferModel').modal('toggle');
      });
    }
    this.propertyPrice = '<i class="fa fa-rupee"></i>' + vieweditem.asking_price_in_word;
    $('#makeCounterOfferModel').modal('toggle');
  }

  getProjectUnitNo(value) {
    this.project_unit_no_id = value;
  }

  onOfferSubmit(favouritesItem) {
    this.submitted = true;
    let propertyId = 0;
    let projectId = 0;
    let projectUnitId = 0;
    let projectUnitNoId = '';
    if (favouritesItem.property_id > 0) {
      if (this.offerPrice === 0) {
        return;
      }
      propertyId = favouritesItem.property_id;
    } else {
      if (this.offerForm.invalid) {
        return;
      }
      projectId = favouritesItem.project_id;
      projectUnitId = favouritesItem.project_unit_id;
      projectUnitNoId = this.project_unit_no_id;
    }
    $('#filterPropBtn').hide();
    $('.loaderBtn').show();
    $('#projectUnitNoIdSel').removeAttr('disabled');
    const form_data = new FormData();
    form_data.append('wishlist_id', favouritesItem.opp_wishlist.id + '');
    form_data.append('property_id', propertyId + '');
    form_data.append('project_id', projectId + '');
    form_data.append('opportunity_id', favouritesItem.opportunity_id + '');
    form_data.append('project_unit_id', projectUnitId + '');
    form_data.append('project_unit_no_id', projectUnitNoId);
    form_data.append('offer_amount', this.offerPrice + '');
    const offer_amount_title = this.amountToString(this.offerPrice);
    form_data.append('offer_amount_title', offer_amount_title + '');

    this.ApiService.postRequest(API_ENDPOINTS.API_SET_OFFER, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success('Offer has been successfully sent.');
        this.getBuyerOfferList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.submitted = false;
      $('#makeCounterOfferModel').modal('toggle');
    }, error => {
      this.submitted = false;
      this.FlashService.error('Something wrong!');
      $('#makeCounterOfferModel').modal('toggle');
    });
  }

  amountToString(amount) {
    var amountText = "";
    if ((amount / 10000000) >= 1) {
      amountText = (amount / 10000000) + " Cr.";
    } else if ((amount / 100000) >= 1) {
      amountText = (amount / 100000) + " Lc.";
    } else if ((amount / 1000) >= 1) {
      amountText = (amount / 1000) + " Th.";
    }
    return amountText;
  }
}
