/* tslint:disable:quotemark */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/global';
import { ApiService } from './../../_services/api.service';
declare var $: any;

@Component({
  selector: 'app-reviews',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class AccountReviewsComponent implements OnInit {

  review_icon: string = '';
  dashboardType = 'buyer';
  constructor(private ApiService: ApiService,  private router: Router, private route: ActivatedRoute, private title: Title,) {
    this.review_icon = API_ENDPOINTS.REVIEW_ICON;
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if(!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if(this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Reviews');
  }
}
