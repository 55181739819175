/* tslint:disable:no-inferrable-types variable-name quotemark no-shadowed-variable max-line-length prefer-const no-var-keyword triple-equals no-string-literal whitespace forin object-literal-key-quotes */
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
import { API_BASE_URL, API_ENDPOINTS, WEB_URL } from 'src/app/global';

declare var $: any;
declare const require: any;
declare var google: any;

@Component({
  selector: 'app-luxury-projects',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './luxury-projects.component.html',
  styleUrls: ['./luxury-projects.component.scss']
})
export class LuxuryProjectsComponent implements OnInit {

  projectsList: any = [];
  error;
  leadForm: FormGroup;
  saveSearchForm: FormGroup;
  submitted = false;
  name = '';
  total_props_s = '';
  suitable_for_id = '';
  locality_elocs;
  currentPage = 1;
  loadmore = false;
  isProject = false;
  isLoading = true;
  zoom = 13;
  lat = 22.295492;
  lng = 73.173463;
  markers: marker[] = [];
  budgetList;
  icon = {
    url: 'assets/img/er_marker.png',
    scaledSize: {
      width: 30,
      height: 49
    }
  };
  propMap: any;

  propertytypeid = '';
  projectstatusid = '';
  transaction_type_id = '';
  boardType = '';
  transaction_type_text = '';
  transactionTypeList;
  bedroomList;
  bathroomList;
  projectStatusList;
  property_category_id = '';
  propertyCategoryList;
  projectTypeCategory;

  minBudgetAmount;
  minBudgetAmountValue;
  maxBudgetAmount;
  maxBudgetAmountValue;
  first_name = '';
  last_name = '';
  email = '';
  phone = '';
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  isLoggedIn;
  moreFilter = 'close';
  property_type_text = '';
  enableSticky = false;
  enableabsoluteMap = false;
  pageConfig: any;
  latlngarea: any = [];
  start_end_mark = [];
  json_string = '';
  searchnameval = 'My Search';
  isZoomFlag = false;
  showHideResetBtn = false;
  prevLocality_elocs: any;

  constructor(private ApiService: ApiService, private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private formBuilder: FormBuilder, private FlashService: FlashService, @Inject(DOCUMENT) private document: Document) {
    this.pageConfig = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      id: 'property_grid'
    };
    if (this.route.snapshot.queryParams.page_no !== undefined) {
      this.pageConfig.currentPage = this.route.snapshot.queryParams.page_no;
    }
    if (this.route.snapshot.queryParams.limit !== undefined) {
      this.pageConfig.itemsPerPage = this.route.snapshot.queryParams.limit;
    }
  }

  get l() {
    return this.leadForm.controls;
  }

  get g() {
    return this.saveSearchForm.controls;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').addClass('header-fixed');
    // $('#header .container-fluid').addClass('header-container-fluid');
    $('.footer-bottom').hide();
    $('#clearBudgetFilter').hide();
    this.isLoggedIn = this.ApiService.loginCheck();
    const componentObj = this;
    if (this.route.snapshot.queryParams.property_type_id !== undefined) {
      this.propertytypeid = this.route.snapshot.queryParams.property_type_id;
      if (this.propertytypeid == "1") {
        this.property_type_text = "Residential";
      }
      if (this.propertytypeid == "3") {
        this.property_type_text = "Commercial";
      }
    }
    if (this.route.snapshot.queryParams.suitable_for_id !== undefined) {
      this.suitable_for_id = this.route.snapshot.queryParams.suitable_for_id;
    }
    this.transactionTypeList = [
      { id: 4, text: 'Buy' },
      { id: 3, text: 'Rent' }
    ];
    if (this.route.snapshot.queryParams.transaction_type_id !== undefined) {
      this.transaction_type_id = this.route.snapshot.queryParams.transaction_type_id;
      if (this.transaction_type_id == "4") {
        this.transaction_type_text = "Buy";
      }
      if (this.transaction_type_id == "3") {
        this.transaction_type_text = "Rent";
      }
    }
    if (this.propertytypeid == "1") {
      $('#bedroom_filter_div').show();
      $('#bathroom_filter_div').show();
    } else {
      $('#bedroom_filter_div').hide();
      $('#bathroom_filter_div').hide();
    }
    if (this.route.snapshot.queryParams.board_type !== undefined) {
      this.boardType = this.route.snapshot.queryParams.board_type;
      // this.icon.url = 'assets/img/school_pin.png';
    }
    this.bedroomList = [
      // { id: "", text: 'Any', isChecked: true },
      { id: 1, text: '1+', isChecked: false },
      { id: 2, text: '2+', isChecked: false },
      { id: 3, text: '3+', isChecked: false },
      { id: 4, text: '4+', isChecked: false },
      { id: 5, text: '5+', isChecked: false }
    ];
    if (this.route.snapshot.queryParams.bedrooms !== undefined) {
      $.each(this.route.snapshot.queryParams.bedrooms.split(','), (index, value) => {
        componentObj.bedroomList[value - 1].isChecked = true;
      });
    }
    this.bathroomList = [
      // { id: "", text: 'Any', isChecked: true },
      { id: 1, text: '1+', isChecked: false },
      { id: 2, text: '2+', isChecked: false },
      { id: 3, text: '3+', isChecked: false },
      { id: 4, text: '4+', isChecked: false },
      { id: 5, text: '5+', isChecked: false }
    ];
    if (this.route.snapshot.queryParams.bath !== undefined) {
      $.each(this.route.snapshot.queryParams.bath.split(','), (index, value) => {
        componentObj.bathroomList[value - 1].isChecked = true;
      });
    }
    this.projectStatusList = [
      { id: 'Under Construction', text: 'Under Construction', isChecked: false },
      { id: 'Pre Launch', text: 'Pre Launch', isChecked: false },
      { id: 'Ready Possession', text: 'Ready Possession', isChecked: false }
    ];
    if (this.route.snapshot.queryParams.projstatus !== undefined) {
      $.each(this.route.snapshot.queryParams.projstatus.split(','), (index, value) => {
        if (value == 'Under Construction') {
          componentObj.projectStatusList[0].isChecked = true;
        } else if (value == 'Pre Launch') {
          componentObj.projectStatusList[1].isChecked = true;
        } else if (value == 'Ready Possession') {
          componentObj.projectStatusList[2].isChecked = true;
        }
      });
    }
    this.getProjectsList('', '', 0);
    var data = { is_web_api: 1 };
    this.searchCritareaNameUpdate();
    if (this.route.snapshot.queryParams.locality_elocs !== undefined) {
      this.locality_elocs = this.route.snapshot.queryParams.locality_elocs;
    }

    // *** Start Get Property Amenities ***//
    this.ApiService.getAmenities(data).subscribe((apiResult: any) => {
      if (apiResult) {
        const store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox" for="hame' + value.id + '">' + value.title + '<input type="checkbox" name="homeamenties[]" id="hame' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $("#f_property_amenities").html(store);
        /*$("input[name='homeamenties[]'").change(function () {
          componentObj.searchboxapply();
        });*/
        if (this.route.snapshot.queryParams.amenitie_ids !== undefined) {
          $.each(this.route.snapshot.queryParams.amenitie_ids.split(','), (index, value) => {
            $("#hame" + value).prop("checked", true);
          });
        }
      }
    });
    // *** End Get Property Amenities ***//

    // *** Start Get Property Facing ***//
    this.ApiService.getFacing(data).subscribe((apiResult: any) => {
      if (apiResult) {
        var store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox" for="hfac' + value.id + '">' + value.title + '<input type="checkbox" name="homefacing[]" id="hfac' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $("#f_property_facing").html(store);
        /*$("input[name='homefacing[]'").change(function(){
          componentObj.searchboxapply();
        });*/
        if (this.route.snapshot.queryParams.facing_ids !== undefined) {
          $.each(this.route.snapshot.queryParams.facing_ids.split(','), (index, value) => {
            $("#hfac" + value).prop("checked", true);
          });
        }
      }
    });
    // *** Ends Get Property Facing ***//

    // *** Start Get Property Status ***//
    this.ApiService.getPropertyStatus(data).subscribe((apiResult: any) => {
      if (apiResult) {
        var storepropetystatus = [];
        $.each(apiResult.data, (index, value) => {
          storepropetystatus.push('<li><label class="custom_checkbox" for="pstatus' + value.id + '">' + value.title + '<input type="checkbox" name="property_status" id="pstatus' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $("#property_status").html(storepropetystatus);
        /*$("input[name='property_status']").change(function () {
          componentObj.searchboxapply();
        });*/
        if (this.route.snapshot.queryParams.property_status_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_status_id.split(','), (index, value) => {
            $("#pstatus" + value).prop("checked", true);
          });
        }
      }
    });
    // *** End Get Property Status ***//

    // *** Start Get Property Facts ***//
    this.ApiService.getallPropertyfacts(data).subscribe((apiResult: any) => {
      if (apiResult) {
        var storepropetyfacts = [];
        storepropetyfacts.push('<option value="0">Unit</option>');
        $.each(apiResult.data, (index, value) => {
          storepropetyfacts.push('<option value="' + value.id + '">' + value.title + '</option>');
        });
        $(".f_property_facts").html(storepropetyfacts);
        if (this.route.snapshot.queryParams.min_builtup_area !== undefined) {
          $("#min_builtup_area").val(this.route.snapshot.queryParams.min_builtup_area);
        }
        if (this.route.snapshot.queryParams.min_builtup_area_unit !== undefined) {
          $("#min_builtup_area_unit").val(this.route.snapshot.queryParams.min_builtup_area_unit);
        }
        if (this.route.snapshot.queryParams.max_builtup_area !== undefined) {
          $("#max_builtup_area").val(this.route.snapshot.queryParams.max_builtup_area);
        }
        if (this.route.snapshot.queryParams.max_builtup_area_unit !== undefined) {
          $("#max_builtup_area_unit").val(this.route.snapshot.queryParams.max_builtup_area_unit);
        }
        if (this.route.snapshot.queryParams.min_saleable_area !== undefined) {
          $("#min_saleable_area").val(this.route.snapshot.queryParams.min_saleable_area);
        }
        if (this.route.snapshot.queryParams.min_saleable_area_unit !== undefined) {
          $("#min_saleable_area_unit").val(this.route.snapshot.queryParams.min_saleable_area_unit);
        }
        if (this.route.snapshot.queryParams.max_saleable_area !== undefined) {
          $("#max_saleable_area").val(this.route.snapshot.queryParams.max_saleable_area);
        }
        if (this.route.snapshot.queryParams.max_saleable_area_unit !== undefined) {
          $("#max_saleable_area_unit").val(this.route.snapshot.queryParams.max_saleable_area_unit);
        }
      }
    });
    // *** End Get Property Facts ***//

    // *** Start Get Property Category ***//
    this.setPropertyCategoryList();
    // *** End Get Property Category ***//

    // *** Start Get Property Type Category ***//
    if (this.route.snapshot.queryParams.property_type_id !== undefined) {
      this.propertytypeid = this.route.snapshot.queryParams.property_type_id;
    }
    const propTypeCategory = { is_mobile_api: 1 };
    this.ApiService.getProjectTypes(propTypeCategory).subscribe((apiResult: any) => {
      if (apiResult) {
        this.projectTypeCategory = apiResult.data;
      }
    });
    // *** End Get Property Type Category ***//

    this.saveSearchForm = this.formBuilder.group({
      searchname: ['', [Validators.required]]
    });

    setTimeout(() => {
      var elocInput = $('#searchinput');
      elocInput.tagsinput({
        itemValue: 'eLoc',
        itemText: 'placeName',
        freeInput: true,
        confirmKeys: [13, 44],
        typeaheadjs: {
          displayKey: 'placeNameFull',
          source: (search, sync, async) => {
            if (search.length >= 2) {
              return $.ajax({
                type: 'GET',
                dataType: 'json',
                url: API_BASE_URL + '/master/getLocality',
                data: { is_web_api: 1, search: search },
                success: (response) => {
                  response = response.data;
                  return async(response);
                }
              });
            }
          }
        },
      });
      $('#searchinput').on('itemAdded', (event) => {
        componentObj.searchboxapply(0);
      });
      $('#searchinput').on('itemRemoved', (event) => {
        componentObj.searchboxapply(0);
        this.locality_elocs=''
      });
      if (this.locality_elocs !== undefined) {
        $.each(this.locality_elocs.split(','), (index, value) => {
          elocInput.tagsinput('add', { "placeNameFull": value, "placeName": value, "eLoc": value });
        });
      }
      $("#property_list_view").hide();
      this.changeHeaderClass();

      var amountStringMatcher = () => {
        return function findMatches(q, cb) {
          var matches = [];
          q = q.replace(/[^0-9.]+/g, "");
          q = q.replace(/\.+$/, "");
          matches.push({ 'label': q + ' Hund.', 'value': q * 100 });
          matches.push({ 'label': q + ' Th.', 'value': q * 1000 });
          matches.push({ 'label': q + ' Lc.', 'value': q * 100000 });
          matches.push({ 'label': q + ' Cr.', 'value': q * 10000000 });
          cb(matches);
        };
      };

      this.minBudgetAmount = $('#min_budget_amount');
      this.minBudgetAmount.typeahead(null, {
        name: 'minBudgetAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.minBudgetAmount.on('typeahead:selected', (event, data) => {
        componentObj.minBudgetAmountValue = data.value;
        componentObj.searchboxapply(0);
      });
      if (this.route.snapshot.queryParams.min_budget_amount !== undefined) {
        componentObj.minBudgetAmountValue = this.route.snapshot.queryParams.min_budget_amount;
        var amountText = "";
        if ((componentObj.minBudgetAmountValue / 10000000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 10000000) + " Cr.";
        } else if ((componentObj.minBudgetAmountValue / 100000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 100000) + " Lc.";
        } else if ((componentObj.minBudgetAmountValue / 1000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 1000) + " Th.";
        }
        $("#min_budget_amount").val(amountText);
        $('#clearBudgetFilter').show();
      }

      this.maxBudgetAmount = $('#max_budget_amount');
      this.maxBudgetAmount.typeahead(null, {
        name: 'maxBudgetAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.maxBudgetAmount.on('typeahead:selected', (event, data) => {
        componentObj.maxBudgetAmountValue = data.value;
        componentObj.searchboxapply(0);
      });
      if (this.route.snapshot.queryParams.max_budget_amount !== undefined) {
        componentObj.maxBudgetAmountValue = this.route.snapshot.queryParams.max_budget_amount;
        var amountText = "";
        if ((componentObj.maxBudgetAmountValue / 10000000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 10000000) + " Cr.";
        } else if ((componentObj.maxBudgetAmountValue / 100000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 100000) + " Lc.";
        } else if ((componentObj.maxBudgetAmountValue / 1000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 1000) + " Th.";
        }
        $("#max_budget_amount").val(amountText);
        $('#clearBudgetFilter').show();
      }
    }, 200);
    if (this.isLoggedIn) {
      var userInfo = JSON.parse(localStorage.getItem('currentUser'));
      this.first_name = userInfo.first_name;
      this.last_name = userInfo.last_name;
      this.email = userInfo.email;
      this.phone = userInfo.contact_no;
    }
    this.leadForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      profileEmail: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
    });
    fromEvent(window, "scroll").subscribe((event) => {
      const footerDiv = document.getElementById("footer");
      const scrollPos = footerDiv.offsetTop;
      if (this.document.defaultView.scrollY < scrollPos) {
        this.enableabsoluteMap = false;
        this.enableSticky = true;
      }
      else if (this.document.defaultView.scrollY > scrollPos) {
        this.enableSticky = false;
        this.enableabsoluteMap = true;
      }
      else {
        this.enableSticky = false;
        this.enableabsoluteMap = false;
      }
    });
  }

  openProjectInNewTab(id) {
    window.open(WEB_URL + '/luxury-project-details/' + id, '_blank')
  }

  searchProperty(){
    if (!this.locality_elocs) {
      this.FlashService.error('Please enter valid location');
      return
    }
  }

  getDiraction(lat, lng) {
    let url = 'https://maps.google.com/' + '?' + 'daddr' + '=' + lat + ',' + lng
    window.open(url, '_blank');
  }

  mapReady(map) {
    this.propMap = map;
  }

  onScroll(): void {
    /*if (this.loadmore && !this.isLoading) {
      this.isLoading = true;
      this.currentPage = this.currentPage + 1;
      this.getProjectsOnScroll();
    }*/
  }

  ngOnDestroy(): void {

  }

  searchCritareaNameUpdate() {
    this.name = "";
    if (this.route.snapshot.queryParams.locality_elocs !== undefined) {
      this.locality_elocs = this.route.snapshot.queryParams.locality_elocs;
      this.name = this.locality_elocs;
    }

    if (this.propertytypeid !== undefined) {
      if (this.propertytypeid == "1" || this.propertytypeid == "3") {
        this.name = this.property_type_text + " Projects for Sale";
      } else if (this.propertytypeid == "4") {
        this.name = this.property_type_text + ' Projects Land for Sale';
      } else if (this.propertytypeid == "5") {
        this.name = this.property_type_text + ' Projects for Investment';
      } else if (this.propertytypeid == "6") {
        this.name = this.property_type_text + ' Projects for Sale';
      } else if (this.propertytypeid == "7") {
        this.name = this.property_type_text + ' Projects for Sale';
      }
    }
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Luxurious Projects');
    this.meta.updateTag({ name: 'description', content: 'Find ' + this.name + ' on empirerealtors.com. Verified Listings, Map View, Price Trends, Offers, Photos & Amenities.' });
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    var position = new google.maps.LatLng(this.lat, this.lng);
    this.propMap.panTo(position);
  }

  addMarker(markerN) {
    this.markers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  clickedMarker(pid: string) {
    this.router.navigate(['luxury-project-details', pid]);
  }

  activeView: boolean = true

  showgridviewfunction() {
    this.activeView = true
    $(() => {
      // $('.active_view').removeClass('active_view');
      // $('.grid_btn').addClass('active_view');
      $('#property_grid_view').show();
      $('#property_list_view').hide();
    });
    var data = {};
    data = this.getQueryData();
    data['page_no'] = this.pageConfig.currentPage;
    data['limit'] = 10;
    this.router.navigate(['/luxury-projects'], { queryParams: data });
    setTimeout(() => {
      this.getProjectsList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  showlistviewfunction() {
    this.activeView = false
    $(() => {
      // $('.active_view').removeClass('active_view');
      // $('.list_btn').addClass('active_view');
      $('#property_grid_view').hide();
      $('#property_list_view').show();
    });
    var data = {};
    data = this.getQueryData();
    data['page_no'] = this.pageConfig.currentPage;
    data['limit'] = 10;
    this.router.navigate(['/luxury-projects'], { queryParams: data });
    setTimeout(() => {
      this.getProjectsList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  getProjectsList(NorthWest, SouthEst, is_modal) {
    if (this.route.snapshot.queryParams.locality_elocs !== this.prevLocality_elocs) {
      this.pageConfig.currentPage = 1
    }
    this.prevLocality_elocs = this.route.snapshot.queryParams.locality_elocs
    this.searchCritareaNameUpdate();
    var form_data = new FormData();
    var page_no = this.pageConfig.currentPage;
    for (var i in this.route.snapshot.queryParams) {
      var param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'project');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECTS, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        var propCattxt = "";
        if (this.route.snapshot.queryParams.property_category_id) {
          var propCat = this.route.snapshot.queryParams.property_category_id;
          propCat = propCat.split(',');
          $(this.propertyCategoryList).each((k, v) => {
            for (var i = 0; i <= propCat.length; i++) {
              if (v.id == propCat[i]) {
                propCattxt = propCattxt != "" ? propCattxt + ', ' + v.title : v.title;
              }
            }
          });
        }
        propCattxt = propCattxt != "" ? propCattxt : "Projects";
        if (apiResult.data.paginate.Project.count > 0 && apiResult.data.list.length > 0) {
          this.isProject = true;
          this.total_props_s = this.total_props_s = apiResult.data.paginate.Project.count + " " + propCattxt;
          if (is_modal === 1) {
            $('#property_grid_view').show();
          }
        } else {
          this.isProject = false;
          if (is_modal === 1) {
            $('#lead_generator_div').show();
          }
        }
        this.loadmore = apiResult.data.paginate.Project.nextPage;
        this.currentPage = 1;
        this.pageConfig.totalItems = apiResult.data.paginate.Project.count;
        this.projectsList = apiResult.data.list;
        if (apiResult.data.paginate.Project.count === 0) {
          if (apiResult.data.lat_lng) {
            this.lat = apiResult.data.lat_lng.lat;
            this.lng = apiResult.data.lat_lng.lng;
          }
          this.name = (this.projectsList || []).length > 0 ? 'We are working on your search criteria, please fill the below form, our team will get back to you soon' : 'No projects found';

        }
        this.ref.detectChanges();
        if (this.boardType) {
          $.each(apiResult.data.schoolData, (index, value) => {
            if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
              componentObj.lat = value.locality_lat;
              componentObj.lng = value.locality_lng;
              componentObj.changeLatLng(componentObj.lat, componentObj.lng);
              debugger
              var markerN = {
                pid: value.id,
                lat: value.locality_lat,
                lng: value.locality_lng,
                symbol: {
                  url: 'assets/img/school_pin.png',
                  scaledSize: {
                    width: 30,
                    height: 49
                  }
                },
                draggable: false,
                // image_preview: value.image_preview,
                locality_title: value.school_name,
                status: (value?.project_status || 'Available'),
                address: ((value.address_line_1 || '') + ' ' + (value.address_line_2 || '') || value.city_title)

                // asking_price: value.asking_price_in_word
              };
              componentObj.addMarker(markerN);
            }
          });
        }
        this.isZoomFlag = false;
        this.isLoading = false;
        if (this.isLoggedIn) {
          this.getProjectFavList('', '', 0);
        }
        this.getProjectMapIcons('', '', 0);
      } else {
        this.loadmore = false;
        this.currentPage = 1;
        this.isLoading = false;
        this.total_props_s = "0 Project";
        this.isZoomFlag = false;
        this.ref.detectChanges();
      }
    });
  }

  getProjectFavList(NorthWest, SouthEst, is_modal) {
    var form_data = new FormData();
    var page_no = this.pageConfig.currentPage;
    for (var i in this.route.snapshot.queryParams) {
      var param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'fav_icon');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECTS, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        $.each(apiResult.data.list, (index, value) => {
          if (value.is_favorited == 1) {
            $('#i_favIcon_' + value.id).removeClass('fav_white_color');
            $('#i_favIcon_' + value.id).addClass('fav_red_color');
          }
        });
      }
    });
  }

  getProjectMapIcons(NorthWest, SouthEst, is_modal) {
    var form_data = new FormData();
    var page_no = this.pageConfig.currentPage;
    for (var i in this.route.snapshot.queryParams) {
      var param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    // form_data.append('page', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'project');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECTS, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        $.each(apiResult.data.list, (index, value) => {
          componentObj.icon.url = 'assets/img/er_marker.png';
          if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
            componentObj.lat = value.locality_lat;
            componentObj.lng = value.locality_lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            if (value.project_status === 'Under Construction') {
              componentObj.icon.url = 'assets/img/er_prj_cons_icon.png';
            } else if (value.project_status === 'Pre Launch') {
              componentObj.icon.url = 'assets/img/er_prj_prelaunch_icon.png';
            } else if (value.project_status === 'Ready Possession') {
              componentObj.icon.url = 'assets/img/er_prj_ready_icon.png';
            }
            var markerN = {
              pid: value.id,
              lat: value.locality_lat,
              lng: value.locality_lng,
              symbol: {
                url: componentObj.icon.url,
                scaledSize: {
                  width: 30,
                  height: 49
                }
              },
              draggable: false,
              image_preview: value.image_preview,
              locality_title: value.name,
              asking_price: value.asking_price_in_word,
                status: (value?.project_status || 'Available'),
                address: ((value.address_line_1 || '') + ' ' + (value.address_line_2 || '') || value.city_title)

            };
            componentObj.addMarker(markerN);
          }
        });
      }
    });
  }

  getProjectsOnScroll() {
    const componentObj = this;
    const form_data = new FormData();
    for (const i in this.route.snapshot.queryParams) {
      const param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECTS + "?page=" + this.currentPage, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        let propCattxt = "";
        if (this.route.snapshot.queryParams.property_category_id) {
          let propCat = this.route.snapshot.queryParams.property_category_id;
          propCat = propCat.split(',');
          $(this.propertyCategoryList).each((k, v) => {
            for (var i = 0; i <= propCat.length; i++) {
              if (v.id == propCat[i]) {
                propCattxt = propCattxt != "" ? propCattxt + ', ' + v.title : v.title;
              }
            }
          });
        }
        propCattxt = propCattxt != "" ? propCattxt : "Projects";
        this.isLoading = false;
        this.loadmore = apiResult.data.paginate.Project.nextPage;
        this.projectsList = this.projectsList.concat(apiResult.data.list);
        this.ref.detectChanges();
        this.total_props_s = apiResult.data.paginate.Project.count + " " + propCattxt;
        if (this.boardType) {
          $.each(apiResult.data.list, (index, value) => {
            if (componentObj.lat == 51.673858 && componentObj.lng == 7.815982 && value.locality_lat != "") {
              componentObj.lat = value.locality_lat;
              componentObj.lng = value.locality_lng;
              componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            }
            if (value.locality_lat != "" && value.locality_lng != "") {
              // componentObj.icon.url = 'assets/img/school_pin.png';
              const markerN = {
                pid: value.id,
                lat: value.locality_lat,
                lng: value.locality_lng,
                symbol: {
                  url: 'assets/img/school_pin.png',
                  scaledSize: {
                    width: 30,
                    height: 49
                  }
                },
                draggable: false,
                // image_preview: value.image_preview,
                locality_title: value.locality_title,
                // asking_price: value.asking_price_in_word
              };
              componentObj.addMarker(markerN);
            }
          });
        }
        $.each(apiResult.data.list, (index, value) => {
          if (componentObj.lat == 51.673858 && componentObj.lng == 7.815982 && value.locality_lat != "") {
            componentObj.lat = value.locality_lat;
            componentObj.lng = value.locality_lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
          }
          if (value.locality_lat != "" && value.locality_lng != "") {
            // componentObj.icon.url = 'assets/img/er_marker.png';
            const markerN = {
              pid: value.id,
              lat: value.locality_lat,
              lng: value.locality_lng,
              symbol: {
                url: 'assets/img/er_marker.png',
                scaledSize: {
                  width: 30,
                  height: 49
                }
              },
              draggable: false,
              image_preview: value.image_preview,
              locality_title: value.name,
              asking_price: value.asking_price_in_word
            };
            componentObj.addMarker(markerN);
          }
        });
      } else {
        this.loadmore = false;
        this.currentPage = 1;
        this.isLoading = false;
        this.total_props_s = "0 Property";
        this.ref.detectChanges();
      }
    });
  }

  // tslint:disable-next-line:variable-name
  searchboxapply(is_modal) {
    if (!this.isLoading) {
      var data = {};
      data = this.getQueryData();
      this.setPropertyCategoryList();
      this.router.navigate(['/luxury-projects'], { queryParams: data });
      setTimeout(() => {
        this.getProjectsList('', '', is_modal);
        this.ref.detectChanges();
        if (is_modal === 1) {
          $('#filterBtn').addClass('collapsed');
          $('#moreFilter').removeClass('show');
          $('#footer').show();
          this.moreFilter = 'close';
        }
        // this.showHideResetBtn = true;
      }, 1000);
    }
  }

  getQueryData() {
    $('#clearBudgetFilter').hide();
    this.projectsList = [];
    this.isLoading = true;
    this.total_props_s = "";
    this.lat = 22.295492;
    this.lng = 73.173463;
    this.markers = [];
    var data = {};

    if (this.transaction_type_id !== undefined && this.transaction_type_id !== "") {
      data['transaction_type_id'] = this.transaction_type_id;
    }
    if (this.suitable_for_id !== undefined) {
      data['suitable_for_id'] = this.suitable_for_id;
    }
    if (this.propertytypeid !== undefined) {
      data['property_type_id'] = this.propertytypeid;
      if (this.propertytypeid === '1') {
        this.property_type_text = 'Residential';
      } else if (this.propertytypeid === '3') {
        this.property_type_text = 'Commercial';
      } else if (this.propertytypeid === '4') {
        this.property_type_text = 'Land';
      } else if (this.propertytypeid === '7') {
        this.property_type_text = 'Mix';
      }
    }
    if (this.transaction_type_id == "4") {
      this.transaction_type_text = "Buy";
    }
    if (this.transaction_type_id == "3") {
      this.transaction_type_text = "Rent";
    }

    var beds = [];
    if (this.propertytypeid === '1') {
      $('#bedroom_filter_div').show();
      $.each(this.bedroomList, (index, val) => {
        if (val.isChecked && val.id !== "") {
          beds.push(val.id);
        }
      });
      if (beds.length != 0) {
        data['bedrooms'] = beds.join(",");
      }
    } else {
      $('#bedroom_filter_div').hide();
    }

    var projstatus = [];
    $.each(this.projectStatusList, (index, val) => {
      if (val.isChecked && val.id !== "") {
        projstatus.push(val.id);
      }
    });
    if (projstatus.length != 0) {
      data['projstatus'] = projstatus.join(",");
    }

    var baths = [];
    if (this.propertytypeid === '1') {
      $('#bathroom_filter_div').show();
      $.each(this.bathroomList, (index, val) => {
        if (val.isChecked && val.id !== "") {
          baths.push(val.id);
        }
      });
      if (baths.length != 0) {
        data['bath'] = baths.join(",");
      }
    } else {
      $('#bathroom_filter_div').hide();
    }

    var property_category = [];
    $("input[name='property_type[]']:checked").each((index, val) => {
      property_category.push($(val).val());
    });
    if (property_category.length != 0) {
      data['property_category_id'] = property_category.join(",");
    }

    var property_status = [];
    $("input[name='property_status']:checked").each((i, val) => {
      property_status.push($(val).val());
    });
    if (property_status.length != 0) {
      data['property_status_id'] = property_status.join(",");
    }

    var amenities = new Array();
    $("input[name='homeamenties[]']:checked").each((index, val) => {
      amenities.push($(val).val());
    });
    if (amenities.length != 0) {
      data['amenitie_ids'] = amenities.join(",");
    }
    if ($("#min_builtup_area").val() != "0" && $("#min_builtup_area").val() != "") {
      data['min_builtup_area'] = $("#min_builtup_area").val();
    }
    if ($("#min_builtup_area_unit").val() != "0" && $("#min_builtup_area_unit").val() != "") {
      data['min_builtup_area_unit'] = $("#min_builtup_area_unit").val();
    }
    if ($("#max_builtup_area").val() != "0" && $("#max_builtup_area").val() != "") {
      data['max_builtup_area'] = $("#max_builtup_area").val();
    }
    if ($("#max_builtup_area_unit").val() != "0" && $("#max_builtup_area_unit").val() != "") {
      data['max_builtup_area_unit'] = $("#max_builtup_area_unit").val();
    }
    if ($("#min_saleable_area").val() != "0" && $("#min_saleable_area").val() != "") {
      data['min_saleable_area'] = $("#min_saleable_area").val();
    }
    if ($("#min_saleable_area_unit").val() != "0" && $("#min_saleable_area_unit").val() != "") {
      data['min_saleable_area_unit'] = $("#min_saleable_area_unit").val();
    }
    if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area'] = $("#max_saleable_area").val();
    }
    if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area_unit'] = $("#max_saleable_area_unit").val();
    }
    if ($('#searchinput').tagsinput('items') && $('#searchinput').tagsinput('items').length) {
      data['locality_elocs'] = ($('#searchinput').tagsinput('items').map(value => value.eLoc)).join(',');
    }
    if (this.minBudgetAmountValue !== undefined) {
      data['min_budget_amount'] = this.minBudgetAmountValue;
      $('#clearBudgetFilter').show();
    }
    if (this.maxBudgetAmountValue !== undefined) {
      data['max_budget_amount'] = this.maxBudgetAmountValue;
      $('#clearBudgetFilter').show();
    }
    return data;
  }

  prepareSearchJson() {
    this.projectsList = [];
    this.isLoading = true;
    this.total_props_s = "";
    this.lat = 22.295492;
    this.lng = 73.173463;
    this.markers = [];
    var data = {};

    if (this.transaction_type_id !== undefined && this.transaction_type_id !== "") {
      data['transaction_type_id'] = this.transaction_type_id;
    }
    if (this.propertytypeid !== undefined) {
      data['property_type_id'] = this.propertytypeid;
      if (this.propertytypeid === '1') {
        this.property_type_text = 'Residential';
      } else if (this.propertytypeid === '3') {
        this.property_type_text = 'Commercial';
      } else if (this.propertytypeid === '4') {
        this.property_type_text = 'Land';
      } else if (this.propertytypeid === '7') {
        this.property_type_text = 'Mix';
      }
    }
    if (this.transaction_type_id == "4") {
      this.transaction_type_text = "Buy";
    }
    if (this.transaction_type_id == "3") {
      this.transaction_type_text = "Rent";
    }

    var beds = [];
    $.each(this.bedroomList, (index, val) => {
      if (val.isChecked && val.id !== "") {
        beds.push(val.id);
      }
    });
    if (beds.length != 0) {
      data['bedrooms'] = beds.join(",");
    }

    var baths = [];
    $.each(this.bathroomList, (index, val) => {
      if (val.isChecked && val.id !== "") {
        baths.push(val.id);
      }
    });
    if (baths.length != 0) {
      data['bath'] = baths.join(",");
    }

    var property_category = [];
    $("input[name='property_type[]']:checked").each((i) => {
      property_category.push($(this).val());
    });
    if (property_category.length != 0) {
      data['property_category_id'] = property_category.join(",");
    }

    var property_status = [];
    $("input[name='property_status']:checked").each((i) => {
      property_status.push($(this).val());
    });
    if (property_status.length != 0) {
      data['property_status_id'] = property_status.join(",");
    }

    var amenities = new Array();
    $("input[name='homeamenties[]']:checked").each((i) => {
      amenities.push($(this).val());
    });
    if (amenities.length != 0) {
      data['amenitie_ids'] = amenities.join(",");
    }
    if ($("#min_builtup_area").val() != "0" && $("#min_builtup_area").val() != "") {
      data['min_builtup_area'] = $("#min_builtup_area").val();
    }
    if ($("#min_builtup_area_unit").val() != "0" && $("#min_builtup_area_unit").val() != "") {
      data['min_builtup_area_unit'] = $("#min_builtup_area_unit").val();
    }
    if ($("#max_builtup_area").val() != "0" && $("#max_builtup_area").val() != "") {
      data['max_builtup_area'] = $("#max_builtup_area").val();
    }
    if ($("#max_builtup_area_unit").val() != "0" && $("#max_builtup_area_unit").val() != "") {
      data['max_builtup_area_unit'] = $("#max_builtup_area_unit").val();
    }
    if ($("#min_saleable_area").val() != "0" && $("#min_saleable_area").val() != "") {
      data['min_saleable_area'] = $("#min_saleable_area").val();
    }
    if ($("#min_saleable_area_unit").val() != "0" && $("#min_saleable_area_unit").val() != "") {
      data['min_saleable_area_unit'] = $("#min_saleable_area_unit").val();
    }
    if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area'] = $("#max_saleable_area").val();
    }
    if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area_unit'] = $("#max_saleable_area_unit").val();
    }
    if ($('#searchinput').tagsinput('items') && $('#searchinput').tagsinput('items').length) {
      data['locality_elocs'] = ($('#searchinput').tagsinput('items').map(value => value.eLoc)).join(',');
    }
    if (this.minBudgetAmountValue !== undefined) {
      data['min_budget_amount'] = this.minBudgetAmountValue;
    }
    if (this.maxBudgetAmountValue !== undefined) {
      data['max_budget_amount'] = this.maxBudgetAmountValue;
    }
    this.json_string = JSON.stringify(data);
  }

  setPropertyCategoryList() {
    var componentObj = this;
    var propCatData = { is_mobile_api: 1, property_type_id: this.propertytypeid, is_for_project: 1 };
    this.ApiService.getPropertyCategory(propCatData).subscribe((apiResult: any) => {
      if (apiResult) {
        this.propertyCategoryList = apiResult.data;
        var store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox" for="ptype' + value.id + '">' + value.title + '<input type="checkbox" name="property_type[]" id="ptype' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $("#f_property_category").html(store);
        /*$("input[name='property_type[]'").change(() => {
          componentObj.searchboxapply(0);
        });*/
        if (this.route.snapshot.queryParams.property_category_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_category_id.split(','), (index, value) => {
            $('#ptype' + value).prop('checked', true);
          });
        }
      }
    });
  }

  clearFilter() {
    if (!this.isLoading) {
      this.isLoading = true;
      var data = {};
      if (this.route.snapshot.queryParams.locality_elocs !== undefined) {
        data['locality_elocs'] = this.route.snapshot.queryParams.locality_elocs;
      }

      if (this.transaction_type_id !== undefined) {
        data['transaction_type_id'] = this.transaction_type_id;
      }
      if (this.suitable_for_id !== undefined) {
        data['suitable_for_id'] = this.suitable_for_id;
      }
      if (this.propertytypeid !== undefined) {
        data['property_type_id'] = this.propertytypeid;
      }
      this.router.navigate(['/luxury-projects'], { queryParams: data });
      setTimeout(() => {
        // this.getPropertyList('', '');
        // this.ref.detectChanges();
        window.location.reload();
      }, 200);
    }
  }

  clearBudgetFilter() {
    this.minBudgetAmountValue = null;
    $("#min_budget_amount").val('');
    this.maxBudgetAmountValue = null;
    $("#max_budget_amount").val('');
    this.searchboxapply(0);
    $('#clearBudgetFilter').hide();
  }

  onLeadFormSubmit() {
    this.submitted = true;
    if (this.leadForm.invalid) {
      return;
    }
    $('#submitProjLead').hide();
    $('.loaderBtn').show();
    var form_data = new FormData();
    form_data.append('first_name', this.first_name);
    form_data.append('last_name', this.last_name);
    form_data.append('contact_no', this.phone);
    form_data.append('email', this.email);
    form_data.append('property_type_id', this.propertytypeid);
    form_data.append('locality_elocs', this.locality_elocs);
    this.ApiService.postRequest(API_ENDPOINTS.API_ADD_PROJECT_LEAD, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success(apiResult.message);
      } else {
        this.FlashService.error(apiResult.message);
      }
      $('.loaderBtn').hide();
    }, error => {
      this.FlashService.error("Something wrong!");
      $('.loaderBtn').hide();
    });
  }

  showHideFilter() {
    this.activeView = true
    if (this.moreFilter === 'close' || this.moreFilter === undefined) {
      $('#filterBtn').removeClass('collapsed');
      $('#moreFilter').addClass('show');
      $('#property_grid_view').hide();
      $('#property_list_view').hide();
      $('#lead_generator_div').hide();
      $('#footer').hide();
      this.moreFilter = 'open';
    } else {
      $('#filterBtn').addClass('collapsed');
      $('#moreFilter').removeClass('show');
      if (this.isProject === false) {
        $('#lead_generator_div').show();
      } else {
        $('#property_grid_view').show();
      }
      $('#footer').show();
      this.moreFilter = 'close';
    }
  }

  makeFavorite(pid, status, franchisee_id) {
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('id', pid);
      form_data.append('status', "1");
      form_data.append('locality_elocs', this.locality_elocs);
      form_data.append('transaction_type_id', this.transaction_type_id);
      form_data.append('franchisee_id', franchisee_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_FAVORITE, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          if ($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
            this.FlashService.success('Luxury Project is removed from your Wishlist.')
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
            this.FlashService.success('Luxury Project is added to your Wishlist.')
          }
        }
      });
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  pageChange(newPage: number, itemsPerPage) {
    this.pageConfig.currentPage = newPage;
    this.pageConfig.itemsPerPage = itemsPerPage;
    var data = {};
    data = this.getQueryData();
    data['page_no'] = newPage;
    data['limit'] = itemsPerPage;
    this.router.navigate(['/luxury-projects'], { queryParams: data });
    setTimeout(() => {
      this.getProjectsList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  zoomChange(e: any) {
    if (this.isZoomFlag === false) {
      this.isLoading = true;
      const tmpMap = this.propMap;
      const bounds = tmpMap.getBounds();
      const NorthWest: any = [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()];
      const SouthEst: any = [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()];
      this.getProjectsList(NorthWest, SouthEst, 0);
    }
  }

  openFilterPropertyModal(): void {
    $('#filterPropertyModal').modal('toggle');
  }

  toggleMapView() {
    $('.show-map').hide();
    $('.show-list').show();
    $('.map-view-main-outer').show();
    $('.content-view-main-outer').hide();
  }

  toggleListView() {
    $('.show-map').show();
    $('.show-list').hide();
    $('.map-view-main-outer').hide();
    $('.content-view-main-outer').show();
  }

  openSaveSearchPopup(): void {
    this.getSavedSearches();
    if ($('.saveSearchPopup').hasClass('savedsearchactive')) {
      $('.saveSearchPopup').removeClass('savedsearchactive');
    } else {
      $('.saveSearchPopup').addClass('savedsearchactive');
    }
  }

  getSavedSearches() {
    // this.prepareSearchJson();
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        $.each(apiResult.data, (index, value) => {
          if (value.json_string === componentObj.json_string) {
            componentObj.searchnameval = value.name;
          }
        });
      }
    });
  }

  hideSearchBox() {
    $('.saveSearchPopup').removeClass('savedsearchactive');
  }

  onSaveSearch() {
    this.submitted = true;
    if (this.saveSearchForm.invalid) {
      return;
    }
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var data = {};
      data = this.getQueryData();
      this.json_string = JSON.stringify(data);
      var form_data = new FormData();
      form_data.append('json_string', this.json_string);
      form_data.append('name', this.searchnameval);
      form_data.append('source', 'projects');
      this.ApiService.postRequest(API_ENDPOINTS.API_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          this.router.navigate(['/savedsearch']);
        }
      });
    }
  }

  viewSaveSearched() {
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      // const querType = [];
      // querType['type'] = 'Buyer';
      this.router.navigate(['/savedsearch'])
    }
  }

}



interface marker {
  pid?: string;
  lat: number;
  lng: number;
  label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
  symbol?: object;
  draggable: boolean;
}
