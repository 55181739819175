<div class="d-flex single_property_grid rupal" *ngIf="(propertyData.asking_price) && (propertyData.property_name || propertyData.building || propertyData.street || propertyData.address_line_1 || propertyData.address_line_2 || propertyData.locality_title || propertyData.City)">
    <div class="card">
      <div class="grid_image_view" [style.background-image]="'url(\'' + propertyData.image_preview + '\')'">
        <div class="property_badge top_left" *ngIf="propertyData.prop_transaction_type">
          <span class="property_badge_sell">{{propertyData.prop_transaction_type.title}}</span>
          <span *ngIf="propertyData.is_coming_soon == 1" class="property_badge_sell">Coming Soon</span>                    
        </div>
        <div class="property_status top_left">
          <span *ngIf="propertyData.property_status_id == 2 || propertyData.property_status_id == 5" class="property_status_title">{{propertyData.prop_status.title}}</span>
        </div>
        <div class="property_badge top_right favoriteIcon_{{propertyData.id}}" [ngClass]="{ 'is_favorite_prop': propertyData.is_favorited == 1 }" *ngIf="propertyData.prop_transaction_type" (click)="makeFavorite(propertyData.id, propertyData.is_favorited)">
          <i class="fa fa-heart" *ngIf="propertyData.is_favorited == 0" style="color: #FFFFFF;"></i>
          <i class="fa fa-heart" *ngIf="propertyData.is_favorited == 1" style="color: #F02174;"></i>
        </div>
      </div>
      <div class="card-body" [routerLink]="['/property-details', propertyData.id ]">
        <h5 class="card-title"><i class="fa fa-rupee"></i>{{propertyData.asking_price}}</h5>
        <p class="card-text">
          {{propertyData.property_name}} {{propertyData.locality_title}} {{(propertyData.City ? propertyData.city.city : '')}}
        </p>
        <p class="card-text">
          <span class="pro_spec" *ngIf="propertyData.total_washrooms"> <i class="fa fa-bath"></i> {{propertyData.total_washrooms}} Bath </span>
          <span class="pro_spec" *ngIf="propertyData.total_bedrooms"> <i class="fa fa-bed"></i> {{propertyData.total_bedrooms}} Bed </span>
          <span class="pro_spec" *ngIf="propertyData.saleable_area"> <i class="fa fa-check-square"></i> {{propertyData.saleable_area}} {{(propertyData.sale_area_unit ? propertyData.sale_area_unit.title : '')}} </span>
        </p>
      </div>
    </div>
  </div>