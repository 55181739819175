<div class="alert-success" *ngIf="isSuccess">
  <span>Thanks for contacting us! We will be in touch with you shortly.</span>
  <span class="close" (click)="isSuccess=false">&times;</span>
</div>
<div class="alert-error" *ngIf="isFailed">
  <span>Something went wrong! Please try again.</span>
  <span class="close" (click)="isFailed=false">&times;</span>
</div>


<div class="container-fluid" id="buyFranchiseDiv">
    <ng-container >
      <div class="loading_div" *ngIf="stopLoader">
        <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
      </div>
    </ng-container>
    <div class="row">
      <div class="col-md-6">
        <div id="div_franch_img">

            <ul  class="info_sec">
                <li><i class="fa fa-map-marker"  ></i> 406 - The Baroda Crossway, Opp Police <div class="sec_address"> Bhavan , Jail Rd Vadodara Gujarat - India</div></li>
                <li><i class="fa fa-envelope"></i> info@empirerealtors.in</li>
                <li><i class="fa fa-phone"></i>+91 8866550550</li>
                <li><i class="fa fa-clock-o"></i>Mon - Sun 9:00 AM to 8:00 PM</li>
              </ul>
            

        </div>
      </div>
      <div class="col-md-6">
        <div class="page-head">
          <h2 class="text-center">Contact Us</h2>
          <!-- <app-flash></app-flash> -->
        </div>
        <div class="lead_generator_main" [formGroup]="contactForm">
          <div class="contact-info-outer">
            <div class="row d-flex">
              <div class="col-md-6">
                <div class="form-group">
                  <input  formControlName="firstName" class="form-control" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && g.firstName.errors }" />
                  <div *ngIf="submitted && g.firstName.errors" class="invalid-feedback">
                    <div *ngIf="g.firstName.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input  formControlName="lastName" class="form-control" placeholder="Last Name"  [ngClass]="{ 'is-invalid': submitted && g.lastName.errors }"/>
                  <div *ngIf="submitted && g.lastName.errors" class="invalid-feedback">
                    <div *ngIf="g.lastName.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="email"  formControlName="email" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && g.email.errors }" />
                  <div *ngIf="submitted && g.email.errors" class="invalid-feedback">
                    <div *ngIf="g.email.errors.required">Email is required</div>
                    <div *ngIf="g.email.errors.email">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input  formControlName="phone" class="form-control" [pattern]="mobNumberPattern" placeholder="Mobile Number"  [ngClass]="{ 'is-invalid': submitted && g.phone.errors }"/>
                  <div *ngIf="submitted && g.phone.errors" class="invalid-feedback">
                    <div *ngIf="g.phone.errors.required">Mobile Number is required</div>
                    <div *ngIf="g.phone.errors.pattern || g.phone.errors.minlength || g.phone.errors.maxlength">Please enter valid Mobile Number</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                    <textarea class="form-control" placeholder="Your Message" id="exampleFormControlTextarea1" rows="3" formControlName="message" [ngClass]="{ 'is-invalid': submitted && g.message.errors }"></textarea>
                  <div *ngIf="submitted && g.message.errors" class="invalid-feedback">
                    <div *ngIf="g.message.errors.required">Message is required</div>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div class="save-button">
            <div class="row d-flex">
              <div class="col-md-3">&nbsp;</div>
              <div class="col-md-6">
                <div class="form-group">
                  <button type="submit" id="submitInquiry" class="btn btn-block login-btn" (click)="onSubmit()">Send Your Details</button>
                  <button type="button" class="btn btn-block login-btn loaderBtn" disabled style="display: none;">
                    <span class="spinSpan spinner-border" role="status"></span> Saving
                  </button>
                </div>
              </div>
              <div class="col-md-3">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  