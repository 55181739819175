/* tslint:disable:max-line-length no-shadowed-variable object-literal-shorthand object-literal-key-quotes quotemark no-string-literal triple-equals variable-name forin */
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from './../../_services/api.service';
import { API_BASE_URL, API_ENDPOINTS, WEB_URL } from '../../global';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Meta, Title } from '@angular/platform-browser';
import { FlashService } from '../../_services/FlashService.service';
import { fromEvent } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { LatLngBounds, LatLngLiteral } from '@agm/core/services/google-maps-types';
// import {getDecoratorPropertyInitializer} from "codelyzer/util/utils";

declare var $: any;
declare const require: any;
declare var google: any;

@Component({
  selector: 'app-luxury-properties',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './luxury-properties.component.html',
  styleUrls: ['./luxury-properties.component.scss']
})
export class LuxuryPropertiesComponent implements OnInit {

  submitted = false;
  saveSearchForm: FormGroup;
  leadForm: FormGroup;
  first_name = '';
  last_name = '';
  email = '';
  phone = '';
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  propertyList: any = [];
  error;
  searchnameval = 'My Search';
  json_string = '';
  name = '';
  total_props_s = '';
  locality_elocs;
  currentPage = 1;
  loadmore = false;
  isLoading = true;
  isPropperty = false;
  zoom = 13;
  lat = 22.295492;
  lng = 73.173463;
  markers: marker[] = [];
  budgetList;
  icon = {
    url: 'assets/img/er_marker.png',
    scaledSize: {
      width: 30,
      height: 49
    }
  };
  isLoggedIn;
  propMap: any;

  propertytypeid = '';
  transaction_type_id = '';
  boardType = '';
  transaction_type_text = '';
  property_type_text = '';
  transactionTypeList;
  bedroomList;
  bathroomList;
  facingList;
  propertyAgeList;
  property_category_id = '';
  suitable_for_id = '';
  propertyCategoryList;
  propertyTypeCategory;
  propertyDimensionList;
  furnishingList;
  landCategoryList;
  landFeaturesList;

  minBudgetAmount;
  minBudgetAmountValue;
  maxBudgetAmount;
  maxBudgetAmountValue;
  suggestedProperties: any = [];
  moreFilter = 'close';
  enableSticky = false;
  enableabsoluteMap = false;
  pageConfig: any;
  isZoomFlag = false;
  showHideResetBtn = false;
  isLuxuryProperty = 1;
  prevLocality_elocs: string;

  constructor(private ApiService: ApiService, private ref: ChangeDetectorRef, private router: Router,  private route: ActivatedRoute, private formBuilder: FormBuilder, private title: Title, private meta: Meta, private FlashService: FlashService, @Inject(DOCUMENT) private document: Document) {
    this.pageConfig = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      id: 'property_grid'
    };
    if (this.route.snapshot.queryParams.page_no !== undefined) {
      this.pageConfig.currentPage = this.route.snapshot.queryParams.page_no;
    }
    if (this.route.snapshot.queryParams.limit !== undefined) {
      this.pageConfig.itemsPerPage = this.route.snapshot.queryParams.limit;
    }
  }

  get g() {
    return this.saveSearchForm.controls;
  }

  get l() {
    return this.leadForm.controls;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').addClass('header-fixed');
    $('#header .container-fluid').addClass('header-container-fluid');
    $('.footer-bottom').hide();
    const componentObj = this;
    if (this.route.snapshot.queryParams.property_type_id !== undefined) {
      this.propertytypeid = this.route.snapshot.queryParams.property_type_id;
      if (this.propertytypeid === '1') {
        this.property_type_text = 'Residential';
      }
      if (this.propertytypeid === '3') {
        this.property_type_text = 'Commercial';
      }
    }
    if (this.route.snapshot.queryParams.suitable_for_id !== undefined) {
      this.suitable_for_id = this.route.snapshot.queryParams.suitable_for_id;
    }
    if (this.route.snapshot.queryParams.is_luxury_property !== undefined) {
      this.isLuxuryProperty = Number(this.route.snapshot.queryParams.is_luxury_property);
    }
    this.transactionTypeList = [
      { id: 4, text: 'Buy' },
      { id: 3, text: 'Rent' }
    ];
    if (this.route.snapshot.queryParams.transaction_type_id !== undefined) {
      this.transaction_type_id = this.route.snapshot.queryParams.transaction_type_id;
      if (this.transaction_type_id === '4') {
        this.transaction_type_text = 'Buy';
      }
      if (this.transaction_type_id === '3') {
        this.transaction_type_text = 'Rent';
      }
    }
    if (this.route.snapshot.queryParams.board_type !== undefined) {
      this.boardType = this.route.snapshot.queryParams.board_type;
      // this.icon.url = 'assets/img/school_pin.png';
    }
    $('#clearBudgetFilter').hide();
    $('#prop_age_div').show();
    $('#facing_div').show();
    $('#prop_fact_div').show();
    if (this.propertytypeid === '1') {
      $('#bedroom_filter_div').show();
      $('#bathroom_filter_div').show();
      $('#extra_rooms_div').show();
      $('#furnish_div').show();
    } else {
      $('#bedroom_filter_div').hide();
      $('#bathroom_filter_div').hide();
      $('#furnish_div').hide();
      $('#extra_rooms_div').hide();
      $('#land_feature_div').hide();
      if (this.propertytypeid === '4') {
        $('#extra_rooms_div').show();
        $('#extra_rooms_div').show();
        $('#land_feature_div').show();
        $('#prop_age_div').hide();
        $('#facing_div').hide();
        $('#prop_fact_div').hide();
      }
    }
    this.bedroomList = [
      // {id: '', text: 'Any', isChecked: true},
      {id: 1, text: '1+', isChecked: false},
      {id: 2, text: '2+', isChecked: false},
      {id: 3, text: '3+', isChecked: false},
      {id: 4, text: '4+', isChecked: false},
      {id: 5, text: '5+', isChecked: false}
    ];
    if (this.route.snapshot.queryParams.bedrooms !== undefined) {
      $.each(this.route.snapshot.queryParams.bedrooms.split(','), (index, value) => {
        componentObj.bedroomList[value - 1].isChecked = true;
      });
    }
    this.bathroomList = [
      // {id: '', text: 'Any', isChecked: true},
      {id: 1, text: '1+', isChecked: false},
      {id: 2, text: '2+', isChecked: false},
      {id: 3, text: '3+', isChecked: false},
      {id: 4, text: '4+', isChecked: false},
      {id: 5, text: '5+', isChecked: false}
    ];
    if (this.route.snapshot.queryParams.bath !== undefined) {
      $.each(this.route.snapshot.queryParams.bath.split(','), (index, value) => {
        componentObj.bathroomList[value - 1].isChecked = true;
      });
    }
    this.getFurnishData();
    this.getPropertyList('', '', 0);
    if (this.isPropperty === false) {
      this.enableSticky = false;
    }
    this.saveSearchForm = this.formBuilder.group({
      searchname: ['', [Validators.required]]
    });
    this.isLoggedIn = this.ApiService.loginCheck();
    const data = { is_web_api: 1 };
    this.searchCritareaNameUpdate();

    // *** Start Get Property Amenities ***//
    this.ApiService.getAmenities(data, this.propertytypeid).subscribe((apiResult: any) => {
      if (apiResult) {
        const store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox" for="hame' + value.id + '">' + value.title + '<input type="checkbox" name="homeamenties[]" id="hame' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_property_amenities').html(store);
        if (this.route.snapshot.queryParams.amenitie_ids !== undefined) {
          $.each(this.route.snapshot.queryParams.amenitie_ids.split(','), (index, value) => {
            $('#hame' + value).prop('checked', true);
          });
        }
      }
    });
    // *** End Get Property Amenities ***//

    // *** Start Get Property Facing ***//
    this.ApiService.getFacing(data).subscribe((apiResult: any) => {
      if (apiResult) {
        const store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox" for="hfac' + value.id + '">' + value.title + '<input type="checkbox" name="homefacing[]" id="hfac' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_property_facing').html(store);
        if (this.route.snapshot.queryParams.facing_ids !== undefined) {
          $.each(this.route.snapshot.queryParams.facing_ids.split(','), (index, value) => {
            $('#hfac' + value).prop('checked', true);
          });
        }
      }
    });
    // *** Ends Get Property Facing ***//

    // *** Start Get Property Age ***//
    this.ApiService.getPropertyAge(data).subscribe((apiResult: any) => {
      if (apiResult) {
        const store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox" for="age' + value.id + '">' + value.title + '<input type="checkbox" name="propertyAge[]" id="age' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_property_age').html(store);
        if (this.route.snapshot.queryParams.property_age_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_age_id.split(','), (index, value) => {
            $('#age' + value).prop('checked', true);
          });
        }
      }
    });
    // *** Ends Get Property Age ***//

    // *** Start Get Property Status ***//
    this.ApiService.getPropertyStatus(data).subscribe((apiResult: any) => {
      if (apiResult) {
        const storepropetystatus = [];
        $.each(apiResult.data, (index, value) => {
          storepropetystatus.push('<li><label class="custom_checkbox" for="pstatus' + value.id + '">' + value.title + '<input type="checkbox" name="property_status" id="pstatus' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#property_status').html(storepropetystatus);
        if (this.route.snapshot.queryParams.property_status_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_status_id.split(','), (index, value) => {
            $('#pstatus' + value).prop('checked', true);
          });
        }
      }
    });
    // *** End Get Property Status ***//

    // *** Start Get Property Facts ***//
    this.ApiService.getallPropertyfacts(data).subscribe((apiResult: any) => {
      if (apiResult) {
        const storepropetyfacts = [];
        storepropetyfacts.push('<option value="0">Unit</option>');
        $.each(apiResult.data, (index, value) => {
          storepropetyfacts.push('<option value="' + value.id + '">' + value.title + '</option>');
        });
        $('.f_property_facts').html(storepropetyfacts);
        if (this.route.snapshot.queryParams.min_builtup_area !== undefined) {
          $('#min_builtup_area').val(this.route.snapshot.queryParams.min_builtup_area);
        }
        if (this.route.snapshot.queryParams.min_builtup_area_unit !== undefined) {
          $('#min_builtup_area_unit').val(this.route.snapshot.queryParams.min_builtup_area_unit);
        }
        if (this.route.snapshot.queryParams.max_builtup_area !== undefined) {
          $('#max_builtup_area').val(this.route.snapshot.queryParams.max_builtup_area);
        }
        if (this.route.snapshot.queryParams.max_builtup_area_unit !== undefined) {
          $('#max_builtup_area_unit').val(this.route.snapshot.queryParams.max_builtup_area_unit);
        }
        if (this.route.snapshot.queryParams.min_saleable_area !== undefined) {
          $('#min_saleable_area').val(this.route.snapshot.queryParams.min_saleable_area);
        }
        if (this.route.snapshot.queryParams.min_saleable_area_unit !== undefined) {
          $('#min_saleable_area_unit').val(this.route.snapshot.queryParams.min_saleable_area_unit);
        }
        if (this.route.snapshot.queryParams.max_saleable_area !== undefined) {
          $('#max_saleable_area').val(this.route.snapshot.queryParams.max_saleable_area);
        }
        if (this.route.snapshot.queryParams.max_saleable_area_unit !== undefined) {
          $('#max_saleable_area_unit').val(this.route.snapshot.queryParams.max_saleable_area_unit);
        }
      }
    });
    // *** End Get Property Facts ***//

    // *** Start Get Property Type Category ***//
    if (this.route.snapshot.queryParams.property_type_id !== undefined) {
      this.propertytypeid = this.route.snapshot.queryParams.property_type_id;
    }
    const propTypeCategory = {is_mobile_api: 1};
    this.ApiService.getPropertyTypes(propTypeCategory).subscribe((apiResult: any) => {
      if (apiResult){
        this.propertyTypeCategory = (apiResult.data || []).filter(x=>x.title!=="Industrial");;
      }
    });
    // *** End Get Property Type Category ***//

    // *** Start Get Property Category ***//
    this.setPropertyCategoryList();
    // *** End Get Property Category ***//

    // *** Start Get Property Dimensions ***//
    const propDimensions = {is_mobile_api: 1, property_type_id: this.propertytypeid};
    this.ApiService.getPropertyDimensions(propDimensions).subscribe((apiResult: any) => {
      if (apiResult) {
        this.propertyDimensionList = apiResult.data;
        const store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox text-left" for="pdimension' + value.id + '">' + value.title + '<input type="checkbox" name="property_dimension[]" id="pdimension' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_property_rooms').html(store);
        $('#f_property_rooms_modal').html(store);
        if (this.route.snapshot.queryParams.property_dimesion_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_dimesion_id.split(','), (index, value) => {
            $('#pdimension' + value).prop('checked', true);
          });
        }
      }
    });
    // *** End Get Property Dimensions ***//

    // *** Start Get Land Category ***//
    this.ApiService.getLandCategories(data).subscribe((apiResult: any) => {
      if (apiResult) {
        this.landCategoryList = apiResult.data;
        const store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox text-left" for="plandcategory' + value.id + '">' + value.title + '<input type="checkbox" name="land_category[]" id="plandcategory' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_land_category').html(store);
        if (this.route.snapshot.queryParams.land_category_id !== undefined) {
          $.each(this.route.snapshot.queryParams.land_category_id.split(','), (index, value) => {
            $('#plandcategory' + value).prop('checked', true);
          });
        }
      }
    });
    // *** End Get Land Category ***//

    // *** Start Get Land Features ***//
    this.ApiService.getLandFeatures(data).subscribe((apiResult: any) => {
      if (apiResult) {
        this.landFeaturesList = apiResult.data;
        const store = [];
        $.each(apiResult.data, (index, value) => {
          store.push('<li><label class="custom_checkbox text-left" for="plandfeature' + value.id + '">' + value.title + '<input type="checkbox" name="land_feature[]" id="plandfeature' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_land_feature').html(store);
        if (this.route.snapshot.queryParams.land_feature_id !== undefined) {
          $.each(this.route.snapshot.queryParams.land_feature_id.split(','), (index, value) => {
            $('#plandfeature' + value).prop('checked', true);
          });
        }
      }
    });
    // *** End Get Land Features ***//

    setTimeout(() => {
      const elocInput = $('#searchinput');
      elocInput.tagsinput({
        itemValue: 'eLoc',
        itemText: 'placeName',
        freeInput: true,
        confirmKeys: [13, 44],
        typeaheadjs: {
          displayKey: 'placeNameFull',
          source: (search, sync, async) => {
            if (search.length >= 2) {
              return $.ajax({
                type: 'GET',
                dataType: 'json',
                url: API_BASE_URL + '/master/getLocality',
                data: { is_web_api: 1, search: search },
                success: (response) => {
                  response = response.data;
                  return async(response);
                }
              });
            }
          }
        },
      });

      $('#searchinput').on('itemAdded', (event) => {
        componentObj.searchboxapply(0);
      });
      $('#searchinput').on('itemRemoved', (event) => {
        componentObj.searchboxapply(0);
        this.locality_elocs=''
      });
      if (this.locality_elocs !== undefined) {
        $.each(this.locality_elocs.split(','), (index, value) => {
          elocInput.tagsinput('add', { "placeNameFull": value, "placeName": value, "eLoc": value });
        });
      }
      $('#property_list_view').hide();
      this.changeHeaderClass();

      const amountStringMatcher = () => {
        return function findMatches(q, cb) {
          const matches = [];
          q = q.replace(/[^0-9.]+/g, '');
          matches.push({'label': q + ' Hund.', 'value': q * 100});
          matches.push({ 'label': q + ' Th.', 'value': q * 1000 });
          matches.push({ 'label': q + ' Lc.', 'value': q * 100000 });
          matches.push({ 'label': q + ' Cr.', 'value': q * 10000000 });
          cb(matches);
        };
      };

      this.minBudgetAmount = $('#min_budget_amount');
      this.minBudgetAmount.typeahead(null, {
        name: 'minBudgetAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.minBudgetAmount.on('typeahead:selected', (event, data) => {
        componentObj.minBudgetAmountValue = data.value;
        componentObj.searchboxapply(0);
      });
      if (this.route.snapshot.queryParams.min_budget_amount !== undefined) {
        componentObj.minBudgetAmountValue = this.route.snapshot.queryParams.min_budget_amount;
        let amountText = '';
        if ((componentObj.minBudgetAmountValue / 10000000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 10000000) + " Cr.";
        } else if ((componentObj.minBudgetAmountValue / 100000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 100000) + " Lc.";
        } else if ((componentObj.minBudgetAmountValue / 1000) >= 1) {
          amountText = (componentObj.minBudgetAmountValue / 1000) + " Th.";
        }
        $("#min_budget_amount").val(amountText);
        $("#clearBudgetFilter").show();
      }

      this.maxBudgetAmount = $('#max_budget_amount');
      this.maxBudgetAmount.typeahead(null, {
        name: 'maxBudgetAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.maxBudgetAmount.on('typeahead:selected', (event, data) => {
        componentObj.maxBudgetAmountValue = data.value;
        componentObj.searchboxapply(0);
      });
      if (this.route.snapshot.queryParams.max_budget_amount !== undefined) {
        componentObj.maxBudgetAmountValue = this.route.snapshot.queryParams.max_budget_amount;
        var amountText = "";
        if ((componentObj.maxBudgetAmountValue / 10000000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 10000000) + " Cr.";
        } else if ((componentObj.maxBudgetAmountValue / 100000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 100000) + " Lc.";
        } else if ((componentObj.maxBudgetAmountValue / 1000) >= 1) {
          amountText = (componentObj.maxBudgetAmountValue / 1000) + " Th.";
        }
        $("#max_budget_amount").val(amountText);
        $("#clearBudgetFilter").show();
      }
    }, 200);
    if (this.isLoggedIn) {
      const userInfo = JSON.parse(localStorage.getItem('currentUser'));
      this.first_name = userInfo.first_name;
      this.last_name = userInfo.last_name;
      this.email = userInfo.email;
      this.phone = userInfo.contact_no;
    }
    this.leadForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      profileEmail: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
    });

    fromEvent(window, "scroll").subscribe((event) => {
      const footerDiv = document.getElementById("footer");
      const scrollPos = footerDiv.offsetTop;
      if (this.document.defaultView.scrollY < scrollPos) {
        this.enableabsoluteMap = false;
        this.enableSticky = true;
      }
      else if (this.document.defaultView.scrollY > scrollPos) {
        this.enableSticky = false;
        this.enableabsoluteMap = true;
      }
      else {
        this.enableSticky = false;
        this.enableabsoluteMap = false;
      }
    });
  }

  searchProperty(){
    if (!this.locality_elocs) {
      this.FlashService.error('Please enter valid location');
      return
    }
  }

  mapReady(map) {
    this.propMap = map;
  }

  onScroll(): void {
    /*if (this.loadmore && !this.isLoading) {
      this.isLoading = true;
      this.currentPage = this.currentPage + 1;
      this.getPropertyOnScroll();
    }*/
  }

  getFurnishData() {
    const componentObj = this;
    this.furnishingList = [
      { id: 'Fully Furnished', text: 'Furnished', isChecked: false },
      { id: 'UnFurnished', text: 'Unfurnished', isChecked: false },
      { id: 'Semi Furnished', text: 'Semi Furnished', isChecked: false },
      { id: 'Ready To Furnished', text: 'Ready To Furnished', isChecked: false },
      { id: 'Not Specified', text: 'Not Specified', isChecked: false }
    ];
    if (this.route.snapshot.queryParams.furnishing !== undefined) {
      $.each(this.route.snapshot.queryParams.furnishing.split(','), (index, value) => {
        $.each(componentObj.furnishingList, (pci, pcv) => {
          if (pcv.id == value) {
            componentObj.furnishingList[pci].isChecked = true;
          }
        });
      });
    }
  }

  ngOnDestroy(): void {

  }

  searchCritareaNameUpdate() {
    this.name = "";
    if (this.route.snapshot.queryParams.locality_elocs !== undefined) {
      this.locality_elocs = this.route.snapshot.queryParams.locality_elocs;
      this.name = this.locality_elocs;
    }

    if (this.propertytypeid !== undefined) {
      if (this.propertytypeid == '1' || this.propertytypeid == '3') {
        if (this.transaction_type_id !== undefined) {
          if (this.transaction_type_id == '4') {
            this.name = this.property_type_text + ' properties for sale';
          } else {
            this.name = this.property_type_text + ' properties for rent';
          }
        }
      } else if (this.propertytypeid == '4') {
        this.name = 'Land for sale';
      } else if (this.propertytypeid == '5') {
        this.name = 'Property for investment';
      } else if (this.propertytypeid == '6') {
        this.name = 'Hi End Properties for sale';
      }
    }
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Luxurious Properties');
    this.meta.updateTag({ name: 'description', content: 'Find ' + this.name + ' on empirerealtors.com. Verified Listings, Map View, Price Trends, Offers, Photos & Amenities.' });
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    const position = new google.maps.LatLng(this.lat, this.lng);
    this.propMap.panTo(position);
  }

  addMarker(markerN) {
    this.markers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  openPropertyDetails(pid: string) {
    window.open(WEB_URL + '/property-details/' + pid, '_blank')
  }

  getDiraction(lat, lng) {
    let url = 'https://maps.google.com/' + '?' + 'daddr' + '=' + lat + ',' + lng
    window.open(url, '_blank');
  }

  activeView:boolean=true
  showgridviewfunction() {
    this.activeView=true
    $(() => {
      // $('.active_view').removeClass('active_view');
      // $('.grid_btn').addClass('active_view');
      $('#property_grid_view').show();
      $('#property_list_view').hide();
    });
    let data = {};
    data = this.getQueryData();
    data['page_no'] = this.pageConfig.currentPage;
    data['limit'] = 10;
    this.router.navigate(['luxury-property'], { queryParams: data });
    setTimeout(() => {
      this.getPropertyList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  showlistviewfunction() {
    this.activeView=false
    $(() => {
      // $('.active_view').removeClass('active_view');
      // $('.list_btn').addClass('active_view');
      $('#property_grid_view').hide();
      $('#property_list_view').show();
    });
    let data = {};
    data = this.getQueryData();
    data['page_no'] = this.pageConfig.currentPage;
    data['limit'] = 10;
    this.router.navigate(['luxury-property'], { queryParams: data });
    setTimeout(() => {
      this.getPropertyList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  getPropertyList(NorthWest, SouthEst, is_modal) {
    if(this.route.snapshot.queryParams.locality_elocs !== this.prevLocality_elocs){
      this.pageConfig.currentPage=1
    }
    this.prevLocality_elocs=this.route.snapshot.queryParams.locality_elocs
    this.searchCritareaNameUpdate();
    const form_data = new FormData();
    const page_no = this.pageConfig.currentPage;
    for (const i in this.route.snapshot.queryParams) {
      const param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'property');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.success) {
        let propCattxt = '';
        if (this.route.snapshot.queryParams.property_category_id) {
          let propCat = this.route.snapshot.queryParams.property_category_id;
          propCat = propCat.split(',');
          $(this.propertyCategoryList).each((k, v) => {
            for (let i = 0; i <= propCat.length; i++) {
              if (v.id == propCat[i]) {
                propCattxt = propCattxt != "" ? propCattxt + ', ' + v.title : v.title;
              }
            }
          });
        }
        propCattxt = propCattxt != "" ? propCattxt : "Property";
        if (apiResult.data.paginate.Property.count > 0 && apiResult.data.list.length > 0) {
          this.total_props_s = apiResult.data.paginate.Property.count + ' ' + propCattxt;
          this.isPropperty = true;
          if (is_modal === 1) {
            $('#property_grid_view').show();
          }
        } else {
          this.isPropperty = false;
          this.enableSticky = false;
          if (is_modal === 1) {
            $('#lead_generator_div').show();
          }
        }
        this.loadmore = apiResult.data.paginate.Property.nextPage;
        this.currentPage = 1;
        this.propertyList = apiResult.data.list;
        this.pageConfig.totalItems = apiResult.data.paginate.Property.count;
        this.ref.detectChanges();
        if (apiResult.data.paginate.Property.count === 0) {
          if (apiResult.data.lat_lng) {
            this.lat = apiResult.data.lat_lng.lat;
            this.lng = apiResult.data.lat_lng.lng;
          }
          this.name =(this.propertyList || []).length > 0 ?  'We are working on your search criteria, please fill the below form, our team will get back to you soon' : 'No properties found';

        }
        if (this.boardType) {
          $.each(apiResult.data.schoolData, (index, value) => {
            if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
              componentObj.lat = value.locality_lat;
              componentObj.lng = value.locality_lng;
              componentObj.changeLatLng(componentObj.lat, componentObj.lng);
              const markerN = {
                pid: value.id,
                lat: value.locality_lat,
                lng: value.locality_lng,
                symbol: {
                  url: 'assets/img/school_pin.png',
                  scaledSize: {
                    width: 30,
                    height: 49
                  }
                },
                draggable: false,
                // image_preview: value.image_preview,
                locality_title: value.school_name,
                // asking_price: value.asking_price_in_word
              };
              componentObj.addMarker(markerN);
            }
          });
        } else {
          if (apiResult.data.list.length > 0 && apiResult.data.lat_lng.length > 0) {
            componentObj.lat = apiResult.data.lat_lng.lat;
            componentObj.lng = apiResult.data.lat_lng.lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            this.markers = [];
          }
        }
        this.isZoomFlag = false;
        this.isLoading = false;
        this.getPropertyFav('', '', 0);
        this.getPropertyMapIcons('', '', 0);
      } else {
        componentObj.lat = apiResult.data.lat_lng.lat;
        componentObj.lng = apiResult.data.lat_lng.lng;
        componentObj.changeLatLng(componentObj.lat, componentObj.lng);
        this.markers = [];
        this.loadmore = false;
        this.currentPage = 1;
        this.isLoading = false;
        this.isPropperty = false;
        this.isZoomFlag = false;
        this.total_props_s = "0 Property";
        this.ref.detectChanges();
      }
    });
  }

  getPropertyFav(NorthWest, SouthEst, is_modal) {
    const form_data = new FormData();
    const page_no = this.pageConfig.currentPage;
    for (const i in this.route.snapshot.queryParams) {
      const param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'fav_icon');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      $.each(apiResult.data.list, (index, value) => {
        if (value.is_favorited == 1) {
          $('#i_favIcon_' + value.id).removeClass('fav_white_color');
          $('#i_favIcon_' + value.id).addClass('fav_red_color');
        }
      });
    });
  }

  getPropertyMapIcons(NorthWest, SouthEst, is_modal) {
    const form_data = new FormData();
    const page_no = this.pageConfig.currentPage;
    for (const i in this.route.snapshot.queryParams) {
      const param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    // @ts-ignore
    form_data.append('page', page_no);
    if (NorthWest != '' && SouthEst != '') {
      this.isZoomFlag = true;
      form_data.append('north_west', NorthWest);
      form_data.append('south_est', SouthEst);
    }
    form_data.append('list_for', 'property');
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.data.list) {
        $.each(apiResult.data.list, (index, value) => {
          if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
            componentObj.lat = value.locality_lat;
            componentObj.lng = value.locality_lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            const markerN = {
              pid: value.id,
              lat: value.locality_lat,
              lng: value.locality_lng,
              symbol: {
                url: 'assets/img/er_marker.png',
                scaledSize: {
                  width: 30,
                  height: 49
                }
              },
              draggable: false,
              image_preview: value.image_preview,
              locality_title: (value.name || value.locality_title),
              asking_price: value.asking_price_in_word,
              status: (value?.prop_status?.title  || 'Available'),
              address: ((value.address_line_1 || '') + ' ' + (value.address_line_2 || '') || value.city_title)
            };
            componentObj.addMarker(markerN);
          }
        });
      }
    });
  }

  getPropertyOnScroll() {
    const componentObj = this;
    const form_data = new FormData();
    for (const i in this.route.snapshot.queryParams) {
      const param = this.route.snapshot.queryParams[i];
      if (this.route.snapshot.queryParams[i]) {
        form_data.append(i, param);
      }
    }
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES + "?page=" + this.currentPage, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        let propCattxt = '';
        if (this.route.snapshot.queryParams.property_category_id) {
          let propCat = this.route.snapshot.queryParams.property_category_id;
          propCat = propCat.split(',');
          $(this.propertyCategoryList).each((k, v) => {
            for (let i = 0; i <= propCat.length; i++) {
              if (v.id == propCat[i]) {
                propCattxt = propCattxt != "" ? propCattxt + ', ' + v.title : v.title;
              }
            }
          });
        }
        this.isLoading = false;
        this.loadmore = apiResult.data.paginate.Property.nextPage;
        this.propertyList = this.propertyList.concat(apiResult.data.list);
        this.ref.detectChanges();
        this.total_props_s = apiResult.data.paginate.Property.count + " " + propCattxt;
        if (this.boardType) {
          $.each(apiResult.data.schoolData, (index, value) => {
            if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
              componentObj.lat = value.locality_lat;
              componentObj.lng = value.locality_lng;
              componentObj.changeLatLng(componentObj.lat, componentObj.lng);
              const markerN = {
                pid: value.id,
                lat: value.locality_lat,
                lng: value.locality_lng,
                symbol: {
                  url: 'assets/img/school_pin.png',
                  scaledSize: {
                    width: 30,
                    height: 49
                  }
                },
                draggable: false,
                // image_preview: value.image_preview,
                locality_title: value.locality_title,
                // asking_price: value.asking_price_in_word
              };
              componentObj.addMarker(markerN);
            }
          });
        }
        if (apiResult.data.list) {
          $.each(apiResult.data.list, (index, value) => {
            if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
              componentObj.lat = value.locality_lat;
              componentObj.lng = value.locality_lng;
              componentObj.changeLatLng(componentObj.lat, componentObj.lng);
              const markerN = {
                pid: value.id,
                lat: value.locality_lat,
                lng: value.locality_lng,
                symbol: {
                  url: 'assets/img/er_marker.png',
                  scaledSize: {
                    width: 30,
                    height: 49
                  }
                },
                draggable: false,
                image_preview: value.image_preview,
                locality_title: value.locality_title,
                asking_price: value.asking_price_in_word
              };
              componentObj.addMarker(markerN);
            }
          });
        } else {
          if (apiResult.data.lat_lng.length > 0) {
            componentObj.lat = apiResult.data.lat_lng.lat;
            componentObj.lng = apiResult.data.lat_lng.lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            const markerN = {
              pid: 0,
              lat: apiResult.data.lat_lng.lng,
              lng: apiResult.data.lat_lng.lng,
              symbol: {},
              draggable: false,
              image_preview: '',
              locality_title: '',
              asking_price: ''
            };
            componentObj.addMarker(markerN);
          }
        }
      } else {
        this.loadmore = false;
        this.currentPage = 1;
        this.isLoading = false;
        this.total_props_s = "0 Property";
        this.ref.detectChanges();
      }
    });
  }

  // tslint:disable-next-line:variable-name
  searchboxapply(is_modal) {
    if (!this.isLoading) {
      let data = {};
      data = this.getQueryData();
      this.setPropertyCategoryList();
      this.router.navigate(['luxury-property'], {queryParams: data});
      setTimeout(() => {
        this.getPropertyList('', '', is_modal);
        this.ref.detectChanges();
        if (is_modal === 1) {
          $('#filterBtn').addClass('collapsed');
          $('#moreFilter').removeClass('show');
          $('#footer').show();
          this.moreFilter = 'close';
          // $('#filterPropertyModal').modal('toggle');
          // this.showHideResetBtn = true;
        } else {
          // $('#lead_generator_div').hide();
          // $('#property_grid_view').hide();
          // $('#property_list_view').hide();
        }
      }, 1000);
    }
  }

  setPropertyCategoryList() {
    const componentObj = this;
    const propCatData = {is_mobile_api: 1, property_type_id: this.propertytypeid, is_for_project: 0};
    this.ApiService.getPropertyCategory(propCatData).subscribe((apiResult: any) => {
      if (apiResult){
        this.propertyCategoryList = apiResult.data;
        const store = [];
        $.each(apiResult.data, ( index, value ) => {
          store.push('<li><label class="custom_checkbox text-left" for="ptype' + value.id + '">' + value.title + '<input type="checkbox" name="property_type[]" id="ptype' + value.id + '" value="' + value.id + '"><span class="checkmark"></span></label></li>');
        });
        $('#f_property_category').html(store);
        /*$("input[name='property_type[]'").change(() => {
          componentObj.searchboxapply(0);
        });*/
        if (this.route.snapshot.queryParams.property_category_id !== undefined) {
          $.each(this.route.snapshot.queryParams.property_category_id.split(','), (index, value) => {
            $('#ptype' + value).prop('checked', true);
          });
        }
      }
    });
  }

  getQueryData() {
    $('#clearBudgetFilter').hide();
    this.propertyList = [];
    this.isLoading = true;
    this.total_props_s = '';
    this.lat = 22.295492;
    this.lng = 73.173463;
    this.markers = [];
    const data = {};

    if (this.transaction_type_id !== undefined) {
      data['transaction_type_id'] = this.transaction_type_id;
    }
    if (this.isLuxuryProperty !== undefined) {
      data['is_luxury_property'] = this.isLuxuryProperty;
    } else {
      data['is_luxury_property'] = 1;
    }
    if (this.boardType !== undefined && this.boardType) {
      data['board_type'] = this.boardType;
    }
    if (this.suitable_for_id !== undefined && this.suitable_for_id) {
      data['suitable_for_id'] = this.suitable_for_id;
    }
    if (this.propertytypeid !== undefined) {
      data['property_type_id'] = this.propertytypeid;
      if (this.propertytypeid === '1') {
        this.property_type_text = 'Residential';
      } else if (this.propertytypeid === '3') {
        this.property_type_text = 'Commercial';
      } else if (this.propertytypeid === '4') {
        this.property_type_text = 'Land';
      } else if (this.propertytypeid === '6') {
        this.property_type_text = 'Industrial';
      }
    }
    if (this.transaction_type_id == "4") {
      this.transaction_type_text = "Buy";
    }
    if (this.transaction_type_id == "3") {
      this.transaction_type_text = "Rent";
    }

    const beds = [];
    if (this.propertytypeid === '1') {
      $('#bedroom_filter_div').show();
      $.each(this.bedroomList, (index, val) => {
        if (val.isChecked && val.id !== "") {
          beds.push(val.id);
        }
      });
      if (beds.length != 0) {
        data['bedrooms'] = beds.join(",");
      }
    } else {
      $('#bedroom_filter_div').hide();
    }

    const baths = [];
    if (this.propertytypeid === '1') {
      $('#bathroom_filter_div').show();
      $.each(this.bathroomList, (index, val) => {
        if (val.isChecked && val.id !== "") {
          baths.push(val.id);
        }
      });
      if (baths.length != 0) {
        data['bath'] = baths.join(",");
      }
    } else {
      $('#bathroom_filter_div').hide();
    }

    const property_category = [];
    $("input[name='property_type[]']:checked").each((index, val) => {
      property_category.push($(val).val());
    });
    if (property_category.length != 0) {
      data['property_category_id'] = property_category.join(",");
    }

    const furnishing = [];
    if (this.propertytypeid == "1") {
      $.each(this.furnishingList, (index, val) => {
        if (val.isChecked && val.id !== "") {
          furnishing.push(val.id);
        }
      });
      if (furnishing.length != 0) {
        data['furnishing'] = furnishing.join(",");
      }
    } else {
      $('#furnish_div').hide();
    }

    const property_status = [];
    $("input[name='property_status']:checked").each((i, val) => {
      property_status.push($(val).val());
    });
    if (property_status.length != 0) {
      data['property_status_id'] = property_status.join(",");
    }

    const amenities = new Array();
    $("input[name='homeamenties[]']:checked").each((i, val) => {
      amenities.push($(val).val());
    });
    if (amenities.length != 0) {
      data['amenitie_ids'] = amenities.join(",");
    }

    const facing = new Array();
    if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      $('#facing_div').show();
      $("input[name='homefacing[]']:checked").each((i, val) => {
        facing.push($(val).val());
      });
      if (facing.length != 0) {
        data['facing_ids'] = facing.join(",");
      }
    } else {
      $('#facing_div').hide();
    }

    const propertyAge = new Array();
    if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      $('#prop_age_div').show();
      $("input[name='propertyAge[]']:checked").each((i, val) => {
        propertyAge.push($(val).val());
      });
      if (propertyAge.length != 0) {
        data['property_age_id'] = propertyAge.join(",");
      }
    } else {
      $('#prop_age_div').hide();
    }

    const propertyDimension = new Array();
    if (this.propertytypeid == "1") {
      $('#extra_rooms_div').show();
      $("input[name='property_dimension[]']:checked").each((i, val) => {
        propertyDimension.push($(val).val());
      });
      if (propertyDimension.length != 0) {
        data['property_dimension_id'] = propertyDimension.join(",");
      }
    } else {
      $('#extra_rooms_div').hide();
    }

    const landCategory = new Array();
    if (this.propertytypeid == "4") {
      $('#extra_rooms_div').show();
      $('#land_category_div').show();
      $("input[name='land_category[]']:checked").each((i, val) => {
        landCategory.push($(val).val());
      });
      if (landCategory.length != 0) {
        data['land_category_id'] = landCategory.join(",");
      }
    } else {
      $('#land_category_div').hide();
    }

    const landFeature = new Array();
    if (this.propertytypeid == "4") {
      $('#extra_rooms_div').show();
      $('#land_feature_div').show();
      $("input[name='land_feature[]']:checked").each((i, val) => {
        landFeature.push($(val).val());
      });
      if (landFeature.length != 0) {
        data['land_feature_id'] = landFeature.join(",");
      }
    } else {
      $('#land_feature_div').hide();
    }

    if (this.propertytypeid == "1" || this.propertytypeid == "3") {
      if ($("#min_builtup_area").val() != "0" && $("#min_builtup_area").val() != "") {
        data['min_builtup_area'] = $("#min_builtup_area").val();
      }
      if ($("#min_builtup_area_unit").val() != "0" && $("#min_builtup_area_unit").val() != "") {
        data['min_builtup_area_unit'] = $("#min_builtup_area_unit").val();
      }
      if ($("#max_builtup_area").val() != "0" && $("#max_builtup_area").val() != "") {
        data['max_builtup_area'] = $("#max_builtup_area").val();
      }
      if ($("#max_builtup_area_unit").val() != "0" && $("#max_builtup_area_unit").val() != "") {
        data['max_builtup_area_unit'] = $("#max_builtup_area_unit").val();
      }
      if ($("#min_saleable_area").val() != "0" && $("#min_saleable_area").val() != "") {
        data['min_saleable_area'] = $("#min_saleable_area").val();
      }
      if ($("#min_saleable_area_unit").val() != "0" && $("#min_saleable_area_unit").val() != "") {
        data['min_saleable_area_unit'] = $("#min_saleable_area_unit").val();
      }
      if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
        data['max_saleable_area'] = $("#max_saleable_area").val();
      }
      if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
        data['max_saleable_area_unit'] = $("#max_saleable_area_unit").val();
      }
      if ($('#searchinput').tagsinput('items') && $('#searchinput').tagsinput('items').length) {
        data['locality_elocs'] = ($('#searchinput').tagsinput('items').map(value => value.eLoc)).join(',');
      }
      if (this.minBudgetAmountValue !== undefined) {
        data['min_budget_amount'] = this.minBudgetAmountValue;
        $('#clearBudgetFilter').show();
      }
      if (this.maxBudgetAmountValue !== undefined) {
        data['max_budget_amount'] = this.maxBudgetAmountValue;
        $('#clearBudgetFilter').show();
      }
    }
    return data;
  }

  makeFavorite(pid, status, franchisee_id) {
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      const form_data = new FormData();
      form_data.append('property_id', pid);
      form_data.append('status', "1");
      form_data.append('locality_elocs', this.locality_elocs);
      form_data.append('transaction_type_id', this.transaction_type_id);
      form_data.append('franchisee_id', franchisee_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          if ($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
            this.FlashService.success('Luxury Property is removed from your Wishlist.')
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
            this.FlashService.success('Luxury Property is added to your Wishlist.')
          }
        }
      });
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  openSaveSearchPopup(): void {
    this.getSavedSearches();
    if ($('.saveSearchPopup').hasClass('savedsearchactive')) {
      $('.saveSearchPopup').removeClass('savedsearchactive');
    } else {
      $('.saveSearchPopup').addClass('savedsearchactive');
    }
  }

  hideSearchBox() {
    $('.saveSearchPopup').removeClass('savedsearchactive');
  }

  onSaveSearch() {
    this.submitted = true;
    if (this.saveSearchForm.invalid) {
      return;
    }
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      let data = {};
      data = this.getQueryData();
      this.json_string = JSON.stringify(data);
      const form_data = new FormData();
      form_data.append('json_string', this.json_string);
      form_data.append('name', this.searchnameval);
      form_data.append('source', 'property');
      this.ApiService.postRequest(API_ENDPOINTS.API_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          this.router.navigate(['/savedsearch']);
        }
      });
    }
  }

  prepareSearchJson() {
    const data = {};
    if (this.transaction_type_id !== undefined) {
      data['transaction_type_id'] = this.transaction_type_id;
    }
    if (this.propertytypeid !== undefined) {
      data['property_type_id'] = this.propertytypeid;
    }

    const beds = [];
    $.each(this.bedroomList, (index, val) => {
      if (val.isChecked && val.id !== "") {
        beds.push(val.id);
      }
    });
    if (beds.length != 0) {
      data['bedrooms'] = beds.join(",");
    }

    const baths = [];
    $.each(this.bathroomList, (index, val) => {
      if (val.isChecked && val.id !== "") {
        baths.push(val.id);
      }
    });
    if (baths.length != 0) {
      data['bath'] = baths.join(",");
    }

    const property_category = [];
    /*$.each(this.propertyCategoryList, function(index, val) {
      if(val.isChecked && val.id !== "") {
        property_category.push(val.id);
      }
    });*/
    $("input[name='property_type[]']:checked").each((i) => {
      property_category.push($(this).val());
    });
    if (property_category.length != 0) {
      data['property_category_id'] = property_category.join(",");
    }

    const furnishing = [];
    $.each(this.furnishingList, (index, val) => {
      if (val.isChecked && val.id !== "") {
        furnishing.push(val.id);
      }
    });
    if (furnishing.length != 0) {
      data['furnishing'] = furnishing.join(",");
    }

    const property_status = [];
    $("input[name='property_status']:checked").each((i) => {
      property_status.push($(this).val());
    });
    if (property_status.length != 0) {
      data['property_status_id'] = property_status.join(",");
    }

    const amenities = new Array();
    $("input[name='homeamenties[]']:checked").each((i) => {
      amenities.push($(this).val());
    });
    if (amenities.length != 0) {
      data['amenitie_ids'] = amenities.join(",");
    }

    const facing = new Array();
    $("input[name='homefacing[]']:checked").each((i) => {
      facing.push($(this).val());
    });
    if (facing.length != 0) {
      data['facing_ids'] = facing.join(",");
    }

    const propertyAge = new Array();
    $("input[name='propertyAge[]']:checked").each((i) => {
      propertyAge.push($(this).val());
    });
    if (propertyAge.length != 0) {
      data['property_age_id'] = propertyAge.join(",");
    }

    if ($("#min_builtup_area").val() != "0" && $("#min_builtup_area").val() != "") {
      data['min_builtup_area'] = $("#min_builtup_area").val();
    }
    if ($("#min_builtup_area_unit").val() != "0" && $("#min_builtup_area_unit").val() != "") {
      data['min_builtup_area_unit'] = $("#min_builtup_area_unit").val();
    }
    if ($("#max_builtup_area").val() != "0" && $("#max_builtup_area").val() != "") {
      data['max_builtup_area'] = $("#max_builtup_area").val();
    }
    if ($("#max_builtup_area_unit").val() != "0" && $("#max_builtup_area_unit").val() != "") {
      data['max_builtup_area_unit'] = $("#max_builtup_area_unit").val();
    }
    if ($("#min_saleable_area").val() != "0" && $("#min_saleable_area").val() != "") {
      data['min_saleable_area'] = $("#min_saleable_area").val();
    }
    if ($("#min_saleable_area_unit").val() != "0" && $("#min_saleable_area_unit").val() != "") {
      data['min_saleable_area_unit'] = $("#min_saleable_area_unit").val();
    }
    if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area'] = $("#max_saleable_area").val();
    }
    if ($("#max_saleable_area").val() != "0" && $("#max_saleable_area").val() != "") {
      data['max_saleable_area_unit'] = $("#max_saleable_area_unit").val();
    }
    if ($('#searchinput').tagsinput('items') && $('#searchinput').tagsinput('items').length) {
      data['locality_elocs'] = ($('#searchinput').tagsinput('items').map(value => value.eLoc)).join(',');
      if (this.searchnameval === "") {
        this.searchnameval = data['locality_elocs'];
      }
    }
    if (this.minBudgetAmountValue !== undefined) {
      data['min_budget_amount'] = this.minBudgetAmountValue;
    }
    if (this.maxBudgetAmountValue !== undefined) {
      data['max_budget_amount'] = this.maxBudgetAmountValue;
    }
    data['is_luxury_property'] = 1;
    this.router.navigate(['luxury-property'], { queryParams: data });
    this.json_string = JSON.stringify(data);
  }

  getSavedSearches() {
    this.prepareSearchJson();
    const formData = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SAVE_SEARCH, formData).subscribe((apiResult: any) => {
      if (apiResult.success) {
        $.each(apiResult.data, (index, value) => {
          if (value.json_string === this.json_string) {
            this.searchnameval = value.name;
          }
        });
      }
    });
  }

  onLeadFormSubmit() {
    this.submitted = true;
    if (this.leadForm.invalid) {
      return;
    }
    $('#submitLead').hide();
    $('.loaderBtn').show();
    const formData = new FormData();
    formData.append('first_name', this.first_name);
    formData.append('last_name', this.last_name);
    formData.append('contact_no', this.phone);
    formData.append('email', this.email);
    formData.append('property_type_id', this.propertytypeid);
    formData.append('locality_elocs', this.locality_elocs);
    this.ApiService.postRequest(API_ENDPOINTS.API_ADD_PROPERTY_LEAD, formData).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success(apiResult.message);
      } else {
        this.FlashService.error(apiResult.message);
      }
      $('.loaderBtn').hide();
    }, error => {
      this.FlashService.error("Something wrong!");
      $('.loaderBtn').hide();
    });
  }

  nearByProperties() {
    const formData = new FormData();
    formData.append('locality_elocs', this.locality_elocs);
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_NEAR_BY_PROPERTIES, formData).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.suggestedProperties = apiResult.data.list;
        this.searchCritareaNameUpdate();
        setTimeout(() => {
          this.initSuggestedPropertyViewSlider();
        }, 200);
      }
    });
  }

  initSuggestedPropertyViewSlider() {
    $('#suggested_property_view').lightSlider({
      gallery: false,
      item: 4,
      auto: true,
      loop: true,
      speed: 1000,
      slideMargin: 25,
      pauseOnHover: true,
      pager: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            item: 1
          }
        }
      ]
    });
  }

  showHideFilter() {
  this.activeView=true
    if (this.moreFilter === 'close' || this.moreFilter === undefined) {
      $('#filterBtn').removeClass('collapsed');
      $('#moreFilter').addClass('show');
      $('#property_grid_view').hide();
      $('#property_list_view').hide();
      $('#lead_generator_div').hide();
      $('#footer').hide();
      this.moreFilter = 'open';
    } else {
      $('#filterBtn').addClass('collapsed');
      $('#moreFilter').removeClass('show');
      if (this.isPropperty === false) {
        $('#lead_generator_div').show();
      } else {
        $('#property_grid_view').show();
      }
      $('#footer').show();
      this.moreFilter = 'close';
    }
  }

  pageChange(newPage: number, itemsPerPage) {
    this.pageConfig.currentPage = newPage;
    this.pageConfig.itemsPerPage = itemsPerPage;
    let data = {};
    data = this.getQueryData();
    data['page_no'] = newPage;
    data['limit'] = itemsPerPage;
    this.router.navigate(['luxury-property'], { queryParams: data });
    setTimeout(() => {
      this.getPropertyList('', '', 0);
      this.ref.detectChanges();
    }, 1000);
  }

  clearFilter() {
    if (!this.isLoading) {
      this.isLoading = true;
      const data = {};
      if (this.route.snapshot.queryParams.locality_elocs !== undefined) {
        data['locality_elocs'] = this.route.snapshot.queryParams.locality_elocs;
      }

      if (this.transaction_type_id !== undefined) {
        data['transaction_type_id'] = this.transaction_type_id;
      }
      if (this.suitable_for_id !== undefined) {
        data['suitable_for_id'] = this.suitable_for_id;
      }
      if (this.propertytypeid !== undefined) {
        data['property_type_id'] = this.propertytypeid;
      }
      data['is_luxury_property'] = this.isLuxuryProperty;
      this.router.navigate(['luxury-property'], { queryParams: data });
      setTimeout(() => {
        // this.getPropertyList('', '', 0);
        // this.ref.detectChanges();
        window.location.reload();
      }, 200);
    }
  }

  clearBudgetFilter() {
    this.minBudgetAmountValue = null;
    $("#min_budget_amount").val('');
    this.maxBudgetAmountValue = null;
    $("#max_budget_amount").val('');
    this.searchboxapply(0);
    $('#clearBudgetFilter').hide();
  }

  zoomChange(e: any) {
    if (this.isZoomFlag === false) {
      this.isLoading = true;
      const tmpMap = this.propMap;
      const bounds = tmpMap.getBounds();
      const NorthWest: any = [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()];
      const SouthEst: any = [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()];
      this.getPropertyList(NorthWest, SouthEst, 0);
    }
  }

  openFilterPropertyModal(): void {
    $('#filterPropertyModal').modal('toggle');
  }

  toggleMapView() {
    $('.show-map').hide();
    $('.show-list').show();
    $('.map-view-main-outer').show();
    $('.content-view-main-outer').hide();
  }

  toggleListView() {
    $('.show-map').show();
    $('.show-list').hide();
    $('.map-view-main-outer').hide();
    $('.content-view-main-outer').show();
  }

  viewSaveSearched(){
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
    // const querType = [];
    // querType['type'] = 'Buyer';
    this.router.navigate(['/savedsearch'])
    }
  }

}

interface marker {
  pid?: string;
  lat: number;
  lng: number;
  label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
  symbol?: object;
  draggable: boolean;
}
