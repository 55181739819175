<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid home-tour-outer" id="accoutnSettings">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">Home Tours</h4>
        <div class="home-tour">
          <div class="empty-message">
            <img src="{{ search_icon }}" class="home-icon" alt="Home" />
            <div class="empty-text">
              <h5>You Have No Upcoming Home Tours</h5>
              <p>Find homes you’d like to see in person and schedule free, no-obligation tours with a local Empire Realtors Agent, at times that work for you. </p>
            </div>
            <button class="btn ac_btn" [routerLink]="['/']">Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
