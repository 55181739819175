<!-- <app-flash></app-flash> -->
<div class="page-2">
  <div class="container-fuid home-container">
    <!-- ======= Start Banner Video Section ======= -->
    <section class="banner-header" id="bannersection">
      <div class="container-fuid" style="margin:0 auto;">
        <img class="" id="videoBG" src="../../../assets/img/home-search.jpg" alt="">
        <!-- <video id="videoBG" [muted]="true" [autoplay]="true">
          <source src="../../../assets/img/home_banner.mp4" type="video/mp4">
        </video> -->
      </div>
    </section>
    <!-- ======= Ends Banner Video Section ======= -->

    <!-- ======= Start Hero Section ======= -->
    <section class="services-header" id="firstsection">
      <div class="container-fuid" style="margin:0 auto;">
        <div class="row">
          <div class="col-sm-12">
            <div class="testFocus">
              <div class="row">
                <div class="search-tabs clearfix firstsection col-sm-12">
                  <div class="input-group banner-search-box wrap_input">
                    <input type="text" id="searchinput" value="" class="form-control form-control-lg"
                      (focus)="hideshow(1)" placeholder="Type Location or Project/Society or Keyword">
                    <div class="input-group-append m-0" (click)="searchProperty()">
                      <button class="btn btn-secondary secndysize" type="button" >
                        <i class="fa fa-search fa-2x"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= End Hero Section ======= -->

    <!-- ======= Start Personalized Filter Section ======= -->
    <section id="personalized_filter" class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <div class="section-title">
              <h2 class="default-color-dark">Personalized Filter</h2>
            </div>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="proptype-select">
              <h4>
                <span>
                  <i class="fa fa-home default-color-dark i-fsize20 v-align-middle" aria-hidden="true"></i>
                  <button id="residential_btn" class="fp_type fp_type_active"
                    (click)="onFilterSelect(1)">Residential</button>
                </span>
                <span class="pl-4">
                  <i class="fa fa-building-o default-color-dark i-fsize16 v-align-middle" aria-hidden="true"></i>
                  <button id="commerical_btn" class="fp_type" (click)="onFilterSelect(2)">Commercial</button>
                </span>
                <!-- <span class="pl-4">
                  <i class="fa fa-university default-color-dark i-fsize16 v-align-middle" aria-hidden="true"></i>
                  <button id="investment_btn" class="fp_type" (click)="onFilterSelect(3)">Investment</button>
                </span> -->
              </h4>
            </div>
          </div>
        </div>

        <div class="row d-flex align-items-center-center">
          <!-- Home -->
          <div class="col-md-3 single-persona-block single-persona-block-2 residentialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('2')">
              <img class="imghover img-fluid" src="../assets/img/persona/single-property-families.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Young Buyer</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#">Amit a young engineer with work experience of 4 years with age of 28, just got married 2
                  years back drawing salary for 35K his wife also looking for Job - as School Teacher - Both combine
                  will draw Salary of 50K.</a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-4 residentialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('4')">
              <img class="imghover img-fluid" src="../assets/img/persona/kids-lovable-properties.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Kids Safe Properties</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#">Meeta & Harish are married happily, having one kid Kabir. Both parents are working and their
                  choice is to find a property which will make Kabir comfortable going to play center close by or a
                  School which is close to their residence.</a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-1 residentialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('1')">
              <img class="imghover img-fluid" src="../assets/img/persona/senior-citizen-properties.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Senior Buyers</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#">Saritaben (60) & Surendrabhai(62) have a big house and their kids are settled abroad with no
                  planning to return back to their home country. It has been stressful for Seniors to maintain their
                  current home as mostly they only use ground floor</a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-3 residentialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('3')">
              <img class="imghover img-fluid" src="../assets/img/persona/working-parents.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Affordable Housing</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <!-- Home -->

          <!-- Commercial -->
          <div class="col-md-3 single-persona-block single-persona-block-7 commercialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('7')">
              <img class="imghover img-fluid" src="../assets/img/persona/Start_Up.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Start Up's</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-10 commercialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('10')">
              <img class="imghover img-fluid" src="../assets/img/persona/professionals.png" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Professionals</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-6 commercialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('6')">
              <img class="imghover img-fluid" src="../assets/img/persona/business.png" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Entrepreneur</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-8 commercialDiv">
            <div class="container-hover pop" (click)="openPersonaModel('8')">
              <img class="imghover img-fluid" src="../assets/img/persona/visa_consultant.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>IT Firms</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <!-- Commercial -->

          <!-- Investment -->
          <div class="col-md-3 single-persona-block single-persona-block-20 investDiv">
            <div class="container-hover pop" (click)="openPersonaModel('20')">
              <img class="imghover img-fluid" src="../assets/img/persona/new_project.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>New Project</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-21 investDiv">
            <div class="container-hover pop" (click)="openPersonaModel('21')">
              <img class="imghover img-fluid" src="../assets/img/persona/commercial_scheme.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Commercial Scheme</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-22 investDiv">
            <div class="container-hover pop" (click)="openPersonaModel('22')">
              <img class="imghover img-fluid" src="../assets/img/persona/residential_scheme.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Residential Scheme</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-md-3 single-persona-block single-persona-block-19 investDiv">
            <div class="container-hover pop" (click)="openPersonaModel('19')">
              <img class="imghover img-fluid" src="../assets/img/persona/warehouse_space.jpg" alt="" />
              <div class="titlee text-center text-uppercase">
                <h5>Warehouse Space</h5>
              </div>
              <div class="overlayy">&nbsp;</div>
              <div class="contenthover">
                <a href="#"> Our Empire Agent had an entire team working with him so someone was always available when
                  we needed them. I can’t imagine buying a home any other way </a>
                <a href="#" class="mx-auto">View More <i class="fa fa-arrow-right fl-2x" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <!-- Investment -->

          <div class="col-md-9" id="personaSlideOut">
            <button type="button" class="personaCloseButton" (click)="closePersonaModel()">&times;</button>
            <div class="persona-details">
              <h2 [innerHTML]="personaTitle"></h2>
              <p [innerHTML]="personaText"></p>
              <div class="row d-flex">
                <div class="col-md-6">
                  <div class="row d-flex">
                    <div class="col-md-6">
                      <div class="single-select">
                        <ng-multiselect-dropdown [placeholder]="'Min Budget'" [settings]="singleSelectdropdownSettings"
                          [data]="budgetList" [(ngModel)]="min_budget"></ng-multiselect-dropdown>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="single-select">
                        <ng-multiselect-dropdown (onSelect)="onMaxBudgetChange()" [placeholder]="'Max Budget'"
                          [settings]="singleSelectdropdownSettings" [data]="budgetList" [(ngModel)]="max_budget">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
                    <div class="{{isSchool == true ? 'col-md-6' : 'col-md-12'}}"
                      *ngIf="propertytypeid != '3' && isBedrooms">
                      <div class="single-select">
                        <ng-multiselect-dropdown [placeholder]="'Bed Room'" [settings]="multiSelectdropdownSettings"
                          [data]="bedroomList" [(ngModel)]="bedrooms"></ng-multiselect-dropdown>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="isSchool">
                      <div class="single-select">
                        <ng-multiselect-dropdown [placeholder]="'School'" [settings]="multiSelectdropdownSettings"
                          [data]="schoolList" [(ngModel)]="school"></ng-multiselect-dropdown>
                      </div>
                    </div>
                    <!-- <div class="col-md-12" *ngIf="isNoOfStaff">
                      <div class="title_afford">No. Of Staff</div>
                      <div class="single-select">
                        <input type="number" value="" [(ngModel)]="minNoOfStaff" class="form-control" placeholder="No. Of Staff" (keyup)="onChangeNoOfStaff()">
                      </div>
                    </div> -->
                    <div class="col-md-12">
                      <div class="single-select">
                        <input type="text" value="" class="form-control searchPersonalInput" (focus)="hideshow(1)"
                          placeholder="Type Location">
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="!isAffordableCal">&nbsp;</div>
                    <div class="col-md-12" *ngIf="isAffordableCal">
                      <div class="tabs_titles_floors_tab_content" [formGroup]="emiForm">
                        <div class="title_afford" [routerLink]="['/affordable-calculator', suitable_for]">Affordability
                          Calculator</div>
                        <div class="title_afford" [routerLink]="['/affordable-calculator', suitable_for]"
                          style="display: none !important;">EMI: <span id="cost_month"></span></div>
                        <div class="row d-flex" style="display: none !important;">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="input-label" for="home_price">Home Price</label>
                              <div class="custom_input_prepend">
                                <i class="fa fa-rupee"></i>
                                <input type="number" id="home_price" [(ngModel)]="home_price"
                                  formControlName="home_price" [ngClass]="{ 'is-invalid': g.home_price.errors }"
                                  class="form-control" placeholder="Home Price" (keyup)="onEMICalPriceChange()" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="inter-flex">
                                <label class="input-label down_payment" for="down_payment">Down payment</label>
                                <label class="input-label down_payment_per" for="down_payment_per">Down payment
                                  %</label>
                              </div>
                              <div class="inter-flex">
                                <div class="custom_input_prepend">
                                  <i class="fa fa-rupee"></i>
                                  <input type="number" id="down_payment" [(ngModel)]="down_payment"
                                    formControlName="down_payment" [ngClass]="{ 'is-invalid': g.down_payment.errors }"
                                    class="form-control" placeholder="Down payment"
                                    (keyup)="onEMICalDownPaymentChange()" />
                                </div>
                                <div class="custom_input_append">
                                  <input type="number" id="down_payment_per" [(ngModel)]="down_payment_per"
                                    formControlName="down_payment_per"
                                    [ngClass]="{ 'is-invalid': g.down_payment_per.errors }" class="form-control"
                                    placeholder="Down payment per" (keyup)="onEMICalDownPaymentPerChange()" />
                                  <span class="append_text">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="input-label" for="loan_program">Loan program</label>
                              <select [(ngModel)]="loan_program" id="loan_program" class="form-control"
                                (change)="onEMICalTermChange()" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let loan_program_option of loan_program_options"
                                  value="{{ loan_program_option.id }}">
                                  {{ loan_program_option.title }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="input-label" for="int_rate">Interest rate</label>
                              <div class="custom_input_append">
                                <input type="number" id="int_rate" [(ngModel)]="int_rate" formControlName="int_rate"
                                  [ngClass]="{ 'is-invalid': g.int_rate.errors }" class="form-control"
                                  placeholder="Interest rate" (keyup)="onEMICalIntRateChange()" />
                                <span class="append_text">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <a href="javascript:;" class="btn text-center" (click)="applyPersonaFilter()">View Properties</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <ul class="amenitiesList" *ngIf="isAmenities">
                    <li *ngFor="let amenitiesListItem of amenitiesList">
                      <i class="{{amenitiesListItem.icon}}" title="{{amenitiesListItem.text}}"></i>
                      <p class="amenities_title">{{amenitiesListItem.text}}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- ======= End Personalized Filter Section ======= -->

    <!-- ======= Start RPCF (Carnival) Section ======= -->
    <!-- <section class="container-fluid py-3">
      <div class="container" id="rpcf_section">
        <div class="row">
          <div class="col-md-6">
            <img src="../assets/img/discount_img.png" class="img-fluid showed">
          </div>
          <div class="col-md-6 d-flex flex-column justify-content-center consultation_section_outer">
            <div class="consultation_section_inner">
              <div class="consultation_section_text text-center">
                <h2 class="boldcl text-white">Ready Possession Carnival</h2>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-append text-center mx-auto">
                    <button class="ml-3 btn btn-secondary secndysize" type="button" (click)="searchCarnivalProperty()">Explore</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- ======= Start RPCF (Carnival) Section ======= -->

    <!-- ======= Start SELLING Section ======= -->
    <section id="consultation_section" class="container-fluid">
      <div class="container container_div">
        <div class="row">
          <div class="col-md-6" style="padding-left: 0px;padding-right: 0px;">
            <img src="../assets/img/sell_home.jpg" class="img-fluid showed">
          </div>
          <div class="col-md-6 d-flex flex-column justify-content-center consultation_section_outer">
            <div class="consultation_section_inner">
              <div class="consultation_section_text">
                <div class="section-title">
                  <h2 class="boldcl default-color-dark">SELLING</h2>
                </div>
                <h4 class="clr default-color">Sell for more than the home next door</h4>
                <p class="clr default-color">Request a free, no-obligation Consultation with a local Empire Realtor
                  Agent</p>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input type="text" class="form-control form-control-lg" (blur)="handleSellingInPut()" id="sellingSearchInput" value=""
                    placeholder="Type Location or Keyword">
                  <div class="input-group-append">
                    <button class="btn btn-secondary secndysize border-search" type="button"
                      (click)="frontLeadGenerator()" style=" background: #ffffff;"><i class="fa fa-search"></i></button>
                  </div>
                </div>
                <p>{{values}}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 reviews_section_outer">

            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="reviews_section_inner">
                    <div class="reviews_section_text">
                      <div class="section-title">
                        <h3 class="boldcl default-color-dark">Ravindra and Nita</h3>
                      </div>
                      <h5 class="c_post default-color">Bought our home with a Empire Realtor Agent in India.</h5>
                      <blockquote>It was such a pleasure working with Empire Realtor who seemed to answer all the
                        questions.</blockquote>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="reviews_section_inner">
                    <div class="reviews_section_text">
                      <div class="section-title">
                        <h3 class="boldcl default-color-dark">Dhaval and Dhrutika</h3>
                      </div>
                      <h5 class="c_post default-color">Sold their home with the help of Empire Realtor Agent.</h5>
                      <blockquote>We like their team approach , and they did a great job showcasing our place online
                        with pictures and videos.</blockquote>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="reviews_section_inner">
                    <div class="reviews_section_text">
                      <div class="section-title">
                        <h3 class="boldcl default-color-dark">Rahul and Anjali</h3>
                      </div>
                      <h5 class="c_post default-color">Found property with the help of Empire Realtor Agent.</h5>
                      <blockquote>After searching for several weeks, Empire Realtor helped me in finding the best place
                        for us. This service is fantastic and eliminated all the hassle.</blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex flex-column" style="padding-left: 0px;padding-right: 0px;">
            <div id="carouselExampleFade2" class="carousel slide carousel-fade" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img class="d-block w-100" src="../assets/img/web-image/testimonials.jpg" alt="First slide">
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" src="../assets/img/web-image/testimonials2.jpg" alt="Second slide">
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" src="../assets/img/web-image/testimonials3.jpg" alt="Third slide">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= End SELLING Section ======= -->

    <!-- ======= Start BUYING Section ======= -->
    <section class="container-fluid py-4" hidden>
      <div class="container" id="buying_section">
        <div class="buying_section_box">
          <h6 class="top_text">BUYING</h6>
          <h3 class="title">Find homes first, tour homes fast</h3>
          <p class="description">Get an edge over other buyers with our online tools and a local Redfin Agent.</p>
          <div class="buy_option_outer">
            <svg class="svg_icon star">
              <svg viewBox="0 0 22 21">
                <path
                  d="M7.518 6.778L.2 7.844a.235.235 0 0 0-.13.4l5.294 5.175-1.25 7.305a.235.235 0 0 0 .341.249L11 17.523l6.544 3.45a.235.235 0 0 0 .34-.249l-1.25-7.305 5.294-5.174a.235.235 0 0 0-.13-.401l-7.316-1.066L11.21.13a.234.234 0 0 0-.42 0z"
                  fill-rule="evenodd"></path>
              </svg>
            </svg>
            <div class="description-item">
              <h2 class="inner_title">Find homes first</h2>
              <p class="inner_description">you see the latest listings every 5 minutes and get new home updates 3 hours
                faster.</p>
            </div>
          </div>
          <div class="buy_option_outer">
            <svg class="svg_icon lightning-bolt">
              <svg viewBox="0 0 19 32">
                <path
                  d="M15.493.127L.086 18.27a.357.357 0 0 0 .269.589h6.313l-3.77 12.679c-.11.37.36.629.608.334l15.408-18.141a.358.358 0 0 0-.27-.59h-6.31L16.1.462c.11-.37-.358-.63-.608-.336z"
                  fill-rule="evenodd"></path>
              </svg>
            </svg>
            <div class="description-item">
              <h2 class="inner_title">Tour faster</h2>
              <p class="inner_description">book online and tour homes ASAP, even same day.</p>
            </div>
          </div>
          <div class="buy_option_outer">
            <svg class="svg_icon price">
              <svg viewBox="0 0 24 24">
                <path
                  d="M12 11c-1.654 0-3-1.306-3-3s1.346-3 3-3a2.975 2.975 0 0 1 2.991 2.764c.01.132.111.236.243.236h1.503a.256.256 0 0 0 .256-.264A4.973 4.973 0 0 0 13 3.099V1.25a.25.25 0 0 0-.25-.25h-1.5a.25.25 0 0 0-.25.25v1.849A4.976 4.976 0 0 0 7 8c0 2.797 2.243 5 5 5 1.654 0 3 1.306 3 3s-1.346 3-3 3a2.975 2.975 0 0 1-2.991-2.764.247.247 0 0 0-.243-.236H7.263a.256.256 0 0 0-.256.264A4.971 4.971 0 0 0 11 20.9v1.85c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25V20.9c2.279-.457 4-2.45 4-4.9 0-2.797-2.243-5-5-5"
                  fill-rule="evenodd"></path>
              </svg>
            </svg>
            <div class="description-item">
              <h2 class="inner_title">Save $8,200 on average</h2>
              <p class="inner_description">you save thousands and get full service when you buy and sell with Redfin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= Start BUYING Section ======= -->

    <!-- ======= Start Top Projects Section ======= -->
    <section class="container-fluid" id="feature">
      <div *ngIf="featuresProjectList.length > 0" class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h2 class="default-color-dark">Top Projects</h2>
            </div>
          </div>
        </div>
        <div class="row" id="projectDiv">
          <div class="col-md-12">
            <div *ngIf="featuresProjectList.length > 0" class="" id="suggested_project">
              <div class="ss_property_view" id="suggested_project_view">
                <owl-carousel [options]="customOptions" [items]="propertyList">

                <div class="col-sm-6 single_property_grid_project" *ngFor="let item of featuresProjectList">
                  <div *ngIf="(item.name)" class="d-flex single_property_view">
                    <a [routerLink]="['/project-details', item.id ]" class="card" href="#" target="_blank">
                      <div [style.background-image]="'url(\'' + item.image_preview + '\')'" class="grid_image_view">

                        <div class="property_badge top_left" *ngIf="item.project_status">
                          <span class="property_badge_sell badge-color">{{item.project_status}}</span>
                        </div>
                        <!--<div (click)="makeProjFavorite(item.id, item.is_favorited);$event.stopPropagation()"
                             [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }"
                             class="property_badge top_right favoriteIcon_{{item.id}}">
                          <i *ngIf="item.is_favorited == 0" class="fa fa-heart" style="color: white"></i>
                          <i *ngIf="item.is_favorited == 1" class="fa fa-heart" style="color: #F02174;"></i>
                        </div>-->
                      </div>
                      <div class="card-body card_body_width">
                        <h5 class="card-title">{{item.name}}</h5>
                        <p class="card-text">
                          {{ item.project_type }}
                        </p>
                        <p class="card-text">
                          <i aria-hidden="true" class="fa fa-home"></i> {{ item.project_unit_group }}
                        </p>
                        <p class="card-text">
                          <i aria-hidden="true" class="fa fa-map-marker"></i> {{item.property_name}}
                          {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                        </p>
                        <!--<button [routerLink]="['/project-details', item.id ]" class="btn project-owner-btn">Contact
                          Owner</button>-->
                      </div>
                    </a>
                  </div>
                </div>
              </owl-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= End Top Projects ======= -->



    <!-- ======= Feature Properties Section ======= -->
    <section class="feature-properties">
      <div class="container">
        <div class="row mx-0 content mb-2">
          <div class="col-md-12 px-0">
            <h2 class="title">Feature Properties</h2>
          </div>

        </div>
        <div class="property_grid_view_class" id="property_grid_view">
          <owl-carousel [options]="customOptions" [items]="propertyList">
            <div class="col-sm-6 d-flex single_property_grid" *ngFor="let item of propertyList ">
              <ng-container
                *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                <a class="card" [routerLink]="['/property-details', item.id ]" href="#" target="_blank">
                  <div [style.background-image]="'url(\'' + item.image_preview + '\')'" class="grid_image_view">
                    <div class="property_badge top_left" *ngIf="item.prop_transaction_type">
                      <span class="property_badge_sell" *ngIf="item.is_coming_soon == 1">Coming Soon</span>
                      <span *ngIf="item.property_status_id && item.is_coming_soon == 0"
                        class="property_badge_sell">{{item.prop_status.title}}</span>
                    </div>
                    <!-- <div class="property_badge top_right favoriteIcon_{{item.id}}"
                      [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }" *ngIf="item.prop_transaction_type">
                      <i id="i_favIcon_{{item.id}}" class="fa fa-heart fav_white_color"></i>
                    </div> -->
                  </div>
                  <div  class="card-body car-body-width">
                    <h5 class="card-title">{{item.name}}</h5>
                    <!-- <p class="card-text card-tex-col"><i class="fa fa-rupee"></i>{{item.asking_price}}</p> -->
                    <p class="card-text card-tex-col">
                      <i aria-hidden="true" class="fa fa-map-marker"></i>  {{item.locality_title}} {{(item.city_title ? item.city_title : '')}}
                    </p>
                    <!-- <p class="card-text">
                      <span class="pro_spec" *ngIf="item.total_showers"> <i class="fa fa-bath"></i>
                        {{item.total_showers}} Bath </span>
                      <span class="pro_spec" *ngIf="item.total_bedrooms"> <i class="fa fa-bed"></i>
                        {{item.total_bedrooms}} Bed </span>
                      <span class="pro_spec" *ngIf="item.saleable_area"> <i class="fa fa-check-square"></i>
                        {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                    </p> -->
                  </div>
                </a>
              </ng-container>
            </div>
            <!-- <div class="col-md-12 text-right" *ngIf="isPropperty == true">
        <pagination-controls id="property_grid" (pageChange)="pageChange($event, 10)" class="my-pagination"
          maxSize="9"></pagination-controls>
      </div> -->
          </owl-carousel>
        </div>
      </div>
    </section>
    <!-- ======= Feature Properties Section ======= -->

    <!-- ======= Start Estimater  Section ======= -->
    <section id="estimater_section" class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="estimater_section_inner">
              <h3 class="s_title">See how much you could save with Empire Realtors</h3>
              <p class="s_text">Buy with a Empire Realtors Agent and we'll give part of our commission to you. Sell with
                Empire Realtors and pay a 1.5% listing fee. Either way, you'll get second-to-none service and could save
                thousands.</p>
              <div class="row estimater_price_text">
                <div class="col-md-6 d-flex flex-column">
                  <span class="estimater_amount" id="amount_buy_save">$1,905</span>
                  <span class="estimater_amount_text">Realtors Buyer Savings</span>
                </div>
                <div class="col-md-6 d-flex flex-column">
                  <span class="estimater_amount" id="amount_sell_save">$1,905</span>
                  <span class="estimater_amount_text">Realtors Seller Savings</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= End Estimater  Section ======= -->

    <!-- ======= Start Technology  Section ======= -->
    <!-- <section id="technology_section" class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="technology_section_inner">
              <h3 class="title default-color-dark">Full-Service Agents, Modern Technology</h3>
              <p class="text default-color">We're full-service, local agents who get to know you over coffee and on home
                tours, and we
                use online tools to make you smarter and faster.</p>
              <a class="btn btn-lg" href="#">See our story</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- ======= End Technology  Section ======= -->

    <!-- ======= Start Cities  Section ======= -->
    <section id="cities_section" class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="area-title" [formGroup]="cityForm">
              <div class='area-title-left'>
                <h4 class="title">Search Properties by Area</h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <select [(ngModel)]="city_eloc1" formControlName="profilecity" class="form-control cityselect"
                  aria-placeholder="Select City" [ngClass]="{ 'is-invalid': submitted && g.profilecity.errors }"
                  (change)="getCitiesArea($event.target.value)">
                  <option *ngFor="let cityOption of city_property_options" value="{{ cityOption.id }}">
                    {{ cityOption.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <ul class="city-list">
              <li *ngFor='let areaList of selectedCityAreaList' class="city col-md-4"><a
                  (click)="applyFilterByArea(areaList.title)" title="Properties in {{areaList.title}}"
                  class="default-color"> Properties in {{ areaList.title }}</a></li>
            </ul>
          </div>
        </div>

        <!-- ======= Start Suitable For Section ======= -->
        <div class="row">
          <div class="col-md-12">
            <div class="area-title">
              <div class='area-title-left'>
                <h4 class="title">Suitable For</h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <select class="form-control cityselect" (change)="getSuitableFor($event.target.value)">
                  <option value="3"> Commercial </option>
                  <option value="1"> Residential </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <ul class="city-list">
              <li *ngFor='let suitable of selectedSuitableFor' class="city col-md-4"><a
                  (click)="applyFilterSuitableFor(suitable.id)" title="{{suitable.title}}" class="default-color"> {{
                  suitable.title }}</a></li>
            </ul>
          </div>
        </div>
        <!-- ======= End Suitable For Section ======= -->

        <div class="">
          <div class="col-md-12">
            <div class="area-title d-block">
              <h4 class="title">Search Properties by School</h4>
              <ul class="area-p-type">
                <li class="default-color-dark m-0" title="Gujarat Secondary Education Board" id="schoolnamegseb"
                  (click)='applyFilterBySchool(1)'>GSEB
                </li>
                <li class="default-color-dark" title="Central Board of Secondary Education" id="schoolnamecbse"
                  (click)='applyFilterBySchool(2)'>
                  CBSE</li>
                <li class="default-color-dark" title="International General Certificate of Secondary Education"
                  id="schoolnameigcse" (click)='applyFilterBySchool(3)'>IGCSE</li>
                <li class="default-color-dark" title="International Baccalaureate" id="schoolnameib"
                  (click)='applyFilterBySchool(4)'>IB</li>
              </ul>
            </div>
          </div>
          <div class="col-md-12">
            <ul class="city-list">
              <li class="city"><a href="#">{{ schollfullname }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= End Cities  Section ======= -->
  </div>
</div>


<!-- ======= Feature Properties Section ======= -->
<!-- <section class="feature-properties">
<div class="container">
  <div class="row mx-0 content">
    <div class="col-md-9 px-0">
      <h5 class="title">Feature Properties</h5>
      <p>Listings of properties with some unique features. </p>
    </div>
    <div class="col-md-3 d-flex flex-column justify-content-center align-items-center">
      <button class="btn btn-view-more" (click)="searchFeatureProperty()">Explore</button>
    </div>
  </div>
</div>
</section> -->
<!-- ======= Feature Properties Section ======= -->

<div class="modal fade" id="personafiltermodel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <div class="row d-flex">
          <div class="col-md-5">
            <img src="{{ personaImgSrc }}" class="imagepreview" alt="{{ personaTitle }}">
          </div>
          <div class="col-md-7">
            <div class="persona-details">
              <h2 [innerHTML]="personaTitle"></h2>
              <p [innerHTML]="personaText"></p>
              <div class="row d-flex">
                <div class="col-md-6">
                  <div class="single-select">
                    <ng-multiselect-dropdown [placeholder]="'Min Budget'" [settings]="singleSelectdropdownSettings"
                      [data]="budgetList" [(ngModel)]="min_budget"></ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="single-select">
                    <ng-multiselect-dropdown [placeholder]="'Max Budget'" [settings]="singleSelectdropdownSettings"
                      [data]="budgetList" [(ngModel)]="max_budget"></ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="propertytypeid != '3'">
                  <div class="single-select">
                    <ng-multiselect-dropdown [placeholder]="'Bed Room'" [settings]="multiSelectdropdownSettings"
                      [data]="bedroomList" [(ngModel)]="bedrooms"></ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="propertytypeid != '3'">
                  <div class="single-select">
                    <ng-multiselect-dropdown [placeholder]="'Bath Room'" [settings]="multiSelectdropdownSettings"
                      [data]="bathroomList" [(ngModel)]="bathroom"></ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="col-md-12">
                  <a href="javascript:;" class="btn text-center" (click)="applyPersonaFilter()">View Available
                    Properties</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cityFilterModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div id="frontLeadGenerator" class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <div class="row d-flex">
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="lead_generator_main">
                  <div class="contact-info-outer p-4">
                    <div class="row d-flex mb-4 save-button">
                      <div class="col-md-12">
                        <div class="row pt-4 justify-content-center">
                          <div class="col-lg-6 text-center py-1">
                            <button type="submit" class="btn btn-block login-btn mx-2"
                              (click)="searchCityProperty('project')">New</button>
                          </div>
                          <div class="col-lg-6 text-center py-1">
                            <button type="submit" class="btn btn-block login-btn mx-2"
                              (click)="searchCityProperty('property')">Re Sale</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  #personalized_filter .multiselect-dropdown[_ngcontent-mwy-c61] .dropdown-btn[_ngcontent-mwy-c61] .selected-item[_ngcontent-mwy-c61] a[_ngcontent-mwy-c61] {
    display: none !important;
    text-decoration: none;
  }
</style>