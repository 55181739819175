import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  private subject = new Subject<any>();
  constructor() { }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  handleConfirmation(message: string,type:boolean) {
    // type is user to diff in success or failed
      this.subject.next({ type: type, text: message });
  }
}
