<div class="container-fluid body_fixer" style="background: #f5f5f5;">
  <section id="job_top">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div id="div_job_img">
            <img class="job_img" src="../../assets/img/rent_img.jpg" />
          </div>
        </div>
        <div class="col-md-6">
          <div id="join_us">
            <p class="title">WHAT TYPE OF RENT/LEASE PROPERTIES WOULD YOU LIKE TO SEE?</p>

            <div id="residential_div" class="prop_div bg_white">
              <label for="residential_radio">
                <p class="sub_title">
                  <input type="radio" id="residential_radio" class="mr-1" value="1" [(ngModel)]="transaction_type_id"
                    (change)="selPropTypeDiv('residential')">
                  <span class="checkmark">&nbsp;RESIDENTIAL</span>
                </p>
                <p class="content">
                  Empire Realtors have choice of both, New Projects and Resale properties. Our team works hard, to bring your dream home closer to you. Explore your options for Residential Properties with Empire Realtors.
                </p>
              </label>
            </div>

            <div id="commercial_div" class="prop_div mt-3 mb-3">
              <label for="commercial_radio">
                <p class="sub_title">
                  <input type="radio" id="commercial_radio" class="mr-1" value="3" [(ngModel)]="transaction_type_id"
                    (change)="selPropTypeDiv('commercial')">
                  <span class="checkmark">&nbsp;COMMERCIAL</span>
                </p>
                <p class="content">
                  Your Growth is Ours. Empire Realtors does analysis where your team can expand. May be its Office Space, showroom, shop or Mall get on searching your business location either new or old. with Empire Realtors.
                </p>
              </label>
            </div>

          </div>

          <div class="view_btns prop_btn">
            <button type="submit" class="btn login-btn mr-3 width_20" (click)="searchProperty('property')">Explore
              &nbsp;&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
