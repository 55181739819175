/* tslint:disable:quotemark variable-name triple-equals object-literal-shorthand */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from './../../_services/api.service';
import { FlashService } from '../../_services/FlashService.service';
import { API_BASE_URL, API_ENDPOINTS } from '../../global';
import {Meta, Title} from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-myaccount',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss']
})
export class MyaccountComponent implements OnInit {

  submitted = false;
  submittedp = false;
  formSubmiting = false;
  profileForm: FormGroup;
  passwordForm: FormGroup;
  profile_image: string = 'assets/img/web-image/loader.gif';
  first_name: string = '';
  last_name: string = '';
  address_line1: string = '';
  address_line2: string = '';
  state_options = [];
  state_eloc: string = '';
  city_options = [];
  city_eloc: string = '';
  email: string = '';
  phone: string = '';
  selectedFile: ImageView;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  area_eloc: string = '';
  area_title: string = '';
  area_lat: string = '';
  area_lng: string = '';
  oldPass: string = '';
  newPass: string = '';
  confPass: string = '';
  localitySearch;
  dashboardType = 'buyer';
  constructor(private ApiService: ApiService,  private router: Router, private route: ActivatedRoute, private FlashService: FlashService, private title: Title, private formBuilder: FormBuilder) {
    window.scroll(0,0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if(this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
    });
    this.getStates();
    this.getCities();
    this.getAccountDetails();

    this.profileForm = this.formBuilder.group({
      addressline1: ['', [Validators.required]],
      addressline2: ['', []],
      prolocality: ['', []],
      profilestate: ['', [Validators.required]],
      profilecity: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      profileEmail: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
    });

    this.passwordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      newpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
    });

    setTimeout (() => {
      this.changeHeaderClass();
      this.localitySearch = $('#locality_search');
      this.localitySearch.typeahead(null, {
        name: 'localitySearch',
        displayKey: 'placeName',
        source: (search, sync, async) => {
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL+'/master/getLocality',
              data: {is_mobile_api: 1, search: search},
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      var componentObj = this;
      this.localitySearch.on('typeahead:selected', function (e, datalocitem) {
        componentObj.area_eloc = datalocitem.eLoc;
        componentObj.area_title = datalocitem.placeName;
        componentObj.area_lat = datalocitem.latitude;
        componentObj.area_lng = datalocitem.longitude;
      });
    }, 200);
  }

  get g() {
    return this.profileForm.controls;
  }

  get p() {
    return this.passwordForm.controls;
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Account Setting');
  }

  getAccountDetails(): void  {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_PROFILE, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.profile_image = apiResult.data.image_preview;
        this.first_name = apiResult.data.first_name;
        this.last_name = apiResult.data.last_name;
        this.email = apiResult.data.email;
        this.phone = apiResult.data.contact_no;
        this.address_line1 = apiResult.data.address_line_1;
        if(apiResult.data.address_line_2 && apiResult.data.address_line_2!=="null"){
          this.address_line2 = apiResult.data.address_line_2;
        }
        this.state_eloc = apiResult.data.state_eloc;
        this.city_eloc = apiResult.data.city_eloc;
        this.area_eloc = apiResult.data.area_eloc;
        this.area_title = apiResult.data.area_title;
        this.area_lat = apiResult.data.area_lat;
        this.area_lng = apiResult.data.area_lng;
        this.localitySearch.typeahead('val', this.area_title);
      }
    });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageView(event.target.result, file);
      this.profile_image = this.selectedFile.src;
    });
    reader.readAsDataURL(file);
  }

  onProfileSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    var oldFName = userInfo.first_name;
    var oldLName = userInfo.last_name;
    var oldName = userInfo.name;
    userInfo.first_name = this.first_name;
    userInfo.last_name = this.last_name;
    userInfo.name = this.first_name + " " + this.last_name;
    localStorage.setItem('currentUser', JSON.stringify(userInfo));

    const firstName=userInfo.first_name !=="undefined" && userInfo.first_name !=="null" ? userInfo.first_name : ''
    const lastName=userInfo.last_name !=="undefined" && userInfo.last_name !=="null" ? userInfo.last_name : ''

    localStorage.setItem('username', firstName+' '+lastName);
    this.formSubmiting = true;
    var form_data = new FormData();
    form_data.append('first_name', this.first_name);
    form_data.append('last_name', this.last_name);
    form_data.append('contact_no', this.phone);
    form_data.append('address_line_1', this.address_line1);
    form_data.append('address_line_2', this.address_line2);
    form_data.append('city_eloc', this.city_eloc);
    form_data.append('state_eloc', this.state_eloc);
    form_data.append('area_eloc', this.area_eloc);
    form_data.append('area_title', this.area_title);
    form_data.append('area_lat', this.area_lat);
    form_data.append('area_lng', this.area_lng);
    if (this.selectedFile) {
      form_data.append('image_file', this.selectedFile.file);
    }
    this.ApiService.postRequest(API_ENDPOINTS.API_UPDATE_PROFILE, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        if (oldFName != this.first_name || oldLName != this.last_name || oldName != userInfo.name) {
            window.location.reload();
        }
        this.FlashService.success("Profile Successfully Updated.");
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.formSubmiting = false;
    }, error => {
      this.formSubmiting = false;
      this.FlashService.error("Something wrong!");
    });
  }

  onPasswordSubmit() {
    this.submittedp = true;
    if (this.passwordForm.invalid) {
      return;
    }
    this.formSubmiting = true;
    var form_data = new FormData();
    form_data.append('old_password', this.oldPass);
    form_data.append('password', this.newPass);
    form_data.append('confirm_password', this.confPass);
    this.ApiService.postRequest(API_ENDPOINTS.API_UPDATE_PASSWORD, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.FlashService.success(apiResult.data);
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.formSubmiting = false;
    }, error => {
      this.formSubmiting = false;
      this.FlashService.error("Something wrong!");
    });
  }

  getStates() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_STATES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.state_options = apiResult.data;
      }
    });
  }

  getCities(state_eloc?:any) {
    var form_data = new FormData();
    if(state_eloc){
      form_data.append('state_eloc', state_eloc);
    }
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_CITIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        if (apiResult.data.length > 0 && state_eloc) {
          this.city_eloc = apiResult.data[0]['id'];
        }
        this.city_options = apiResult.data;
      }
    });
  }
  getStateCities(stateEloc) {
    let state_eloc= stateEloc;
    setTimeout(() => {
      this.getCities(state_eloc);
    }, 500);
  }
}

class ImageView {
  constructor(public src: string, public file: File) {}
}
