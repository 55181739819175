<!-- Start Header -->
 <app-header></app-header>
<!--<app-property-list-header></app-property-list-header>-->
<!-- End Header -->
<div class="align_body">
    <router-outlet>
        <app-flash></app-flash>
    </router-outlet>
</div>
<!-- Start Footer -->
<app-footer></app-footer>
<!-- End Footer -->
