/* tslint:disable */
import {Component, OnInit, ChangeDetectorRef, ViewEncapsulation, Inject} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS, WEB_URL } from '../../global';
import { Meta, Title } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import {DOCUMENT} from '@angular/common';
import { Lightbox } from 'ngx-lightbox';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FlashService } from '../../_services/FlashService.service';

declare var $: any;
declare const require: any;
declare var google:any;

@Component({
  selector: 'app-propertydetails',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './propertydetails.component.html',
  styleUrls: ['./propertydetails.component.scss']
})

export class PropertydetailsComponent implements OnInit {

  emiForm: FormGroup;
  shareform: FormGroup;
  submitted = false;
  propertyinfo: any = [];
  similerProperties: any = [];
  suggestedProperties: any = [];
  schedulevisitdata = {};
  isLoggedIn;
  isFavoriting: boolean = false;
  showPropImages: boolean = true;
  nearByLandmarks: string = 'school';

  zoom: number = 13;
  lat: number = 22.295492;
  lng: number = 73.173463;
  markers: marker[] = [];
  schoolMarkers: marker[] = [];
  icon = {
    url: 'assets/img/map_property_pin.png',
    scaledSize: {
      width: 33,
      height: 50
    }
  };
  iconSchool = {
    url: 'assets/img/school_pin.png',
    scaledSize: {
      width: 25,
      height: 41
    }
  };
  propMap: any;

  home_price: number = 500000;
  down_payment: number = 50000;
  new_home_price: number = this.home_price - this.down_payment;
  down_payment_per: number = 20;
  loan_program_options: any = [];
  loan_program: number = 4;
  int_rate: number = 7.25;

  inc_morg_ins: boolean = false;

  property_tex_per: number = 1;
  property_tex_yearly: number = 10;

  home_ins_year: number = 6000;

  hoa_per_month: number = 0;

  water_sewer_month: number = 0;
  gas_month: number = 0;
  internet_month: number = 0;
  electric_month: number = 0;
  enableSticky: boolean = false;
  _album = [];
  _video = [];
  clicked = false;
  safeSrc: SafeResourceUrl;
  tourInPerson = true;
  tourVideoChat = false;

  constructor(private ref: ChangeDetectorRef, private router: Router,  private route: ActivatedRoute, private ApiService: ApiService, private title: Title, private meta: Meta, private formBuilder: FormBuilder, @Inject(DOCUMENT) private document:Document, private _lightbox: Lightbox, private sanitizer: DomSanitizer, private FlashService: FlashService) {

  }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.route.params.subscribe(routeParams => {
      this.emiForm = this.formBuilder.group({
        home_price: ['', [Validators.minLength(1), Validators.min(1)]],
        down_payment: ['', [Validators.minLength(1), Validators.min(1)]],
        down_payment_per: ['', [Validators.minLength(1), Validators.min(1)]],
        int_rate: ['', [Validators.minLength(1), Validators.min(1)]],
        home_ins_year: ['', [Validators.minLength(0), Validators.min(0)]],
        hoa_per_month: ['', [Validators.minLength(0), Validators.min(0)]],
        water_sewer_month: ['', [Validators.minLength(0), Validators.min(0)]],
        gas_month: ['', [Validators.minLength(0), Validators.min(0)]],
        internet_month: ['', [Validators.minLength(0), Validators.min(0)]],
        electric_month: ['', [Validators.minLength(0), Validators.min(0)]],
        property_tex_per: ['', [Validators.minLength(0), Validators.min(0)]]
      });
      this.getLoanMonthly();
      this.getPropertyDetails();
      this.isLoggedIn = this.ApiService.loginCheck();
      setTimeout (() => {
        // this.generateAppointmentSlider();
        $(document).ready(() => {
          $('#shrehide').click(() => {
            $('#visit').toggle();
          });
          $("#hidendiv").click(() => {
            $("#propertydimention").toggle();
          });
        });
        this.changeHeaderClass();
        this.loan_program_options = [
          {id: 1, title: '5 Years'},
          {id: 2, title: '10 Years'},
          {id: 3, title: '15 Years'},
          {id: 4, title: '20 Years'}
        ];
      }, 200);
    });

    this.shareform = this.formBuilder.group({
      emailShare: ['', [Validators.required, Validators.email]]
    });

    fromEvent(window, "scroll").subscribe((event) => {
      if (this.document.defaultView.scrollY > 750) {
        this.enableSticky=true;
      } else {
        this.enableSticky=false;
      }
    });
  }

  get g() {
    return this.emiForm.controls;
  }

  get k() {
    return this.shareform.controls;
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  mapReady(map) {
    this.propMap = map;
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    var position = new google.maps.LatLng(this.lat, this.lng);
    this.propMap.panTo(position);
  }

  addMarker(markerN) {
    this.markers.push(markerN);
  }

  addSchoolMarker(markerN) {
    this.schoolMarkers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  viewSimilarPropDetails(propId) {
    window.open(WEB_URL + '/property-details/' + propId , '_blank')

  }

  generateAppointmentSlider(slotDatesAry) {
    const d = new Date();
    const dateDay = String(d.getDate()).padStart(2, '0');
    const a = [];
    const dateDayi = parseInt(dateDay);

    for (let prop in slotDatesAry) {
      const slotData = slotDatesAry[prop];
      let dyname = slotData.day_name;
      let datestr = slotData.date_fromat;
      let actClass = '';
      let bookClass = '';
      let title = '';
      if(slotData.slot !== 'book' && parseInt(slotData.day) === dateDayi) {
        actClass = "s_day_active";
      }
      if(slotData.slot === 'book') {
        bookClass = 's_booked_slot';
        title = 'Fully Booked';
      }
      a.push('<div class="single_day '+ actClass +' ' + bookClass + '" data-date="'+datestr+'" data-slot="'+slotData.slot+'" title="'+title+'"><label class="day_name">'+dyname+'</label><label class="day_date">'+slotData.day+'</label><label class="month_name">'+slotData.month+'</label></div>');
    }
    $("#visit_date_slider").html(a);

    $('#visit_date_slider').owlCarousel({
      loop: false,
      nav: true,
      navText : ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>","<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive:{}
    });

    $('.single_day').on('click', function(event) {
      if ($(this).data('slot') === 'available') {
        $('.s_day_active').removeClass('s_day_active');
        $(this).addClass('s_day_active');
      }
    });
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Property Details');
  }

  initSimilarPropertyViewSlider(){
    $('#similar_property_view').lightSlider({
      gallery: false,
      item: 2.6,
      loop: false,
      slideMargin: 15,
      responsive : [
        {
          breakpoint: 767,
          settings: {
            item: 1.5
          }
        }
      ]
    });
  }

  initSuggestedPropertyViewSlider(){
    $('#suggested_property_view').lightSlider({
      gallery: false,
      item: 4,
      loop: false,
      slideMargin: 15,
      responsive : [
        {
          breakpoint: 767,
          settings: {
            item: 1
          }
        }
      ]
    });
  }

  initImagesSlider(){
    $('#lightSlider').lightSlider({
      gallery: false,
      item: 1,
      loop: true,
      slideMargin: 0
    });
  }

  moreimage() {
    $('#moreshowimage').modal('show');
  }

  getPropertyDetails() {
    var componentObj = this;
    var form_data = new FormData();
    form_data.append('property_id', this.route.snapshot.params.id);
    this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_DETAIL, form_data).subscribe((apiResult: any) => {
      if(apiResult.success){
        this.propertyinfo = apiResult.data;
        this.meta.updateTag({ name: 'description', content: this.propertyinfo.display_name });
        if(this.propertyinfo.locality_lat != "" && this.propertyinfo.locality_lng != "") {
          var markerN = {
            pid: this.propertyinfo.id,
            lat: this.propertyinfo.locality_lat,
            lng: this.propertyinfo.locality_lng,
            draggable: false,
            image_preview: "",
            locality_title: this.propertyinfo.locality_title,
            asking_price: this.propertyinfo.asking_price_in_word
          };
          componentObj.addMarker(markerN);
          componentObj.lat = this.propertyinfo.locality_lat;
          componentObj.lng = this.propertyinfo.locality_lng;
          componentObj.changeLatLng(componentObj.lat, componentObj.lng);
          componentObj.getNearBYLocations(componentObj.nearByLandmarks);
        }
        this.schedulevisitdata['related_source_id'] = apiResult.data.id;
        this.schedulevisitdata['assign_to_user_id'] = apiResult.data.assign_to_user_id;
        this.schedulevisitdata['franchisee_id'] = apiResult.data.franchisee_id;
        this.getSimilerProperties(this.propertyinfo.id);
        this.getSuggestedProperties(this.propertyinfo.id);
        this.generateAppointmentSlider(this.propertyinfo.slot_dates);
        var slideImgs = [];
        if(apiResult.data.prop_gallery){
          if(apiResult.data.prop_gallery.length > 3) {
            var countMore = apiResult.data.prop_gallery.length - 3;
            $('.more_images_3').html(countMore + "+ <br>More Images");
            $('.more_images_3').removeClass('hidden');
          }
          let newArray = [];
          $(apiResult.data.prop_gallery).each((sImageKey, sImage) => {
            if(sImageKey == 0) {
              $('.project_main_img').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            if(sImageKey == 1) {
              $('.project_other_img_1').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            if(sImageKey == 2) {
              $('.project_other_img_2').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            const src = sImage.preview_url;
            const caption = '';
            const thumb = sImage.preview_url;
            const album = {
              src: src,
              caption: caption,
              thumb: thumb
            };

            newArray.push(album);
          });
          this._album = newArray;
          //$("#lightSlider").html(slideImgs);
          //this.initImagesSlider();
        } else {
          this.showPropImages = false;
        }
        if(apiResult.data.prop_video_gallery){
          $(apiResult.data.prop_video_gallery).each((svideoKey, sVideo) => {
            this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(sVideo.name);
            this._video[svideoKey] = this.safeSrc;
          });
        }
        if(apiResult.data.asking_price != "" && apiResult.data.asking_price > 0) {
          this.home_price = parseFloat(apiResult.data.asking_price);
          this.down_payment = (this.home_price * this.down_payment_per) / 100;
          this.new_home_price = this.home_price - this.down_payment;
          this.getLoanMonthly();
        }
      }
    });
  }

  open(index: number): void {
    // open lightbox
    if(this._album.length>0){
      this._lightbox.open(this._album, index, { showZoom: true });
    }
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  getSimilerProperties(pid) {
    var form_data = new FormData();
    form_data.append('id', pid);
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SIMILAR_PROP, form_data).subscribe((apiResult: any) => {
      if(apiResult.success){
        this.similerProperties = apiResult.data.list;
        setTimeout (() => {
          this.initSimilarPropertyViewSlider();
        }, 200);
      }
    });
  }

  getSuggestedProperties(pid) {
    var form_data = new FormData();
    form_data.append('id', pid);
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SUGGESTED_PROP, form_data).subscribe((apiResult: any) => {
      if(apiResult.success){
        this.suggestedProperties = apiResult.data.list;
        setTimeout (() => {
          this.initSuggestedPropertyViewSlider();
        }, 200);
      }
    });
  }

  scrollTo(element_id){
    var el = document.getElementById(element_id);
    el.scrollIntoView({behavior: 'smooth'});
  }

  searchschedulevisit(franchisee_id, assign_to_user_id) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      this.schedulevisitdata['related_source_id'] = this.route.snapshot.params.id;
      this.schedulevisitdata['appointment_type'] = (this.tourInPerson == true ? '0' : '1');
      this.schedulevisitdata['franchisee_id'] = franchisee_id;
      this.schedulevisitdata['assign_to_user_id'] = assign_to_user_id;
      this.schedulevisitdata['temp_source'] = 'property';
      this.schedulevisitdata['source'] = 'property';
      this.schedulevisitdata['date'] = $('.s_day_active').data('date');
      this.router.navigate(['/schedulevisit'], {queryParams: this.schedulevisitdata});
    }
  }

  makeFavorite(pid, franchisee_id) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      this.isFavoriting = true;
      var form_data = new FormData();
      form_data.append('property_id', pid);
      form_data.append('status', "1");
      form_data.append('franchisee_id', franchisee_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
        this.isFavoriting = false;
        if(apiResult.success) {
          if($('.heart_btn').hasClass('is_favorite_prop')) {
            $('.heart_btn').removeClass('is_favorite_prop');
            $('.heart_btn').find('.fa').css("color", "#FFFFFF");
            this.FlashService.success('Property is removed from your Wishlist.')
          } else {
            $('.heart_btn').addClass('is_favorite_prop');
            $('.heart_btn').find('.fa').css("color", "#F02174");
            this.FlashService.success('Property is added to your Wishlist.')
          }
        }
      });
    }
  }

  makeFavoriteOthers(pid, franchisee_id) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('property_id', pid);
      form_data.append('status', "1");
      form_data.append('franchisee_id', franchisee_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
        if(apiResult.success) {
          if($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
          }
        }
      });
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  changeNearBy(type, gm) {
    if(type !== this.nearByLandmarks) {
      this.iconSchool = {
        url: 'assets/img/' + type + '_pin.png',
        scaledSize: {
          width: 25,
          height: 41
        }
      };
      gm.lastOpen = null;
      this.nearByLandmarks = type;
      this.getNearBYLocations(this.nearByLandmarks);
    }
  }

  getNearBYLocations(type) {
    this.schoolMarkers = [];
    var componentObj = this;
    var searchLatLng = new google.maps.LatLng(this.lat,this.lng);
    var request = {
      location: searchLatLng,
      radius: '1500',
      types: [type]
    };
    var service = new google.maps.places.PlacesService(this.propMap);
    service.nearbySearch(request, function(results, status){
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        for (var i = 1; i < results.length; i++) {
          var d = componentObj.distance(searchLatLng, results[i].geometry.location);
          if((d as any) < 0) {
            d = 0.1;
          }
          var markerN = {
            pid: "",
            lat: results[i].geometry.location.lat(),
            lng: results[i].geometry.location.lng(),
            draggable: false,
            image_preview: "",
            locality_title: results[i].name,
            asking_price: d + " km"
          };
          componentObj.addSchoolMarker(markerN);
        }
      }
    });
  }

  distance(p1, p2) {
     if (!p1 || !p2)
      return 0;
     var R = 6371000;
     var dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
     var dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
     var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
     Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) *
     Math.sin(dLon / 2) * Math.sin(dLon / 2);
     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
     var d = R * c;
     d = d / 1000;
     return d.toFixed(1);
  }

  onEMITabClick(id) {
    if(id == 1) {
      this.getLoanMonthly();
    } else if(id == 2) {
      this.changeMortgage();
    } else if(id == 3) {
      this.onPropertyTaxPerChange();
    } else if(id == 4) {
      this.onYearInsChange();
    } else if(id == 5) {
      this.onHOAPerMonthChange();
    } else if(id == 6) {
      this.onUtilitiesChange();
    }
  }

  getLoanMonthly() {
    var term = 5;
    if(this.loan_program == 1) {
      term = 5;
    } else if(this.loan_program == 2) {
      term = 10;
    } else if(this.loan_program == 3) {
      term = 15;
    } else if(this.loan_program == 4) {
      term = 20;
    }
    var totalAmount = (this.home_price - this.down_payment) * (this.int_rate / 100) * term;
    var monthlyAmount = ((this.home_price - this.down_payment) + totalAmount) / (term * 12);
    $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
    $('#header_emi_tag').html('&nbsp;&nbsp; <i class="fa fa-circle" aria-hidden="true"></i>&nbsp;&nbsp; EMI starts from <i class="fa fa-rupee"></i> ' + monthlyAmount.toFixed(2) + '/month');
  }

  onEMICalPriceChange() {
    // if(this.home_price <= 0) {
    //   this.home_price = 500000;
    // }
    this.down_payment = (this.home_price * this.down_payment_per) / 100;
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }

  onEMICalDownPaymentChange() {
    // if(this.down_payment <= 0) {
    //   this.down_payment_per = 1;
    //   this.down_payment = (this.home_price * this.down_payment_per) / 100;
    // } else {
      this.down_payment_per = (this.down_payment * 100) / this.home_price;
    // }
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }

  onEMICalDownPaymentPerChange() {
    // if(this.down_payment_per <= 1) {
    //   this.down_payment_per = 1;
    // }
    this.down_payment = (this.home_price * this.down_payment_per) / 100;
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }

  onEMICalTermChange() {
    this.getLoanMonthly();
  }

  onEMICalIntRateChange() {
    // if(this.int_rate <= 0) {
    //   this.int_rate = 1;
    // }
    this.getLoanMonthly();
  }

  changeMortgage() {
    var term = 5;
    if(this.loan_program == 1) {
      term = 5;
    } else if(this.loan_program == 2) {
      term = 10;
    } else if(this.loan_program == 3) {
      term = 15;
    } else if(this.loan_program == 4) {
      term = 20;
    }
    term = term * 12;
    var newHomeAmt = (this.home_price - this.down_payment);
    var aprMonthlyRate = ((this.int_rate / 100) / 12);
    var interestPayments = Math.pow(1 + aprMonthlyRate, term);
    var final = Math.round((newHomeAmt * (aprMonthlyRate * interestPayments) / (interestPayments - 1)));
  }

  onPropertyTaxPerChange() {
    if(this.property_tex_per <= 0) {
      this.property_tex_per = 0;
    }
    if(this.property_tex_per > 0) {
      this.property_tex_yearly = Math.round((this.new_home_price * this.property_tex_per) / 100);
      var monthlyAmount = (this.property_tex_yearly / 12);
      $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
    } else {
      $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + 0 + '</b> per month');
    }
  }

  onYearInsChange() {
    if(this.home_ins_year <= 0) {
      this.home_ins_year = 0;
    }
    if(this.home_ins_year > 0) {
      var monthlyAmount = (this.home_ins_year / 12);
      $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
    } else {
      $('#cost_month').html('N/A');
    }
  }

  onHOAPerMonthChange() {
    if(this.hoa_per_month <= 0) {
      this.hoa_per_month = 0;
    }
    if(this.hoa_per_month > 0) {
      var monthlyAmount = this.hoa_per_month;
      $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
    } else {
      $('#cost_month').html('N/A');
    }
  }

  onUtilitiesChange() {
    if(this.water_sewer_month <= 0) {
      this.water_sewer_month = 0;
    }
    if(this.gas_month <= 0) {
      this.gas_month = 0;
    }
    if(this.internet_month <= 0) {
      this.internet_month = 0;
    }
    if(this.electric_month <= 0) {
      this.electric_month = 0;
    }
    var monthlyAmount = this.water_sewer_month + this.gas_month + this.internet_month + this.electric_month;
    if(monthlyAmount > 0) {
      $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
    } else {
      $('#cost_month').html('N/A');
    }
  }

  askQuestion(pid) {
    $('#agent_text_que_alert').addClass('hidden');
    if( $('#agent_text_que').val() != '') {
      if (!this.isLoggedIn) {
        this.openLoginModel();
      } else {
        // this.clicked = !this.clicked;
        var userInfo = localStorage.getItem('currentUser');
        var form_data = new FormData();
        form_data.append('related_source_id', pid);
        form_data.append('userInfo', userInfo);
        form_data.append('question', $('#agent_text_que').val());
        form_data.append('source', 'propety');
        this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_QUESTION, form_data).subscribe((apiResult: any) => {
          if (apiResult.success) {
            $('#agent_text_que').val('');
            $('#agent_text_que_alert').removeClass('hidden');
          } else {
            $('#agent_text_que').focus();
            $('#agent_text_que')[0].setCustomValidity('Something went wrong.');
            $('#agent_text_que')[0].reportValidity();
            return false;
          }
        });
      }
    } else {
      $('#agent_text_que').focus();
      $('#agent_text_que')[0].setCustomValidity('Please write your query.');
      $('#agent_text_que')[0].reportValidity();
      return false;
    }
  }

  onShareSubmit(propertyId) {
    var componentObj = this;
    this.submitted = true;
    if (this.shareform.invalid) {
      return;
    }
    var emailid = this.shareform.value.emailShare;
    // var subject = this.shareform.value.subjectShare;
    var form_data = new FormData();
    form_data.append('email', emailid);
    // form_data.append('subject', subject);
    form_data.append('is_web_api','1');
    form_data.append('source','property');
    form_data.append('property_id', propertyId);
    this.ApiService.shareLink(form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success(apiResult.message);
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      console.log('error');
    });
  }

  selectAppointmentType(select) {
    this.tourInPerson = false;
    this.tourVideoChat = false;
    if (select == 1) {
      this.tourInPerson = true;
    } else {
      this.tourVideoChat = true;
    }
  }

  getDirection(lat, lng) {
    const startPoint = lat+','+lng; 
    const destination =  this.propertyinfo.locality_lat+','+this.propertyinfo.locality_lng
    const mapsUrl = `https://www.google.com/maps/dir/${startPoint}/${destination}`;
    window.open(mapsUrl, "_blank");
  }

  propertyInArea(loc){
    this.router.navigate(['/property'], { queryParams: { locality_elocs: loc}});
  }
}

interface marker {
  pid?: string;
	lat: number;
	lng: number;
	label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
	draggable: boolean;
}
