/* tslint:disable:whitespace no-var-keyword prefer-const no-string-literal quotemark forin variable-name */
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { Meta, Title } from '@angular/platform-browser';
import { FlashService } from '../../_services/FlashService.service';
import { environment } from 'src/environments/environment';
import { API_BASE_URL, API_ENDPOINTS } from 'src/app/global';

declare var $: any;
declare const require: any;
declare var google: any;

@Component({
  selector: 'app-buy-franchisee',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './buy-franchisee.component.html',
  styleUrls: ['./buy-franchisee.component.scss']
})
export class BuyFranchiseeComponent implements OnInit {

  submitted = false;
  leadForm: FormGroup;

  first_name: string = '';
  last_name: string = '';
  email: string = '';
  phone: string = '';
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  // address_line1: string = '';
  // address_line2: string = '';
  state_options = [];
  //state_eloc: string = 'PASCLQ';
  state_eloc: string = '';
  city_options = [];
  // city_eloc: string = '7A8Q6Y';
  city_eloc: string = '';
  area_eloc: string = '';
  area_title: string = '';
  area_lat: string = '';
  area_lng: string = '';
  localitySearch;
  // trans_type_options = [];
  // trans_type: string = '4';
  // prop_type_options = [];
  // prop_type: string = '1';
  franchisee_type: string = 'Regular';
  franchisee_type_options = [];

  isLoggedIn;
  isAddingLead: boolean = false;
  isLeadAdded: boolean = false;

  zoom: number = 13;
  lat: number = 22.295492;
  lng: number = 73.173463;
  markers: marker[] = [];
  icon = {
    url: 'assets/img/marker.png',
    scaledSize: {
      width: 15,
      height: 15
    }
  };
  propMap: any;
  total_props_s: string = '0 Property';

  constructor(private ref: ChangeDetectorRef, private FlashService: FlashService, private router: Router, private route: ActivatedRoute, private ApiService: ApiService, private title: Title, private meta: Meta, private formBuilder: FormBuilder) {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    var componentObj = this;
    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.queryParams.state_eloc !== undefined) {
        this.state_eloc = this.route.snapshot.queryParams.state_eloc;
      }
      this.getStates();
      this.getCities();
      // this.trans_type_options = [
      //   {id: "3", text: 'Rent/Lease'},
      //   {id: "4", text: 'Sell'}
      // ];
      // this.prop_type_options = [
      //   {id: "1", text: 'Residential'},
      //   {id: "3", text: 'Commercial'},
      //   {id: "4", text: 'Land'},
      //   {id: "6", text: 'Industrial'}
      // ];
      this.franchisee_type_options = [
        { id: "Master", text: 'Master Franchisee' },
        { id: "Regular", text: 'Franchisee' }
      ];
      this.leadForm = this.formBuilder.group({
        // addressline1: ['', [Validators.required]],
        // addressline2: ['', []],
        prolocality: ['', []],
        profilestate: ['', [Validators.required]],
        profilecity: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        profileEmail: ['', [Validators.required, Validators.email]],
        mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
        // transtype: ['', []],
        // proptype: ['', []],
        franchiseetype: ['', []],
      });
      this.isLoggedIn = this.ApiService.loginCheck();
      if (this.isLoggedIn) {
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        this.first_name = userInfo.first_name;
        this.last_name = userInfo.last_name;
        this.email = userInfo.email;
        this.phone = userInfo.contact_no;
      }
      setTimeout(() => {
        this.changeHeaderClass();
        this.localitySearch = $('#locality_search');
        this.localitySearch.typeahead(null, {
          name: 'localitySearch',
          displayKey: 'placeName',
          source: (search, sync, async) => {
            if (search.length >= 2) {
              return $.ajax({
                type: 'GET',
                dataType: 'json',
                url: API_BASE_URL + '/master/getLocality',
                data: { is_mobile_api: 1, search: search },
                success: (response) => {
                  response = response.data;
                  return async(response);
                }
              });
            }
          }
        });
        var componentObj = this;
        this.localitySearch.on('typeahead:selected', function (e, datalocitem) {
          componentObj.area_eloc = datalocitem.eLoc;
          componentObj.area_title = datalocitem.placeName;
          componentObj.area_lat = datalocitem.latitude;
          componentObj.area_lng = datalocitem.longitude;
          componentObj.changeLatLng(componentObj.area_lat, componentObj.area_lng);
        });
      }, 200);
      if (this.route.snapshot.queryParams.locality !== undefined) {
        this.area_title = this.route.snapshot.queryParams.locality;
      }
      if (this.route.snapshot.queryParams.lat !== undefined) {
        this.area_lat = this.route.snapshot.queryParams.lat;
      }
      if (this.route.snapshot.queryParams.lng !== undefined) {
        this.area_lng = this.route.snapshot.queryParams.lng;
      }
      if (this.route.snapshot.queryParams.leadadded !== undefined) {
        this.isLeadAdded = true;
      }
    });
  }

  mapReady(map) {
    this.propMap = map;
    if (this.area_lat != "" && this.area_lng != "") {
      this.changeLatLng(this.area_lat, this.area_lng);
    }
    this.getPropertyList();
  }

  getPropertyList() {
    var form_data = new FormData();
    form_data.append('locality_elocs', this.area_title);
    // form_data.append('transaction_type_id', this.trans_type);
    // form_data.append('franchisee_type', this.franchisee_type);
    // form_data.append('property_type_id', this.prop_type);
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        if (apiResult.data.paginate.Property.count > 1) {
          this.total_props_s = apiResult.data.paginate.Property.count + " Properties";
        } else {
          this.total_props_s = apiResult.data.paginate.Property.count + " Property";
        }
        $.each(apiResult.data.list, function (index, value) {
          if (componentObj.lat == 22.295492 && componentObj.lng == 73.173463 && value.locality_lat != null) {
            componentObj.lat = value.locality_lat;
            componentObj.lng = value.locality_lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
          }
          if (value.locality_lat != null && value.locality_lng != null) {
            var markerN = {
              pid: value.id,
              lat: value.locality_lat,
              lng: value.locality_lng,
              draggable: false,
              image_preview: value.image_preview,
              locality_title: value.locality_title,
              asking_price: value.asking_price_in_word
            };
            componentObj.addMarker(markerN);
          }
        });
      } else {
        this.total_props_s = "0 Property";
      }
    });
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    //var position = new google.maps.LatLng(this.lat, this.lng);
    //this.propMap.panTo(position);
  }

  addMarker(markerN) {
    this.markers = [];
    this.markers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  clickedMarker(pid: string) {
    //this.router.navigate(['property-details', pid]);
  }

  get g() {
    return this.leadForm.controls;
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $('#imgHeadLogo').attr('src', 'assets/img/empire_logo.png');
    // $("#imgHeadLogo").attr("src", "assets/img/logo_gray.png");
    this.title.setTitle('Empire Realtors » Buy Franchise');
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  getStates() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_STATES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.state_options = apiResult.data;
      }
    });
  }

  getCities() {
    var form_data = new FormData();
    form_data.append('state_eloc', this.state_eloc);
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_CITIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        if (apiResult.data.length > 0 && this.state_eloc != '') {
          this.city_eloc = apiResult.data[0]['id'];
        }
        this.city_options = apiResult.data;
      }
    });
  }

  onBusinessLeadFormSubmit() {
    this.submitted = true;
    if (this.leadForm.invalid) {
      return;
    }
    $('#submitInquiry').hide();
    $('.loaderBtn').show();
    this.isAddingLead = true;
    var form_data = new FormData();
    form_data.append('first_name', this.first_name);
    form_data.append('last_name', this.last_name);
    form_data.append('contact_no', this.phone);
    form_data.append('email', this.email);
    form_data.append('state_eloc', this.state_eloc);
    form_data.append('city_eloc', this.city_eloc);
    form_data.append('locality_title', this.area_title);
    form_data.append('locality_eloc', this.area_eloc);
    form_data.append('locality_lat', this.area_lat);
    form_data.append('locality_lng', this.area_lng);
    this.ApiService.postRequest(API_ENDPOINTS.API_ADD_BUSINESS_LEAD, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success(apiResult.message);
        var data = {};
        data['leadadded'] = "true";
        for (var i in this.route.snapshot.queryParams) {
          var param = this.route.snapshot.queryParams[i];
          if (this.route.snapshot.queryParams[i]) {
            data[i] = param;
          }
        }
        this.isLeadAdded = true;
        this.isAddingLead = false;
      } else {
        this.FlashService.error(apiResult.message);
        this.isAddingLead = false;
      }
      $('.loaderBtn').hide();
    }, error => {
      this.isAddingLead = false;
      this.FlashService.error("Something wrong!");
      $('.loaderBtn').hide();
    });
  }

  onBackToHome() {
    this.router.navigate(['/']);
  }

  addFranchiseTypeValid() {
    if ($('#trans_type').val() === 'Regular') {
      this.leadForm.setControl('profilecity', this.formBuilder.control('', [Validators.required]));
    } else {
      this.leadForm.setControl('profilecity', this.formBuilder.control('', []));
    }
  }

  getStateCities(stateEloc) {
    var data = {};
    data['state_eloc'] = stateEloc;
    if (this.route.snapshot.queryParams.locality !== undefined) {
      data['area_title'] = this.route.snapshot.queryParams.locality;
    }
    this.router.navigate(['buy-franchisee'], { queryParams: data });
    setTimeout(() => {
      this.getCities();
      this.ref.detectChanges();
    }, 1000);
  }
}

interface marker {
  pid?: string;
  lat: number;
  lng: number;
  label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
  draggable: boolean;
}
