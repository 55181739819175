/* tslint:disable:triple-equals no-var-keyword prefer-const variable-name no-inferrable-types no-shadowed-variable max-line-length comment-format quotemark no-string-literal whitespace */
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from './../../_services/api.service';
import {API_ENDPOINTS} from '../../global';
import {Meta, Title} from '@angular/platform-browser';
import {fromEvent} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {Lightbox} from 'ngx-lightbox';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FlashService } from '../../_services/FlashService.service';

declare var $: any;
declare const require: any;
declare var google:any;

@Component({
  selector: 'app-luxury-project-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './luxury-project-details.component.html',
  styleUrls: ['./luxury-project-details.component.scss']
})
export class LuxuryProjectDetailsComponent implements OnInit {

  projectinfo: any = [];
  schedulevisitdata = {};
  projectGallery = {};
  projectUnitGroup = {};
  shareform: FormGroup;
  enableSticky: boolean = false;
  submitted = false;
  _album = [];
  _floor_plan_album = [];
  isLoggedIn;
  scheduleText;
  firstUnitData = {};
  clicked = false;
  nearByLandmarks: string = 'school';
  zoom: number = 13;
  lat: number = 22.295492;
  lng: number = 73.173463;
  markers: marker[] = [];
  schoolMarkers: marker[] = [];
  icon = {
    url: 'assets/img/map_property_pin.png',
    scaledSize: {
      width: 33,
      height: 50
    }
  };
  iconSchool = {
    url: 'assets/img/school_pin.png',
    scaledSize: {
      width: 25,
      height: 41
    }
  };
  propMap: any;
  _video = [];
  safeSrc: SafeResourceUrl;
  similarProjectList: any = [];
  luxuryProjectUnits: any = [];
  tourInPerson = true;
  tourVideoChat = false;

  constructor(private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private ApiService: ApiService, private title: Title, private meta: Meta, @Inject(DOCUMENT) private document: Document, private _lightbox: Lightbox, private sanitizer: DomSanitizer, private formBuilder: FormBuilder, private FlashService: FlashService) { }

  get g() {
    return this.shareform.controls;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.isLoggedIn = this.ApiService.loginCheck();
    this.scheduleText = API_ENDPOINTS.SCHEDULE_VISIT_TEXT;
    this.getProjectDetails();

    setTimeout(() => {
      // this.generateAppointmentSlider();
      $(document).ready(() => {
        $('#shrehide').click(() => {
          $('#visit').toggle();
        });
        $("#hidendiv").click(() => {
          $("#propertydimention").toggle();
        });
        $(".chartContainer").CanvasJSChart({
          title: {
            text: "Price History"
          },
          axisY: {
            title: "Price",
            includeZero: false
          },
          axisX: {
            interval: 1
          },
          data: [{
            type: "line",
            toolTipContent: "{label}: {y} Lc.",
            dataPoints: [
              {label: "Jan", y: 55.28},
              {label: "Feb", y: 53.83},
              {label: "March", y: 56.55},
              {label: "April", y: 54.81},
              {label: "May", y: 33.49}
            ]
          }]
        });
      });
      this.changeHeaderClass();
    }, 200);

    // this.initSimilarPropertyViewSlider();
    this.initSuggestedPropertyViewSlider();

    this.shareform = this.formBuilder.group({
      emailShare: ['', [Validators.required, Validators.email]],
      // subjectShare: ['', [Validators.required]]
    });

    fromEvent(window, "scroll").subscribe((event) => {
      if (this.document.defaultView.scrollY > 850) {
        this.enableSticky = true;
      } else {
        this.enableSticky = false;
      }
    });
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    $("#accordion").on('show.bs.collapse', '.collapse', () => {
      $("#accordion").find('.collapse.in').collapse('hide');
    });
  }

  mapReady(map) {
    this.propMap = map;
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    var position = new google.maps.LatLng(this.lat, this.lng);
    this.propMap.panTo(position);
  }

  addMarker(markerN) {
    this.markers.push(markerN);
  }

  addSchoolMarker(markerN) {
    this.schoolMarkers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  clickedMarker(pid: string) {
    //this.router.navigate(['property-details', pid]);
  }

  generateAppointmentSlider(slotDatesAry) {
    const d = new Date();
    const dateDay = String(d.getDate()).padStart(2, '0');
    const a = [];
    const dateDayi = Number(dateDay);
    // tslint:disable-next-line:forin
    for (let prop in slotDatesAry) {
      const slotData = slotDatesAry[prop];
      let dyname = slotData.day_name;
      let datestr = slotData.date_fromat;
      let actClass = '';
      let bookClass = '';
      let title = '';
      if(slotData.slot !== 'book' && Number(slotData.day) === dateDayi) {
        actClass = "s_day_active";
      }
      if(slotData.slot === 'book') {
        bookClass = 's_booked_slot';
        title = 'Fully Booked';
      }
      a.push('<div class="single_day '+ actClass +' ' + bookClass + '" data-date="'+datestr+'" data-slot="'+slotData.slot+'" title="'+title+'"><label class="day_name">'+dyname+'</label><label class="day_date">'+slotData.day+'</label><label class="month_name">'+slotData.month+'</label></div>');
    }

    $("#visit_date_slider").html(a);

    $('#visit_date_slider').owlCarousel({
      loop: false,
      nav: true,
      navText: ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>", "<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive: {}
    });

    $('.single_day').on('click', function(event) {
      if ($(this).data('slot') === 'available') {
        $('.s_day_active').removeClass('s_day_active');
        $(this).addClass('s_day_active');
      }
    });
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Luxury Project Details');
  }

  initSimilarPropertyViewSlider() {
    $('#similar_property_view').lightSlider({
      gallery: false,
      item: 3.3,
      auto: true,
      loop: true,
      speed: 2000,
      pauseOnHover: true,
      slideMargin: 15,
      controls: true,
      prevHtml: '<img src="../assets/img/prev.png" style="height: 30px;" />',
      nextHtml: '<img src="../assets/img/next.png" style="height: 30px;" />',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            item: 1.2
          }
        }
      ]
    });
  }

  initLuxuryProjectUnitViewSlider() {
    $('#luxury_project_unit_view').lightSlider({
      gallery: false,
      item: 4.1,
      auto: true,
      loop: true,
      speed: 2000,
      pauseOnHover: true,
      slideMargin: 15,
      controls: true,
      pager:false,
      prevHtml: '<img src="../assets/img/prev.png" style="height: 30px;" />',
      nextHtml: '<img src="../assets/img/next.png" style="height: 30px;" />',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            item: 1.2
          }
        }
      ]
    });
  }

  initSuggestedPropertyViewSlider() {
    $('#suggested_property_view').lightSlider({
      gallery: false,
      item: 2.6,
      loop: false,
      slideMargin: 15,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            item: 1.5
          }
        }
      ]
    });
  }

  moreimage() {
    $('#project_more_image').modal('show');
  }

  showProjectDimensions() {
    if ($('.show_p_dimensions_slider').hasClass('active')) {
      $('.show_p_dimensions_slider').removeClass('active');
      $('.p_dimensions_slider').slideUp();
    } else {
      $('.show_p_dimensions_slider').addClass('active');
      $('.p_dimensions_slider').slideDown();
    }
  }

  getProjectDetails() {
    let componentObj = this;
    let form_data = new FormData();
    form_data.append('id', this.route.snapshot.params.id);
    form_data.append('is_luxury_property', '1');
    this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_DETAIL, form_data).subscribe((apiResult: any) => {

      if(!apiResult.data.id){
        this.router.navigate(['/not-found'])
      }

      if (apiResult.success) {
        this.projectinfo = apiResult.data;
        this.projectUnitGroup = apiResult.data.proj_unit_group;
        this.setProjectFloorPlan();
        this.meta.updateTag({name: 'description', content: this.projectinfo.name});
        if (this.projectinfo.locality_lat != '' && this.projectinfo.locality_lng != '') {
          var markerN = {
            pid: this.projectinfo.id,
            lat: this.projectinfo.locality_lat,
            lng: this.projectinfo.locality_lng,
            draggable: false,
            image_preview: '',
            locality_title: this.projectinfo.locality_title,
            asking_price: this.projectinfo.minMaxData.min_rate_title + ' - ' + this.projectinfo.minMaxData.max_rate_title
          };
          componentObj.addMarker(markerN);
          componentObj.lat = this.projectinfo.locality_lat;
          componentObj.lng = this.projectinfo.locality_lng;
          componentObj.changeLatLng(componentObj.lat, componentObj.lng);
          componentObj.getNearBYLocations(componentObj.nearByLandmarks);
        }
        this.schedulevisitdata['related_source_id'] = apiResult.data.id;
        this.schedulevisitdata['assign_to_user_id'] = apiResult.data.assign_to_user_id;
        this.schedulevisitdata['franchisee_id'] = apiResult.data.franchisee_id;
        this.generateAppointmentSlider(apiResult.data.slot_dates);
        if(apiResult.data.proj_video_gallery){
          $(apiResult.data.proj_video_gallery).each((svideoKey, sVideo) => {
            this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(sVideo.name);
            this._video[svideoKey] = this.safeSrc;
          });
        }
        if (apiResult.data.proj_gallery) {
          if (apiResult.data.proj_gallery.length > 3) {
            var countMore = apiResult.data.proj_gallery.length - 3;
            $('.more_images_3').html(countMore + "+ <br>More Images");
            $('.more_images_3').removeClass('hidden');
          }
          let newArray = [];
          $(apiResult.data.proj_gallery).each((sImageKey, sImage) => {
            if (sImageKey == 0) {
              $('.project_main_img').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            if (sImageKey == 1) {
              $('.project_other_img_1').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            if (sImageKey == 2) {
              $('.project_other_img_2').css('background-image', 'url(' + sImage.preview_url + ')');
            }

            const src = sImage.preview_url;
            const caption = '';
            const thumb = sImage.preview_url;
            const album = {
              src: src,
              caption: caption,
              thumb: thumb
            };

            newArray.push(album);
          });
          this._album = newArray;
        }
        this.luxuryProjectUnits = apiResult.data.luxury_project_units;
        setTimeout (() => {
          this.initLuxuryProjectUnitViewSlider();
        }, 200);
        this.getSimilarProjects(this.projectinfo.id, this.projectinfo.locality_title, this.projectinfo.city_eloc, this.projectinfo.project_type_id);
      }
    });
  }

  getSimilarProjects(projectId, localityTile, cityEloc, projectTypeId) {
    let componentObj = this;
    let form_data = new FormData();
    form_data.append('project_id', projectId);
    form_data.append('locality_title', localityTile);
    form_data.append('city_eloc', cityEloc);
    form_data.append('project_type_id', projectTypeId);
    this.ApiService.postRequest(API_ENDPOINTS.API_SIMILAR_PROJECTS, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.similarProjectList = apiResult.data.list;
        setTimeout (() => {
          this.initSimilarPropertyViewSlider();
        }, 200);
      }
    });
  }

  setProjectFloorPlan() {
    let mainArray = [];
    $.each(this.projectUnitGroup, (key, value) => {
      $.each(value, (childKey, childValue) => {
        const newArray = [];
        if (childValue.proj_unit_gallery.length > 0) {
          $(childValue.proj_unit_gallery).each((sImageKey, sImage) => {
            const src = sImage.preview_url;
            const caption = '';
            const thumb = sImage.preview_url;
            const album = {
              src: src,
              caption: caption,
              thumb: thumb
            };

            newArray.push(album);
          });
          mainArray[childKey] = newArray;
        }
      });
    });
    this._floor_plan_album = mainArray;
  }

  getNearBYLocations(type) {
    this.schoolMarkers = [];
    var componentObj = this;
    var searchLatLng = new google.maps.LatLng(this.lat, this.lng);
    var request = {
      location: searchLatLng,
      radius: '1500',
      types: [type]
    };
    var service = new google.maps.places.PlacesService(this.propMap);
    service.nearbySearch(request, (results, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        for (var i = 1; i < results.length; i++) {
          var d = componentObj.distance(searchLatLng, results[i].geometry.location);
          if ((d as any) < 0) {
            d = 0.1;
          }
          var markerN = {
            pid: '',
            lat: results[i].geometry.location.lat(),
            lng: results[i].geometry.location.lng(),
            draggable: false,
            image_preview: '',
            locality_title: results[i].name,
            asking_price: d + ' km'
          };
          componentObj.addSchoolMarker(markerN);
        }
      }
    });
  }

  distance(p1, p2) {
    if (!p1 || !p2) {
      return 0;
    }
    var R = 6371000;
    var dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
    var dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    d = d / 1000;
    return d.toFixed(1);
  }

  changeNearBy(type, gm) {
    if (type !== this.nearByLandmarks) {
      this.iconSchool = {
        url: 'assets/img/' + type + '_pin.png',
        scaledSize: {
          width: 25,
          height: 41
        }
      };
      gm.lastOpen = null;
      this.nearByLandmarks = type;
      this.getNearBYLocations(this.nearByLandmarks);
    }
  }

  openFloorPlan(key, index: number): void {
    // open lightbox
    if(this._floor_plan_album[key]){
      this._lightbox.open(this._floor_plan_album[key], index, { showZoom: true });
    }
  }

  open(index: number): void {
    // open lightbox
    if(this._album.length>0){
      this._lightbox.open(this._album, index, { showZoom: true });
    }
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  scrollTo(element_id) {
    var el = document.getElementById(element_id);
    el.scrollIntoView({behavior: 'smooth'});
  }

  selectAppointmentType(select) {
    this.tourInPerson = false;
    this.tourVideoChat = false;
    if (select == 1) {
      this.tourInPerson = true;
    } else {
      this.tourVideoChat = true;
    }
  }

  searchschedulevisit(franchisee_id, assign_to_user_id) {
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      this.schedulevisitdata['related_source_id'] = this.route.snapshot.params.id;
      this.schedulevisitdata['appointment_type'] = (this.tourInPerson == true ? '0' : '1');
      this.schedulevisitdata['franchisee_id'] = franchisee_id;
      this.schedulevisitdata['assign_to_user_id'] = assign_to_user_id;
      this.schedulevisitdata['temp_source'] = 'project';
      this.schedulevisitdata['source'] = 'project';
      this.schedulevisitdata['date'] = $('.s_day_active').data('date');
      this.router.navigate(['/schedulevisit'], {queryParams: this.schedulevisitdata});
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  navChange(i, j) {
    $('.floor_link').removeClass('active');
    $('.dimensions').addClass('d-none');
    $('.dimension_' + i + '_' + j).removeClass('d-none');
    $('.titles').addClass('d-none');
    $('.title_' + i + '_' + j).removeClass('d-none');
  }

  makeFavorite(pid, status, franchisee_id) {
    if (!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('id', pid);
      form_data.append('status', "1");
      form_data.append('franchisee_id', franchisee_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_FAVORITE, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          if ($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
            this.FlashService.success('Luxury Project is removed from your Wishlist.')
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
            this.FlashService.success('Luxury Project is added to your Wishlist.')
          }
        }
      });
    }
  }

  askQuestion(pid) {
    $('#agent_text_que_alert').addClass('hidden');
    if( $('#agent_text_que').val() != '') {
      if (!this.isLoggedIn) {
        this.openLoginModel();
      } else {
        // this.clicked = !this.clicked;
        var userInfo = localStorage.getItem('currentUser');
        var form_data = new FormData();
        form_data.append('related_source_id', pid);
        form_data.append('userInfo', userInfo);
        form_data.append('question', $('#agent_text_que').val());
        form_data.append('source', 'project');
        this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_QUESTION, form_data).subscribe((apiResult: any) => {
          if (apiResult.success) {
            $('#agent_text_que').val('');
            $('#agent_text_que_alert').removeClass('hidden');
          } else {
            $('#agent_text_que').focus();
            $('#agent_text_que')[0].setCustomValidity('Something went wrong.');
            $('#agent_text_que')[0].reportValidity();
            return false;
          }
        });
      }
    } else {
      $('#agent_text_que').focus();
      $('#agent_text_que')[0].setCustomValidity('Please write your query.');
      $('#agent_text_que')[0].reportValidity();
      return false;
    }
  }

  onShareSubmit(projectId) {
    var componentObj = this;
    this.submitted = true;
    if (this.shareform.invalid) {
      return;
    }
    var emailid = this.shareform.value.emailShare;
    // var subject = this.shareform.value.subjectShare;
    var form_data = new FormData();
    form_data.append('email', emailid);
    // form_data.append('subject', subject);
    form_data.append('is_web_api','1');
    form_data.append('source','project');
    form_data.append('project_id',projectId);
    this.ApiService.shareLink(form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success(apiResult.message);
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      console.log('error');
    });
  }

  propertyInArea(loc){
    this.router.navigate(['/property'], { queryParams: { locality_elocs: loc}});
  }

  getDirection(lat, lng) {
    const startPoint = lat+','+lng; 
    const destination =  this.projectinfo.locality_lat+','+this.projectinfo.locality_lng
    const mapsUrl = `https://www.google.com/maps/dir/${startPoint}/${destination}`;
    window.open(mapsUrl, "_blank");
  }
}

interface marker {
  pid?: string;
  lat: number;
  lng: number;
  label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
  draggable: boolean;
}
