<div class="container-fluid" id="frontLeadGenerator">
  <ng-container *ngIf="isAddingLead == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <div class="row">
    <div class="col-md-7">
      <div id="div_franch_img">
        <img class="loading_img" src="../../assets/img/reset_pass.png"/>
      </div>
    </div>
    <div class="col-md-5">
      <div class="page-head text-center">
        <!--<div>
          <img class="loading_img" src="../../assets/img/logo.png"/>
        </div>-->
        <h2 id="reset_title">Reset Password</h2>
        <!-- <app-flash></app-flash> -->
        <h2 id="resetDone" [innerHTML]="resetDone" style="color: #f07373;"></h2>
      </div>
      <div class="lead_generator_main" [formGroup]="resetPasswordForm">
        <div class="contact-info-outer">
          <div class="row d-flex">
            <div class="col-md-12">
              <div class="form-group">
                <input type="password" formControlName="passwordReset" class="form-control" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted && g.passwordReset.errors }" />
                <div *ngIf="submitted && g.passwordReset.errors" class="invalid-feedback">
                  <div *ngIf="g.passwordReset.errors.required">Password is required</div>
                  <div *ngIf="g.passwordReset.errors.minlength">Password must be at least 6 characters</div>
                  <div *ngIf="g.passwordReset.errors.maxlength">Max Length  20 characters</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input type="password" formControlName="confirmPasswordReset" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && g.confirmPasswordReset.errors }" />
                <div *ngIf="submitted && g.confirmPasswordReset.errors" class="invalid-feedback">
                  <div *ngIf="g.confirmPasswordReset.errors.required">Password is required</div>
                  <div *ngIf="g.confirmPasswordReset.errors.minlength">Password must be at least 6 characters</div>
                  <div *ngIf="g.confirmPasswordReset.errors.maxlength">Max Length  20 characters</div>
                  <div *ngIf="g.confirmPasswordReset.errors.confirmedValidator">Password and Confirm Password must be match.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="save-button">
          <div class="row d-flex">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6">
              <div class="form-group">
                <button type="submit" class="btn btn-block login-btn" (click)="submitUserResetPassword()">Submit</button>
              </div>
            </div>
            <div class="col-md-3">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
