/* tslint:disable:quotemark */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ApiService } from './../../_services/api.service';
import { FlashService } from '../../_services/FlashService.service';
import {Meta, Title} from '@angular/platform-browser';
import { API_ENDPOINTS } from 'src/app/global';
declare var $: any;

@Component({
  selector: 'app-list-your-property',
  templateUrl: './list-your-property.component.html',
  styleUrls: ['./list-your-property.component.scss']
})
export class ListYourPropertyComponent implements OnInit {

  home_icon: string = '';

  constructor(private route: ActivatedRoute, private title: Title) {
    this.home_icon = API_ENDPOINTS.HOME_ICON;
   }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.route.params.subscribe(routeParams => {
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » List Your Property');
  }
}
