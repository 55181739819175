/* tslint:disable:quotemark no-inferrable-types variable-name max-line-length no-shadowed-variable no-string-literal */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API_ENDPOINTS, WEB_URL } from '../../global';
import { ApiService } from './../../_services/api.service';
declare var $: any;

@Component({
  selector: 'app-accountfavourites',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class AccountFavouritesComponent implements OnInit {

  home_icon: string = '';
  favouritesPropertiesList: any = [];
  favouritesProjectList: any = [];
  isLoading: boolean = true;
  dashboardType = 'buyer';
  schedulevisitdata = {};

  constructor(private ApiService: ApiService, private router: Router, private route: ActivatedRoute,  private title: Title) {
    this.home_icon = API_ENDPOINTS.HOME_ICON;
    window.scroll(0,0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
      this.getFavouritesProperties();
      this.getFavouritesProjects();
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Favourites');
  }

  getFavouritesProperties() {
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_WISHLIST_PROPERTIES, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.favouritesPropertiesList = apiResult.data.list;
      }
    });
  }

  getFavouritesProjects() {
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BUYER_WISHLIST_PROJECT, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.favouritesProjectList = apiResult.data.list;
      }
    });
  }

  makeFavorite(pid) {
    this.isLoading = true;
    const form_data = new FormData();
    form_data.append('property_id', pid);
    form_data.append('status', "1");
    this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.getFavouritesProperties();
      }
    });
  }

  navigateTo(data:any){
    if(data.prop_status.title=='Sold'){
      return
    }

    let paramsId=data.property_id ? data.property_id : data.project_id
    let route= data.property_id ? "/property-details/" : "/project-details/"
    window.open(WEB_URL + route + paramsId, '_blank')
    // this.router.navigate([route, paramsId]);
  }

  makeProjectFavorite(pid) {
    this.isLoading = true;
    const form_data = new FormData();
    form_data.append('id', pid);
    form_data.append('status', "1");
    this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_FAVORITE, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.getFavouritesProjects();
      }
    });
  }

  onRescheduleAppointmentClick(project_id, id, assign_to_user_id, franchisee_id, source) {
    // tslint:disable-next-line:triple-equals
    if (id != '0') {
      this.schedulevisitdata['related_source_id'] = id;
    } else {
      this.schedulevisitdata['related_source_id'] = project_id;
    }
    this.schedulevisitdata['assign_to_user_id'] = assign_to_user_id;
    this.schedulevisitdata['franchisee_id'] = franchisee_id;
    this.schedulevisitdata['temp_source'] = source;
    this.schedulevisitdata['source'] = source;
    this.router.navigate(['/schedulevisit'], {queryParams: this.schedulevisitdata});
  }
}
