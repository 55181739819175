/* tslint:disable:quotemark */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-transaction-type',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './transaction-type.component.html',
  styleUrls: ['./transaction-type.component.scss']
})
export class TransactionTypeComponent implements OnInit {

  isLoggedIn;
  isForSearch = false;
  isForCarnival = false;
  transaction_type_id: string = '1';

  constructor(private router: Router, private route: ActivatedRoute,  private title: Title) { }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    $('#projLinkBtn').show();
    if (this.route.snapshot.queryParams.page === 'search') {
      this.isForSearch = true;
      this.isForCarnival = false;
    }
    if (this.route.snapshot.queryParams.page === 'carnival') {
      this.isForCarnival = true;
      this.isForSearch = false;
    }
    setTimeout (() => {
      this.changeHeaderClass();
    }, 200);
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » RPC');
  }

  onBackToHome() {
    this.router.navigate(['/']);
  }

  searchProperty(tran_type_id, page) {
    var data = {};
    if (this.isForSearch === true) {
      if (this.route.snapshot.queryParams.locality_elocs) {
        data['locality_elocs'] = this.route.snapshot.queryParams.locality_elocs;
      }
      if (this.transaction_type_id != undefined) {
        data['property_type_id'] = this.transaction_type_id;
        localStorage.setItem('transaction_type_id_dynamic', this.transaction_type_id)
      }
      data['transaction_type_id'] = tran_type_id;
      if (page === 'property') {
        data['page'] = page;
        this.setSearchinlocal(data);
        this.router.navigate(['/property'], {queryParams: data});
      } else {
        data['page'] = 'projects';
        this.setSearchinlocal(data);
        this.router.navigate(['/projects'], {queryParams: data});
      }
    } else if (this.isForCarnival === true) {
      if (this.route.snapshot.queryParams.locality_elocs) {
        data['locality_elocs'] = this.route.snapshot.queryParams.locality_elocs;
      }
      data['page'] = page;
      data['transaction_type_id'] = 4;
      if (this.transaction_type_id != undefined) {
        data['property_type_id'] = this.transaction_type_id;
      }
      this.setSearchinlocal(data);
      this.router.navigate(['/carnival'], {queryParams: data});
    }
  }

  searchCarnivalProperty(propTypeId){
    var data = {};
    if (this.route.snapshot.queryParams.locality_elocs) {
      data['locality_elocs'] = this.route.snapshot.queryParams.locality_elocs;
    }
    data['page'] = 'new';
    data['transaction_type_id'] = 4;
    data['property_type_id'] = propTypeId;
    this.setSearchinlocal(data);
    this.router.navigate(['/carnival'], {queryParams: data});
  }

  selPropTypeDiv(divType) {
    $('#residential_div').removeClass('bg_white');
    $('#commercial_div').removeClass('bg_white');
    $('#land_div').removeClass('bg_white');
    $('#projLinkBtn').show();
    if (divType === 'residential') {
      $('#residential_div').addClass('bg_white');
    } else if (divType === 'commercial') {
      $('#commercial_div').addClass('bg_white');
    } else {
      $('#land_div').addClass('bg_white');
      $('#projLinkBtn').hide();
    }
  }

  setSearchinlocal(data){
    var userInfo = JSON.parse(localStorage.getItem('currentUser'));
    if(userInfo){
      if(localStorage.getItem('page_name'+userInfo.id) == null || localStorage.getItem('page_name'+userInfo.id) == ''){
      localStorage.setItem('page_name'+userInfo.id, data['page']);
      }
      if(localStorage.getItem('property_type_id'+userInfo.id) == null || localStorage.getItem('property_type_id'+userInfo.id) == ''){
        localStorage.setItem('property_type_id'+userInfo.id, data['property_type_id']);
        localStorage.setItem('transaction_type_id_dynamic', data['property_type_id']);
      }
      if(localStorage.getItem('transaction_type_id'+userInfo.id) == null || localStorage.getItem('transaction_type_id'+userInfo.id) == ''){
        localStorage.setItem('transaction_type_id'+userInfo.id, data['transaction_type_id']);
      }
    }
  }
}
