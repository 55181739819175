<!-- ======= Start Footer ======= -->
<footer class="footer-bottom" id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 footer-links">
          <h4><a class="dynamic" [routerLink]="['/jobs']">Jobs @Empire</a></h4>
          <ul>
            <li><a class="dynamic" [routerLink]="['/buy-franchisee']">Franchisee</a></li>
            <li><a class="dynamic" [routerLink]="['/sell']">List your property with us</a></li>
            <li><a class="dynamic" [routerLink]="['/partners-program']">Contact us</a></li>
            <li><a class="dynamic" href="javascript:void(0)" (click)="openModel(true)">Disclaimer</a></li>
            <!--           <li><a class="dynamic" [routerLink]="['/sez-investment']">Investment in SEZ</a></li>-->
          </ul>
        </div>
        <div class="col-md-6">
          <h4 (click)="openModel(false)" class="about_us">About Us</h4>
          <!-- <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining
            essentially unchanged.</p>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when
            looking at its layout.</p> -->
          <p>&copy; 2024 Empire Spaces, LLP. All rights reserved.</p>


        </div>
        <div class="col-lg-3 col-md-6 footer-social">
          <h4>Social Media</h4>
          <ul>
            <li><a href="https://www.facebook.com/empirerealtorsindia" class="fb" target="_blank"><i
                  class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="https://twitter.com/Empire_Realtors" class="tw" target="_blank"><i
                  class="fa fa-twitter"></i></a></li>
            <li><a href="#" class="ln"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            <li><a href="https://www.instagram.com/empirerealtors.in/" class="insta" target="_blank"><i
                  class="fa fa-instagram" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- ======= End Footer ======= -->


<!-- ======= Disclaimer Model Section ======= -->
<div class="modal fade bd-example-modal-lg" id="disclaimer__model" role="dialog" style="overflow: hidden;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h5 class="modal-title"><b>{{isDisclaimer ? 'Disclaimer' : 'About Us'}}</b></h5>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div [class]="isDisclaimer ? 'modal-body':'modal-body model_scroll'">
        <ng-container *ngIf="isDisclaimer">
          <p><b>Accuracy Disclaimer:</b> The information contained on this website is believed to be accurate
            and reliable. However, we make no guarantee or warranty regarding the information's
            accuracy, completeness, or reliability.</p>

          <p><b>Results Disclaimer:</b> Results may vary from person to person and are not guaranteed. The
            testimonials and examples provided on this website are not intended to represent or
            guarantee that everyone will achieve the same or similar results.</p>

          <p><b>No Liability Disclaimer:</b> We are not liable for any damages, including but not limited to direct,
            indirect, incidental, or consequential damages, arising from the use or inability to use this
            website or the information provided on it.</p>
        </ng-container>

        <ng-container *ngIf="!isDisclaimer">

          <p>As a real estate broker, our primary goal is to help our clients navigate the complex world of buying and
            selling properties. We understand that buying or selling a property can be a significant milestone in one's
            life, and it requires careful consideration and attention to detail.</p>

          <p>At our brokerage, we pride ourselves on providing exceptional customer service and personalized attention
            to
            each of our clients. We believe that every client has unique needs and requirements, and we work tirelessly
            to
            ensure that those needs are met.</p>

          <p>Our team of experienced agents has a wealth of knowledge and expertise in the real estate market. We stay
            up-to-date with the latest market trends, regulations, and best practices to ensure that our clients receive
            the most accurate and reliable information.</p>

          <p>Whether you are looking to buy or sell a property, we are committed to making the process as smooth and
            stress-free as possible. We will work with you every step of the way, from the initial consultation to the
            closing of the deal, to ensure that your goals are achieved.</p>

          <p>We also understand that real estate transactions can be complex and time-consuming. That's why we offer a
            full range of services, including property valuation, marketing, negotiations, and legal assistance, to
            ensure
            that our clients have everything they need to succeed.</p>

          <p>At our brokerage, we are committed to building long-term relationships with our clients. We believe that
            trust, honesty, and transparency are essential components of any successful real estate transaction, and we
            strive to uphold these values in all our interactions.</p>
          <p>If you are looking for a reliable, knowledgeable, and customer-focused real estate broker, look no further
            than us. Contact us today to schedule a consultation and discover how we can help you achieve your real
            estate
            goals.</p>

        </ng-container>
        <div class="col-md-3 d-flex flex-column justify-content-center btn-disclamier">
          <button class="btn btn-view-more" data-dismiss="modal">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ======= End Disclaimer Model Section ======= -->
