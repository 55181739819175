import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_common/_guard/auth.guard';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { PropertiesComponent } from './Components/properties/properties.component';
import { PropertydetailsComponent } from './Components/property-details/propertydetails.component';
import { ProjectsComponent } from './Components/projects/projects.component';
import { ProjectdetailsComponent } from './Components/projects-details/projectsdetails.component';
import { ScheduleComponent } from './Components/schedule-visit/schedulevisit.component';
import { MyaccountComponent } from './Components/myaccount/myaccount.component';
import { AccountFavouritesComponent } from './Components/account-favourites/favourites.component';
import { AccountSavedSearchComponent } from './Components/account-savedsearch/savedsearch.component';
import { AccountOpenHouseComponent } from './Components/account-openhouse/openhouse.component';
import { AccountHomeTourComponent } from './Components/account-hometour/hometour.component';
import { AccountOffersComponent } from './Components/account-offers/offers.component';
import { AccountReviewsComponent } from './Components/account-reviews/reviews.component';
import { AccountSettingsComponent } from './Components/account-settings/settings.component';
import { RentcalCulatorComponent } from './Components/rent-calculator/rentcalculator.component';
import { AddLeadComponent } from './Components/addlead/addlead.component';
import { AccountDashboardComponent } from './Components/account-dashboard/dashboard.component';
import { CarnivalComponent } from './Components/carnival/carnival.component';
import { BuyFranchiseeComponent } from './Components/buy-franchisee/buy-franchisee.component';
import { CarnivalProjectDetailsComponent } from './Components/carnival-project-details/carnival-project-details.component';
import { TransactionTypeComponent } from './Components/transaction-type/transaction-type.component';
import { BuyPropertyComponent } from './Components/buy-property/buy-property.component';
import { RentLeasePropertyComponent } from './Components/rent-lease-property/rent-lease-property.component';
import { SuitablePropertyComponent } from './Components/suitable-property/suitable-property.component';
import { ProjectUnitsComponent } from './Components/project-units/project-units.component';
import { ProjectUnitDetailsComponent } from './Components/project-unit-details/project-unit-details.component';
import { SellerPropertyComponent } from './Components/seller-property/seller-property.component';
import { BuyerViewedComponent } from './Components/buyer-viewed/buyer-viewed.component';
import { FranchiseComponent } from './Components/franchise/franchise.component';
import { LearningRealEstateComponent } from './Components/learning-real-estate/learning-real-estate.component';
import { FreeClassesComponent } from './Components/free-classes/free-classes.component';
import { ListYourPropertyComponent } from './Components/list-your-property/list-your-property.component';
import { PartnersProgramComponent } from './Components/partners-program/partners-program.component';
import { SezInvestmentComponent } from './Components/sez-investment/sez-investment.component';
import { JobsComponent } from './Components/jobs/jobs.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { AffordableCalculatorComponent } from './Components/affordable-calculator/affordable-calculator.component';
import { DeveloperDetailsComponent } from './Components/developer-details/developer-details.component';
import { LuxuryComponent } from './Components/luxury/luxury.component';
import { LuxuryPropertiesComponent } from './Components/luxury-properties/luxury-properties.component';
import { LuxuryProjectsComponent } from './Components/luxury-projects/luxury-projects.component';
import { LuxuryProjectUnitsComponent } from './Components/luxury-project-units/luxury-project-units.component';
import { LuxuryProjectDetailsComponent } from './Components/luxury-project-details/luxury-project-details.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { BuyerParamsGuard } from './_common/_guard/buyer-params.guard';



const routes: Routes = [
  { path: '', component: HomeComponent, runGuardsAndResolvers: 'always' },
  { path: 'user-verify', component: HomeComponent, runGuardsAndResolvers: 'always' },
  { path: 'property', component: PropertiesComponent, runGuardsAndResolvers: 'always' },
  { path: 'property-details/:id', component: PropertydetailsComponent, runGuardsAndResolvers: 'always' },
  { path: 'projects', component: ProjectsComponent, runGuardsAndResolvers: 'always' },
  { path: 'project-details/:id', component: ProjectdetailsComponent, runGuardsAndResolvers: 'always' },
  { path: 'schedulevisit', component: ScheduleComponent, runGuardsAndResolvers: 'always' },
  { path: 'myaccount', component: MyaccountComponent, runGuardsAndResolvers: 'always',canActivate:[BuyerParamsGuard]},
  { path: 'favourites', component: AccountFavouritesComponent, runGuardsAndResolvers: 'always',canActivate:[BuyerParamsGuard] },
  { path: 'savedsearch', component: AccountSavedSearchComponent, runGuardsAndResolvers: 'always',canActivate:[BuyerParamsGuard] },
  { path: 'openhouse', component: AccountOpenHouseComponent, runGuardsAndResolvers: 'always', canActivate:[BuyerParamsGuard] },
  { path: 'buyerdashboard', component: AccountDashboardComponent, runGuardsAndResolvers: 'always',canActivate:[BuyerParamsGuard] },
  { path: 'offers', component: AccountOffersComponent, runGuardsAndResolvers: 'always',canActivate:[BuyerParamsGuard] },
  { path: 'hometour', component: AccountHomeTourComponent, runGuardsAndResolvers: 'always' },
  { path: 'reviews', component: AccountReviewsComponent, runGuardsAndResolvers: 'always' },
  { path: 'email-settings', component: AccountSettingsComponent, runGuardsAndResolvers: 'always' },
  { path: 'rent-calculator', component: RentcalCulatorComponent, runGuardsAndResolvers: 'always' },
  { path: 'sellerdashboard', component: AccountDashboardComponent, runGuardsAndResolvers: 'always' },
  { path: 'lead-generator', component: AddLeadComponent, runGuardsAndResolvers: 'always' },
  { path: 'sell', component: AddLeadComponent, runGuardsAndResolvers: 'always' },
  { path: 'carnival-project-details/:id', component: CarnivalProjectDetailsComponent, runGuardsAndResolvers: 'always' },
  { path: 'carnival', component: CarnivalComponent, runGuardsAndResolvers: 'always' },
  { path: 'buy-franchisee', component: BuyFranchiseeComponent, runGuardsAndResolvers: 'always' },
  { path: 'transaction-type', component: TransactionTypeComponent, runGuardsAndResolvers: 'always' },
  { path: 'buy', component: BuyPropertyComponent, runGuardsAndResolvers: 'always' },
  { path: 'rent-lease', component: RentLeasePropertyComponent, runGuardsAndResolvers: 'always' },
  { path: 'project-units', component: ProjectUnitsComponent, runGuardsAndResolvers: 'always' },
  { path: 'suitable-property', component: SuitablePropertyComponent, runGuardsAndResolvers: 'always' },
  { path: 'project-unit-details/:id', component: ProjectUnitDetailsComponent, runGuardsAndResolvers: 'always' },
  { path: 'buyer-viewed', component: BuyerViewedComponent, runGuardsAndResolvers: 'always',canActivate:[BuyerParamsGuard] },
  { path: 'seller-property', component: SellerPropertyComponent, runGuardsAndResolvers: 'always' },
  { path: 'franchise', component: FranchiseComponent, runGuardsAndResolvers: 'always' },
  { path: 'learning-real-estate', component: LearningRealEstateComponent, runGuardsAndResolvers: 'always' },
  { path: 'free-classes', component: FreeClassesComponent, runGuardsAndResolvers: 'always' },
  { path: 'list-your-property', component: ListYourPropertyComponent, runGuardsAndResolvers: 'always' },
  { path: 'partners-program', component: PartnersProgramComponent, runGuardsAndResolvers: 'always' },
  { path: 'sez-investment', component: SezInvestmentComponent, runGuardsAndResolvers: 'always' },
  { path: 'jobs', component: JobsComponent, runGuardsAndResolvers: 'always' },
  { path: 'reset-password', component: ResetPasswordComponent, runGuardsAndResolvers: 'always' },
  { path: 'affordable-calculator/:id', component: AffordableCalculatorComponent, runGuardsAndResolvers: 'always' },
  { path: 'luxury-projects', component: LuxuryProjectsComponent, runGuardsAndResolvers: 'always' },
  { path: 'luxury-property', component: LuxuryPropertiesComponent, runGuardsAndResolvers: 'always' },
  { path: 'developer-details/:group_slug', component: DeveloperDetailsComponent, runGuardsAndResolvers: 'always' },
  { path: 'luxury-project-units', component: LuxuryProjectUnitsComponent, runGuardsAndResolvers: 'always' },
  { path: 'luxury', component: LuxuryComponent, runGuardsAndResolvers: 'always' },
  { path: 'luxury-project-details/:id', component: LuxuryProjectDetailsComponent, runGuardsAndResolvers: 'always' },
  
  // redirect to 404 page **page-not-found** you can change any other name or **404**
  {path: 'page-not-found', component: PageNotFoundComponent,runGuardsAndResolvers: 'always'},
  {path: '**', redirectTo: 'page-not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
