import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-property-list-footer',
  templateUrl: './property-list-footer.component.html',
  styleUrls: ['./property-list-footer.component.scss']
})
export class PropertyListFooterComponent implements OnInit {
  isDisclaimer: boolean=false;

  constructor() { }

  ngOnInit(): void {
  }

  openModel(isDisclaimer=false){
    this.isDisclaimer=isDisclaimer
    $('#disclaimer__model').modal('toggle');
  }

}
