/* tslint:disable:no-string-literal quotemark */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API_BASE_URL } from 'src/app/global';

declare var $: any;

@Component({
  selector: 'app-buy-property',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './buy-property.component.html',
  styleUrls: ['./buy-property.component.scss']
})
export class BuyPropertyComponent implements OnInit {

  transaction_type_id: string = '1';
  sellingSearchInput;
  area_title: string = '';
  area_lat: string = '';
  area_lng: string = '';
  board_type: string = '';

  constructor(private router: Router,  private route: ActivatedRoute, private title: Title) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    $('#projLinkBtn').show();
    if (this.route.snapshot.queryParams.board_type) {
      this.board_type = this.route.snapshot.queryParams.board_type;
    }
    if (this.route.snapshot.queryParams.locality_elocs) {
      this.area_title = this.route.snapshot.queryParams.locality_elocs;
    }

    setTimeout (() => {
      this.sellingSearchInput = $('#sellingSearchInput');
      this.sellingSearchInput.typeahead(null, {
        displayKey: 'placeNameFull',
        source: (search, sync, async) => {
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL + '/master/getLocality',
              data: {is_web_api: 1, search: search},
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      this.sellingSearchInput.on('typeahead:selected', function (e, datalocitem) {
        this.area_title = datalocitem.placeName;
        this.area_lat = datalocitem.latitude;
        this.area_lng = datalocitem.longitude;
      });

      this.changeHeaderClass();
    }, 200);
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Buy');
  }

  onBackToHome() {
    this.router.navigate(['/']);
  }

  searchProperty(page) {
    var data = {};
    // if (this.area_title != "" && this.area_lat != "" && this.area_lng != "") {
    if (this.board_type) {
      data['board_type'] = this.board_type;
    }
    data['transaction_type_id'] = 4;
    data['property_type_id'] = this.transaction_type_id;
    localStorage.setItem('transaction_type_id_dynamic', this.transaction_type_id)

    if (this.area_title) {
      data['locality_elocs'] = this.area_title;
    } else {
      const userCurrCity = localStorage.getItem('user_curr_city');
      if (userCurrCity && userCurrCity !== 'undefined' && userCurrCity !== undefined) {
        data['locality_elocs'] = userCurrCity;
      } else {
        data['locality_elocs'] = 'Vadodara';
      }
    }
    if (this.route.snapshot.queryParams.is_featured !== undefined) {
      data['is_featured'] = this.route.snapshot.queryParams.is_featured;
    }

    if (page === 'project') {
      this.router.navigate(['/projects'], {queryParams: data});
    } else {
      this.router.navigate(['/property'], {queryParams: data});
    }
  }

  selPropTypeDiv(divType) {
    $('#residential_div').removeClass('bg_white');
    $('#commercial_div').removeClass('bg_white');
    $('#land_div').removeClass('bg_white');
    $('#projLinkBtn').show();
    if (divType === 'residential') {
      $('#residential_div').addClass('bg_white');
    } else if (divType === 'commercial') {
      $('#commercial_div').addClass('bg_white');
    } else {
      $('#land_div').addClass('bg_white');
      $('#projLinkBtn').hide();
    }
  }
}
