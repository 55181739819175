<div class="container-fluid" style="background: #f5f5f5;">
    <section id="job_top">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div id="div_job_img">
              <img class="job_img" src="../../assets/img/job2.png"/>
            </div>
          </div>
          <div class="col-md-6 text-center">
            <div id="join_us">
              <p class="title">WHY JOIN US?</p>
              <!-- <p class="content">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                ut labore et dolore magna Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container jobs">
      <div class="row">
        <div class="col-md-4" style="margin-bottom: 30px;">
          <div class="job-list">
            <h4 class="job_title">ADMIN</h4>
            <div class="job-details">
              <p class="text-justify job_location">
                <i class="fa fa-map-marker" aria-hidden="true"></i> Vadodara, Gujarat
              </p>
              <p class="text-justify">
                The one who admimistrates and supports the office and the staff for the daily Funtions and Activities.
              </p>
            </div>
            <div class="apply">
              <button class='btn' (click)="openApplyModel('Admin')">Apply</button>
            </div>
          </div>
        </div>

        <div class="col-md-4" style="margin-bottom: 30px;">
          <div class="job-list">
            <h4 class="job_title">RELATIONALSHIP MANAGER</h4>
            <div class="job-details">
              <p class="text-justify job_location">
                <i class="fa fa-map-marker" aria-hidden="true"></i> Vadodara, Gujarat
              </p>
              <p class="text-justify">
                Someone who directly communicates with the customers, Streategieses on building Sales Plan, setting up Quotas, Plans and tracks the sales plan.
              </p>
            </div>
            <div class="apply">
              <button class='btn' (click)="openApplyModel('Relationalship Manager')">Apply</button>
            </div>
          </div>
        </div>

        <div class="col-md-4" style="margin-bottom: 30px;">
          <div class="job-list">
            <h4 class="job_title">TEAM LEAD</h4>
            <div class="job-details">
              <p class="text-justify job_location">
                <i class="fa fa-map-marker" aria-hidden="true"></i> Vadodara, Gujarat
              </p>
              <p class="text-justify">
                The one who leads the team in a Right direction and provides daily tasks and goals to achive the milestones.
              </p>
            </div>
            <div class="apply">
              <button class='btn' (click)="openApplyModel('Team Lead')">Apply</button>
            </div>
          </div>
        </div>

        <div class="col-md-4" style="margin-bottom: 30px;">
          <div class="job-list">
            <h4 class="job_title">LEAD GENERATOR</h4>
            <div class="job-details">
              <p class="text-justify job_location">
                <i class="fa fa-map-marker" aria-hidden="true"></i> Vadodara, Gujarat
              </p>
              <p class="text-justify">
                Someone who's able to generate more leads and is able to convert the prospects into potential customer, with their Negotiating skills, Communications and Strategies.
              </p>
            </div>
            <div class="apply">
              <button class='btn' (click)="openApplyModel('Lead Generator')">Apply</button>
            </div>
          </div>
        </div>

        <div class="col-md-4" style="margin-bottom: 30px;">
          <div class="job-list">
            <h4 class="job_title">MARKETING MANAGER</h4>
            <div class="job-details">
              <p class="text-justify job_location">
                <i class="fa fa-map-marker" aria-hidden="true"></i> Vadodara, Gujarat
              </p>
              <p class="text-justify">
                One who's responsible for initaiting Marketing Campaings, able to boost company's potential on Social Media domains though promotions and branding, and is able to gather more traffic through Brand Awarness.
              </p>
            </div>
            <div class="apply">
              <button class='btn' (click)="openApplyModel('Marketing Manager')">Apply</button>
            </div>
          </div>
        </div>

        <div class="col-md-4" style="margin-bottom: 30px;">
          <div class="job-list">
            <h4 class="job_title">SYSTEM COORDINATOR</h4>
            <div class="job-details">
              <p class="text-justify job_location">
                <i class="fa fa-map-marker" aria-hidden="true"></i> Vadodara, Gujarat
              </p>
              <p class="text-justify">
                The one who coordinates and directs the whole system in to a proper workflow for a systematic completion of the work.
              </p>
            </div>
            <div class="apply">
              <button class='btn' (click)="openApplyModel('System Coordinator')">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="applyFormModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Job Application</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <!-- <app-flash></app-flash> -->
        <div class="newform">
          <form [formGroup]="applyForm" (ngSubmit)="onApplySubmtit()" enctype="multipart/form-data">
            <div class="form-group">
              <input type="text" formControlName="position" id="position" class="form-control" placeholder="Position" [ngClass]="{ 'is-invalid': submitted && f.position.errors }" />
              <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
                <div *ngIf="f.position.errors.required">Position is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" formControlName="fullname" class="form-control" placeholder="Name" [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" />
              <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                <div *ngIf="f.fullname.errors.required">First name is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" [(ngModel)]="phone" formControlName="mobileNumber" class="form-control" [pattern]="mobNumberPattern" placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }" />
              <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
                <div *ngIf="f.mobileNumber.errors.pattern || f.mobileNumber.errors.minlength || f.mobileNumber.errors.maxlength">Please enter valid Mobile Number</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" formControlName="email" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <input type="file" formControlName="resume" class="file-input" (change)="onFileSelected($event)" #fileUpload>
            </div>
            <div class="form-group">
              <button  class="bttn btn-block login-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
