/* tslint:disable:no-var-keyword prefer-const no-shadowed-variable variable-name no-string-literal max-line-length */
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { API_ENDPOINTS } from '../../global';
import { ApiService } from './../../_services/api.service';

declare var $: any;

@Component({
  selector: 'app-accountheader',
  templateUrl: './myaccount-header.component.html',
  styleUrls: ['./myaccount-header.component.scss']
})

export class MyAccountHeaderComponent implements OnInit {
  @Input() dashboardtypeinput: string;
  isLoggedIn;
  dashboardType = 'Buyer';
  contactType = 'both';

  constructor(private ApiService: ApiService,  private route: ActivatedRoute) {

  }

  ngOnInit() {
    var componentObj = this;
    this.isLoggedIn = this.ApiService.loginCheck();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.route.snapshot.queryParams.type !== undefined && this.route.snapshot.queryParams.type !== '') {
      var data = {};
      if (this.route.snapshot.queryParams.type === 'both') {
        this.contactType = 'both';
        this.dashboardType = 'Buyer';
        data['type'] = 'Buyer';
      } else {
        this.contactType = this.route.snapshot.queryParams.type;
        this.dashboardType = this.route.snapshot.queryParams.type;
        data['type'] = this.route.snapshot.queryParams.type;
      }
    } else {
      if (currentUser) {
        var form_data = new FormData();
        form_data.append('is_web_api', '1');
        this.ApiService.postRequest(API_ENDPOINTS.API_CHECK_USER_ACC_TYPE, form_data).subscribe((apiResult: any) => {
          var data = {};
          if (apiResult.success) {
            this.contactType = apiResult.data.contact_type;
            if (apiResult.data === 'both') {
              data['type'] = 'Buyer';
            } else {
              data['type'] = apiResult.data.contact_type;
            }
          } else {
            this.contactType = 'both';
          }
        });
      }
      this.dashboardType = this.dashboardtypeinput;
    }
  }
}
