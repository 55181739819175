/* tslint:disable:quotemark no-inferrable-types variable-name max-line-length no-shadowed-variable typedef-whitespace no-var-keyword whitespace prefer-const comment-format triple-equals no-string-literal object-literal-shorthand */
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS } from '../../global';
import { Meta, Title } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import {DOCUMENT} from '@angular/common';
import { Lightbox } from 'ngx-lightbox';
import { FlashService } from '../../_services/FlashService.service';

declare var $: any;
declare const require: any;
declare var google: any;

@Component({
  selector: 'app-project-unit-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './project-unit-details.component.html',
  styleUrls: ['./project-unit-details.component.scss']
})
export class ProjectUnitDetailsComponent implements OnInit {

  emiForm: FormGroup;
  shareform: FormGroup;
  submitted = false;
  propertyinfo: any = [];
  similerProperties: any = [];
  suggestedProperties: any = [];
  schedulevisitdata = {};
  isLoggedIn;
  isFavoriting: boolean = false;
  showPropImages: boolean = true;
  nearByLandmarks: string = 'school';

  zoom: number = 13;
  lat: number = 22.295492;
  lng: number = 73.173463;
  markers: marker[] = [];
  schoolMarkers: marker[] = [];
  icon = {
    url: 'assets/img/map_property_pin.png',
    scaledSize: {
      width: 33,
      height: 50
    }
  };
  iconSchool = {
    url: 'assets/img/school_pin.png',
    scaledSize: {
      width: 25,
      height: 41
    }
  };
  propMap: any;

  home_price: number = 500000;
  down_payment: number = 50000;
  new_home_price: number = this.home_price - this.down_payment;
  down_payment_per: number = 10;
  loan_program_options: any = [];
  loan_program: number = 1;
  int_rate: number = 1.5;

  inc_morg_ins: boolean = false;

  property_tex_per: number = 0;
  property_tex_yearly: number = 10;

  home_ins_year: number = 6000;

  hoa_per_month: number = 0;

  water_sewer_month: number = 0;
  gas_month: number = 0;
  internet_month: number = 0;
  electric_month: number = 0;
  enableSticky: boolean = false;
  _album = [];
  tourInPerson = true;
  tourVideoChat = false;

  constructor(private ref: ChangeDetectorRef, private router: Router,  private route: ActivatedRoute, private ApiService: ApiService, private title: Title, private meta: Meta, private formBuilder: FormBuilder, @Inject(DOCUMENT) private document:Document, private _lightbox: Lightbox, private FlashService: FlashService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.route.params.subscribe(routeParams => {
      this.emiForm = this.formBuilder.group({
        home_price: ['', [Validators.minLength(1), Validators.min(1)]],
        down_payment: ['', [Validators.minLength(1), Validators.min(1)]],
        down_payment_per: ['', [Validators.minLength(1), Validators.min(1)]],
        int_rate: ['', [Validators.minLength(1), Validators.min(1)]],
        home_ins_year: ['', [Validators.minLength(0), Validators.min(0)]],
        hoa_per_month: ['', [Validators.minLength(0), Validators.min(0)]],
        water_sewer_month: ['', [Validators.minLength(0), Validators.min(0)]],
        gas_month: ['', [Validators.minLength(0), Validators.min(0)]],
        internet_month: ['', [Validators.minLength(0), Validators.min(0)]],
        electric_month: ['', [Validators.minLength(0), Validators.min(0)]],
        property_tex_per: ['', [Validators.minLength(0), Validators.min(0)]]
      });
      this.getLoanMonthly();
      this.getProjectUnitDetail();
      this.isLoggedIn = this.ApiService.loginCheck();
      setTimeout (() => {
        // this.generateAppointmentSlider();
        $(document).ready(() => {
          $('#shrehide').click(() => {
            $('#visit').toggle();
          });
          $("#hidendiv").click(() => {
            $("#propertydimention").toggle();
          });
        });
        this.changeHeaderClass();
        this.loan_program_options = [
          {id: 1, title: '5 Years'},
          {id: 2, title: '10 Years'},
          {id: 3, title: '15 Years'},
          {id: 4, title: '20 Years'}
        ];
      }, 200);
    });

    this.shareform = this.formBuilder.group({
      emailShare: ['', [Validators.required, Validators.email]]
      // subjectShare: ['', [Validators.required]]
    });

    fromEvent(window, "scroll").subscribe((event) => {
      if (this.document.defaultView.scrollY > 750) {
        this.enableSticky = true;
      } else {
        this.enableSticky = false;
      }
    });
  }

  getLoanMonthly() {
    var term = 5;
    if (this.loan_program == 1) {
      term = 5;
    } else if(this.loan_program == 2) {
      term = 10;
    } else if(this.loan_program == 3) {
      term = 15;
    } else if(this.loan_program == 4) {
      term = 20;
    }
    let totalAmount = (this.home_price - this.down_payment) * (this.int_rate / 100) * term;
    let monthlyAmount = ((this.home_price - this.down_payment) + totalAmount) / (term * 12);
    $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
  }

  addMarker(markerN) {
    this.markers.push(markerN);
  }

  get i() {
    return this.emiForm.controls;
  }

  get g() {
    return this.shareform.controls;
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    var position = new google.maps.LatLng(this.lat, this.lng);
    this.propMap.panTo(position);
  }

  getNearBYLocations(type) {
    this.schoolMarkers = [];
    var componentObj = this;
    var searchLatLng = new google.maps.LatLng(this.lat,this.lng);
    var request = {
      location: searchLatLng,
      radius: '1500',
      types: [type]
    };
    var service = new google.maps.places.PlacesService(this.propMap);
    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        for (var i = 1; i < results.length; i++) {
          var d = componentObj.distance(searchLatLng, results[i].geometry.location);
          if((d as any) < 0) {
            d = 0.1;
          }
          var markerN = {
            pid: "",
            lat: results[i].geometry.location.lat(),
            lng: results[i].geometry.location.lng(),
            draggable: false,
            image_preview: "",
            locality_title: results[i].name,
            asking_price: d + " km"
          };
          componentObj.addSchoolMarker(markerN);
        }
      }
    });
  }

  distance(p1, p2) {
    if (!p1 || !p2) {
      return 0;
    }
    var R = 6371000;
    var dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
    var dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    d = d / 1000;
    return d.toFixed(1);
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Project Unit Details');
  }

  addSchoolMarker(markerN) {
    this.schoolMarkers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  clickedMarker(pid: string) {
    //this.router.navigate(['property-details', pid]);
  }

  getProjectUnitDetail() {
    var componentObj = this;
    var form_data = new FormData();
    form_data.append('project_unit_id', this.route.snapshot.params.id);
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROJECT_UNIT_DETAIL, form_data).subscribe((apiResult: any) => {
      if(apiResult.success){
        this.propertyinfo = apiResult.data;
        this.meta.updateTag({ name: 'description', content: this.propertyinfo.name });
        if(this.propertyinfo.locality_lat != "" && this.propertyinfo.locality_lng != "") {
          var markerN = {
            pid: this.propertyinfo.id,
            lat: this.propertyinfo.locality_lat,
            lng: this.propertyinfo.locality_lng,
            draggable: false,
            image_preview: "",
            locality_title: this.propertyinfo.locality_title,
            asking_price: this.propertyinfo.unit_data.rpcf_price_title
          };
        }
        this.schedulevisitdata['related_source_id'] = apiResult.data.id;
        this.schedulevisitdata['assign_to_user_id'] = apiResult.data.assign_to_user_id;
        this.schedulevisitdata['franchisee_id'] = apiResult.data.franchisee_id;
        this.generateAppointmentSlider(apiResult.data.slot_dates);
        var slideImgs = [];
        if(apiResult.data.proj_gallery){
          if(apiResult.data.proj_gallery.length > 3) {
            var countMore = apiResult.data.proj_gallery.length - 3;
            $('.more_images_3').html(countMore + "+ <br>More Images");
            $('.more_images_3').removeClass('hidden');
          }
          let newArray = [];
          $(apiResult.data.proj_gallery).each((sImageKey, sImage) => {
            if(sImageKey === 0) {
              $('.project_main_img').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            if(sImageKey === 1) {
              $('.project_other_img_1').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            if(sImageKey === 2) {
              $('.project_other_img_2').css('background-image', 'url(' + sImage.preview_url + ')');
            }
            const src = sImage.preview_url;
            const caption = '';
            const thumb = sImage.preview_url;
            const album = {
              src: src,
              caption: caption,
              thumb: thumb
            };

            newArray.push(album);
          });
          this._album = newArray;
        } else {
          this.showPropImages = false;
        }
        if(apiResult.data.unit_data.base_price != "" && apiResult.data.unit_data.base_price > 0) {
          this.home_price = parseFloat(apiResult.data.unit_data.base_price);
          this.down_payment = (this.home_price * this.down_payment_per) / 100;
          this.new_home_price = this.home_price - this.down_payment;
          this.getLoanMonthly();
        }
      }
    });
  }

  makeFavorite(pid, puid) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      this.isFavoriting = true;
      var form_data = new FormData();
      form_data.append('id', pid);
      form_data.append('project_unit_id', puid);
      form_data.append('status', "1");
      this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_FAVORITE, form_data).subscribe((apiResult: any) => {
        this.isFavoriting = false;
        if(apiResult.success) {
          if($('.heart_btn').hasClass('is_favorite_prop')) {
            $('.heart_btn').removeClass('is_favorite_prop');
            this.FlashService.success('Project is removed from your Wishlist.')
          } else {
            $('.heart_btn').addClass('is_favorite_prop');
            this.FlashService.success('Project is added to your Wishlist.')
          }
        }
      });
    }
  }

  makeFavoriteOthers(pid) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('property_id', pid);
      form_data.append('status', "1");
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
        if(apiResult.success) {
          if($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
          }
        }
      });
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  open(index: number): void {
    // open lightbox
    if(this._album.length>0){
      this._lightbox.open(this._album, index, { showZoom: true });
    }
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  generateAppointmentSlider_old() {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    var dateDay = String(d.getDate()).padStart(2, '0');
    var monthname = monthNames[d.getMonth()];
    var currentYear = (new Date).getFullYear();
    var currentMonth = (new Date).getMonth() + 1;
    var n = daysInMonth(currentMonth, currentYear);
    var a = [];
    var dateDayi = parseInt(dateDay);
    for (var i = dateDayi; i <= n; i++) {
      var weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var frmate = currentMonth+'/'+i+'/'+currentYear;
      var date = new Date('"'+frmate+'"');
      var dyname = weekday[date.getDay()];
      var datestr = i + "/" + currentMonth + "/" + currentYear;
      var actClass = "";
      if(i === dateDayi) {
        actClass = "s_day_active";
      }
      a.push('<div class="single_day '+ actClass +'" data-date="'+datestr+'"><label class="day_name">'+dyname+'</label><label class="day_date">'+i+'</label><label class="month_name">'+monthname+'</label></div>');
    }
    var preDays = n - dateDayi;
    if(preDays < 15) {
      if((d.getMonth() + 1) > 11) {
        var monthname = monthNames[0];
        var currentYear = (new Date).getFullYear() + 1;
        var currentMonth = 1;
      } else {
        var monthname = monthNames[d.getMonth() + 1];
        var currentYear = (new Date).getFullYear();
        var currentMonth = (new Date).getMonth() + 2;
      }
      var n = daysInMonth(currentMonth, currentYear);
      for (var i = 1; i <= n; i++) {
        var weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var frmate = currentMonth+'/'+i+'/'+currentYear;
        var date = new Date('"'+frmate+'"');
        var dyname = weekday[date.getDay()];
        var datestr = i + "/" + currentMonth + "/" + currentYear;
        var actClass = "";
        if(i == 1 && preDays < 0) {
          actClass = "s_day_active";
        }
        a.push('<div class="single_day '+ actClass +'" data-date="'+datestr+'"><label class="day_name">'+dyname+'</label><label class="day_date">'+i+'</label><label class="month_name">'+monthname+'</label></div>');
      }
    }
    $("#visit_date_slider").html(a);

    $('#visit_date_slider').owlCarousel({
      loop: false,
      nav: true,
      navText : ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>","<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive:{}
    });

    $('.single_day').on('click', function(event){
      $('.s_day_active').removeClass('s_day_active');
      $(this).addClass('s_day_active');
    });
  }

  generateAppointmentSlider(slotDatesAry) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    var dateDay = String(d.getDate()).padStart(2, '0');
    var monthname = monthNames[d.getMonth()];
    var currentYear = (new Date).getFullYear();
    var currentMonth = (new Date).getMonth() + 1;
    var n = daysInMonth(currentMonth, currentYear);
    var a = [];
    var dateDayi = Number(dateDay);
    // tslint:disable-next-line:forin
    for (let prop in slotDatesAry) {
      const slotData = slotDatesAry[prop];
      let dyname = slotData.day_name;
      let datestr = slotData.date_fromat;
      let actClass = '';
      let bookClass = '';
      let title = '';
      if(slotData.slot !== 'book' && Number(slotData.day) === dateDayi) {
        actClass = "s_day_active";
      }
      if(slotData.slot === 'book') {
        bookClass = 's_booked_slot';
        title = 'Fully Booked';
      }
      a.push('<div class="single_day '+ actClass +' ' + bookClass + '" data-date="'+datestr+'" data-slot="'+slotData.slot+'" title="'+title+'"><label class="day_name">'+dyname+'</label><label class="day_date">'+slotData.day+'</label><label class="month_name">'+slotData.month+'</label></div>');
    }
    $("#visit_date_slider").html(a);

    $('#visit_date_slider').owlCarousel({
      loop: false,
      nav: true,
      navText : ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>","<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive:{}
    });

    $('.single_day').on('click', function(event){
      if ($(this).data('slot') === 'available') {
        $('.s_day_active').removeClass('s_day_active');
        $(this).addClass('s_day_active');
      }
    });
  }

  searchschedulevisit(franchisee_id, assign_to_user_id) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      this.schedulevisitdata['related_source_id'] = this.route.snapshot.params.id;
      this.schedulevisitdata['appointment_type'] = (this.tourInPerson == true ? '0' : '1');
      this.schedulevisitdata['franchisee_id'] = franchisee_id;
      this.schedulevisitdata['assign_to_user_id'] = assign_to_user_id;
      this.schedulevisitdata['temp_source'] = 'project_unit';
      this.schedulevisitdata['source'] = 'project';
      this.schedulevisitdata['date'] = $('.s_day_active').data('date');
      this.schedulevisitdata['project_unit_id'] = this.route.snapshot.params.id;
      this.router.navigate(['/schedulevisit'], {queryParams: this.schedulevisitdata});
    }
  }

  selectAppointmentType(select) {
    this.tourInPerson = false;
    this.tourVideoChat = false;
    if (select == 1) {
      this.tourInPerson = true;
    } else {
      this.tourVideoChat = true;
    }
  }

  scrollTo(element_id){
    var el = document.getElementById(element_id);
    el.scrollIntoView({behavior: 'smooth'});
  }

  changeNearBy(type, gm) {
    if(type !== this.nearByLandmarks) {
      this.iconSchool = {
        url: 'assets/img/' + type + '_pin.png',
        scaledSize: {
          width: 25,
          height: 41
        }
      };
      gm.lastOpen = null;
      this.nearByLandmarks = type;
      this.getNearBYLocations(this.nearByLandmarks);
    }
  }

  mapReady(map) {
    this.propMap = map;
  }

  onShareSubmit() {
    var componentObj = this;
    this.submitted = true;
    if (this.shareform.invalid) {
      return;
    }
    var emailid = this.shareform.value.emailShare;
    // var subject = this.shareform.value.subjectShare;
    var form_data = new FormData();
    form_data.append('email', emailid);
    // form_data.append('subject', subject);
    form_data.append('is_web_api','1');
    form_data.append('source','project_unit');
    form_data.append('project_unit_id', this.route.snapshot.params.id);
    this.ApiService.shareLink(form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success(apiResult.message);
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      console.log('error');
    });
  }

  onEMITabClick(id) {
    if(id == 1) {
      this.getLoanMonthly();
    } else if(id == 2) {
      this.changeMortgage();
    } else if(id == 3) {
      this.onPropertyTaxPerChange();
    } else if(id == 4) {
      this.onYearInsChange();
    }
  }

  changeMortgage() {
    var term = 5;
    if(this.loan_program == 1) {
      term = 5;
    } else if(this.loan_program == 2) {
      term = 10;
    } else if(this.loan_program == 3) {
      term = 15;
    } else if(this.loan_program == 4) {
      term = 20;
    }
    term = term * 12;
    var newHomeAmt = (this.home_price - this.down_payment);
    var aprMonthlyRate = ((this.int_rate / 100) / 12);
    var interestPayments = Math.pow(1 + aprMonthlyRate, term);
    var final = Math.round((newHomeAmt * (aprMonthlyRate * interestPayments) / (interestPayments - 1)));
  }

  onPropertyTaxPerChange() {
    if(this.property_tex_per <= 0) {
      this.property_tex_per = 0;
    }
    if(this.property_tex_per > 0) {
      this.property_tex_yearly = Math.round((this.new_home_price * this.property_tex_per) / 100);
      var monthlyAmount = (this.property_tex_yearly / 12);
      $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
    } else {
      $('#cost_month').html('N/A');
    }
  }

  onYearInsChange() {
    if(this.home_ins_year <= 0) {
      this.home_ins_year = 0;
    }
    if(this.home_ins_year > 0) {
      var monthlyAmount = (this.home_ins_year / 12);
      $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
    } else {
      $('#cost_month').html('N/A');
    }
  }

  onEMICalPriceChange() {
    if(this.home_price <= 0) {
      this.home_price = 500000;
    }
    this.down_payment = (this.home_price * this.down_payment_per) / 100;
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }

  onEMICalDownPaymentChange() {
    if(this.down_payment <= 0) {
      this.down_payment_per = 1;
      this.down_payment = (this.home_price * this.down_payment_per) / 100;
    } else {
      this.down_payment_per = (this.down_payment * 100) / this.home_price;
    }
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }

  onEMICalTermChange() {
    this.getLoanMonthly();
  }

  onEMICalIntRateChange() {
    if(this.int_rate <= 0) {
      this.int_rate = 1;
    }
    this.getLoanMonthly();
  }

  onEMICalDownPaymentPerChange() {
    if(this.down_payment_per <= 1) {
      this.down_payment_per = 1;
    }
    this.down_payment = (this.home_price * this.down_payment_per) / 100;
    this.new_home_price = this.home_price - this.down_payment;
    this.getLoanMonthly();
  }
  propertyInArea(loc){
    this.router.navigate(['/property'], { queryParams: { locality_elocs: loc}});
  }
}

function daysInMonth (month, year) {
  return new Date(year, month, 0).getDate();
}

interface marker {
  pid?: string;
  lat: number;
  lng: number;
  label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
  draggable: boolean;
}
