export function OrderByArray(
    values: any[],
    orderType: string,
    sortDir: string = 'asc',
    isDateField: boolean = false,
    isReversed:boolean=false
  ) {
    let records = values.sort((a, b) => {
     let aDate  = isReversed ? a[orderType].split("/").reverse().join("/") : a[orderType]
     let bDate = isReversed ? b[orderType].split("/").reverse().join("/") : b[orderType]
      if (isDateField) {
        return +new Date(aDate) - +new Date(bDate);
      }
  
  
      let firstElement = typeof (a[orderType]) == "string" ? a[orderType]?.toUpperCase() : a[orderType];
      let secondElement = typeof (b[orderType]) == "string" ? b[orderType]?.toUpperCase() : b[orderType];
      if (firstElement === '' || secondElement === '') return -1;
  
      return firstElement > secondElement ? 1 : firstElement < secondElement ? -1 : 0;
    });
    if (sortDir == 'desc') {
      records = records.reverse();
    }
    return records;
  }