import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlashService } from '../../_services/FlashService.service';

@Component({
  selector: 'app-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss']
})

export class FlashComponent implements OnInit,OnDestroy {
  private subscription: Subscription;
  message: any;
  constructor(private ref: ChangeDetectorRef, private flashService: FlashService) {}

  ngOnInit() {
    this.subscription = this.flashService.getMessage().subscribe(message => {
      this.message = message;
      this.ref.detectChanges();
      setTimeout(() => {
        if(this.message && this.message.type)
        this.message.type='hide'
      }, 5000);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
