<div class="container-fluid" id="affordableCalculator">
  <ng-container *ngIf="isFindingProperty == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <div class="container">
    <!-- Start Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li>
            <a href="/"> Home </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> Affordable Calculator </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <div class="page-head">
          <h1>Affordable Calculator</h1>
          <p>This calculator shows properties that fit your budget. Savings, debt and other expenses could impact the amount you want to spend on rent each month. Input your net (after tax) income and the calculator will display rentals up to 40% of your estimated gross income. Property managers typically use gross income to qualify applicants, so the tool assumes your net income is taxed at 25%. Actual tax rates vary.</p>
        </div>
        <div class="title_afford">EMI: <span id="cost_month"></span></div>
        <div class="rent-calculate_main" [formGroup]="emiForm">
          <div class="row d-flex">
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-label" for="homePrice">Price</label>
                <div class="custom_input_prepend">
                  <i class="fa fa-rupee"></i>
                  <input type="number" id="homePrice" [(ngModel)]="homePrice" formControlName="homePrice"
                         [ngClass]="{ 'is-invalid': g.homePrice.errors }" class="form-control"
                         placeholder="Home Price" (keyup)="onEMICalPriceChange()" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <div class="inter-flex">
                  <label class="input-label down_payment" for="downPayment">Down Payment</label>
                  <label class="input-label down_payment_per" for="downPaymentPer">Down Payment %</label>
                </div>
                <div class="inter-flex">
                  <div class="custom_input_prepend">
                    <i class="fa fa-rupee"></i>
                    <input type="number" id="downPayment" [(ngModel)]="downPayment"
                           formControlName="downPayment" [ngClass]="{ 'is-invalid': g.downPayment.errors }"
                           class="form-control" placeholder="Down Payment" (keyup)="onEMICalDownPaymentChange()" />
                  </div>
                  <div class="custom_input_append">
                    <input type="number" id="downPaymentPer" [(ngModel)]="downPaymentPer"
                           formControlName="downPaymentPer"
                           [ngClass]="{ 'is-invalid': g.downPaymentPer.errors }" class="form-control"
                           placeholder="DP Percent" (keyup)="onEMICalDownPaymentPerChange()" />
                    <span class="append_text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-label" for="loanProgram">Loan program</label>
                <select [(ngModel)]="loanProgram" id="loanProgram" class="form-control" (change)="onEMICalTermChange()"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let loan_program_option of loanProgramOptions"
                          value="{{ loan_program_option.id }}">
                    {{ loan_program_option.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="input-label" for="intRate">Interest rate</label>
                <div class="custom_input_append">
                  <input type="number" id="intRate" [(ngModel)]="intRate" formControlName="intRate"
                         [ngClass]="{ 'is-invalid': g.intRate.errors }" class="form-control"
                         placeholder="Interest rate" (keyup)="onEMICalIntRateChange()" />
                  <span class="append_text">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rent-slider_main" *ngIf="net_income > 0">
          <div class="row d-flex">
            <div class="col-md-6">
              <h3 [innerHTML]="rentSliderTitle"></h3>
              <p [innerHTML]="rentSliderText"></p>
              <h4 [innerHTML]="rentSliderSpend"></h4>
            </div>
            <div class="col-md-6">
              <ng5-slider [(value)]="rentSliderValue" (valueChange)="onRentCalPriceChange()" [options]="rentSliderOptions"></ng5-slider>
            </div>
          </div>
        </div>
        <div class="property-results">
          <h4 id="p_counter" *ngIf="matchingResult > 0">{{ matchingResult }} matching rentals {{ locationSearch }}</h4>
          <h4 id="p_counter" *ngIf="matchingResult == 0">No matching rentals</h4>
          <div class="row ss_property_view" id="matching_property_view">
            <ng-container *ngFor="let item of matchingProperties">
              <div class="d-flex single_property_view" *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                <div class="card">
                  <div class="grid_image_view" [style.background-image]="'url(\'' + item.image_preview + '\')'">
                    <div class="property_badge top_left" *ngIf="item.prop_transaction_type">
                      <span class="property_badge_sell">{{item.prop_transaction_type.title}}</span>
                    </div>
                    <div class="property_badge top_right favoriteIcon_{{item.id}}" [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }" *ngIf="item.prop_transaction_type" (click)="makeFavoriteOthers(item.id)">
                      <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: #FFFFFF;"></i>
                      <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                    </div>
                  </div>
                  <div class="card-body" [routerLink]="['/property-details', item.id ]">
                    <h5 class="card-title"><i class="fa fa-rupee"></i>{{item.asking_price}}</h5>
                    <p class="card-text">
                      {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                    </p>
                    <p class="card-text">
                      <span class="pro_spec" *ngIf="item.total_washrooms"> <i class="fa fa-bath"></i> {{item.total_washrooms}} Bath </span>
                      <span class="pro_spec" *ngIf="item.total_bedrooms"> <i class="fa fa-bed"></i> {{item.total_bedrooms}} Bed </span>
                      <span class="pro_spec" *ngIf="item.saleable_area"> <i class="fa fa-check-square"></i> {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="bottom_buttons">
            <span *ngIf="net_income > 0 && matchingResult > 0" class="btn view_all" (click)="viewallMatching()">See all matching rentals</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
