import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Empire Realtors';

  constructor() {

  }

  ngOnInit() {
    this.getLocation()
    this.handleLocationAvailibility()
  }

  handleLocationAvailibility() {
    navigator.permissions.query({ name: 'geolocation' }).then((permission) => {
      permission.onchange = (event) => {
        const newEv = event.target as any
        if (newEv.state == 'granted') {
          this.confirmBox()
        } else if (newEv.state == 'prompt') {
        } else {
          this.confirmBox()
        }
      };
    });
  }

  confirmBox() {
    if (confirm('Your new location has been updated. Please click "OK" to continue with new location.')) {
      window.location.reload()
    } else { 
      console.log('You have denied to refesh!!!')
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          const oldUserCurrCity = localStorage.getItem('user_curr_city');
          const GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + '%2C' + position.coords.longitude + '&language=en&key=' + environment.G_CLIENT_KEY;
          let city = '';
          $.getJSON(GEOCODING).done((location) => {
            const results = location.results;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < results[0].address_components.length; i++) {
              // tslint:disable-next-line:prefer-for-of
              for (let b = 0; b < results[0].address_components[i].types.length; b++) {
                if (results[0].address_components[i].types[b] === 'administrative_area_level_2' || results[0].address_components[i].types[b] === "administrative_area_level_3") {
                  city = results[0].address_components[i];
                  break;
                }
              }
            }
            // @ts-ignore
            localStorage.setItem('user_curr_city', (city.long_name ? city.long_name : 'Vadodara'));
            if (!oldUserCurrCity) {
              window.location.reload();
            }
          });
        }
      },
        (error: any) => {
          localStorage.setItem('user_curr_city', ('Vadodara'));
          console.log(error)
        });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

}
