/* tslint:disable:no-shadowed-variable max-line-length no-string-literal */
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { Meta, Title } from '@angular/platform-browser';
import { FlashService } from '../../_services/FlashService.service';

declare var $: any;

@Component({
  selector: 'app-luxury',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './luxury.component.html',
  styleUrls: ['./luxury.component.scss']
})
export class LuxuryComponent implements OnInit {

  transactionTypeId = '1';
  propType = 'new';

  constructor(private router: Router, private title: Title) { }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    window.scroll(0, 0);
    const componentObj = this;
    setTimeout (() => {
      this.changeHeaderClass();
    }, 200);
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $('#imgHeadLogo').attr('src', 'assets/img/empire_logo.png');
    this.title.setTitle('Empire Realtors » Empire Luxurious');
  }

  searchProperty(page) {
    const data = {};
    data['transaction_type_id'] = 4;
    data['property_type_id'] = 1;
    data['is_luxury_property'] = 1;
    const userCurrCity = localStorage.getItem('user_curr_city');
    if (userCurrCity) {
      data['locality_elocs'] = userCurrCity;
    } else {
      data['locality_elocs'] = 'Vadodara';
    }
    if (this.propType === 'new') {
      this.router.navigate(['/luxury-projects'], {queryParams: data});
    } else {
      this.router.navigate(['/luxury-property'], {queryParams: data});
    }
  }

  selPropTypeDiv(divType) {
    $('#residential_div').removeClass('bg_white');
    $('#commercial_div').removeClass('bg_white');
    if (divType === 'new') {
      $('#residential_div').addClass('bg_white');
      this.propType = 'new';
    } else {
      $('#commercial_div').addClass('bg_white');
      this.propType = 'resales';
    }
  }
}
