export const environment = {
  production: true,

  API_BASE_URL:  'https://crm.empirerealtors.in',
  // API_BASE_URL:  'http://localhost/empirecrm',
   WEB_URL:  'https://www.empirerealtors.in',
  // WEB_URL:  'http://localhost:4200',


 // ** Start Google Data **/
  // G_CLIENT_ID:  '357433225707-e568m122cchvf9laos3fg2ie17f7r1if.apps.googleusercontent.com';
  G_CLIENT_ID: '621229473496-mle81a6aapf8i05l11at74dvglhuapvg.apps.googleusercontent.com',
  G_CLIENT_KEY:  'AIzaSyBegNdgOXPd2ICe4nhdPdcChJ2cb7uBNSE',
  G_SCOPE:  'profile email',
  // ** End Google Data **/

  // ** Start FB Data **/
  F_APP_ID: '259650981702792',
  F_SCOPE: 'user_birthday'
  // ** End FB Data **/
};
