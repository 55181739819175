/* tslint:disable:quotemark variable-name no-inferrable-types max-line-length no-shadowed-variable align no-var-keyword prefer-const whitespace triple-equals object-literal-key-quotes */
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS, WEB_URL } from 'src/app/global';
declare var $: any;

@Component({
  selector: 'app-offers',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class AccountOffersComponent implements OnInit {

  dashboardType = 'seller';
  submitted = false;
  offerformvalid: boolean = true;
  offerpriceminerror: boolean = true;
  offer_icon: string = '';
  isLoading: boolean = true;
  sellerOfferList: any = [];
  buyerOfferList: any = [];
  buyerOfferProjectList: any = [];
  propertyList: any = [];
  viewOffersList: any = [];
  projectUnitNoData: any = [];
  currentItem: any;

  propertyName: string = '';
  propertyPrice: string = '';
  offerForm: FormGroup;
  minOfferPrice: number = 0;
  project_unit_no_id: string = '';
  minOfferPriceText: string = '';
  offerPrice: number = 0;
  offerPriceInWord: string = '';
  projectUnitNoId: string = '';

  offerAmount;

  constructor(private ApiService: ApiService, private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private FlashService: FlashService, private title: Title, private formBuilder: FormBuilder) {
    this.offer_icon = API_ENDPOINTS.OFFER_ICON;
    window.scroll(0,0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    $('#filterPropBtn').show();
    var componentObj = this;
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
    });
    if (this.dashboardType == 'Seller') {
      this.getSellerOffersList();
    } else {
      this.getBuyerOfferList();
    }
    this.offerForm = this.formBuilder.group({
      projectUnitNoId: ['', [Validators.required]],
      offerprice: ['', [Validators.required]]
    });

    setTimeout(() => {
      this.changeHeaderClass();
      var amountStringMatcher = () => {
        return function findMatches(q, cb) {
          var matches = [];
          q = q.replace(/[^0-9\.]/g, '');
          matches.push({ 'label': q + ' Th', 'value': Math.round(q * 1000) });
          matches.push({ 'label': q + ' Lc', 'value': Math.round(q * 100000) });
          matches.push({ 'label': q + ' Cr', 'value': Math.round(q * 10000000) });
          cb(matches);
        };
      };
      this.offerAmount = $('#counterofferAmount');
      this.offerAmount.typeahead(null, {
        name: 'offerAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.offerAmount.on('typeahead:selected', (event, data) => {
        componentObj.offerPrice = data.value;
      });
    }, 200);
  }

  get g() {
    return this.offerForm.controls;
  }


  openInNewTab(data, id, type) {

    if (data.offered_property.prop_status.title == 'Sold') {
      return
    }
    if (type === 'property') {
      window.open(WEB_URL + '/property-details/' + id, '_blank')
    }

    if (type === 'PU') {
      window.open(WEB_URL + '/project-unit-details/' + id, '_blank')
    }
  }

  getSellerOffersList() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_OFFERS_PROPERTIES, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.sellerOfferList = apiResult.data.list;
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getBuyerOfferList() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_SET_BUYER_PROPERTY_OFFER, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerOfferList = apiResult.data.list.property;
        this.buyerOfferProjectList = apiResult.data.list.project;
        // this.ref.detectChanges();
      } else {
        this.isLoading = false;
        // this.ref.detectChanges();
      }
    });
  }

  onMakeCounterOfferClick(vieweditem) {
    this.propertyName = '';
    this.currentItem = vieweditem;
    this.offerForm.reset();
    $('#filterPropBtn').show();
    $('.loaderBtn').hide();
    $('.projectUnitNoIdDiv').hide();
    $('#projectUnitNoIdSel').removeAttr('disabled');
    if (vieweditem.project_id && vieweditem.project_unit_id && vieweditem.project_unit_no_id) {
      $('.projectUnitNoIdDiv').show();
      var form_data = new FormData();
      form_data.append('project_id', vieweditem.project_id);
      form_data.append('project_unit_id', vieweditem.project_unit_id);
      form_data.append('project_unit_no_id', vieweditem.project_unit_no_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_GET_PROJECT_UNIT_NO, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          this.projectUnitNoData = apiResult.data.list;
          this.project_unit_no_id = vieweditem.project_unit_no_id;
          if (vieweditem.project_unit_no_id > 0) {
            $('#projectUnitNoIdSel').attr('disabled', 'disabled');
          }
        }
        $('#makeCounterOfferModel').modal('toggle');
      }, error => {
        this.submitted = false;
        this.FlashService.error('Something wrong!');
        $('#makeCounterOfferModel').modal('toggle');
      });
    }
    this.propertyPrice = '<i class="fa fa-rupee"></i>' + vieweditem.asking_price_in_word;
    $('#makeCounterOfferModel').modal('toggle');
  }

  onOfferSubmit(favouritesItem) {
    this.submitted = true;
    let propertyId = 0;
    let projectId = 0;
    let projectUnitId = 0;
    let projectUnitNoId = '';
    if (favouritesItem.property_id > 0) {
      if (this.offerPrice === 0) {
        return;
      }
      propertyId = favouritesItem.property_id;
    } else {
      if (this.offerForm.invalid) {
        return;
      }
      projectId = favouritesItem.project_id;
      projectUnitId = favouritesItem.project_unit_id;
      projectUnitNoId = this.project_unit_no_id;
    }
    $('#filterPropBtn').hide();
    $('.loaderBtn').show();
    $('#projectUnitNoIdSel').removeAttr('disabled');
    var form_data = new FormData();
    form_data.append('wishlist_id', favouritesItem.id + '');
    form_data.append('property_id', propertyId + '');
    form_data.append('project_id', projectId + '');
    form_data.append('opportunity_id', favouritesItem.opportunity_id + '');
    form_data.append('project_unit_id', projectUnitId + '');
    form_data.append('project_unit_no_id', projectUnitNoId);
    form_data.append('offer_amount', this.offerPrice + '');
    var offer_amount_title = this.amountToString(this.offerPrice);
    form_data.append('offer_amount_title', offer_amount_title + '');

    this.ApiService.postRequest(API_ENDPOINTS.API_SET_OFFER, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success('Offer has been successfully sent.');
        this.getBuyerOfferList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.submitted = false;
      $('.loaderBtn').hide();
      $('#makeCounterOfferModel').modal('toggle');
    }, error => {
      this.submitted = false;
      this.FlashService.error('Something wrong!');
      $('.loaderBtn').hide();
      $('#makeCounterOfferModel').modal('toggle');
    });
  }

  getProjectUnitNo(value) {
    this.project_unit_no_id = value;
  }

  onMakeOfferClick(favouritesItem) {
    this.propertyName = "";
    this.currentItem = favouritesItem;
    this.offerPrice = (favouritesItem.wishlist_property.asking_price * 95) / 100;
    if (favouritesItem.wishlist_property.property_name != "" && favouritesItem.wishlist_property.property_name != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.property_name;
      } else {
        this.propertyName = favouritesItem.wishlist_property.property_name;
      }
    }
    if (favouritesItem.wishlist_property.building != "" && favouritesItem.wishlist_property.building != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.building;
      } else {
        this.propertyName = favouritesItem.wishlist_property.building;
      }
    }
    if (favouritesItem.wishlist_property.street != "" && favouritesItem.wishlist_property.street != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.street;
      } else {
        this.propertyName = favouritesItem.wishlist_property.street;
      }
    }
    if (favouritesItem.wishlist_property.address_line_1 != "" && favouritesItem.wishlist_property.address_line_1 != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.address_line_1;
      } else {
        this.propertyName = favouritesItem.wishlist_property.address_line_1;
      }
    }
    if (favouritesItem.wishlist_property.address_line_2 != "" && favouritesItem.wishlist_property.address_line_2 != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.address_line_2;
      } else {
        this.propertyName = favouritesItem.wishlist_property.address_line_2;
      }
    }
    if (favouritesItem.wishlist_property.locality_title != "" && favouritesItem.wishlist_property.locality_title != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.locality_title;
      } else {
        this.propertyName = favouritesItem.wishlist_property.locality_title;
      }
    }
    this.propertyPrice = '<i class="fa fa-rupee"></i>' + favouritesItem.wishlist_property.asking_price_in_word;
    $('#makeOfferModel').modal('toggle');
  }

  onOfferSubmit_old(favouritesItem) {
    this.submitted = true;
    this.minOfferPrice = (favouritesItem.wishlist_property.asking_price * 70) / 100;
    this.minOfferPriceText = this.amountToString(this.minOfferPrice);
    if (this.minOfferPrice > this.offerPrice) {
      this.offerformvalid = false;
      this.offerpriceminerror = true;
    } else {
      this.offerformvalid = true;
      this.offerpriceminerror = false;
    }
    if (this.offerForm.invalid || !this.offerformvalid) {
      return;
    }
    var form_data = new FormData();
    form_data.append('property_id', favouritesItem.property_id + "");
    form_data.append('opportunity_id', favouritesItem.opportunity_id + "");
    form_data.append('offer_amount', this.offerPrice + "");
    var offer_amount_title = this.amountToString(this.offerPrice);
    form_data.append('offer_amount_title', offer_amount_title + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_SET_OFFER, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Offer has been successfully sent.");
        this.getBuyerOfferList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.submitted = false;
      $('#makeOfferModel').modal('toggle');
    }, error => {
      this.submitted = false;
      this.FlashService.error("Something wrong!");
      $('#makeOfferModel').modal('toggle');
    });
  }

  onViewOffersClick(favouritesItem) {
    this.propertyName = "";
    this.currentItem = favouritesItem;
    if (favouritesItem.wishlist_property.property_name != "" && favouritesItem.wishlist_property.property_name != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.property_name;
      } else {
        this.propertyName = favouritesItem.wishlist_property.property_name;
      }
    }
    if (favouritesItem.wishlist_property.building != "" && favouritesItem.wishlist_property.building != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.building;
      } else {
        this.propertyName = favouritesItem.wishlist_property.building;
      }
    }
    if (favouritesItem.wishlist_property.street != "" && favouritesItem.wishlist_property.street != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.street;
      } else {
        this.propertyName = favouritesItem.wishlist_property.street;
      }
    }
    if (favouritesItem.wishlist_property.address_line_1 != "" && favouritesItem.wishlist_property.address_line_1 != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.address_line_1;
      } else {
        this.propertyName = favouritesItem.wishlist_property.address_line_1;
      }
    }
    if (favouritesItem.wishlist_property.address_line_2 != "" && favouritesItem.wishlist_property.address_line_2 != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.address_line_2;
      } else {
        this.propertyName = favouritesItem.wishlist_property.address_line_2;
      }
    }
    if (favouritesItem.wishlist_property.locality_title != "" && favouritesItem.wishlist_property.locality_title != null) {
      if (this.propertyName != "") {
        this.propertyName = this.propertyName + " " + favouritesItem.wishlist_property.locality_title;
      } else {
        this.propertyName = favouritesItem.wishlist_property.locality_title;
      }
    }
    this.propertyPrice = '<i class="fa fa-rupee"></i>' + favouritesItem.wishlist_property.asking_price_in_word;
    var form_data = new FormData();
    form_data.append('property_id', favouritesItem.property_id + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_PROPERTY_OFFER_HISTORY, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.viewOffersList = apiResult.data.list;
        this.ref.detectChanges();
        $('#viewOffersModel').modal('toggle');
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  amountToString(amount) {
    var amountText = "";
    if ((amount / 10000000) >= 1) {
      amountText = (amount / 10000000) + " Cr.";
    } else if ((amount / 100000) >= 1) {
      amountText = (amount / 100000) + " Lc.";
    } else if ((amount / 1000) >= 1) {
      amountText = (amount / 1000) + " Th.";
    }
    return amountText;
  }

  changeHeaderClass(): void {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Offers');
  }
}
