<!-- <div *ngIf="message && message?.type!=='hide'" [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-danger': message.type === 'error', 'alert-hide': message.type === 'hide' }">
  {{message.text}}
  <a class="close" (click)="removeMessage()">&times;</a>
</div> -->

<div [class]="message.type === 'success' ? 'c-alert-success' : message.type === 'error' ? 'c-alert-error': ''" *ngIf="message && message?.type!=='hide'">
  <span class="text">{{message.text}}</span>
  <span class="close" (click)="message.type='hide'">&times;</span>
</div>
<!-- <div class="alert-error" *ngIf="!showMessage && message">
  <span class="text">{{message}}</span>
  <span class="close" (click)="removeMessage()">&times;</span>
</div> -->