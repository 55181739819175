/* tslint:disable:quotemark */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { API_ENDPOINTS } from '../../global';
declare var $: any;

@Component({
  selector: 'app-learning-real-estate',
  templateUrl: './learning-real-estate.component.html',
  styleUrls: ['./learning-real-estate.component.scss']
})
export class LearningRealEstateComponent implements OnInit {

  home_icon: string = '';

  constructor( private route: ActivatedRoute,private title: Title) {
    this.home_icon = API_ENDPOINTS.HOME_ICON;
   }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    /*if(!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }*/
    this.route.params.subscribe(routeParams => {
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Learn Real Estate');
  }
}
