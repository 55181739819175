<div class="container-fluid" id="rentCalculator">
  <ng-container *ngIf="isFindingProperty == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <div class="container">
    <!-- Start Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li>
            <a href="/"> Home </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> Rent Calculator </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <div class="page-head">
          <h1>Rent Calculator</h1>
          <p>This calculator shows rentals that fit your budget. Savings, debt and other expenses could impact the
            amount you want to spend on rent each month. Input your net (after tax) income and the calculator will
            display rentals up to 40% of your estimated gross income. Property managers typically use gross income to
            qualify applicants, so the tool assumes your net income is taxed at 25%. Actual tax rates vary.</p>
        </div>
        <div class="rent-calculate_main" [formGroup]="rentForm">
          <div class="row d-flex">
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-label" for="net_income">Monthly net income</label>
                <div class="custom_input_prepend">
                  <i class="fa fa-rupee"></i>
                  <input type="number" id="net_income" [(ngModel)]="net_income" formControlName="net_income"
                    [ngClass]="{ 'is-invalid': g.net_income.errors }" class="form-control" placeholder="Net Income"
                    (keyup)="onRentCalPriceChange()" />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-label" for="m_debts">Monthly debts</label>
                <div class="custom_input_prepend">
                  <i class="fa fa-rupee"></i>
                  <input type="number" id="m_debts" [(ngModel)]="m_debts" formControlName="m_debts"
                    [ngClass]="{ 'is-invalid': g.m_debts.errors }" class="form-control" placeholder="Monthly debts"
                    (keyup)="onRentCalPriceChange()" />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-label" for="home_price">Rental location</label>
                <div class="ng-autocomplete">
                  <ng-autocomplete [data]="locationData" [searchKeyword]="locationKeyword"
                    (selected)='locationOnSelectEvent($event)' (inputChanged)='getLocationServerResponse($event)'
                    [itemTemplate]="itemTemplate" (inputCleared)="locationSearchCleared()"
                    [notFoundTemplate]="notFoundTemplate" [debounceTime]="600" [isLoading]="locationIsLoadingResult"
                    [minQueryLength]="2" placeHolder="Location">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a href="javascript:void(0)"><span [innerHTML]="item.placeName"></span></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    {{ locationErrorMsg }}
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex">
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-label" for="m_savings">Monthly Savings</label>
                <div class="custom_input_prepend">
                  <i class="fa fa-rupee"></i>
                  <input type="number" id="m_savings" [(ngModel)]="m_savings" formControlName="m_savings"
                    [ngClass]="{ 'is-invalid': g.m_savings.errors }" class="form-control" placeholder="Monthly Savings"
                    (keyup)="onRentCalPriceChange()" />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-label" for="m_expenses">Monthly Expenses</label>
                <div class="custom_input_prepend">
                  <i class="fa fa-rupee"></i>
                  <input type="number" id="m_expenses" [(ngModel)]="m_expenses" formControlName="m_expenses"
                    [ngClass]="{ 'is-invalid': g.m_expenses.errors }" class="form-control"
                    placeholder="Monthly Expenses" (keyup)="onRentCalPriceChange()" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rent-slider_main" *ngIf="net_income > 0">
          <div class="row d-flex">
            <div class="col-md-6">
              <h3 [innerHTML]="rentSliderTitle"></h3>
              <p [innerHTML]="rentSliderText"></p>
              <h4 [innerHTML]="rentSliderSpend"></h4>
            </div>
            <div class="col-md-6">
              <ng5-slider [(value)]="rentSliderValue" (valueChange)="onRentCalPriceChange()"
                [options]="rentSliderOptions"></ng5-slider>
            </div>
          </div>
        </div>
        <div class="property-results">
          <h4 id="p_counter" *ngIf="(matchingProperties || []).length > 0">{{ (matchingProperties || []).length }} matching rentals {{ locationSearch }}</h4>
          <h4 id="p_counter" *ngIf="(matchingProperties || []).length == 0">No matching rentals</h4>
          <div class="row ss_property_view" id="matching_property_view">
            <ng-container *ngFor="let item of matchingProperties" >
              <div class="d-flex single_property_view" (click)="openPropertyDetails(item.id)"
                *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                <div class="card">
                  <div class="grid_image_view" [style.background-image]="'url(\'' + item.image_preview + '\')'">
                    <div class="property_badge top_left" *ngIf="item.prop_transaction_type">
                      <span class="property_badge_sell">{{item.prop_transaction_type.title}}</span>
                    </div>
                    <div class="property_badge top_right favoriteIcon_{{item.id}}"
                      [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }" *ngIf="item.prop_transaction_type"
                      (click)="makeFavoriteOthers(item.id)">
                      <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: #FFFFFF;"></i>
                      <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                    </div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title"><i class="fa fa-rupee"></i>{{item.asking_price}}</h5>
                    <p class="card-text">
                      {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                    </p>
                    <p class="card-text">
                      <span class="pro_spec" *ngIf="item.total_washrooms"> <i class="fa fa-bath"></i>
                        {{item.total_washrooms}} Bath </span>
                      <span class="pro_spec" *ngIf="item.total_bedrooms"> <i class="fa fa-bed"></i>
                        {{item.total_bedrooms}} Bed </span>
                      <span class="pro_spec" *ngIf="item.saleable_area"> <i class="fa fa-check-square"></i>
                        {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="bottom_buttons">
            <span *ngIf="net_income > 0 && (matchingProperties || []).length > 0" class="btn view_all" (click)="viewallMatching()">See all
              matching rentals</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>