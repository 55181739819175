<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>

<div class="container acc-dashboard-outer" id="accoutnSettings">
  <ng-container *ngIf="isLoading == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="isLoading == false"> -->
    <ng-container *ngIf="dashboardType == 'Buyer'">
      <div class="row sidebar-mobile mobile-show">
    <div class="col-12 dashboard-sidebar-mobile">
      <ul>
        <li class="sidebarli active" (click)="activeBuyerSidebarRoute('buyerWishlist', $event)">FAVOURITES</li>
        <li class="sidebarli" (click)="activeBuyerSidebarRoute('buyerAppointments', $event)">MY APPOINTMENTS</li>
        <li class="sidebarli" (click)="activeBuyerSidebarRoute('buyerViewed', $event)">RECENTLY VIEWED</li>
        <li class="sidebarli" (click)="activeBuyerSidebarRoute('buyerOffer', $event)">OFFERS</li>
      </ul>
    </div>
  </div>
    </ng-container>
    <ng-container *ngIf="dashboardType == 'Seller'">
      <div class="row sidebar-mobile mobile-show">
        <div class="col-12 dashboard-sidebar-mobile">
          <ul>
            <li class="sidebarli active" (click)="activeSellerSidebarRoute('sellerProperty', $event)">MY PROPERTIES</li>
            <li class="sidebarli" (click)="activeSellerSidebarRoute('sellerAppointment', $event)">MY APPOINTMENTS</li>
            <li class="sidebarli" (click)="activeSellerSidebarRoute('sellerOffer', $event)">OFFERS</li>
          </ul>
        </div>
      </div>
    </ng-container>
    <!-- <div class="container"> -->
      <div class="row">
        <div class="col-md-12">
          <!-- <app-flash></app-flash> -->
          <div class="acc-dashboard">
            <ng-container *ngIf="dashboardType == 'Buyer'">
              <div class="row">
                <div class="col-md-3 col-12 dashboard-sidebar mobile-hide">
                  <ul>
                    <li class="sidebarli active" (click)="activeBuyerSidebarRoute('buyerWishlist', $event)">FAVOURITES</li>
                    <li class="sidebarli" id="buyerAppointments_sec" (click)="activeBuyerSidebarRoute('buyerAppointments', $event)">MY APPOINTMENTS</li>
                    <li class="sidebarli" (click)="activeBuyerSidebarRoute('buyerViewed', $event)">RECENTLY VIEWED</li>
                    <li class="sidebarli" (click)="activeBuyerSidebarRoute('buyerOffer', $event)">OFFERS</li>
                  </ul>
                </div>
                <div class="col-md-9">
                  <div id='buyerWishlistDiv' class="fade in show section-show">
                    <div class="left-fix-head">FAVOURITES</div>
                  <div  class="shortlistedProperties">
                    <ul class="nav nav-tabs">
                      <li class="nav_li active"><a data-toggle="tab" href="#resalewishlist" class="active" (click)="onChnageTabMode('resaleWishList')">Resale ({{ buyerResaleWishlist.length }})</a></li>
                      <li class="nav_li active"><a data-toggle="tab" href="#newwishlist" (click)="onChnageTabMode('newWishList')">New ({{ buyerNewWishlist.length }})</a></li>
                    </ul>
                    <div class="open-house">
                      <div class="tab-content">
                        <div id="resalewishlist" class="tab-pane fade in active show mt-3">
                          <ng-container *ngIf="isLoading == false">
                            <div class="empty-message" *ngIf="buyerResaleWishlist.length == 0">
                              <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                              <div class="empty-text">
                                <h5>You have no property in wishlist</h5>
                                <!-- <p>Add homes to your appointment to help you plan out your home search.</p> -->
                              </div>
                              <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                            </div>
                          </ng-container>
                          <div *ngIf="buyerResaleWishlist.length > 0">
                            <ng-container *ngFor="let wishlistItem of buyerResaleWishlist">
                              <div *ngIf="isLoading == false"  (click)="openInNewTab(wishlistItem.property_id,'property',wishlistItem?.prop_status?.title)" [ngClass]="(wishlistItem?.prop_status&& wishlistItem?.prop_status?.title == 'Sold') ? 'account-list-property remove_hand_cursor' : 'account-list-property'">
                                <div [style.background-image]="'url(\'' + wishlistItem.image + '\')'" class="p_image_view"></div>
                              
                                <div class="property_badge_ top_left_" *ngIf="wishlistItem?.prop_status&& wishlistItem?.prop_status?.title">
                                  <span [ngClass]="wishlistItem?.prop_status?.title==='Available' ? 'property_badge_sell_ available_tag' : wishlistItem?.prop_status?.title==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{wishlistItem?.prop_status?.title}}</span>
                                  <!-- <span class="property_badge_sell_ neg">Under Negotiation</span> -->
                                </div>

                                <div class="p_details">
                                  <div class="p_details_top">
                                    <div class="price_div">
                                      <span>{{ wishlistItem.display_name }}</span>
                                      <!--<i class="fa fa-trash"></i>-->
                                      <button (click)="onRescheduleAppointmentClick(0, wishlistItem.property_id,wishlistItem.assign_to_user_id,wishlistItem.franchisee_id,'property');$event.stopPropagation()" *ngIf="wishlistItem?.prop_status?.title!='Sold' && wishlistItem?.prop_status?.title!='Under offer'" class="btn make-offer-btn mobile-hide color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                                      <div class="recently-mob mobile-show">
                                        <button (click)="onRescheduleAppointmentClick(0, wishlistItem.property_id,wishlistItem.assign_to_user_id,wishlistItem.franchisee_id,'property');$event.stopPropagation()" *ngIf="wishlistItem?.prop_status?.title!='Sold' && wishlistItem?.prop_status?.title!='Under offer'" class="btn make-offer-btn color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                                      </div>
                                    </div>
                                    <p class="address"><i class="fa fa-rupee"></i>{{ wishlistItem.asking_price_in_word }}</p>
                                    
                                    <p class="card-text">
                                      <span class="pro_spec" *ngIf="wishlistItem.total_showers"> <i class="fa fa-bath"></i> {{(wishlistItem.total_showers ? wishlistItem.total_showers : 0)}} Bath </span>
                                      <span class="pro_spec" *ngIf="wishlistItem.total_bedrooms"> <i class="fa fa-bed"></i> {{(wishlistItem.total_bedrooms ? wishlistItem.total_bedrooms : 0)}} Bed </span>
                                      <span class="pro_spec" *ngIf="wishlistItem.area_size"> <i class="fa fa-check-square"></i> {{ wishlistItem.area_size || 'Sq.Ft.' }}  </span>
                                    </p>
                                  </div>
                                  <div class="p_details_bottom">
                                    <ul class="prop_more_details">
                                      <li *ngIf="wishlistItem.transaction_name">
                                        <h5>Type</h5>
                                        <p>{{ wishlistItem.transaction_name }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.rate_sqft">
                                        <h5>Price per SQ.FT</h5>
                                        <p><i class="fa fa-rupee"></i>{{ wishlistItem.rate_sqft }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.created">
                                        <h5>Year Built</h5>
                                        <p>{{ wishlistItem.created | date : "y" }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.property_ownership">
                                        <h5>Ownership</h5>
                                        <p>{{ wishlistItem.property_ownership }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.transfer_fees">
                                        <h5>HOA Dues</h5>
                                        <p><i class="fa fa-rupee"></i>{{ wishlistItem.transfer_fees }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.current_rent">
                                        <h5>Lot Size</h5>
                                        <p><i class="fa fa-rupee"></i>{{ wishlistItem.current_rent }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.furnished_type">
                                        <h5>Furnished</h5>
                                        <p>{{ wishlistItem.furnished_type }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.locality_title">
                                        <h5>Locality</h5>
                                        <p>{{ wishlistItem.locality_title }}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div id="newwishlist" class="tab-pane fade show mt-3">
                          <ng-container *ngIf="isLoading == false">
                            <div class="empty-message" *ngIf="buyerNewWishlist.length == 0">
                              <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                              <div class="empty-text">
                                <h5>You have no property in wishlist</h5>
                                <!-- <p>Add homes to your appointment to help you plan out your home search.</p> -->
                              </div>
                              <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                            </div>
                          </ng-container>
                          <div *ngIf="buyerNewWishlist.length > 0" class="shortlistedProperties new-buyer-fav-list">
                            <ng-container *ngFor="let wishlistItem of buyerNewWishlist">
                              <div (click)="navigateToProject(wishlistItem.project_id, wishlistItem.project_unit_id)" *ngIf="isLoading == false" class="account-list-property" >
                                <div [style.background-image]="'url(\'' + wishlistItem.image + '\')'" class="p_image_view"></div>
                                <div class="p_details">
                                  <div class="p_details_top">
                                    <div class="price_div">
                                      <span>{{ wishlistItem.display_name }}</span>
                                      <!--<i class="fa fa-trash"></i>-->
                                      <button (click)="onRescheduleAppointmentClick(wishlistItem.project_id, wishlistItem.project_unit_id,wishlistItem.assign_to_user_id,wishlistItem.franchisee_id,'project');$event.stopPropagation()"  class="btn make-offer-btn color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                                      <button (click)="onRescheduleAppointmentClick(wishlistItem.project_id, wishlistItem.project_unit_id,wishlistItem.assign_to_user_id,wishlistItem.franchisee_id,'project');$event.stopPropagation()"  class="btn make-offer-btn appointment_btn mobile-show recently-mob color_added"><i class="fa fa-calendar-check-o"></i> Book Appointment</button>
                                    </div>
                                    <p class="address" *ngIf="wishlistItem.show_price_on_website==1"><i class="fa fa-rupee"></i>{{ wishlistItem.asking_price_in_word }}</p>
                                    <p class="card-text">
                                      <span class="pro_spec" *ngIf="wishlistItem.total_showers"> <i class="fa fa-bath"></i> {{(wishlistItem.total_showers ? wishlistItem.total_showers : 0)}} Bath </span>
                                      <span class="pro_spec" *ngIf="wishlistItem.total_bedrooms"> <i class="fa fa-bed"></i> {{(wishlistItem.total_bedrooms ? wishlistItem.total_bedrooms : 0)}} Bed </span>
                                      <span class="pro_spec" *ngIf="wishlistItem.area_size"> <i class="fa fa-check-square"></i> {{ wishlistItem.area_size }} Sq.Ft. </span>
                                    </p>
                                  </div>
                                  <div class="p_details_bottom">
                                    <ul class="prop_more_details">
                                      <li *ngIf="wishlistItem.transaction_name">
                                        <h5>Type</h5>
                                        <p>{{ wishlistItem.transaction_name }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.rate_sqft">
                                        <h5>Price per SQ.FT</h5>
                                        <p><i class="fa fa-rupee"></i>{{ wishlistItem.rate_sqft }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.created">
                                        <h5>Year Built</h5>
                                        <p>{{ wishlistItem.created | date : "y" }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.property_ownership">
                                        <h5>Ownership</h5>
                                        <p>{{ wishlistItem.property_ownership }}</p>
                                      </li>
                                      <li *ngIf="wishlistItem.locality_title">
                                        <h5>Locality</h5>
                                        <p>{{ wishlistItem.locality_title }}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div id='buyerAppointmentsDiv' class="fade in show section-hide">
                    <div class="left-fix-head">My Appointments</div>
                  <div class="recentAppointmentsProperties" >
                    <ul class="nav nav-tabs">
                      <li class="nav_li active"><a data-toggle="tab" class="active" href="#resale" (click)="onChnageTabMode('resaleAppointmntList')">Resale ({{resaleAppointmntList.length || 0}})</a></li>
                      <li class="nav_li active"><a data-toggle="tab" href="#new" (click)="onChnageTabMode('newAppointmntList')">New ({{newAppointmntList.length || 0}})</a></li>
                    </ul>

                    <div class="open-house">
                      <div class="tab-content">
                        <div id="resale" class="tab-pane fade in active show mt-3">
                          <ng-container *ngIf="isLoading == false">
                            <div class="empty-message" *ngIf="resaleAppointmntList.length == 0">
                              <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                              <div class="empty-text">
                                <h5>You have no appointments</h5>
                                <p>Add homes to your appointment to help you plan out your home search.</p>
                              </div>
                              <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="let appointmentItem of resaleAppointmntList">
                            <div *ngIf="isLoading == false" (click)="openInNewTab((appointmentItem.property_detail.id || appointmentItem.related_source_id),'property', appointmentItem?.property_detail?.prop_status?.title)"  [ngClass]="appointmentItem?.property_detail?.prop_status?.title == 'Sold' ? 'account-list-property remove_hand_cursor' : 'account-list-property 111by'">
                              <div [style.background-image]="'url(\'' + appointmentItem.property_detail.image_preview  + '\')'" class="p_image_view"></div>
                              <div class="property_badge_ top_left_" *ngIf="appointmentItem.property_detail.prop_status">
                                <span [ngClass]="appointmentItem?.property_detail?.prop_status?.title==='Available' ? 'property_badge_sell_ available_tag' : appointmentItem?.property_detail?.prop_status?.title==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{appointmentItem?.property_detail?.prop_status?.title}}</span>
                                <!-- <span class="property_badge_sell_ neg">Under Negotiation</span> -->
                              </div>
                              <div class="p_details">
                                <div class="p_details_top">
                                  <div class="price_div">
                                    <span class="set_width">
                                      {{ appointmentItem.property_detail.property_name }} {{appointmentItem.property_detail.building}} {{appointmentItem.property_detail.street}} {{appointmentItem.property_detail.address_line_1}} {{appointmentItem.property_detail.address_line_2}} {{appointmentItem.property_detail.locality_title}} {{(appointmentItem.property_detail.City ? appointmentItem.property_detail.city.city : '')}}
                                     </span>
                                  <div class="mobile-hide">
                                    <div class="appointmentDetails">
                                      {{ appointmentItem.appo_date }} {{ appointmentItem.appo_time }}
                                      <span class="active_appo" *ngIf="appointmentItem.status == 1">Active</span>
                                      <span class="done_appo" *ngIf="appointmentItem.status == 3">Done</span>
                                      <span class="missed_appo" *ngIf="appointmentItem.status == 4">Missed</span>
                                      <span class="cancelled_appo" *ngIf="appointmentItem.status == 5">Canceled</span>
                                    </div>
                                    <span (click)="onCancelAppointmentClick(appointmentItem.id);$event.stopPropagation()" *ngIf="appointmentItem.status == 1 && appointmentItem?.property_detail?.prop_status?.title!='Sold' && appointmentItem?.property_detail?.prop_status?.title!='Under offer'" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel Appointment</span>
                                  </div>

                                  <div class="recently-mob mobile-show">
                                    <div class="appointmentDetails">
                                      {{ appointmentItem.appo_date }} {{ appointmentItem.appo_time }}
                                      <span class="active_appo" *ngIf="appointmentItem.status == 1">Active</span>
                                      <span class="done_appo" *ngIf="appointmentItem.status == 3">Done</span>
                                      <span class="missed_appo" *ngIf="appointmentItem.status == 4">Missed</span>
                                      <span class="cancelled_appo" *ngIf="appointmentItem.status == 5">Canceled</span>
                                    </div>
                                    <span (click)="onCancelAppointmentClick(appointmentItem.id);$event.stopPropagation()" *ngIf="appointmentItem.status == 1 && appointmentItem?.property_detail?.prop_status?.title!='Sold' && appointmentItem?.property_detail?.prop_status?.title!='Under offer'" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel Appointment</span>
                                  </div>
                                  </div>
                                  <p class="address">
                                    <i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.asking_price_in_word }}
                                  </p>
                                  <p class="card-text">
                                    <span class="pro_spec" *ngIf="appointmentItem.property_detail.total_showers"> <i class="fa fa-bath"></i> {{(appointmentItem.property_detail.total_showers ? appointmentItem.property_detail.total_showers : 0)}} Bath </span>
                                    <span class="pro_spec" *ngIf="appointmentItem.property_detail.total_bedrooms"> <i class="fa fa-bed"></i> {{(appointmentItem.property_detail.total_bedrooms ? appointmentItem.property_detail.total_bedrooms : 0)}} Bed </span>
                                    <span class="pro_spec" *ngIf="appointmentItem.property_detail.super_builtup"> <i class="fa fa-check-square"></i> {{ appointmentItem.property_detail.super_builtup }} Sq.Ft. </span>
                                  </p>
                                </div>
                                <div class="p_details_bottom">
                                  <ul class="prop_more_details">
                                    <li *ngIf="appointmentItem.property_detail.transaction_name">
                                      <h5>Type</h5>
                                      <p>{{ appointmentItem.property_detail.transaction_name }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.property_detail.rate_sqft">
                                      <h5>Price per SQ.FT</h5>
                                      <p><i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.rate_sqft }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.property_detail.created">
                                      <h5>Year Built</h5>
                                      <p>{{ appointmentItem.property_detail.created | date : "y" }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.property_detail.property_ownership">
                                      <h5>Ownership</h5>
                                      <p>{{ appointmentItem.property_detail.property_ownership }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.property_detail.transfer_fees">
                                      <h5>HOA Dues</h5>
                                      <p><i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.transfer_fees }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.property_detail.current_rent">
                                      <h5>Lot Size</h5>
                                      <p><i class="fa fa-rupee"></i>{{ appointmentItem.property_detail.current_rent }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.property_detail.furnished_type">
                                      <h5>Furnished</h5>
                                      <p>{{ appointmentItem.property_detail.furnished_type }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.property_detail.locality_title">
                                      <h5>Locality</h5>
                                      <p>{{ appointmentItem.property_detail.locality_title }}</p>
                                    </li>
                                    <li *ngIf="appointmentItem.type_of_appointment === 0">
                                      <h5>Appointment Type</h5>
                                      <p>Tour in Person</p>
                                    </li>
                                    <li *ngIf="appointmentItem.type_of_appointment === 1">
                                      <h5>Appointment Type</h5>
                                      <p>Tour via Video Chat</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>

                        <div id="new" class="tab-pane fade mt-3">
                          <ng-container *ngIf="isLoading == false">
                            <div class="empty-message" *ngIf="newAppointmntList.length == 0">
                              <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                              <div class="empty-text">
                                <h5>You have no appointments</h5>
                                <p>Add homes to your appointment to help you plan out your home search.</p>
                              </div>
                              <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                            </div>
                          </ng-container>
                          <ng-container *ngFor="let appointProjectItem of newAppointmntList">
                            <div *ngIf="isLoading == false" (click)="openInNewTab((appointProjectItem?.project_detail?.id || appointProjectItem.related_source_id) ,'project', appointProjectItem.project_detail.project_status)" [ngClass]="appointProjectItem?.project_detail?.project_status == 'Sold' ? 'account-list-property remove_hand_cursor' : 'account-list-property'">
                              <div [style.background-image]="'url(\'' + appointProjectItem.project_detail.image_preview + '\')'" class="p_image_view"></div>
                             
                              <div class="property_badge_ top_left_" *ngIf="appointProjectItem.project_detail.project_status">
                                <span [ngClass]="appointProjectItem?.project_detail?.project_status==='Available' ? 'property_badge_sell_ available_tag' : appointProjectItem?.project_detail?.project_status==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{appointProjectItem?.project_detail?.project_status}}</span>
                                <!-- <span class="property_badge_sell_ neg">Under Negotiation</span> -->
                              </div>
                              <!-- <div class="property_badge_ top_left_" >
                                <span class="property_badge_sell_ sold" >Sold Out</span>
                                <span class="property_badge_sell_ neg">Under Negotiation</span>
                              </div> -->
                            
                              <div class="p_details">
                                <div class="p_details_top">
                                  <div class="price_div price_div_proj set_width_proj">
                                    <span class="">
                                      {{ appointProjectItem.project_detail.project_name }} {{appointProjectItem.project_detail.building}} {{appointProjectItem.project_detail.street}} {{appointProjectItem.project_detail.address_line_1}} {{appointProjectItem.project_detail.address_line_2}} {{appointProjectItem.project_detail.locality_title}} {{(appointProjectItem.project_detail.City ? appointProjectItem.project_detail.city.city : '')}}</span>
                                    <span *ngIf="appointProjectItem.project_unit_type"> ({{ appointProjectItem.project_unit_type }})</span>
                                  <div class="mobile-hide">
                                    <div class="appointmentDetails">
                                      {{ appointProjectItem.appo_date }} {{ appointProjectItem.appo_time }}
                                      <span class="active_appo" *ngIf="appointProjectItem.status == 1">Active</span>
                                      <span class="done_appo" *ngIf="appointProjectItem.status == 3">Done</span>
                                      <span class="missed_appo" *ngIf="appointProjectItem.status == 4">Missed</span>
                                      <span class="cancelled_appo" *ngIf="appointProjectItem.status == 5">Cancelled</span>
                                    </div>
                                    <span (click)="onCancelProjectAppointmentClick(appointProjectItem.id);$event.stopPropagation()" *ngIf="appointProjectItem.status == 1" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel Appointment</span>
                                  </div>
                                  <div class="recently-mob mobile-show">
                                    <div class="appointmentDetails">
                                      {{ appointProjectItem.appo_date }} {{ appointProjectItem.appo_time }}
                                      <span class="active_appo" *ngIf="appointProjectItem.status == 1">Active</span>
                                      <span class="done_appo" *ngIf="appointProjectItem.status == 3">Done</span>
                                      <span class="missed_appo" *ngIf="appointProjectItem.status == 4">Missed</span>
                                      <span class="cancelled_appo" *ngIf="appointProjectItem.status == 5">Cancelled</span>
                                    </div>
                                    <span (click)="onCancelAppointmentClick(appointProjectItem.id);$event.stopPropagation()" *ngIf="appointProjectItem.status == 1" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel Appointment</span>
                                  </div>
                                  </div>
                                  <p class="address" *ngIf="appointProjectItem?.project_detail.show_price_on_website === 1">
                                    <i class="fa fa-rupee"></i>{{ appointProjectItem.project_detail.minMaxData.min_rate_title }} - {{ appointProjectItem.project_detail.minMaxData.max_rate_title }}
                                  </p>
                                  <p class="card-text">
                                    <span class="pro_spec" *ngIf="appointProjectItem.project_detail.total_showers"> <i class="fa fa-bath"></i> {{(appointProjectItem.project_detail.total_showers ? appointProjectItem.project_detail.total_showers : 0)}} Bath </span>
                                    <span class="pro_spec" *ngIf="appointProjectItem.project_detail.total_bedrooms"> <i class="fa fa-bed"></i> {{(appointProjectItem.project_detail.total_bedrooms ? appointProjectItem.project_detail.total_bedrooms : 0)}} Bed </span>
                                    <span class="pro_spec" *ngIf="appointProjectItem.project_detail.super_builtup"> <i class="fa fa-check-square"></i> {{ appointProjectItem.project_detail.super_builtup }} Sq.Ft. </span>
                                  </p>
                                </div>
                                <div class="p_details_bottom">
                                  <ul class="prop_more_details">
                                    <li *ngIf="appointProjectItem.project_detail.transaction_name">
                                      <h5>Type</h5>
                                      <p>{{ appointProjectItem.project_detail.transaction_name }}</p>
                                    </li>
                                    <li *ngIf="appointProjectItem.project_detail.rate_sqft">
                                      <h5>Price per SQ.FT</h5>
                                      <p><i class="fa fa-rupee"></i>{{ appointProjectItem.project_detail.rate_sqft }}</p>
                                    </li>
                                    <li *ngIf="appointProjectItem.project_detail.created">
                                      <h5>Year Built</h5>
                                      <p>{{ appointProjectItem.project_detail.created | date : "y" }}</p>
                                    </li>

                                    <li *ngIf="appointProjectItem.project_detail.furnished_type">
                                      <h5>Furnished</h5>
                                      <p>{{ appointProjectItem.project_detail.furnished_type }}</p>
                                    </li>
                                    <li *ngIf="appointProjectItem.project_detail.locality_title">
                                      <h5>Locality</h5>
                                      <p>{{ appointProjectItem.project_detail.locality_title }}</p>
                                    </li>
                                    <li *ngIf="appointProjectItem.type_of_appointment === 0">
                                      <h5>Appointment Type</h5>
                                      <p>Tour in Person</p>
                                    </li>
                                    <li *ngIf="appointProjectItem.type_of_appointment === 1">
                                      <h5>Appointment Type</h5>
                                      <p>Tour via Video Chat</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div id='buyerViewedDiv' class="fade in show section-hide">
                    <div class="left-fix-head">Recently Viewed</div>
                    <div class="recentlyViewedProperties" >
                    <ul class="nav nav-tabs">
                      <li class="nav_li active"><a data-toggle="tab" class="active" href="#resaleView" (click)="onChnageTabMode('resaleViewed')">Resale ({{ buyerRecentlyViewed.length }})</a></li>
                      <li class="nav_li active"><a data-toggle="tab" href="#newView" (click)="onChnageTabMode('newViewed')">New ({{ buyerNewRecentlyViewed.length }})</a></li>
                    </ul>
                    <div class="tab-content">
                      <div id="resaleView" class="tab-pane fade in active show mt-3">
                        <ng-container *ngIf="isLoading == false">
                          <div class="empty-message" *ngIf="buyerRecentlyViewed.length == 0">
                            <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                            <div class="empty-text">
                              <h5>You have no viewed property</h5>
                            </div>
                            <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                          </div>
                        </ng-container>
                        <div *ngIf="buyerRecentlyViewed.length > 0 && isLoading == false">
                          <ng-container *ngFor="let appointmentItem of buyerRecentlyViewed">
                            <app-recently-viewed-card [buyerRecentlyViewed]='appointmentItem' (rejectedclick)='onRejectedClick($event)'></app-recently-viewed-card>
                          </ng-container>
                        </div>
                      </div>
                      <div id="newView" class="tab-pane fade show mt-3">
                        <ng-container *ngIf="isLoading == false">
                          <div class="empty-message" *ngIf="buyerNewRecentlyViewed.length == 0">
                            <img src="{{ search_icon }}" class="home-icon" alt="Home" />
                            <div class="empty-text">
                              <h5>You have no viewed project</h5>
                            </div>
                            <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                          </div>
                        </ng-container>
                        <div *ngIf="buyerNewRecentlyViewed.length > 0 && isLoading == false">
                          <ng-container *ngFor="let appointmentItem of buyerNewRecentlyViewed">
                            <app-recently-viewed-card [buyerRecentlyViewed]='appointmentItem' (rejectedclick)='onRejectedClick($event)'></app-recently-viewed-card>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div id='buyerOfferDiv' class="fade in show section-hide">
                    <div class="left-fix-head">Offers</div>
                    <div class="buyerOfferProperties">
                      <ng-container *ngFor="let buyerOfferItem of buyerOfferList">
                        <div *ngIf="isLoading == false"  [ngClass]="buyerOfferItem?.offered_property?.prop_status?.title == 'Sold' ? 'account-list-property remove_hand_cursor' : 'account-list-property'">
                          <div class="p_image_view" (click)="openInNewTab(buyerOfferItem.property_id ? buyerOfferItem.property_id : buyerOfferItem.project_unit_id,buyerOfferItem.property_id ? 'property' : 'PU' , buyerOfferItem?.offered_property?.prop_status?.title)" [style.background-image]="'url(\'' + buyerOfferItem.offered_property.image_preview + '\')'"></div>
                          <div class="property_badge_ top_left_" *ngIf="buyerOfferItem?.offered_property?.prop_status">
                            <span [ngClass]="buyerOfferItem?.offered_property?.prop_status?.title==='Available' ? 'property_badge_sell_ available_tag' : buyerOfferItem?.offered_property?.prop_status?.title==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{buyerOfferItem?.offered_property?.prop_status?.title}}</span>
                          </div>
                          <div class="p_details">
                            <div class="p_details_top">
                              <div class="price_div">
                                <span *ngIf="buyerOfferItem.offered_property.display_name">{{ buyerOfferItem.offered_property.display_name }}</span>
                                <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                                  <label class="label-accept text-center mobile-hide">Accepted</label>
                                </ng-container>
                                <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                                  <label class="label-reject text-center mobile-hide">Rejected</label>
                                </ng-container>
                                <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                                  <label class="label-accept text-center mobile-hide">Sold</label>
                                </ng-container>
                                <button class="btn make-offer-btn mobile-hide" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0 && buyerOfferItem?.offered_property?.prop_status?.title!='Sold' && buyerOfferItem?.offered_property?.prop_status?.title!='Under offer'">Counter Offer</button>
                                <div class="recently-mob mobile-show">
                                  <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                                    <label class="label-accept text-center">Accepted</label>
                                  </ng-container>
                                  <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                                    <label class="label-reject text-center">Rejected</label>
                                  </ng-container>
                                  <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                                    <label class="label-accept text-center">Sold</label>
                                  </ng-container>
                                  <button class="btn make-offer-btn" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0 && buyerOfferItem?.offered_property?.prop_status?.title!='Sold' && buyerOfferItem?.offered_property?.prop_status?.title!='Under offer'">Counter Offer</button>
                                </div>
                              </div>
                              <p class="address" >
                                <i class="fa fa-rupee"></i>{{ buyerOfferItem.offered_property.asking_price_in_word }}  
                              </p>
                              <p class="card-text">
                                <span class="pro_spec" *ngIf="buyerOfferItem.offered_property.total_showers"> <i class="fa fa-bath"></i> {{(buyerOfferItem.offered_property.total_showers ? buyerOfferItem.offered_property.total_showers : 0)}} Bath </span>
                                <span class="pro_spec" *ngIf="buyerOfferItem.offered_property.total_bedrooms"> <i class="fa fa-bed"></i> {{(buyerOfferItem.offered_property.total_bedrooms ? buyerOfferItem.offered_property.total_bedrooms : 0)}} Bed </span>
                                <span class="pro_spec" *ngIf="buyerOfferItem.offered_property.super_builtup"> <i class="fa fa-check-square"></i> {{ buyerOfferItem.offered_property.super_builtup }} Sq.Ft. </span>
                              </p>
                            </div>
                            <div class="p_details_bottom"  (click)="openInNewTab(buyerOfferItem.property_id ? buyerOfferItem.property_id : buyerOfferItem.project_unit_id,buyerOfferItem.property_id ? 'property' : 'PU',buyerOfferItem?.offered_property?.prop_status?.title)">
                              <ul class="prop_more_details">
                                <li *ngIf="buyerOfferItem.offered_property.transaction_name">
                                  <h5>Type</h5>
                                  <p>{{ buyerOfferItem.offered_property.transaction_name }}</p>
                                </li>
                                <li *ngIf="buyerOfferItem.offered_property.rate_sqft">
                                  <h5>Price per SQ.FT</h5>
                                  <p><i class="fa fa-rupee"></i>{{ buyerOfferItem.offered_property.rate_sqft }}</p>
                                </li>
                                <li *ngIf="buyerOfferItem.offered_property.created">
                                  <h5>Year Built</h5>
                                  <p>{{ buyerOfferItem.offered_property.created | date : "y" }}</p>
                                </li>
                                <li *ngIf="buyerOfferItem.offered_property.property_ownership">
                                  <h5>Ownership</h5>
                                  <p>{{ buyerOfferItem.offered_property.property_ownership }}</p>
                                </li>
                                <li *ngIf="buyerOfferItem.offer_amount_title">
                                  <h5>Offered Amount</h5>
                                  <p><i class="fa fa-rupee"></i>{{ buyerOfferItem.offer_amount_title }}</p>
                                </li>
                              </ul>
                            </div>
                            <div class="p_details_history">
                              <a type="button" class="btn btn-info" data-toggle="collapse"[attr.data-target]="'#id' + buyerOfferItem.property_id">Offer History</a>
                              <div id='id{{buyerOfferItem.property_id }}' class="collapse">
                                <ul>
                                  <li *ngFor="let historyList of buyerOfferItem.offer_history">{{ historyList.user_type}} {{historyList.created | date:'medium'}} </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngFor="let buyerOfferItem of buyerOfferProjectList">
                        <div *ngIf="isLoading == false"  [ngClass]="buyerOfferItem?.offered_project?.project_status == 'Sold' ? 'account-list-property remove_hand_cursor' : 'account-list-property'">
                          <div class="p_image_view" (click)="openInNewTab(buyerOfferItem.project_unit_id,'PU',buyerOfferItem?.offered_project?.project_status)" [style.background-image]="'url(\'' + buyerOfferItem.offered_project.image_preview + '\')'"></div>
                          <div class="property_badge_ top_left_" >
                            <span [ngClass]="buyerOfferItem?.offered_project?.project_status==='Available' ? 'property_badge_sell_ available_tag' : buyerOfferItem?.offered_project?.project_status==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{buyerOfferItem?.offered_project?.project_status}}</span>
                          </div>
                          <div class="p_details">
                            <div class="p_details_top">
                              <div class="price_div">
                                <span *ngIf="buyerOfferItem.offered_project_unit.display_name">{{ buyerOfferItem.offered_project_unit.display_name }}</span>
                                <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                                  <label class="label-accept text-center mobile-hide">Accepted</label>
                                </ng-container>
                                <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                                  <label class="label-reject text-center mobile-hide">Rejected</label>
                                </ng-container>
                                <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                                  <label class="label-accept text-center mobile-hide">Sold</label>
                                </ng-container>
                                <button class="btn make-offer-btn mobile-hide" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0 && buyerOfferItem?.offered_project?.project_status!='Sold' && buyerOfferItem?.offered_project?.project_status!='Under offer'">Counter Offer</button>
                                <div class="recently-mob mobile-show">
                                  <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                                    <label class="label-accept text-center">Accepted</label>
                                  </ng-container>
                                  <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                                    <label class="label-reject text-center">Rejected</label>
                                  </ng-container>
                                  <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                                    <label class="label-accept text-center">Sold</label>
                                  </ng-container>
                                  <button class="btn make-offer-btn" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0 && buyerOfferItem?.offered_project?.project_status!='Sold' && buyerOfferItem?.offered_project?.project_status!='Under offer'">Counter Offer</button>
                                </div>
                              </div>
                              <p class="address" *ngIf="buyerOfferItem.offered_project_unit.show_price_on_website">
                                <!-- {{ buyerOfferItem.offered_project_unit.display_name }} -->
                                <i class="fa fa-rupee"></i>{{ buyerOfferItem.offered_project_unit.base_price_title }}
                              </p>
                              <p class="card-text">
                                <span class="pro_spec" *ngIf="buyerOfferItem.offered_project_unit.total_showers"> <i class="fa fa-bath"></i> {{(buyerOfferItem.offered_project_unit.total_showers ? buyerOfferItem.offered_project_unit.total_showers : 0)}} Bath </span>
                                <span class="pro_spec" *ngIf="buyerOfferItem.offered_project_unit.total_bedrooms"> <i class="fa fa-bed"></i> {{(buyerOfferItem.offered_project_unit.total_bedrooms ? buyerOfferItem.offered_project_unit.total_bedrooms : 0)}} Bed </span>
                                <span class="pro_spec" *ngIf="buyerOfferItem.offered_project_unit.super_builtup"> <i class="fa fa-check-square"></i> {{ buyerOfferItem.offered_project_unit.super_builtup }} Sq.Ft. </span>
                              </p>
                            </div>
                            <div class="p_details_bottom"  (click)="openInNewTab(buyerOfferItem.project_unit_id,'PU' , buyerOfferItem?.offered_project?.project_status)">
                              <ul class="prop_more_details">
                                <li *ngIf="buyerOfferItem.offered_project.transaction_name">
                                  <h5>Type</h5>
                                  <p>{{ buyerOfferItem.offered_project.transaction_name }}</p>
                                </li>
                                <li *ngIf="buyerOfferItem.offered_project.created">
                                  <h5>Year Built</h5>
                                  <p>{{ buyerOfferItem.offered_project.created | date : "y" }}</p>
                                </li>
                                <li *ngIf="buyerOfferItem.offer_amount_title">
                                  <h5>Offered Amount</h5>
                                  <p><i class="fa fa-rupee"></i>{{ buyerOfferItem.offer_amount_title }}</p>
                                </li>
                              </ul>
                            </div>
                            <div class="p_details_history">
                              <a type="button" class="btn btn-info" data-toggle="collapse"[attr.data-target]="'#id' + buyerOfferItem.id">Offer History</a>
                              <div id='id{{ buyerOfferItem.id }}' class="collapse">
                                <ul>
                                  <li *ngFor="let historyList of buyerOfferItem.offer_history">
                                    {{ historyList.user_type}} {{historyList.created | date:'medium'}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="dashboardType == 'Seller'">
              <div class="row">
                  <div class="col-md-3 dashboard-sidebar mobile-hide">
                    <ul>
                      <li class="sidebarli active" (click)="activeSellerSidebarRoute('sellerProperty', $event)">MY PROPERTIES</li>
                      <li class="sidebarli" (click)="activeSellerSidebarRoute('sellerAppointment', $event)">MY APPOINTMENTS</li>
                      <li class="sidebarli" (click)="activeSellerSidebarRoute('sellerOffer', $event)">OFFERS</li>
                    </ul>
                  </div>
                  <div class="col-md-9">
                    <div id='sellerPropertyDiv' class="fade in show section-show">
                      <div class="left-fix-head">My Properties</div>
                      <div class="sellerMyProperties">
                        <ng-container *ngFor="let propertyItem of sellerPropertyList">
                          <div *ngIf="isLoading == false" class="account-list-property">
                            <div class="p_image_view" (click)="openInNewTab(propertyItem.id,'property')" [style.background-image]="'url(\'' + propertyItem.image_preview + '\')'"></div>
                            <div class="p_details" style="margin-top: 15px;">
                              <div class="p_details_top">
                                <div class="price_div">
                                  <span> {{ propertyItem.property_name }} {{propertyItem.building}} {{propertyItem.street}} {{propertyItem.address_line_1}} {{propertyItem.address_line_2}} {{propertyItem.locality_title}} {{(propertyItem.City ? propertyItem.city.city : '')}}</span>
                                  <button class="btn view-offer-btn mobile-hide" *ngIf="propertyItem.property_status_id != 2" (click)="onMarkSold(propertyItem.id)">Mark As Sold</button>
                                  <button class="btn sold_btn red-btn mobile-hide" *ngIf="propertyItem.property_status_id == 2" >Sold</button>
                                  <button class="btn make-offer-btn mobile-hide" *ngIf="propertyItem.property_status_id != 2">Start Offer</button>

                                  <button class="btn mark-sold-btn mobile-show" *ngIf="propertyItem.property_status_id != 2" (click)="onMarkSold(propertyItem.id)">Mark As Sold</button>
                                  <button class="btn red-btn sold-btnM mobile-show" *ngIf="propertyItem.property_status_id == 2" >Sold</button>
                                  <button class="btn offer-btn mobile-show" *ngIf="propertyItem.property_status_id != 2">Start Offer</button>
                                </div>
                                <p class="address">
                                  <i class="fa fa-rupee"></i>{{ propertyItem.asking_price_title }}
                                </p>
                                <p class="card-text">
                                  <span class="pro_spec" *ngIf="propertyItem.total_showers"> <i class="fa fa-bath"></i> {{(propertyItem.total_showers ? propertyItem.total_showers : 0)}} Bath </span>
                                  <span class="pro_spec" *ngIf="propertyItem.total_bedrooms"> <i class="fa fa-bed"></i> {{(propertyItem.total_bedrooms ? propertyItem.total_bedrooms : 0)}} Bed </span>
                                  <span class="pro_spec" *ngIf="propertyItem.super_builtup"> <i class="fa fa-check-square"></i> {{ propertyItem.super_builtup }} Sq.Ft. </span>
                                </p>
                              </div>
                              <div class="p_details_bottom" (click)="openInNewTab(propertyItem.id,'property')">
                                <ul class="prop_more_details">
                                  <li *ngIf="propertyItem.transaction_name">
                                    <h5>Type</h5>
                                    <p>{{ propertyItem.transaction_name }}</p>
                                  </li>
                                  <li *ngIf="propertyItem.rate_sqft">
                                    <h5>Price</h5>
                                    <p><i class="fa fa-rupee"></i>{{ propertyItem.rate_sqft }}</p>
                                  </li>
                                  <li class="totalViews">
                                    <h5>Online Visit</h5>
                                    <p>{{ propertyItem.online_visit }}</p>
                                  </li>
                                  <li class="totalViews">
                                    <h5>Site Visit</h5>
                                    <p>{{ propertyItem.site_visit }}</p>
                                  </li>
                                  <li class="totalViews">
                                    <h5>Total Offers</h5>
                                    <p>{{ propertyItem.total_offers }}</p>
                                  </li>
                                  <li class="totalViews">
                                    <h5>Total Selected</h5>
                                    <p>{{ propertyItem.total_selected }}</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <div id='sellerAppointmentDiv' class="fade in show section-hide">
                      <div class="left-fix-head">My Appointments</div>
                      <div class="sellerOfferProperties appointment">
                        <ng-container *ngFor="let sellerAppointmentItem of sellerAppointmentList">
                          <div *ngIf="isLoading == false" class="account-list-property">
                            <div class="p_image_view"  (click)="openInNewTab(sellerAppointmentItem.property_detail.related_source_id,'property')"  [style.background-image]="'url(\'' + sellerAppointmentItem.property_detail.image_preview + '\')'"></div>
                            <div class="p_details">
                              <div class="p_details_top">
                                <div class="price_div">
                                  <span> {{ sellerAppointmentItem.property_detail.property_name }} {{sellerAppointmentItem.property_detail.building}} {{sellerAppointmentItem.property_detail.street}} {{sellerAppointmentItem.property_detail.address_line_1}} {{sellerAppointmentItem.property_detail.address_line_2}} {{sellerAppointmentItem.property_detail.locality_title}} {{(sellerAppointmentItem.property_detail.City ? sellerAppointmentItem.property_detail.city.city : '')}}</span>
                                  <div class="mobile-hide">
                                  <div class="appointmentDetails">
                                    {{ sellerAppointmentItem.appo_date }} {{ sellerAppointmentItem.appo_time }}
                                    <span class="active_appo" *ngIf="sellerAppointmentItem.property_detail.status == 1">Active</span>
                                    <span class="done_appo" *ngIf="sellerAppointmentItem.property_detail.status == 3">Done</span>
                                    <span class="missed_appo" *ngIf="sellerAppointmentItem.property_detail.status == 4">Missed</span>
                                    <span class="cancelled_appo" *ngIf="sellerAppointmentItem.property_detail.status == 5">Canceled</span>
                                  </div>
                                  <span *ngIf="sellerAppointmentItem.appo_from_type == 'Seller'" (click)="onCancelAppointmentClick(sellerAppointmentItem.property_detail.id)" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel Appointment</span>
                                  <button *ngIf="sellerAppointmentItem.appo_from_type == 'Buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0" class="btn accept_btn green-btn" (click)="onAcceptAppointmentClick(sellerAppointmentItem.id,'Seller',sellerAppointmentItem.appoint_attendee[0].id)" >Accept</button>
                                  <button (click)="onRescheduleAppointmentClick(0, sellerAppointmentItem.property_detail.id,sellerAppointmentItem.assign_to_user_id,sellerAppointmentItem.franchisee_id,'property')" *ngIf="sellerAppointmentItem.appo_from_type == 'Buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0"  class="btn reschedule_btn red-btn" >Reschedule</button>
                                </div>
                                <div class="recently-mob mobile-show">
                                  <div class="appointmentDetails">
                                    {{ sellerAppointmentItem.appo_date }} {{ sellerAppointmentItem.appo_time }}
                                    <span class="active_appo" *ngIf="sellerAppointmentItem.property_detail.status == 1">Active</span>
                                    <span class="done_appo" *ngIf="sellerAppointmentItem.property_detail.status == 3">Done</span>
                                    <span class="missed_appo" *ngIf="sellerAppointmentItem.property_detail.status == 4">Missed</span>
                                    <span class="cancelled_appo" *ngIf="sellerAppointmentItem.property_detail.status == 5">Canceled</span>
                                  </div>
                                  <span *ngIf="sellerAppointmentItem.appo_from_type == 'Seller'" (click)="onCancelAppointmentClick(sellerAppointmentItem.property_detail.id)" class="cancelAppointment"><i class="fa fa-trash"></i> Cancel Appointment</span>
                                  <button *ngIf="sellerAppointmentItem.appo_from_type == 'Buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0" class="btn accept_btn green-btn" (click)="onAcceptAppointmentClick(sellerAppointmentItem.id,'Seller',sellerAppointmentItem.appoint_attendee[0].id)" >Accept</button>
                                  <button (click)="onRescheduleAppointmentClick(0, sellerAppointmentItem.property_detail.id,sellerAppointmentItem.assign_to_user_id,sellerAppointmentItem.franchisee_id,'property')" *ngIf="sellerAppointmentItem.appo_from_type == 'Buyer' && sellerAppointmentItem.appoint_attendee[0].status == 0"  class="btn reschedule_btn red-btn" >Reschedule</button>
                                </div>
                                </div>
                                <p class="address">
                                  <i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.asking_price_in_word }}
                                </p>
                                <p class="card-text">
                                  <span class="pro_spec" *ngIf="sellerAppointmentItem.property_detail.total_showers"> <i class="fa fa-bath"></i> {{(sellerAppointmentItem.property_detail.total_showers ? sellerAppointmentItem.property_detail.total_showers : 0)}} Bath </span>
                                  <span class="pro_spec" *ngIf="sellerAppointmentItem.property_detail.total_bedrooms"> <i class="fa fa-bed"></i> {{(sellerAppointmentItem.property_detail.total_bedrooms ? sellerAppointmentItem.property_detail.total_bedrooms : 0)}} Bed </span>
                                  <span class="pro_spec" *ngIf="sellerAppointmentItem.property_detail.super_builtup"> <i class="fa fa-check-square"></i> {{ sellerAppointmentItem.property_detail.super_builtup }} Sq.Ft. </span>
                                </p>
                              </div>
                              <div class="p_details_bottom" (click)="openInNewTab(sellerAppointmentItem.property_detail.related_source_id,'property')">
                                <ul class="prop_more_details">
                                  <li *ngIf="sellerAppointmentItem.property_detail.transaction_name">
                                    <h5>Type</h5>
                                    <p>{{ sellerAppointmentItem.property_detail.transaction_name }}</p>
                                  </li>
                                  <li *ngIf="sellerAppointmentItem.property_detail.rate_sqft">
                                    <h5>Price per SQ.FT</h5>
                                    <p><i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.rate_sqft }}</p>
                                  </li>
                                  <li *ngIf="sellerAppointmentItem.property_detail.created">
                                    <h5>Year Built</h5>
                                    <p>{{ sellerAppointmentItem.property_detail.created | date : "y" }}</p>
                                  </li>
                                  <li *ngIf="sellerAppointmentItem.property_detail.property_ownership">
                                    <h5>Ownership</h5>
                                    <p>{{ sellerAppointmentItem.property_detail.property_ownership }}</p>
                                  </li>
                                  <li *ngIf="sellerAppointmentItem.property_detail.transfer_fees">
                                    <h5>HOA Dues</h5>
                                    <p><i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.transfer_fees }}</p>
                                  </li>
                                  <li *ngIf="sellerAppointmentItem.property_detail.current_rent">
                                    <h5>Lot Size</h5>
                                    <p><i class="fa fa-rupee"></i>{{ sellerAppointmentItem.property_detail.current_rent }}</p>
                                  </li>
                                  <li *ngIf="sellerAppointmentItem.property_detail.furnished_type">
                                    <h5>Furnished</h5>
                                    <p>{{ sellerAppointmentItem.property_detail.furnished_type }}</p>
                                  </li>
                                  <li *ngIf="sellerAppointmentItem.property_detail.locality_title">
                                    <h5>Locality</h5>
                                    <p>{{ sellerAppointmentItem.property_detail.locality_title }}</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <div id='sellerOffersDiv' class="fade in show section-hide">
                      <div class="left-fix-head">Offers</div>
                      <div class="sellerOfferProperties">
                        <ng-container *ngFor="let sellerOfferItem of sellerOfferList">
                          <div *ngIf="isLoading == false" class="account-list-property">
                            <div class="p_image_view" (click)="openInNewTab(sellerOfferItem.property_id,'property')" [style.background-image]="'url(\'' + sellerOfferItem.offered_property.image_preview + '\')'"></div>                          
                            <div class="p_details">
                              <div class="p_details_top">
                                <div class="price_div">
                                  <span>{{ sellerOfferItem.offered_property.property_name }} {{sellerOfferItem.offered_property.building}} {{sellerOfferItem.offered_property.street}} {{sellerOfferItem.offered_property.address_line_1}} {{sellerOfferItem.offered_property.address_line_2}} {{sellerOfferItem.offered_property.locality_title}} {{(sellerOfferItem.offered_property.City ? sellerOfferItem.offered_property.city.city : '')}}</span>
                                </div>
                                <p class="address">
                                  <i class="fa fa-rupee"></i>{{ sellerOfferItem.offered_property.asking_price_in_word }}
                                </p>
                                <p class="card-text">
                                  <span class="pro_spec" *ngIf="sellerOfferItem.offered_property.total_showers"> <i class="fa fa-bath"></i> {{(sellerOfferItem.offered_property.total_showers ? sellerOfferItem.offered_property.total_showers : 0)}} Bath </span>
                                  <span class="pro_spec" *ngIf="sellerOfferItem.offered_property.total_bedrooms"> <i class="fa fa-bed"></i> {{(sellerOfferItem.offered_property.total_bedrooms ? sellerOfferItem.offered_property.total_bedrooms : 0)}} Bed </span>
                                  <span class="pro_spec" *ngIf="sellerOfferItem.offered_property.super_builtup"> <i class="fa fa-check-square"></i> {{ sellerOfferItem.offered_property.super_builtup }} Sq.Ft. </span>
                                </p>
                              </div>
                              <div class="p_details_bottom" (click)="openInNewTab(sellerOfferItem.property_id,'property')">
                                <ul class="prop_more_details">
                                  <li *ngIf="sellerOfferItem.offered_property.transaction_name">
                                    <h5>Type</h5>
                                    <p>{{ sellerOfferItem.offered_property.transaction_name }}</p>
                                  </li>
                                  <li *ngIf="sellerOfferItem.offered_property.rate_sqft">
                                    <h5>Price per SQ.FT</h5>
                                    <p><i class="fa fa-rupee"></i>{{ sellerOfferItem.offered_property.rate_sqft }}</p>
                                  </li>
                                  <li *ngIf="sellerOfferItem.offered_property.created">
                                    <h5>Year Built</h5>
                                    <p>{{ sellerOfferItem.offered_property.created | date : "y" }}</p>
                                  </li>
                                  <li *ngIf="sellerOfferItem.offered_property.property_ownership">
                                    <h5>Ownership</h5>
                                    <p>{{ sellerOfferItem.offered_property.property_ownership }}</p>
                                  </li>
                                  <li *ngIf="sellerOfferItem.offer_history">
                                    <h5>Total Offers</h5>
                                    <p>{{ sellerOfferItem.offer_history.length }}</p>
                                    </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
            </ng-container>
          </div>
        </div>
      </div>
    <!-- </div> -->
  <!-- </ng-container> -->

  <div class="modal fade" id="makeCounterOfferModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <div class="row d-flex">
            <div class="col-md-12">
              <div class="property-offer-details">
                <p>Make Offer</p>
                <div class="row d-flex">
                  <div class="col-md-12" [formGroup]="offerForm">
                    <div class="form-group projectUnitNoIdDiv">
                      <select id="projectUnitNoIdSel" [(ngModel)]="project_unit_no_id" formControlName="projectUnitNoId" class="form-control" [ngClass]="{ 'is-invalid': (submitted && g.projectUnitNoId.errors || submitted && !offerformvalid) }" (change)="getProjectUnitNo($event.target.value)">
                        <!--<option value="">Select Unit No</option>-->
                        <option *ngFor="let option of projectUnitNoData" value="{{ option.id }}">
                          {{ option.text }}
                        </option>
                      </select>
                      <div *ngIf="submitted && g.projectUnitNoId.errors" class="invalid-feedback">
                        <div *ngIf="g.projectUnitNoId.errors.required">Please select Unit No</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input id="counterofferAmount" formControlName="offerprice" class="form-control" placeholder="Offer Price" [ngClass]="{ 'is-invalid': (submitted && g.offerprice.errors || submitted && !offerformvalid) }" />
                      <div *ngIf="submitted && g.offerprice.errors" class="invalid-feedback">
                        <div *ngIf="g.offerprice.errors.required">Offer Price is required</div>
                      </div>
                      <div *ngIf="submitted && !offerformvalid" class="invalid-feedback">
                        <div *ngIf="offerpriceminerror">Minimum <i class="fa fa-rupee"></i>{{ minOfferPriceText }} Offer Price required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button type="button" id="filterPropBtn" class="btn-view-all"
                            (click)="onOfferSubmit(currentItem)">Submit</button>

                    <button type="button" class="btn-view-all loaderBtn" disabled style="display: none;">
                      <span class="spinSpan spinner-border" role="status"></span> Saving
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

