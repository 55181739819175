import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OwlModule } from 'ngx-owl-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { Ng5SliderModule } from 'ng5-slider';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUsefulSwiperModule  } from 'ngx-useful-swiper';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AppComponent } from './app.component';

import { ApiService } from './_services/api.service';
import { FlashService } from './_services/FlashService.service';

import { FlashComponent } from './_common/flash/flash.component';
import { HeaderComponent } from './_common/header/header.component';
import { FooterComponent } from './_common/footer/footer.component';
import { AuthGuard } from './_common/_guard/auth.guard';
import { UnAuthGuard } from './_common/_guard/unauth.guard';
import { MyAccountHeaderComponent } from './_common/myaccount-header/myaccount-header.component';

import { HomeComponent } from './Components/home/home.component';
import { PropertiesComponent } from './Components/properties/properties.component';
import { PropertydetailsComponent } from './Components/property-details/propertydetails.component';
import { ProjectsComponent } from './Components/projects/projects.component';
import { ProjectdetailsComponent } from './Components/projects-details/projectsdetails.component';
import { ScheduleComponent } from './Components/schedule-visit/schedulevisit.component';
import { MyaccountComponent } from './Components/myaccount/myaccount.component';
import { AccountFavouritesComponent } from './Components/account-favourites/favourites.component';
import { AccountSavedSearchComponent } from './Components/account-savedsearch/savedsearch.component';
import { AccountOpenHouseComponent } from './Components/account-openhouse/openhouse.component';
import { AccountHomeTourComponent } from './Components/account-hometour/hometour.component';
import { AccountOffersComponent } from './Components/account-offers/offers.component';
import { AccountReviewsComponent } from './Components/account-reviews/reviews.component';
import { AccountSettingsComponent } from './Components/account-settings/settings.component';
import { RentcalCulatorComponent } from './Components/rent-calculator/rentcalculator.component';
import { AddLeadComponent } from './Components/addlead/addlead.component';
import { AccountDashboardComponent } from './Components/account-dashboard/dashboard.component';
import { CarnivalComponent } from './Components/carnival/carnival.component';
import { BuyFranchiseeComponent } from './Components/buy-franchisee/buy-franchisee.component';
import { LightboxModule } from 'ngx-lightbox';
import { CarnivalProjectDetailsComponent } from './Components/carnival-project-details/carnival-project-details.component';
import { TransactionTypeComponent } from './Components/transaction-type/transaction-type.component';
import { BuyPropertyComponent } from './Components/buy-property/buy-property.component';
import { RentLeasePropertyComponent } from './Components/rent-lease-property/rent-lease-property.component';
import { ProjectUnitsComponent } from './Components/project-units/project-units.component';
import { ProjectUnitDetailsComponent } from './Components/project-unit-details/project-unit-details.component';
import { SellerPropertyComponent } from './Components/seller-property/seller-property.component';
// import { SellerAppointmentsComponent } from './Components/seller-appointments/seller-appointments.component';
import { RecentlyViewedCardComponent } from './_common/recently-viewed-card/recently-viewed-card.component';
import { BuyerViewedComponent } from './Components/buyer-viewed/buyer-viewed.component';
import { PropertyCardComponent } from './_common/property-card/property-card.component';
import { FranchiseComponent } from './Components/franchise/franchise.component';
import { LearningRealEstateComponent } from './Components/learning-real-estate/learning-real-estate.component';
import { FreeClassesComponent } from './Components/free-classes/free-classes.component';
import { ListYourPropertyComponent } from './Components/list-your-property/list-your-property.component';
import { PartnersProgramComponent } from './Components/partners-program/partners-program.component';
import { SezInvestmentComponent } from './Components/sez-investment/sez-investment.component';
import { SinglePropertyCardComponent } from './_common/single-property-card/single-property-card.component';
import { JobsComponent } from './Components/jobs/jobs.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { PropertyListFooterComponent } from './_common/property-list-footer/property-list-footer.component';
import { PropertyListHeaderComponent } from './_common/property-list-header/property-list-header.component';
import { SuitablePropertyComponent } from './Components/suitable-property/suitable-property.component';
import { AffordableCalculatorComponent } from './Components/affordable-calculator/affordable-calculator.component';
import { DeveloperDetailsComponent } from './Components/developer-details/developer-details.component';
import { LuxuryComponent } from './Components/luxury/luxury.component';
import { LuxuryPropertiesComponent } from './Components/luxury-properties/luxury-properties.component';
import { LuxuryProjectsComponent } from './Components/luxury-projects/luxury-projects.component';
import { LuxuryProjectUnitsComponent } from './Components/luxury-project-units/luxury-project-units.component';
import { LuxuryProjectDetailsComponent } from './Components/luxury-project-details/luxury-project-details.component';
import { RouterModule } from '@angular/router';
import { ContactUsComponent } from './Components/shared/contact-us/contact-us.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { UsernameTrimPipe } from './_common/pipes/username-trim.pipe';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { ConfirmationDialogComponent } from './Components/shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { ActionsButtonsVisibilityPipe } from './_common/pipes/actions-buttons-visibility.pipe';

@NgModule({
  declarations: [
    AppComponent,
    FlashComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PropertiesComponent,
    PropertydetailsComponent,
    ProjectsComponent,
    ProjectdetailsComponent,
    ScheduleComponent,
    MyaccountComponent,
    AccountFavouritesComponent,
    AccountSavedSearchComponent,
    AccountOpenHouseComponent,
    AccountHomeTourComponent,
    AccountOffersComponent,
    AccountReviewsComponent,
    AccountSettingsComponent,
    MyAccountHeaderComponent,
    RentcalCulatorComponent,
    AddLeadComponent,
    AccountDashboardComponent,
    CarnivalComponent,
    BuyFranchiseeComponent,
    CarnivalProjectDetailsComponent,
    TransactionTypeComponent,
    BuyPropertyComponent,
    RentLeasePropertyComponent,
    ProjectUnitsComponent,
    ProjectUnitDetailsComponent,
    SellerPropertyComponent,
    // SellerAppointmentsComponent,
    RecentlyViewedCardComponent,
    BuyerViewedComponent,
    PropertyCardComponent,
    FranchiseComponent,
    LearningRealEstateComponent,
    FreeClassesComponent,
    ListYourPropertyComponent,
    PartnersProgramComponent,
    SezInvestmentComponent,
    SinglePropertyCardComponent,
    JobsComponent,
    ResetPasswordComponent,
    PropertyListHeaderComponent,
    PropertyListFooterComponent,
    AffordableCalculatorComponent,
    SuitablePropertyComponent,
    LuxuryComponent,
    DeveloperDetailsComponent,
    LuxuryProjectsComponent,
    LuxuryProjectUnitsComponent,
    LuxuryProjectDetailsComponent,
    LuxuryPropertiesComponent,
    ContactUsComponent,
    PageNotFoundComponent,
    UsernameTrimPipe,
    ConfirmationDialogComponent,
    ActionsButtonsVisibilityPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OwlModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    AutocompleteLibModule,
    Ng5SliderModule,
    NgOtpInputModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBegNdgOXPd2ICe4nhdPdcChJ2cb7uBNSE', libraries: ['places']}),
    LightboxModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxUsefulSwiperModule,
    NgxShimmerLoadingModule,
    TooltipModule.forRoot(),
    RouterModule,
    SocialLoginModule
  ],
  providers: [
    ApiService,
    FlashService,
    AuthGuard,
    SocialLoginModule,
    UnAuthGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.G_CLIENT_ID)
          },
        ],
        onError: (err) => { 
          // alert('Your browser has cookies disabled.');
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
