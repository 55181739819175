/* tslint:disable:prefer-const no-var-keyword max-line-length variable-name quotemark no-string-literal whitespace no-inferrable-types no-shadowed-variable */
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashService } from '../../_services/FlashService.service';
import { API_ENDPOINTS, WEB_URL } from '../../global';
import { ApiService } from './../../_services/api.service';
import { OrderByArray } from '../shared/sortarray';
declare var $: any;

@Component({
  selector: 'app-openhouse',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './openhouse.component.html',
  styleUrls: ['./openhouse.component.scss']
})
export class AccountOpenHouseComponent implements OnInit {

  search_icon: string = '';
  isLoading: boolean = true;
  propertyList: any = [];
  sellerAppointmentList: any = [];
  projectList: any = [];
  schedulevisitdata = {};
  dashboardType = 'buyer';
  constructor(private ApiService: ApiService, private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private FlashService: FlashService, private title: Title) {
    this.search_icon = API_ENDPOINTS.HOME_ICON;
    window.scroll(0,0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    // this.router.events.subscribe((val) => {debugger})
    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      } else {
        
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
          var form_data = new FormData();
          form_data.append('is_web_api', '1');
          this.ApiService.postRequest(API_ENDPOINTS.API_CHECK_USER_ACC_TYPE, form_data).subscribe((apiResult: any) => {
            if (apiResult.success) {
              this.dashboardType = apiResult.data.contact_type;
            }
          });
        }
      }
      this.getAppointmentList();
      this.getProjectAppointmentList();
      this.getSellerAppointmentList();

      setTimeout(() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  ngOnChanges(simple: any) {
  }

  openInNewTab(data, id, type) {
    if (data?.property_detail?.prop_status.title == 'Sold' || data?.project_status == 'Sold') {
      return
    }

    const routedTo = type == 'property' ? '/property-details/' : '/project-details/'
    window.open(WEB_URL + routedTo + id, '_blank')
  }

  getAppointmentList() {
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_APPOINTMENT_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.propertyList = OrderByArray((apiResult.data || []), 'appo_date', 'desc', true, true);
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getSellerAppointmentList() {
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_APPOINTMENT_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.sellerAppointmentList = apiResult.data;
        // this.ref.detectChanges();
      } else {
        this.isLoading = false;
        // this.ref.detectChanges();
      }
    });
  }

  onAcceptAppointmentClick(id, from) {
    const form_data = new FormData();
    form_data.append('appointment_id', id + "");
    form_data.append('status', '1');
    form_data.append('from', from);
    this.ApiService.postRequest(API_ENDPOINTS.API_ACCEPT_APPOINTMENT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Appointment has been successfully accepted.");
        this.getSellerAppointmentList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  onRescheduleAppointmentClick(id, assign_to_user_id, franchisee_id) {
    this.schedulevisitdata['related_source_id'] = id;
    this.schedulevisitdata['assign_to_user_id'] = assign_to_user_id;
    this.schedulevisitdata['franchisee_id'] = franchisee_id;
    this.schedulevisitdata['temp_source'] = 'property';
    this.schedulevisitdata['source'] = 'property';
    this.router.navigate(['/schedulevisit'], { queryParams: this.schedulevisitdata });
  }

  getProjectAppointmentList() {
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_APPOINTMENT_PROJECTS, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.isLoading = false;
        this.projectList = OrderByArray((apiResult.data || []), 'appo_date', 'desc', true, true);
        this.ref.detectChanges();
      } else {
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  onCancelAppointmentClick(id) {
    const form_data = new FormData();
    form_data.append('appointment_id', id + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_CANCEL_APPOINTMENT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Appointment has been successfully cancelled.");
        this.getAppointmentList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  onCancelProjectAppointment(id) {
    const form_data = new FormData();
    form_data.append('appointment_id', id);
    this.ApiService.postRequest(API_ENDPOINTS.API_CANCEL_PROJECT_APPOINTMENT, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success("Appointment has been successfully cancelled.");
        this.getProjectAppointmentList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
    }, error => {
      this.FlashService.error("Something wrong!");
    });
  }

  changeHeaderClass(): void {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Appointment');
  }
}
