import { environment } from 'src/environments/environment';

export const API_BASE_URL: string = environment.API_BASE_URL;
export const WEB_URL: string = environment.WEB_URL;

export const API_ENDPOINTS = {
  API_REGISTER_URL:  API_BASE_URL + '/users/api/signup',
  API_JOB_APPLICATION:  API_BASE_URL + '/master/submitJobApplication',
  API_FORGOT_PASSWORD:  API_BASE_URL + '/master/websiteUserForgotPassword',
  API_FORGOT_VALIDATE_USER:  API_BASE_URL + '/master/websiteForgotPassUserValid',
  API_WEB_USER_RESET_PASSWORD:  API_BASE_URL + '/master/webUserResetPassword',
  API_LOGIN_URL:  API_BASE_URL + '/users/api/login',
  API_SHARE_URL:  API_BASE_URL + '/master/shareEmailLink',
  API_VERIFY_USER_URL:  API_BASE_URL + '/master/verifyUser',

  // ** Start Search Areas API URLs **/
  API_GET_STATES:  API_BASE_URL + '/master/getStates',
  API_GET_CITIES:  API_BASE_URL + '/master/getCity',
  API_GET_PROPERTY_CITIES:  API_BASE_URL + '/master/getCityProppertyWise',
  API_GET_LOCALITY:  API_BASE_URL + '/master/getLocality',
  API_CHECK_USER_ACC_TYPE:  API_BASE_URL + '/master/checkUserContactType',
  API_GET_PROPERTY_AREAWISE:  API_BASE_URL + '/master/getPropertyAreaWise',
  // ** End Search Areas API URLs **/

  // ** Start Properties API URLs **/
  API_GET_SUITABLE_FOR:  API_BASE_URL + '/master/getSuitbaleFor/3?is_mobile_api=1',
  API_GET_SUITABLE_FOR_RESD:  API_BASE_URL + '/master/getSuitbaleFor/1?is_mobile_api=1',
  API_GET_SUITABLE_FOR_COMM:  API_BASE_URL + '/master/getSuitbaleFor/3?is_mobile_api=1',
  API_GET_AMENITIES:  API_BASE_URL + '/master/getAmenities?is_web_api=1',
  API_GET_PROPERTY_FACING:  API_BASE_URL + '/master/getFacing?is_web_api=1',
  API_GET_PROPERTY_AGE:  API_BASE_URL + '/master/getPropertyAge?is_web_api=1',
  API_GET_PROPERTY_TYPE:  API_BASE_URL + '/master/getPropertyType?is_web_api=1',
  API_GET_PROJECT_TYPE:  API_BASE_URL + '/master/getProjectType?is_web_api=1',
  API_GET_PROPERTY_FACTS:  API_BASE_URL + '/master/getUnitsOfArea?is_web_api=1',
  API_GET_PROPERTY_CATEGORY:  API_BASE_URL + '/master/getPropertyCategory?is_mobile_api=1',
  API_GET_PROPERTY_DIMENSIONS:  API_BASE_URL + '/master/getPropertyDimension?is_mobile_api=1',
  API_GET_PROJECT_DIMENSIONS:  API_BASE_URL + '/master/getProjectDimension?is_mobile_api=1',
  API_GET_PROPERTY_STATUS:  API_BASE_URL + '/master/getPropertyStatus?is_web_api=1',
  API_GET_LAND_CATEGORY:  API_BASE_URL + '/master/getLandCategory?is_web_api=1',
  API_GET_LAND_FEATURES:  API_BASE_URL + '/master/getLandFeatures?is_web_api=1',
  API_FIND_PROPERTIES:  API_BASE_URL + '/sales/property/api/findProperty',
  API_PROPERTY_DETAIL:  API_BASE_URL + '/sales/property/api/getDetails',
  API_PROPERTY_APPOINTMENT:  API_BASE_URL + '/sales/property/api/addAppointment',
  API_PROPERTY_FAVORITE:  API_BASE_URL + '/sales/property/api/favoriteProperty',
  API_SAVE_SEARCH:  API_BASE_URL + '/users/api/saveSearch',
  API_UPDATE_SAVE_SEARCH:  API_BASE_URL + '/users/api/updateSavedSearch',
  API_GET_SIMILAR_PROP:  API_BASE_URL + '/sales/property/api/findSimilar',
  API_GET_SUGGESTED_PROP:  API_BASE_URL + '/sales/property/api/findSuggested',
  API_FIND_CARNIVAL_PROPERTIES:  API_BASE_URL + '/master/findCarnivalProperty',
  API_GET_NEAR_BY_PROPERTIES:  API_BASE_URL + '/sales/property/api/getSuggestedProperty',
  API_PROPERTY_QUESTION:  API_BASE_URL + '/sales/property/api/askQuestion',
  API_FIND_TOP_PROPERTIES:  API_BASE_URL + '/sales/property/api/findTopProperties',
  // ** End Properties API URLs **/

  // ** Start Projects API URLs **/
  API_FIND_PROJECTS:  API_BASE_URL + '/sales/project/api/find',
  API_PROJECT_DETAIL:  API_BASE_URL + '/sales/project/api/getDetails',
  API_PROJECT_APPOINTMENT:  API_BASE_URL + '/sales/project/api/addAppointment',
  API_FIND_PROJECT_UNITS:  API_BASE_URL + '/master/findProjectUnits',
  API_FIND_CARNIVAL_PROJECTS:  API_BASE_URL + '/master/findCarnivalProject',
  API_FIND_CARNIVAL_PROJECT_DETAIL:  API_BASE_URL + '/master/getCarnivalProjectDetail',
  API_FIND_PROJECT_UNIT_DETAIL:  API_BASE_URL + '/master/getProjectUnitDetail',
  API_PROJECT_FAVORITE:  API_BASE_URL + '/sales/project/api/favorite',
  API_PROJECT_QUESTION:  API_BASE_URL + '/sales/project/api/askQuestion',
  API_SIMILAR_PROJECTS:  API_BASE_URL + '/sales/project/api/findSimilarProjects',
  API_FIND_TOP_PROJECTS:  API_BASE_URL + '/sales/project/api/findTopProjects',
  API_DEVELOPER_DETAIL:  API_BASE_URL + '/sales/project/api/getDeveloperDetails',
  // ** End Projects API URLs **/

  // ** Start Account API URLs **/
  API_GET_PROFILE:  API_BASE_URL + '/users/api/profile',
  API_UPDATE_PROFILE:  API_BASE_URL + '/users/api/updateProfile',
  API_UPDATE_PASSWORD:  API_BASE_URL + '/users/api/changePassword',
  API_GET_SAVE_SEARCH:  API_BASE_URL + '/users/api/listSavedSearch',
  API_DELETE_SAVE_SEARCH:  API_BASE_URL + '/users/api/deleteSavedSearch',
  API_GET_EMAIL_PREF:  API_BASE_URL + '/users/api/getEmailSetting',
  API_SET_EMAIL_PREF:  API_BASE_URL + '/users/api/setEmailSetting',
  API_VERIFY_MOBILE:  API_BASE_URL + '/users/api/verifyMobile',
  API_RESEND_OTP:  API_BASE_URL + '/users/api/resendOTP',
  // ** End Account API URLs **/

  // ** Start Schedule Visit API URLs **/
  API_GET_BOOKED_SLOTS:  API_BASE_URL + '/sales/property/api/getBookedTimeSlot',
  API_GET_BOOKED_SLOTS_DATES:  API_BASE_URL + '/master/getBookedDateSlot',
  // ** End Schedule Visit API URLs **/

  // ** Start Lead API URLs **/
  API_ADD_LEAD:  API_BASE_URL + '/sales/lead/lead/add',
  API_ADD_BUSINESS_LEAD:  API_BASE_URL + '/master/addBusinessLead',
  API_ADD_PROPERTY_LEAD:  API_BASE_URL + '/master/addPropertyProjectLead',
  API_ADD_PROJECT_LEAD:  API_BASE_URL + '/master/addPropertyProjectLead',
  // ** End Lead API URLs **/

  // ** Start Appointment API URLs **/
  API_GET_APPOINTMENT_PROPERTIES:  API_BASE_URL + '/sales/property/api/listAppointment',
  API_GET_APPOINTMENT_PROJECTS:  API_BASE_URL + '/master/listProjectAppointments',
  API_CANCEL_APPOINTMENT:  API_BASE_URL + '/sales/property/api/cancelAppointment',
  API_ACCEPT_APPOINTMENT:  API_BASE_URL + '/master/acceptRejectAppointment',
  API_MARKED_PROPERTY:  API_BASE_URL + '/master/markPropertySold',
  API_CANCEL_PROJECT_APPOINTMENT:  API_BASE_URL + '/master/cancelProjectAppointment',
  API_GET_APPOINTMENT_SLOT:  API_BASE_URL + '/master/checkAppoAvailableSlot',
  // ** End Appointment API URLs **/

  // ** Start Appointment API URLs **/
  API_VIEWED_REJECTED:  API_BASE_URL + '/master/rejectRecentViewed',
  // ** End Appointment API URLs **/

  // ** Start OFFERS API URLs **/
  API_GET_OFFERS_PROPERTIES:  API_BASE_URL + '/sales/property/api/getOfferedProperty',
  // API_SET_PROPERTY_OFFER:  API_BASE_URL + '/sales/property/api/setPropertyOffer',
  API_GET_PROPERTY_OFFER_HISTORY:  API_BASE_URL + '/sales/property/api/getPropertyOfferHistory',
  API_SET_OFFER:  API_BASE_URL + '/sales/opportunity/opportunity/setPropertyOffer',
  API_GET_PROJECT_UNIT_NO:  API_BASE_URL + '/master/getProjectUnitNoData',
  // ** End OFFERS API URLs **/

  // ** SELLER PROPERTY **/
  API_GET_SELLER_PROPERTIES:  API_BASE_URL + '/master/getSellerProperty',

  // ** BUYER PROPERTY **/
  API_GET_BUYER_WISHLIST_PROPERTIES:  API_BASE_URL + '/master/getWishListProperty',
  API_GET_BUYER_WISHLIST_PROJECT:  API_BASE_URL + '/master/getWishListProject',
  API_SET_BUYER_PROPERTY_OFFER:  API_BASE_URL + '/master/getBuyerOfferedProperty',
  API_GET_BUYER_RECENTLY_VIEWED_PROPERTIES:  API_BASE_URL + '/master/getRecentViewedProperty',
  API_GET_BUYER_RECENTLY_VIEWED_PROJECT:  API_BASE_URL + '/master/getRecentViewedProject',


  // ** ICONS **/
  HOME_ICON:  'assets/img/web-image/home_icon.png',
  SEARCH_ICON:  'assets/img/web-image/search_icon.png',
  OFFER_ICON:  'assets/img/web-image/offer_icon.png',
  REVIEW_ICON:  'assets/img/web-image/review_icon.png',
  // ** ICONS **/

  SCHEDULE_VISIT_TEXT:  'Feel free to schedule the visit, its free, with no obligation and cancel any time.',
  HEAD_TITLE:  'Empire Realtors » India Real Estate Property Site - Buy Sell Rent Properties.',
}
