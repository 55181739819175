/* tslint:disable:quotemark prefer-const no-var-keyword whitespace indent variable-name no-string-literal no-inferrable-types no-shadowed-variable max-line-length triple-equals */
import { MouseEvent } from '@agm/core';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS } from 'src/app/global';

declare var $: any;
declare const require: any;
declare const google: any;

@Component({
  selector: 'app-rentcalculator',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './addlead.component.html',
  styleUrls: ['./addlead.component.scss']
})

export class AddLeadComponent implements OnInit {

  submitted = false;
  leadForm: FormGroup;
  propAddressForm: FormGroup;

  first_name: string = '';
  last_name: string = '';
  email: string = '';
  phone: string = '';
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  address_line1: string = '';
  address_line2: string = '';
  state_options = [];
  state_eloc: string = 'PASCLQ';
  city_options = [];
  city_eloc: string = '7A8Q6Y';
  area_eloc: string = '';
  area_title: string = '';
  area_lat: string = '';
  area_lng: string = '';
  localitySearch;
  trans_type_options = [];
  trans_type: string = '4';
  prop_type_options = [];
  prop_type: string = '1';

  isLoggedIn;
  isAddingLead: boolean = false;
  isLeadAdded: boolean = false;

  zoom: number = 13;
  lat: number = 22.29385297411398;
  lng: number = 73.16903960950928;
  propAddressLat = 22.29385297411398;
  propAddressLng: number = 73.16903960950928;
  markers: marker[] = [];
  icon = {
    url: 'assets/img/er_marker.png',
    scaledSize: {
      width: 30,
      height: 49
    }
  };
  propMap: any;
  total_props_s: string = '0 Property';
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  tranTypeSell = false;
  tranTypeRent = false;
  propTypeResidential = false; // 1
  propTypeCommercial = false; // 3
  propTypeLand = false; // 4
  propTypeIndustrial = false; // 6
  markerPropAddressLat = 0;
  markerPropAddressLng = 0;

  constructor(private ref: ChangeDetectorRef, private FlashService: FlashService, private router: Router,  private route: ActivatedRoute, private ApiService: ApiService, private title: Title,  private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    this.isAddingLead = true;
    var componentObj = this;
    this.route.params.subscribe(routeParams => {
      if(this.route.snapshot.queryParams.locality !== undefined) {
        this.area_title = this.route.snapshot.queryParams.locality;
      }
      if(this.route.snapshot.queryParams.state_eloc !== undefined) {
        this.state_eloc = this.route.snapshot.queryParams.state_eloc;
      }
      if(this.route.snapshot.queryParams.lat !== undefined) {
        this.area_lat = this.route.snapshot.queryParams.lat;
      }
      if(this.route.snapshot.queryParams.lng !== undefined) {
        this.area_lng = this.route.snapshot.queryParams.lng;
      }
      if(this.route.snapshot.queryParams.leadadded !== undefined) {
        this.isLeadAdded = true;
      }
      this.getStates();
      this.getCities();
      this.trans_type_options = [
        {id: "3", text: 'Rent/Lease'},
        {id: "4", text: 'Sell'}
      ];
      this.prop_type_options = [
        {id: "1", text: 'Residential'},
        {id: "3", text: 'Commercial'},
        {id: "4", text: 'Land'},
        {id: "6", text: 'Industrial'}
      ];
      this.propAddressForm = this.formBuilder.group({
        addressline1: ['', [Validators.required]],
        addressline2: ['', [Validators.required]],
        prolocality: ['', [Validators.required]],
        profilestate: ['', [Validators.required]],
        profilecity: ['', [Validators.required]]
      });
      this.leadForm = this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        profileEmail: ['', [Validators.required, Validators.email]],
        mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
        transtype: ['', []],
        proptype: ['', []],
      });
      this.isLoggedIn = this.ApiService.loginCheck();
      if(this.isLoggedIn) {
        var userInfo = JSON.parse(localStorage.getItem('currentUser'));
        this.first_name = userInfo.first_name;
        this.last_name = userInfo.last_name;
        this.email = userInfo.email;
        this.phone = userInfo.contact_no;
      }
      setTimeout (() => {
        this.changeHeaderClass();
        this.isAddingLead = false;
        /*this.localitySearch = $('#locality_search');
        this.localitySearch.typeahead(null, {
          name: 'localitySearch',
          displayKey: 'placeName',
          source: (search, sync, async) => {
            if (search.length >= 2) {
              return $.ajax({
                type: 'GET',
                dataType: 'json',
                url: API_BASE_URL+'/master/getLocality',
                data: {is_web_api:1, search: search},
                success: (response) => {
                  response = response.data;
                  return async(response);
                }
              });
            }
          }
        });
        var componentObj = this;
        this.localitySearch.on('typeahead:selected', (e, datalocitem) => {
          componentObj.area_eloc = datalocitem.eLoc;
          componentObj.area_title = datalocitem.placeName;
          componentObj.area_lat = datalocitem.latitude;
          componentObj.area_lng = datalocitem.longitude;
          componentObj.changeLatLng(componentObj.area_lat, componentObj.area_lng);
        });*/
      }, 200);
    });
    // this.getPropertyList();
  }

  mapReady(map) {
    this.propMap = map;
    if(this.area_lat != "" && this.area_lng != "") {
      this.changeLatLng(this.area_lat, this.area_lng);
    }
    // this.getPropertyList();
  }

  getPropertyList() {
    var form_data = new FormData();
    form_data.append('locality_elocs', this.area_title);
    form_data.append('transaction_type_id', this.trans_type);
    form_data.append('from', 'web_sell_page');
    form_data.append('list_for', 'map_icon');
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      let componentObj = this;
      if(apiResult.success){
        /*if(apiResult.data.paginate.Property.count > 1) {
          this.total_props_s = apiResult.data.paginate.Property.count + " Properties";
        } else {
          this.total_props_s = apiResult.data.paginate.Property.count + " Property";
        }*/
        $.each(apiResult.data.list, ( index, value ) => {
          if (parseFloat(value.locality_lat) > 0 && parseFloat(value.locality_lng) > 0) {
            componentObj.lat = value.locality_lat;
            componentObj.lng = value.locality_lng;
            componentObj.changeLatLng(componentObj.lat, componentObj.lng);
            const markerN = {
              pid: value.id,
        		  lat: value.locality_lat,
        		  lng: value.locality_lng,
              symbol: {
                url: 'assets/img/er_marker.png',
                scaledSize: {
                  width: 30,
                  height: 49
                }
              },
        		  draggable: false,
        	  };
            componentObj.addMarker(markerN);
          }
        });
      } else {
        this.total_props_s = "0 Property";
      }
    });
  }

  changeLatLng(latN, lngN) {
    this.lat = latN;
    this.lng = lngN;
    var position = new google.maps.LatLng(this.lat, this.lng);
    this.propMap.panTo(position);
  }

  addMarker(markerN) {
    this.markers.push(markerN);
  }

  onMarkerMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  clickedMarker(pid: string) {
    // this.router.navigate(['property-details', pid]);
  }

  get g() {
    return this.leadForm.controls;
  }

  get k() {
    return this.propAddressForm.controls;
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Sell');
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  getStates() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_STATES, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.state_options = apiResult.data;
      }
    });
  }

  getCities() {
    let form_data = new FormData();
    form_data.append('state_eloc', this.state_eloc);
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_CITIES, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        if (apiResult.data.length > 0) {
          if (apiResult.data.title === 'Vadodara') {
            this.city_eloc = apiResult.data[0]['id'];
          } /*else {
            this.city_eloc = apiResult.data[0]['id'];
          }*/
        }
        this.city_options = apiResult.data;
        const cityLoc = this.city_options.filter(eloc => eloc.id == this.city_eloc);
        this.changeLatLng(Number(cityLoc[0].lat), Number(cityLoc[0].lng));
      }
    });
  }

  onLeadFormSubmit() {
    this.submitted = true;
    if (this.leadForm.invalid) {
      return;
    }
    this.isAddingLead = true;
    let form_data = new FormData();
    this.trans_type = (this.tranTypeSell === true ? '4' : '3');
    if (this.propTypeResidential === true) {
      this.prop_type = '1';
    } else if (this.propTypeCommercial === true) {
      this.prop_type = '3';
    } else if (this.propTypeLand === true) {
      this.prop_type = '4';
    } else if (this.propTypeIndustrial === true) {
      this.prop_type = '6';
    }
    form_data.append('first_name', this.first_name);
    form_data.append('last_name', this.last_name);
    form_data.append('contact_no', this.phone);
    form_data.append('email', this.email);
    form_data.append('address_line_1', this.address_line1);
    form_data.append('address_line_2', this.address_line2);
    form_data.append('state_eloc', this.state_eloc);
    form_data.append('city_eloc', this.city_eloc);
    form_data.append('locality_title', this.area_title);
    form_data.append('locality_eloc', this.area_eloc);
    form_data.append('locality_lat', this.area_lat);
    form_data.append('locality_lng', this.area_lng);
    form_data.append('transaction_type_id', this.trans_type);
    form_data.append('property_type_id', this.prop_type);
    form_data.append('from', 'website');
    form_data.append('customer_type_id', '1');
    // @ts-ignore
    form_data.append('address_latitude', this.markerPropAddressLat);
    // @ts-ignore
    form_data.append('address_longitude', this.markerPropAddressLng);
    this.ApiService.postRequest(API_ENDPOINTS.API_ADD_LEAD, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        let data = {};
        data['leadadded'] = "true";
        // tslint:disable-next-line:forin
        for (const i in this.route.snapshot.queryParams){
          const param = this.route.snapshot.queryParams[i];
          if(this.route.snapshot.queryParams[i]){
            data[i] = param;
          }
        }
        this.router.navigate(['lead-generator'], {queryParams: data});
        this.isLeadAdded = true;
        this.isAddingLead = false;
      } else {
        this.FlashService.error(apiResult.error_code);
        this.isAddingLead = false;
      }
    }, error => {
      this.isAddingLead = false;
      this.FlashService.error("Something wrong!");
    });
  }

  onBackToHome() {
    this.router.navigate(['/']);
  }

  setCityLocation(cityEloc) {
    const selectedCityLoc = this.city_options.filter(eloc => eloc.id == cityEloc);
    this.area_title = selectedCityLoc[0].title;
    this.changeLatLng(Number(selectedCityLoc[0].lat), Number(selectedCityLoc[0].lng));
    // this.getPropertyList();
  }

  getStateCities(stateEloc, lat, lng) {
    var data = {};
    data['state_eloc'] = stateEloc;
    if (this.route.snapshot.queryParams.locality !== undefined) {
      data['area_title'] = this.route.snapshot.queryParams.locality;
    }
    if (this.route.snapshot.queryParams.lat !== undefined) {
      data['area_lat'] = this.route.snapshot.queryParams.lat;
      this.lat = lat;
    }
    if (this.route.snapshot.queryParams.lng !== undefined) {
      data['area_lng'] = this.route.snapshot.queryParams.lng;
      this.lng = lng;
    }
    this.router.navigate(['sell'], {queryParams: data});
    setTimeout(() => {
      this.getCities();
      this.ref.detectChanges();
    }, 1000);
  }

  onStepOneNext() {
    if (this.tranTypeSell === false && this.tranTypeRent === false) {
      alert("Please select Transaction Type");
    } else {
      this.step3 = false;
      this.step1 = false;
      this.step4 = false;
      this.step2 = true;
    }
  }

  onStepTwoNext() {
    if (this.propTypeResidential === false && this.propTypeCommercial === false && this.propTypeLand === false && this.propTypeIndustrial === false) {
      alert('Please select Property Type');
    } else {
      this.markers = [];
      this.isAddingLead = true;
      this.step1 = false;
      this.step2 = false;
      this.step4 = false;
      this.step3 = true;
      const markerN = {
        pid: '1',
        lat: this.propAddressLat,
        lng: this.propAddressLng,
        symbol: {
          url: 'assets/img/er_marker.png',
          scaledSize: {
            width: 30,
            height: 49
          }
        },
        draggable: true,
      };
      this.addMarker(markerN);
      this.isAddingLead = false;
    }
  }

  onStepTwoBack() {
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step1 = true;
  }

  onStepThreeNext() {
    this.submitted = true;
    if (this.propAddressForm.invalid) {
      return;
    } else {
      if (this.markerPropAddressLat === 0 && this.markerPropAddressLat === 0) {
        alert('Please select use Icon to point location at Map');
      } else {
        this.markers = [];
        this.submitted = false;
        this.isAddingLead = true;
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.getPropertyList();
        this.isAddingLead = false;
      }
    }
  }

  onStepThreeBack() {
    this.step1 = false;
    this.step3 = false;
    this.step4 = false;
    this.step2 = true;
  }

  onStepFourBack() {
    this.markers = [];
    this.step1 = false;
    this.step2 = false;
    this.step4 = false;
    this.step3 = true;
    let componentObj = this;
    componentObj.lat = this.markerPropAddressLat;
    componentObj.lng = this.markerPropAddressLng;
    componentObj.changeLatLng(componentObj.lat, componentObj.lng);
    const markerN = {
      pid: '1',
      lat: this.markerPropAddressLat,
      lng: this.markerPropAddressLng,
      symbol: {
        url: 'assets/img/er_marker.png',
        scaledSize: {
          width: 30,
          height: 49
        }
      },
      draggable: true,
    };
    componentObj.addMarker(markerN);
    this.isAddingLead = false;
  }

  onTranTypeSelect(tranType) {
    this.tranTypeSell = false;
    this.tranTypeRent = false;
    if (tranType === 4) {
      this.tranTypeSell = true;
    } else {
      this.tranTypeRent = true;
    }
  }

  onSelectPropType(propType) {
    this.propTypeResidential = false;
    this.propTypeCommercial = false;
    this.propTypeLand = false;
    this.propTypeIndustrial = false;
    if (propType === 1) {
      this.propTypeResidential = true;
    } else if (propType === 3) {
      this.propTypeCommercial = true;
    } else if (propType === 4) {
      this.propTypeLand = true;
    } else {
      this.propTypeIndustrial = true;
    }
  }

  markerDragEnd(m: marker, event: MouseEvent) {
    $.each(event, ( index, value ) => {
      this.markerPropAddressLat = value.lat;
      this.markerPropAddressLng = value.lng;
    });
  }
}

interface marker {
  pid?: string;
	lat: number;
	lng: number;
	label?: string;
  image_preview?: string;
  locality_title?: string;
  asking_price?: string;
  symbol?: object;
	draggable: boolean;
}
