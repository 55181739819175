/* tslint:disable:quotemark prefer-const no-var-keyword variable-name no-inferrable-types no-shadowed-variable max-line-length object-literal-key-quotes no-string-literal */
import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from './../../_services/api.service';
import { FlashService } from '../../_services/FlashService.service';
import { API_ENDPOINTS, WEB_URL } from '../../global';

declare var $: any;

@Component({
  selector: 'app-recently-viewed-card',
  templateUrl: './recently-viewed-card.component.html',
  styleUrls: ['./recently-viewed-card.component.scss']
})
export class RecentlyViewedCardComponent implements OnInit {
  @Input() buyerRecentlyViewed;
  // @Input() reloadItem;
  @Output() rejectedclick: EventEmitter<any> = new EventEmitter<any>();
  schedulevisitdata = {};
  offerPrice: number = 0;
  submitted = false;
  offerformvalid: boolean = true;
  offerpriceminerror: boolean = true;
  minOfferPriceText: string = '';
  currentItem: any;
  propertyName: string = '';
  propertyPrice: string = '';
  minOfferPrice: number = 0;
  isLoading: boolean = true;
  buyerOfferList: any = [];
  projUnitNoData: any = [];
  proj_unit_no_id: string = '';
  projUnitNoId: string = '';
  offerForm: FormGroup;
  offerAmount;

  constructor(private ApiService: ApiService, private router: Router,  private FlashService: FlashService, private formBuilder: FormBuilder) {
    // this.search_icon = appConstants.HOME_ICON;
  }

  ngOnInit(): void {
    $('.filterPropBtn').show();
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }

    this.offerForm = this.formBuilder.group({
      projUnitNoId: ['', [Validators.required]],
      offerprice: ['', [Validators.required]]
    });

    setTimeout (() => {
      const componentObj = this;
      this.changeHeaderClass();
      const amountStringMatcher = () => {
        return function findMatches(q, cb) {
          var matches = [];
          q = q.replace(/[^0-9.]+/g, '');
          matches.push({'label': q + ' Hund.', 'value': q * 100});
          matches.push({'label': q + ' Th.', 'value': q * 1000});
          matches.push({'label': q + ' Lc.', 'value': q * 100000});
          matches.push({'label': q + ' Cr.', 'value': q * 10000000});
          cb(matches);
        };
      };
      // this.offerAmount = $('#offerAmount1');
      this.offerAmount = $('#offerAmount_' + componentObj.buyerRecentlyViewed.id);
      this.offerAmount.typeahead(null, {
        name: 'offerAmount',
        displayKey: 'label',
        source: amountStringMatcher()
      });
      this.offerAmount.on('typeahead:selected', (event, data) => {
        componentObj.offerPrice = data.value;
      });
    }, 200);
  }

  get g() {
    return this.offerForm.controls;
  }
  onRejectedClick(id){
    this.rejectedclick.emit(id);
  }
  onReshowingClick(id, assign_to_user_id, franchisee_id, source,project_id) {
    
    this.schedulevisitdata['related_source_id'] = source=='project' && project_id ?  project_id :  id;
    this.schedulevisitdata['assign_to_user_id'] = assign_to_user_id;
    this.schedulevisitdata['franchisee_id'] = franchisee_id;
    this.schedulevisitdata['temp_source'] = source;
    this.schedulevisitdata['source'] = source;
    if(source=='project' && project_id){
      this.schedulevisitdata['project_unit_id'] = id;
    }

    this.router.navigate(['/schedulevisit'], {queryParams: this.schedulevisitdata});
  }
  onMakeOfferClick(vieweditem) {
    $('.filterPropBtn').show();
    $('.loaderBtn').hide();
    $('.projectUnitNoIdSelDiv').hide();
    this.propertyName = '';
    this.currentItem = vieweditem;
    this.propertyPrice = '<i class="fa fa-rupee"></i>' + vieweditem.asking_price_in_word;
    if (vieweditem.project_unit_id > 0) {
      $('.projectUnitNoIdSelDiv').show();
      $('#projUnitNoIdSel_' + vieweditem.id).removeAttr('disabled');
      if (vieweditem.project_unit_no_id > 0) {
        this.proj_unit_no_id = vieweditem.project_unit_no_id;
        $('#projUnitNoIdSel_' + vieweditem.id).attr('disabled', 'disabled');
      }
      const form_data = new FormData();
      form_data.append('project_id', vieweditem.project_id);
      form_data.append('project_unit_id', vieweditem.project_unit_id);
      this.ApiService.postRequest(API_ENDPOINTS.API_GET_PROJECT_UNIT_NO, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          this.projUnitNoData = apiResult.data.list;
        } else {
          // this.FlashService.error(apiResult.error_code);
        }
      }, error => {
        this.submitted = false;
        this.FlashService.error('Something wrong!');
      });
    }
    $('#makeInitOfferModel_' + vieweditem.id).modal('toggle');
  }

  setProjectUnitNo(value) {
    this.proj_unit_no_id = value;
  }

  onOfferSubmit(favouritesItem) {
    this.submitted = true;
    if (favouritesItem.property_id > 0) {
      if (this.offerPrice === 0) {
        return;
      }
    } else {
      if (this.offerForm.invalid) {
        return;
      }
    }
    $('.filterPropBtn').hide();
    $('.loaderBtn').show();
    $('#projUnitNoIdSel_' + favouritesItem.id).removeAttr('disabled');
    const form_data = new FormData();
    form_data.append('wishlist_id', favouritesItem.id + "");
    form_data.append('property_id', favouritesItem.property_id + "");
    form_data.append('project_id', favouritesItem.project_id + "");
    form_data.append('opportunity_id', favouritesItem.opportunity_id + "");
    form_data.append('project_unit_id', favouritesItem.project_unit_id + "");
    form_data.append('franchisee_id', favouritesItem.franchisee_id + "");
    form_data.append('project_unit_no_id', this.proj_unit_no_id);
    form_data.append('offer_amount', this.offerPrice + "");
    const offer_amount_title = this.amountToString(this.offerPrice);
    form_data.append('offer_amount_title', offer_amount_title + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_SET_OFFER, form_data).subscribe((apiResult: any) => {
      if (apiResult.success) {
        this.FlashService.success('Offer has been successfully sent.');
        this.getBuyerOfferList();
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.submitted = false;
      $('.loaderBtn').hide();
      $('#makeInitOfferModel_' + favouritesItem.id).modal('toggle');
      // $('#makeInitOfferModel').modal('toggle');
    }, error => {
      this.submitted = false;
      this.FlashService.error('Something wrong!');
      $('.loaderBtn').hide();
      $('#makeInitOfferModel_' + favouritesItem.id).modal('toggle');
      // $('#makeInitOfferModel').modal('toggle');
    });
  }

    amountToString(amount) {
    var amountText = "";
    if ((amount / 10000000) >= 1) {
      amountText = (amount / 10000000) + " Cr.";
    } else if ((amount / 100000) >= 1) {
      amountText = (amount / 100000) + " Lc.";
    } else if ((amount / 1000) >= 1) {
      amountText = (amount / 1000) + " Th.";
    }
    return amountText;
  }

  getBuyerOfferList() {
    const form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_SET_BUYER_PROPERTY_OFFER, form_data).subscribe((apiResult: any) => {
      const componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.buyerOfferList = apiResult.data.list;
      } else {
        this.isLoading = false;
      }
    });
  }


  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
  }

  openInNewTab(buyerRecentlyViewed) {
    if(buyerRecentlyViewed?.project_unit_status_txt=='sold' || buyerRecentlyViewed?.prop_status?.title=='Sold'){
      return
    }
   const id= buyerRecentlyViewed.project_unit_id ? buyerRecentlyViewed.project_unit_id : buyerRecentlyViewed.property_id
    const type=buyerRecentlyViewed.project_unit_id ? 'PU' : 'property'

    if (type === 'property') {
      window.open(WEB_URL + '/property-details/' + id, '_blank')
    }


    if (type === 'PU') {
      window.open(WEB_URL + '/project-unit-details/' + id, '_blank')
    }
  }
}
