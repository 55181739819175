/* tslint:disable:quotemark variable-name prefer-const no-var-keyword no-inferrable-types max-line-length no-shadowed-variable */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS } from 'src/app/global';
declare var $: any;

@Component({
  selector: 'app-savedsearch',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './savedsearch.component.html',
  styleUrls: ['./savedsearch.component.scss']
})
export class AccountSavedSearchComponent implements OnInit {

  search_icon: string = '';
  savedSearchList: any = [];
  isLoading: boolean = true;
  dashboardType = 'buyer';
  constructor(private ApiService: ApiService,  private router: Router, private route: ActivatedRoute,  private title: Title) {
    this.search_icon = API_ENDPOINTS.SEARCH_ICON;
    window.scroll(0,0);
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if (!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
      this.getSavedSearches();

      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Saved Search');
  }

  getSavedSearches() {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        this.isLoading = false;
        this.savedSearchList = apiResult.data;
      }
    });
  }

  onSaveSearchDelete(sid) {
    var form_data = new FormData();
    form_data.append('id', sid);
    this.ApiService.postRequest(API_ENDPOINTS.API_DELETE_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        this.getSavedSearches();
      }
    });
  }

  onSaveSearchClicked(json_string, source) {
    var json_obj = JSON.parse(json_string);
    var data = {};
    $.each(json_obj, (index, value) => {
      data[index] = value;
    });
    this.router.navigate([source], {queryParams: data});
  }
}
