<div class="container-fluid" style="background: #f5f5f5;">
  <section id="job_top">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div id="div_job_img">
            <img class="job_img" src="../../assets/img/suit_prop.jpg" />
          </div>
        </div>
        <div class="col-md-6">
          <div id="join_us">
            <p class="title">WHAT TYPE OF PROPERTIES WOULD YOU LIKE TO SEE?</p>

            <div id="new_prop_div" class="prop_div bg_white">
              <label for="new_prop_div">
                <p class="sub_title">
                  <input type="radio" id="new_prop_radio" class="mr-1" value="0" [(ngModel)]="transaction_type_id"
                         (change)="selPropTypeDiv('new')">
                  <span class="checkmark">&nbsp;NEW PROPERTY</span>
                </p>
                <p class="content">
                  Don't let your business suffer from a poor property choice. Put your trust in Empire Realtors, where our priority is to ensure you find the commercial space that will support and enhance your business endeavors.
                </p>
              </label>
            </div>

            <div id="residential_div" class="prop_div" *ngIf="searchReSaleProperty">
              <label for="residential_radio">
                <p class="sub_title">
                  <input type="radio" id="residential_radio" class="mr-1" value="1" [(ngModel)]="transaction_type_id"
                         (change)="selPropTypeDiv('residential')">
                  <span class="checkmark">&nbsp;RESALE PROPERTY</span>
                </p>
                <p class="content">
                  At Empire Realtors, our experienced team can help you identify and evaluate resale commercial properties that align with your immediate footfall requirements and exponential expansion goals. We provide insights into market trends, pricing, and growth potential to assist you in making informed decisions.
                </p>
              </label>
            </div>

            <!--<div id="commercial_div" class="prop_div mt-3 mb-3">
              <label for="commercial_radio">
                <p class="sub_title">
                  <input type="radio" id="commercial_radio" class="mr-1" value="3" [(ngModel)]="transaction_type_id"
                         (change)="selPropTypeDiv('commercial')">
                  <span class="checkmark">&nbsp;RENT PROPERTY</span>
                </p>
                <p class="content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </label>
            </div>-->

          </div>

          <div class="view_btns prop_btn">
            <button type="submit" class="btn login-btn mr-3 width_20" (click)="searchProperty('property')">Explore
              &nbsp;&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
