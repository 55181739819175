<div class="container-fluid body_fixer" style="background: #f5f5f5;">
  <section id="job_top">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div id="div_job_img">
            <img class="job_img" src="../../assets/img/luxury_img.jpg" />
          </div>
        </div>
        <div class="col-md-6">
          <div id="join_us">
            <p class="title">WHAT TYPE OF LUXURY PROPERTIES WOULD YOU LIKE TO SEE?</p>

            <div class="prop_div bg_white" id="residential_div">
              <label for="residential_radio">
                <p class="sub_title">
                  <input (change)="selPropTypeDiv('new')" [(ngModel)]="transactionTypeId" class="mr-1" id="residential_radio" type="radio"
                         value="1">
                  <span class="checkmark">&nbsp;NEW PROPERTY</span>
                </p>
                <p class="content">
                  Don't let your business suffer from a poor property choice. Put your trust in Empire Realtors, where our priority is to ensure you find the commercial space that will support and enhance your business endeavors.
                </p>
              </label>
            </div>

            <div class="prop_div mt-3 mb-3" id="commercial_div">
              <label for="commercial_radio">
                <p class="sub_title">
                  <input (change)="selPropTypeDiv('resale')" [(ngModel)]="transactionTypeId" class="mr-1" id="commercial_radio" type="radio"
                         value="3">
                  <span class="checkmark">&nbsp;RE-SALE PROPERTY</span>
                </p>
                <p class="content">
                  At Empire Realtors, our experienced team can help you identify and evaluate resale commercial properties that align with your immediate footfall requirements and exponential expansion goals. We provide insights into market trends, pricing, and growth potential to assist you in making informed decisions.
                </p>
              </label>
            </div>

          </div>

          <div class="view_btns prop_btn">
            <button (click)="searchProperty('property')" class="btn login-btn mr-3 width_20" type="submit">Explore
              &nbsp;&nbsp;<i aria-hidden="true" class="fa fa-long-arrow-right"></i></button>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
