<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid email-settings-outer" id="accoutnSettings">
  <div class="saveLoadingOverlay" *ngIf="formSubmiting" [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">My Email Preferences</h4>
        <p>Don’t want to receive email notifications? <b>Unsubscribe from all email notifications</b></p>
        <ng-container *ngIf="isLoading == true">
          <div class="loading_div">
            <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
          </div>
        </ng-container>
        <div class="email-settings" *ngIf="isLoading == false">
          <div class="top-communication-types">
            <h5 class="sub-title">Contact Methods</h5>
            <div class="form-group">
              <div class="custom-control custom-checkbox custom-control-inline" *ngFor="let communicationTypesItem of communicationTypesList">
                <input type="checkbox" class="custom-control-input" id="selectedcommunicationTypes{{communicationTypesItem.id}}" [(ngModel)]="communicationTypesItem.isChecked" (change)="changeCommunicationTypes(communicationTypesItem.id, communicationTypesItem.isChecked)"/>
                <label class="custom-control-label" for="selectedcommunicationTypes{{communicationTypesItem.id}}">{{ communicationTypesItem.text }}</label>
              </div>
            </div>
          </div>
          <div *ngIf="dashboardType == 'buyer'" class="fav-e-settings">
            <h5 class="sub-title">Favourites</h5>
            <!--<div class="form-group">
              <div class="custom-control custom-radio custom-control-inline" *ngFor="let favoritesFreqItem of favoritesFreqList; index as i">
                <input type="radio" value="{{ favoritesFreqItem.text }}" checked="{{ favoritesFreqItem.isChecked }}" class="custom-control-input" id="favorites{{i}}" [(ngModel)]="yourfavorites" name="yourfavorites" (change)="changeFavorites(favoritesFreqItem.id, i)" />
                <label class="custom-control-label" for="favorites{{i}}">{{ favoritesFreqItem.text }}</label>
              </div>
            </div>-->
          </div>
          <div *ngIf="dashboardType == 'buyer'" class="fav-e-types-settings">
            <h5 class="sub-title">Favourites Email Types</h5>
            <div class="form-group">
              <div class="custom-control custom-checkbox custom-control-inline" *ngFor="let favEmailTypesItem of favEmailTypesList">
                <input type="checkbox" class="custom-control-input" id="selectedFavEmailTypes{{favEmailTypesItem.id}}" [(ngModel)]="favEmailTypesItem.isChecked" (change)="changeFavEmailTypes(favEmailTypesItem.id, favEmailTypesItem.isChecked)"/>
                <label class="custom-control-label" for="selectedFavEmailTypes{{favEmailTypesItem.id}}">{{ favEmailTypesItem.text }}</label>
              </div>
            </div>
          </div>
          <div *ngIf="dashboardType == 'buyer'" class="fav-e-settings">
            <h5 class="sub-title">Saved Searches</h5>
            <div class="search-items">
              <ng-container *ngFor="let searchItem of savedSearchList">
                <div class="search-item">
                  <h4 (click)="onSaveSearchClicked(searchItem.json_string)">{{ searchItem.name }}</h4>
                  <div class="form-group">
                    <div class="custom-control custom-radio custom-control-inline">
                      <ng-container *ngIf="searchItem.frequency_status == 1">
                        <input type="radio" value="1" checked="true" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_1" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 1)" />
                      </ng-container>
                      <ng-container *ngIf="searchItem.frequency_status != 1">
                        <input type="radio" value="1" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_1" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 1)" />
                      </ng-container>
                      <label class="custom-control-label" for="savedSearch_{{ searchItem.id }}_1">Instant</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <ng-container *ngIf="searchItem.frequency_status == 2">
                        <input type="radio" value="2" checked="true" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_2" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 2)" />
                      </ng-container>
                      <ng-container *ngIf="searchItem.frequency_status != 2">
                        <input type="radio" value="2" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_2" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 2)" />
                      </ng-container>
                      <label class="custom-control-label" for="savedSearch_{{ searchItem.id }}_2">Daily</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <ng-container *ngIf="searchItem.frequency_status == 3">
                        <input type="radio" value="3" checked="true" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_3" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 3)" />
                      </ng-container>
                      <ng-container *ngIf="searchItem.frequency_status != 3">
                        <input type="radio" value="3" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_3" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 3)" />
                      </ng-container>
                      <label class="custom-control-label" for="savedSearch_{{ searchItem.id }}_3">Weekly</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <ng-container *ngIf="searchItem.frequency_status == 4">
                        <input type="radio" value="4" checked="true" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_4" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 4)" />
                      </ng-container>
                      <ng-container *ngIf="searchItem.frequency_status != 4">
                        <input type="radio" value="4" class="custom-control-input" id="savedSearch_{{ searchItem.id }}_4" name="savedSearch_{{ searchItem.id }}" (change)="changeSavedSearchSettings(searchItem.id, 4)" />
                      </ng-container>
                      <label class="custom-control-label" for="savedSearch_{{ searchItem.id }}_4">Never</label>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="fav-e-types-settings">
            <h5 class="sub-title">Saved Searches Email Types</h5>
            <div class="form-group">
              <div class="custom-control custom-checkbox custom-control-inline" *ngFor="let emailTypesItem of savedSearchEmailTypesList">
                <input type="checkbox" class="custom-control-input" id="selectedEmailTypes{{emailTypesItem.id}}" [(ngModel)]="emailTypesItem.isChecked" (change)="changeSavedSearchEmailTypes(emailTypesItem.id, emailTypesItem.isChecked)" />
                <label class="custom-control-label" for="selectedEmailTypes{{emailTypesItem.id}}">{{ emailTypesItem.text }}</label>
              </div>
            </div>
          </div>
          <div class="email-types-settings">
            <h5 class="sub-title">Recommended For You</h5>
            <p>Recommended homes based on your searches, favourites, and likes, sent to you frequently.</p>
            <div class="form-group">
              <div class="custom-control custom-checkbox" *ngFor="let recommendedItem of recommendedList">
                <input type="checkbox" class="custom-control-input" id="selectedRecommended{{recommendedItem.id}}" [(ngModel)]="recommendedItem.isChecked" (change)="changeRecommended(recommendedItem.id, recommendedItem.isChecked)"/>
                <label class="custom-control-label" for="selectedRecommended{{recommendedItem.id}}">{{ recommendedItem.text }}</label>
              </div>
            </div>
          </div>
          <div class="email-types-settings">
            <h5 class="sub-title">Home Search Insights</h5>
            <div class="form-group">
              <div class="custom-control custom-checkbox" *ngFor="let searchInsightsItem of searchInsightsList">
                <input type="checkbox" class="custom-control-input" id="selectedSearchInsights{{searchInsightsItem.id}}" [(ngModel)]="searchInsightsItem.isChecked" (change)="changeSearchInsights(searchInsightsItem.id, searchInsightsItem.isChecked)"/>
                <label class="custom-control-label" for="selectedSearchInsights{{searchInsightsItem.id}}">{{ searchInsightsItem.text }}</label>
                <p>{{ searchInsightsItem.info }}</p>
              </div>
            </div>
          </div>
          <div class="email-types-settings">
            <h5 class="sub-title">Home Reports</h5>
            <p>Get email updates on <b>homes you’ve claimed.</b></p>
            <div class="form-group">
              <div class="custom-control custom-checkbox" *ngFor="let homeReportsItem of homeReportsList">
                <input type="checkbox" class="custom-control-input" id="selectedHomeReports{{homeReportsItem.id}}" [(ngModel)]="homeReportsItem.isChecked" (change)="changeHomeReports(homeReportsItem.id, homeReportsItem.isChecked)"/>
                <label class="custom-control-label" for="selectedHomeReports{{homeReportsItem.id}}">{{ homeReportsItem.text }}</label>
                <p>{{ homeReportsItem.info }}</p>
              </div>
            </div>
          </div>
          <div class="email-types-settings">
            <h5 class="sub-title">Lifestyle & Empire News</h5>
            <div class="form-group">
              <div class="custom-control custom-checkbox" *ngFor="let lfenItem of lfenList">
                <input type="checkbox" class="custom-control-input" id="selectedlfen{{lfenItem.id}}" [(ngModel)]="lfenItem.isChecked" (change)="changeLFEN(lfenItem.id, lfenItem.isChecked)"/>
                <label class="custom-control-label" for="selectedlfen{{lfenItem.id}}">{{ lfenItem.text }}</label>
                <p>{{ lfenItem.info }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
