/* tslint:disable:quotemark whitespace no-shadowed-variable max-line-length no-inferrable-types variable-name prefer-const no-var-keyword no-string-literal */
import {Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {FlashService} from '../../_services/FlashService.service';
import {ApiService} from './../../_services/api.service';
import { API_ENDPOINTS } from '../../global';
import {Title} from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-schedule',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './schedulevisit.component.html',
  styleUrls: ['./schedulevisit.component.scss']
})

export class ScheduleComponent implements OnInit {

  schedulelist: any = [];
  source: string = '';
  project_unit_id: string = '';
  temp_source: string = '';
  related_source_id: string = '';
  franchisee_id: string = '';
  role_id: string = '';
  userid: string = '';
  type: string = 'appointment';
  assign_to_user_id: string = '';
  subject: string = '';
  whatsAppNo: string = '';
  googleDuo: string = '';
  skypeId: string = '';
  description: string = '';
  appo_date: string = '';
  appo_date_d: string = '';
  appo_time: string = '';
  appo_e_time: string = '';
  selectedVideoChatOption = 0;
  timeList: any = [];
  timeListAll: any = [];
  appointmentStep: number = 1;
  isLoggedIn;
  submitted = false;
  formSubmiting = false;
  scheduleForm: FormGroup;
  scheduleVideoChatForm: FormGroup;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  @ViewChild('scheduleFormSubmit') scheduleFormSubmitEl;
  @ViewChild('scheduleVideoChatFormSubmit') scheduleVideoChatFormSubmitEl;
  visitTimeSliderEl;
  isTimeLoading: boolean = true;
  appointmentType = 0;
  weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  constructor(private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, private ApiService: ApiService, private FlashService: FlashService, private title: Title, private formBuilder: FormBuilder) {

  }

  get k() {
    return this.scheduleVideoChatForm.controls;
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  get g() {
    return this.scheduleForm.controls;
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if (this.route.snapshot.queryParams.project_unit_id !== undefined) {
      this.project_unit_id = this.route.snapshot.queryParams.project_unit_id;
    }
    if (this.route.snapshot.queryParams.appointment_type !== undefined) {
      this.appointmentType = Number(this.route.snapshot.queryParams.appointment_type);
    }
    if (this.route.snapshot.queryParams.temp_source !== undefined) {
      this.temp_source = this.route.snapshot.queryParams.temp_source;
    }
    if (this.route.snapshot.queryParams.project_unit_id !== undefined) {
      this.project_unit_id = this.route.snapshot.queryParams.project_unit_id;
    }
    if (this.route.snapshot.queryParams.source !== undefined) {
      this.source = this.route.snapshot.queryParams.source;
    } else {
      this.router.navigate(['/']);
    }
    if (this.route.snapshot.queryParams.related_source_id !== undefined) {
      this.related_source_id = this.route.snapshot.queryParams.related_source_id;
    } else {
      this.router.navigate(['/']);
    }
    if (this.route.snapshot.queryParams.franchisee_id !== undefined) {
      this.franchisee_id = this.route.snapshot.queryParams.franchisee_id;
    } else {
      this.router.navigate(['/']);
    }
    if (this.route.snapshot.queryParams.assign_to_user_id !== undefined) {
      this.assign_to_user_id = this.route.snapshot.queryParams.assign_to_user_id;
    } else {
      this.router.navigate(['/']);
    }
    if (this.route.snapshot.queryParams.date !== undefined) {
      this.appo_date = this.route.snapshot.queryParams.date;
    }
    if (this.route.snapshot.queryParams.time !== undefined) {
      this.appo_time = this.route.snapshot.queryParams.time;
    }
    if (this.route.snapshot.queryParams.subject !== undefined) {
      this.subject = this.route.snapshot.queryParams.subject;
    }
    if (this.route.snapshot.queryParams.description !== undefined) {
      this.description = this.route.snapshot.queryParams.description;
    }
    if (this.route.snapshot.queryParams.appointment_step !== undefined) {
      this.appointmentStep = Number(this.route.snapshot.queryParams.appointment_step);
    }
    if (this.appointmentStep === 2) {
      $("#schedule_left_box_2").hide();
      $("#schedule_left_box_3").hide();
      if (this.appointmentType === 1) {
        $("#schedule_left_box_3").show();
        $("#whatsapp_phone").show();
        $("#skype_id").hide();
        $("#google_duo").hide();
        this.selectedVideoChatOption = 1;
      } else {
        $("#schedule_left_box_2").show();
      }
    }
    this.isLoggedIn = this.ApiService.loginCheck();

    // this.timeListAll.push({id: "07:00:00", stime: "07:00 AM", etime: "07:30 AM"});
    // this.timeListAll.push({id: "07:30:00", stime: "07:30 AM", etime: "08:00 AM"});
    // this.timeListAll.push({id: "08:00:00", stime: "08:00 AM", etime: "08:30 AM"});
    this.timeListAll.push({id: "08:30:00", stime: "08:30 AM", etime: "09:00 AM"});
    this.timeListAll.push({id: "09:00:00", stime: "09:00 AM", etime: "09:30 AM"});
    this.timeListAll.push({id: "09:30:00", stime: "09:30 AM", etime: "10:00 AM"});
    this.timeListAll.push({id: "10:00:00", stime: "10:00 AM", etime: "10:30 AM"});
    this.timeListAll.push({id: "10:30:00", stime: "10:30 AM", etime: "11:00 AM"});
    this.timeListAll.push({id: "11:00:00", stime: "11:00 AM", etime: "11:30 AM"});
    this.timeListAll.push({id: "11:30:00", stime: "11:30 AM", etime: "12:00 PM"});
    this.timeListAll.push({id: "12:00:00", stime: "12:00 PM", etime: "12:30 PM"});
    this.timeListAll.push({id: "12:30:00", stime: "12:30 PM", etime: "01:00 PM"});
    this.timeListAll.push({id: "13:00:00", stime: "01:00 PM", etime: "01:30 PM"});
    this.timeListAll.push({id: "13:30:00", stime: "01:30 PM", etime: "02:00 PM"});
    this.timeListAll.push({id: "14:00:00", stime: "02:00 PM", etime: "02:30 PM"});
    this.timeListAll.push({id: "14:30:00", stime: "02:30 PM", etime: "03:00 PM"});
    this.timeListAll.push({id: "15:00:00", stime: "03:00 PM", etime: "03:30 PM"});
    this.timeListAll.push({id: "15:30:00", stime: "03:30 PM", etime: "04:00 PM"});
    this.timeListAll.push({id: "16:00:00", stime: "04:00 PM", etime: "04:30 PM"});
    this.timeListAll.push({id: "16:30:00", stime: "04:30 PM", etime: "05:00 PM"});
    this.timeListAll.push({id: "17:00:00", stime: "05:00 PM", etime: "05:30 PM"});
    this.timeListAll.push({id: "17:30:00", stime: "05:30 PM", etime: "06:00 PM"});
    this.timeListAll.push({id: "18:00:00", stime: "06:00 PM", etime: "06:30 PM"});
    this.timeListAll.push({id: "18:30:00", stime: "06:30 PM", etime: "07:00 PM"});
    this.timeListAll.push({id: "19:00:00", stime: "07:00 PM", etime: "07:30 PM"});
    this.timeListAll.push({id: "19:30:00", stime: "07:30 PM", etime: "08:00 PM"});
    this.timeListAll.push({id: "20:00:00", stime: "08:00 PM", etime: "08:30 PM"});
    this.timeListAll.push({id: "20:30:00", stime: "08:30 PM", etime: "09:00 PM"});

    setTimeout(() => {
      this.changeHeaderClass();
      this.setAppointmentSlider();
      // this.generateAppointmentSlider();
      // this.getBookedSlots();
    }, 200);

    this.scheduleForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]]
    });
    this.scheduleVideoChatForm = this.formBuilder.group({
      whatsAppNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
      skypeId: ['', []],
      googleDuo: ['', [Validators.minLength(10), Validators.maxLength(20)]],
    });

    let lS=JSON.parse(localStorage.getItem('currentUser'))
    if(lS && lS.contact_no){
      this.scheduleForm.patchValue({
        mobileNumber:lS.contact_no
      })
      this.subject=lS.contact_no
    }
  }

  showVideoChatOption(type) {
    this.selectedVideoChatOption = type;
    $("#whatsapp_phone").hide();
    $("#skype_id").hide();
    $("#google_duo").hide();
    $('.video-chat-label').removeClass('active-label');
    this.scheduleVideoChatForm.setControl('whatsAppNumber', this.formBuilder.control('', []));
    this.scheduleVideoChatForm.setControl('skypeId', this.formBuilder.control('', []));
    this.scheduleVideoChatForm.setControl('googleDuo', this.formBuilder.control('', []));
    if (type === 1) {
      $('.whatsapp-label').addClass('active-label');
      $("#whatsapp_phone").show();
      this.scheduleVideoChatForm.setControl('whatsAppNumber', this.formBuilder.control('', [Validators.required]));
    } else if (type === 2) {
      $('.skype-label').addClass('active-label');
      $("#skype_id").show();
      this.scheduleVideoChatForm.setControl('skypeId', this.formBuilder.control('', [Validators.required]));
    } else {
      $('.google-label').addClass('active-label');
      $("#google_duo").show();
      this.scheduleVideoChatForm.setControl('googleDuo', this.formBuilder.control('', [Validators.required]));
    }
  }

  onScheduleSubmit() {
    this.submitted = true;
    if (this.appointmentType === 1) {
      if (this.scheduleVideoChatForm.invalid) {
        return;
      }
      if (this.selectedVideoChatOption === 1) {
        this.subject = this.whatsAppNo;
      } else if (this.selectedVideoChatOption === 2) {
        this.subject = this.skypeId;
      } else {
        this.subject = this.googleDuo;
      }
    } else {
      if (this.scheduleForm.invalid) {
        return;
      }
    }
    $('#next').hide();
    $('.loaderBtn').show();
    this.formSubmiting = true;
    let form_data = new FormData();
    form_data.append('is_web_api', '1');
    form_data.append('franchisee_id', this.franchisee_id);
    form_data.append('franchiseeId', this.franchisee_id);
    form_data.append('role_id', '1');
    // form_data.append('userid', '1');
    form_data.append('type', this.type);
    form_data.append('source', this.source);
    form_data.append('related_source_id', this.related_source_id);
    form_data.append('assign_to_user_id', this.assign_to_user_id);
    form_data.append('subject', this.subject);
    form_data.append('description', this.description);
    form_data.append('appo_date', this.appo_date);
    form_data.append('appo_time', this.appo_time);
    form_data.append('project_unit_id', this.project_unit_id);
    // @ts-ignore
    form_data.append('video_chat_type', this.selectedVideoChatOption);
    // @ts-ignore
    form_data.append('appointment_type', this.appointmentType);
    const queryNavData = [];
    queryNavData['type'] = 'both';
    localStorage.setItem('isFromSchedule','propertyAddAppointment')
    if (this.source === "property") {
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_APPOINTMENT, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          // localStorage.setItem('isFromSchedule','propertyAddAppointment')
          this.router.navigate(['buyerdashboard'], {queryParams: queryNavData});
        } else {
          this.FlashService.error(apiResult.error_code);
        }
      });
    } else if (this.source === "project") {
      this.ApiService.postRequest(API_ENDPOINTS.API_PROJECT_APPOINTMENT, form_data).subscribe((apiResult: any) => {
        if (apiResult.success) {
          // localStorage.setItem('isFromSchedule','projectAddAppointment')
          this.router.navigate(['buyerdashboard'], {queryParams: queryNavData});
        } else {
          this.FlashService.error(apiResult.error_code);
        }
      });
    }
  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Schedule Visit');
  }

  updateSelectedDateTime() {
    this.appo_date = $('.s_day_active').data('date');
    this.appo_time = $('.s_time_active').data('stime');
    this.appo_e_time = $('.s_time_active').data('etime');
    if (this.appo_date !== undefined && this.appo_date !== '') {
      const sDateArray = this.appo_date.split('/');
      const sDayDate = new Date(sDateArray[2] + "-" + sDateArray[1] + "-" + sDateArray[0]).toDateString();
      this.appo_date_d = sDayDate;
    }
  }

  generateAppointmentSlider_old() {
    let componentObj = this;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    let dateDay = String(d.getDate()).padStart(2, '0');
    let monthname = monthNames[d.getMonth()];
    // tslint:disable-next-line:new-parens
    let currentYear = (new Date).getFullYear();
    // tslint:disable-next-line:new-parens
    let currentMonth = (new Date).getMonth() + 1;
    let n = daysInMonth(currentMonth, currentYear);
    let a = [];
    // tslint:disable-next-line:radix
    let dateDayi = parseInt(dateDay);
    for (var i = dateDayi; i <= n; i++) {
      let weekday = this.weekDays;
      let frmate = currentMonth + '/' + i + '/' + currentYear;
      let date = new Date('"' + frmate + '"');
      let dyname = weekday[date.getDay()];
      let datestr = i + "/" + currentMonth + "/" + currentYear;
      a.push('<div class="single_day" data-date="' + datestr + '"><label class="day_name">' + dyname + '</label><label class="day_date">' + i + '</label><label class="month_name">' + monthname + '</label></div>');
    }
    var preDays = n - dateDayi;
    if (preDays < 15) {
      if ((d.getMonth() + 1) > 11) {
        monthname = monthNames[0];
        // tslint:disable-next-line:new-parens
        currentYear = (new Date).getFullYear() + 1;
        currentMonth = 1;
      } else {
        monthname = monthNames[d.getMonth() + 1];
        // tslint:disable-next-line:new-parens
        currentYear = (new Date).getFullYear();
        // tslint:disable-next-line:new-parens
        currentMonth = (new Date).getMonth() + 2;
      }
      n = daysInMonth(currentMonth, currentYear);
      for (let i = 1; i <= n; i++) {
        let weekday = this.weekDays;
        let frmate = currentMonth + '/' + i + '/' + currentYear;
        let date = new Date('"' + frmate + '"');
        let dyname = weekday[date.getDay()];
        let datestr = i + "/" + currentMonth + "/" + currentYear;
        a.push('<div class="single_day" data-date="' + datestr + '"><label class="day_name">' + dyname + '</label><label class="day_date">' + i + '</label><label class="month_name">' + monthname + '</label></div>');
      }
    }
    $("#visit_date_slider").html(a);

    $('#visit_date_slider').owlCarousel({
      loop: false,
      nav: true,
      navText: ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>", "<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 4
        },
        1000: {
          items: 7
        }
      }
    });

    this.visitTimeSliderEl = $('#visit_time_slider').owlCarousel({
      loop: false,
      nav: true,
      navText: ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>", "<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 4
        },
        1000: {
          items: 8
        }
      }
    });

    $('.single_day').on('click', function (event) {
      $('.s_day_active').removeClass('s_day_active');
      $(this).addClass('s_day_active');
      componentObj.updateSelectedDateTime();
      componentObj.getBookedSlots();
    });

    $('.time_item').on('click', function (event) {
      $('.s_time_active').removeClass('s_time_active');
      $(this).addClass('s_time_active');
      componentObj.updateSelectedDateTime();
    });

    if (this.appo_time) {
      $('#visit_time_slider').find('.time_item[data-stime="' + this.appo_time + '"]').addClass('s_time_active');
    } else {
      $('#visit_time_slider').find('.owl-item:first-of-type').find('.time_item').addClass('s_time_active');
    }
    if (this.appo_date) {
      $('#visit_date_slider').find('.single_day[data-date="' + this.appo_date + '"]').addClass('s_day_active');
    } else {
      $('#visit_date_slider').find('.owl-item:first-of-type').find('.single_day').addClass('s_day_active');
    }
    componentObj.updateSelectedDateTime();
    if (this.appointmentStep === 2) {
      $("#schedule_left_box_2").show();
      $("#schedule_left_box_1").hide();
    }
  }

  generateAppointmentSlider(slotDatesAry) {
    let componentObj = this;
    let a = [];
    // tslint:disable-next-line:forin
    for (let prop in slotDatesAry) {
      const slotData = slotDatesAry[prop];
      let dyname = slotData.day_name;
      let datestr = slotData.date_fromat;
      let bookClass = '';
      let title = '';
      if(slotData.slot === 'book') {
        bookClass = 's_booked_slot';
        title = 'Fully Booked';
      }
      a.push('<div class="single_day ' + bookClass + '" data-date="' + datestr + '" data-slot="'+slotData.slot+'" title="'+title+'"><label class="day_name">' + dyname + '</label><label class="day_date">' + slotData.day + '</label><label class="month_name">' + slotData.month + '</label></div>');
    }
    $("#visit_date_slider").html(a);

    $('#visit_date_slider').owlCarousel({
      loop: false,
      nav: true,
      navText: ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>", "<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 4
        },
        1000: {
          items: 7
        }
      }
    });

    this.visitTimeSliderEl = $('#visit_time_slider').owlCarousel({
      loop: false,
      nav: true,
      navText: ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>", "<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 4
        },
        1000: {
          items: 8
        }
      }
    });

    this.getBookedSlots();

    $('.single_day').on('click', function(event) {
      if ($(this).data('slot') === 'available') {
        $('.s_day_active').removeClass('s_day_active');
        $(this).addClass('s_day_active');
        componentObj.updateSelectedDateTime();
        componentObj.getBookedSlots();
      }
    });

    $('.time_item').on('click', function(event) {
      $('.s_time_active').removeClass('s_time_active');
      $(this).addClass('s_time_active');
      componentObj.updateSelectedDateTime();
    });

    if (this.appo_time) {
      $('#visit_time_slider').find('.time_item[data-stime="' + this.appo_time + '"]').addClass('s_time_active');
    } else {
      $('#visit_time_slider').find('.owl-item:first-of-type').find('.time_item').addClass('s_time_active');
    }
    if (this.appo_date) {
      $('#visit_date_slider').find('.single_day[data-date="' + this.appo_date + '"]').addClass('s_day_active');
    } else {
      $('#visit_date_slider').find('.owl-item:first-of-type').find('.single_day').addClass('s_day_active');
    }
    componentObj.updateSelectedDateTime();
    if (this.appointmentStep === 2) {
      $("#schedule_left_box_2").show();
      $("#schedule_left_box_1").hide();
    }
  }

  setAppointmentSlider() {
    const form_data = new FormData();
    form_data.append('assign_to_user_id', this.assign_to_user_id);
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BOOKED_SLOTS_DATES, form_data).subscribe((apiResult: any) => {
      this.generateAppointmentSlider(apiResult.data);
      // this.getBookedSlots();
    });
  }

  appointmentNextStep() {
    if (this.appointmentStep === 1) {
      this.appointmentStep = 2;
      $("#schedule_left_box_1").hide();
      if (this.appointmentType === 1) {
        $("#schedule_left_box_3").show();
        $("#whatsapp_phone").show();
        $("#skype_id").hide();
        $("#google_duo").hide();
      } else {
        $("#schedule_left_box_2").show();
      }
    } else if (this.appointmentStep === 2) {
      this.subject = $("#phone").val();
      this.description = $("#message").val();
      let data = {};
      data['related_source_id'] = this.related_source_id;
      data['assign_to_user_id'] = this.assign_to_user_id;
      data['franchisee_id'] = this.franchisee_id;
      data['source'] = this.source;
      data['date'] = this.appo_date;
      data['time'] = this.appo_time;
      data['subject'] = this.subject;
      data['description'] = this.description;
      data['appointment_step'] = this.appointmentStep;
      data['appointment_type'] = this.appointmentType;
      this.router.navigate(['/schedulevisit'], {queryParams: data});
      if (!this.isLoggedIn) {
        this.openLoginModel();
      } else {
        if (this.appointmentType === 1) {
          this.scheduleVideoChatFormSubmitEl.ngSubmit.emit();
        } else {
          this.scheduleFormSubmitEl.ngSubmit.emit();
        }
      }
    } else {
      this.FlashService.error("Please fill below details!");
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  appointmentBackStep() {
    if (this.appointmentStep === 2) {
      this.appointmentStep = 1;
      $("#schedule_left_box_2").hide();
      $("#schedule_left_box_1").show();
    }
  }

  getBookedSlots(): void {
    this.isTimeLoading = true;
    this.timeList = [];
    this.visitTimeSliderEl.trigger('destroy.owl.carousel');
    this.visitTimeSliderEl.find('.owl-stage-outer').children().unwrap();
    let form_data = new FormData();
    form_data.append('property_id', this.related_source_id);
    form_data.append('assign_to_user_id', this.assign_to_user_id);
    form_data.append('date', this.appo_date);
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_BOOKED_SLOTS, form_data).subscribe((apiResult: any) => {
      var componentObj = this;
      if (apiResult.success) {
        $.each(this.timeListAll, (index, value) => {
          if (!apiResult.data.includes(value.id)) {
            componentObj.timeList.push(value);
          }
        });
        this.ref.detectChanges();
        this.visitTimeSliderEl = $('#visit_time_slider').owlCarousel({
          loop: false,
          nav: true,
          navText: ["<div class='nav-btnn prev-slider'><i class='fa fa-chevron-left'></i></div>", "<div class='nav-btnn next-slider'><i class='fa fa-chevron-right'></i></div>"],
          dots: false,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 4
            },
            1000: {
              items: 8
            }
          }
        });
        $('.time_item').on('click', function (event) {
          $('.s_time_active').removeClass('s_time_active');
          $(this).addClass('s_time_active');
          componentObj.updateSelectedDateTime();
        });
        if (this.appo_time && $('#visit_time_slider').find('.time_item[data-stime="' + this.appo_time + '"]').length) {
          $('#visit_time_slider').find('.time_item[data-stime="' + this.appo_time + '"]').addClass('s_time_active');
        } else {
          $('#visit_time_slider').find('.owl-item:first-of-type').find('.time_item').addClass('s_time_active');
        }
        this.updateSelectedDateTime();
        this.isTimeLoading = false;
      }
    });
  }
}

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
