import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Visibility'
})
export class ActionsButtonsVisibilityPipe implements PipeTransform {

  transform(value: any): boolean {

    if (value.prop_status) {
      if (value.prop_status?.title == 'Sold' || value.prop_status?.title == 'Under offer') {
        return false
      }
    }

    if (value.project_unit_status_txt) {

      if (value.project_unit_status_txt == 'under_offer' || value.project_unit_status_txt == 'sold') {
        return false
      }

    }
    return true;
  }

}
