import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usernameTrim'
})
export class UsernameTrimPipe implements PipeTransform {

  transform(value: string, limit = 10): string {
    if (value.includes('@')) {
      let userName = value.split('@')
      return userName[0]
    }

    let userName = value.split(' ')
    if (!userName[1]) return userName[0]
    if (userName[0] && userName[0].length > limit) {
      userName[0] = userName[0].slice(0, limit)
    }
    if (userName[1] && userName[1].length > limit) {
      userName[1] = userName[1].slice(0, limit)
    }
    return userName[0] + ' ' + userName[1]
  }

}
