<header id="header" class="header-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-12">
        <button class="navbar-toggler d-small-block" type="button" data-toggle="collapse"
          data-target="#mainNavbarCollapse" aria-controls="mainNavbarCollapse" aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fa fa-bars"></i>
        </button>
        <a href="/">
          <img id="imgHeadLogo" src="" class="head_logo" />
        </a>
        <div class="headUser mobile-show" *ngIf="isLoggedIn">
          <span *ngIf="username != 'null'">{{username}}</span>
        </div>
      </div>
      <div class="col-md-6 col-12 d-lg-block">
        <nav class="nav-menu d-lg-block">
          <ul>
            <li class="active head-phone"><a class="dynamic" href="tel:+918866550550"><i class="fa fa-mobile"
                  aria-hidden="true"></i> +91 8866550550</a></li>
            <li class="active"><a class="dynamic" [routerLink]="['/buy']">Buy</a></li>
            <li class="active"><a class="dynamic" [routerLink]="['/sell']">Sell</a></li>
            <li class="active"><a class="dynamic" [routerLink]="['/rent-lease']">Rent/Lease</a></li>
            <li class="active"><a class="dynamic" href="/">Loan</a></li>
            <li class="active"><a class="dynamic" [routerLink]="['/buy-franchisee']">Buy Franchisee</a></li>
            <li class="active"><a class="dynamic" [routerLink]="['/rent-calculator']">Rent Calculator</a></li>
            <li *ngIf="!(isLoggedIn)" class="active login-register-menu"><a href="javascript:void(0);"
                (click)="openLoginModel()" class="modalopn">Sign In / Join</a></li>
            <li *ngIf="(isLoggedIn)" class="dynamic drop-down active login-register-menu">
              <a href="javascript:void(0);" class="dynamic dynamicmenu" *ngIf="username == 'null'">My Account</a>
              <a href="javascript:void(0);" class="dynamic dynamicmenu" *ngIf="username != 'null'">{{username}}</a>
              <ul>
                <li><a [routerLink]="['/buyerdashboard']" [queryParams]="{type: 'buyer'}">Dashboard</a></li>
                <li><a [routerLink]="['/favourites']">Favourites</a></li>
                <li><a [routerLink]="['/savedsearch']">Saved Search</a></li>
                <li><a [routerLink]="['/offers']">Offers</a></li>
                <li><a [routerLink]="['/openhouse']">My Appointment</a></li>
                <li><a [routerLink]="['/reviews']">My Reviews</a></li>
                <li><a [routerLink]="['/email-settings']">My Email Preferences</a></li>
                <li><a [routerLink]="['/myaccount']">Account Settings</a></li>
                <li><a href="javascript:void(0);" (click)="logout()">Sign Out</a></li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="collapse navbar-toggleable-md" id="mainNavbarCollapse">
      <ul class="nav navbar-nav pull-lg-right">
        <li class="nav-item">
          <a class="nav-link" href="tel:+918866550550">+91 8866550550 <span class="sr-only"></span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/buy']">Buy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/sell']">Sell</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/rent-lease']">Rent/Lease</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/">Empire Luxurious</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/">Loan</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/buy-franchisee']">Buy Franchisee</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/rent-calculator']">Rent Calculator</a>
        </li>
        <li *ngIf="!(isLoggedIn)" class="nav-item active"><a href="javascript:void(0);" (click)="openLoginModel()"
            class="nav-link modalopn">Signin / Join</a></li>
        <li *ngIf="(isLoggedIn)" class="nav-item dynamic drop-down active">
          <a href="javascript:void(0);" class="nav-link dynamic dynamicmenu" *ngIf="username == 'null'">My Account</a>
          <a href="javascript:void(0);" class="nav-link dynamic dynamicmenu" *ngIf="username != 'null'">{{username}}</a>
          <ul>
            <li><a [routerLink]="['/favourites']">Favourites</a></li>
            <li><a [routerLink]="['/savedsearch']">Saved Search</a></li>
            <li><a [routerLink]="['/offers']">Offers</a></li>
            <li><a [routerLink]="['/openhouse']">My Appointment</a></li>
            <li><a [routerLink]="['/reviews']">My Reviews</a></li>
            <li><a [routerLink]="['/email-settings']">My Email Preferences</a></li>
            <li><a [routerLink]="['/myaccount']">Account Settings</a></li>
            <li><a href="javascript:void(0);" (click)="logout()">Sign Out</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</header>

<!-- ======= Start Login Model Section ======= -->
<div class="modal fade login_model" id="loginModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="loginOverlay" *ngIf="normalLoggingIn"
        [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div>
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Welcome to Empire Realtors</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <!-- <app-flash></app-flash> -->
        <div class="module-nav">
          <ul class="zsg-tabs zsg-tabs_lg">
            <li id="login" class="zsg-tab_active">
              <a href="javascript:void(0);" (click)="showLogin()">Login</a>
            </li>
            <li id="newac">
              <a href="javascript:void(0);" (click)="showRegister()">New account</a>
            </li>
            <li id="forgotPasswordLi">
              <a href="javascript:void(0);">Forgot Password</a>
            </li>
          </ul>
        </div>

        <!-- =================== Login Form Starts =================== -->
        <div class="loginform">
          <form [formGroup]="loginform" (ngSubmit)="onLoginSubmit()">
            <div class="form-group">
              <input type="text" formControlName="emaillogin" class="form-control" placeholder="Email Address"
                [ngClass]="{ 'is-invalid': submitted && g.emaillogin.errors }" />
              <div *ngIf="submitted && g.emaillogin.errors" class="invalid-feedback">
                <div *ngIf="g.emaillogin.errors.required">Email is required</div>
                <div *ngIf="g.emaillogin.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <input type="password" formControlName="passwordlogin" class="form-control" placeholder="Enter Password"
                [ngClass]="{ 'is-invalid': submitted && g.passwordlogin.errors }" />
              <div *ngIf="submitted && g.passwordlogin.errors" class="invalid-feedback">
                <div *ngIf="g.passwordlogin.errors.required">Password is required</div>
                <div *ngIf="g.passwordlogin.errors.minlength">Password must be at least 6 characters</div>
                <div *ngIf="g.passwordlogin.errors.maxlength">Max Length 20 characters</div>
              </div>
            </div>
            <div class="form-group">
              <button class="bttn btn-block login-btn">Submit</button>
            </div>
          </form>
          <div class="forgot_div">
            <button class="forgot_btn" (click)="showForgotPassDiv()">Forgot Password?</button>
          </div>
        </div>
        <!-- =================== Login Form Ends =================== -->

        <!-- =================== Signup Form Starts =================== -->
        <div class="newform" [ngStyle]="{'display': 'none'}">
          <form [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()">
            <div class="form-group">
              <input type="text" formControlName="firstname" class="form-control" placeholder="First Name"
                [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
              <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                <div *ngIf="f.firstname.errors.required">First name is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" formControlName="lastname" class="form-control" placeholder="Last Name"
                [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
              <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                <div *ngIf="f.lastname.errors.required">Last name is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" [(ngModel)]="phone" formControlName="mobileNumber" class="form-control"
                [pattern]="mobNumberPattern" placeholder="Mobile Number"
                [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }" />
              <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
                <div
                  *ngIf="f.mobileNumber.errors.pattern || f.mobileNumber.errors.minlength || f.mobileNumber.errors.maxlength">
                  Please enter valid Mobile Number</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" formControlName="email" class="form-control" placeholder="Email Address"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <input type="password" formControlName="password" class="form-control" placeholder="Create Password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                <div *ngIf="f.password.errors.maxlength">Max Length 20 characters</div>
              </div>
            </div>
            <div class="form-group">
              <button class="bttn btn-block login-btn">Submit</button>
            </div>
          </form>
        </div>
        <!-- =================== Signup Form Ends =================== -->

        <!-- =================== Login Form Starts =================== -->
        <div class="forgotPasswordform">
          <form [formGroup]="forgotPasswordform" (ngSubmit)="onForgotPassSubmit()">
            <div class="form-group">
              <input type="text" formControlName="emailreset" class="form-control" placeholder="Email Address"
                [ngClass]="{ 'is-invalid': submitted && g.emailreset.errors }" />
              <div *ngIf="submitted && g.emailreset.errors" class="invalid-feedback">
                <div *ngIf="g.emailreset.errors.required">Email is required</div>
                <div *ngIf="g.emailreset.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <button class="bttn btn-block login-btn">Submit</button>
            </div>
          </form>
          <div class="forgot_div">
            <button class="forgot_btn" (click)="showLoginDiv()">Login</button>
          </div>
        </div>
        <!-- =================== Login Form Ends =================== -->

        <p class="social_login_section"><span>Or Connect us With</span></p>
        <div class="text-center social-pop-btn">
          <a href="javascript:void(0);" class="btn btn-google btn-block loginBtn loginBtn--google" #loginRef><i
              class="fa fa-google"></i> <span>Continue with Google</span></a>
          <a href="javascript:void(0);" class="btn btn-facebook btn-block" data-scope="public_profile, email"
             (click)="facebookLogin();"><i class="fa fa-facebook"></i> <span>Continue with Facebook</span></a>
          <!-- <a href="javascript:void(0);" class="btn btn-apple btn-block"><i class="fa fa-apple"></i> <span>Continue with Apple</span></a> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ======= End Login Model Section ======= -->
<!-- ======= Start Mobile Update Model Section ======= -->
<div class="modal fade login_model" id="mobileUpdateModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="mobileOverlay" *ngIf="mobileUpadating"
        [style.background-image]="'url(assets/img/web-image/loader.gif)'"></div>
      <!-- Modal Header -->
      <div class="modal-header">
        <h5 class="modal-title">Please enter your mobile number</h5>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <!-- <app-flash></app-flash> -->
        <form [formGroup]="mobileUpdateForm" (ngSubmit)="onMobileUpdateSubmit()">
          <div class="form-group">
            <input type="text" [(ngModel)]="mobilenumber" formControlName="mobileNumber" class="form-control"
              [pattern]="mobNumberPattern" placeholder="Mobile Number"
              [ngClass]="{ 'is-invalid': submitted && m.mobileNumber.errors }" />
            <div *ngIf="submitted && m.mobileNumber.errors" class="invalid-feedback">
              <div *ngIf="m.mobileNumber.errors.required">Mobile Number is required</div>
              <div
                *ngIf="m.mobileNumber.errors.pattern || m.mobileNumber.errors.minlength || m.mobileNumber.errors.maxlength">
                Please enter valid Mobile Number</div>
            </div>
          </div>
          <div class="form-group">
            <button class="bttn btn-block login-btn">Get OTP</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- ======= End Mobile Update Model Section ======= -->
<!-- ======= Start OTP Model Section ======= -->
<div class="modal fade login_model" id="otpModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="otpOverlay" *ngIf="otpUpadating" [style.background-image]="'url(assets/img/web-image/loader.gif)'">
      </div>
      <!-- Modal Header -->
      <div class="modal-header">
        <h5 class="modal-title">Please verify your mobile number</h5>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <!-- <app-flash></app-flash> -->
        <div class="otp_input">
          <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
        </div>
        <input type="hidden" id="userId" value="">
        <div class="form-group">
          <button class="bttn btn-block verify-btn" (click)="onVerifyOTP()">Verify OTP</button>
          <button class="bttn btn-block resend-btn" (click)="onResendOTP()">Resend OTP</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ======= End OTP Model Section ======= -->
