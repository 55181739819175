<div class="container-fluid" id="buyFranchiseDiv">
  <ng-container *ngIf="isAddingLead == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <div class="row">
    <div class="col-md-6">
      <div id="div_franch_img">
        <img class="loading_img" src="../../assets/img/franch_img.png"/>
      </div>
    </div>
    <div class="col-md-6">
      <div class="page-head">
        <h2 class="text-center">Buy Franchisee</h2>
        <!-- <app-flash></app-flash> -->
      </div>
      <div class="lead_generator_main" [formGroup]="leadForm">
        <div class="contact-info-outer">
          <div class="row d-flex">
            <div class="col-md-6">
              <div class="form-group">
                <input [(ngModel)]="first_name" formControlName="firstName" class="form-control" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && g.firstName.errors }" />
                <div *ngIf="submitted && g.firstName.errors" class="invalid-feedback">
                  <div *ngIf="g.firstName.errors.required">First Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input [(ngModel)]="last_name" formControlName="lastName" class="form-control" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && g.lastName.errors }" />
                <div *ngIf="submitted && g.lastName.errors" class="invalid-feedback">
                  <div *ngIf="g.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="email" [(ngModel)]="email" formControlName="profileEmail" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && g.profileEmail.errors }" />
                <div *ngIf="submitted && g.profileEmail.errors" class="invalid-feedback">
                  <div *ngIf="g.profileEmail.errors.required">Email is required</div>
                  <div *ngIf="g.profileEmail.errors.email">Email must be a valid email address</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input [(ngModel)]="phone" formControlName="mobileNumber" class="form-control" [pattern]="mobNumberPattern" placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && g.mobileNumber.errors }" />
                <div *ngIf="submitted && g.mobileNumber.errors" class="invalid-feedback">
                  <div *ngIf="g.mobileNumber.errors.required">Mobile Number is required</div>
                  <div *ngIf="g.mobileNumber.errors.pattern || g.mobileNumber.errors.minlength || g.mobileNumber.errors.maxlength">Please enter valid Mobile Number</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-label" for="">State</label>
                <select [(ngModel)]="state_eloc" formControlName="profilestate" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.profilestate.errors }" (change)="getStateCities($event.target.value)">
                  <option *ngFor="let stateOption of state_options" value="{{ stateOption.id }}">
                    {{ stateOption.title }}
                  </option>
                </select>
                <div *ngIf="submitted && g.profilestate.errors" class="invalid-feedback">
                  <div *ngIf="g.profilestate.errors.required">State is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-label" for="trans_type">City</label>
                <select [(ngModel)]="city_eloc" formControlName="profilecity" class="form-control" [ngClass]="{ 'is-invalid': submitted && g.profilecity.errors }">
                  <option *ngFor="let cityOption of city_options" value="{{ cityOption.id }}">
                    {{ cityOption.title }}
                  </option>
                </select>
                <div *ngIf="submitted && g.profilecity.errors" class="invalid-feedback">
                  <div *ngIf="g.profilecity.errors.required">City is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="save-button">
          <div class="row d-flex">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-6">
              <div class="form-group">
                <button type="submit" id="submitInquiry" class="btn btn-block login-btn" (click)="onBusinessLeadFormSubmit()">Send Your Details</button>
                <button type="button" class="btn btn-block login-btn loaderBtn" disabled style="display: none;">
                  <span class="spinSpan spinner-border" role="status"></span> Saving
                </button>
              </div>
            </div>
            <div class="col-md-3">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
