<div class="property_search_outer">
  <!--<div [ngClass]="{'padding-div':enableSticky,'properties_map_view':!enableSticky && !enableabsoluteMap}"
    id="properties_map_view">-->
  <div class="padding-div" id="properties_map_view">
    <div class="row mx-0">
      <div class="col-md-6 map-view-main-outer px-0">
        <div class="map-view">
          <span class="save-search-btn" (click)="openSaveSearchPopup()" *ngIf="isLoggedIn">Save Search</span>
          <div class="saveSearchPopup">
            <form [formGroup]="saveSearchForm" (ngSubmit)="onSaveSearch()">
              <div class="form-group">
                <input [(ngModel)]="searchnameval" formControlName="searchname" class="form-control"
                  placeholder="Search Name" [ngClass]="{ 'is-invalid': submitted && g.searchname.errors }" />
                <div *ngIf="submitted && g.searchname.errors" class="invalid-feedback">
                  <div *ngIf="g.searchname.errors.required">Search Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-block save-form-btn">Save Search</button>
                <span (click)="viewSaveSearched()" class="view_saved_searches">View Saved Searches </span>
              </div>
            </form>
          </div>
          <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
            [mapTypeControl]="true" [usePanning]="true" (mapReady)="mapReady($event)" (zoomChange)="zoomChange($event)">
            <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
              [label]="m.label" [iconUrl]="m.symbol"
              (mouseOver)="onMarkerMouseOver(infoWindow, gm)" [markerDraggable]="m.draggable">
              <agm-info-window [disableAutoPan]="false" #infoWindow>
                <div class="infoWindowOuter">
                  <div class="imgDiv" *ngIf='m.image_preview'
                    [style.background-image]="'url(\'' + m.image_preview + '\')'">
                    &nbsp;</div>
                  <div class="otherInfo">
                    <h4>{{ m.locality_title }}</h4>
                    <div >{{m.address}}</div>
                    <div class="dir_top">
                      <span class="link" (click)="getDiraction(m.lat,m.lng)">Get Directions</span>&nbsp;|&nbsp;<span class="link" (click)="openPropertyDetails(m.pid)">Visit Property Detail</span>
                    </div>

                    <div class="property_badge_map" >
                      <span 
                        class="property_badge_sell_map">{{m.status}}</span>
                    </div>
                    <!-- <p *ngIf='m.asking_price'><i class="fa fa-rupee"></i> {{ m.asking_price }}</p> -->
                  </div>
                </div>
              </agm-info-window>
            </agm-marker>
          </agm-map>
        </div>
      </div>
      <!-- <div class="col-md-5 col-sm-6 col-xs-6" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">-->
      <div class="col-md-6 px-0 content-view-main-outer" (click)="hideSearchBox()">
        <ng-container *ngIf="isLoading == true">
          <div class="loading_div">
            <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
          </div>
        </ng-container>
        <div class="content-view px-3">

          <div class="row">

            <div class="col-md-2 mt-1" *ngIf="propertytypeidSection!='4'">
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{selectedTypeOfProperty}}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" (click)="searchProject(4)">New</a>
                  <a class="dropdown-item" (click)="searchProject(3)" *ngIf="selectedTypeOfProperty=='Re-Sale'">Rent</a>
                  <a class="dropdown-item" (click)="searchProject(4,'resale')" *ngIf="selectedTypeOfProperty=='Rent'">Re-Sale</a>
                </div>
              </div>
            </div>

            <div class="col-md-10">
              <div class="input-group search_text_input" id="search_text">
                <input type="text" id="searchinput" class="form-control form-control-lg"
                  placeholder="Type Location or Project/Society or Keyword">
                <span class="input-group-append" (click)="searchProperty()"><i class="fa fa-search"></i></span>
              </div>
            </div>
          </div>
          <div class="col-md-12 new-filter">
            <label> Budget &nbsp;&nbsp;</label>
            <div class="form-group row mx-0">
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_budget_amount"
                  placeholder="Min" name="min_budget_amount" />
              </div>
              <label> to </label>
              <div class="col-md-3 px-2">
                <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_budget_amount"
                  placeholder="Max" name="max_budget_amount" />
              </div>
              <span id="clearBudgetFilter"><i class="fa fa-times" aria-hidden="true"
                  (click)="clearBudgetFilter()"></i></span>
              <div class="col-md-5 filterBtnDiv">
                <button type="button" id="filterBtn" class="btn btn-block login-btn mx-2 plus-minus collapsed"
                  (click)="showHideFilter()">Filters</button>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-9 prop_type_title">
              <h4 style="padding-top: 0px;">{{ name }}</h4>
            </div>
            <div class="col-3 iconview" style="padding-top: 0px;" *ngIf="propertyList.length>0 && moreFilter=='close'">
              <span class="sort_btn"><i class="fa fa-sort-amount-asc" aria-hidden="true"></i></span>
              <span [ngClass]="activeView ? 'grid_btn active_view' : 'grid_btn'" (click)="showgridviewfunction()">
                <i class="fa fa-th-large" aria-hidden="true"></i>
              </span>
              <span  [ngClass]="!activeView ? 'list_btn active_view' : 'list_btn'" (click)="showlistviewfunction()">
                <i class="fa fa-list" aria-hidden="true"></i>
              </span>
            </div>
            <div class="col-12 prop_cat_view">
              <span class="total_props" *ngIf="total_props_s">{{ total_props_s }}</span>
            </div>
          </div>

          <!-- More Filters Options Starts -->
          <div class="row collapse mt-3" id="moreFilter">
            <div class="col-md-12 filter-property-form" style="border-bottom: 1px solid #cbcbcb !important;">
              <div class="row mx-0">
                <div class="col-md-12">
                  <!-- Category -->
                  <div class="col-md-12 col-sm-6 multi-select">
                    <label><i class="fa fa-cubes fa_icon" aria-hidden="true"></i> &nbsp;<b> Property
                        Category</b></label>
                    <div class="form-group pl-3 pt-2">
                      <ul class="filter_drop_outer" style="height: auto;display: flex;">
                        <li class="pl-2" *ngFor="let singlett of propertyTypeCategory">
                          <label class="custom_checkbox">
                            {{ singlett.title }}
                            <input type="radio" id="tt{{ singlett.id }}" (change)="searchboxapply(0)"
                              value="{{ singlett.id }}" [(ngModel)]="propertytypeid"><span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Ends -->
                </div>

                <div class="col-md-6" id="bedroom_filter_div">
                  <!-- Bedrooms -->
                  <label><i class="fa fa-bed fa_icon" aria-hidden="true"></i> &nbsp;<b> Bedrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebedroom of bedroomList">
                      <div class="checkbox-div">
                        <input type="checkbox" name="beds[]" id="b{{ singlebedroom.id }}" class="f_box_check"
                          value="{{ singlebedroom.id }}" [(ngModel)]="singlebedroom.isChecked">
                        <label class="f_box_label" for="b{{ singlebedroom.id }}">{{ singlebedroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>
                <div class="col-md-6" id="bathroom_filter_div">
                  <!-- Bathrooms -->
                  <label><i class="fa fa-bath fa_icon" aria-hidden="true"></i> &nbsp;<b> Bathrooms</b></label>
                  <div class="form-group pl-2 mb-2 pb-3">
                    <ng-container *ngFor="let singlebathroom of bathroomList">
                      <div class="checkbox-div">
                        <input type="checkbox" name="baths[]" id="bath{{ singlebathroom.id }}" class="f_box_check"
                          value="{{ singlebathroom.id }}" [(ngModel)]="singlebathroom.isChecked">
                        <label class="f_box_label" for="bath{{ singlebathroom.id }}">{{ singlebathroom.text }}</label>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Ends -->
                </div>

                <!-- Property Facts -->
                <div class="col-md-12" id="prop_fact_div">
                  <label><i class="fa fa-area-chart fa_icon" aria-hidden="true"></i> &nbsp;<b> Property
                      Facts</b></label>
                  <div class="form-group">
                    <div class="row mx-0">
                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Min Carpet Area</label>
                        </div>
                        <div class="col-12" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="min_saleable_area"
                              placeholder="Min" name="min_saleable_area" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control"
                              name="min_saleable_area_unit" id="min_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-6 mx-0">
                        <div class="col-12 px-2">
                          <label>Max Carpet Area</label>
                        </div>
                        <div class="col-12 px-2" style="display: flex;padding-left: 0px;">
                          <div class="col-5 px-2">
                            <input class="p_filter_text_box f_property_budget form-control mb-2" id="max_saleable_area"
                              placeholder="Max" name="max_saleable_area" />
                          </div>
                          <div class="col-6 px-2">
                            <select class="p_filter_select_box f_property_facts form-control"
                              name="max_saleable_area_unit" id="max_saleable_area_unit">
                              <option selected value="0">Unit</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ends -->

                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i class="fa fa-tree fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Type</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_category"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Facing -->
                <div class="col-md-6 col-sm-6 multi-select" id="facing_div">
                  <label><i class="fa fa-compass fa_icon" aria-hidden="true"></i> &nbsp;<b> Facing</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_facing"></ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Ends -->

                <div class="col-md-6 col-sm-6 multi-select" id="furnish_div">
                  <label><i class="fa fa-cubes fa_icon" aria-hidden="true"></i> &nbsp;<b> Furnishing</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2" *ngFor="let furnish of furnishingList">
                        <label class="custom_checkbox" for="furnish{{ furnish.id }}">
                          {{ furnish.text }}
                          <input type="checkbox" name="furnishing" id="furnish{{ furnish.id }}" value="{{ furnish.id }}"
                            [(ngModel)]="furnish.isChecked"><span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 multi-select" id="extra_rooms_div">
                  <label><i class="fa fa-clock-o fa_icon" aria-hidden="true"></i> &nbsp;<b> Extra Rooms</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li>
                        <ul id="f_property_rooms"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Amenities -->
                <div class="col-md-6 col-sm-6 multi-select">
                  <label><i class="fa fa-tree fa_icon" aria-hidden="true"></i> &nbsp;<b> Amenities</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li class="pl-2">
                        <ul id="f_property_amenities"></ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Ends -->

                <div class="col-md-6 col-sm-6 multi-select" id="land_feature_div">
                  <label><i class="fa fa-clock-o fa_icon" aria-hidden="true"></i> &nbsp;<b> Land Features</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li>
                        <ul id="f_land_feature"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 multi-select" id="prop_age_div">
                  <label><i class="fa fa-clock-o fa_icon" aria-hidden="true"></i> &nbsp;<b> Property Age</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li>
                        <ul id="f_property_age"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 multi-select" id="land_category_div">
                  <label><i class="fa fa-clock-o fa_icon" aria-hidden="true"></i> &nbsp;<b> Land Category</b></label>
                  <div class="form-group pl-3 pt-2">
                    <ul class="filter_drop_outer">
                      <li>
                        <ul id="f_land_category"></ul>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-12 filter-foot">
              <div class="col-md-6">
                <button type="button" class="btn login-btn mx-2" (click)="clearFilter()">Reset</button>
              </div>
              <div class="col-md-6 text-right">
                <button type="button" class="btn login-btn mx-2" (click)="searchboxapply(1)">Apply Filters</button>
              </div>
            </div>

          </div>
          <!-- More Filters Options Ends -->

          <!-- Start Property Grid View -->
          <div class="row" id="property_grid_view">
            <ng-container *ngFor="let item of propertyList | paginate : pageConfig">
              <div class="col-sm-6 d-flex single_property_grid"
                *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                <div class="card">
                  <div (click)="openPropertyDetails(item.id)"
                    [style.background-image]="'url(\'' + item.image_preview + '\')'" class="grid_image_view">
                    <div class="property_badge top_left" *ngIf="item.prop_transaction_type">
                      <span class="property_badge_sell" *ngIf="item.is_coming_soon == 1">Coming Soon</span>
                      <span *ngIf="item.property_status_id && item.is_coming_soon == 0"
                        class="property_badge_sell">{{item.prop_status.title}}</span>
                    </div>
                    <div class="property_badge top_right favoriteIcon_{{item.id}}"
                      [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }" *ngIf="item.prop_transaction_type"
                      (click)="makeFavorite(item.id, item.is_favorited, item.franchisee_id);$event.stopPropagation()">
                      <i id="i_favIcon_{{item.id}}" class="fa fa-heart fav_white_color"></i>
                    </div>
                  </div>
                  <div (click)="openPropertyDetails(item.id)" class="card-body">
                    <p class=" card-title card_text_title">
                      {{(item.name || (item.locality_title + ' '+item.city_title ? item.city_title : ''))}}
                      <!-- {{item.locality_title}} {{(item.city_title ? item.city_title : '')}} -->
                    </p>
                    <h6 class="card-text card_text_title_price"><i class="fa fa-rupee"></i>{{item.asking_price_title}}
                    </h6>
                    <p class="card-text">
                      <span class="pro_spec" *ngIf="item.total_showers"> <i class="fa fa-bath"></i>
                        {{item.total_showers}} Bath </span>
                      <span class="pro_spec" *ngIf="item.total_bedrooms"> <i class="fa fa-bed"></i>
                        {{item.total_bedrooms}} Bed </span>
                      <span class="pro_spec" *ngIf="item.saleable_area"> <i class="fa fa-check-square"></i>
                        {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 text-right" *ngIf="isPropperty == true">
              <pagination-controls id="property_grid" (pageChange)="pageChange($event, 10)" class="my-pagination"
                maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- End Property Grid View -->
          <!-- Start Property List View -->
          <div class="row" id="property_list_view">
            <ng-container *ngFor="let item of propertyList | paginate : pageConfig">
              <div class="col-md-12 single_property_list"
                *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                <div class="row no-gutters">
                  <div class="col-md-12">
                    <div class="card-body" (click)="openPropertyDetails(item.id)">
                      <div class="row">


                        <div class="col-md-6" style="padding-left: 0px; padding-right: 0px;">
                          <p class="card-text card_text_title" style="font-weight: 600;">
                            {{(item.name || (item.locality_title + ' '+item.city_title ? item.city_title : ''))}}
                          </p>
                        </div>
                        <div class="col-md-2" style="padding-left: 0px; padding-right: 0px;">
                          <h6 class="card-title card_text_title_price"><i
                              class="fa fa-rupee"></i>{{item.asking_price_title}}</h6>
                        </div>
                        <div class="col-md-4">
                          <p class="card-text">
                            <span class="pro_spec" *ngIf="item.total_showers"> <i class="fa fa-bath"></i>
                              {{item.total_showers}} Bath </span>
                            <span class="pro_spec" *ngIf="item.total_bedrooms"> <i class="fa fa-bed"></i>
                              {{item.total_bedrooms}} Bed </span>
                            <span class="pro_spec" *ngIf="item.saleable_area"> <i class="fa fa-check-square"></i>
                              {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-12 text-right" *ngIf="isPropperty == true">
              <pagination-controls id="property_grid" (pageChange)="pageChange($event, 10)" class="my-pagination"
                maxSize="9"></pagination-controls>
            </div>
          </div>
          <!-- End Property List View -->

          <ng-container *ngIf="isPropperty == false && moreFilter === 'close' && isLoading == false">
            <div id="lead_generator_div" class="lead_generator_main" [formGroup]="leadForm">
              <div class="contact-info-outer">
                <div class="row d-flex">
                  <div class="col-md-12">
                    <!-- <app-flash></app-flash> -->
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="first_name" formControlName="firstName" class="form-control"
                        placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && l.firstName.errors }" />
                      <div *ngIf="submitted && l.firstName.errors" class="invalid-feedback">
                        <div *ngIf="l.firstName.errors.required">First Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="last_name" formControlName="lastName" class="form-control"
                        placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && l.lastName.errors }" />
                      <div *ngIf="submitted && l.lastName.errors" class="invalid-feedback">
                        <div *ngIf="l.lastName.errors.required">Last Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="email" [(ngModel)]="email" formControlName="profileEmail" class="form-control"
                        placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && l.profileEmail.errors }" />
                      <div *ngIf="submitted && l.profileEmail.errors" class="invalid-feedback">
                        <div *ngIf="l.profileEmail.errors.required">Email is required</div>
                        <div *ngIf="l.profileEmail.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input [(ngModel)]="phone" formControlName="mobileNumber" class="form-control"
                        [pattern]="mobNumberPattern" placeholder="Mobile Number"
                        [ngClass]="{ 'is-invalid': submitted && l.mobileNumber.errors }" />
                      <div *ngIf="submitted && l.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="l.mobileNumber.errors.required">Mobile Number is required</div>
                        <div
                          *ngIf="l.mobileNumber.errors.pattern || l.mobileNumber.errors.minlength || l.mobileNumber.errors.maxlength">
                          Please enter valid Mobile Number</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="save-button">
                <div class="row d-flex">
                  <div class="col-md-3">&nbsp;</div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button type="submit" id="submitLead" class="btn btn-block login-btn"
                        (click)="onLeadFormSubmit()"> Send Your Details</button>
                      <button type="button" class="btn btn-block login-btn loaderBtn" disabled style="display: none;">
                        <span class="spinSpan spinner-border" role="status"></span> Saving
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3">&nbsp;</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <app-property-list-footer></app-property-list-footer>

      </div>
    </div>

    <ng-container *ngIf="isPropperty == false && isLoading == false && suggestedProperties.length > 0"
      (click)="hideSearchBox()">
      <div class="row" style="display: none;">
        <div class="col-md-12 text-center">
          <hr />
          <h4 id="suggested_title">Properties nearby your entered location</h4>
        </div>
      </div>
      <div class="row" style="display: none;">
        <div class="col-md-12">
          <div *ngIf="suggestedProperties.length > 0" class="" id="suggested_property">
            <div class="row ss_property_view" id="suggested_property_view">
              <ng-container *ngFor="let item of suggestedProperties">
                <div class="d-flex single_property_view"
                  *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                  <div class="card">
                    <div class="grid_image_view" [style.background-image]="'url(\'' + item.image_preview + '\')'">
                      <div class="property_badge top_left" *ngIf="item.prop_transaction_type">
                        <span class="property_badge_sell">{{item.prop_transaction_type.title}}</span>
                      </div>
                    </div>
                    <div class="card-body" (click)="openPropertyDetails(item.id)">
                      <h5 class="card-title"><i class="fa fa-rupee"></i>{{ item.asking_price_title}}</h5>
                      <p class="card-text">
                        {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                      </p>
                      <p class="card-text">
                        <span class="pro_spec" *ngIf="item.total_washrooms"> <i class="fa fa-bath"></i>
                          {{item.total_washrooms}} Bath </span>
                        <span class="pro_spec" *ngIf="item.total_bedrooms"> <i class="fa fa-bed"></i>
                          {{item.total_bedrooms}} Bed </span>
                        <span class="pro_spec" *ngIf="item.saleable_area"> <i class="fa fa-check-square"></i>
                          {{item.saleable_area}} {{(item.sale_area_unit ? item.sale_area_unit.title : '')}} </span>
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>

<div class="change-view">
  <a href="javascript:void(0);" class="show-map" (click)="toggleMapView()"><i class="fa fa-map"></i> &nbsp; Map</a>
  <a href="javascript:void(0);" class="show-list" (click)="toggleListView()"><i class="fa fa-list"></i> &nbsp; List</a>
</div>