/* tslint:disable:quotemark */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashService } from '../../_services/FlashService.service';
import { ApiService } from './../../_services/api.service';
import { API_ENDPOINTS } from 'src/app/global';
declare var $: any;

@Component({
  selector: 'app-settings',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  review_icon: string = '';
  yourfavorites: string = '';
  formSubmiting = false;
  isLoading: boolean = true;

  communicationTypesList = [];
  favoritesFreqList = [];
  favEmailTypesList = [];
  savedSearchEmailTypesList = [];
  recommendedList = [];
  searchInsightsList = [];
  homeReportsList = [];
  lfenList = [];
  savedSearchList: any = [];
  dashboardType = 'buyer';
  constructor(private ApiService: ApiService,  private router: Router, private route: ActivatedRoute, private FlashService: FlashService, private title: Title, private formBuilder: FormBuilder) {
    this.review_icon = API_ENDPOINTS.REVIEW_ICON;
  }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if(!this.ApiService.loginCheck()) {
      this.router.navigate(['/']);
    }
    this.route.params.subscribe(routeParams => {
      if(this.route.snapshot.queryParams.type !== undefined) {
        this.dashboardType = this.route.snapshot.queryParams.type;
      }
    this.getEmialSettings();

      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  onSaveSearchClicked(json_string) {
    var json_obj = JSON.parse(json_string);
    var data = {};
    $.each(json_obj, function(index, value) {
      data[index] = value;
    });
    this.router.navigate(['property'], {queryParams: data});
  }

  changeFavorites(id, value): void  {
    value = value + 1;
    this.onEmialSettingsSave(id, value);
  }

  changeFavEmailTypes(id, value): void  {
    if(value) {
      value = 1;
    } else {
      value = 0;
    }
    this.onEmialSettingsSave(id, value);
  }

  changeSavedSearchEmailTypes(id, value): void  {
    if(value) {
      value = 1;
    } else {
      value = 0;
    }
    this.onEmialSettingsSave(id, value);
  }

  changeRecommended(id, value): void  {
    if(value) {
      value = 1;
    } else {
      value = 0;
    }
    this.onEmialSettingsSave(id, value);
  }

  changeSearchInsights(id, value): void  {
    if(value) {
      value = 1;
    } else {
      value = 0;
    }
    this.onEmialSettingsSave(id, value);
  }

  changeHomeReports(id, value): void  {
    if(value) {
      value = 1;
    } else {
      value = 0;
    }
    this.onEmialSettingsSave(id, value);
  }

  changeLFEN(id, value): void  {
    if(value) {
      value = 1;
    } else {
      value = 0;
    }
    this.onEmialSettingsSave(id, value);
  }

  changeCommunicationTypes(id, value): void  {
    if(value) {
      value = 1;
    } else {
      value = 0;
    }
    this.onEmialSettingsSave(id, value);
  }

  onEmialSettingsSave(id, value): void {
    this.formSubmiting = true;
    var form_data = new FormData();
    form_data.append('email_setting_id', id);
    form_data.append('setting_value', value);
    this.ApiService.postRequest(API_ENDPOINTS.API_SET_EMAIL_PREF, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.FlashService.success(apiResult.data);
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.formSubmiting = false;
    });
  }

  getEmialSettings(): void {
    var form_data = new FormData();
    this.ApiService.postRequest(API_ENDPOINTS.API_GET_EMAIL_PREF, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.isLoading = false;
        this.favoritesFreqList = apiResult.data.favorites;
        this.favEmailTypesList = apiResult.data.favorite_email_type;
        this.savedSearchEmailTypesList = apiResult.data.saved_search_email_type;
        this.recommendedList = apiResult.data.recommended_for_you;
        this.searchInsightsList = apiResult.data.home_search_insights;
        this.homeReportsList = apiResult.data.home_reports;
        this.lfenList = apiResult.data.life_style_empire_news;
        this.communicationTypesList = apiResult.data.contact_preference;
        this.savedSearchList = apiResult.data.saved_search;
      }
    });
  }

  changeSavedSearchSettings(id, value) {
    this.formSubmiting = true;
    var form_data = new FormData();
    form_data.append('id', id);
    form_data.append('frequency_status', value);
    this.ApiService.postRequest(API_ENDPOINTS.API_UPDATE_SAVE_SEARCH, form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        this.FlashService.success(apiResult.data);
      } else {
        this.FlashService.error(apiResult.error_code);
      }
      this.formSubmiting = false;
    });
  }

  changeHeaderClass(): void  {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Dashboard » Email Preferences');
  }
}
