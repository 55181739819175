import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/_services/confirmation-dialog.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() showMessage: boolean = false
  subscription: Subscription
  message: string
  constructor(private confirmationService: ConfirmationDialogService) { }

  ngOnInit(): void {
    this.subscription = this.confirmationService.getMessage().subscribe(res => {
      if (res) {
        this.showMessage = res.type
        this.message = res.text;

        setTimeout(() => {
          this.message=''
        }, 5000);
      }
    });
  }

}
