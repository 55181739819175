/* tslint:disable:no-string-literal prefer-const no-var-keyword object-literal-shorthand max-line-length no-shadowed-variable variable-name no-inferrable-types */
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_BASE_URL } from '../../global';
import { Meta, Title } from '@angular/platform-browser';
import { FlashService } from '../../_services/FlashService.service';

declare var $: any;

@Component({
  selector: 'app-rent-lease-property',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './rent-lease-property.component.html',
  styleUrls: ['./rent-lease-property.component.scss']
})
export class RentLeasePropertyComponent implements OnInit {

  transaction_type_id: string = '1';
  sellingSearchInput;
  area_title: string = '';
  area_lat: string = '';
  area_lng: string = '';
  suitable_for_id: string = '';

  constructor(private ref: ChangeDetectorRef, private FlashService: FlashService, private router: Router,  private route: ActivatedRoute, private ApiService: ApiService, private title: Title, private meta: Meta, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    window.scroll(0, 0);
    if (this.route.snapshot.queryParams.suitable_for_id !== undefined) {
      this.suitable_for_id = this.route.snapshot.queryParams.suitable_for_id;
    }
    if (this.route.snapshot.queryParams.prop_type_id !== undefined) {
      this.transaction_type_id = this.route.snapshot.queryParams.prop_type_id;
    }
    var componentObj = this;
    setTimeout (() => {
      this.sellingSearchInput = $('#sellingSearchInput');
      this.sellingSearchInput.typeahead(null, {
        displayKey: 'placeNameFull',
        source: (search, sync, async) => {
          if (search.length >= 2) {
            return $.ajax({
              type: 'GET',
              dataType: 'json',
              url: API_BASE_URL + '/master/getLocality',
              data: {is_web_api: 1, search: search},
              success: (response) => {
                response = response.data;
                return async(response);
              }
            });
          }
        }
      });
      this.sellingSearchInput.on('typeahead:selected', (e, datalocitem) => {
        componentObj.area_title = datalocitem.placeName;
        componentObj.area_lat = datalocitem.latitude;
        componentObj.area_lng = datalocitem.longitude;
      });

      this.changeHeaderClass();
    }, 200);
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $('#imgHeadLogo').attr('src', 'assets/img/empire_logo.png');
    this.title.setTitle('Empire Realtors » Rent/Lease');
  }

  onBackToHome() {
    this.router.navigate(['/']);
  }

  searchProperty(page) {
    var data = {};
    data['transaction_type_id'] = 3;
    data['property_type_id'] = this.transaction_type_id;
    data['suitable_for_id'] = this.suitable_for_id;
    const userCurrCity = localStorage.getItem('user_curr_city');
    if (userCurrCity && userCurrCity !== 'undefined' && userCurrCity !== undefined) {
      data['locality_elocs'] = userCurrCity;
    } else {
      data['locality_elocs'] = 'Vadodara';
    }
    this.router.navigate(['/property'], {queryParams: data});
  }

  selPropTypeDiv(divType) {
    $('#residential_div').removeClass('bg_white');
    $('#commercial_div').removeClass('bg_white');
    if (divType === 'residential') {
      $('#residential_div').addClass('bg_white');
    } else {
      $('#commercial_div').addClass('bg_white');
    }
  }
}
