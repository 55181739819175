<div class="property_search_outer">
  <ng-container *ngIf="isLoading == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>

  <div class="container-fluid" id="rpcBanner" (click)="hideSearchBox()">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2 class="banner_title">READY PASSION CARNIVAL</h2>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-3" (click)="hideSearchBox()">
    <div class="property_search_top">
      <div class="row">
        <div class="col-md-5">
          <div class="input-group search_text_input">
            <input type="text" id="searchinput" class="form-control form-control-lg"
              placeholder="Type Location or Project/Society or Keyword">
            <span class="input-group-append"><i class="fa fa-search"></i></span>
          </div>
        </div>
        <div class="col-md-7">
          <div class="p_filter_outer">
            <ul class="p_filter_main">

              <li class="p_filter_drop property_type">
                <div class="p_filter_drop_main_box">
                  <i class="fa fa-home fa_icon" aria-hidden="true"></i>
                  Property Type
                </div>
                <ul class="filter_list_outer" id="f_property_type">
                  <li>
                    <ul id="f_property_category"></ul>
                  </li>
                </ul>
              </li>

              <li class="p_filter_drop property_type">
                <div class="p_filter_drop_main_box">
                  <i class="fa fa-usd fa_icon" aria-hidden="true"></i>
                  Discount
                </div>
                <ul class="filter_list_outer" id="f_property_discount">
                  <li *ngFor="let dis of discountList">
                    <label class="custom_checkbox" for="discount{{ dis.id }}">
                      {{ dis.text }}
                      <input type="radio" name="property_discount" id="discount{{ dis.id }}" (change)="searchboxapply()"
                        value="{{ dis.id }}" [(ngModel)]="discount"><span class="checkmark"></span>
                      <!--<input type="radio" name="property_discount" id="discount{{ dis.id }}" value="{{ dis.id }}"
                        [(ngModel)]="discount"><span class="checkmark"></span>-->
                    </label>
                  </li>
                </ul>
              </li>

              <!--<li class="p_filter_drop drop_amenities">
                <button type="button" class="btn btn-block login-btn mx-2" (click)="searchboxapply()">Go</button>
              </li>

              <li class="p_filter_drop drop_amenities">
                <button type="button" class="btn btn-block login-btn mx-2" (click)="clearFilter()">Reset</button>
              </li>-->

              <li class="p_filter_drop drop_amenities">
                <button type="button" id="filterBtn" class="btn btn-block login-btn mx-2 plus-minus collapsed"
                  (click)="showHideFilter()">Filters</button>
                <!--<button type="button" id="filterBtn" class="btn btn-block login-btn mx-2 plus-minus collapsed" (click)="openFilterPropertyModal()">Filters</button>-->
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid collapse mt-3" id="moreFilter" (click)="hideSearchBox()">
    <div class="row">
      <div class="col-md-12">
        <div class="p_filter_outer">
          <ul class="p_filter_main">

            <!-- Beds -->
            <li class="p_filter_drop drop_bed_bath" *ngIf="propertytypeid == '1' || propertytypeid == ''">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-bed fa_icon" aria-hidden="true"></i>
                Beds
              </div>
              <ul class="filter_drop_outer">
                <li>
                  <legend>Bedrooms</legend>
                  <ul class="filter_box_list">
                    <li class="f_box_lay" *ngFor="let singlebedroom of bedroomList">
                      <input type="checkbox" name="beds[]" id="b{{ singlebedroom.id }}" class="f_box_check"
                        (change)="searchboxapply()" value="{{ singlebedroom.id }}"
                        [(ngModel)]="singlebedroom.isChecked">
                      <!--<input type="checkbox" name="beds[]" id="b{{ singlebedroom.id }}" class="f_box_check"
                        value="{{ singlebedroom.id }}" [(ngModel)]="singlebedroom.isChecked">-->
                      <label class="f_box_label" for="b{{ singlebedroom.id }}">{{ singlebedroom.text }}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- Beds Ends -->

            <!-- Bath -->
            <li class="p_filter_drop drop_bed_bath" *ngIf="propertytypeid == '1' || propertytypeid == ''">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-bath fa_icon" aria-hidden="true"></i>
                Baths
              </div>
              <ul class="filter_drop_outer">
                <li>
                  <legend>Bathrooms</legend>
                  <ul class="filter_box_list">
                    <li class="f_box_lay" *ngFor="let singlebathroom of bathroomList">
                      <input type="checkbox" name="baths[]" id="bath{{ singlebathroom.id }}" class="f_box_check"
                        (change)="searchboxapply()" value="{{ singlebathroom.id }}"
                        [(ngModel)]="singlebathroom.isChecked">
                      <!--<input type="checkbox" name="baths[]" id="bath{{ singlebathroom.id }}" class="f_box_check"
                        value="{{ singlebathroom.id }}" [(ngModel)]="singlebathroom.isChecked">-->
                      <label class="f_box_label" for="bath{{ singlebathroom.id }}">{{ singlebathroom.text }}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- Bath Ends -->

            <!-- Price -->
            <li class="p_filter_drop property_facts">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-money fa_icon" aria-hidden="true"></i>
                Price
              </div>
              <ul class="filter_list_outer">
                <li>
                  <div class="row">
                    <div class="col-md-6">
                      <!--<legend>Min Budget</legend> -->
                      <div class="row">
                        <div class="col-md-12">
                          <input class="p_filter_text_box f_property_budget" id="min_budget_amount"
                            placeholder="Min Budget" name="min_budget_amount" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <!--<legend>Max Budget</legend> -->
                      <div class="row">
                        <div class="col-md-12">
                          <input class="p_filter_text_box f_property_budget" id="max_budget_amount"
                            placeholder="Max Budget" name="max_budget_amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <!-- Price Ends -->

            <!-- Property Facts -->
            <li class="p_filter_drop property_facts">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-area-chart fa_icon" aria-hidden="true"></i>
                Property Facts
              </div>
              <ul class="filter_list_outer">
                <li>
                  <div class="row">
                    <div class="col-md-6">
                      <legend>Min Carpet Area</legend>
                      <div class="row">
                        <div class="col-md-5">
                          <input type="text" (change)="searchboxapply()" placeholder="0" name="min_saleable_area"
                            id="min_saleable_area" class="p_filter_text_box" />
                          <!--<input type="text" placeholder="0" name="min_saleable_area" id="min_saleable_area" class="p_filter_text_box" />-->
                        </div>
                        <div class="col-md-7">
                          <select class="p_filter_select_box f_property_facts" (change)="searchboxapply()"
                            name="min_saleable_area_unit" id="min_saleable_area_unit">
                            <option selected value="0">Unit</option>
                          </select>
                          <!-- <select class="p_filter_select_box f_property_facts" name="min_saleable_area_unit" id="min_saleable_area_unit">
                              <option selected value="0">Unit</option>
                          </select> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <legend>Max Carpet Area</legend>
                      <div class="row">
                        <div class="col-md-5">
                          <input type="text" (change)="searchboxapply()" placeholder="0" name="max_saleable_area"
                            id="max_saleable_area" class="p_filter_text_box" />
                          <!-- <input type="text" placeholder="0" name="max_saleable_area" id="max_saleable_area" class="p_filter_text_box" /> -->
                        </div>
                        <div class="col-md-7">
                          <select class="p_filter_select_box f_property_facts" (change)="searchboxapply()"
                            name="max_saleable_area_unit" id="max_saleable_area_unit">
                            <option selected value="0">Unit</option>
                          </select>
                          <!-- <select class="p_filter_select_box f_property_facts" name="max_saleable_area_unit" id="max_saleable_area_unit">
                            <option selected value="0">Unit</option>
                          </select> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <!-- Property Facts Ends -->

            <!-- Amenities -->
            <li class="p_filter_drop drop_amenities">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-tree fa_icon" aria-hidden="true"></i>
                Amenities
              </div>
              <ul class="filter_drop_outer">
                <li>
                  <ul id="f_property_amenities"></ul>
                </li>
              </ul>
            </li>
            <!-- Amenities Ends -->

            <!-- Facing -->
            <li class="p_filter_drop drop_facing">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-compass fa_icon" aria-hidden="true"></i>
                Facing
              </div>
              <ul class="filter_drop_outer">
                <li>
                  <ul id="f_property_facing"></ul>
                </li>
              </ul>
            </li>
            <!-- Facing Ends -->

            <!-- Furnishing -->
            <li class="p_filter_drop drop_facing" *ngIf="page == 'resale'">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-cubes fa_icon" aria-hidden="true"></i>
                Furnishing
              </div>
              <ul class="filter_drop_outer">
                <li *ngFor="let furnish of furnishingList">
                  <label class="custom_checkbox" for="furnish{{ furnish.id }}">
                    {{ furnish.text }}
                    <input type="checkbox" name="furnishing" id="furnish{{ furnish.id }}" (change)="searchboxapply()"
                      value="{{ furnish.id }}" [(ngModel)]="furnish.isChecked"><span class="checkmark"></span>
                    <!--<input type="checkbox" name="furnishing" id="furnish{{ furnish.id }}" value="{{ furnish.id }}"
                      [(ngModel)]="furnish.isChecked"><span class="checkmark"></span>-->
                  </label>
                </li>
              </ul>
            </li>
            <!-- Facing Ends -->

            <!-- Property Age -->
            <li class="p_filter_drop drop_prop_age" *ngIf="page == 'resale'">
              <div class="p_filter_drop_main_box">
                <i class="fa fa-clock-o fa_icon" aria-hidden="true"></i>
                Property Age
              </div>
              <ul class="filter_drop_outer">
                <li>
                  <ul id="f_property_age"></ul>
                </li>
              </ul>
            </li>
            <!-- Property Age Ends -->

          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="properties_map_view">
    <div class="container-fluid ">
      <div class="row">
        <div class="col-md-7 col-sm-6 col-xs-6 map-view-main-outer">
          <div
            [ngClass]="{'sticky-div':enableSticky,'absoluteMap':enableabsoluteMap, 'col-md-12':!enableSticky && !enableabsoluteMap}"
            class="col-md-7">
            <span class="save-search-btn" (click)="openSaveSearchPopup()" *ngIf="isLoggedIn">Save Search</span>
            <div class="saveSearchPopup" >
              <form [formGroup]="saveSearchForm" (ngSubmit)="onSaveSearch()">
                <div class="form-group">
                  <input [(ngModel)]="searchnameval" formControlName="searchname" class="form-control"
                    placeholder="Search Name" [ngClass]="{ 'is-invalid': submitted && g.searchname.errors }" />
                  <div *ngIf="submitted && g.searchname.errors" class="invalid-feedback">
                    <div *ngIf="g.searchname.errors.required">Search Name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-block save-form-btn">Save Search</button>
                  <span class="view_saved_searches" [routerLink]="['/savedsearch']">View Saved Searches</span>
                </div>
              </form>
            </div>
            <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
              [zoomControl]="false" [mapTypeControl]="true" [usePanning]="true" (mapReady)="mapReady($event)"
              (zoomChange)="zoomChange($event)">
              <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
                [label]="m.label" [iconUrl]="m.symbol" (markerClick)="clickedMarker(m.pid)"
                (mouseOver)="onMarkerMouseOver(infoWindow, gm)" [markerDraggable]="m.draggable">
                <agm-info-window [disableAutoPan]="false" #infoWindow>
                  <div class="infoWindowOuter">
                    <div class="imgDiv" [style.background-image]="'url(' + m.image_preview + ')'">&nbsp;</div>
                    <div class="otherInfo">
                      <h4>{{ m.locality_title }}</h4>
                      <p><i class="fa fa-rupee"></i> {{ m.asking_price }}</p>
                    </div>
                  </div>
                </agm-info-window>
              </agm-marker>
            </agm-map>
          </div>
        </div>

        <div class="col-md-5 col-sm-6 col-xs-6" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50" (scrolled)="onScroll()" (click)="hideSearchBox()">
          <div class="row">
            <!--<div class="col-md-6 text-center">
              <button  class="ml-3 btn btn-secondary secndysize" type="button" (click)="showProject()">Project</button>
            </div>
            <div class="col-md-6 text-center">
              <button  class="ml-3 btn btn-secondary secndysize" type="button" (click)="showProperty()">Property</button>
            </div> -->
            <div class="col-9 prop_type_title">
              <h4 style="padding-top: 0px;">{{ name }}</h4>
            </div>
            <div class="col-3 iconview" style="padding-top: 0px;">
              <span class="sort_btn"><i class="fa fa-sort-amount-asc" aria-hidden="true"></i></span>
              <span class="grid_btn active_view" (click)="showgridviewfunction()"><i class="fa fa-th-large"
                  aria-hidden="true"></i></span>
              <span class="list_btn" (click)="showlistviewfunction()"><i class="fa fa-list"
                  aria-hidden="true"></i></span>
            </div>
            <div class="col-12 prop_cat_view">
              <span class="total_props" *ngIf="total_props_s">{{ total_props_s }}</span>
            </div>
          </div>

          <!-- Start Project Grid View -->
          <div class="row" id="project_grid_view" *ngIf="viewProject">
            <ng-container *ngFor="let item of projectsList">
              <div class="col-md-6 d-flex single_property_grid" *ngIf="item.name">
                <div class="card">
                  <div class="grid_image_view" [style.background-image]="'url(' + item.image_preview + ')'">
                    <div class="property_badge top_left">
                      <div class="property_badge top_left favoriteIcon_{{item.project_unit_id}}"
                        [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }"
                        (click)="makeProjectFav(item.id, item.project_unit_id, item.is_favorited)">
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: white"></i>
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                      </div>
                    </div>
                    <div class="property_badge top_right" *ngIf="item.rpcf_percentage">
                      <div class="ribbon ribbon-top-right {{item.ribbion_color}}"><span>{{item.rpcf_percentage}}%
                          OFF</span></div>
                    </div>
                  </div>
                  <div class="card-body" [routerLink]="['/carnival-project-details', item.project_unit_id ]">
                    <!--<h5 class="card-title">{{item.name}}</h5> -->
                    <h5 class="card-title"
                      *ngIf="item.rpcf_price && item.show_unit_price_on_website == 1; else askingPrice">
                      <i class="fa fa-rupee" style="color: #4b4a4a;"></i> <span
                        style="padding-right: 10px;color: #4b4a4a;">{{item.rpcf_price}}</span>
                      <s> <span style="font-size: 17px;"><i class="fa fa-rupee"
                            style="font-size: 17px;"></i>{{item.base_price}} </span></s>
                    </h5>
                    <ng-template #askingPrice>
                      <h5 class="card-title" *ngIf="item.show_unit_price_on_website == 1; else contactUs"><i
                          class="fa fa-rupee"></i>{{item.base_price}}</h5>
                    </ng-template>
                    <ng-template #contactUs>
                      <h5 class="card-title">Contact Us</h5>
                    </ng-template>
                    <p class="card-text">
                      {{item.name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                    </p>
                    <p class="card-text" style="padding-top: 10px;border-top: 1px solid rgb(0 0 0 / 51%);">
                      <span class="pro_spec" *ngIf="item.total_bedrooms"> {{item.total_bedrooms}} <i
                          class="fa fa-bed"></i> </span>
                      <span class="pro_spec" *ngIf="item.total_showers"> {{item.total_showers}} <i
                          class="fa fa-bath"></i> </span>
                      <span class="property_badge_sell">For Sale</span>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- Ends Project Grid View -->
          <!-- Start Project List View -->
          <div class="row" id="project_list_view">
            <ng-container *ngFor="let item of projectsList">
              <div class="col-md-12 single_property_list" *ngIf="item.name">
                <div class="row no-gutters">
                  <div class="col-md-6">
                    <div class="list_image_view" [style.background-image]="'url(' + item.image_preview + ')'">
                      <div class="property_badge top_left" *ngIf="item.rpcf_percentage">
                        <div class="ribbon ribbon-top-left {{item.ribbion_color}}"><span>{{item.rpcf_percentage}}%
                            OFF</span></div>
                      </div>
                      <div class="property_badge top_right favoriteIcon_{{item.project_unit_id}}"
                        [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }" *ngIf="item.prop_transaction_type"
                        (click)="makeProjectFav(item.id, item.project_unit_id, item.is_favorited)">
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: #FFFFFF;"></i>
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-body" [routerLink]="['/carnival-project-details', item.id ]">
                      <h5 class="card-title"
                        *ngIf="item.rpcf_price && item.show_unit_price_on_website == 1; else askingPrice">
                        <i class="fa fa-rupee" style="color: #4b4a4a;"></i> <span
                          style="padding-right: 10px;color: #4b4a4a;">{{item.rpcf_price}}</span>
                        <s> <span style="font-size: 17px;"><i class="fa fa-rupee"
                              style="font-size: 17px;"></i>{{item.asking_price}} </span></s>
                      </h5>
                      <ng-template #askingPrice>
                        <h5 class="card-title" style="color: #4b4a4a;" *ngIf="item.show_unit_price_on_website == 1"><i
                            class="fa fa-rupee"></i>{{item.asking_price}}</h5>
                      </ng-template>
                      <p class="card-text">
                        {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                      </p>
                      <p class="card-text">
                        <span class="pro_spec" *ngIf="item.total_bedrooms"> {{item.total_bedrooms}} <i
                            class="fa fa-bed"></i> </span>
                        <span class="pro_spec" *ngIf="item.total_showers"> {{item.total_showers}} <i
                            class="fa fa-bath"></i> </span>
                        <span class="pro_spec" *ngIf="item.saleable_area"> {{item.saleable_area}} {{(item.sale_area_unit
                          ? item.sale_area_unit.title : '')}} <i class="fa fa-check-square"></i> </span>
                        <span class="property_badge_sell">Fore Sale</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- Ends Project List View -->

          <!-- Start Property Grid View -->
          <div class="row" id="property_grid_view" *ngIf="viewProperty">
            <ng-container *ngFor="let item of propertyList">
              <div class="col-md-6 d-flex single_property_grid"
                *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                <div class="card">
                  <div class="grid_image_view" [style.background-image]="'url(' + item.image_preview + ')'">
                    <div class="property_badge top_left favoriteIcon_{{item.id}}"
                      [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }" *ngIf="item.prop_transaction_type"
                      (click)="makeFavorite(item.id, item.is_favorited, page)">
                      <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: #FFFFFF;"></i>
                      <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                    </div>
                    <div class="property_badge top_right" *ngIf="item.rpcf_percentage">
                      <div class="ribbon ribbon-top-right {{item.ribbion_color}}"><span
                          class="">{{item.rpcf_percentage}}% OFF</span></div>
                    </div>
                  </div>
                  <div class="card-body" [routerLink]="['/property-details', item.id ]">
                    <h5 class="card-title" *ngIf="item.rpcf_price; else askingPrice">
                      <i class="fa fa-rupee" style="color: #4b4a4a;"></i> <span
                        style="padding-right: 10px;color: #4b4a4a;">{{item.rpcf_price}}</span>
                      <s> <span style="font-size: 17px;"><i class="fa fa-rupee"
                            style="font-size: 17px;"></i>{{item.asking_price}} </span></s>
                    </h5>
                    <ng-template #askingPrice>
                      <h5 class="card-title" style="color: #4b4a4a;"><i class="fa fa-rupee"></i>{{item.asking_price}}
                      </h5>
                    </ng-template>
                    <p class="card-text">
                      {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                    </p>
                    <p class="card-text" style="padding-top: 10px;border-top: 1px solid rgb(0 0 0 / 51%);">
                      <span class="pro_spec" *ngIf="item.total_bedrooms"> {{item.total_bedrooms}} <i
                          class="fa fa-bed"></i> </span>
                      <span class="pro_spec" *ngIf="item.total_showers"> {{item.total_showers}} <i
                          class="fa fa-bath"></i> </span>
                      <span class="pro_spec" *ngIf="item.saleable_area"> {{item.saleable_area}} {{(item.sale_area_unit ?
                        item.sale_area_unit.title : '')}} <i class="fa fa-check-square"></i> </span>
                      <span class="property_badge_sell">For Sale</span>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- End Property Grid View -->
          <!-- Start Property List View -->
          <div class="row" id="property_list_view">
            <ng-container *ngFor="let item of propertyList">
              <div class="col-md-12 single_property_list"
                *ngIf="(item.asking_price) && (item.property_name || item.building || item.street || item.address_line_1 || item.address_line_2 || item.locality_title || item.City)">
                <div class="row no-gutters">
                  <div class="col-md-6">
                    <div class="list_image_view" [style.background-image]="'url(' + item.image_preview + ')'">
                      <div class="property_badge top_left" *ngIf="item.rpcf_percentage">
                        <div class="ribbon ribbon-top-left {{item.ribbion_color}}"><span>{{item.rpcf_percentage}}%
                            OFF</span></div>
                      </div>
                      <div class="property_badge top_right favoriteIcon_{{item.id}}"
                        [ngClass]="{ 'is_favorite_prop': item.is_favorited == 1 }" *ngIf="item.prop_transaction_type"
                        (click)="makeFavorite(item.id, item.is_favorited, page)">
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 0" style="color: #FFFFFF;"></i>
                        <i class="fa fa-heart" *ngIf="item.is_favorited == 1" style="color: #F02174;"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-body" [routerLink]="['/property-details', item.id ]">
                      <h5 class="card-title" *ngIf="item.rpcf_price; else askingPrice">
                        <i class="fa fa-rupee" style="color: #4b4a4a;"></i> <span
                          style="padding-right: 10px;color: #4b4a4a;">{{item.rpcf_price}}</span>
                        <s> <span style="font-size: 17px;"><i class="fa fa-rupee"
                              style="font-size: 17px;"></i>{{item.asking_price}} </span></s>
                      </h5>
                      <ng-template #askingPrice>
                        <h5 class="card-title" style="color: #4b4a4a;"><i class="fa fa-rupee"></i>{{item.asking_price}}
                        </h5>
                      </ng-template>
                      <p class="card-text">
                        {{item.property_name}} {{item.locality_title}} {{(item.City ? item.city.city : '')}}
                      </p>
                      <p class="card-text">
                        <span class="pro_spec" *ngIf="item.total_bedrooms"> {{item.total_bedrooms}} <i
                            class="fa fa-bed"></i> </span>
                        <span class="pro_spec" *ngIf="item.total_showers"> {{item.total_showers}} <i
                            class="fa fa-bath"></i> </span>
                        <span class="pro_spec" *ngIf="item.saleable_area"> {{item.saleable_area}} {{(item.sale_area_unit
                          ? item.sale_area_unit.title : '')}} <i class="fa fa-check-square"></i> </span>
                        <span class="property_badge_sell">Fore Sale</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- End Property List View -->
          <ng-container *ngIf="isLoading == false">
            <div *ngIf="propertyList.length == 0 && page == 'resale'">
              <p>No property found</p>
            </div>
            <div *ngIf="projectsList.length == 0 && page == 'new'">
              <p>No property found</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</div>
