<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid account-offers-outer" id="accoutnSettings">
  <ng-container *ngIf="isLoading == true">
    <div class="loading_div">
      <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
    </div>
  </ng-container>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">Offers</h4>
        <div class="account-offers acc-dashboard">
          <ng-container *ngIf="isLoading == false">
            <div class="empty-message" *ngIf="buyerOfferList.length === 0 && buyerOfferProjectList.length === 0 && sellerOfferList.length === 0">
              <img src="{{ offer_icon }}" class="home-icon" alt="Home" />
              <div class="empty-text">
                <h5>You Don’t Have Any Offers, Currently.</h5>
                <p>Get started on an offer day or night, seven days a week.</p>
              </div>
            </div>
          </ng-container>
          <div *ngIf="buyerOfferList.length > 0 || buyerOfferProjectList.length > 0 || sellerOfferList.length > 0">
          <ng-container *ngFor="let sellerOfferItem of sellerOfferList">
            <div *ngIf="isLoading == false" class="account-list-property">
              <div class="p_image_view" (click)="openInNewTab(sellerOfferItem,sellerOfferItem.related_source_id,'property')" [style.background-image]="'url(\'' + sellerOfferItem.offered_property.image_preview + '\')'"></div>
            
            
              <!-- <div class="property_badge_ top_left_" >
                <span [ngClass]="sellerOfferItem.prop_status.title==='Available' ? 'property_badge_sell_ available_tag' : sellerOfferItem.prop_status.status==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{sellerOfferItem.prop_status.title}}</span>
                <span class="property_badge_sell_ neg">Under Negotiation</span>
              </div> -->
            
              <div class="p_details">
                <div class="p_details_top">
                  <div class="price_div">
                    <span>
                      {{ sellerOfferItem.offered_property.property_name }} {{sellerOfferItem.offered_property.building}} {{sellerOfferItem.offered_property.street}} {{sellerOfferItem.offered_property.address_line_1}} {{sellerOfferItem.offered_property.address_line_2}} {{sellerOfferItem.offered_property.locality_title}} {{(sellerOfferItem.offered_property.City ? sellerOfferItem.offered_property.city.city : '')}}

                    </span>
                  </div>
                  <p class="address">
                    <i class="fa fa-rupee"></i>{{ sellerOfferItem.offered_property.asking_price_in_word }}
                  </p>
                  <p class="card-text">
                    <span class="pro_spec" *ngIf="sellerOfferItem.offered_property.total_showers"> <i class="fa fa-bath"></i> {{(sellerOfferItem.offered_property.total_showers ? sellerOfferItem.offered_property.total_showers : 0)}} Bath </span>
                    <span class="pro_spec" *ngIf="sellerOfferItem.offered_property.total_bedrooms"> <i class="fa fa-bed"></i> {{(sellerOfferItem.offered_property.total_bedrooms ? sellerOfferItem.offered_property.total_bedrooms : 0)}} Bed </span>
                    <span class="pro_spec" *ngIf="sellerOfferItem.offered_property.super_builtup"> <i class="fa fa-check-square"></i> {{ sellerOfferItem.offered_property.super_builtup }} Sq.Ft. </span>
                  </p>
                </div>
                <div class="p_details_bottom" (click)="openInNewTab(sellerOfferItem,sellerOfferItem.offered_property.related_source_id,'property')">
                  <ul class="prop_more_details">
                    <li *ngIf="sellerOfferItem.offered_property.transaction_name">
                      <h5>Type</h5>
                      <p>{{ sellerOfferItem.offered_property.transaction_name }}</p>
                    </li>
                    <li *ngIf="sellerOfferItem.offered_property.rate_sqft">
                      <h5>Price per SQ.FT</h5>
                      <p><i class="fa fa-rupee"></i>{{ sellerOfferItem.offered_property.rate_sqft }}</p>
                    </li>
                    <li *ngIf="sellerOfferItem.offered_property.created">
                      <h5>Year Built</h5>
                      <p>{{ sellerOfferItem.offered_property.created | date : "y" }}</p>
                    </li>
                    <li *ngIf="sellerOfferItem.offered_property.property_ownership">
                      <h5>Ownership</h5>
                      <p>{{ sellerOfferItem.offered_property.property_ownership }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngFor="let buyerOfferItem of buyerOfferList">
            <div *ngIf="isLoading == false" [ngClass]="buyerOfferItem.offered_property.prop_status.title =='Sold' ? 'account-list-property remove_hand_cursor':'account-list-property'">
              <div class="p_image_view" (click)="openInNewTab(buyerOfferItem,buyerOfferItem.property_id ? buyerOfferItem.property_id : buyerOfferItem.project_unit_id,buyerOfferItem.property_id ? 'property' : 'PU')" [style.background-image]="'url(\'' + buyerOfferItem.offered_property.image_preview + '\')'"></div>

              <div class="property_badge_ top_left_" *ngIf="buyerOfferItem?.offered_property?.prop_status && buyerOfferItem?.offered_property?.prop_status.title">
                <span [ngClass]="buyerOfferItem.offered_property.prop_status.title==='Available' ? 'property_badge_sell_ available_tag' : buyerOfferItem.offered_property.prop_status.title=='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{buyerOfferItem.offered_property.prop_status.title}}</span>
                <!-- <span class="property_badge_sell_ neg">Under Negotiation</span> -->
              </div>
              <div class="p_details">
                <div class="p_details_top">
                  <div class="price_div">
                    <span class="set_width" *ngIf="buyerOfferItem.offered_property.display_name"> {{ buyerOfferItem.offered_property.display_name }}</span>
                    <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                      <label class="label-accept text-center mobile-hide">Accepted</label>
                    </ng-container>
                    <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                      <label class="label-reject text-center mobile-hide">Rejected</label>
                    </ng-container>
                    <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                      <label class="label-accept text-center mobile-hide">Sold</label>
                    </ng-container>
                    <button class="btn make-offer-btn mobile-hide" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0 && buyerOfferItem.offered_property.prop_status.title!='Sold'">Counter Offer</button>
                    <div class="recently-mob mobile-show">
                      <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                        <label class="label-accept text-center">Accepted</label>
                      </ng-container>
                      <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                        <label class="label-reject text-center">Rejected</label>
                      </ng-container>
                      <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                        <label class="label-accept text-center">Sold</label>
                      </ng-container>
                      <button class="btn make-offer-btn" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0 && buyerOfferItem.offered_property.prop_status.title!='Sold'">Counter Offer</button>
                    </div>
                  </div>
                  <p class="address" >
                    <i class="fa fa-rupee"></i>{{ buyerOfferItem.offered_property.asking_price_in_word }} 
                  </p>
                  <p class="card-text">
                    <span class="pro_spec" *ngIf="buyerOfferItem.offered_property.total_showers"> <i class="fa fa-bath"></i> {{(buyerOfferItem.offered_property.total_showers ? buyerOfferItem.offered_property.total_showers : 0)}} Bath </span>
                    <span class="pro_spec" *ngIf="buyerOfferItem.offered_property.total_bedrooms"> <i class="fa fa-bed"></i> {{(buyerOfferItem.offered_property.total_bedrooms ? buyerOfferItem.offered_property.total_bedrooms : 0)}} Bed </span>
                    <span class="pro_spec" *ngIf="buyerOfferItem.offered_property.super_builtup"> <i class="fa fa-check-square"></i> {{ buyerOfferItem.offered_property.super_builtup }} Sq.Ft. </span>
                  </p>
                </div>
                <div class="p_details_bottom" (click)="openInNewTab(buyerOfferItem,buyerOfferItem.property_id ? buyerOfferItem.property_id : buyerOfferItem.project_unit_id,buyerOfferItem.property_id ? 'property' : 'PU')">
                  <ul class="prop_more_details">
                    <li *ngIf="buyerOfferItem.offered_property.transaction_name">
                      <h5>Type</h5>
                      <p>{{ buyerOfferItem.offered_property.transaction_name }}</p>
                    </li>
                    <li *ngIf="buyerOfferItem.offered_property.rate_sqft">
                      <h5>Price per SQ.FT</h5>
                      <p><i class="fa fa-rupee"></i>{{ buyerOfferItem.offered_property.rate_sqft }}</p>
                    </li>
                    <li *ngIf="buyerOfferItem.offered_property.created">
                      <h5>Year Built</h5>
                      <p>{{ buyerOfferItem.offered_property.created | date : "y" }}</p>
                    </li>
                    <li *ngIf="buyerOfferItem.offered_property.property_ownership">
                      <h5>Ownership</h5>
                      <p>{{ buyerOfferItem.offered_property.property_ownership }}</p>
                    </li>
                    <li *ngIf="buyerOfferItem.offer_amount_title">
                      <h5>Offered Amount</h5>
                      <p><i class="fa fa-rupee"></i>{{ buyerOfferItem.offer_amount_title }}</p>
                    </li>
                  </ul>
                </div>
                <div class="p_details_history">
                  <a type="button" class="btn btn-info" data-toggle="collapse"[attr.data-target]="'#id' + buyerOfferItem.property_id">Offer History</a>
                  <div id='id{{buyerOfferItem.property_id }}' class="collapse">
                    <ul>
                      <li *ngFor="let historyList of buyerOfferItem.offer_history">{{ historyList.user_type}} {{historyList.created | date:'medium'}} </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngFor="let buyerOfferItem of buyerOfferProjectList">
            <div *ngIf="isLoading == false" [ngClass]="buyerOfferItem?.offered_project?.project_status==='Sold'?'account-list-property remove_hand_cursor': 'account-list-property'">
              <div class="p_image_view"  (click)="openInNewTab(buyerOfferItem,buyerOfferItem.project_unit_id,'PU')" [style.background-image]="'url(\'' + buyerOfferItem.offered_project.image_preview + '\')'"></div>
              <div class="property_badge_ top_left_" *ngIf="buyerOfferItem?.offered_project?.project_status">
                <span [ngClass]="buyerOfferItem?.offered_project?.project_status==='Available' ? 'property_badge_sell_ available_tag' : buyerOfferItem?.offered_project?.project_status==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{buyerOfferItem?.offered_project?.project_status}}</span>
              </div>
              <!-- <div class="property_badge_ top_left_" > -->
                <!-- <span [ngClass]="buyerOfferItem.prop_status.title==='Available' ? 'property_badge_sell_ available_tag' : buyerOfferItem.prop_status.status==='Sold' ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'" >{{buyerOfferItem.prop_status.title}}</span> -->
                <!-- <span class="property_badge_sell_ neg">Under Negotiation</span> -->
              <!-- </div> -->

              <div class="p_details">
                <div class="p_details_top">
                  <div class="price_div">
                    <span *ngIf="buyerOfferItem.offered_project_unit.display_name" class="set_width">{{ buyerOfferItem.offered_project_unit.display_name }}</span>
                    <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                      <label class="label-accept text-center mobile-hide">Accepted</label>
                    </ng-container>
                    <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                      <label class="label-reject text-center mobile-hide">Rejected</label>
                    </ng-container>
                    <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                      <label class="label-accept text-center mobile-hide">Sold</label>
                    </ng-container>
                    <button class="btn make-offer-btn mobile-hide" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0">Counter Offer</button>
                    <div class="recently-mob mobile-show">
                      <ng-container *ngIf="buyerOfferItem.offer_status === 1">
                        <label class="label-accept text-center">Accepted</label>
                      </ng-container>
                      <ng-container *ngIf="buyerOfferItem.offer_status === 2">
                        <label class="label-reject text-center">Rejected</label>
                      </ng-container>
                      <ng-container *ngIf="buyerOfferItem.offer_status === 3">
                        <label class="label-accept text-center">Sold</label>
                      </ng-container>
                      <button class="btn make-offer-btn" (click)='onMakeCounterOfferClick(buyerOfferItem)' *ngIf="buyerOfferItem.offer_status === 0">Counter Offer</button>
                    </div>
                  </div>
                  <p class="address" *ngIf="buyerOfferItem.offered_project_unit.show_price_on_website">
                    <i class="fa fa-rupee"></i>{{ buyerOfferItem.offered_project_unit.base_price_title }}
                    
                  </p>
                  <p class="card-text">
                    <span class="pro_spec" *ngIf="buyerOfferItem.offered_project_unit.total_showers"> <i class="fa fa-bath"></i> {{(buyerOfferItem.offered_project_unit.total_showers ? buyerOfferItem.offered_project_unit.total_showers : 0)}} Bath </span>
                    <span class="pro_spec" *ngIf="buyerOfferItem.offered_project_unit.total_bedrooms"> <i class="fa fa-bed"></i> {{(buyerOfferItem.offered_project_unit.total_bedrooms ? buyerOfferItem.offered_project_unit.total_bedrooms : 0)}} Bed </span>
                    <span class="pro_spec" *ngIf="buyerOfferItem.offered_project_unit.super_builtup"> <i class="fa fa-check-square"></i> {{ buyerOfferItem.offered_project_unit.super_builtup }} Sq.Ft. </span>
                  </p>
                </div>
                <div class="p_details_bottom"  (click)="openInNewTab(buyerOfferItem,buyerOfferItem.project_unit_id,'PU')">
                  <ul class="prop_more_details">
                    <li *ngIf="buyerOfferItem.offered_project.transaction_name">
                      <h5>Type</h5>
                      <p>{{ buyerOfferItem.offered_project.transaction_name }}</p>
                    </li>
                    <li *ngIf="buyerOfferItem.offered_project.created">
                      <h5>Year Built</h5>
                      <p>{{ buyerOfferItem.offered_project.created | date : "y" }}</p>
                    </li>
                    <li *ngIf="buyerOfferItem.offer_amount_title">
                      <h5>Offered Amount</h5>
                      <p><i class="fa fa-rupee"></i>{{ buyerOfferItem.offer_amount_title }}</p>
                    </li>
                  </ul>
                </div>
                <div class="p_details_history">
                  <a type="button" class="btn btn-info" data-toggle="collapse"[attr.data-target]="'#id' + buyerOfferItem.id">Offer History</a>
                  <div id='id{{ buyerOfferItem.id }}' class="collapse">
                    <ul>
                      <li *ngFor="let historyList of buyerOfferItem.offer_history">
                        {{ historyList.user_type}} {{historyList.created | date:'medium'}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="makeCounterOfferModel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <div class="row d-flex">
          <div class="col-md-12">
            <div class="property-offer-details">
              <p>Make Offer</p>
              <div class="row d-flex">
                <div class="col-md-12" [formGroup]="offerForm">
                  <div class="form-group projectUnitNoIdDiv">
                    <select id="projectUnitNoIdSel" [(ngModel)]="project_unit_no_id" formControlName="projectUnitNoId" class="form-control" [ngClass]="{ 'is-invalid': (submitted && g.projectUnitNoId.errors || submitted && !offerformvalid) }" (change)="getProjectUnitNo($event.target.value)">
                      <!--<option value="">Select Unit No</option>-->
                      <option *ngFor="let option of projectUnitNoData" value="{{ option.id }}">
                        {{ option.text }}
                      </option>
                    </select>
                    <div *ngIf="submitted && g.projectUnitNoId.errors" class="invalid-feedback">
                      <div *ngIf="g.projectUnitNoId.errors.required">Please select Unit No</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input id="counterofferAmount" formControlName="offerprice" class="form-control" placeholder="Offer Price" [ngClass]="{ 'is-invalid': (submitted && g.offerprice.errors || submitted && !offerformvalid) }" />
                    <div *ngIf="submitted && g.offerprice.errors" class="invalid-feedback">
                      <div *ngIf="g.offerprice.errors.required">Offer Price is required</div>
                    </div>
                    <div *ngIf="submitted && !offerformvalid" class="invalid-feedback">
                      <div *ngIf="offerpriceminerror">Minimum <i class="fa fa-rupee"></i>{{ minOfferPriceText }} Offer Price required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="button" id="filterPropBtn" class="btn-view-all"
                          (click)="onOfferSubmit(currentItem)">Submit</button>

                  <button type="button" class="btn-view-all loaderBtn" disabled style="display: none;">
                    <span class="spinSpan spinner-border" role="status"></span> Saving
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
