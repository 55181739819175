<app-accountheader [dashboardtypeinput] = dashboardType></app-accountheader>
<div class="container-fluid open-house-outer" id="accoutnSettings">
  <!-- <div class="container"> -->
    <div class="row">
      <div class="tab-content col-md-10 col-12">
        <!-- <app-flash></app-flash> -->
        <h4 class="page-title">Recently Viewed</h4>
        <ul class="nav nav-tabs">
          <li class="nav_li active"><a data-toggle="tab" class="active" href="#resale">Resale ({{ buyerRecentlyViewed.length }})</a></li>
          <li class="nav_li active"><a data-toggle="tab" href="#new">New ({{ buyerNewRecentlyViewed.length }})</a></li>
        </ul>
        <div class="favourite-property">
          <div class="tab-content">
            <div id="resale" class="tab-pane fade in active show mt-3">
              <ng-container *ngIf="isLoading == false">
                <div class="empty-message" *ngIf="buyerRecentlyViewed.length == 0 && buyerNewRecentlyViewed.length == 0">
                  <img src="{{ home_icon }}" class="home-icon" alt="Home" />
                  <div class="empty-text">
                    <h5>You don't have any Recently viewed Property.</h5>
                  </div>
                  <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                </div>
              </ng-container>
              <ng-container *ngIf="isLoading == true">
                <div class="loading_div">
                  <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
                </div>
              </ng-container>
              <div *ngIf="buyerRecentlyViewed.length > 0 && isLoading == false">
                <ng-container *ngFor="let appointmentItem of buyerRecentlyViewed">
                  <app-recently-viewed-card [buyerRecentlyViewed]='appointmentItem' (rejectedclick)='onRejectedClick($event)'></app-recently-viewed-card>
                </ng-container>
              </div>
            </div>
            <div id="new" class="tab-pane fade mt-3">
              <ng-container *ngIf="isLoading == false">
                <div class="empty-message" *ngIf="buyerRecentlyViewed.length == 0 && buyerNewRecentlyViewed.length == 0">
                  <img src="{{ home_icon }}" class="home-icon" alt="Home" />
                  <div class="empty-text">
                    <h5>You don't have any Recently viewed Project.</h5>
                  </div>
                  <button class="btn ac_btn" [routerLink]="['/']">Search</button>
                </div>
              </ng-container>
              <ng-container *ngIf="isLoading == true">
                <div class="loading_div">
                  <img src="../../assets/img/web-image/loader.gif" class="loading_img" />
                </div>
              </ng-container>
              <div *ngIf="buyerNewRecentlyViewed.length > 0 && isLoading == false">
                <ng-container *ngFor="let appointmentItem of buyerNewRecentlyViewed">
                  <app-recently-viewed-card [buyerRecentlyViewed]='appointmentItem' (rejectedclick)='onRejectedClick($event)'></app-recently-viewed-card>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</div>
