import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../_services/api.service';
import { API_BASE_URL, API_ENDPOINTS } from '../../global';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;
declare const require: any;
declare var google: any;

@Component({
  selector: 'app-affordable-calculator',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './affordable-calculator.component.html',
  styleUrls: ['./affordable-calculator.component.scss']
})
export class AffordableCalculatorComponent implements OnInit {

  affordText = 'You can afford <i class="fa fa-rupee"></i>[AMOUNT]/mo';
  overbudgetText = 'You\'re overbudget at <i class=\'fa fa-rupee\'></i>[AMOUNT]/mo';
  spendLeftText = 'You will have <i class="fa fa-rupee"></i>[AMOUNT]/mo left to spend.';
  fitComfortablyText = 'Based on your income, a rental at this price should fit comfortably within your budget.';
  expensesExceedText = 'Careful! If you spend this much on rent, your debts and expenses will exceed your income.';
  landlordsMoreText = 'Generally, landlords require your rent to be no more than one-third of your gross income.';

  rentSliderTitle: string;
  rentSliderText: string;
  rentSliderSpend: string;

  rentSliderValue = 10;
  rentSliderOptions: any;

  emiForm: FormGroup;
  rentForm: FormGroup;
  locationKeyword = 'placeName';
  locationData: any;
  locationErrorMsg: string;
  locationIsLoadingResult: boolean;
  locationSearch = '';
  locationSearchValue = '';

  matchingProperties: any = [];
  matchingResult = 0;
  matchingPropertySlider: any;
  monthlyRent = 0;
  isLoggedIn;
  isFavoriting = false;
  net_income_tax: number;
  gross_income: number;
  net_income: number;
  m_debts: number;
  m_savings: number;
  m_expenses: number;
  isFindingProperty = false;
  isSliderInitialize = false;

  slider_color = 'green';
  slider_orange = 'orange';

  suitableFor = 2;
  homePrice = 0;
  downPayment = 450000;
  newHomePrice: number = this.homePrice - this.downPayment;
  downPaymentPer = 15;
  loanProgramOptions: any = [];
  loanProgram = 4;
  intRate = 7.25;

  // tslint:disable-next-line:max-line-length no-shadowed-variable
  constructor( private router: Router, private route: ActivatedRoute, private ApiService: ApiService, private title: Title, private meta: Meta, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    const componentObj = this;
    $('#header').removeClass('header-fixed');
    $('#header .container-fluid').removeClass('header-container-fluid');
    $('.footer-bottom').show();
    if (this.route.snapshot.params.id !== '') {
      this.suitableFor = Number(this.route.snapshot.params.id);
    }
    if (this.suitableFor === 1) {
      this.homePrice = 7500000;
    } else if (this.suitableFor === 2) {
      this.homePrice = 3000000;
    } else if (this.suitableFor === 3) {
      this.homePrice = 2500000;
    } else if (this.suitableFor === 4) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 6) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 7) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 8) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 10) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 19) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 20) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 21) {
      this.homePrice = 4500000;
    } else if (this.suitableFor === 22) {
      this.homePrice = 4500000;
    }
    this.route.params.subscribe(routeParams => {
      this.loanProgramOptions = [
        {id: 1, title: '5 Years'},
        {id: 2, title: '10 Years'},
        {id: 3, title: '15 Years'},
        {id: 4, title: '20 Years'}
      ];
      this.onEMICalPriceChange();
      this.rentSliderOptions = {
        floor: 10,
        ceil: 40,
        translate: (value: number): string => {
          return value + '%';
        },
        showSelectionBar: true,
        getSelectionBarColor: (value: number): string => {
          if (value >= 33) {
            return componentObj.slider_orange;
          }
          return componentObj.slider_color;
        }
      };
      this.emiForm = this.formBuilder.group({
        homePrice: ['', [Validators.minLength(1), Validators.min(1)]],
        downPayment: ['', [Validators.minLength(1), Validators.min(1)]],
        downPaymentPer: ['', [Validators.minLength(1), Validators.min(1)]],
        intRate: ['', [Validators.minLength(1), Validators.min(1)]],
        home_ins_year: ['', [Validators.minLength(0), Validators.min(0)]]
      });
      this.isLoggedIn = this.ApiService.loginCheck();
      setTimeout (() => {
        this.changeHeaderClass();
      }, 200);
    });
  }

  locationOnSelectEvent(item) {
    if(item.eLoc) {
      this.locationSearch = "in " + item.eLoc;
      this.locationSearchValue = item.eLoc;
      this.onRentCalPriceChange();
    }
  }

  getLocationServerResponse(event) {
    this.locationIsLoadingResult = true;
    var form_data = new FormData();
    form_data.append('search', event);
    form_data.append('is_web_api', '1');
    this.ApiService.getRequest(API_BASE_URL + "/master/getLocality?search=" + event + "&is_web_api=1", form_data).subscribe((apiResult: any) => {
      if(apiResult.success) {
        if(apiResult.data.length) {
          this.locationData = apiResult.data;
        } else {
          this.locationData = [];
          this.locationErrorMsg = "Please enter a valid rental location.";
        }
      }
      this.locationIsLoadingResult = false;
    });
  }

  locationSearchCleared() {
    this.locationData = [];
  }

  get g() {
    return this.emiForm.controls;
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {

  }

  changeHeaderClass() {
    $('#header').removeClass('header-transparent');
    $("#imgHeadLogo").attr("src", "assets/img/empire_logo.png");
    this.title.setTitle('Empire Realtors » Rent Calculator');
  }

  initMatchingPropertyViewSlider(){
    this.matchingPropertySlider = $('#matching_property_view').lightSlider({
      gallery: false,
      item: 4.6,
      loop: false,
      slideMargin: 15,
      responsive : [
        {
          breakpoint: 767,
          settings: {
            item: 1.5
          }
        }
      ]
    });
  }

  getMatchingProperties() {
    this.isFindingProperty = true;
    var form_data = new FormData();
    form_data.append('property_type_id', '1');
    form_data.append('transaction_type_id', '3');
    form_data.append('locality_elocs', this.locationSearchValue + "");
    form_data.append('max_budget_amount', this.monthlyRent + "");
    this.ApiService.postRequest(API_ENDPOINTS.API_FIND_PROPERTIES, form_data).subscribe((apiResult: any) => {
      if(apiResult.success){
        this.matchingResult = apiResult.data.paginate.Property.count;
        this.matchingProperties = apiResult.data.list;
        this.isFindingProperty = false;
        if(this.matchingResult) {
          setTimeout (() => {
            if(this.isSliderInitialize) {
              this.matchingPropertySlider.refresh();
            } else {
              this.isSliderInitialize = true;
              this.initMatchingPropertyViewSlider();
            }
          }, 200);
        } else {
          if(this.isSliderInitialize) {
            this.matchingPropertySlider.refresh();
          }
        }
      }
    });
  }

  makeFavoriteOthers(pid) {
    if(!this.isLoggedIn) {
      this.openLoginModel();
    } else {
      var form_data = new FormData();
      form_data.append('property_id', pid);
      form_data.append('status', "1");
      this.ApiService.postRequest(API_ENDPOINTS.API_PROPERTY_FAVORITE, form_data).subscribe((apiResult: any) => {
        if(apiResult.success) {
          if($('.favoriteIcon_' + pid).hasClass('is_favorite_prop')) {
            $('.favoriteIcon_' + pid).removeClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#FFFFFF");
          } else {
            $('.favoriteIcon_' + pid).addClass('is_favorite_prop');
            $('.favoriteIcon_' + pid).find('.fa').css("color", "#F02174");
          }
        }
      });
    }
  }

  openLoginModel(): void {
    $('#loginModal').modal('toggle');
  }

  onRentCalPriceChange() {
    if(this.net_income <= 0) {
      this.net_income = 0;
    }
    if(this.m_debts <= 0) {
      this.m_debts = 0;
    }
    if(this.m_savings <= 0) {
      this.m_savings = 0;
    }
    if(this.m_expenses <= 0) {
      this.m_expenses = 0;
    }
    if(this.net_income > 0) {
      this.net_income_tax = Math.round((this.net_income * 25) / 75);
      this.gross_income = this.net_income_tax + this.net_income;
      this.monthlyRent = Math.round((this.net_income * this.rentSliderValue) / 100);
      var spendLeft = this.net_income - this.monthlyRent;
      if(this.m_debts > 0) {
        spendLeft = spendLeft - this.m_debts;
      }
      if(this.m_savings > 0) {
        spendLeft = spendLeft - this.m_savings;
      }
      if(this.m_expenses > 0) {
        spendLeft = spendLeft - this.m_expenses;
      }
      if(spendLeft > 0) {
        if(this.rentSliderValue >= 35) {
          this.rentSliderTitle = this.affordText.replace("[AMOUNT]", this.monthlyRent + "");
          this.rentSliderText = this.landlordsMoreText;
          this.rentSliderSpend = this.spendLeftText.replace("[AMOUNT]", spendLeft + "");
        } else {
          this.rentSliderTitle = this.affordText.replace("[AMOUNT]", this.monthlyRent + "");
          this.rentSliderText = this.fitComfortablyText;
          this.rentSliderSpend = this.spendLeftText.replace("[AMOUNT]", spendLeft + "");
        }
        this.slider_color = 'green';
        this.slider_orange = 'orange';
      } else {
        this.rentSliderTitle = this.overbudgetText.replace("[AMOUNT]", this.monthlyRent + "");
        this.rentSliderText = this.expensesExceedText;
        this.rentSliderSpend = this.spendLeftText.replace("[AMOUNT]", spendLeft + "");
        this.slider_color = 'red';
        this.slider_orange = 'red';
      }
    }
    if(this.net_income > 0 && this.locationSearch != "") {
      this.matchingResult = 0;
      this.matchingProperties = [];
      this.getMatchingProperties();
    }
  }

  viewallMatching() {
    var data = {};
    data['transaction_type_id'] = '3';
    data['property_type_id'] = '1';
    data['locality_elocs'] = this.locationSearchValue + "";
    data['max_budget_amount'] = this.monthlyRent + "";
    this.router.navigate(['property'], {queryParams: data});
  }

  onEMICalPriceChange() {
    setTimeout(() => {
      if (this.homePrice <= 0) {
        this.homePrice = 500000;
      }
      this.downPayment = (this.homePrice * this.downPaymentPer) / 100;
      this.newHomePrice = this.homePrice - this.downPayment;
      this.getLoanMonthly();
    }, 200);
  }

  getLoanMonthly() {
    let term = 5;
    if (this.loanProgram === 1) {
      term = 5;
    } else if (this.loanProgram === 2) {
      term = 10;
    } else if (this.loanProgram === 3) {
      term = 15;
    } else if (this.loanProgram === 4) {
      term = 20;
    }
    const totalAmount = (this.homePrice - this.downPayment) * (this.intRate / 100) * term;
    const monthlyAmount = ((this.homePrice - this.downPayment) + totalAmount) / (term * 12);
    $('#cost_month').html('<b><i class="fa fa-rupee"></i></b><b>' + monthlyAmount.toFixed(2) + '</b> per month');
  }

  onEMICalDownPaymentChange() {
    if (this.downPayment <= 0) {
      this.downPaymentPer = 1;
      this.downPayment = (this.homePrice * this.downPaymentPer) / 100;
    } else {
      this.downPaymentPer = (this.downPayment * 100) / this.homePrice;
    }
    this.newHomePrice = this.homePrice - this.downPayment;
    this.getLoanMonthly();
  }

  onEMICalDownPaymentPerChange() {
    if (this.downPaymentPer <= 1) {
      this.downPaymentPer = 1;
    }
    this.downPayment = (this.homePrice * this.downPaymentPer) / 100;
    this.newHomePrice = this.homePrice - this.downPayment;
    this.getLoanMonthly();
  }

  onEMICalTermChange() {
    this.getLoanMonthly();
  }

  onEMICalIntRateChange() {
    if (this.intRate <= 0) {
      this.intRate = 1;
    }
    this.getLoanMonthly();
  }
}
