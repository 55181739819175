// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_URL:  'https://crm.empirerealtors.in',
  // WEB_URL: 'https://www.empirerealtors.in',
  WEB_URL:  'https://www.empirerealtors.in',


 // ** Start Google Data **/
  // G_CLIENT_ID: string = '357433225707-e568m122cchvf9laos3fg2ie17f7r1if.apps.googleusercontent.com';
  G_CLIENT_ID: '621229473496-mle81a6aapf8i05l11at74dvglhuapvg.apps.googleusercontent.com',
  G_CLIENT_KEY:  'AIzaSyBegNdgOXPd2ICe4nhdPdcChJ2cb7uBNSE',
  G_SCOPE:  'profile email',
  // ** End Google Data **/

  // ** Start FB Data **/
  F_APP_ID: '259650981702792',
  F_SCOPE: 'user_birthday'
  // ** End FB Data **/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
