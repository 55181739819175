<div [ngClass]="(buyerRecentlyViewed?.prop_status?.title=='sold'|| buyerRecentlyViewed?.prop_status?.title=='Sold') || (buyerRecentlyViewed?.project_unit_status_txt=='sold'|| buyerRecentlyViewed?.project_unit_status_txt=='Sold') ? 'account-list-property remove_hand_cursor' : 'account-list-property'">
  <div (click)="openInNewTab(buyerRecentlyViewed )"
    [style.background-image]="'url(\'' + buyerRecentlyViewed.image + '\')'" class="p_image_view"></div>

  <div class="property_badge_ top_left_"
    *ngIf="buyerRecentlyViewed.property_id || buyerRecentlyViewed?.project_unit_status_txt">
    <!-- <span class="property_badge_sell_ sold" >Sold Out</span> -->
    <span
      [ngClass]="(buyerRecentlyViewed?.prop_status?.title || buyerRecentlyViewed?.project_unit_status_txt)==='Available' ? 'property_badge_sell_ available_tag' : (buyerRecentlyViewed?.prop_status?.title=='sold'|| buyerRecentlyViewed?.prop_status?.title=='Sold') || (buyerRecentlyViewed?.project_unit_status_txt=='sold'|| buyerRecentlyViewed?.project_unit_status_txt=='Sold') ? 'property_badge_sell_ sold'  : 'property_badge_sell_ neg'">{{buyerRecentlyViewed.property_id
      ? buyerRecentlyViewed?.prop_status?.title : buyerRecentlyViewed?.project_unit_status_txt}}</span>
  </div>
  <div class="p_details">
    <div class="p_details_top">
      <p class="address" *ngIf="buyerRecentlyViewed.display_name">
        {{ buyerRecentlyViewed.display_name }}
      </p>
      <!-- *ngIf="buyerRecentlyViewed.show_status === 4 && buyerRecentlyViewed.selected_counter > 0 && buyerRecentlyViewed.accepted_offer_id == null" -->
      <div class="price_div">
        <!--        <div class="price_div" *ngIf="buyerRecentlyViewed.selected_counter > 0">-->
        <span
          *ngIf="buyerRecentlyViewed?.show_price_on_website!==undefined && buyerRecentlyViewed?.show_price_on_website==0"><br></span>
        <span *ngIf="(buyerRecentlyViewed?.show_price_on_website==0 ? 0 : 1)"><i class="fa fa-rupee"></i>{{
          buyerRecentlyViewed.asking_price_in_word }}</span>
        <!-- buyerRecentlyViewed.show_status!==2 &&         -->
        <ng-container
          *ngIf="(buyerRecentlyViewed | Visibility )">
          <!-- *ngIf='buyerRecentlyViewed.selected_counter > 0' -->
          <div class="mobile-hide buttons_set">
            <button class="btn make-offer-btn btn_color_selected btn_position"
              (click)="onMakeOfferClick(buyerRecentlyViewed)" style="width: 101px; right: 238px;"> <i
                class="fa fa-check-square-o"></i> Selected</button>
            <button class="btn make-offer-btn btn_color_reshow btn_position"
              (click)="onReshowingClick(buyerRecentlyViewed.property_id ? buyerRecentlyViewed.property_id : buyerRecentlyViewed.project_unit_id, buyerRecentlyViewed.assign_to_user_id, buyerRecentlyViewed.franchisee_id, buyerRecentlyViewed.source,buyerRecentlyViewed.project_id)"
              style="width: 101px; right: 125px;"><i class="fa fa-calendar-check-o"></i> Reshowing</button>
            <button class="btn make-offer-btn btn_color_reject btn_position"
              (click)="onRejectedClick(buyerRecentlyViewed.id)" style="width: 101px;"><i class="fa fa-times"></i>
              Rejected</button>
          </div>
          <div *ngIf='buyerRecentlyViewed.selected_counter > 0' class="recently-mob mobile-show">
            <button class="btn make-offer-btn btn_color_selected" (click)="onMakeOfferClick(buyerRecentlyViewed)"
              style="width: 101px; right: 238px;"><i class="fa fa-check-square-o"></i> Selected</button>
            <button class="btn make-offer-btn btn_color_reshow"
              (click)="onReshowingClick(buyerRecentlyViewed.property_id ? buyerRecentlyViewed.property_id : buyerRecentlyViewed.project_unit_id, buyerRecentlyViewed.assign_to_user_id, buyerRecentlyViewed.franchisee_id, buyerRecentlyViewed.source,buyerRecentlyViewed.project_id)"
              style="width: 101px; right: 125px;"><i class="fa fa-calendar-check-o"></i> Reshowing</button>
            <button class="btn make-offer-btn btn_color_reject" (click)="onRejectedClick(buyerRecentlyViewed.id)"
              style="width: 75px;"><i class="fa fa-times"></i> Rejected</button>
          </div>
        </ng-container>
      </div>

      <p class="card-text">
        <span class="pro_spec" *ngIf="buyerRecentlyViewed.total_showers"> <i class="fa fa-bath"></i>
          {{(buyerRecentlyViewed.total_showers ? buyerRecentlyViewed.total_showers : 0)}} Bath </span>
        <span class="pro_spec" *ngIf="buyerRecentlyViewed.total_bedrooms"> <i class="fa fa-bed"></i>
          {{(buyerRecentlyViewed.total_bedrooms ? buyerRecentlyViewed.total_bedrooms : 0)}} Bed </span>
        <span class="pro_spec" *ngIf="buyerRecentlyViewed.super_builtup"> <i class="fa fa-check-square"></i> {{
          (buyerRecentlyViewed.super_builtup || 'Sq.Ft.') }}  </span>
      </p>
    </div>
    <div class="p_details_bottom" (click)="openInNewTab(buyerRecentlyViewed)">
      <ul class="prop_more_details">
        <li *ngIf="buyerRecentlyViewed.transaction_name">
          <h5>Type</h5>
          <p>{{ buyerRecentlyViewed.transaction_name }}</p>
        </li>
        <li *ngIf="buyerRecentlyViewed.rate_sqft">
          <h5>Price per SQ.FT</h5>
          <p><i class="fa fa-rupee"></i>{{ buyerRecentlyViewed.rate_sqft }}</p>
        </li>
        <li *ngIf="buyerRecentlyViewed.created">
          <h5>Year Built</h5>
          <p>{{ buyerRecentlyViewed.created }}</p>
        </li>
        <li *ngIf="buyerRecentlyViewed.property_ownership">
          <h5>Ownership</h5>
          <p>{{ buyerRecentlyViewed.property_ownership }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="modal fade" id="makeInitOfferModel_{{buyerRecentlyViewed.id}}" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <div class="row d-flex">
          <div class="col-md-12">
            <div class="property-offer-details">
              <p>Make Offer</p>
              <div class="row d-flex">
                <div class="col-md-12" [formGroup]="offerForm">
                  <div class="form-group projectUnitNoIdSelDiv">
                    <select id="projUnitNoIdSel_{{buyerRecentlyViewed.id}}" [(ngModel)]="proj_unit_no_id"
                      formControlName="projUnitNoId" class="form-control"
                      [ngClass]="{ 'is-invalid': (submitted && g.projUnitNoId.errors || submitted && !offerformvalid) }"
                      (change)="setProjectUnitNo($event.target.value)" disabled>
                      <option value="">Select Unit No</option>
                      <option *ngFor="let option of projUnitNoData" value="{{ option.id }}">
                        {{ option.text }}
                      </option>
                    </select>
                    <div *ngIf="submitted && g.projUnitNoId.errors" class="invalid-feedback">
                      <div *ngIf="g.projUnitNoId.errors.required">Please select Unit No</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input id="offerAmount_{{buyerRecentlyViewed.id}}" formControlName="offerprice" class="form-control"
                      placeholder="Offer Price"
                      [ngClass]="{ 'is-invalid': (submitted && g.offerprice.errors || submitted && !offerformvalid) }" />
                    <div *ngIf="submitted && g.offerprice.errors" class="invalid-feedback">
                      <div *ngIf="g.offerprice.errors.required">Offer Price is required</div>
                    </div>
                    <div *ngIf="submitted && !offerformvalid" class="invalid-feedback">
                      <div *ngIf="offerpriceminerror">Minimum <i class="fa fa-rupee"></i>{{ minOfferPriceText }} Offer
                        Price required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="button" class="btn-view-all filterPropBtn"
                    (click)="onOfferSubmit(currentItem)">Submit</button>

                  <button type="button" class="btn-view-all loaderBtn" disabled style="display: none;">
                    <span class="spinSpan spinner-border" role="status"></span> Saving
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>