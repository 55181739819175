<div class="container-fluid" id="singleProperty">
  <div class="container">
    <!-- Start Breadcrumb -->
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li>
            <a href="/"> Home </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="propertyInArea(projectinfo)"> Projects for sale in {{projectinfo.locality_title}} </a>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> {{projectinfo.name}} </span>
          </li>
          <li class="b_sep">
            <i class="fa fa-chevron-right"></i>
          </li>
          <li>
            <span> {{(projectinfo.plot_area ? projectinfo.plot_area : '0') +' '+ (projectinfo?.plot_area_unittype?.title || 'Acres') }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->
    <!-- Start Property header -->
    <div class="row">
      <div class="col-md-9">
        <div class="row property_head">
          <div class="col-md-6">
            <h4>{{projectinfo.name}}</h4>
            <h6>{{projectinfo.locality_title ? projectinfo.locality_title : ''}} {{(projectinfo.city ? projectinfo.city.city : '')}}</h6>
            <h6 *ngIf="projectinfo && projectinfo.project_account && projectinfo.project_account.group_name !== '' && projectinfo.project_account.group_name !== null">
              Group: <b><a [routerLink]="['/developer-details', projectinfo.project_account.group_name_slug ]" target="_blank" href="#" class="text-dark" target="_blank">{{projectinfo.project_account.group_name}}</a></b>
            </h6>
            <h6 style="color: #5a5a5a;"><b >Project ID</b>&nbsp;:&nbsp;{{projectinfo.id || ''}}</h6>
          </div>
          <div class="col-md-4">
            <ul class="prop_other_info">
              <li *ngIf="projectinfo.show_price_on_website === 1; else contactInfo">
                <h4 *ngIf="projectinfo.minMaxData"><i class="fa fa-rupee"></i> {{projectinfo.minMaxData.min_rate_title}}
                  - {{projectinfo.minMaxData.max_rate_title}}</h4>
                <p>Price excludes maintenance, floor rise cost,</p>
              </li>
              <ng-template #contactInfo>
                <li>
                  <ng-template #popTemplate>
                    Name: {{ (projectinfo.assign_to.name ? projectinfo.assign_to.name : projectinfo.assign_to.first_name + ' ' + projectinfo.assign_to.last_name) }} <br>
                    Contact No: {{(projectinfo.assign_to && projectinfo.assign_to.contact_no ? projectinfo.assign_to.contact_no : '')}} <br>
                    Email: {{projectinfo.assign_to.email}}
                  </ng-template>
                  <a [tooltip]="popTemplate" class="btn btn-common btn-lg contact-tooltip-btn" href="tel:+918866550550"  type="button">
                    <i aria-hidden="true" class="fa fa-phone"></i> Call For Price <br> {{ (projectinfo.assign_to && projectinfo.assign_to.contact_no ? projectinfo.assign_to.contact_no : '') }}
                  </a>
                </li>
              </ng-template>
              <!--<li *ngIf="projectinfo.total_bedrooms">
                <h4>{{(projectinfo.total_bedrooms ? projectinfo.total_bedrooms : 0)}}</h4>
                <p>Bed</p>
              </li>
              <li *ngIf="projectinfo.total_showers">
                <h4>{{(projectinfo.total_showers ? projectinfo.total_showers : 0)}}</h4>
                <p>Baths</p>
              </li>
              <li *ngIf="projectinfo.super_builtup_area">
                <h4>{{projectinfo.super_builtup_area}}</h4>
                <p>Sq.Ft</p>
              </li>-->
            </ul>
          </div>
          <div class="col-md-2">
            <ul class="prop_icon_info">
              <li class="like_share_btn">
                <button class="btn btn-common btn-lg heart_btn favoriteIcon_{{projectinfo.id}}"
                        [ngClass]="{ 'is_favorite_prop': projectinfo.is_favorited == 1 }"
                        (click)="makeFavorite(projectinfo.id, projectinfo.is_favorited, projectinfo.franchisee_id)">
                  <i class="fa fa-heart" *ngIf="projectinfo.is_favorited == 0" style="color: white"></i>
                  <i class="fa fa-heart" *ngIf="projectinfo.is_favorited == 1" style="color: #F02174;"></i>
                </button>
                <button type="button" id="shrehide" class="btn btn-common btn-lg share_btn" data-toggle="modal" data-target="#sharePropModal">
                  <i class="fa fa-share-alt"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- End Property header -->
    <!-- Start Project images -->
    <div class="row">
      <div class="col-md-9">
        <div class="project_main_img" (click)="open(0)">
          <div class="property_badge top_left" *ngIf="projectinfo.project_status">
            <span class="property_badge_sell badge-color">{{projectinfo.project_status}}</span>
          </div>
        </div>
        <!-- (click)="moreimage()" -->
      </div>
      <div class="col-md-3">
        <div class="project_other_img">
          <div class="project_other_img_1" (click)="open(0)"></div>
          <div class="project_other_img_2" (click)="open(0)"><span class="more_images_3 hidden"></span></div>
        </div>
      </div>
    </div>
    <!-- End Project images -->
    <div class="row">
      <div class="col-md-9">
        <div class="row property_more_info">
          <div class="col-md-12">
            <ul class="nav nav-tabs prop_info_tabs" role="tablist">
              <li class="nav-item" *ngIf="_video && _video.length > 0">
                <span class="nav-link active" id="property_video_tab" (click)="scrollTo('property_video')"
                      role="tab" aria-controls="property_video"
                      aria-selected="true">Property Video</span>
              </li>
              <li class="nav-item">
                <span class="nav-link active" id="project_description_tab" (click)="scrollTo('project_description')"
                      role="tab" aria-controls="project_description" aria-selected="true">Description</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="project_floor_plan_tab" (click)="scrollTo('project_floor_plan')" role="tab"
                      aria-controls="project_floor_plan" aria-selected="false">Price & Floor Plan</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="project_amenities_tab" (click)="scrollTo('project_amenities')" role="tab"
                      aria-controls="project_amenities" aria-selected="false">Amenities</span>
              </li>
              <li class="nav-item">
                <span class="nav-link" id="project_locality_tab" (click)="scrollTo('project_locality')" role="tab"
                      aria-controls="project_locality" aria-selected="false">Locality</span>
              </li>
              <!--<li class="nav-item">
                <span class="nav-link" id="project_developers_tab" (click)="scrollTo('project_developers')" role="tab"
                      aria-controls="project_developers" aria-selected="false">Developer</span>
              </li>-->
              <!--<li class="nav-item">
                <span class="nav-link" id="project_features_pro_tab" (click)="scrollTo('project_features_pro')"
                      role="tab" aria-controls="project_features_pro" aria-selected="false">Features Project</span>
              </li>-->
            </ul>
            <div class="tab-content prop_info_tab_content">
              <!--<div class="tab-pane fade show active" id="project_description_old" role="tabpanel"
                   aria-labelledby="project_description_tab">
                <h5>Description</h5>
                <div class="property_description_info">
                  <ul>
                    <li *ngIf="projectinfo.no_proj_tower">
                      <h3>Project Size</h3>
                      <p>
                        {{(projectinfo.no_proj_tower ? projectinfo.no_proj_tower + ' Towers -' : '')}} {{projectinfo.no_proj_units}} Units
                      </p>
                    </li>
                    <li *ngIf="projectinfo.possesion_date">
                      <h3>Possession Date</h3>
                      <p>{{projectinfo.possesion_date}}</p>
                    </li>
                    <li *ngIf="projectinfo.plot_area">
                      <h3>Project Area</h3>
                      <p>{{(projectinfo.plot_area ? projectinfo.plot_area : '0')}} Acres</p>
                    </li>
                    <li *ngIf="projectinfo.project_status">
                      <h3>Age of Construction</h3>
                      <p>{{projectinfo.project_status}}</p>
                    </li>
                    <li *ngIf="projectinfo.proj_amenity">
                      <h3>Overlooking</h3>
                      <p><span *ngFor="let itemA of projectinfo.proj_amenity; index as i"><span
                        *ngIf="i != 0">, </span>{{itemA.amenity.title}}</span></p>
                    </li>
                    <li *ngIf="projectinfo.proj_feature">
                      <h3>Unique Feature</h3>
                      <p>
                        <span *ngFor="let unique of projectinfo.proj_feature; index as i">
                        <span *ngIf="i != 0">, </span>{{unique.feature.title}}</span>
                      </p>
                    </li>
                    <li *ngIf="projectinfo.rera_no">
                      <h3>Rera Id</h3>
                      <p>{{projectinfo.rera_no}}</p>
                    </li>
                    <li *ngIf="projectinfo.proj_specification" style="width: 100%;">
                      <h3>Specification</h3>
                      <table class="tbl_spec">
                        <tbody>
                        <tr *ngFor="let specf of projectinfo.proj_specification; index as i">
                          <td class="tbl_spec_info" valign="top" style="width: 30%;">
                            {{specf.specification.title}}
                          </td>
                          <td class="tbl_spec_info" style="width: 70%;">{{specf.description}}</td>
                        </tr>
                        </tbody>
                      </table>
                      &lt;!&ndash;<p>
                        <span *ngFor="let specf of projectinfo.proj_specification; index as i">
                        <span *ngIf="i != 0">, </span>{{specf.specification.title}}</span>
                      </p>&ndash;&gt;
                    </li>

                  </ul>
                </div>
                <h5>About Project</h5>
                <div class="property_description_contant">
                  {{(projectinfo.description ? projectinfo.description : 'Description not found.')}}
                </div>
              </div>-->

              <div class="tab-content prop_info_tab_content" *ngIf="_video && _video.length > 0">
                <div class="tab-pane fade show active" id="property_video" role="tabpanel"
                     aria-labelledby="property_video_tab">
                  <h5>Property Videos</h5>
                  <div class="d-flex row">
                    <ul>
                      <li class="li-display" *ngFor="let video of _video;">
                        <iframe [src]="video" width="280" height="200" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="project_description" role="tabpanel" aria-labelledby="property_detail_tab">
                <h5>About Project</h5>
                <ng-template [ngIf]="projectinfo.project_logo_file != ''">
                  <img alt="Project Logo" class="project-logo" src="{{ projectinfo.project_logo_file }}" />
                </ng-template>
                <div class="property_description_contant">
                  {{(projectinfo.description ? projectinfo.description : 'Description not found.')}}
                </div>
                <h5 style="margin-top: 10px;">Description</h5>

                <div class="porject_details_accordians">
                  <div class="accordion_outer">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#virtual_tour" aria-expanded="true" aria-controls="virtual_tour">Description<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="virtual_tour">
                      <div class="row d-flex">
                        <div class="col-md-6 sub_div" *ngIf="projectinfo.no_proj_tower">
                          <h4 class="sub_div_title">Project Size</h4>
                          <ul class="sub_div_ul">
                            <li>
                              {{(projectinfo.no_proj_tower ? projectinfo.no_proj_tower + ' Towers -' : '')}} {{projectinfo.no_proj_units}} Units
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6 sub_div" *ngIf="projectinfo.possesion_date">
                          <h4 class="sub_div_title">Possession Date</h4>
                          <ul class="sub_div_ul">
                            <li>{{projectinfo.possesion_date}}</li>
                          </ul>
                        </div>
                        <div class="col-md-6 sub_div" *ngIf="projectinfo.plot_area">
                          <h4 class="sub_div_title">Project Area</h4>
                          <ul class="sub_div_ul">
                            <li>
                              {{(projectinfo.plot_area ? projectinfo.plot_area : '0') +' '+ (projectinfo?.plot_area_unittype?.title || 'Acres') }}
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6 sub_div" *ngIf="projectinfo.project_status">
                          <h4 class="sub_div_title">Age of Construction</h4>
                          <ul class="sub_div_ul">
                            <li>{{projectinfo.project_status}}</li>
                          </ul>
                        </div>
                        <div class="col-md-6 sub_div" *ngIf="projectinfo.rera_no">
                          <h4 class="sub_div_title">Rera Id</h4>
                          <ul class="sub_div_ul">
                            <li>{{projectinfo.rera_no}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="porject_details_accordians" *ngIf="(projectinfo.proj_feature && projectinfo.proj_feature.length > 0) || (projectinfo.proj_amenity && projectinfo.proj_amenity.length)">
                  <div class="accordion_outer">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#overlooking" aria-expanded="true" aria-controls="virtual_tour">Unique Feature & Amenities<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="overlooking">
                      <div class="row d-flex">
                        <div class="col-md-6 sub_div" *ngIf="projectinfo.proj_feature">
                          <h4 class="sub_div_title">Unique Feature</h4>
                          <ul class="sub_div_ul" *ngFor="let unique of projectinfo.proj_feature; index as i">
                            <li>{{unique.feature.title}}</li>
                          </ul>
                        </div>
                        <div class="col-md-6 sub_div" *ngIf="projectinfo.proj_amenity">
                          <h4 class="sub_div_title">Amenities</h4>
                          <ul class="sub_div_ul" *ngFor="let itemA of projectinfo.proj_amenity; index as i">
                            <li>{{itemA.amenity.title}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="porject_details_accordians" *ngIf="projectinfo.proj_school && projectinfo.proj_school.length > 0">
                  <div class="accordion_outer">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#school_details" aria-expanded="true" aria-controls="virtual_tour">School Information<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="school_details">
                      <div class="row d-flex">
                        <div class="col-md-6 sub_div">
                          <h4 class="sub_div_title">School Information</h4>
                          <ul class="sub_div_ul" *ngFor="let school of projectinfo.proj_school;">
                            <li>{{school.placeName}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="porject_details_accordians" *ngIf="projectinfo.proj_specification && projectinfo.proj_specification.length > 0">
                  <div class="accordion_outer">
                    <h4 class="accordion_title" data-toggle="collapse" data-target="#specification" aria-expanded="true" aria-controls="virtual_tour">Specification<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>
                    <div class="accordion_in_content collapse show" id="specification">
                      <div class="row d-flex">
                        <div class="col-md-12">
                          <table class="tbl_spec">
                            <tbody>
                            <tr *ngFor="let specf of projectinfo.proj_specification; index as i">
                              <td class="tbl_spec_info" valign="top" style="width: 20%;font-size: 13px;">
                                {{specf.specification.title}}
                              </td>
                              <td class="tbl_spec_info" style="width: 80%;font-size: 13px;">{{specf.description}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="projectinfo.project_account && projectinfo.project_account.acc_past_project && (projectinfo.project_account.acc_past_project.length > 0 || projectinfo.project_account.past_project.length > 0 || projectinfo.project_account.ongoing_project > 0 || projectinfo.project_account.upcoming_project > 0)" class="porject_details_accordians">
                  <div class="accordion_outer">
                    <h4 aria-controls="virtual_tour" aria-expanded="true" class="accordion_title" data-target="#upcoming_project" data-toggle="collapse">Developer Highlights<i class="fa fa-minus"></i><i class="fa fa-plus"></i></h4>

                    <div class="accordion_in_content collapse show" id="upcoming_project">
                      <div class="row d-flex">
                        <div class="col-md-6 sub_div">
                          <h4 class="sub_div_title">Past Projects</h4>
                          <div class="row d-flex">
                            <div class="col-md-12">
                              <table class="tbl_spec" style="width: 100%;">
                                <tbody>
                                <tr>
                                  <th class="sub_div_title" style="width: 60%;">Project Name</th>
                                  <th class="sub_div_title" style="width: 25%;">Type</th>
                                  <th style="width: 15%;">Year</th>
                                </tr>
                                <tr *ngFor="let pastProj of projectinfo.project_account.past_project; index as i">
                                    <td *ngIf="pastProj.project_id > '0' else noLinkColPast" class="tbl_spec_info" style="width: 60%;font-size: 13px;cursor: pointer;" valign="top">
                                      <a [routerLink]="['/project-details', pastProj.project_id]" target="_blank" href="#" class="text-dark text-decoration-none" href="javascript:;" target="_blank">
                                        {{pastProj.project_title}}
                                      </a>
                                    </td>
                                    <ng-template #noLinkColPast>
                                      <td class="tbl_spec_info" style="width: 60%;font-size: 13px;" valign="top">
                                        {{pastProj.project_title}}
                                      </td>
                                    </ng-template>
                                  <td class="tbl_spec_info" style="width: 25%;font-size: 13px;">{{pastProj.proj_type.title}}</td>
                                  <td class="tbl_spec_info" style="width: 15%;font-size: 13px;">{{pastProj.year_of_complete}}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 sub_div">
                          <h4 class="sub_div_title">Ongoing Projects</h4>
                          <div class="row d-flex">
                            <div class="col-md-12">
                              <table class="tbl_spec" style="width: 100%;">
                                <tbody>
                                <tr>
                                  <th class="sub_div_title" style="width: 60%;">Project Name</th>
                                  <th class="sub_div_title" style="width: 25%;">Type</th>
                                  <th style="width: 15%;">Year</th>
                                </tr>
                                <tr *ngFor="let onProj of projectinfo.project_account.ongoing_project; index as i">
                                  <td *ngIf="onProj.project_id > '0' else noLinkColOnProj" class="tbl_spec_info" style="width: 60%;font-size: 13px;" valign="top">
                                    <a [routerLink]="['/project-details', onProj.project_id]" target="_blank" href="#" class="text-dark text-decoration-none" href="javascript:;" target="_blank">
                                      {{onProj.project_title}}
                                    </a>
                                  </td>
                                  <ng-template #noLinkColOnProj></ng-template>
                                  <td class="tbl_spec_info" style="width: 25%;font-size: 13px;">{{onProj.proj_type.title}}</td>
                                  <td class="tbl_spec_info" style="width: 15%;font-size: 13px;">{{onProj.year_of_complete}}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 sub_div">
                          <h4 class="sub_div_title">Upcoming Projects</h4>
                          <div class="row d-flex">
                            <div class="col-md-12">
                              <table class="tbl_spec" style="width: 100%;">
                                <tbody>
                                <tr>
                                  <th class="sub_div_title" style="width: 60%;">Project Name</th>
                                  <th class="sub_div_title" style="width: 25%;">Type</th>
                                  <th style="width: 15%;">Year</th>
                                </tr>
                                <tr *ngFor="let nxtProj of projectinfo.project_account.upcoming_project; index as i">
                                  <td *ngIf="nxtProj.project_id > '0' else noLinkColnxtProj" class="tbl_spec_info" style="width: 60%;font-size: 13px;" valign="top">
                                    <a [routerLink]="['/project-details', nxtProj.project_id]" target="_blank" href="#" class="text-dark text-decoration-none" href="javascript:;" target="_blank">
                                      {{nxtProj.project_title}}
                                    </a>
                                  </td>
                                  <ng-template #noLinkColnxtProj>
                                    <td class="tbl_spec_info" style="width: 60%;font-size: 13px;" valign="top">
                                      {{nxtProj.project_title}}
                                    </td>
                                  </ng-template>
                                  <td class="tbl_spec_info" style="width: 25%;font-size: 13px;">{{nxtProj.proj_type.title}}</td>
                                  <td class="tbl_spec_info" style="width: 15%;font-size: 13px;">{{nxtProj.year_of_complete}}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <div class="tab-pane fade show active" id="project_floor_plan" role="tabpanel"
                   aria-labelledby="project_floor_plan_tab">
                <h5>Price & Floor Plan</h5>
                <ng-container *ngFor="let group of projectUnitGroup | keyvalue; index as i">
                  <ng-container *ngFor="let subgroup of group.value | keyvalue; index as j">
                    <div class="row titles title_{{i}}_{{j}} {{i==0 && j==0 ? '' : 'd-none'}}">
                      <div class="col-md-6 p_left">
                        <h4 id="projectCategoryTitle">{{subgroup.value.project_category_title}}</h4>
                        <p>Super Buildup Area : <b id="superBuildupTitle">{{subgroup.value.super_buildup_title}}</b></p>
                      </div>
                      <div class="col-md-6 p_right">
                        <span id="basePriceTitle" class="price" *ngIf="projectinfo.show_price_on_website === 1">₹ {{subgroup.value.base_price_title}}</span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <div class="row d-flex">
                  <div class="col-md-3 tabs_titles_floors" id="project_floor">
                    <div class="panel-group" id="accordion" role="tablist">
                      <div class="panel panel-default card"
                           *ngFor="let group of projectUnitGroup | keyvalue; index as i">
                        <div class="panel-heading" role="tab" id="heading{{i}}">
                          <h6 class="panel-title">
                            <a data-toggle="collapse" data-parent="#accordion"
                               href="#collapse{{i}}" aria-expanded="true"
                               class="nav-link {{i == 0 ? '': 'collapsed'}} {{group.key.length>20 ? 'arrow_fixer': ''}}">{{group.key}}</a>
                          </h6>
                        </div>
                        <div id="collapse{{i}}" class="panel-collapse in collapse {{i == 0 ? 'show':''}}"
                             role="tabpanel">
                          <div class="panel-body card-body">
                            <ul class="nav nav-tabs tabs_titles_floors_tabs" role="tablist"
                                *ngFor="let subgroup of group.value | keyvalue; index as j">
                              <li class="nav-item">
                                <a class="nav-link floor_link {{i == 0 && j == 0 ? 'active': ''}}"
                                   (click)="navChange(i,j);" id="floor_img_{{i}}_{{j}}"
                                   href="#floor_plan_{{i}}_{{j}}" data-toggle="tab" role="tab"
                                   aria-selected="true">{{subgroup.key}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 floor_pans">
                    <div class="tab-content tabs_titles_floors_tab_content">
                      <ng-container *ngFor="let imggroup of projectUnitGroup | keyvalue; index as x">
                        <ng-container *ngFor="let imgsubgroup of imggroup.value | keyvalue; index as y">
                          <div class="tab-pane fade {{x == 0 && y == 0 ? 'show active': ''}}"
                               id="floor_plan_{{x}}_{{y}}" data-toggle="tab"
                               role="tabpanel">
                            <ng-container *ngIf="imgsubgroup.value.proj_unit_gallery[0]">
                              <img (click)="openFloorPlan(imgsubgroup.key,0)" height="275" width="500"
                                   src="{{imgsubgroup.value.proj_unit_gallery[0].preview_url}}">
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                  <!-- <div class="col-md-4 floor_price_chart">
                    <div class="chartContainer" style="height: 200px; width: 100%;"></div>
                  </div> -->
                </div>
                <div class="row d-flex mt-5">
                  <div class="col-md-12 tabs_titles_floors">
                    <span (click)="showProjectDimensions()" class="show_p_dimensions_slider">Show Property Dimensions <i
                      class="fa fa-chevron-up"></i><i class="fa fa-chevron-down"></i></span>
                  </div>
                </div>
                <div class="p_dimensions_slider" style="display: none;">
                  <h5>Property Dimensions</h5>
                  <div class="p_details_accordians">
                    <div class="accordion_outer">
                      <div class="accordion_in_content collapse show">
                        <ng-container *ngFor="let unitgroup of projectUnitGroup | keyvalue; index as a">
                          <ng-container *ngFor="let unit of unitgroup.value | keyvalue; index as b">
                            <div class="row dimensions dimension_{{a}}_{{b}} {{a==0 && b==0 ? '' : 'd-none'}}">
                              <ng-container *ngIf="unit.value.dimensions">
                                <ng-container *ngFor="let dimension of unit.value.dimensions | keyvalue;">
                                  <!--                                  <div class="col-md-4">-->
                                  <div class="box-room">
                                    <h4>{{dimension.value.title}}</h4>
                                    <ul>
                                      <ng-container *ngFor="let data of dimension.value | keyvalue;">
                                        <ng-container *ngIf="data.key != 'title'">
                                          <li>{{data.value}}</li>
                                        </ng-container>
                                      </ng-container>
                                    </ul>
                                    <!--                                    </div>-->
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="project_amenities" role="tabpanel" aria-labelledby="project_amenities_tab" *ngIf="projectinfo.proj_amenity && projectinfo.proj_amenity.length > 0">
                <h5>Amenities</h5>
                <ul class="p_amenities">
                  <li class="s_amenities" *ngFor="let itemAmenities of projectinfo.proj_amenity; index as i">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {{itemAmenities.amenity.title}}
                  </li>
                </ul>
              </div>

              <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="property_amenities_tab" *ngIf="projectinfo.proj_bank_approve && projectinfo.proj_bank_approve.length > 0">
                <h5>Bank Approval</h5>
                <div class="logo-container">
                  <ul>
                    <li *ngFor="let bank_app of projectinfo.proj_bank_approve; index as i">
                      <div class="logo-holder logo-1">
                        <img src="{{bank_app.bank.bank_logo_url}}" alt="{{bank_app.bank.title}}" title="{{bank_app.bank.title}}" width="100%" height="100%" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-pane fade show active" id="ask_que_agent" role="tabpanel"
                   aria-labelledby="ask_que_agent_tab" *ngIf="projectinfo.assign_to">
                <h5>Ask Realtors
                  Agent {{(projectinfo.assign_to.name ? projectinfo.assign_to.name : projectinfo.assign_to.first_name + ' ' + projectinfo.assign_to.last_name)}}
                  a Question</h5>
                <div class="row d-flex agent_que_box">
                  <div class="col-md-4">
                    <img src="{{projectinfo.assign_to.image_preview}}" class="rounded-circle" alt="Cinque Terre"
                         width="95" height="95"/>
                    <div class="ag_info">
                      <h6>{{(projectinfo.assign_to.name ? projectinfo.assign_to.name : projectinfo.assign_to.first_name + ' ' + projectinfo.assign_to.last_name)}}</h6>
                      <!--<p>Vadodara Realtors Agent</p>-->
                      <p>{{projectinfo.assign_to.email}}</p>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <textarea class="form-control" id="agent_text_que" rows="4"
                              placeholder="I'd like to know more about Vadodara Real Estate."></textarea>
                    <span id="agent_text_que_alert" class="fs-12 hidden">Your query is submitted. Our team will get back to you soon</span>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-common btn-lg agent_btn" [disabled]="clicked" (click)="askQuestion(projectinfo.id)">Ask a Question
                    </button>
                    <p *ngIf="projectinfo.assign_to.contact_no">Text/Call <b>{{projectinfo.assign_to.contact_no}}</b>
                    </p>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="project_locality" role="tabpanel"
                   aria-labelledby="project_locality_tab">
                <h5>Locality</h5>
                <ul class="nav nav-tabs prop_nearby_tabs" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="schools-tab" (click)="changeNearBy('school', gm)" data-toggle="pill"
                       href="#schools" role="tab" aria-controls="schools" aria-selected="true">Schools</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="restaurants-tab" (click)="changeNearBy('restaurant', gm)" data-toggle="pill"
                       href="#restaurants" role="tab" aria-controls="restaurants" aria-selected="false">Restaurants</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="hospitals-tab" (click)="changeNearBy('hospital', gm)" data-toggle="pill"
                       href="#hospitals" role="tab" aria-controls="hospitals" aria-selected="false">Hospitals</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="atms-tab" (click)="changeNearBy('atm', gm)" data-toggle="pill" href="#atms"
                       role="tab" aria-controls="atms" aria-selected="false">Atm's</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="shopping_mall-tab" (click)="changeNearBy('shopping_mall', gm)"
                       data-toggle="pill" href="#shopping_mall" role="tab" aria-controls="shopping_mall"
                       aria-selected="false">Shopping Mall</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="schools-tab">
                    <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                             [zoomControl]="false" [mapTypeControl]="true" [scrollwheel]="false" [usePanning]="true"
                             (mapReady)="mapReady($event)">
                      <agm-marker *ngFor="let m of markers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="icon"
                                  (markerClick)="clickedMarker(m.pid)"
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <div class="imgDiv" [style.background-image]="'url(\'' + m.image_preview + '\')'">&nbsp;</div>
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p><i class="fa fa-rupee"></i> {{ m.asking_price }}</p>
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                      <agm-marker *ngFor="let m of schoolMarkers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [label]="m.label"
                                  [iconUrl]="iconSchool"
                                  (mouseOver)="onMarkerMouseOver(infoWindow, gm)"
                                  [markerDraggable]="m.draggable">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                          <div class="infoWindowOuter">
                            <!-- <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <p>{{ m.asking_price }}</p>
                            </div> -->
                            <div class="otherInfo">
                              <h4>{{ m.locality_title }}</h4>
                              <div >{{m.asking_price}}</div>
                              <div class="dir_top">
                                <span class="link" (click)="getDirection(m.lat,m.lng)">Get Directions</span>                              </div>
          
                            </div>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="project_features_pro" role="tabpanel"
                   aria-labelledby="project_features_pro_tab">
                <h5>Similar Project</h5>
                <div *ngIf="(similarProjectList ||[]).length == 0">No Similar Project Found!</div>
                <div  class="row ss_property_view" id="similar_property_view" *ngIf="(similarProjectList ||[]).length > 0">
                  <ng-container *ngFor="let similarProject of similarProjectList">
                    <div class="d-flex single_property_view">
                      <a [routerLink]="['/project-details', similarProject.id ]" target="_blank" href="#" class="card" href="#" target="_blank">
                        <div [style.background-image]="'url(\'' + similarProject.image_preview + '\')'"
                             class="grid_image_view" >
                          <!--<div class="property_badge top_left">
                            <span class="property_badge_sell">New</span>
                          </div>-->
                          <!--<div class="property_badge top_right">
                            <i class="fa fa-heart" style="color: white"></i>
                          </div>-->
                        </div>
                        <div class="card-body">
                          <h5 class="card-title">{{ similarProject.name }}</h5>
                          <p class="card-text">
                            {{ similarProject.project_unit_group }}
                          </p>
                          <p class="card-text">
                            {{ similarProject.locality_title }} {{ (similarProject.city ? ', '+similarProject.city.city : '') }}
                          </p>
<!--                          <button class="btn project-owner-btn">Contact Owner</button>-->
                        </div>
                      </a>
                    </div>
                  </ng-container>

                  <!--<div class="d-flex single_property_view">
                    <div class="card">
                      <div class="grid_image_view"
                           [style.background-image]="'url(../../assets/img/web-image/home-2.jpg)'">
                        <div class="property_badge top_left">
                          <span class="property_badge_h_sell">Home For Sale</span>
                        </div>
                        <div class="property_badge top_right">
                          <i class="fa fa-heart" style="color: white"></i>
                        </div>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">Podar Plaza Center</h5>
                        <p class="card-text">
                          2 - 3 BHK Apartment
                        </p>
                        <p class="card-text">
                          338 Spear St Unit 21F, Surat, Gujarat, IN
                        </p>
                        <button class="btn project-owner-btn">Contact Owner</button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex single_property_view">
                    <div class="card">
                      <div class="grid_image_view"
                           [style.background-image]="'url(../../assets/img/web-image/home-3.jpg)'">
                        <div class="property_badge top_left">
                          <span class="property_badge_h_sell">Home For Sale</span>
                        </div>
                        <div class="property_badge top_right">
                          <i class="fa fa-heart" style="color: white"></i>
                        </div>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">Podar Plaza Center</h5>
                        <p class="card-text">
                          2 - 3 BHK Apartment
                        </p>
                        <p class="card-text">
                          338 Spear St Unit 21F, Surat, Gujarat, IN
                        </p>
                        <button class="btn project-owner-btn">Contact Owner</button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex single_property_view">
                    <div class="card">
                      <div class="grid_image_view"
                           [style.background-image]="'url(../../assets/img/web-image/home-4.jpg)'">
                        <div class="property_badge top_left">
                          <span class="property_badge_sell">New</span>
                        </div>
                        <div class="property_badge top_right">
                          <i class="fa fa-heart" style="color: white"></i>
                        </div>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">Podar Plaza Center</h5>
                        <p class="card-text">
                          2 - 3 BHK Apartment
                        </p>
                        <p class="card-text">
                          338 Spear St Unit 21F, Surat, Gujarat, IN
                        </p>
                        <button class="btn project-owner-btn">Contact Owner</button>
                      </div>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Property images -->
      </div>
      <div class="col-md-3" [ngClass]="{'sticky-div':enableSticky}">
        <div class="side_block" id="visit">
          <h5>Book Your Appointment</h5>
          <div class="owl-carousel owl-theme" id="visit_date_slider"></div>
          <p>{{scheduleText}}</p>
          <div class="row schedule-select mt-2">
            <div (click)="selectAppointmentType(1)" class="col-md-6 text-center">
              <div class="row schedule-option {{ (this.tourInPerson ? 'option-active' : '') }}">
                <div class="col-md-12">
                  <i aria-hidden="true" class="fa fa-home icon-cls"></i>
                </div>
                <div class="col-md-12 schedule-text">
                  Tour in person
                </div>
              </div>
            </div>
            <div (click)="selectAppointmentType(2)" class="col-md-6 text-center">
              <div class="row schedule-option {{ (this.tourVideoChat ? 'option-active' : '') }}">
                <div class="col-md-12">
                  <i aria-hidden="true" class="fa fa-mobile icon-cls"></i>
                </div>
                <div class="col-md-12 schedule-text">
                  Tour via video chat
                </div>
              </div>
            </div>
          </div>
          <a (click)="searchschedulevisit(projectinfo.franchisee_id, projectinfo.assign_to_user_id)" class="schdule btn">Book Now</a>
        </div>
        <!--<div class="side_block agent_details">
          <img src="../../assets/img/web-image/projectdetialspage/person.png" alt="Nimpesh Parikh" />
          <h4>Nimpesh Parikh</h4>
          <div class="ag_in_details_flex">
            <span>Pwc Property Hub</span>
            <span>Operating Since <b>2011</b></span>
          </div>
          <div class="ag_in_details">
            <p class="ag_title">Rera Id</p>
            <p>https://gujrera.gujarat.gov.in/viewProjectDetailPage?projectID=8199</p>
          </div>
          <div class="ag_in_details">
            <p class="ag_title">Location</p>
            <p>Bhatar Road, Surat, Gujarat, 395001 India</p>
          </div>
          <div class="ag_in_details_flex">
            <div class="ag_in_details_inner">
              <p class="ag_title">Properties For Sale</p>
              <p>30</p>
            </div>
            <div class="ag_in_details_inner">
              <p class="ag_title">Properties For Rent</p>
              <p>15</p>
            </div>
          </div>
          <a class="schdule btn">Contact Certified Agent</a>
        </div>-->
      </div>
    </div>
  </div>
</div>

<div class="modal fade right" id="project_more_image" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
  <div class="modal-dialog momodel modal-fluid" role="document">
    <div class="modal-content">
      <div class="modal-body">

      </div>
    </div>
  </div>
</div>

<!-- Shareable Link Modal -->
<div class="modal fade share_model" id="sharePropModal" tabindex="-1" role="dialog" aria-labelledby="sharePropModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <app-flash></app-flash> -->

        <div class="loginform">
          <form [formGroup]="shareform" (ngSubmit)="onShareSubmit(projectinfo.id)">
            <div class="form-group">
              <input type="text" formControlName="emailShare" class="form-control" placeholder="Email Address"
                     [ngClass]="{ 'is-invalid': submitted && g.emailShare.errors }" />
              <div *ngIf="submitted && g.emailShare.errors" class="invalid-feedback">
                <div *ngIf="g.emailShare.errors.required">Email is required</div>
                <div *ngIf="g.emailShare.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <!--<div class="form-group">
              <input type="text" formControlName="subjectShare" class="form-control" placeholder="Subject"
                     [ngClass]="{ 'is-invalid': submitted && g.subjectShare.errors }" />
              <div *ngIf="submitted && g.subjectShare.errors" class="invalid-feedback">
                <div *ngIf="g.subjectShare.errors.required">Subject is required</div>
              </div>
            </div>-->
            <div class="form-group">
              <button class="bttn btn-block login-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
