import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isFailed: boolean = false
  isSuccess: boolean = false
  contactForm: FormGroup;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  stopLoader: boolean = false;
  submitted: boolean=false;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required,Validators.minLength(10), Validators.maxLength(20)]],
      message: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.email]],
    });
  }
  get g() {
    return this.contactForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    this.stopLoader = true
    const templateParams = {
      from_name: this.contactForm.value.firstName + ' ' + this.contactForm.value.lastName,
      from_email: this.contactForm.value.email,
      message: this.contactForm.value.message
    };
    emailjs.send('service_7pn5uyq', 'template_ydwajog', templateParams, '5i3TRCpSW4aYM_Oko')
      .then((response) => {
        this.stopLoader = false
        this.isSuccess=true
        this.submitted=false
        this.contactForm.reset()
      }, (err) => {
        this.stopLoader = false
        this.isFailed=true
      });
      setTimeout(() => {
        this.isSuccess=false
        this.isFailed=false
      }, 5000);
  }



}
